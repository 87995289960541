import * as IncrementosDomain from '~/domain/use_cases/configurar/almacen/incrementos_porcentuales'
import { incrementosFactory } from "~/domain/entities/configurar/almacen/incrementos_porcentuales/factories/incrementosFactory"
import { $try } from '~/services/global'

export const state = {
    incrementos: [],
    incremento: incrementosFactory.instantiateEmpty(),
    editIncremento: incrementosFactory.instantiateEmpty(),
}

export const getters = {
    incrementos: state => state.incrementos,
    incremento: state => state.incremento,
    editIncremento: state => state.editIncremento,
}

export const mutations = {
    set_select_incrementos(state, payload){
        state.incrementos = payload
    },
    set_incremento(state, payload){
        state.incremento = payload
    },
    set_edit_incremento(state, payload){
        state.editIncremento = payload
    },
    add_incremento(state, payload){
        state.incrementos.push(payload)
    },
    update_incremento(state, payload){
        state.incrementos = state.incrementos.map(e => {
            if (e.id === payload.id) e = payload
            return e 
        })
    },
    delete_incremento(state, payload){
        state.incrementos = state.incrementos.filter(e => (e.id !== payload))
    }
}

export const actions = {
    async ACTION_GET_INCREMENTOS({commit}){
        const data = await IncrementosDomain.GetIncrementosDataTypeUseCase()

        $try(async () => {
            commit('set_select_incrementos', data);
        });
    },
    async ACTION_CREATE_INCREMENTOS({ state, commit}){
        const incremento = await IncrementosDomain.CreateIncrementosDataTypeUseCase(state.incremento)
        $try(() => {
            if(incremento) commit('set_select_incrementos', [...state.incrementos, incremento])
        })
    },
    async ACTION_UPDATE_INCREMENTOS({commit}){
        const incremento = await IncrementosDomain.UpdateIncrementosDataTypeUseCase(state.editIncremento)
        $try(() => {
            if(incremento) commit('set_select_incrementos', state.incrementos.map(e => e.id === incremento.id ? incremento : e))
        })
    },
    async ACTION_DELETE_INCREMENTOS({commit}, id){
        const data = await IncrementosDomain.DeleteIncrementosDataTypeUseCase(id)
        $try(async () => {
            if(data) commit('set_select_incrementos', state.incrementos.filter(e => e.id !== id));
        });
    },
    async ACTION_UPDATE_POSITION_INCREMENTOS ({commit}, payload){
        await IncrementosDomain.AddPosIncrementosDataTypeUseCase(payload)
        $try( 
           () => commit('set_select_incrementos', state.incrementos)
        )
    }
}