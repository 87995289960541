import { Entity } from '~/domain/entities'

export class PedidosInternosDetalleOcsFilter extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'tipos_materiales',
				'unidades_min',
				'unidades_max',
            ]
        })
    }
}