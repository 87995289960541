import Api from '~/services/api'
const ruta = 'obras/vigentes'

export const ListaChequeo = {
    getDetalleServicio(payload){
        const Service = new Api()
        return Service.get(`${ruta}/${payload.id_proyecto}/servicios/${payload.id_control_piso}`)
    },
    getCheckListies(payload){
        const Service = new Api()
        return Service.get(`${ruta}/${payload.id_proyecto}/servicios/${payload.id_control_piso}/check-list`)
    },
    createCheckListies(params){
        const Service = new Api({notification: true})
        return Service.post(`${ruta}/${params.id_proyecto}/servicios/${params.id_control_piso}/check-list`, params.payload)
    },
    editCheckListies(params){
        const Service = new Api({notification: true})
        return Service.put(`${ruta}/${params.id_proyecto}/servicios/${params.id_control_piso}/check-list/${params.id_control_pisos_lista_chequeo}`, params.payload)
    },
    deleteCheckListies(payload){
        const Service = new Api({notification: true})
        return Service.delete(`${ruta}/${payload.id_proyecto}/servicios/${payload.id_control_piso}/check-list/${payload.id_control_pisos_lista_chequeo}`)
    },
    addActividad(params){
        const Service = new Api({notification: true})
        return Service.post(`${ruta}/${params.id_proyecto}/servicios/${params.id_control_piso}/check-list/${params.id_control_pisos_lista_chequeo}/actividad`, params.payload)
    },
    editActividad(params){
        const Service = new Api({notification: true})
        return Service.put(
            `${ruta}/${params.id_proyecto}/servicios/${params.id_control_piso}/check-list/${params.id_control_pisos_lista_chequeo}/actividad/${params.id_configuracion_control_piso_lista_chequeo_actividades}`,
            params.payload)
    },
    deleteActividad(payload){
        const Service = new Api({notification: true})
        return Service.delete(
            `${ruta}/${payload.id_proyecto}/servicios/${payload.id_control_piso}/check-list/${payload.id_control_pisos_lista_chequeo}/actividad/${payload.id_configuracion_control_piso_lista_chequeo_actividades}`
        )
    },
    addAdjuntoActividad(params){
        const Service = new Api({notification: true})
        return Service.post(
            `${ruta}/${params.id_proyecto}/servicios/${params.id_control_piso}/check-list/${params.id_control_pisos_lista_chequeo}/actividad/${params.id_configuracion_control_piso_lista_chequeo_actividades}/adjunto`,
            params.payload
        )
    },
}