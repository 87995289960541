import { UseCase } from "~/domain/core/UseCase";
import { Documents } from '~/services/talleres/ver_documentos';
import { FolderFactory } from "~/domain/entities/talleres/factories/FolderFactory";
import { $try } from '~/services/global';

class GetFoldersUseCase extends UseCase {
    static async execute({id_taller}) {
        const data = await Documents.getFolders(id_taller);
        return $try(() => {
            if(!Array.isArray(data?.data)) return [];
        
            return data.data.map(FolderFactory.instantiate)
        })
    }
}

export default GetFoldersUseCase.execute;