<template>
    <div class="bg-f9 border p-3 br-4 mb-3">
        <div class="row mb-1">
            <div class="col-12">
                <div class="d-middle">
                    <p class="f-18 f-700">Orden de compra {{ orderNumber }}</p>
                    <i :class="`${status.icon} mx-2 f-20 f-600`" />
                    <p class="f-14 font-italic f-500"> {{ status.text }} - {{ date }}</p>
                </div>
            </div>
        </div>
        <!-- proveedor + forma de pago -->
        <div class="row f-14">
            <div class="col-12 col-lg-6">
                <p class="f-600">
                    Proveedor:
                    <span class="f-300"> {{ supplier }} </span>
                </p>
            </div>
            <div class="col-12 col-lg-6 text-md-left text-xl-right">
                <p class="f-18 f-700">{{ formatoMoneda(value) }} </p>
                
            </div>
        </div>
        <!-- destino + cotizacion -->
        <div class="row f-14">
            <div class="col-12 col-lg-6">
                <p class="f-600">
                    Destino: 
                    <span class="f-300"> {{ destiny }} </span>
                </p>
            </div>
            <div class="col-12 col-lg-6 text-md-left text-xl-right">
                <p class="f-600">
                    Forma de pago:  
                    <span class="f-300"> {{ paymentMethod  }} </span>
                    <i class="icon-pencil ml-2 f-18 cr-pointer" @click.stop="$emit('edit')" />
                </p>
                
            </div>
        </div>
        <!-- servicio + cliente -->
        <div class="row f-14">
            <div class="col-12 col-lg-6">
                <p class="f-600">
                    Servicio: 
                    <span class="f-300"> {{ service }} </span>
                </p>
            </div>
        </div>
        <div class="row f-14">
            <div v-if="project" class="col-12 col-lg-6">
                <p class="f-600">
                    Proyecto: 
                    <span class="f-300"> {{ project }} </span>
                </p>
            </div>
            <div v-if="client" class="col-12 col-lg-6 text-md-left text-xl-right" :class="!project ? 'ml-auto' : '' ">
                <p class="f-600">
                    Cliente:  
                    <span class="f-300"> {{ client }} </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props:{
        orderNumber:{
            type: [String, Number],
            default: '-',
            required: true
        },
        state: {
            type: [Number, Boolean],
            default: false,
        },
        date:{
            type: String,
            default: '-'
        },
        supplier:{
            type: String,
            default: '-'
        },
        value:{
            type: [String, Number],
            default: '-'
        },
        destiny:{
            type: String,
            default: '-'
        },
        paymentMethod:{
            type: [String, Number],
            default: '-'
        },
        client:{
            type: [String, Boolean],
            default: '-'
        },
        project:{
            type: [String, Boolean],
            default: '-'
        },
        service:{
            type: String,
            default: '-'
        },

    },
    data(){
        return{

        }
    },
    computed:{
        ...mapGetters({
            purchase_states: 'compras/ordenes_compra/purchase_states'
        }),
        status(){
            return this.purchase_states[this.state] || this.purchase_states.default
        }
    }

}
</script>

<style>

</style>