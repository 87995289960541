export default {
    created(){
        window.option_df_gbp = {
            enableDownload: false,
            hideControls: 'share,outline',
            source: '',
        };
    },
    methods: {
/*         set_flip_img(arreglo){
            new Promise(resolve => {
                const fileTemp = localStorage.getItem('')
                let tmp = arreglo.map(a => `/api/storage/${a}?no=1`) || 
                resolve(tmp);
            }).then(tmp => {
                console.log(tmp)
                option_df_gbp.source = tmp;
            })
        }, */
        set_pdf(file){
            option_df_gbp.source = this.$options.filters['helper-storage'](file)
        }
    }
}