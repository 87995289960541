import { UseCase } from "~/domain/core/UseCase";
import { Gallery } from "~/services/obras/gallery/gallery"
import { GalleryFactory } from '~/domain/entities/obras/galeria/factories/GalleryFactory'
import { $try } from '~/services/global'

class GetFoldersUseCase extends UseCase {
    static async execute(params){
        
        const data = await Gallery.getFolders(params)
        return $try( () => {
            let newData = data.data.data
            if(newData && Array.isArray(newData)) {
                return newData.map(type => GalleryFactory.instantiate(type))
            }
        })
    }
}

export default GetFoldersUseCase.execute