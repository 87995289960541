import { Maintenance } from "../Maintenance";

export class MaintenanceFactory {
    static instantiate(obj){
        return new Maintenance(obj)
    }

    static instantiateEmpty(){
        return new Maintenance({
            nombre: '',
            id: '',
            descripcion: '',
            numero_actividades: '',
        })
    }
}