import { UseCase } from "~/domain/core/UseCase"
import { Pedidos } from '~/services/almacen/pedidos'
import { PedidoInternoMaterialFiltroFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoMaterialFiltroFactory'
import { $try } from "~/services/global"

class GetFiltrosMaterialPedidoUseCase extends UseCase{
    static async execute(params){
        const data =  await Pedidos.getFiltroMaterialPedido(params)
        return $try(() => {
            return data.data ? PedidoInternoMaterialFiltroFactory.instantiate(data.data): null 
        })
    } 
}

export default GetFiltrosMaterialPedidoUseCase.execute