import { UseCase } from "~/domain/core/UseCase";
import { WorkshopCalendarService } from '~/services/configurar/obras/calendario_talleres'
import { WorkshopCalendarActivityFactory } from '~/domain/entities/configurar/obras/calendario_talleres/factories/WorkshopCalendarActivityFactory'
import { $try } from '~/services/global'

class CreateWorkshopCalendarActivityUseCase extends UseCase {
    static async execute(params){
        const { data } =  await WorkshopCalendarService.createWorkshopCalendarActivity(params)
        const {created_at, created_by, updated_at,updated_by, ...rest } = data
        return $try(
                () => WorkshopCalendarActivityFactory.instantiate(rest)
        )
    }
}

export default CreateWorkshopCalendarActivityUseCase.execute