import { UseCase } from "~/domain/core/UseCase";
import { Equipos } from '~/services/obras/equipos/agregarEquipo'
import { AgregarEquipoOpsFactory } from '~/domain/entities/obras/equipos/factory/agregarEquipoOpsFactory'
import { $try } from '~/services/global'

class GetInfoEquipoOpsUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await Equipos.getInfoEquipoModelo(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new AgregarEquipoOpsFactory.instantiate(e)) 
        })
    }
}

export default GetInfoEquipoOpsUseCase.execute