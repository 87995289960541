import { MaterialesFilter } from "../materialesFilter";

export class MaterialesFilterFactory {
    static instantiate(obj) {
        return new MaterialesFilter(obj);
    }

    static instantiateEmpty() {
        return new MaterialesFilter({
            nombre: '',
            id_unidad_medida: '',
            cantidad_min: 0,
            cantidad_max: 0
        });
    }

    static instantiateEmptyWithRange(range) {
        const cantidad_min = range?.min ?? 0;
        const cantidad_max = range?.max ?? 0;
        
        const filter = new MaterialesFilter({
            nombre: '',
            id_unidad_medida: null,
            cantidad_min,
            cantidad_max
        });

        filter.setExceptions({
            cantidad_min,
            cantidad_max,
        });

        return filter
    }
}