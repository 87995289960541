import { Entity } from '~/domain/entities'

export class PedidoInterno extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
				'id_proyecto',
				'proyecto',
				'id_equipo',
				'id_equipo_modelo',
				'equipo',
				'fecha_solicitud',
				'solicita',
				'fecha_llegada'
            ]
        })
    }
}