import { ValueObject } from '~/domain/core/ValueObject'

export class AttachmentVO extends ValueObject{
    constructor(obj){
        super({
            params: obj, 
            keys: ['nombre', 'descripcion', 'archivo', 'id_materiales_mantenimientos_actividades', 'actividad']
        });
    }
}
