import { UseCase } from '~/domain/core/UseCase';
import { Resumen } from '~/services/oportunidades/productos/resumen';
import { PercentageIncreaseResumeFactory } from '~/domain/entities/oportunidades/productos/factories/PercentageIncreaseResumeFactory';
import { $try } from '~/services/global';

class UpdatePercentageIncreaseUseCase extends UseCase {
    static async execute(id, payload) {
        const data = await Resumen.updatePercetangeIncrease(id, payload);

        return $try(() => {
            if (data?.data) {
                return PercentageIncreaseResumeFactory.instantiate(data.data);
            }
        })   
    }
}

export default UpdatePercentageIncreaseUseCase.execute;