import { UseCase } from "~/domain/core/UseCase";
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'
import { ValorFinalEtapaFactory } from '~/domain/entities/cotizaciones/cotizaciones_clientes/factories/valorFinalEtapaFactory'
import { $try } from '~/services/global'

class GetValorFinalEtapaUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CotizacionCliente.getValorFinalEtapa(payload)
        return $try(() => {
            if(data?.data) return new ValorFinalEtapaFactory.instantiate(data.data)
        })
    }
}

export default GetValorFinalEtapaUseCase.execute