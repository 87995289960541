import { UseCase } from '~/domain/core/UseCase';
import { WorkshopView } from '~/services/talleres/ver';
import { WorkshopTypeGraphVOFactory } from '~/domain/value_objects/talleres/factories/WorkshopTypeGraphVOFactory';
import { $try } from '~/services/global';

class GetWorkshopServicesUseCase extends UseCase {
  static async execute(id_taller) {
    const data = await WorkshopView.getWorkshopServices(id_taller);
    return $try(() => {
        return data?.data?.map(WorkshopTypeGraphVOFactory.instantiate)
    })
  }
}

export default GetWorkshopServicesUseCase.execute;