import * as CotizacionesDomain from '~/domain/use_cases/cotizaciones'
import { MaterialesFilterFactory } from '~/domain/filters/cotizaciones/factories/materialesFilterFactory';
import {$try} from '~/services/global'

export const state = {
    detalleInfo: {},
    materiales: [],
    rangos: {},
    filtros: MaterialesFilterFactory.instantiateEmpty()
}

export const getters = {
    detalleInfo: state => state.detalleInfo,
    materiales: state => state.materiales,
    rangos: state => state.rangos,
    filtros: state => state.filtros,
}

export const mutations = {
    set_detalle_info(state, payload){
        state.detalleInfo = payload
    },
    set_materiales(state, payload){
        state.materiales = payload
    },
    set_rangos(state, payload){
        state.rangos = payload
    },
    set_filtros(state, payload){
        state.filtros = payload
    },
}

export const actions = {

    async Action_get_detalle_info({commit}, payload){
        const data = await CotizacionesDomain.GetInfoDetalleCotizacionUseCase(payload)
        $try(() => { 
            commit('set_detalle_info', data)
        });
    },
    async Action_get_materiales({commit}, params){
        params.payload = {...state.filtros.clean()}
        const data = await CotizacionesDomain.GetMaterialesCotizacionUseCase(params)
        $try(() => { 
            commit('set_materiales', data)
        });
    },
    async Action_edit_material({commit}, params){
        await CotizacionesDomain.EditMaterialCotizacionUseCase(params)
    },
    async Action_enviar_cotizacion({commit}, params){
        await CotizacionesDomain.EnviarCotizacionUseCase(params)
    },
    async Action_get_rango_materiales({commit}, payload){
        const data = await CotizacionesDomain.GetRangosMaterialesUseCase(payload)
        $try(() => { 
            commit('set_rangos', data)
        });
    },
    async Action_clear_filters({commit}){
        const rangos = {min: state.rangos.cantidad_min, max: state.rangos.cantidad_max}
        $try(() => { commit('set_filtros', MaterialesFilterFactory.instantiateEmptyWithRange(rangos)) });
    },
}