import * as MenuDomain from '~/domain/use_cases/configurar/menus'
import { $try } from '~/services/global';

export const state = {
    menus: [],
    headers: [],
    newRuta: [],
}

export const getters = {
    menus: state => state.menus, 
    headers: state => state.headers,
    newRuta: state => state.newRuta,
}

export const mutations = {
    set_menus(state, payload){
        state.menus = payload
    },
    set_headers(state, payload){
        state.headers = payload
    },
    set_ruta_permiso_list(state, payload) {
        state.permisoRutaList = payload
    },
    set_newRuta(state, payload){
        state.newRuta = payload
    },
}

export const actions = {
    async Action_get_menus({commit}, payload){ 
        const data = await MenuDomain.GetMenusUseCase(payload)
        $try(() => {
            if(data) commit('set_menus', data);
        });
    }, 

    async Action_get_headers({commit}, payload){
        const header = await MenuDomain.GetHeadersUseCase(payload)
        $try(
            () => commit('set_headers', header) 
        )
    },    

    async Action_create_ruta_permiso({commit}, payload){  
        const permiso_ruta = await MenuDomain.CreateRutaPermisoUseCase(payload)
        if (permiso_ruta) commit('set_headers', [...state.headers, permiso_ruta])
        return ([...state.headers]);
    },  

    async Action_create_raiz_ruta({commit}, payload){  
        const permiso_ruta = await MenuDomain.CreateRutaRaizUseCase(payload)
        if (permiso_ruta) commit('set_newRuta', [permiso_ruta])
        return ([...state.newRuta]);
    },
} 