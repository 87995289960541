import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class CreateConfMaterialCloneUseCase extends UseCase {
    static async execute(params){
        const { id_etapa, id_configuracion_etapa, ...payload } = params
        const data = await Stages.createMaterialConfigurationClone({id_etapa, id_configuracion_etapa, payload})
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default CreateConfMaterialCloneUseCase.execute