import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { AdditionalDataPosicionFactory } from '~/domain/entities/configurar/almacen/datos_adicionales/factories/AdditionalDataPosicionFactory'
import { $try } from '~/services/global'

class GetAdditionalDataPosicionUseCase extends UseCase {
    static async execute(payload){
        const data =  await AdditionalData.getAdditionalDataPosicion(payload)

        return $try(() => {
            return data?.data?.map(item => AdditionalDataPosicionFactory.instantiate(item)) ?? []
        })
    }
}

export default GetAdditionalDataPosicionUseCase.execute