import { Folder } from "../Folder";

export class FolderFactory {
    static instantiate(obj){
        return new Folder(obj)
    }

    static instantiateEmpty(){
        return new Folder({
            id: '',
            nombre: '',
        })
    }
}