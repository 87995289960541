import { opcionCalificacion } from "../opcionCalificacion";

export class opcionCalificacionFactory {
    static instantiate(obj){
        return new opcionCalificacion(obj)
    }

    static instantiateEmpty(){
        return new opcionCalificacion({
            id: undefined,
            opcion: '',
            valor: '',
            id_configuracion_control_piso_aspectos_calificar: '',
            posicion: ''
        })
    }
}