import { PercentageIncrease } from "../PercentageIncrease";

export class PercentageIncreaseFactory {
    static instantiate(obj) {
        return new PercentageIncrease(obj);
    }

    static instantiateEmpty() {
        return new PercentageIncrease({
            id: null,
            nombre: '',
            porcentaje: 0,
            posicion: 0
        });
    }
}