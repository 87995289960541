import { UseCase } from "~/domain/core/UseCase";
import { Proveedores } from "../../../../services/proveedores/proveedor-cotizacion";
import { $try } from '~/services/global'

class GetDetalleCotizacionUseCase extends UseCase{

    static async execute(idProveedor, idCotizacion){
        const data = await Proveedores.getDetalleCotizacion(idProveedor, idCotizacion);
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default GetDetalleCotizacionUseCase.execute