import { UseCase } from '~/domain/core/UseCase'
import { Material } from "~/services/almacen/categorias/materiales"

class GetCategoryMaterialsSelectedUseCase extends UseCase{
    static async execute(category_id){
        const data = await Material.selectedMaterials(category_id)
        return data?.data
    }
}

export default GetCategoryMaterialsSelectedUseCase.execute