import { UseCase } from "~/domain/core/UseCase"
import Materials from "~/services/almacen/materiales"
import { MaterialFactory } from "~/domain/entities/almacen/materiales/factories/MaterialFactory"
import { PaginationDecorator } from "~/domain/decorators/PaginationDecorator"

class GetMaterialsUseCase extends UseCase {
    static async execute(params){
        const data = await Materials.listMaterials(params)
        if(data?.data){
            data.data.data = data.data.data.map(el => ({...el, espesor: null}))
            return new PaginationDecorator(
                data.data,
                MaterialFactory.instantiate
            ).toTable()
        }
    }
}

export default GetMaterialsUseCase.execute