import { Entity } from '~/domain/entities';

export class BillVO extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                'id',
                'factura'
            ]
        });
    }
}