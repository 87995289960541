import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'
import { opcionCalificacionFactory } from '~/domain/entities/configurar/obras/calificacion_servicio/factories/opcionCalificacionFactory'
import { $try } from '~/services/global'

class AddPosOptionUseCase extends UseCase {
    static async execute(payload){
        const data = await CalificacionService.addPosOption(payload)
        return $try(() => {
            if(data?.data) {
                const { id, opcion, valor, id_configuracion_control_piso_aspectos_calificar, posicion } = data.data
                return opcionCalificacionFactory.instantiate({id, opcion, valor, id_configuracion_control_piso_aspectos_calificar, posicion});
            }
        })
    }
}
export default AddPosOptionUseCase.execute
