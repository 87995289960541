import { UseCase } from "~/domain/core/UseCase";
import { Equipos } from '~/services/obras/equipos/equipo'


class DeleteEtapasUseCase extends UseCase{
    static async execute(payload){
        await Equipos.deleteEtapas(payload)
    }
}

export default DeleteEtapasUseCase.execute