import { UseCase } from "~/domain/core/UseCase"
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'
import { CityPaginatedVOFactory } from '~/domain/value_objects/opportunity/factories/cityPaginatedVOFactory'

class GetSelectCityPaginatedUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectCityPaginated(payload)
        return $try(() => {
            if(data?.data) return data.data.map(el => CityPaginatedVOFactory.instantiate(el))
            return []
        })
    }

}

export default GetSelectCityPaginatedUseCase.execute;