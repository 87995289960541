import { UseCase } from '~/domain/core/UseCase';
import { Obras } from '~/services/obras/obras';
import { $try } from '~/services/global';

class GetFinishedObrasRangeUseCase extends UseCase {
  static async execute(params) {
    const data = await Obras.getFinishedObrasRange(params);
    return $try(() => {
        if(data?.data){
            return data.data
        }
    })
  }
}

export default GetFinishedObrasRangeUseCase.execute;