import { UseCase } from "~/domain/core/UseCase";
import { CheckList } from '~/services/configurar/obras/lista_chequeo.js'
import { CheckListFactory } from '~/domain/entities/configurar/obras/check_list/factories/CheckListFactory.js'
import { $try } from '~/services/global'

class CreateCheckListUseCase extends UseCase {
    static async execute(payload){
        const data = await CheckList.createCheckList(payload)
        return $try( () => {
            if (data?.data) {
                // return CheckListFactory.instantiate(data.data);
                return data?.data?.map(e => CheckListFactory.instantiate(e)) ?? []
            }
        })

    }

}

export default CreateCheckListUseCase.execute