import { UseCase } from "~/domain/core/UseCase";
import { Pedidos } from '~/services/almacen/pedidos';
import { PedidoInternoMaterialFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoMaterialFactory';
import { $try } from "~/services/global";

class EditEstadoMaterialPedidoUseCase extends UseCase {
    static async execute(routeParams, payload) {
        const data = await Pedidos.updateEstadoPedidoMaterial(routeParams, payload);
        return $try(() => {
          if (data?.data) return new PedidoInternoMaterialFactory.instantiate(data.data);
        })
    }
}

export default EditEstadoMaterialPedidoUseCase.execute;