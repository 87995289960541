import { Steps } from "../Steps";

export class StepsFactory {
    static instantiate(obj){
        return new Steps(obj)
    }

    static instantiateEmpty(){
        return new Steps({
            id: '',
            posicion: '',
            titulo: '',
            descripcion: '',
            id_materiales_mantenimientos_actividad: '',
        })
    }

    static instantiateWithAttachments(obj){
        return new Steps( obj, ['adjuntos'])
    }
}