import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { WarehouseFactory } from '~/domain/entities/compras/matriz/factories/WarehouseFactory'
import { $try } from '~/services/global'

class GetWarehousesUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data: { data } } =  await Matriz.getWarehouses(params)

        $try(() => {
            if(data.length){
                data.forEach((item) =>{
                    customData.push(
                        WarehouseFactory.instantiate(item))
                })
            }
        })

        return customData
    }
}

export default GetWarehousesUseCase.execute