import { UseCase } from "~/domain/core/UseCase"
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'
import { ResponsibleVOFactory } from '~/domain/value_objects/proveedores/responsables/factories/ResponsibleVOFactory'
class GetSelectResposibleUserUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectResponsables()
        return $try(() => {
            if(data?.data) return data.data.map(el => ResponsibleVOFactory.instantiate(el))
            return []
        })
    }

}

export default GetSelectResposibleUserUseCase.execute;