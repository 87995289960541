import { VigentesFilter } from "../vigentesFilter";

export class vigentesFilterFactory {
    static instantiate(obj) {
        return new VigentesFilter(obj);
    }

    static instantiateEmpty() {
        return new VigentesFilter({
            buscar: '',
            estado: null,
            fecha_inicio: null,
            fecha_fin: null
        });
    }

}