import { UseCase } from "../../core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { $try } from '~/services/global'

class PostReestablecerMantenimientosUseCase extends UseCase {
    static async execute(payload){
        const data = await Opportunities.postReestablecerMantenimientos(payload)
        return $try( () => data?.meesage ?? '')
    }
}

export default PostReestablecerMantenimientosUseCase.execute