import { UseCase } from "~/domain/core/UseCase";
import { VisitasService } from '~/services/obras/servicios/visitas'

class EditVisitUseCase extends UseCase {
    static async execute(params){
        const data = await VisitasService.editVisit(params)
        return data?.data
    }
}

export default EditVisitUseCase.execute