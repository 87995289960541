import { ClientVO } from "../ClientVO";

export class ClientVOFactory {
    static instantiate(obj){
        return new ClientVO(obj)
    }

    static instantiateEmpty(){
        return new ClientVO({
            id: '',
            nombre: '',
        })
    }
}