import { UseCase } from "~/domain/core/UseCase";
import { PurchaseOrder } from '~/services/compras/ordenes_compra'
import { PurchaseOrderMaterialFactory } from '~/domain/entities/compras/ordenes_compra/factories/PurchaseOrderMaterialFactory'
import { $try } from '~/services/global'

class GetPurchaseOrderMaterialsUseCase extends UseCase {
    static async execute({id}){
        const data = await PurchaseOrder.getPurchaseOrderMaterials({id})
        return $try(() => {
            if(data.data){
                return data.data.map(el => PurchaseOrderMaterialFactory.instantiate(el));
            }   
        });
    }
}

export default GetPurchaseOrderMaterialsUseCase.execute