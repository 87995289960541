import { UseCase } from "~/domain/core/UseCase";
import ViewInfoProvider from '~/services/proveedores/ver/ver'
import { $try } from '~/services/global'
import AWS from '~/services/aws'

class UploadProviderFileUseCase extends UseCase {
    static async execute(params){
        const { id_proveedor, archivo, nombre, tipo }  = params
        const fileKey = await AWS.uploadFile({file: archivo, path:`archivos/${id_proveedor}/`})
        
        const payload = {
            nombre,
            tipo,
            archivo: fileKey
        }
        const data = await ViewInfoProvider.subirArchivoProveedor({id_proveedor, payload})

        return $try(() => data?.data ?? [])
    }
}

export default UploadProviderFileUseCase.execute