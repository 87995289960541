import { UseCase } from "~/domain/core/UseCase";
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'


class DeleteConfigurationUseCase extends UseCase{
    static async execute(payload){
        const data = await CotizacionCliente.deleteEtapaConfiguracion(payload)
        return data?.message
    }
}

export default DeleteConfigurationUseCase.execute