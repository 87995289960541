import { Entity } from '~/domain/entities'

export class Bodega extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'idMaterial',
                'nombre',
                'pais',
                'estado_bodega',
                'estado',
                'ciudad',
                'direccion',
                'longitud',
                'latitud',
                'cantidad_materiales',
                'valor_materiales',
                'fecha_ultima_salida',
                'fecha_ultima_entrada',
                'bodegas_imagenes',
            ]
        })
    }
}