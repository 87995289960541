import { Filter } from '~/domain/core/Filter'

export class ServiceFilter extends Filter {
    constructor(obj){
        super({
            params: obj,
            keys: [ 
                'id_servicio',
                'fecha_inicio',
                'fecha_fin',
                'valor_min',
                'valor_max',
            ]
        })
    }
}