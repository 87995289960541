import { UseCase } from "~/domain/core/UseCase";
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'
import { CotizacionClienteFactory } from '~/domain/entities/cotizaciones/cotizaciones_clientes/factories/cotizacionClienteFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetQuotationClientUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CotizacionCliente.getCotizacion(payload)
        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    CotizacionClienteFactory.instantiate
                ).toTable();
            }
        })
    }
}

export default GetQuotationClientUseCase.execute