import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { AdditionalDataTypesPosicionFactory } from '~/domain/entities/configurar/almacen/datos_adicionales/factories/AdditionalDataTypesPosicionFactory'
import { $try } from '~/services/global'

class GetAdditionalDataTypesPosicionUseCase extends UseCase {
    static async execute(payload){
        const data =  await AdditionalData.getAdditionalDataTypesPosicion(payload)

        return $try(() => {
            return data?.data?.map(item => AdditionalDataTypesPosicionFactory.instantiate(item)) ?? []
        })
    }
}

export default GetAdditionalDataTypesPosicionUseCase.execute