import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { $try } from '~/services/global'

class GetDataFiltroConfiguracionUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getFiltroConfiguracion(payload)
        return $try( () => data?.data ?? [] )
    }
}

export default GetDataFiltroConfiguracionUseCase.execute