import { PedidoMaterialFilter } from "../PedidoMaterialFilter"

export class PedidoMaterialFilterFactory {
    static instantiate(obj){
        return new PedidoMaterialFilter(obj)
    }

    static instantiateEmpty(){
        const filter = new PedidoMaterialFilter({
            id_categoria: null,
            id_material: null,
            id_espesor: null,
            id_tipo_material: null,
            cantidad_min: 0,
            cantidad_max: 0,
            id_estado: null,
            id_proveedor: null
        })
        filter.setExceptions({
            cantidad_min: 0,
            cantidad_max: 0,
        })
        return filter
    }

    static instantiateEmptyWithRange(rangeCantidad){
        const cantidad_min = rangeCantidad?.min ?? 0;
        const cantidad_max = rangeCantidad?.max ?? 0;

        const filter = new PedidoMaterialFilter({
            id_categoria: null,
            id_material: null,
            id_espesor: null,
            id_tipo_material: null,
            cantidad_min,
            cantidad_max,
            id_estado: null,
            id_proveedor: null
        })
        filter.setExceptions({
            cantidad_min,
            cantidad_max,
        })
        return filter
    }
}