import Vue from 'vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timegridPlugin from '@fullcalendar/timegrid'
Vue.component('FullCalendar', FullCalendar);
Vue.component('dayGridPlugin', dayGridPlugin);
Vue.component('interactionPlugin', interactionPlugin);
Vue.component('timegridPlugin', timegridPlugin);

