import { UseCase } from "~/domain/core/UseCase";
import { PresenciaEquipos } from '~/services/ventas/presenciaEquipos'
import { CabeceraDetalleMaterialesFactory } from '~/domain/entities/ventas/factories/CabeceraDetalleMaterialesFactory'
import { $try } from '~/services/global'

class GetCabeceraDetalleMaterialesUseCase extends UseCase {
    static async execute(payload){
        const data = await PresenciaEquipos.getCabeceraDetalleMateriales(payload)
        return $try(() => {
            if(data?.data) return new CabeceraDetalleMaterialesFactory.instantiate(data.data)
        })
    }
}

export default GetCabeceraDetalleMaterialesUseCase.execute