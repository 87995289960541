import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { PaymentMethodFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/PaymentMethodFactory';
import { $try } from '~/services/global';

class GetPaymentMethodsUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso, id_orden_compra }) {
        const data = await PurchaseOrder.getPaymentMethods(id_proyecto, id_control_piso, id_orden_compra);
        
        return $try(() => {
            if(!data?.data) return [];
            return data.data.map(({valor, ...el}) => PaymentMethodFactory.instantiate({valor: parseFloat(valor),...el}));
        });
    }
}

export default GetPaymentMethodsUseCase.execute;