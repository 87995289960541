<template>
    <div  class="default-image d-flex flex-column justify-content-center align-items-center gap-1">
        <img :src="image" class="pointer-no-events">
        <span class="f-22 text-gray">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props:{
        text: { type: String, default: '' },
        image: { type: String, required: true },
    }
}
</script>