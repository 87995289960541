import { UseCase } from '~/domain/core/UseCase'
import { WorkshopCalendarService } from '~/services/configurar/obras/calendario_talleres'

class UpdatePositionActivities extends UseCase {
    static async execute(payload){
        await WorkshopCalendarService.changePositionCalendarTallerActivity(payload)
    }
}

export default UpdatePositionActivities.execute
