export const state = {
    routes: {
        url_origen: null,
        url_destino: null,
    }
}

export const getters = {
    routes: state => state.routes
}

export const mutations = {
    SET_ROUTES(state, payload){
        state.routes = payload
    }
}