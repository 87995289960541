import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'
import { GeneralCalendarFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/GeneralCalendarFactory'
import { $try } from '~/services/global'

class CreateGeneralCalendarUseCase extends UseCase {
    static async execute(params){
        const data = await GeneralCalendarService.createGeneralCalendar(params)
        return $try(() => {
            if(data?.data) return GeneralCalendarFactory.instantiate(data.data)
        })
    }
}

export default CreateGeneralCalendarUseCase.execute