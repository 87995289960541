import * as OrdenesComprasUseCase from '~/domain/use_cases/obras/ordenes_compras'
import { $try } from '~/services/global'


export const state = {
    listaOrdenCompra: {},
    buyList: {},
    inventario_material:[],
    proveedores_select:[]

}

export const getters = {
    listaOrdenCompra: state => state.listaOrdenCompra,
    buyList: state => state.buyList,
    inventario_material: state => state.inventario_material,
    proveedores_select: state => state.proveedores_select,

}

export const mutations = {
    set_lista_orden_compra(state, payload){
        state.listaOrdenCompra = payload
    },
    set_orden_compra(state, payload){
        state.buyList = payload
    },
    set_inventario_material(state, payload){
        state.inventario_material = payload
    },
    set_proveedores_select(state, payload){
        state.proveedores_select = payload
    }
}

export const actions = {
    async Action_get_lista_orden_compra({ commit }, payload){
        const data = await OrdenesComprasUseCase.GetOrdenesComprasUseCase(payload);
        if(data) {
            commit('set_lista_orden_compra', data);
        }
    },

    async Action_get_orden_compra({ commit }, payload){
        const data = await OrdenesComprasUseCase.GetOrdenCompraUseCase(payload);
        if(data) {
            commit('set_orden_compra', data);
        }
    },

    async Action_get_inventario_material({commit}, payload){
        $try( async () => {
            const data = await OrdenesComprasUseCase.GetInventarioMaterialUseCase(payload);
            if(data) {
                commit('set_inventario_material', data);
            }
        })
    },

    async Action_get_valor_materiales_proveedores({commit}, payload){
        try {
            const data = await OrdenesComprasUseCase.GetValorInventarioMaterialPvsUseCase(payload);
            if(data) {
                commit('set_proveedores_select', data);
            }
        } catch (error) {
            console.error(error)
        }
    },
}