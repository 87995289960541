import { CotizacionesPendientes } from "../CotizacionesPendientes";
import { CotizacionesRealizadas } from "../CotizacionesRealizadas";
import { CotizacionesVencidas } from "../CotizacionesVencidas";

export class CotizacionesFactory {
    static instantiatePendiente(obj){
        return new CotizacionesPendientes(obj)
    }
    static instantiateRealizadas(obj){
        return new CotizacionesRealizadas(obj)
    }
    static instantiateVencidas(obj){
        return new CotizacionesVencidas(obj)
    }
}