import Api from '~/services/api'

const ApiHandler = new Api()

const Services = {
    getServicesWorkshop(){
        return ApiHandler.get('configuracion/talleres/tipo-servicio')
    },
    createServicesWorkshop(payload){
        return ApiHandler.post('configuracion/talleres/tipo-servicio', payload)
    },
    updateServicesWorkshop(payload){
        return ApiHandler.put(`configuracion/talleres/tipo-servicio/${payload.id}`, payload)
    },
    updatePositionServicesWorkshop(payload){
        return ApiHandler.post(`configuracion/talleres/tipo-servicio/posicion`, payload)
    },
    deleteServicesWorkshop({id}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`configuracion/talleres/tipo-servicio/${id}`)
    }
}

export default Services