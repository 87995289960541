import { UseCase } from "~/domain/core/UseCase";
import { Cotizaciones } from '~/services/users_prividers/cotizacionesPendientes.js'
import { RangeCantValVerMaterialsFactory } from '~/domain/entities/users_providers/factories/RangeCantValVerMaterialsFactory.js'
import { $try } from '~/services/global'

class GetRangeCantValVerMaterialsUseCase extends UseCase {
    static async execute(payload){
        const data =  await Cotizaciones.getRangeCantValorVerMaterials(payload)
        return $try(() => {
            if (data?.data) {
                return RangeCantValVerMaterialsFactory.instantiate(data.data)
            }
        })
    }
}

export default GetRangeCantValVerMaterialsUseCase.execute