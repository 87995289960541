import { UseCase } from "~/domain/core/UseCase";
import { Compras } from '~/services/almacen/compras'
import { FiltroCompraFactory } from '~/domain/entities/almacen/materiales/compras/factories/FiltroCompraFactory'
import { $try } from '~/services/global'

class GetFiltroCompraUseCase extends UseCase{
    static async execute(id){
        const data = await Compras.getFiltrosCompras(id)
        return $try(() => {
            return data.data ? FiltroCompraFactory.instantiate(data.data): null
        })
    }
}

export default GetFiltroCompraUseCase.execute