import { Matriz } from "../Matriz";
import moment from 'moment'

export class MatrizFactory {
    static instantiate(obj){
        return new Matriz(obj)
    }

    static instantiateEmpty(){
        return new Matriz({
            fecha: moment().add(1,'days'),
            lugar: '',
            flete: '',
            id_moneda: '',
            oportunidad: null,
            descripcion: '',
            proveedores: [],
            bodegas: [],
        })
    }
}