import { GeneralCalendar } from "../GeneralCalendar";

export class GeneralCalendarFactory {
    static instantiate(obj){
        return new GeneralCalendar(obj)
    }

    static instantiateEmpty(){
        return new GeneralCalendar({
            id: '',
            nombre: '',
            actividades: [],
        })
    }
}