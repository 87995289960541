import { UseCase } from "~/domain/core/UseCase";
import { OrdenesCompras } from '~/services/obras/ordenes_compras/ordenesCompras'
import { OrdenCompraFactory } from '~/domain/entities/obras/ordenes_compra/factories/OrdenCompraFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetOrdenesComprasUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await OrdenesCompras.getListarOrdenesCompras(payload)
        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    OrdenCompraFactory.instantiate
                );
            }
        })
    }
}

export default GetOrdenesComprasUseCase.execute