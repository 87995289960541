import { FiltroCobrar } from "../FiltroCobrar";

export class FiltroCobrarFactory {
    static instantiate(obj){
        return new FiltroCobrar(obj)
    }

    static instantiateEmpty(){
        return new FiltroCobrar({
            proyectos: '',
            valor_min: '',
            valor_max: '',
            dias_vencimiento_min: '',
            dias_vencimiento_max: ''
        })
    }
}