import Api from '~/services/api'

const ApiHandler = new Api()

export const GeneralCalendarService = {
    getGeneralCalendars(){
        return ApiHandler.get(`/configuracion/obras/calendario-general`)
    },
    createGeneralCalendar(payload){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.post(`/configuracion/obras/calendario-general`, payload)
    },
    editGeneralCalendar({id, payload}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.put(`/configuracion/obras/calendario-general/${id}`, payload)
    },
    deleteGeneralCalendar({id}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`/configuracion/obras/calendario-general/${id}`)
    },

    createGeneralCalendarActivity(payload){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.post(`/configuracion/obras/calendario-general-actividades`, payload)
    },
    editGeneralCalendarActivity({id, payload}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.put(`/configuracion/obras/calendario-general-actividades/${id}`, payload)
    },
    deleteGeneralCalendarActivity({id_configuracion_calendarios_actividades: id}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`/configuracion/obras/calendario-general-actividades/${id}`)
    },

    createGeneralCalendarSubactivity(payload){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.post(`/configuracion/obras/calendario-general-actividades-actividades`, payload)
    },
    editGeneralCalendarSubactivity({id, payload}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.put(`/configuracion/obras/calendario-general-actividades-actividades/${id}`, payload)
    },
    deleteGeneralCalendarSubactivity({id}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`/configuracion/obras/calendario-general-actividades-actividades/${id}`)
    },
    changePositionCalendarActivityChildrens(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/calendario-general-actividades-actividades/posicion', payload)
    },
    changePositionCalendarActivity(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/calendario-actividad/posicion', payload)
    },

}