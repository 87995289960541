import * as Proveedores from '~/domain/use_cases/proveedores/cotizaciones'
import { $try } from '~/services/global'
import { MatsFilterFactory } from '../../../domain/filters/proveedores/cotizaciones/factories/MatsFilterFactory'

export const state = {
    detalleCotizacion: {},
    cotizacionesMateriales: {},
    query: '',
    filter: MatsFilterFactory.instantiateEmpty()
}

export const getters = {
    query: state => state.query,
    filter: state => state.filter,
    detalleCotizacion: state => state.detalleCotizacion,
    cotizacionesMateriales: state => state.cotizacionesMateriales
}

export const mutations = {
    set_filter(state, payload){
        state.filter = payload
    },
    set_query(state, payload){
        state.query = payload
    },
    set_detalle_cotizacion(state, payload){
        state.detalleCotizacion = payload
    },
    set_detalle_cotizacion_materiales(state, payload){
        state.cotizacionesMateriales = payload
    }
}

export const actions = {
    async Action_detalle_cotizacion({ commit }, payload){
        const { idProveedor, idCotizacion } = payload
        const data = await Proveedores.GetDetalleCotizacionUseCase(idProveedor, idCotizacion)
        $try(() => {
            commit('set_detalle_cotizacion', data)
        })
    },
    async Action_detalle_materiales_cotizacion({state, commit}, payload){
        const { idProveedor, idCotizacion, page } = payload
        const filter = state.filter.clean()
        const buscar = state.query || null
        const data = await Proveedores.GetDetalleCotizacionMatsUseCase(idProveedor, idCotizacion, {...filter, buscar, page})
        $try(() => {
            commit('set_detalle_cotizacion_materiales', data)
        })
    },
    clear_filter({commit}){
        commit('set_filter', MatsFilterFactory.instantiateEmpty())
    },
}