import { Entity } from "~/domain/entities";

export class Documentacion extends Entity {
  constructor(obj) {
    super({
      params: obj,
      keys: [
        "cantidad_archivos",
        "id",
        "id_control_piso",
        "nombre",
      ],
    });
  }
}
