import { UseCase } from "~/domain/core/UseCase";
import { PedidosInternos } from '~/services/configurar/almacen/pedidos_internos.js'
import { EstadoPedidosInternosFactory } from '~/domain/entities/configurar/almacen/pedidos_internos/factories/estadoPedidosInternosFactory.js'
import { $try } from '~/services/global'

class GetEstadosPedidosInternosUseCase extends UseCase {
    static async execute(payload){
        const data = await PedidosInternos.getEstadosPedidosInternos(payload)      
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new EstadoPedidosInternosFactory.instantiate(e)) 
        })
    }
}

export default GetEstadosPedidosInternosUseCase.execute