import Api from '~/services/api'

export const Equipos = {
    getEquipos(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/equipos`, payload)
    },
    createEquipos(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`almacen/equipos`, payload)
    },
    getDetalleEquipo(id_equipos_modelos){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/equipos/${id_equipos_modelos}`)
    },
    editEquipo(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`almacen/equipos/${params.id}`, params.payload)
    },
    deleteEquipo(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`almacen/equipos/${payload}`)
    },
    guardarConfigEquipo(params){
        const ApiHandler = new Api({notification: true})
        // return ApiHandler.post(`almacen/equipos/${params.idEquipo}/annadir-configuracion`, params.payload)
        return ApiHandler.post(`etapas-configuraciones/${params.idEquipo}`, params.payload)
    },
    getEtapasConfiguraciones(payload){
        const ApiHandler = new Api({notification: false})
        return ApiHandler.get(`etapas-configuraciones`, payload)
    },
    getDetalleCabecera(payload){
        const ApiHandler = new Api({notification: false})
        return ApiHandler.get(`etapas-configuraciones/cabecera/${payload}`)
    }
}