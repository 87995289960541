import { UseCase } from "~/domain/core/UseCase";
import { HoursMan } from "~/services/configurar/almacen/horas_hombre"
import { HoursManFactory } from "~/domain/entities/configurar/almacen/horas_hombre/factories/HoursManFactory"
import { $try } from '~/services/global'

class CreateHoursManUseCase extends UseCase {
    static async execute(params){
        const { data } = await HoursMan.createHoursManValue(params)

        return $try( 
            () => HoursManFactory.instantiate(data)
        )
    }
}

export default CreateHoursManUseCase.execute