import { Entity } from '~/domain/entities'

export class CotizacionesMateriales extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
				'nombre',
				'cantidad',
				'tipo',
				'unidad_medida',
				'valor_unidad',
				'valor_parcial',
				'dias_entrega',
				'garantia'
            ]
        });
    }
}