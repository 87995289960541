import Api from '~/services/api'

export const incrementos = {
    getIncrementos(){
        const Service = new Api()
        return Service.get('/configuraciones/almacen/incrementos-porcentuales')
    },
    createIncrementos(payload){
        const Service = new Api({notification: true})
        return Service.post('/configuraciones/almacen/incrementos-porcentuales', payload)
    },
    addPosIncrementos(payload){
        const Service = new Api({notification: true})
        return Service.post('/configuraciones/almacen/incrementos-porcentuales/posicion', payload)
    },
    
    editIncrementos(payload){
        const Service = new Api({notification: true})
        return Service.put(`/configuraciones/almacen/incrementos-porcentuales/${payload.id}`, payload)
    },
    deleteIncrementos(id){
        const Service = new Api({notification: true})
        return Service.delete(`/configuraciones/almacen/incrementos-porcentuales/${id}`)
    },
}
