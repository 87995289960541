import {$try} from '~/services/global'
import * as CotizacionEquipos from '~/domain/use_cases/ventas'
import {PresenciaEquiposFilterFactory} from '~/domain/filters/ventas/factory/PresenciaEquiposFilterFactory'
import {FilterMaterialesFactory} from '~/domain/filters/ventas/factory/FilterMaterialesFactory'


export const state = {
    presenciaEquipos: [],
    listaEquipos: [],
    listaClientes:[],
    listaRangos:{},
    filter:PresenciaEquiposFilterFactory.instantiateEmpty(),
    detalleEquipo:{},
    materiales:[],
    cabeceraDetalle:{},
    filtroMateriales:{},
    filterMateriales:FilterMaterialesFactory.instantiateEmpty(),
    tabla:''


}

export const getters = {
    presenciaEquipos: state => state.presenciaEquipos,
    listaEquipos: state => state.listaEquipos,
    listaClientes: state => state.listaClientes,
    listaRangos: state => state.listaRangos,
    filter: state => state.filter,
    detalleEquipo: state => state.detalleEquipo,
    materiales: state => state.materiales,
    cabeceraDetalle: state => state.cabeceraDetalle,
    filtroMateriales: state => state.filtroMateriales,
    filterMateriales: state => state.filterMateriales,
    tabla: state => state.tabla,
  
}

export const mutations = {
    set_presencia_equipos(state, data){
        state.presenciaEquipos = data
    },
    set_listar_equipos(state, data){
        state.listaEquipos = data
    },
    set_listar_clientes(state, data){
        state.listaClientes = data
    },
    set_listar_rangos(state, data){
        state.listaRangos = data
    },
    set_filter(state, data){
        state.filter = data
    },
    set_detalle_equipo(state, data){
        state.detalleEquipo = data
    },
    set_materiales(state, data){
        state.materiales = data
    },
    set_cabecera_detalle(state, data){
        state.cabeceraDetalle = data
    },
    set_filtro_materiales(state, data){
        state.filtroMateriales = data
    },
    set_filter_materiales(state, data){
        state.filterMateriales = data
    },
    set_tabla(state, data){
        state.tabla = data
    },
}


export const actions = {
    async Action_get_presencia_equipos({commit}, params){
        const payload = {
            ...state.filter.clean(),
            ...params
        }
        const data = await CotizacionEquipos.GetPresenciaEquiposUseCase(payload)
        $try(() => {
            commit('set_presencia_equipos', data)
        })
    },
    async Action_get_equipos({state, commit}){
        const data = await CotizacionEquipos.GetEquiposUseCase()
        $try(() => {
            commit('set_listar_equipos', data)
        })
    },
    async Action_get_clientes({state, commit}){
        const data = await CotizacionEquipos.GetClientesUseCase()
        $try(() => {
            commit('set_listar_clientes', data)
        })
    },
    async Action_get_rangos({state, commit}){
        const data = await CotizacionEquipos.GetRangosUseCase()
        $try(() => {
            commit('set_listar_rangos', data)
        })
    },
    Action_clean_filter({commit}){
        $try(() => {
            commit('set_filter', PresenciaEquiposFilterFactory.instantiateEmptyWithRange(state.listaRangos))
        })
    },
    async Action_get_detalle_equipo_vendido({commit}, payload){
        const data = await CotizacionEquipos.GetDetalleEquipoVendidoUseCase(payload)
        $try(() => {
            commit('set_detalle_equipo', data)
            commit('set_tabla', data?.tabla ?? '')
        })
    },
    async Action_get_materiales({commit}, payload){
        const params = {
            ...payload, ...state.filterMateriales.clean()
        }
        const data = await CotizacionEquipos.GetMaterialesConfiguracionUseCase(params)
        $try(() => {
            commit('set_materiales', data)
        })
    },
    async Action_get_cabecera_detalle_materiales({commit}, payload){
        const data = await CotizacionEquipos.GetCabeceraDetalleMaterialesUseCase(payload)
        $try(() => {
            commit('set_cabecera_detalle', data)
        })
    },
    async Action_get_filtro_materiales({commit}, payload){
        const data = await CotizacionEquipos.GetFiltroMaterialesUseCase(payload)
        $try(() => {
            commit('set_filtro_materiales', data)
        })
    },
    Action_clean_filter_materiales({commit}){
        $try(() => {
            commit('set_filter_materiales', FilterMaterialesFactory.instantiateEmptyWithRange(state.filtroMateriales))
        })
    },
    

}