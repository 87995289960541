import { UseCase } from "~/domain/core/UseCase";
import { Selects } from "~/services/selects/selects";
import { $try } from '~/services/global'

class GetActividadesPredecesorasUseCase extends UseCase {
    static async execute(payload) {
        const data = await Selects.GetSelectActividadPredecesora(payload)
        return $try(() => data?.data ?? [] )
    }
}

export default GetActividadesPredecesorasUseCase.execute