import { Entity } from '~/domain/entities'

export class ListaClientes extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'cliente'
            ]
        });
    }
}