
import { Entity } from '~/domain/entities'

export class MaintenanceList extends Entity{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'nombre',
                'id',
                'referencia',
                'id_tipo_material',
                'tipo_material',
                'id_unidad_medida',
                'unidad_medida',
                'valor',
                'numero_planes_mantenimiento',
            ]
        })
    }
}