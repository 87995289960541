import * as EquiposDomain from '~/domain/use_cases/obras/equipos'
import * as InformacionDomain from '~/domain/use_cases/obras/informacion_header'

import { $try } from '~/services/global'

export const state = {
    informacion: [],
    informacionFS: {},
    historyFS: {},
    equipos:[],
    ops:[],
    etapas:[],
    producto:{},
    addEtapa:{},
}

export const getters = {
    informacion: state => state.informacion,
    informacionFS: state => state.informacionFS,
    historyFS: state => state.historyFS,
    equipos: state => state.equipos,
    ops: state => state.ops,
    etapas: state => state.etapas,
    producto: state => state.producto,
    addEtapa: state => state.addEtapa,
}

export const mutations = {
    setInformacion(state, payload){
        state.informacion = payload
    },
    setInformacionFS(state, payload){
        state.informacionFS = payload
    },
    setHistoryFS(state, payload){
        state.historyFS = payload
    },
    setEquipos(state, payload){
        state.equipos = payload
    },
    setOps(state, payload){
        state.ops = payload
    },
    setEtapas(state, payload){
        state.etapas = payload
    },
    setProducto(state, payload){
        state.producto = payload
    },
    setAddEtapa(state, payload){
        state.addEtapa = payload
    }

}

export const actions = {
    async Action_get_informacion_header({commit}, payload){
        const data = await InformacionDomain.GetInformacionHeaderUseCase(payload)
        $try ( () => { commit('setInformacion', data) } )
    },

    async Action_get_informacion_header_FS({commit}, params){
        const data = await InformacionDomain.GetInformacionHeaderFSUseCase(params)
        $try ( () => { commit('setInformacionFS', data) } )
    },

    async Action_get_history_header_FS({commit}, params){
        const data = await InformacionDomain.GetHistoryHeaderFSUseCase(params)
        $try ( () => { commit('setHistoryFS', data) } )
    },

    async Action_post_edit_duration_FS(_, payload){
        await InformacionDomain.PostEditDurationUseCase(payload)
    },

    async Action_get_informacion_equipos({commit}, payload){
        const data = await EquiposDomain.GetInformacionEquiposUseCase(payload)
        console.log("🚀 ~ file: informacion.js:78 ~ Action_get_informacion_equipos ~ data", data)
        $try ( () => { commit('setEquipos', data) } )
    },

    async Action_get_informacion_ops({commit}, payload){
        const data = await EquiposDomain.GetInfoEquipoOpsUseCase(payload)
        $try ( () => { commit('setOps', data) } )
    },

    async Action_post_agregar_equipo({commit}, payload){
        await EquiposDomain.AgregarEquipoProyectoUseCase(payload)
    },

    async Action_get_etapas({commit}, payload){
        const data = await EquiposDomain.GetListarEtapasUseCase(payload)
        $try ( () => { commit('setEtapas', data) } )
    },

    async Action_post_add_etapas({commit}, payload){
        const data = await EquiposDomain.AddEtapasUseCase(payload)
        $try ( () => { commit('setEtapas', data) } )
    },

    async Action_put_edit_etapas({commit}, payload){
        const data = await EquiposDomain.EditEtapasUseCase(payload)
        $try ( () => { 
            if (data) {
                let idx = -1
                state.producto.etapas.forEach((el, index) => {
                    if (el.id_etapa == data[0].id_etapa) {
                        idx = index
                    }
                })
                let product = JSON.parse(JSON.stringify(state.producto))
                product.etapas[idx] = data[0]
                commit('setProducto', product)
            }
         } )
    },

    async Action_eliminar_etapa({commit}, payload){
        const data = await EquiposDomain.DeleteEtapasUseCase(payload)
        $try ( () => { commit('setAddEtapa', data) } )
    },

    async Action_eliminar_equipo({commit}, payload){
        const data = await EquiposDomain.DeleteEquipoUseCase(payload)
        $try ( () => { commit('setAddEtapa', data) } )
    },

}