

import Api from '~/services/api'

export const PurchaseOrder = {
    getPaymentMethodFees(id_proyecto, id_control_piso) {
        const Service = new Api()         
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/forma-pago/cuotas`)
    },
    getPaymentMethodResume(id_proyecto, id_control_piso) {
        const Service = new Api()         
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/forma-pago/resumen`)
    },
    savePaymentFee(id_proyecto, id_control_piso, payload){
        const Service = new Api({ notification: true })
        return Service.post(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/forma-pago/cuotas`, { cuotas: payload })
    },
    reportPayment(id_proyecto, id_control_piso, payload){
        const Service = new Api({ notification: true })
        return Service.post(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/forma-pago/reportar-pago`, payload)
    },
    finishPayment(id_proyecto, id_control_piso){
        const Service = new Api({ notification: true })
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/forma-pago/finalizar-pago`)
    }
}