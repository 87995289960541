import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { EquiposFactory } from '~/domain/entities/oportunidades/information/factories/EquiposFactory'
import { $try } from '~/services/global'

class AddOpportunityEquipoUseCase extends UseCase {
    static async execute(params){
        const data =  await Opportunities.addEquiposOpportunity(params)
        return $try(() => data?.data ? EquiposFactory.instantiate(data.data) : {} )
    }
}

export default AddOpportunityEquipoUseCase.execute