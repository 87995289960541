import Api from '~/services/api'

export const PurchaseOrder = {
    getPurchaseOrders(id_proyecto, id_control_piso) {
        const Service = new Api()
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra`)
    },
    getPaymentMethods(id_proyecto, id_control_piso, id_orden_compra) {
        const Service = new Api()
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/cuotas`)
    },
    savePaymentFee(id_proyecto, id_control_piso, id_orden_compra, payload){
        const Service = new Api({ notification: true })
        return Service.post(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/cuotas`, { cuotas: payload })
    },
    getPurchaseOrderInfo(id_proyecto, id_control_piso, id_orden_compra, filter) {
        const Service = new Api()
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}`, filter)
    },
    sendPurchaseOrder(id_proyecto, id_control_piso, id_orden_compra) {
        const Service = new Api({ notification: true })
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/enviar-orden`)
    },
    getPurchaseOrderBills(id_proyecto, id_control_piso, id_orden_compra) {
        const Service = new Api()
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/facturas`)
    },
    savePurchaseOrderBill(id_proyecto, id_control_piso, id_orden_compra, payload) {
        const Service = new Api({ notification: true })
        return Service.post(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/facturas`, payload)
    },
    updatePurchaseOrderBill(id_proyecto, id_control_piso, id_orden_compra, id_ordenes_compras_facturas, payload) {
        const Service = new Api({ notification: true })
        return Service.put(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/facturas/${id_ordenes_compras_facturas}`, payload)
    },
    deletePurchaseOrderBill(id_proyecto, id_control_piso, id_orden_compra, id_ordenes_compras_facturas) {
        const Service = new Api({ notification: true })
        return Service.delete(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/facturas/${id_ordenes_compras_facturas}`)
    },
    reportPurchaseOrderPayment(id_proyecto, id_control_piso, id_orden_compra, payload) {
        const Service = new Api({ notification: true })
        return Service.post(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/pagos`, payload)
    },
    finishPurchaseOrderPayment(id_proyecto, id_control_piso, id_orden_compra) {
        const Service = new Api({ notification: true })
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/finalizar-pago`)
    },
    deliverPurchaseOrder(id_proyecto, id_control_piso, id_orden_compra) {
        const Service = new Api({ notification: true })
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/entrega-completa`)
    },
    getPurchaseOrderMaterialsRange(id_proyecto, id_control_piso, id_orden_compra) {
        const Service = new Api()
        return Service.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/orden-compra/${id_orden_compra}/rangos-unidades`)
    }
}