import { Filter } from '~/domain/core/Filter';

export class OpportunityFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'fecha_cierre_inicio',
                'fecha_cierre_fin',
                'probabilidad_minima',
                'probabilidad_maxima',
                'valor_minimo',
                'valor_maximo',
                'ciclos',
                'tipos',
                'responsables',
                'criterios',
            ]
        });
    }
}