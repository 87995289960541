import Api from '~/services/api'


export const PedidosInternosOcs = {
    getPedidosInternosOcs(payload){
        const pedidosInternos = new Api()
        return pedidosInternos.get('almacen/pedidos-internos/ordenes-compras', payload)
    },
    getSelectPedidosInternos(payload){
        const pedidosInternos = new Api()
        return pedidosInternos.get('select/pedidos-internos')
    },    
    getSelectPedidosInternosMateriales(payload){
        const pedidosInternos = new Api()
        return pedidosInternos.get('select/pedidos-internos-materiales', payload)
    },
    getInformacionOcs(payload){
        const pedidosInternos = new Api()
        return pedidosInternos.get(`almacen/pedidos-internos/ordenes-compras/${payload}`)
    },
    getMateriales(params){
        const pedidosInternos = new Api()
        return pedidosInternos.get(`almacen/pedidos-internos/ordenes-compras/${params.id}/materiales`, params.payload)
    },
    getFiltroOrdenesCompra(){
        const pedidosInternos = new Api()
        return pedidosInternos.get(`almacen/pedidos-internos/ordenes-compras/filtro/ocs`)
    },
    getFiltrDetalleoOrdenesCompra(payload){
        const pedidosInternos = new Api()
        return pedidosInternos.get(`almacen/pedidos-internos/ordenes-compras/${payload}/materiales/filtros/ocs`)
    },
    postMaterialesPedidosInternos(payload){
        const pedidosInternos = new Api()
        return pedidosInternos.post(`almacen/pedidos-internos/ordenes-compras`, payload)
    },
    getInformacionDetalleOcs(payload){
        const pedidosInternos = new Api()
        return pedidosInternos.get(`almacen/pedidos-internos/ordenes-compras/informacion-detalle/ocs`, payload)
    },
    deleteFacturaOrdenCompra({id}, payload){
        const pedidosInternos = new Api({notification: true})
        return pedidosInternos.delete(`almacen/pedidos-internos/ordenes-compras/${id}/eliminar-factura`, payload)
    },
    reportarPago({id}, payload){
        const pedidosInternos = new Api({notification: true})
        return pedidosInternos.post(`almacen/pedidos-internos/ordenes-compras/${id}/reportar-pago`, payload)
    },
    finalizarPago(id){
        const pedidosInternos = new Api({notification: true})
        return pedidosInternos.get(`almacen/pedidos-internos/ordenes-compras/${id}/finalizar-pago`)
    },
    entregaCompleta(id){
        const pedidosInternos = new Api()
        return pedidosInternos.get(`almacen/pedidos-internos/ordenes-compras/${id}/entrega-completa`)
    },
    asociarFactura(payload){
        const pedidosInternos = new Api({notification: true})
        return pedidosInternos.post(`almacen/pedidos-internos/ordenes-compras/asociar-factura`, payload)
    }
}