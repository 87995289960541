import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'

class DeleteGeneralCalendarUseCase extends UseCase {
    static async execute(id){
        const data =await GeneralCalendarService.deleteGeneralCalendar(id)
        return data?.message
    }
}

export default DeleteGeneralCalendarUseCase.execute