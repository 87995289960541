import { UseCase } from "~/domain/core/UseCase";
import { Pedidos } from '~/services/almacen/pedidos';
import { PedidoInternoMaterialFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoMaterialFactory';
import { $try } from "~/services/global";

class AddMaterialPedidoInternoUseCase extends UseCase {
  static async execute({id, payload}) {
        const data = await Pedidos.addMaterialPedidoInterno(id, payload);
        return $try(() => {
            if (data?.data) return new PedidoInternoMaterialFactory.instantiate(data.data);
        })
  }
}

export default AddMaterialPedidoInternoUseCase.execute;
