import { Entity } from '~/domain/entities'

export class DetalleTarea extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'nombre_mantenimiento',
                'descripcion',
                'created_by',
                'actividad',
                'fecha',
                'user',
                'created_at'
            ]
        })
    }
}