import * as ServiceCalification from '~/domain/use_cases/configurar/obras/calificacion_servicio'


import { $try } from '~/services/global'
// state
export const state = {
    questionsList: [],
    questionsOptionsList: [],
    questionsWorkShopList: [],
}

// getters
export const getters = {
    getQuestionList: state => state.questionsList,
    getQuestionOptionsList: state => state.questionsOptionsList,
    getQuestionsWorkShopList: state => state.questionsWorkShopList,
}

// mutations
export const mutations = {
    set_questions_list(state, payload) {
        state.questionsList = payload
    },
   
    set_options_questions_list(state, payload) {
        state.questionsOptionsList = payload
    },
    set_questions_workshop_list(state, payload) {
        state.questionsWorkShopList = payload
    },
}

// actions
export const actions = {
   
    async Action_list_questions({ commit }) {
        const list = await ServiceCalification.GetQuestionsUseCase()
        if (list) commit('set_questions_list', list)
    },
    async Action_create_question({ state, commit }, payload) {
        const question = await ServiceCalification.CreatePreguntaUseCase(payload)
        if (question) commit('set_questions_list', [...state.questionsList, question])
    },
    async Action_delete_question({ state, commit }, id) {
        const data = await ServiceCalification.DeleteQuestionUseCase(id)
        if (data) commit('set_questions_list', state.questionsList.filter(el => el.id != id))
    },
    async Action_edit_question({ commit }, payload) {
        const question = await ServiceCalification.EditQuestionsUseCase(payload)
        if (question) commit('set_questions_list', state.questionsList.map(el => el.id === question.id ? question : el))
    },
    async Action_create_option_question({ state, commit }, payload) {
        const optionQuestion = await ServiceCalification.CreateOptionQuestionsUseCase(payload)
        if (optionQuestion) commit('set_options_questions_list', [...state.questionsOptionsList, optionQuestion])
    },
    async Action_list_options_questions({ commit }, payload) {
        const options = await ServiceCalification.GetOptionsQuestionUseCase(payload)
        if (options) commit('set_options_questions_list', options)
    },
    async Action_delete_option_question({ state, commit }, id) {
        const data = await ServiceCalification.DeleteQuestionOptionsUseCase(id)
        if (data) commit('set_options_questions_list', state.questionsOptionsList.filter(el => el.id != id.id_configuracion_control_piso_aspectos_calificar_opciones))
    },
    async Action_list_questions_workshops({ commit }, payload) {
        const data = await ServiceCalification.GetQuestionWorkShopUseCase(payload)
        if (data) commit('set_questions_workshop_list', data)
    },
    async Action_question_workshop({ state, commit }, payload) {
        const question = await ServiceCalification.AddQuestionToWorkShopUseCase(payload)
        //if (question) commit('set_questions_list', [...state.questionsList, question])
    },
    async Action_workshop_question({ state, commit }, payload) {
        const question = await ServiceCalification.AddWorkShopToQuestionUseCase(payload)
        //if (question) commit('set_questions_list', [...state.questionsList, question])
    },
    async Action_update_pos_questions({ commit }, payload) {
        await ServiceCalification.AddPosQuestionUseCase(payload)
        $try(
            () => commit('set_questions_list', state.questionsList)
        ) 
    },
    async Action_update_pos_options({ commit }, payload) {
        await ServiceCalification.AddPosOptionUseCase(payload)
        $try(
            () => commit('set_options_questions_list', state.questionsOptionsList)
        ) 
    }
}