import store from '~/store'
import { notify } from '~/services/helper'

export default async (to, from, next) => {
    const excludeAuth = ['login', 'recover', 'sended.username', 'validate.code', 'validate.password', 'redirect.login.iaf', 'logout.smart.iaf'];

    if(excludeAuth.includes(to.name)) return next();
    const subdomain = window.location.hostname.split('.').shift()

    const token = store.getters['auth/token'];

    if(!token && ['proveedores', 'proveedores-prueba'].includes(subdomain)) return next({name: 'login'});
    else if (!token) return next({name: 'logout.smart.iaf'});

    await Promise.all([
        store.dispatch('auth/FETCH_USER'),
        store.dispatch('personalizacion/Action_get_personalizacion'),
        store.dispatch('formato_moneda/Action_get_formato_moneda'),
    ])

    //Access validation to: ocs process

    const OBSERVED_ROUTE_NAMES_OCS = ['compras.crear.matriz.proveedores', 'compras.crear.matriz.bodegas', 'compras.crear.matriz.etapa.configurar']

    if(OBSERVED_ROUTE_NAMES_OCS.find((name) => to.name === name)){
        const orden_compra = JSON.parse(localStorage.getItem('orden_compra')) ?? []

        if(!Object.keys(orden_compra).length){
            notify({title: 'Procedimiento invalido', type: 'error'})
            return next({name: 'compras.matrix'})
        }
    }

    //Access validation to: ops details

    // const OBSERVED_ROUTE_NAMES_OPS = [
    //     'oportunidades.ver.informacion',
    //     'oportunidades.ver.productos',
    //     'oportunidades.ver.cotizaciones',
    //     'oportunidades.ver.bitacora.calendario',
    //     'oportunidades.ver.bitacora.mes',
    //     'oportunidades.ver.bitacora.lista',
    //     'oportunidades.ver.bitacora.semana',
    // ]

    // if(OBSERVED_ROUTE_NAMES_OPS.find((name) => to.name === name)){
    //     const oportunidad = JSON.parse(localStorage.getItem('oportunidad')) ?? []

    //     if(!Object.keys(oportunidad).length){
    //         notify({title: 'Procedimiento invalido', type: 'error'})
    //         return next({name: 'oportunidades.listado.vigentes'})
    //     }
    // }


    return next();
}