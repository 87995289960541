import { UseCase } from "~/domain/core/UseCase";
import { Bills } from '~/services/compras/facturas'
import { FacturaFactory } from '~/domain/entities/compras/facturas/factories/FacturaFactory'
import { $try } from '~/services/global'

class GetFacturaUseCase extends UseCase {
    static async execute(params){
        const data  =  await Bills.getFacturas(params)
        return $try(() => {
            if (data?.data) {
              return FacturaFactory.instantiate(data.data)
            }
          });
    }
}

export default GetFacturaUseCase.execute