import { UseCase } from "~/domain/core/UseCase";
import { PurchaseOrder } from '~/services/compras/ordenes_compra'
import { PurchaseOrderInfoFactory } from '~/domain/entities/compras/ordenes_compra/factories/PurchaseOrderInfoFactory'
import { $try } from '~/services/global'

class SendPurchaseOrderUseCase extends UseCase {
    static async execute({ id }) {
        const data = await PurchaseOrder.sendPurchaseOrder({ id })
        return $try(() => {
            if (data?.data) {
                return PurchaseOrderInfoFactory.instantiate(data.data);
            }
        });
    }
}

export default SendPurchaseOrderUseCase.execute