import { UseCase } from "~/domain/core/UseCase";
import unitMeasure from "~/services/configurar/almacen/measure_unit"

class CreateUnitMeasureTypeUseCase extends UseCase {
    static async execute(params){
        const data = await unitMeasure.createUnitMeasure(params)
        return data?.data
    }
}

export default CreateUnitMeasureTypeUseCase.execute