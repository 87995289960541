import Api from '~/services/api'

const ApiHandler = new Api()

export const WorkshopCalendarService = {
    createWorkshopCalendar(payload){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post(`configuracion/obras/calendario-talleres`, payload)
    },
    editWorkshopCalendar({id, payload}){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.put(`configuracion/obras/calendario-talleres/${id}`, payload)
    },
    deleteWorkshopCalendar({id}){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.delete(`configuracion/obras/calendario-talleres/${id}`)
    },
    getWorkshopCalendar(){
        return ApiHandler.get(`configuracion/obras/calendario-talleres`)
    },
    

    createWorkshopCalendarActivity(payload){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post(`configuracion/obras/calendario-talleres-actividades`, payload)
    },
    editWorkshopCalendarActivity({id, payload}){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.put(`configuracion/obras/calendario-talleres-actividades/${id}`, payload)
    },
    deleteWorkshopCalendarActivity({id}){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.delete(`configuracion/obras/calendario-talleres-actividades/${id}`)
    },
    changePositionCalendarActivity(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/calendario-talleres-actividades/posicion', payload)
    },
    changePositionCalendarTallerActivity(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/calendario-general/posicion-grupo', payload)
    },
}