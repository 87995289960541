import store from '~/store';

export default (to, from, next) => {
	const excludeAuth = ['login', 'recover', 'sended.username', 'validate.code', 'validate.password', 'redirect.login.iaf', 'logout.smart.iaf', 'home'];
	if(excludeAuth.includes(to.name)) return next();

	const user = store.getters['auth/user'];
	const ruta = process.env.VUE_APP_URL_FULL_SMART;
	const permisos = user.permisos.rutas.map(el => el.name_ruta);
	
	// Si el user es proveedor debe pasar a proveedores
	if (user.tipo == 4) {
		if (to.name.startsWith('proveedores.usuarios')) return next();
		to.name = "proveedores.usuarios";
		return next();
	}

	if(!user.super_admin && !permisos.includes(to.name)) window.location.href = `${ruta}/sinpermiso`

	next();
}
