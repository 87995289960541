import Api from '~/services/api'


const RolesAreasList = {
    listRolesAreas(){
        const Service = new Api()
        return Service.get('/configuracion/permisos/listar-roles-menus')
    }
}

export default RolesAreasList