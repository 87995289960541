import { UseCase } from "~/domain/core/UseCase";
import { Bills } from '~/services/compras/facturas'
import { ClientesVOFactory } from '~/domain/value_objects/compras/facturas/factories/ClientesVOFactory'
import { $try } from '~/services/global'

class GetFilterOportunidadUseCase extends UseCase {
    static async execute(){
        let customData = []
        const { data } =  await Bills.getFilterClientes()


        $try(() => {

            if(data.length){
                data.forEach((item) =>{
                    customData.push(ClientesVOFactory.instantiate(item))
                })
            }

        })

        return customData
    }
}

export default GetFilterOportunidadUseCase.execute