import { UseCase } from "~/domain/core/UseCase"
import { PedidosInternosOcs } from '~/services/almacen/pedidos_internos_ocs'
import { MaterialesFactory } from '~/domain/value_objects/almacen/pedidos/factories/MaterialesFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetMaterialesUseCase extends UseCase {
    static async execute(payload){
        const data = await PedidosInternosOcs.getMateriales(payload)
        return $try(() => {
            if(data?.data) 
                return new PaginationDecorator(data.data, MaterialesFactory.instantiate).toTable();
        })
    }
}

export default GetMaterialesUseCase.execute