import { UseCase } from "~/domain/core/UseCase"
import { Calendario } from "~/services/calendario/calendario"
import { DetalleTareaFactory } from "~/domain/entities/calendario/factories/DetalleTareaFactory"
import { $try } from "~/services/global"

class GetDetalleTareaUseCase extends UseCase{
    static async execute(payload){
        const data = await Calendario.getDetalleTarea(payload)
        return $try(() => {
            return $try( () => data?.data ? DetalleTareaFactory.instantiate(data.data) : {})

        })
    }
}

export default GetDetalleTareaUseCase.execute