import { ValueObject } from '~/domain/core/ValueObject'

export class ActividadLista extends ValueObject {
    constructor(obj){
        super({
            params: obj, 
            keys: [
                "id",
                "actividad",
                "peso",
                "id_lista_chequeo"
            ]
        });
    }
}