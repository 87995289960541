import { ProviderCotizaciones } from "../ProviderCotizaciones";

export class ProviderCotizacionesFactory {
    static instantiate(obj){
        return new ProviderCotizaciones(obj)
    }

    static instantiateEmpty(){
        return new ProviderCotizaciones({
            id: undefined,
            dias_credito: '',
            id_estado: '',
            fecha_cotizacion: '',
            materiales_cotizados: '',
            oportunidad: '',
            referencia: '',
            valor: ''
        })
    }
}