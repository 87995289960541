import { UseCase } from "~/domain/core/UseCase";
import { Providers } from '~/services/proveedores/proveedores'
import { PaginationDecorator } from '../../decorators/PaginationDecorator'
import { ProviderFactory } from '../../entities/proveedores/factories/ProviderFactory'
import { $try } from '~/services/global'

class GetProviderListUseCase extends UseCase {
    static async execute(params){
        const data = await Providers.getProviders(params)
        return $try(() => {
            if(data?.data){
                return new PaginationDecorator(
                    data.data,
                    ProviderFactory.instantiate
                ).toTable();
            }
        })
    }
}

export default GetProviderListUseCase.execute
