import { Entity } from '~/domain/entities'

export class CabeceraStock extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'total_salidas',
				'total_entradas',
				'valor_salidas',
				'valor_entradas',
                'valor_total'
            ]
        })
    }
}