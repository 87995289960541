import { UseCase } from "~/domain/core/UseCase";
import MaterialTypes from "~/services/configurar/almacen/tipos_material"
import { $try } from '~/services/global'

class GetMaterialTypesUseCase extends UseCase {
    static async execute(params){
        const data = await MaterialTypes.listMaterialTypes()

        return $try(() => {
            return data?.data?.map(d => ({...d, edit: false})) ?? []
        })
    }
}

export default GetMaterialTypesUseCase.execute