import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'
import { CalificacionFactory } from '~/domain/entities/configurar/obras/calificacion_servicio/factories/calificacionFactory'
import { $try } from '~/services/global'

class GetQuestionWorkShopUseCase extends UseCase {
    static async execute(params){
        const data = await CalificacionService.getQuestionsWorkShops(params)
        return data.data
    }
}

export default GetQuestionWorkShopUseCase.execute