import { UseCase } from "~/domain/core/UseCase"
import Auth from "~/services/auth/auth"
import { $try } from '~/services/global'

class ChangePasswordUseCase extends UseCase {
    static async execute(params){
        const data = await Auth.changePassword(params)
        return $try(() => {
            return data
        })
    }
}

export default ChangePasswordUseCase.execute
