import { Product } from "../Product";

export class ProductFactory {
    static instantiate(obj){
        return new Product(obj)
    }

    static instantiateEmpty(){
        return Product({
            id: '',
            nombre: '',
            imagen: '',
            alerta_config: '',
            alerta_etapa: '',
            alerta_material: '',
            id_equipo: '',
            tabla: ''
        })
    }
}