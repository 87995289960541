import { UseCase } from "~/domain/core/UseCase"
import { Cartera } from "~/services/cobro_cartera/cartera"
import { GraficaPagarFactory } from "~/domain/entities/cobro_cartera/factories/GraficaPagarFactory"
import { $try } from "~/services/global"

class GetGraficaPagarUseCase extends UseCase{
    static async execute(payload){
        const data =  await Cartera.getCobroCarteraPagarGrafica(payload)
        return $try(() => {
            return data.data ? GraficaPagarFactory.instantiate(data.data): null 
        })
    } 
}

export default GetGraficaPagarUseCase.execute

