import { UseCase } from "~/domain/core/UseCase";
import { Funcionariosroveedores } from '~/services/proveedores/funcionariosProveedores'
import { $try } from '~/services/global'

class PostFuncionariosProveedoresUseCase extends UseCase {
    static async execute({payload, idProveedor}){
        const data = await Funcionariosroveedores.postUsuarioProveedor({payload, idProveedor})
        return $try(() => {
            return data.message
        })
    }
}

export default PostFuncionariosProveedoresUseCase.execute