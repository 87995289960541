import { RangoStockMateriales } from "../RangoStockMateriales";

export class RangoStockMaterialesFactory {
    static instantiate(obj){
        return new RangoStockMateriales(obj)
    }

    static instantiateEmpty(){
        return new RangoStockMateriales({
            valor_min: '',
            valor_max: ''
        })
    }
}