import  Api  from '~/services/api'

export const MaintenanceActivities = {
    //Activities
    getMaintenanceActivities({id_material, id_mantenimiento}){
        const Maintenance = new Api()
        return Maintenance.get(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades?page=1`)
    },
    createMaintenanceActivities({id_material, id_mantenimiento, payload}){
        const Maintenance = new Api({notification: true})
        return Maintenance.post(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades`, payload)
    },
    editMaintenanceActivity({id_material, id_mantenimiento, id_actividad, payload}){
        const Maintenance = new Api({notification: true})
        return Maintenance.put(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}`, payload)
    },
    deleteMaintenanceActivity({id_material, id_mantenimiento, id_actividad}){
        const Maintenance = new Api()
        return Maintenance.delete(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}`)
    },

    //Activities // Attachments
    createActiviyFileAttachment({id_material, id_mantenimiento, id_actividad, payload}){
        const ActivityFile = new Api({notification: true})
        return ActivityFile.post(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/adjunto`, payload)
    },
    deleteActiviyFileAttachment({id_material, id_mantenimiento, id_actividad, id_adjunto}){
        const ActivityFile = new Api({notification: true})
        return ActivityFile.delete(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/adjunto/${id_adjunto}`)
    },
    editActiviyFileAttachment({id_material, id_mantenimiento, id_actividad, id_adjunto, payload}){
        const ActivityFile = new Api({notification: true})
        return ActivityFile.put(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/adjunto/${id_adjunto}`, payload)
    },

    //Activies calendar types
    getActivityCalendarTypes(){
        const CalendarTypes = new Api()
        return CalendarTypes.get(`/almacen/materiales/tipos-calendarios`)
    },
}