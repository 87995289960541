import { UseCase } from "~/domain/core/UseCase"
import { Cartera } from "~/services/cobro_cartera/cartera"
import { PagarFactory } from "~/domain/entities/cobro_cartera/factories/PagarFactory"
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from "~/services/global"

class GetPagarUseCase extends UseCase{
    static async execute(payload){
        const data = await Cartera.getCobroCarteraPagar(payload)
        return $try(() => {
            if(data?.data) 
                return new PaginationDecorator(data.data, PagarFactory.instantiate);
        })
    } 
}

export default GetPagarUseCase.execute