import * as Cotizacion from '~/domain/use_cases/almacen/cotizaciones'
import { CotizacionFilterFactory } from '~/domain/filters/almacen/materiales/cotizaciones/factories/CotizacionFilterFactory'
import { $try } from '~/services/global'

export const state = {
    proveedores: [],
    estados_cotizacion: {
        17: 'icon-progress-pencil text-muted5',
        18: 'icon-file-send text-orange',
        19: 'icon-file-send text-primary',
        20: 'icon-file-send text-success',
    },
    filtro_cotizaciones: {},
    filter: CotizacionFilterFactory.instantiateEmpty(),
    query: '',
}

export const getters = {
    proveedores: state => state.proveedores,
    pagination: state => state.cotizaciones.pagination,
    filtro_cotizaciones: state => state.filtro_cotizaciones,
    estados_cotizacion: state => state.estados_cotizacion,
    filter: state => state.filter,
    query: state => state.query,
}

export const mutations = {
    set_proveedores(state, payload){
        state.proveedores = payload
    },
    set_filtro_cotizaciones(state, payload){
        state.filtro_cotizaciones = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_query(state, payload){
        state.query = payload
    }
}

export const actions = {
    async Action_get_cotizaciones({state, commit}, idMaterial){
        const filter = state.filter.clean()
        const data = await Cotizacion.GetCotizacionUseCase(idMaterial, {...filter, buscar: state.query})
        $try( () => commit('set_proveedores', data))
        
    },
    async Action_get_filtro_cotizaciones({commit}, idMaterial){
        const data = await Cotizacion.GetCotizacionOptionUseCase(idMaterial)
        $try( () => commit('set_filtro_cotizaciones', data))
    },
    Action_clear_filter({ state, commit }){       
        commit('set_filter', CotizacionFilterFactory.instantiateEmptyWithRange(state.filtro_cotizaciones))
    }
}