<template>
    <div>
        <div v-if="count == 0" class="col-auto my-auto px-1 icon-option">
            <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                <button class="btn btn-square32-5d" @click="$emit('openModalFilter')">
                    <i class="icon-filter text-white" />
                </button>
            </el-tooltip>
        </div>
        <div v-if="count > 0" class="col-auto filtro-contador my-auto px-1">
            <div class="d-flex border-general br-4">
                <button class="btn btn-square32-5d cr-pointer" @click="$emit('openModalFilter')">
                    <i class="icon-filter text-white" />
                </button>
                <div class="d-middle px-2">
                    <p class="f-12"> {{ count }} </p>
                    <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="$emit('clearFilter')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        count: {
            type: Number,
            default: 0
        },
    },
}
</script>