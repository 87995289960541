import { PresenciaEquiposFilter } from "../PresenciaEquiposFilter";

export class PresenciaEquiposFilterFactory {
        static instantiateEmpty(){
        return new PresenciaEquiposFilter({
            id_equipo: '',
            id_cliente: '',
            fecha_venta_desde: null,
            fecha_venta_hasta: null,
            precio_minimo: 0,
            precio_maximo: 0,
        })
    }

    static instantiateEmptyWithRange(range){
        const precio_minimo = range?.valor_min ?? 0
        const precio_maximo = range?.valor_max ?? 0
        
        const service = new PresenciaEquiposFilter({
            id_equipo: '',
            id_cliente: '',
            fecha_venta_desde: null,
            fecha_venta_hasta: null,
            precio_minimo,
            precio_maximo,
        })

        service.setExceptions({
            precio_minimo,
            precio_maximo,
        })

        return service
    }
}