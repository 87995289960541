import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivitiesSteps } from "~/services/almacen/mantenimiento/steps"
import { ActivityFactory } from '~/domain/entities/almacen/mantenimiento/actividades/factories/ActivityFactory'
import { $try } from '~/services/global'

class SwitchStepsMaintenanceActivityStepsUseCase extends UseCase {
    static async execute(params){
        const { data } = await MaintenanceActivitiesSteps.switchPosition(params)

        // return $try(
        //     () => ActivityFactory.instantiate(data[0])
        // )

    }
}

export default SwitchStepsMaintenanceActivityStepsUseCase.execute