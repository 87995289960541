import { AdditionalData } from "../AdditionalData";

export class AdditionalDataFactory {
    static instantiate(obj){
        return new AdditionalData(obj)
    }

    static instantiateEmpty(){
        return new AdditionalData({
            id: '',
            pregunta: '',
            posicion: '',
            tipo: ''
        })
    }
}