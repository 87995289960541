import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'
import { $try } from '~/services/global'

class DeleteCalendarSubactivityUseCase extends UseCase {
    static async execute(params){
        const data = await GeneralCalendarService.deleteGeneralCalendarSubactivity(params)
        return $try(() => data?.message)
    }
}

export default DeleteCalendarSubactivityUseCase.execute