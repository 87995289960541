import { UseCase } from "~/domain/core/UseCase";
import { Equipos } from '~/services/obras/equipos/equipo'
import { EquipoFactory } from '~/domain/entities/obras/equipos/factory/equiposFactory'
import { $try } from '~/services/global'

class GetInformacionEquiposUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await Equipos.getInfoEquipos(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new EquipoFactory.instantiate(e)) 
        })
    }
}

export default GetInformacionEquiposUseCase.execute