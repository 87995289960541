import { Entity } from '~/domain/entities'

export class Etapa extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                "id",
                "id_etapa",
                "configuraciones",
                "nombre"
            ]
        })
    }
}


