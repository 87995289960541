import { UseCase } from '~/domain/core/UseCase'
import { Gallery } from '~/services/obras/gallery/gallery'
import { GalleryFactory } from '~/domain/entities/obras/galeria/factories/GalleryFactory'
import { $try } from '~/services/global' 

class CreateNewFileUseCase extends UseCase {
    static async execute(params){
        const data = await Gallery.saveFile(params)
        //return $try(() => data?.data ? GalleryFactory.instantiate(data?.data) : {} )
    }
}

export default CreateNewFileUseCase.execute