<template>
    <div class="bg-f5 border br-4 d-flex tres-puntos pl-1 pr-2 card-file my-3">
        <div id="df_gbp" class="_df_custom position-absolute card-actions d-flex justify-content-lg-around">
            <i class="f-20 rounded-circle bg-general text-white icon-eye mx-2" @click="toggleVisualizer"/>
            <a v-if="permiso && permitAction(permiso)" :href="fileBase64" :download="`${fileName}.${extension}`" target="_blank" @click.stop="">
                <i class="f-20 mx-2 rounded-circle text-white bg-general icon-arrow-down-bold" :class="permitAction(permiso) ? 'cr-pointer' : 'cr-not-allowed'" />
            </a>
            <p v-else class="f-20 mx-2 rounded-circle text-white bg-general icon-arrow-down-bold cr-not-allowed" @click.stop="functionPermitAction(permiso)"/>
        </div>
        <div class="d-flex">
            <i class="f-20" :class="setFileType(fileType)" />
            <p class="tres-puntos f-12 my-auto ml-1 f-600"> {{ fileName }} </p>
        </div>
        <modal-media-visualizer ref="mediaVisualizer" :media="file" :extension="extension" />
    </div>
</template>

<script>
import FlipBook from '~/mixins/FlipBook'

export default {
    mixins: [FlipBook],
    props:{
        id:{
            type: Number,
            required: true
        },
        fileType:{
            type: Number,
            required: true
        },
        fileName:{
            type: String,
            required: true
        },
        file: {
            type: String,
            default: ''
        },
        permiso: {
            type: String,
            required: false
        }
    },
    data(){
        return{
            PDF_TYPE: 1,
            IMAGE_TYPE: 2,
            VIDEO_TYPE: 3,
            fileBase64: '',
        }
    },
    computed: {
        extension(){
            return this.file.includes('base64') ? this.file?.split('/')[3]?.split('.')[1] : this.file.split('.').pop()
        }
    },
    methods:{
        async toggleVisualizer(e){
            if(this.fileType === this.PDF_TYPE || this.extension.includes('pdf')){
                this.set_pdf(this.file)
            }else {
                e.stopPropagation()
                this.$refs.mediaVisualizer.toggle()
            }
        },
        setFileType(type){
            switch (type) {
                case 1:
                    return 'icon-pdf text-pdf'
                    break;
                case 2:
                    return 'icon-image text-imagen'
                    break;
                case 3:
                    return 'icon-youtube-play-1 text-vid'
                    break;
            
                default:
                    return 'icon-file text-file'
                    break;
            }
        }
    },
    async mounted(){
        let baseFile = await this.base64ToFile(this.$options.filters['helper-storage'](this.file), this.fileName)
        let reader = new FileReader()
        reader.readAsDataURL(baseFile)
        reader.onload = () => this.fileBase64 = reader.result
    }

}
</script>

<style lang="scss" scoped>
    .card-file{
        .card-actions {
            top: -0.18rem;
            right: 1.59rem;
        }
    }
</style>