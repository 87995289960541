import Api from '~/services/api'

const ApiHandler = new Api()

export const Documentacion = {
    //** Carpetas */
    getDocumentosCarpetas(payload){
        return ApiHandler.get(`obras/vigentes/${payload.idProyecto}/servicios/${payload.idControlPiso}/documentacion/carpetas`)
    },
    createDocumentosCarpetas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${payload.idProyecto}/servicios/${payload.idControlPiso}/documentacion/carpetas`, payload)
    },
    editDocumentosCarpetas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`obras/vigentes/${payload.idProyecto}/servicios/${payload.idControlPiso}/documentacion/carpetas/${payload.idDocumento}`, payload)
    },
    deleteDocumentosCarpetas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`obras/vigentes/${payload.idProyecto}/servicios/${payload.idControlPiso}/documentacion/carpetas/${payload.idDocumento}`)
    },
    //** Archivos */
    getArchiviosCarpetas(payload){
        return ApiHandler.get(`obras/vigentes/${payload.idProyecto}/servicios/${payload.idControlPiso}/documentacion/carpetas/${payload.idCarpeta}/archivos`)
    },
    createArchiviosCarpetas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${payload.idProyecto}/servicios/${payload.idControlPiso}/documentacion/carpetas/${payload.idCarpeta}/archivos`, payload)
    },
    editArchiviosCarpetas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`obras/vigentes/${payload.idProyecto}/servicios/${payload.idControlPiso}/documentacion/carpetas/${payload.idCarpeta}/archivos/${payload.idArchivo}`, payload)
    },
    deleteArchiviosCarpetas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`obras/vigentes/${payload.idProyecto}/servicios/${payload.idControlPiso}/documentacion/carpetas/${payload.idCarpeta}/archivos/${payload.idArchivo}`)
    }
}