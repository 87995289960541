import Api from '~/services/api'

export const Informacion = {
    getInfoCabecera(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload}`)
    },

    async getInfoCabeceraFS(params){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/informacion/${params.id}/detalle-cabecera`, {token: params.token})
    },

    async getHistorialCabeceraFS(params){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/informacion/${params.id}/historial`, {token: params.token})
    },
    
    async editarDuracion(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/informacion/guardar-duracion`, {payload})
    },

    addNumeroIdentificacion(payload){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.post(`obras/vigentes/${payload.id}/informacion/numero`, payload)
    }
}