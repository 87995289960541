import Auth from './auth/auth'
import Configurar from './configurar/configurar'
import Almacen from './almacen/almacen'
import Compras from './compras/compras'
import Oportunidades from './oportunidades/oportunidades'
import Talleres from './talleres/talleres'
import Ventas from './ventas/ventas'
import Obras from './obras/obras'
import Proveedores from './proveedores/proveedores'
import cobroCartera from './cobroCartera/cobroCartera'
import Cotizaciones from './cotizaciones/cotizaciones'
import Calendario from './calendario/calendario'
import Bodega from './bodega/bodega'
import ProveedoresUsuarios from './proveedores/proveedoresUsuarios'
import Redirect from './redirect/redirect'
import Proyectos from './proyectos/proyectos'
export default [
    {
        path: '/',
        name: 'home',
        component: ()=>import('../pages/Home.vue').then(m=>m.default || m)
    },
    ...Auth,
    ...Configurar,
    ...Almacen,
    ...Compras,
    ...Oportunidades,
    ...Talleres,
    ...Obras,
    ...Proveedores,
    ...cobroCartera,
    ...Cotizaciones,
    ...Calendario,
    ...Bodega,
    ...ProveedoresUsuarios,
    ...Redirect,
    ...Ventas,
    ...Proyectos,
]
