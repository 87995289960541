import * as PurchaseOrderDomain from '~/domain/use_cases/compras/ordenes_compra'
import * as MatrizDomain from '~/domain/use_cases/compras/matriz'
import { PurchaseOrderListFilterFactory } from '~/domain/filters/compras/ordenes_compra/factories/PurchaseOrderListFilterFactory.js'
import { PurchaseOrderDaysFilterFactory } from '~/domain/filters/compras/ordenes_compra/factories/PurchaseOrderDaysFilterFactory.js'
import { GruposOrdenesComprasFactory } from '~/domain/filters/compras/ordenes_compra/factories/GruposOrdenesComprasFactory.js'
import { $try } from '~/services/global'
import moment from 'moment'

export const state = {
    purchase_order_list: [],
    purchase_order_info: {},
    purchase_order_materials: [],
    purchase_order_days: [],
    week_states: [],
    pagination: {},
    values_list: {},
    filtroGruposOcs: GruposOrdenesComprasFactory.instantiateEmpty(),
    filter_list: PurchaseOrderListFilterFactory.instantiateEmpty(),
    filter_days: PurchaseOrderDaysFilterFactory.instantiate({
        fecha_inicio: moment().startOf('week').format('YYYY-MM-DD'),
        fecha_fin: moment().endOf('week').format('YYYY-MM-DD'),
    }),
    purchase_states: {
        1: { text: 'Borrador', icon: 'icon-progress-pencil text-5d' },
        2: { text: 'Pendiente', icon: 'icon-history text-orange' },
        3: { text: 'Entrega parcial', icon: 'icon-circle-check text-info' },
        4: { text: 'Entrega completa', icon: 'icon-circle-check text-verde' },
        default: { text: '', icon: '' },
    },
    grupos: [],
    oportunidades: [],
    equipos: [],
    etapas: [],
    configuraciones: [],
    proveedores: [],
    bodegas: [],
    cantidades_material: [],
    valores_material: [],
    materiales: {
        data: [],
        pagination: {
            current_page: 1
        },
    },
    select_states: [],
    select_payment_status: [],
    filtrosGruposOcs: {},
}

export const getters = {
    grupos: state => state.grupos,
    oportunidades: state => state.oportunidades,
    equipos: state => state.equipos,
    etapas: state => state.etapas,
    selected_etapas: state => state.etapas.filter(etapa => etapa.selected).map(etapa => ({ tabla: etapa.info.tabla, id: etapa.id_etapa })),
    configuraciones: state => state.configuraciones,
    selected_configuraciones: state => _.uniqBy(state.configuraciones.filter(conf => !!conf.selected).map(({ id, tabla }) => ({ id, tabla })), 'id'),
    values_list: state => state.values_list,
    proveedores: state => state.proveedores,
    bodegas: state => state.bodegas,
    cantidades_material: state => state.cantidades_material,
    valores_material: state => state.valores_material,
    materiales: state => state.materiales,
    purchase_order_list: state => state.purchase_order_list,
    purchase_order_info: state => state.purchase_order_info,
    purchase_order_materials: state => state.purchase_order_materials,
    purchase_order_days: state => state.purchase_order_days,
    week_states: state => state.week_states,
    filter_list: state => state.filter_list,
    filter_days: state => state.filter_days,
    pagination: state => state.pagination,
    select_states: state => state.select_states,
    select_payment_status: state => state.select_payment_status,
    purchase_states: state => state.purchase_states,
    filtrosGruposOcs: state => state.filtrosGruposOcs,
    filtroGruposOcs: state => state.filtroGruposOcs,
}

export const mutations = {
    set_grupos(state, grupos) {
        state.grupos = grupos
    },
    set_oportunidades(state, oportunidades) {
        state.oportunidades = oportunidades
    },
    set_equipos(state, equipos) {
        state.equipos = equipos
    },
    set_etapas(state, etapas) {
        state.etapas = etapas
    },
    set_configuraciones(state, configuraciones) {
        state.configuraciones = configuraciones
    },
    set_proveedores(state, proveedores) {
        state.proveedores = proveedores
    },
    set_bodegas(state, bodegas) {
        state.bodegas = bodegas
    },
    set_cantidades_material(state, cantidades_material) {
        state.cantidades_material = cantidades_material
    },
    set_valores_material(state, valores_material) {
        state.valores_material = valores_material
    },
    set_configuraciones_oportunidad(state, configuraciones_oportunidad) {
        state.configuraciones_oportunidad = configuraciones_oportunidad
    },
    set_materiales(state, materiales) {
        state.materiales = materiales
    },
    set_values_orders(state, values) {
        state.values_list = values
    },
    set_purchase_order_list(state, orders) {
        state.purchase_order_list = orders
    },
    set_purchase_order_info(state, orders) {
        state.purchase_order_info = orders
    },
    set_purchase_order_materials(state, orders) {
        state.purchase_order_materials = orders
    },
    set_purchase_order_days(state, orders) {
        state.purchase_order_days = orders
    },
    set_week_states_list(state, weeks) {
        state.week_states = weeks
    },
    set_filter_list(state, filters) {
        state.filter_list = filters
    },
    set_filter_days(state, dates) {
        state.filter_days = dates
    },
    set_pagination(state, pagination) {
        state.pagination = pagination
    },
    set_select_states(state, payload) {
        state.select_states = payload
    },
    set_select_payment_status(state, payload) {
        state.select_payment_status = payload
    },
    set_filtros_grupos_ocs(state, payload) {
        state.filtrosGruposOcs = payload
    },
    set_filtro_grupos_ocs(state, payload) {
        state.filtroGruposOcs = payload
    }
}

export const actions = {
    async Action_create_orden_compra({ commit }, payload) {
        const orden_compra = await MatrizDomain.CreateBuyOrderUseCase(payload)
    },

    async Action_get_grupos({ commit }, filters) {
        const payload = {
            meses: filters.meses,
            ...state.filtroGruposOcs
        }
        const groups = await MatrizDomain.GetBuyOrdersGroupUseCase(payload)
        $try(() => {
            commit('set_grupos', groups)
        })
    },

    async Action_get_oportunidades({ commit }, payload) {
        const oportunidades = await MatrizDomain.GetOpportunitiesUseCase(payload)
        $try(() => {
            commit('set_oportunidades', oportunidades)
        })
    },

    async Action_get_equipos({ commit }, payload) {
        const equipos = await MatrizDomain.GetOpportunityProductsUseCase(payload)
        $try(() => {
            commit('set_equipos', equipos)
        })
    },

    async Action_get_etapas({ commit }, { id_ops, id_producto, tabla, ...payload }) {
        const data = await MatrizDomain.GetProductStagesUseCase({ id_ops, id_producto, tabla }, payload)
        $try(() => {
            data.forEach(item => item.selected = false)
            commit('set_etapas', data)
        })
    },

    async Action_get_proveedores({ commit }, payload) {
        const proveedores = await MatrizDomain.GetProvidersUseCase(payload)
        $try(() => {
            commit('set_proveedores', proveedores)
        })
    },

    async Action_get_bodegas({ commit }, payload) {
        const bodegas = await MatrizDomain.GetWarehousesUseCase(payload)
        $try(() => {
            commit('set_bodegas', bodegas)
        })
    },

    async Action_get_cantidad({ commit }, payload) {
        const cantidades_material = await MatrizDomain.GetWarehouseMaterialQuantityUseCase(payload)
        $try(() => {
            commit('set_cantidades_material', cantidades_material)
        })
    },

    async Action_get_valores_material({ commit }, payload) {
        const valores_material = await MatrizDomain.GetProviderMaterialValueUseCase(payload)
        $try(() => {
            commit('set_valores_material', valores_material)
        })
    },

    async Action_get_configuraciones({ commit, getters }, payload) {
        const data = await MatrizDomain.GetStageConfigurations(payload)
        $try(() => {
            data.forEach(item => item.selected = false)
            commit('set_configuraciones', data)
        })
    },

    async Action_get_materiales({ commit }, payload) {
        const materiales = await MatrizDomain.GetConfigurationMaterialsUseCase(payload)
        $try(() => {
            commit('set_materiales', materiales)
        })
    },

    async Action_get_purchase_order_list({ commit }, { id, pagination, payload }) {
        const decorator = await PurchaseOrderDomain.GetPurchaseOrderListUseCase({ id, pagination, payload })
        $try(() => {
            commit('set_purchase_order_list', decorator.data);
            commit('set_pagination', decorator.pagination);
        })
    },

    async Action_get_purchase_order_list_values({ commit }, { id }) {
        const data = await PurchaseOrderDomain.GetPurchaseOrderListValuesUseCase({ id })
        $try(() => {
            commit('set_values_orders', data);
        })
    },
    async Action_get_purchase_order_info({ commit }, id) {
        const info = await PurchaseOrderDomain.GetPurchaseOrderInfoUseCase({ id })
        $try(() => {
            commit('set_purchase_order_info', info);
        })
    },
    async Action_send_purchase_order({ commit }, id) {
        const data = await PurchaseOrderDomain.SendPurchaseOrderUseCase({ id })
        $try(() => {
            commit('set_purchase_order_info', data);
        })
    },
    async Action_get_purchase_order_materials({ commit }, id) {
        const info = await PurchaseOrderDomain.GetPurchaseOrderMaterialsUseCase({ id })
        $try(() => {
            commit('set_purchase_order_materials', info);
        })
    },
    async Action_get_purchase_order_days({ commit }, { id, payload }) {
        const decorator = await PurchaseOrderDomain.GetPurchaseOrderDaysUseCase({ id, payload })
        $try(() => {
            commit('set_purchase_order_days', decorator.data);
        })
    },

    async Action_get_week_states({ commit }, { id, anio, payload }) {
        const listado = await PurchaseOrderDomain.GetWeekStatesUseCase({ id, anio, payload })
        $try(() => {
            commit('set_week_states_list', listado);
        })
    },

    async Action_get_select_states({ commit }) {
        const listado = await PurchaseOrderDomain.GetStatesUseCase();
        $try(() => {
            commit('set_select_states', listado);
        })
    },

    async Action_get_select_payment_status({ commit }) {
        const paymentStatus = await PurchaseOrderDomain.GetPaymentStatusUseCase();
        $try(() => {
            commit('set_select_payment_status', paymentStatus);
        })
    },

    async Action_clear_filter_list({ commit, state }) {
        $try(() => {
            commit('set_filter_list', PurchaseOrderListFilterFactory.instantiateEmptyWithRange(state.values_list));
        })
    },

    async Action_change_filter_dates({ commit }, week) {
        $try(() => {
            const dates = {
                fecha_inicio: moment().startOf('week').week(week).format('YYYY-MM-DD'),
                fecha_fin: moment().endOf("week").week(week).format('YYYY-MM-DD'),
            };
            commit('set_filter_days', dates)
        })
    },

    async Action_get_filtros_grupos_ordenes_comrpas({ commit }) {
        const data = await PurchaseOrderDomain.GetFiltrosOrdenesCompraGruposUseCase();
        $try(() => {
            commit('set_filtros_grupos_ocs', data);
        })
    },

    async Action_clear_grupos_ocs({ commit }) {
        $try(() => {
            commit('set_filtro_grupos_ocs', GruposOrdenesComprasFactory.instantiateEmpty());
        })
    },
}