import { Entity } from '~/domain/entities'

export class ValorFinalEtapa extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'total', 
            ]
        })
    }
}