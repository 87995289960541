import { UseCase } from "~/domain/core/UseCase";
import { Cotizaciones } from '~/services/oportunidades/cotizaciones/cotizaciones'
import { $try } from '~/services/global'

class GetCotizacionesOportunidadUseCase extends UseCase {
    static async execute({idOportunidad, buscar, page}){
        const data = await Cotizaciones.getCotizacionesOportunidad({idOportunidad, buscar, page})
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default GetCotizacionesOportunidadUseCase.execute