import * as UsersProviders from '~/domain/use_cases/users_providers'
import { CotizacionPendienteFilterFactory } from '~/domain/filters/users_providers/factories/CotizacionPendienteFilterFactory';
import { CotizacionRealizadaFilterFactory } from '~/domain/filters/users_providers/factories/CotizacionRealizadaFilterFactory';
import { CotizacionVencidaFilterFactory } from '~/domain/filters/users_providers/factories/CotizacionVencidaFilterFactory';
import { CotizacionVerPendienteFilterFactory } from '~/domain/filters/users_providers/factories/CotizacionVerPendienteFilterFactory';
import {$try} from '~/services/global'

// state
export const state = {
    infoTop: {},
    cotizacionesPendientes: [],
    cotizacionesRealizadas: [],
    cotizacionesVencidas: [],
    materialesCotizaciones: [],
    informationProvider: [],
    rangeCantMaterials: [],
    rangeValueMaterials: [],
    rangeCantValVerMaterials: {},
    filterPendientes: CotizacionPendienteFilterFactory.instantiateEmpty(),
    filterVerPendientes: CotizacionVerPendienteFilterFactory.instantiateEmpty(),
    filterRealizadas: CotizacionRealizadaFilterFactory.instantiateEmpty(),
    filterVencidas: CotizacionVencidaFilterFactory.instantiateEmpty(),
}

// getters
export const getters = {
    infoTop: state => state.infoTop,
    cotizacionesPendientes: state => state.cotizacionesPendientes,
    cotizacionesRealizadas: state => state.cotizacionesRealizadas,
    cotizacionesVencidas: state => state.cotizacionesVencidas,
    materialesCotizaciones: state => state.materialesCotizaciones,
    informationProvider: state => state.informationProvider,
    rangeCantMaterials: state => state.rangeCantMaterials,
    rangeValueMaterials: state => state.rangeValueMaterials,
    rangeCantValVerMaterials: state => state.rangeCantValVerMaterials,
    filterPendientes: state => state.filterPendientes,
    filterVerPendientes: state => state.filterVerPendientes,
    filterRealizadas: state => state.filterRealizadas,
    filterVencidas: state => state.filterVencidas,
    
}

// mutations
export const mutations = {
    set_info_top(state, data){
        state.infoTop = data
    },
    set_cotizaciones_pendientes(state, data){
        state.cotizacionesPendientes = data
    },
    set_cotizaciones_realizadas(state, data){
        state.cotizacionesRealizadas = data
    },
    set_cotizaciones_vencidas(state, data){
        state.cotizacionesVencidas = data
    },
    set_materiales_cotizaciones(state, data){
        state.materialesCotizaciones = data
    },
    set_information_provider(state, data){
        state.informationProvider = data
    },
    set_range_cant_materials(state, data){
        state.rangeCantMaterials = data
    },
    set_range_value_materials(state, data){
        state.rangeValueMaterials = data
    },
    set_range_cantidad_value_ver_materials(state, data){
        state.rangeCantValVerMaterials = data
    },
    set_filters_pendientes(state, data){
        state.filterPendientes = data
    },
    set_filters_ver_pendientes(state, data){
        state.filterVerPendientes = data
    },
    set_filters_realizadas(state, data){
        state.filterRealizadas = data
    },
    set_filters_vencidas(state, data){
        state.filterVencidas = data
    },
}

// actions
export const actions = {
    async Action_list_cotizaciones_pendientes({state, commit}, payload){
        const filters = state.filterPendientes.clean();
        payload = {...payload, ...filters}
        const data = await UsersProviders.GetCotizacionesPendientesUseCase(payload)
        $try(() => {
            commit('set_cotizaciones_pendientes', data)
        })
    },
    async Action_list_cotizaciones_realizadas({state, commit}, payload){
        const filters = state.filterRealizadas.clean();
        payload = {...payload, ...filters}
        const data = await UsersProviders.GetCotizacionesRealizadasUseCase(payload)
        $try(() => {
            commit('set_cotizaciones_realizadas', data)
        })
    },
    async Action_list_cotizaciones_vencidas({state, commit}, payload){
        const filters = state.filterVencidas.clean();
        payload = {...payload, ...filters}
        const data = await UsersProviders.GetCotizacionesVencidasUseCase(payload)
        $try(() => {
            commit('set_cotizaciones_vencidas', data)
        })
    },
    async Action_list_materiales_cotizaciones({state, commit}, params){
        const filters = state.filterVerPendientes.clean()
        params.payload = {...params.payload, ...filters}
        const data = await UsersProviders.GetMaterialCotizacionUseCase(params)
        $try(() => {
            commit('set_materiales_cotizaciones', data)
        })
    },
    async Action_get_information_provider({commit}, payload){
        const data = await UsersProviders.GetInformationProviderUseCase(payload)
        $try(() => {
            commit('set_information_provider', data)
        })
    },
    async Action_set_price_materials(_, payload){
        await UsersProviders.SetPriceMaterialUseCase(payload)
    },
    async Action_get_range_materials({commit}, payload){
        const data = await UsersProviders.GetRangeCantMaterialsUseCase(payload)
        $try(() => {
            commit('set_range_cant_materials', data)
        })
    },
    async Action_get_value_materials({commit}, payload){
        const data = await UsersProviders.GetRangeValueMaterialsUseCase(payload)
        $try(() => {
            commit('set_range_value_materials', data)
        })
    },
    async Action_get_cantidad_value_ver_materials({commit}, payload){
        const data = await UsersProviders.GetRangeCantValVerMaterialsUseCase(payload)
        $try(() => {
            commit('set_range_cantidad_value_ver_materials', data)
        })
    },
    clean_filters_pendientes({ state, commit }){
        commit('set_filters_pendientes', CotizacionPendienteFilterFactory.instantiateEmptyWithRange(state.rangeCantMaterials))
    },
    clean_filters_ver_pendientes({ state, commit }){
        commit('set_filters_ver_pendientes', CotizacionVerPendienteFilterFactory.instantiateEmptyWithRanges(state.rangeCantValVerMaterials))
    },
    clean_filters_realizadas({ state, commit }){
        commit('set_filters_realizadas', CotizacionRealizadaFilterFactory.instantiateEmptyWithRange(state.rangeValueMaterials))
    },
    clean_filters_vencidas({ commit }){
        commit('set_filters_vencidas', CotizacionVencidaFilterFactory.instantiateEmpty())
    },

}