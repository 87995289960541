import { Activity } from "../Activity";

export class ActivityFactory {
    static instantiate(obj){
        return new Activity(obj)
    }

    static instantiateEmpty(){
        return new Activity({
            nombre_tarea: '',
            id: '',
            horario_calendario: 0,
            descripcion: '',
            id_tipo_calendario: '',
            calendario: '',
            mantenimiento: '',
            adjuntos: [],
            id_materiales_mantenimiento: '',
            unidades_tiempo: '',
        })
    }
}