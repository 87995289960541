import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { FiltersBodegasVOFactory } from '~/domain/value_objects/bodegas/factories/FiltersBodegasVOFactory'
import { $try } from '~/services/global'

class GetSelectFiltersBodegasUseCase extends UseCase {
    static async execute(){
        const  data  =  await Selects.GetSelectFiltroBodegas()
        return $try(() => {
            if(data?.data) return FiltersBodegasVOFactory.instantiate(data.data)
        })
    }
}

export default GetSelectFiltersBodegasUseCase.execute