import * as PaisesDomain  from "~/domain/use_cases/configurar/paises";
import { CountryFilterFactory } from "~/domain/filters/configurar/paises/factories/CountryFilterFactory";
import { CountryUtility } from "~/domain/entities/configurar/paises/CountryUtility";
import { Pagination } from "~/domain/entities/decorators/pagination/Pagination";

export const state = {
    countries: {},
    filter: CountryFilterFactory.instantiateEmpty(),
}

export const getters = {
    countries: state => state.countries,
    filter: state => state.filter,
}

export const mutations = {
    set_countries(state, payload){
        state.countries = payload
    },
    set_filter(state, payload){
        state.filter = payload
    }
}

export const actions = {
    async getCountries({ state, commit }, page){
        const filter = state.filter.clean();
        const decorator = await PaisesDomain.GetCountriesUseCase({...filter, page});
        
        if(decorator instanceof Pagination)
            commit('set_countries', decorator)
    },
    async createCountryUtility({ state, dispatch }, payload){
        const country = await PaisesDomain.CreateCountryUseCase(payload)

        if(country instanceof CountryUtility)
            dispatch('getCountries', state.countries.current_page) 
    },
    async updateCountryUtility({ state, commit}, payload){
        const country = await PaisesDomain.UpdateCountryUseCase(payload)

        if(country instanceof CountryUtility)
            commit('set_countries', state.countries.data.map(element => payload.id === element.id ? country : element)) 
    },
    clearFilter({ commit }){
        commit('set_filter', CountryFilterFactory.instantiateEmpty())
    }
}