import { Entity } from '~/domain/entities'

export class PedidoInternoOcs extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
				'proyecto',
				'tipo',
				'estado',
				'valor',
				'responsable'
            ]
        })
    }
}