import { UseCase } from "~/domain/core/UseCase";
import { Configuracion } from '~/services/obras/configuracion/configuracion'
import { $try } from '~/services/global'
class GetDataFiltroUseCase extends UseCase {
    static async execute(params) {
        const data = await Configuracion.getDataFiltro(params)
        return $try(() => {
            if(data?.data){
                return data.data
            }
        })
    }
}

export default GetDataFiltroUseCase.execute