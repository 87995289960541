import { Filter } from '~/domain/core/Filter';

export class CotizacionVerPendienteFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_unidad_medida',
                'cantidad_material_minimo',
                'cantidad_material_maximo',
                'rango_valor_minimo',
                'rango_valor_maximo',
            ]
        });
    }
}