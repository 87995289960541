import { Entity } from '~/domain/entities'

export class Pagination extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['current_page', 'first_page_url', 'from', 'last_page', 'last_page_url', 'next_page_url', 'path', 'per_page', 'prev_page_url', 'to','total','data']
        });
    }
    setPage(val){
        this.current_page = val
    }
}