import { UseCase } from "~/domain/core/UseCase";
import { Dashboard } from '~/services/bodegas/dashboard'
import { SalidaFactory } from '~/domain/entities/bodegas/dashboard/factories/SalidaFactory'
import { $try } from '~/services/global'

class GetSalidaUseCase extends UseCase {
    static async execute(payload){
        const data =  await Dashboard.getDashboardBodegasSalida(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new SalidaFactory.instantiate(e)) 
        })
    }
}

export default GetSalidaUseCase.execute