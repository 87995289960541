import { UseCase } from "~/domain/core/UseCase"
import { Maintenance } from '~/services/almacen/mantenimiento'
import { MaintenanceFactory } from '~/domain/entities/almacen/mantenimiento/factories/MaintenanceFactory'
import { $try } from '~/services/global'

class GetMaintenancePlansUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data: { data } } =  await Maintenance.getMaintenancePlans(params)

        $try(() => {
            if(data.length){
                data.forEach((item) =>{
                    customData.push(
                        MaintenanceFactory.instantiate(item))
                })
            }
        })

        return customData
    }
}

export default GetMaintenancePlansUseCase.execute