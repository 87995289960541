import { UseCase } from '~/domain/core/UseCase'
import { Category } from '~/services/almacen/categorias/categorias'

class GetCategoryRangesUseCase extends UseCase{
    static async execute(){
        const data = await Category.getCategoryRanges()
        return data?.data
    }
}

export default GetCategoryRangesUseCase.execute