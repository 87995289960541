<template>
<div>
    <titulo-divisor titulo="Datos adicionales" />
    <section-dato-adicional :datosAdicionales="info" />
</div>

</template>

<script>
export default {
    props:{
        info: {
            type: Array,
            default: () => []
        }
    }

}
</script>

<style>

</style>