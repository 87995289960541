import { UseCase } from "~/domain/core/UseCase";
import { Documentacion } from "~/services/obras/servicios/documetacion";
import { ArchivosFactory } from "~/domain/entities/obras/servicios/documentacion/factories/ArchivosFactory";
import { $try } from "~/services/global";

class GetArchivosCarpetas extends UseCase {
  static async execute(params) {
    const data = await Documentacion.getArchiviosCarpetas(params)
    return $try(() => {
      let newData = data.data;
      if (newData && Array.isArray(newData)) {
        return newData.map((type) => ArchivosFactory.instantiate(type))
      }
    });
  }
}

export default GetArchivosCarpetas.execute
