import { Material } from "../Material";

export class MaterialFactory {
    static instantiate(obj){
        return new Material(
            obj,
            [
                'id',
                'nombre',
                'referencia',
                'id_tipo_material', 
                'tipo',
                'id_material',
                'valor_teorico',
                'proveedor',
                'id_unidad_medida',
                'unidad_medida',
                'id_proveedor_favorito',
                'etapas',
                'planes_mantenimiento', 
                'estado',
            ]
        )
    }

    static instantiateCategory(obj){
        return new Material(
            obj,
            [
                'id',
                'referencia',
                'nombre',
                'id_tipo_material', 
                'tipo',
                'id_material',
                'valor_teorico',
                'proveedor',
                'id_unidad_medida',
                'unidad_medida',
                'id_proveedor_favorito',
                'etapas',
                'planes_mantenimiento', 
                'estado',
            ]
        )
    }
}