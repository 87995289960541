import { ValueObject } from '~/domain/core/ValueObject'

export class Materiales extends ValueObject{
    constructor(obj) {
        super({
            params: obj,
            keys: [
            'id',
            'material', 
            'tipo',
            'total_unidades',
            'valor_unitario'
        ]
        })
    }
}