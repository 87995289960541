import { UseCase } from "~/domain/core/UseCase"
import PermisosAccionesList from "~/services/configurar/permisos/acciones"

class GetPermisosAccionesUseCase extends UseCase {
    static async execute(params){
        const data = await PermisosAccionesList.listPermisosAcciones(params)
        if(data?.data){
            return data.data;
        }
    }
}

export default GetPermisosAccionesUseCase.execute