// @ts-check
import axios from 'axios'
// @ts-ignore
import { notify } from '~/services/helper'
// api routes to exclude the notification error

/** @type {string[]} */
const excludeErrorNotification = ['auth/login']

/**
 * @author Gabriel Luna
 * @class Service 
 */
export default class Api {
    /** @type {boolean} */
    #notification = false
    /** @type {number} */
    #delay = 0
    
    
    constructor(options){
        this.#notification = options?.notification
        this.#delay = options?.delay
    }

    /**
     * 
     * @param {function} callback 
     * @param {string} path 
     * @param {object} payload 
     * @param {object} options
     * @param {function} errorCallback
     * @returns {Promise<any>}
     */
    async request (callback, path, payload, options = {}, errorCallback = (error) => {}){
        try {
            const data = await callback(path, payload, options);
            notify(data, this.#notification, this.#delay);
            return data.data;
        } catch (error) {
            notify(error, true);
            errorCallback(error)
        }
    }

    /**
     * @param  {string} path
     * @param  {object} params
     * @param  {object} options
     * @param  {function} errorCallback
     */
    get(path, params = {}, options, errorCallback){
        return this.request(axios.get, path, { params }, options, errorCallback)
    }
    
    /**
     * @param  {string} path
     * @param  {object} payload
     */
    post(path, payload = {}, options, errorCallback){
        return this.request(axios.post, path, payload, options, errorCallback)
    }

     /**
     * @param  {string} path 
     * @param  {object} payload
     */
    put(path, payload = {}, options, errorCallback){
        return this.request(axios.put, path, payload, options, errorCallback)
    }
    
    /**
     * @param  {string} path
     */ 
    delete(path, params = {}, options, errorCallback){
        return this.request(axios.delete, path, { params }, options, errorCallback)
    }

}
