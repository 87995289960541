import { Entity } from "~/domain/entities";

export class DetalleServicio extends Entity {
    constructor(obj) {
        super({
        params: obj,
        keys: [
            "id",
            "seccion",
            "taller",
            "configuracion",
            "descripcion",
            "porcentaje_avance"
        ]});
    } 
}
