import * as DocumentacionDomain from '~/domain/use_cases/obras/servicios/documentation'
import { $try } from '~/services/global'

//* State */
export const state = {
    documentacion: [],
    archivos: []
}

//* Getters */
export const getters = {
    getFoldersFiles: state => state.documentacion,
    archivosList: state => state.archivos
}

//* Mutations */
export const mutations = {
    set_documentacion(state, payload){
        state.documentacion = payload
    },
    add_carpeta(state, payload){
        state.documentacion.push(payload)
    },
    edit_carpeta(state, payload){
        state.documentacion = state.documentacion.map(e => {
            if (e.id === payload.id) e = payload
            return e 
        })
    },
    delete_carpeta(state, payload){
        state.documentacion = state.documentacion.filter(e => (e.id !== payload))
    },
    set_archivos(state, payload){
        state.archivos = payload
    },
    add_archivo(state, payload){
        state.archivos.push(payload)
    },
    edit_archivo(state, payload){
        state.archivos = state.archivos.map(e => {
            if (e.id === payload.id) e = payload
            return e 
        })
    },
    delete_archivo(state, payload){
        state.archivos = state.archivos.filter(e => (e.id !== payload))
    }
}

//* Actions */
export const actions = {
    //* Documentacion */
    async Action_get_documentacion_carpetas({ commit }, payload){
        const data = await DocumentacionDomain.GetDocumentacionCarpetaUseCase(payload)
        $try(() => {
            if(data) commit('set_documentacion', data)
        })
    },
    async Action_crear_carpeta({ commit }, payload) {
        const carpeta = await DocumentacionDomain.CreateDocumentoCarpetaUseCase(payload)
        $try(() => {
            if(carpeta) commit('add_carpeta', carpeta)
        })
    },
    async Action_edit_carpeta({ commit }, payload) {
        const carpeta = await DocumentacionDomain.EditeDocumentoCarpetaUseCase(payload)
        $try(() => {
            if(carpeta) commit('edit_carpeta', carpeta)
        })
    },
    async Action_delete_carpeta( { commit } , params) {
        const carpeta = await DocumentacionDomain.DeleteDocumentoCapretaUseCase(params)
        $try(() => {
            if(carpeta) commit('delete_carpeta', carpeta)
        })
    },
    //* Archivos */
    async Action_get_archivos_carpetas({ commit }, payload){
        const data = await DocumentacionDomain.GetArchivosCarpetasUseCase(payload)
        $try(() => {
            if(data) commit('set_archivos', data)
        })
    },
    async Action_crear_archivos_carpetas({ commit }, payload) {
        const archivo = await DocumentacionDomain.CreateArchivosUseCase(payload)
        $try(() => {
            if(archivo) commit('add_archivo', archivo)
        })
    },
    async Action_editar_archivos_carpetas({ commit }, payload) {
        const archivo = await DocumentacionDomain.EditarArchivosUseCase(payload)
        $try(() => {
            if(archivo) commit('edit_archivo', archivo)
        })
    },
    async Action_delete_archivos_carpetas( { commit } , params) {
        const carpeta = await DocumentacionDomain.DeleteArchivosUseCase(params)
        $try(() => {
            if(carpeta) commit('delete_archivo', carpeta)
        })
    }
}