import { Provider } from "../Provider";

export class ProviderFactory {
    static instantiate(obj){
        return new Provider(obj)
    }

    static instantiateEmpty(){
        return new Provider({
            id: '',
            proveedor: '',
        })
    }
}