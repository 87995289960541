import Api from '~/services/api'

export const Equipos = {
    getInfoEquipoModelo(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload}/equipos/lista-oportunidades`)
    },

    addProyectoEquipo(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${payload.id}/equipos`, payload)
    },


}