import Api from '~/services/api';
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { DetailQuoteFactory } from '~/domain/entities/oportunidades/ver/factories/DetailQuoteFactory.js'

export async function getDetalleCotizaciones(idOportunidad, idCotizacion, payload){
	const data = await new Api().get(`oportunidades/vigentes/${idOportunidad}/cotizaciones/detalle/${idCotizacion}`, payload)
	if(!data?.data) return null;

	return new PaginationDecorator(
		data.data,
		DetailQuoteFactory.instantiate
	)
}