import { UseCase } from "~/domain/core/UseCase"
import { Maintenance } from '~/services/almacen/mantenimiento'
import { MaintenanceFactory } from '~/domain/entities/almacen/mantenimiento/factories/MaintenanceFactory'
import { $try } from '~/services/global'

class EditMaintenancePlanUseCase extends UseCase {
    static async execute(params){
        const data = await Maintenance.editMaintenancePlan(params)

        return $try(() => {
            return data.data 
                ? MaintenanceFactory.instantiate(data.data)
                : null
        })
    }
}

export default EditMaintenancePlanUseCase.execute