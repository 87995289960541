import { UseCase } from '~/domain/core/UseCase'
import { PurchaseOrder } from '~/services/obras/servicios/forma_pago';
import { $try } from '~/services/global';

class FinishPaymentUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso }){
        const data = await PurchaseOrder.finishPayment(id_proyecto, id_control_piso)
        return $try(() => {
            if(data?.data){
                return ({
                    total_pagado: parseFloat(data.data.total_pagado),
                    total_valor_pagar: parseFloat(data.data.total_valor_pagar),
                    fecha_pago: data.data.fecha_pago
                })
            }
        })
    }
}

export default FinishPaymentUseCase.execute