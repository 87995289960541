import Api from '~/services/api'

export const CalificacionService = {
    getQuestions() {
        const ApiHandler = new Api()
        return ApiHandler.get('configuracion/obras/calificacion-servicio')
    },
    createQuestion(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/calificacion-servicio', payload)
    },
    editQuestion(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.put(`configuracion/obras/calificacion-servicio/${payload.id_configuracion_control_piso_aspectos_calificar}`, payload)
    },
    deleteQuestion(id_configuracion_control_piso_aspectos_calificar) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.delete(`configuracion/obras/calificacion-servicio/${id_configuracion_control_piso_aspectos_calificar}`)
    },
    getOptionsQuestions(id_configuracion_control_piso_aspectos_calificar) {
        const ApiHandler = new Api()
        return ApiHandler.get(`configuracion/obras/calificacion-servicio/${id_configuracion_control_piso_aspectos_calificar}/opciones`)
    },
    addOptionQuestion(payload){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post(`configuracion/obras/calificacion-servicio/${payload.id_configuracion_control_piso_aspectos_calificar}/opciones`, payload )
    },
    deleteOptionQuestion(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.delete(`configuracion/obras/calificacion-servicio/${payload.id_configuracion_control_piso_aspectos_calificar}/opciones/${payload.id_configuracion_control_piso_aspectos_calificar_opciones}`)
    },
    getQuestionsWorkShops() {
        const ApiHandler = new Api()
        return ApiHandler.get('configuracion/obras/calificacion-servicio/asignar-preguntas-talleres')
    },
    addQuestionToWorkShop(payload){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/calificacion-servicio/asignar-preguntas-talleres', payload )
    },
    addWorkShopToQuestion(payload){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/calificacion-servicio/asignar-talleres-preguntas', payload )
    },
    addPosQuestion(payload){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/calificacion-servicio/posicion', payload)
    },
    addPosOption(payload){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post(`configuracion/obras/calificacion-servicio/${payload.id}/opciones/posicion`, payload.payload)
    }
}
