import  Api  from '~/services/api'

export const Maintenance = {
    listMaterialesMantenimiento(payload){
        const Service = new Api()
        return Service.get(`almacen/mantenimiento?page=${payload?.pag ?? 1}`, payload?.filtros)
    },
    createMaintenancePlan({id_material, payload}){
        const Service = new Api({ notification: true })
        return Service.post(`/almacen/materiales/${id_material}/mantenimientos`, payload)
    },
    editMaintenancePlan({id_material, id_mantenimiento, payload}){
        const Service = new Api({ notification: true })
        return Service.put(`/almacen/materiales/${id_material}/mantenimientos/${id_mantenimiento}`, payload)
    },
    deleteMaintenancePlan({id_material, id_mantenimiento}){
        const Service = new Api({ notification: true })
        return Service.delete(`/almacen/materiales/${id_material}/mantenimientos/${id_mantenimiento}`)
    },
    getMaintenancePlans({id_material}){
        const Service = new Api()
        return Service.get(`/almacen/materiales/${id_material}/mantenimientos`)
    },
}   