import { UseCase } from '~/domain/core/UseCase'
import { Resumen } from '~/services/oportunidades/productos/resumen'
import { $try } from '~/services/global'

class GetDetalleEtapaOportunidadEquipo extends UseCase {
    static async execute(payload){
        const data = await Resumen.getDetalleEtapa(payload)
        return $try(() => {
            if(data?.data) return data.data
            return {}
        })
    }
}

export default GetDetalleEtapaOportunidadEquipo.execute