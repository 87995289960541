import { UseCase } from "~/domain/core/UseCase";
import { PaymentStatusVOFactory } from '~/domain/value_objects/compras/ordenes_compra/factories/PaymentStatusVOFactory'
import { $try } from '~/services/global'

const PaymentStatus = [
    { id: "0", name: "No pago"},
    { id: "1", name: "Pago"},
];

class GetPaymentStatusUseCase extends UseCase {
    static execute(){
        return $try(() => {
            return PaymentStatus.map(paymentStatus => PaymentStatusVOFactory.instantiate(paymentStatus));
        })
    }
}

export default GetPaymentStatusUseCase.execute