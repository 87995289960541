import { Valor } from "../Valor";

export class ValorFactory {
    static instantiate(obj){
        return new Valor(obj)
    }

    static instantiateEmpty(){
        return new Valor({
            id: '',
            bodega: '',
            valor: ''
        })
    }
}