import { UseCase } from "~/domain/core/UseCase";
import { StateVOFactory } from '~/domain/value_objects/compras/ordenes_compra/factories/StateVOFactory'
import { $try } from '~/services/global'

class GetStatesUseCase extends UseCase {
    static execute(){
        const states = [
            { id: 1, name: "Borrador"},
            { id: 2, name: "Pendiente"},
            { id: 3, name: "Entrega parcial"},
            { id: 4, name: "Entrega completa"},
        ];
        return $try(() => {
            return states.map(state => StateVOFactory.instantiate(state));
        })
    }
}

export default GetStatesUseCase.execute