import { UseCase } from "~/domain/core/UseCase";
import PermisosAccionesList from "~/services/configurar/permisos/acciones"
import { PermisoFactory } from '~/domain/entities/configurar/permisos/factories/PermisoFactory'
import { $try } from '~/services/global'

class CreatePermisoUseCase extends UseCase {
    static async execute(params){ 
        const { data } =  await PermisosAccionesList.createPermiso(params)

        return $try(
            () => PermisoFactory.instantiate(data)
        )
    } 
}

export default CreatePermisoUseCase.execute


