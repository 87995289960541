import { Entity } from '~/domain/entities';

export class PurchaseOrder extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                'id',
                'estado',
                'created_at',
                'valor',
                'id_taller',
                'proveedor',
                'destino',
                'servicio',
                'forma_pago',
                'valor_pagado',
                'descripcion',
                'materiales',
            ]
        });
    }
}