import Api from '~/services/api'

export const Providers = {
    getProviders(params){
        const Provider = new Api()
        return Provider.get('/proveedores/proveedores', params)
    },
    createProvider(payload){
        const Provider = new Api({notification: true})
        return Provider.post('/proveedores/proveedores', payload)
    },
    updateProvider({id, payload}){
        const Provider = new Api({notification: true})
        return Provider.put(`/proveedores/proveedores/${id}`, payload)
    },

}