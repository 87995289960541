export default [
    {
        path: '/login-iaf/:id',
        name: 'redirect.login.iaf',
        component: () => import('~/pages/redirect/index.vue').then(m => m.default || m),
    },
    {
        path: '/login-full-smart',
        name: 'full.smart.iaf',
        component: () => import('~/pages/redirect/redirectFullEmpresa.vue').then(m => m.default || m),
    },
    {
        path: '/logout',
        name: 'logout.smart.iaf',
        component: () => import('~/pages/redirect/logout.vue').then(m => m.default || m),
    },
    {
        path: '/delete-sesion',
        name: 'delete.sesion.iaf',
        component: () => import('~/pages/redirect/cerrarSesion.vue').then(m => m.default || m),
    }
]
