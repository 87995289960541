<template>
    <el-select v-model="value" :placeholder="placeholder" :clearable="clearable">
        <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.valor"
        :value="item.id">
        </el-option>
    </el-select>
</template>
<script>
import ApiService from '~/services/api'
export default {
    props:{
        model: { type: Object },
        prop: { type: String, default: 'id_configuracion_datos_adicionales_tipos'},
        placeholder: { type: String, default: 'Seleccione'},
        url: { type: String, default: undefined },
        data: { type: Array, default: undefined },
        clearable: { type: Boolean, default: true }
    },
    data(){
        return {
            options: Array,
            value: null,
        }
    },
    watch:{
        data:{
            handler(val){
                this.options = val
            },
            deep: true
        },
        value(val){
            this.$emit('selected', val);
        },
        model(val){
            this.value = val?.hasOwnProperty(this.prop) ? val[this.prop] : '';
        }
    },
    created(){
        if(this.url) this.getSelectOptions();
    },
    methods:{
        clear(){
            this.value = null
        },
        async getSelectOptions(url){
            url = url ?? this.url;
            const Api = new ApiService();
            const { data }= await Api.get(url);
            
            this.$try(() => {
                this.options = data;
            });
        }
    }
}
</script>