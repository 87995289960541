import { Filter } from '~/domain/core/Filter';

export class ServicioFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_taller',
                'id_estado',
            ]
        });
    }
}