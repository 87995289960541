import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { CustomersFactory } from '~/domain/entities/oportunidades/information/factories/CustomersFactory'
import { $try } from '~/services/global'

class AddOpportunityCustomerUseCase extends UseCase {
    static async execute(params){
        const data =  await Opportunities.addCustomersOpportunity(params)
        return $try(() => data?.data ? CustomersFactory.instantiate(data.data) : {} )
    }
}

export default AddOpportunityCustomerUseCase.execute