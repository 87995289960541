// @ts-check
import Vue from 'vue';
import _ from 'lodash';
// @ts-ignore
import Service from '~/services/api'
// @ts-ignore
import store from '~/store'

/**@type {string} */
const control_errores_url = process.env.VUE_APP_CONTROL_ERRORES_URL || 'http://control-errores.localhost';

/**@type {string} */
const bucket = process.env.VUE_APP_BUCKET || 'v2-fullsmart-iaf';

/**
 * @param  {object} error @description error we want to store into 'control de errores'
 * @returns {Promise<void>}
 */
export const error_catch = async (error) => {
    /** @type {number} */
    const idProject = 3
    
    /** @type {RegExp} */
    const regex = /.\/src\/\D*/g;

    /** @type {array} */
    const file = error.stack.match(regex);

    /** @type {string} */
    const filePath = file?.[0] ?? '';

    // getting routes
    /** @type {object} */
    const routes = store.getters['error_handle/routes'];

    // getting user data
    /** @type {object} */
    const user = store.getters['auth/user']; 

    /** @type {number?} */
    const idUser = user?.id ?? ''

    /** @type {string?} */
    const userName = user?.nombre ?? null

    /** @type {string} */
    const errorMessage = error.message

    const payload = {
        id_proyecto: idProject,
        archivo: filePath,
        id_user: idUser,
        nombre_usuario: userName,
        error: errorMessage,
        ...routes,
    }

    console.error(`${errorMessage} => ${filePath}`)
    return;
    
    /** @type {Service} */
    const Api = new Service({notification:true});
    await Api.post(`${control_errores_url}/api/control-errores-frontend`,  payload,  {});
}

/**
 * @param  {function} callback @description function passed to evaluate if there is any error
 * @param  {function} errorCallback @description if the callback return something
 * @returns {Promise}
 */
export const $try = async (callback, errorCallback = () => {} ) => {
    try {
        return await callback();
    } catch (error) {
        error_catch(error);
        errorCallback(error);
    }
}

/**
 * @param  {Vue} context @description function router
 * @param  {Object} route @description navigate to this path
 * @returns {void}
 */
export const navigateTo = (route, context) => {
    if(!route) return console.error('Es necesario pasar una route como primer parametro a la funcion navigateTo');
    if(!context) return console.error('Es necesario pasar el context "this" como segundo parametro a la funcion navigateTo');
    // map matches  
    
    const redirectMatches = context.$route.matched.map(
    /** @param {object} redirect @description redirect to this path */
    ({redirect, name}) => ({...redirect, name}));
    // if the route is the same where we are then return
    if(context.$route.name === route.name || redirectMatches.some(el => el.name === route.name)) return;
    // push route
    context.$router.push(route);
}

/**
 * 
 * @param {object[]} array 
 * @param {string} prop 
 * @param {number} minValueProp 
 * @returns {array} return min and max value from 
 */
export const getRange = (array = [], prop = 'valor', minValueProp = 0) => {
    if(!Array.isArray(array)) throw Error("Primer argumento no es array")
    if(typeof prop != 'string') throw Error("segundo argumento no es string")
    if(typeof minValueProp != 'number') throw Error("tercer argumento no es number")

    /** @type {number} */
    const max = _.maxBy(array, (m) => m[prop] && parseFloat(m[prop]));

    /** @type {number} max */
    const min = _.minBy(array, (m) => m[prop] && parseFloat(m[prop]));

    /** @type {number} max */
    const maxValue = max?.[prop] ? parseFloat(max[prop]) : 0;

    /** @type {number} max */
    const minValue = min?.[prop] ? parseFloat(min[prop]) : 0;

    return [
        minValueProp ?? minValue,
        maxValue
    ];
}

/**
 * @param  {string} url @description image as (base64, URI) that we want to convert 
 * @param  {string} filename @description name of file including its extension
 * @param  {string} mimeType='image/png' @description mimetype
 * @returns {Promise<File>} @description return a file
 */
export const base64ToFile = async(url, filename = 'default', mimeType) => {
    const type = url.includes('http') ? url.split('.').pop() : (url.match(/^data:([^;]+);/)||'')[1];
    const finalName = filename.includes('.') ? filename.split('.').shift() : filename
    const extension = type?.split('/').pop()
    const finalFilename = `${finalName}.${extension}`
    return $try(async() => {
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();
        return await new File([buffer], finalFilename, { type });
    })
}