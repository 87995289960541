import { Entity } from '~/domain/entities'

export class Cobrar extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'cliente',
                'proyecto',
                'valor',
                'forma_pago',
                'plazo_pago',
                'pago',
                'fecha_pago'
            ]
        })
    }
}