import { Entity } from '~/domain/entities'

export class CotizacionEnviada extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'referencia',
                'fecha_solicitud',
                'materiales_cotizar', 
                'materiales_cotizados', 
                'fecha_cotizacion', 
                'valor_cotizacion', 
                'dias_credito', 
                'dias_vigencia'
            ]
        })
    }
}