import { UsersVO } from "../UsersVO";

export class UsersVOFactory {
    static instantiate(obj){
        return new UsersVO(obj)
    }

    static instantiateEmpty(){
        return new UsersVO({
            id: '',
            nombre: '',
        })
    }
}