import { Filter } from '~/domain/core/Filter';

export class CompraFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_proyecto',
                'id_proveedor',
                'id_cliente',
                'unidades_min',
                'unidades_max',
                'valor_min',
                'valor_max',
                'fecha_min',
                'fecha_max',
                'id_estado'
            ]
        });
    }
}