import Api from '~/services/api'

export const Material = {
    getCategoryMaterials(category_id, payload){
        const CategoryMaterials = new Api();
        return CategoryMaterials.get(`almacen/categorias-materiales/${category_id}/materiales`, payload);
    },
    getCategoryMaterialsRange(category_id){
        const CategoryMaterials = new Api();
        return CategoryMaterials.get(`almacen/categorias-materiales/${category_id}/materiales/rangos`);
    },
    addCategoryMaterial({category_id, id_materiales}){
        const CategoryMaterial = new Api({ notification: true });
        return CategoryMaterial.post(`almacen/categorias-materiales/${category_id}/materiales`, { id_materiales });
    },
    deleteCategoryMaterial({category_id, category_material_id}){
        const CategoryMaterial = new Api({ notification: true });
        return CategoryMaterial.delete(`almacen/categorias-materiales/${category_id}/materiales/${category_material_id}`)
    },
    selectedMaterials(category_id){
        const CategoryMaterials = new Api();
        return CategoryMaterials.get(`almacen/categorias-materiales/${category_id}/seleccionados`);
    }
}