import * as typeServices from '~/domain/use_cases/configurar/talleres'
import { $try } from '~/services/global';
// state
export const state = {
    services: [],
}

// getters
export const getters = {
    services: state => state.services,
}

// mutations
export const mutations = {
    set_services(state, payload) {
        state.services = payload
    },
    add_service(state, payload){
        state.services.push(payload)
    },
    edit_service(state, payload){
        state.services = state.services.map(e => {
            if (e.id === payload.id) e.nombre == payload.nombre
            return e
        })
    },
    delete_service(state, id){
        state.services = state.services.filter(e => e.id !== id)
    },
}

// actions
export const actions = {
    async Action_get_services({ commit }) {
        const data = await typeServices.GetServicesWorkshopUseCase()
        $try(() => {
            commit('set_services', data)
        });
    },
    async Action_create_service({commit}, payload) {
        const data = await typeServices.CreateServicesWorkshopUseCase(payload)
        $try(() => {
            commit('add_service', data)
        });
    },
    async Action_update_service({ commit }, payload) {
        const services = await typeServices.UpdateServicesWorkshopUseCase({ nombre:payload.nombre, id:payload.id })
        $try(() => {
            if(services) commit('edit_service', payload)
        })
    },
    async Action_delete_services({ commit }, id) {
        const data = await typeServices.DeleteServicesWorkshopUsecase({ id })
        $try(() => {
            if(data) commit('delete_service',id)
        })
    },
    async Action_update_position_services(_,payload) {
        await typeServices.UpdatePositionServicesWorkshopUseCase(payload)
    }
}