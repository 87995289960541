import { UseCase } from "~/domain/core/UseCase";
import { Cotizaciones } from '~/services/users_prividers/cotizacionesPendientes.js'
import { RangeValueMaterialsFactory } from '~/domain/entities/users_providers/factories/RangeValueMaterialsFactory.js'
import { $try } from '~/services/global'

class GetRangeValueMaterialsUseCase extends UseCase {
    static async execute(payload){
        const data =  await Cotizaciones.getRangeValueMaterials(payload)
        return $try(() => {
            if (data?.data) {
                return RangeValueMaterialsFactory.instantiate(data.data)
            }
        })
    }
}

export default GetRangeValueMaterialsUseCase.execute