import { UseCase } from "~/domain/core/UseCase";
import { incrementos } from '~/services/configurar/almacen/incrementos_porcentuales'
import { incrementosFactory } from '~/domain/entities/configurar/almacen/incrementos_porcentuales/factories/incrementosFactory.js'
import { $try } from '~/services/global'

class CreateIncrementosDataTypeUseCase extends UseCase {
    static async execute(payload){
        const data = await incrementos.createIncrementos(payload)
        
        return $try(() => {
            if(data?.data) {
                const { id, nombre, porcentaje, posicion } = data.data
                return incrementosFactory.instantiate({id, nombre, porcentaje, posicion});
            }
        })
    }
}

export default CreateIncrementosDataTypeUseCase.execute