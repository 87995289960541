import { UseCase } from "~/domain/core/UseCase";
import { Work } from '~/services/configurar/obras/obras'
import { FolderFactory } from '~/domain/entities/configurar/obras/carpetas/factories/FolderFactory'
import { $try } from '~/services/global'

class GetFoldersUseCase extends UseCase {
    static async execute(params){
        const data = await Work.getFolders()
        return $try(() => {
            return data?.data?.map(item => {
                const { created_at, created_by, updated_at, updated_by, deleted_at, ...rest } = item
                return FolderFactory.instantiate(rest)
            }) ?? []
        })
    }
}

export default GetFoldersUseCase.execute