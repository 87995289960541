import { HoursMan } from "../HoursMan";

export class HoursManFactory {
    static instantiate(obj){
        return new HoursMan(obj)
    }

    static instantiateEmpty(){
        return new HoursMan({
            id: '',
            valor_hora_hombre: '',
        })
    }
}