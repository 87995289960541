import { getFormatoMoneda } from '~/services/formato_moneda'

export const state = {
    formato_moneda: {}
}

export const getters = {
    formato_moneda: state => state.formato_moneda
}

export const mutations = {
    set_formato_moneda(state, payload){
        state.formato_moneda = payload
    }
}

export const actions = {
    async Action_get_formato_moneda({commit}){
        const data = await getFormatoMoneda()
        if(data) commit('set_formato_moneda', data)
    }
}
