import Api from '~/services/api'


const StockMaterials = {
    listMaterialInStock(payload){
        const ApiHandler = new Api()
        return ApiHandler.get('/almacen/stock', payload)
    },
    listBodegas(){
        const ApiHandler = new Api()
        return ApiHandler.get('/select/bodegas')
    },
    inOutOperation(payload){
        const ApiHandler = new Api()
        return ApiHandler.post('/almacen/stock', payload)
    },
    getMaterialHistory({id}){
        const ApiHandler = new Api()
        return ApiHandler.get(`/almacen/stock/${id}/materiales`)
    },
    getRangoValoresStock(){
        const ApiHandler = new Api()
        return ApiHandler.get('/almacen/stock/rango-valores')
    },
    getCabeceraStockUseCase(payload){
        const ApiHandler = new Api()
        return ApiHandler.get('/almacen/stock/cabecera', payload)
    }

}

export default StockMaterials