import { Filter } from '~/domain/core/Filter';

export class VigentesFilter extends Filter{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'buscar',
                'estado',
                'fecha_inicio',
                'fecha_fin'
            ]
        });
    }
}