import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'
import { CalificacionFactory } from '~/domain/entities/configurar/obras/calificacion_servicio/factories/calificacionFactory'
import { $try } from '~/services/global'

class AddPosQuestionUseCase extends UseCase {
    static async execute(payload){
        const data = await CalificacionService.addPosQuestion(payload)
        return $try(() => {
            if(data?.data) {
                const { id, nombre, id_tipo, posicion } = data.data
                return CalificacionFactory.instantiate({id, nombre, id_tipo, posicion});
            }
        })
    }
}
export default AddPosQuestionUseCase.execute