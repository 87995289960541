import { Filter } from '~/domain/core/Filter';

export class GruposOrdenesCompraFilter extends Filter{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id_oportunidad',
                'id_cliente',
                'id_proveedor',
                'id_bodega',
                'buscar'
            ]
        });
    }
}