import { CotizacionPendienteFilter } from "../CotizacionPendienteFilter";

export class CotizacionPendienteFilterFactory {
    static instantiate(obj){
        return new CotizacionPendienteFilter(obj)
    }

    static instantiateEmpty(){
        return new CotizacionPendienteFilter({
            referencia: null,
            cantidad_materiales_min: 0,
            cantidad_materiales_max: 0,
            fecha_cotizacion_inicio: null,
            fecha_cotizacion_fin: null,
            fecha_vencimiento_inicio: null,
            fecha_vencimiento_fin: null,
        })
    }

    static instantiateEmptyWithRange(range){
        const cantidad_materiales_min = range?.cantidad_materiales_minimo ?? 0
        const cantidad_materiales_max = range?.cantidad_materiales_maximo ?? 0

        const service = new CotizacionPendienteFilter({
            referencia: null,
            cantidad_materiales_min,
            cantidad_materiales_max,
            fecha_cotizacion_inicio: null,
            fecha_cotizacion_fin: null,
            fecha_vencimiento_inicio: null,
            fecha_vencimiento_fin: null,
        })

        service.setExceptions({
            cantidad_materiales_min,
            cantidad_materiales_max,
        })

        return service;
    }
}