import { UseCase } from "~/domain/core/UseCase";
import ViewInfoProvider from '~/services/proveedores/ver/ver'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { ProviderCotizacionesFactory } from '~/domain/entities/proveedores/factories/ProviderCotizacionesFactory';
import { $try } from '~/services/global'

class GetProviderQuotesUseCase extends UseCase {
    static async execute(params){
        const data = await ViewInfoProvider.obtenerCotizacionesProveedor(params)
        
        return $try(()=>{
            return new PaginationDecorator(data.data, ProviderCotizacionesFactory.instantiate).toTable();
        })
    }
}

export default GetProviderQuotesUseCase.execute