import * as FacturasDomain from '~/domain/use_cases/compras/facturas'
import { OrdenCompraFilterFactory } from '~/domain/filters/compras/facturas/factories/OrdenCompraFilterFactory'
import { $try } from '~/services/global'

export const state = {
    id: null,
    facturas: [],
    filtro_proveedores: [],
    filtro_estados: [],
    filtro_clientes: [],
    filtro_oportunidades: [],
    factura: {},
    rango_maximo: 0,
    ordenes_compra: [],
    query: '',
    filter: OrdenCompraFilterFactory.instantiateEmpty(),
    estados: {
        1: 'icon-history text-orange',
        2: 'icon-circle-check text-verde',
        3: 'icon-circle-check text-info'
    },
    id_estados: [
        { id: 1, name: 'Borrador' },
        { id: 2, name: 'Pendiente' },
        { id: 3, name: 'Entrega parcial' },
        { id: 4, name: 'Entrega completa' }
    ]
}

export const getters = {
    facturas: state => state.facturas,
    filtro_proveedores: state => state.filtro_proveedores,
    filtro_estados: state => state.filtro_estados,
    filtro_clientes: state => state.filtro_clientes,
    filtro_oportunidades: state => state.filtro_oportunidades,
    rango_maximo: state => state.rango_maximo,
    factura: state => state.factura,
    ordenes_compra: state => state.ordenes_compra,
    filter: state => state.filter,
    estados: state => state.estados,
    id_estados: state => state.id_estados,
    query: state => state.query,
    id: state => state.id,
}

export const mutations = {
    set_facturas(state, facturas){
        state.facturas = facturas
    },
    set_filtro_proveedores(state, filtro_proveedores){
        state.filtro_proveedores = filtro_proveedores
    },
    set_filtro_estados(state, filtro_estados){
        state.filtro_estados = filtro_estados
    },
    set_filtro_clientes(state, filtro_clientes){
        state.filtro_clientes = filtro_clientes
    },
    set_filtro_oportunidades(state, filtro_oportunidades){
        state.filtro_oportunidades = filtro_oportunidades
    },
    set_factura(state, factura){
        state.factura = factura
    },
    set_ordenes_compra(state, ordenes_compra){
        state.ordenes_compra = ordenes_compra
    },
    set_filter(state, filter){
        state.filter = filter
    },
    set_rango_maximo(state, rango_maximo){
        state.rango_maximo = rango_maximo
    },
    set_query(state, query){
        state.query = query
    },
    set_id(state, payload){
        state.id = payload
    }
}

export const actions = {
    async Action_get_facturas({state, commit}, filters){
        const facturas = await FacturasDomain.GetBillsUseCase(state.id, filters)
        $try(
            () => commit('set_facturas', facturas)
        )
    },
    async Action_get_filtro_proveedores({commit}){
        const proveedores = await FacturasDomain.GetFilterProviderUseCase()

        $try(
            () => commit('set_filtro_proveedores', proveedores)
        )
    },
    async Action_get_filtro_estados({commit}){
        const estados = await FacturasDomain.GetFilterStateUseCase()

        $try(
            () => commit('set_filtro_estados', estados)
        )
    },
    async Action_get_filtro_clientes({commit}){
        const clientes = await FacturasDomain.GetFilterClienteUseCase()
        $try(
            () => commit('set_filtro_clientes', clientes)
        )
    },
    async Action_get_filtro_oportunidades({commit}){
        const oportunidades = await FacturasDomain.GetFilterOportunidadUseCase()
        $try(
            () => commit('set_filtro_oportunidades', oportunidades)
        )
    },
    async Action_get_rango_maximo({commit}, id){
        const rango_maximo = await FacturasDomain.GetFilterRangoMaximoUseCase(id)
        $try(
            () => commit('set_rango_maximo', rango_maximo)
        )
    },
    async Action_get_factura({commit}, params){
        const factura = await FacturasDomain.GetFacturaUseCase(params)

        $try(
            () => commit('set_factura', factura)
        )
    },
    async Action_get_ordenes_compra({ state, commit}, id){
        const filter = state.filter.clean()
        const buscar = state.query
        const ordenes_compra = await FacturasDomain.GetOrdenesCompraUseCase(id, {...filter, buscar })

        $try(
            () => commit('set_ordenes_compra', ordenes_compra)
        )
    },
    clear_filter({ state, commit}){
        commit('set_filter', OrdenCompraFilterFactory.instantiateEmpty(state.rango_maximo))
    }
}