import { UseCase } from "~/domain/core/UseCase";
import PermisosAccionesList from "~/services/configurar/permisos/acciones"
import { PermisoRutaFactory } from '~/domain/entities/configurar/permisos/factories/PermisoRutaFactory'
import { $try } from '~/services/global'
 
class AddRolPermisosRutaUseCase extends UseCase { 
    static async execute(params){ 
        const { data } =  await PermisosAccionesList.addRolPermisosRuta(params)

        return $try( 
            () => PermisoRutaFactory.instantiate(data)
        )
    } 
}

export default AddRolPermisosRutaUseCase.execute

