import { UseCase } from "~/domain/core/UseCase"
import { Pedidos } from '~/services/almacen/pedidos'
import { PedidoInternoMaterialFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoMaterialFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator';
import { $try } from "~/services/global"

class GetPedidosInternosMaterialesUseCase extends UseCase{
    static async execute(id, payload){
        const data =  await Pedidos.getPedidosInternosMateriales(id, payload)
        return $try(() => {
            return new PaginationDecorator(data.data, PedidoInternoMaterialFactory.instantiate);
        })
    } 
}

export default GetPedidosInternosMaterialesUseCase.execute