import { CotizacionFilter } from "../CotizacionFilter";

export class CotizacionFilterFactory {
    static instantiate(obj){
        return new CotizacionFilter(obj)
    }

    static instantiateEmpty(){
        return new CotizacionFilter({
			id_proveedor: '',
			materiales_no_cotizados: null,
			materiales_cotizados: null,
			id_tipo_material: '',
			ordenar_por: null,
        })
	}
}