import Api from '~/services/api'

const ApiHandler = new Api()

export const Gallery = {
    // folders
    getFolders(payload) {
        return ApiHandler.get(`obras/${payload.id_obra}/obras-carpetas/${payload.idTipo}`)
    },
    createFolder(payload) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/${payload.id_obra}/obras-carpetas`, payload)
    },
    editeFolder(payload) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`obras/${payload.id_obra}/obras-carpetas/${payload.id}`, payload)
    },
    deleteFolder(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.delete(`obras/${payload.idProyecto}/obras-carpetas/${payload.idCarpeta}`)
    },
    // folder files
    getFolderFiles0(id_carpeta) {
        return ApiHandler.get(`obras/${id_carpeta}/obras-carpetas-archivos`)
    },
    getFolderFiles(payload) {
        return ApiHandler.get(`obras/${payload.idProyecto}/carpetas/${payload.idCarpeta}/archivos`)
    },
    saveFile(payload) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/${payload.idProyecto}/carpetas/${payload.idCarpeta}/archivos`, payload)
    },
    editFolderFile(payload) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`obras/${payload.idProyecto}/carpetas/${payload.idCarpeta}/archivos/${payload.id_obras_carpetas_archivos}`, payload)
    },
    deleteFolderFile(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.delete(`obras/${payload.idProyecto}/carpetas/${payload.idCarpeta}/archivos/${payload.idArchivo}`)
    },
}
