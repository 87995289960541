import { PedidoInterno } from "../PedidoInterno";

export class PedidoInternoFactory {
    static instantiate(obj) {
        return new PedidoInterno(obj);
    }

    static instantiateEmpty() {
        return new PedidoInterno({
            id: '',
            id_proyecto: '',
            proyecto: '',
            id_equipo: '',
            id_equipo_modelo: '',
            equipo: '',
            fecha_solicitud: '',
            solicita: '',
            fecha_llegada: ''
        });
    }
}