import { Entity } from '~/domain/entities'

export class StockMateriales extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
				'unidades',
				'comentario',
				'id_bodega',
				'tipo',
				'fecha_ultimo_movimiento',
				'id_material',
				'nombre',
				'referencia',
				'valor',
				'valor_total',
				'tipo_material',
				'unidad_medida',
				'estado',
				'unidades_disponibles'
            ]
        })
    }
}