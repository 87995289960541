import { CheckList } from "../CheckList";

export class CheckListFactory {
    static instantiate(obj) {
        return new CheckList(obj)
    }

    static instantiateEmpty() {
        return new CheckList({
            id: undefined,
            nombre: '',
            actividades: undefined,
        })
    }
}