import { PedidoInternoFiltros } from "../PedidoInternoFiltros";

export class PedidoInternoFiltrosFactory {
    static instantiate(obj) {
        return new PedidoInternoFiltros(obj);
    }

    static instantiateEmpty() {
        return new PedidoInternoFiltros({
            proyectos: '',
            equipos: '',
            solicitantes: ''
        });
    }
}