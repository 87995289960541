<template>
    <div class="main-layout bg-tema" style="height:100vh;">
        <navbar />
        <navigation-drawer />
        <div class="position-relative bg-tema app-wrapper-fs" style="min-width: 45rem;">
            <child />
        </div>
    </div>
</template>

<script>
export default {
    name: 'fs',
    components: {
        
    },
    methods:{
        
    }
}
</script>
<style lang="scss">
main{
    padding-left: 65px !important
}
.app-wrapper-fs {
    padding-left: 65px;
    
    min-height: calc(100vh - 55px);
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
</style>
