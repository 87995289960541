import { UseCase } from "~/domain/core/UseCase";
import { OrdenesCompras } from '~/services/obras/ordenes_compras/ordenesCompras'
import { $try } from '~/services/global'

class GetOrdenCompraUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await OrdenesCompras.getOrdenCompra(payload)
        return $try(() => {
            if(data?.data){
				return data.data
            }
        })
    }
}

export default GetOrdenCompraUseCase.execute