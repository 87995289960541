import { ProviderMaterialFilterFactory } from "~/domain/filters/proveedores/materiales/factories/ProviderMaterialFilterFactory"

export const state = {
    filter: ProviderMaterialFilterFactory.instantiateEmpty()
}

export const getters = {
    filter: state => state.filter
}

export const mutations = {
    set_filter(state, payload){
        state.filter = payload
    }
}

export const actions = {
    clear_filter({commit}){
        commit('set_filter', ProviderMaterialFilterFactory.instantiateEmpty())
    }
}