import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import mixins from './mixins'
import './components'
import './library'
import './plugins'
import './filters'

// import './assets/sass/personalizados/element-variables.scss'

Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

// register all mixins globally
Vue.mixin(...mixins);

new Vue({
    el: '#app',
    store,
    router,
    ...App
})
