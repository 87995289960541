import * as Bodega from '~/domain/use_cases/bodegas/bodega'
import { BodegasFilterFactory } from '~/domain/filters/almacen/bodegas/factories/bodegasFilterFactory'
import { $try } from '~/services/global'

export const state = {
    bodegas: [],
    bodega: {},
    purchaseOrders: {},
    filters: BodegasFilterFactory.instantiateEmpty()
}

export const getters = {
    bodegas: state => state.bodegas,
    bodega: state => state.bodega,
    purchaseOrders: state => state.purchaseOrders,
    filters: state => state.filters,
}

export const mutations = {
    setBodegas(state, payload){
        state.bodegas = payload
    },
    setBodega(state, payload){
        state.bodega = payload
    },
    setPurchaseOrders(state, payload){
        state.purchaseOrders = payload
    },
    setFilters(state, payload){
        state.filters = payload
    },
    clearFilters(state, payload){
        state.filters = payload
    }
}

export const actions = {
    async Action_get_bodegas({commit}, pag){
        const filter = {...state.filters.clean(), ...pag}
        const data = await Bodega.GetBodegasUseCase(filter)
        $try ( () => { commit('setBodegas', data) } )
    },
    async Action_get_bodega({commit}, id){
        const data = await Bodega.GetBodegaUseCase(id)
        $try (() => {commit('setBodega', data)})
    },
    async Action_warehouse_purchase_orders({commit}, payload){
        const data = await Bodega.WarehouseDetailPurchaseOrdersUseCase(payload)
        $try (() => {commit('setPurchaseOrders', data)})
    },
    async Action_create_bodega(_, payload){
        await Bodega.CreateBodegaUseCase(payload)
    },
    async Action_update_bodega(_, params){
        await Bodega.UpdateBodegaUseCase(params)
    },
    Action_clear_filters({ commit }, payload){
        let rangeValue = [payload.valor_minimo, payload.valor_maximo]
        let rangeCant = [payload.materiales_minimo, payload.materiales_maximo]
        $try( () => { commit('clearFilters', BodegasFilterFactory.instantiateEmptyWithRange(rangeValue, rangeCant))})
    },
}