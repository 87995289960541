import { Entity } from '~/domain/entities'

export class Material extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'bodega',
                'cantidad_material'
            ]
        })
    }
}