import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { ProductFactory } from '~/domain/entities/compras/matriz/factories/ProductFactory'
import { $try } from '~/services/global'

class GetOpportunityProductsUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data } =  await Matriz.getOpportunityProducts(params)

        $try(() => {

            if(data.length){
                data.forEach((item) =>{
                    customData.push(ProductFactory.instantiate(
                        {
                            id: item.id,
                            nombre: item.nombre,
                            imagen: item.imagen,
                            tabla:item.tabla,
							id_equipo:item.id_equipo,
                            alerta_config: item.alerta_config,
                            alerta_etapa: item.alerta_etapa,
                            alerta_material: item.alerta_material
                        }
                    ))
                })
            }

        })

        return customData
    }
}

export default GetOpportunityProductsUseCase.execute