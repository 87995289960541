import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'

class DeleteQuestionOptionsUseCase extends UseCase {
    static async execute(id){
        const data = await CalificacionService.deleteOptionQuestion(id)
        return data?.message 
    }
}

export default DeleteQuestionOptionsUseCase.execute