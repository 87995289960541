import { Entity } from '~/domain/entities'

export class CalendarioSemana extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'title',
                'start',
                'className'
            ]
        })
    }
}