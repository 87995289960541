import { Archivos } from "../Archivos";

export class ArchivosFactory {
  static instantiate(obj) {
    return new Archivos(obj);
  }

  static instantiateEmpty() {
    return new Archivos({
      id: "",
      nombre: "",
      ruta: "",
      id_tipo: "",
      tipo: "",
      visualizador_archivo: "",
      id_control_piso_documentacion_carpetas: "",
      created_by: "",
      created_at: "",
      user: ""
    });
  }
}
