import { UseCase } from "~/domain/core/UseCase";
import { Bodegas } from '~/services/bodegas/bodegas';
import { $try } from '~/services/global';

class GetBodegaUseCase extends UseCase {
    static async execute(id){
        const  data  =  await Bodegas.getBodegaDetail(id)
        
        return $try(() => {
            return data?.data ?? {}
        })
    }
}
export default GetBodegaUseCase.execute