import { Entity } from '~/domain/entities'

export class Steps extends Entity{
    constructor(obj, otherKeys = []){
        super({
            params: obj, 
            keys: [
                'id', 
                'posicion', 
                'titulo', 
                'descripcion', 
                'id_materiales_mantenimientos_actividad', 
                ...otherKeys
            ]
        });
    }
}