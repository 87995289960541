import * as WorkshopDomain from '~/domain/use_cases/talleres'
import { WorkshopFilterFactory } from '~/domain/filters/talleres/factories/WorkshopFilterFactory'
import store from '~/store'

export const state = {
    query: '',
    filter: WorkshopFilterFactory.instantiateEmpty(),
    workshops: [],
    range: {},
    services_graph: [],
    services_types_graph: [],
    select_responsible_users: [],
    id_taller: null,
}

export const getters = {
    filter: state => state.filter,
    workshops: state => state.workshops.data,
    pagination: state => state.workshops.pagination,
    range: state => state.range,
    services_graph: state => state.services_graph,
    services_types_graph: state => state.services_types_graph,
    select_responsible_users: state => state.select_responsible_users,
    query: state => state.query,
    id_taller: state => state.id_taller,
}

export const mutations = {
    set_filter(state, payload){
        state.filter = payload
    },
    set_workshops(state, payload){
        state.workshops = payload
    },
    set_range(state, payload){
        state.range = payload
    },
    set_services_graph(state, payload){
        state.services_graph = payload
    },
    set_services_types_graph(state, payload){
        state.services_types_graph = payload
    },
    set_select_responsible_users(state, payload){
        state.select_responsible_users = payload
    },
    set_query(state, payload){
        state.query = payload
    },
    set_id_taller(state, payload){
        state.id_taller = payload
    }
}

export const actions = {
    async Action_get_workshops({ state, commit }, payload){
        const filter = state.filter.clean()
        const buscar = state.query || null
        const data = await WorkshopDomain.GetWorkshopsUseCase({ ...filter, ...payload, buscar: buscar})
        if(data) commit('set_workshops', data)
    },
    async Action_get_service_range({ state, commit }){
        const data = await WorkshopDomain.GetServiceRangeUseCase()
        if(data) commit('set_range', data)
    },
    async Action_get_services_graph({ commit }){
        const data = await WorkshopDomain.GetServicesGraphUseCase()
        if(data) commit('set_services_graph', data)
    },
    async Action_get_services_types_graph({ commit }){
        const data = await WorkshopDomain.GetServicesTypesGraphUseCase()
        if(data) commit('set_services_types_graph', data)
    },
    async Action_get_select_responsible_users({ commit }){
        const data = await WorkshopDomain.GetSelectResponsibleUsersUseCase()
        if(data) commit('set_select_responsible_users', data)
    },
    async Action_save_workshop({ state, dispatch }, payload){
        const data = await WorkshopDomain.SaveWorkshopUseCase(payload)
        if(!data) return

        const isLastPage = state.workshops.pagination.current_page == state.workshops.pagination.last_page

        if(!isLastPage) return dispatch('Action_get_service_range')

        await dispatch('Action_get_workshops', { page: state.workshops.pagination.current_page })
        dispatch('Action_get_service_range')
    },
    async Action_update_workshop({ state }, payload){
        const data = await WorkshopDomain.UpdateWorkshopUseCase({id_taller: state.id_taller, payload })
        if(!data) return

        store.commit('talleres/ver/set_workshop', data)
    },
    async Action_delete_workshop(_, id_taller){
        await WorkshopDomain.DeleteWorkshopUseCase(id_taller)
    },
    async Action_save_functionary({ commit }, payload){
        const data = await WorkshopDomain.SaveFunctionaryUseCase(payload)
        if(data) store.commit('selects/selects/set_select_responsible_workshops', [...store.state['selects/selects'].select_responsible_workshops, data])
    },
    clear_filter({ state, commit, dispatch }){
        commit('set_filter', WorkshopFilterFactory.instantiateEmpty(state.range))
        dispatch('Action_get_workshops')
    }
}