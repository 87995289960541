import { UseCase } from "~/domain/core/UseCase";
import { Equipos } from '~/services/obras/equipos/equipo'
import { EtapaFactory } from '~/domain/entities/obras/equipos/factory/etapaFactory'
import { $try } from '~/services/global'

class AddEtapasUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await Equipos.addEtapas(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new EtapaFactory.instantiate(e)) 
        })
    }
}

export default AddEtapasUseCase.execute