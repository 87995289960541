import { WorkshopFilter } from "../WorkshopFilter";

export class WorkshopFilterFactory {
    static instantiate(obj) {
        return new WorkshopFilter(obj);
    }

    static instantiateEmpty() {
        return new WorkshopFilter({
            id_cliente: null,
            fecha_inicio: '',
            fecha_fin: '',
            valor_minimo: 0,
            valor_maximo: 0,
        });
    }

    static instantiateEmptyWithRange(range) {
        const valor_minimo = range?.min ?? 0;
        const valor_maximo = range?.max ?? 0;
        
        const filter = new WorkshopFilter({
            id_cliente: null,
            fecha_inicio: '',
            fecha_fin: '',
            valor_minimo,
            valor_maximo,
        });

        filter.setExceptions({
            valor_minimo,
            valor_maximo,
        });

        return filter
    }
}