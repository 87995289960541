import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'

class EditQuestionsUseCase extends UseCase {
    static async execute(params){
        const data = await CalificacionService.editQuestion(params)
        return data?.data
    }
}

export default EditQuestionsUseCase.execute