import { render, staticRenderFns } from "./valores.vue?vue&type=template&id=fe2f0938&scoped=true&"
import script from "./valores.vue?vue&type=script&lang=js&"
export * from "./valores.vue?vue&type=script&lang=js&"
import style0 from "./valores.vue?vue&type=style&index=0&id=fe2f0938&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe2f0938",
  null
  
)

export default component.exports