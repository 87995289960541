import { UseCase } from '~/domain/core/UseCase'
import { Material } from "~/services/almacen/categorias/materiales"
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { MaterialFactory } from '~/domain/entities/almacen/materiales/factories/MaterialFactory'

class GetCategoryMaterialListUseCase extends UseCase{
    static async execute(category_id, payload){
        const data = await Material.getCategoryMaterials(category_id, payload)
        if(data?.data) {
            return new PaginationDecorator(
                data.data,
                MaterialFactory.instantiateCategory
            ).toTable()
        }
    }
}

export default GetCategoryMaterialListUseCase.execute