import { UseCase } from "~/domain/core/UseCase";
import { incrementos } from '~/services/configurar/almacen/incrementos_porcentuales'
import { $try } from '~/services/global'

class DeleteIncrementosDataTypeUseCase extends UseCase {
    static async execute(payload){
        const data = await incrementos.deleteIncrementos(payload)
        
        return $try(() => data?.message)
    }
}

export default DeleteIncrementosDataTypeUseCase.execute