import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { OpportunityFactory } from '~/domain/entities/oportunidades/factories/OpportunityFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetOpportunityesUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getOpportunity(payload)
        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    OpportunityFactory.instantiate
                ).toTable();
            }
        })
    }
}

export default GetOpportunityesUseCase.execute