
import Api from '~/services/api'



export const CheckList = {
    getCheckList() {
        const ApiHandler = new Api()
        return ApiHandler.get('configuracion/obras/lista-chequeo')
    },
    createCheckList(payload) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post('configuracion/obras/lista-chequeo', payload)
    },
    editCheckList(payload) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`configuracion/obras/lista-chequeo/${payload.id}`, {"nombre":payload.nombre})
    },
    deleteCheckList(id) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`configuracion/obras/lista-chequeo/${id}`)
    },
    // activities check list
    getActivitiesCheckList() {
        return ApiHandler.get('configuracion/obras/lista-chequeo-actividades')
    },
    createActivityCheckList(payload) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post('configuracion/obras/lista-chequeo-actividades', payload)
    },
    editActivityCheckList(payload) {
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`configuracion/obras/lista-chequeo-actividades/${payload.id}`, payload)
    },
    deleteActivityCheckList(id) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.delete(`configuracion/obras/lista-chequeo-actividades/${id}`)
    },
    changePositionActivityCheckList(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/lista-chequeo-actividades/posicion', payload)
    },
    updatePositionCheckList(payload) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('configuracion/obras/lista-chequeo/posicion', payload)
    },
}
