import Cookies from 'js-cookie'
import { $try } from '~/services/global'

import * as Auth from '~/domain/use_cases/auth'
// state
export const state = {
    user: null,
    token: Cookies.get('token'),
    logged: false,
    email: null,
    username: null, 
    hidden_email: null,
    bucket: process.env.VUE_APP_BUCKET
}

// getters
export const getters = {
    user: state => state.user,
    token: state => state.token,
    check: state => state.user !== null,
    email: state => state.email,
    username: state =>  state.username,
    logged: state => state.logged,
    hidden_email: state => state.hidden_email,
    bucket: state => state.bucket,
}

// mutations
export const mutations = {
    SET_LOGGED(state, payload){
        state.logged = payload;
    },
    SET_TOKEN(state, { token, remember }){
        state.token = token
        const myCookie = Cookies.get('token');
        if (myCookie) Cookies.remove('token');
        Cookies.set('token', token, { expires: remember ? 365 : null });
    },
    SET_USER(state, payload){
        state.user = payload;
    },
    SET_USER_FAILURE(state){
        state.user = null;
        state.token = null;
        Cookies.remove('token');
    },
    SET_EMAIL(state, payload){
        state.email = payload
    },
    SET_HIDDEN_EMAIL(state, payload){
        state.hidden_email = payload
    },
    SER_USERNAME(state, payload){
        state.username = payload
    },
    LOGOUT(state){
        state.user = null;
        state.token = null;
        Cookies.remove('token');
    },
}

// actions
export const actions = {
    SAVE_TOKEN({ commit, dispatch }, token){
        $try(() =>  commit('SET_TOKEN', { token }));
    },
    NO_AUTHENTICATED_USER({ commit }){
        $try(() => commit('SET_USER_FAILURE'))
    },
    HIDE_EMAIL({commit}, hidden_email){
        $try(() => commit('SET_HIDDEN_EMAIL', hidden_email))
    },
    async FETCH_USER({ commit }){        
        const userData = await Auth.GetUserUseCase()
        $try(
             () => {
                if(userData) commit('SET_USER', userData)
            },
            () => commit('SET_USER_FAILURE')
        );
    },
    async LOGIN({commit, dispatch}, payload){
        const token = await Auth.LoginUseCase(payload)
        $try(() => {
            if(token) commit('SET_TOKEN', { token: token})
        });
    },
    async LOGOUT({ commit }){
        const logout = await Auth.LogoutUseCase()
        $try(() => { 
            if(logout) commit('LOGOUT')
        });
    },
    async LOGOUTPROVEEDORES({ commit }){
        const logout = await Auth.LogoutProveedoresUseCase()
        $try(() => { 
            if(logout) commit('LOGOUT')
        });
    },
    async RECOVER_PASSWORD({ commit }, payload){
        const data = await Auth.RecoverPasswordUseCase(payload)
        return $try(async () => {
            if(data?.error?.message) return { error: data.error.message }
            if(data?.data) return commit('SET_EMAIL', data.data)
        });
    },
    async RECOVER_USERNAME({ commit }, payload){
        const data = await Auth.RecoverUsernameUseCase(payload)
        return $try(async () => {
            if(data?.error?.message) return { error: data.error.message }
            if(data?.data) return commit('SET_EMAIL', data.data)
        })
        
    },
    async VALIDATE_CODE({ commit }, payload){
        const data = await Auth.ValidateCodeUseCase(payload)
        return $try(async () => {
            if(data?.error) return { error: data.error };
            if(data?.data?.username) commit('SER_USERNAME', data.data.username);
        })
    },
    async CHANGE_PASSWORD({commit}, payload){
        await Auth.ChangePasswordUseCase(payload)
    }
}
