import { AttachmentVO } from "../AttachmentVO";

export class AttachmentVOFactory {
    static instantiate(obj){
        return new AttachmentVO(obj)
    }

    static instantiateEmpty(){
        return new AttachmentVO({
            nombre: '',
            archivo: '',
            descripcion: '',
            id_materiales_mantenimientos_actividades: '',
            actividad: '',
        })
    }
}