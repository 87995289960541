<template>
    <div class="row mx-0">
        <div v-for="(data,idx) in datosAdicionales" :key="idx" class="col-12 col-lg-12 col-xl-10 my-1">
            <div class="row border-dato br-3 mx-0">
                <div class="col-lg-5 py-2">
                    <p class="f-12 text-muted2 f-600"> {{ data.nombreDato }}: </p>
                </div> 
                <div class="col-lg-7 py-2">
                    <p class="f-12 text-muted2"> {{ data.descripcion }} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datosAdicionales: {
            type: Array,
            default: () => []
        }
    },

}
</script>

<style>

</style>