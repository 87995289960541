import { Entity } from '~/domain/entities'

export class Equipos extends Entity {
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'imagen',
                'nombre',
                'precio',
                'etapas',
                'materiales',
                'horas_hombre',
                'id_ops'
            ]
        });
    }
}