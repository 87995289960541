import { Entity } from '~/domain/entities'

export class ListarMaterialesConfiguracion extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'nombre', 
                'valor', 
                'tipo_material', 
            ]
        })
    }
}