import { OrdenCompraFilter } from "../OrdenCompraFilter"

export class OrdenCompraFilterFactory {
    static instantiate(obj){
        return new OrdenCompraFilter(obj)
    }

    static instantiateEmpty(rango_maximo){
        const valor_final = rango_maximo ?? 0;
        const filter = new OrdenCompraFilter({
            id_oportunidad: null,
            id_proveedor: null,
            id_cliente: null,
            fecha_inicio: '',
            fecha_fin: '',
            valor_inicial: 0,
            valor_final,
            id_estado: null,
        })
        filter.setExceptions({
            valor_inicial: 0,
            valor_final,
        })
        return filter
    }
}