import { Entity } from '~/domain/entities'

export class Equipos extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'id_ops',
                'nombre',
                'tabla',
                'imagen'
            ]
        })
    }
}