import { Entity } from '~/domain/entities'

export class Informacion extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'nombre',
                'cliente',
                'logo',
                'fecha_inicio',
                'fecha_fin',
                'numero_equipos',
                'numero_ordenes_compra',
                'porcentaje',
                'user_responsable',
                'descripcion',
                'id_oportunidad',
                'oportunidad',
                'valor',
                'numero',
                'historial'
            ]
        })
    }
}


