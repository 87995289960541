import { UseCase } from "~/domain/core/UseCase";
import { Documentacion } from "~/services/obras/servicios/documetacion";
import { $try } from "~/services/global";

class DeleteDocumentoCarpetaUseCase extends UseCase {
    static async execute(params){
        const data = await Documentacion.deleteDocumentosCarpetas(params)
        
        return $try(() => data?.message)
    }
}

export default DeleteDocumentoCarpetaUseCase.execute;