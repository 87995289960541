import { Compra } from "../Compra";

export class CompraFactory {
    static instantiate(obj){
        return new Compra(obj)
    }

    static instantiateEmpty(){
        return new Compra({
            id: '',
            proyecto: '',
            proveedor: '',
            cliente: '',
            unidades: '',
            fecha: '',
            valor: '',
            pago: ''
        })
    }
}