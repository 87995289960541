import { ProveedoresFilter } from "../proveedoresFilter";

export class ProveedoresFilterFactory {
    static instantiate(obj) {
        return new ProveedoresFilter(obj);
    }

    static instantiateEmpty() {
        return new ProveedoresFilter({
            buscar: '',
            ciudad: null,
            id_proveedor:null,
            id_responsable:null
        });
    }

}