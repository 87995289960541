<template>
    <div class="main-layout">
        <navbar />
        <main-menu/>
        <navigation-drawer />
        <div class="position-relative app-wrapper my-3">
            <div class="contenedor p-2 bg-white custom-scroll">
                <div style="width:inherit">
                    <child />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'MainLayout',
    async created () {
        await this.Action_get_permision()
    },
    methods: {
        ...mapActions({
            Action_get_permision: 'personalizacion/Action_get_permision'
        })
    },
}
</script>
<style lang="scss" >
main{
    padding-left: 65px !important
}
.app-wrapper {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: var(--color-5d);
    height: calc(100vh - 164px);
    width:calc(100vw - 82px);
    margin-left: auto;
    top: 66px;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
.contenedor{
    min-height: 95%;
    width: 98%;
    height: inherit;
    box-shadow: 0 3px 6px 0 #00000029;
    border-radius: 4px;
    overflow-y: scroll;
}
</style>
