import { UseCase } from "~/domain/core/UseCase";
import MaterialTypes from "~/services/configurar/almacen/tipos_material"
import { $try } from '~/services/global'

class DeleteMaterialTypeUseCase extends UseCase {
    static async execute(params){
        const { id } = params
        const data = await MaterialTypes.deleteMaterialType({id})
        
        return $try(() => data?.message)
    }
}

export default DeleteMaterialTypeUseCase.execute