import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivities } from "~/services/almacen/mantenimiento/actividades"
import { CalendarVOFactory } from '~/domain/value_objects/almacen/mantenimiento/actividades/factories/CalendarVOFactory'
import { $try } from '~/services/global'

class GetCalendarTypesUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const data = await MaintenanceActivities.getActivityCalendarTypes()   

        $try(() => {
            if(data?.data) customData = data.data.map(item => CalendarVOFactory.instantiate(item))
        })

        return customData
    }
}

export default GetCalendarTypesUseCase.execute

