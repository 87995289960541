import { Entity } from '~/domain/entities'

export class Ciclos extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'ciclo',
                'fecha',
                'dias',
                'color',
                'estado',
                'id_ops'
            ]
        });
    }
}