import { UseCase } from '~/domain/core/UseCase'
import { Material } from "~/services/almacen/categorias/materiales"

class DeleteCategoryMaterialUsecase extends UseCase {
    static async execute(payload){
        const data = await Material.deleteCategoryMaterial(payload);

        return data?.message
    }
}

export default DeleteCategoryMaterialUsecase.execute