import { Entity } from '~/domain/entities'
import moment from 'moment'
export class Matriz extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['fecha', 'lugar', 'flete', 'oportunidad', 'descripcion', 'proveedores', 'bodegas']
        });

        this.fecha = moment(this.fecha).format('YYYY-MM-DD')
    }
}