import { UseCase } from "~/domain/core/UseCase";
import { OrdenesCompras } from '~/services/obras/ordenes_compras/ordenesCompras'
import { ValorMaterialFactory } from '~/domain/entities/obras/ordenes_compra/factories/ValorMaterialFactory'
import { $try } from '~/services/global'

class GetValorInventarioMaterialPvsUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await OrdenesCompras.getValorMaterialPvs(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new ValorMaterialFactory.instantiate(e))
        })
    }
}

export default GetValorInventarioMaterialPvsUseCase.execute