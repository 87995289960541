import Api from '~/services/api'

export const Cotizaciones = {
    getInfoDetalleCotizacion(idCotizacion){
        const ApiHandler = new Api()
        return ApiHandler.get(`/cotizaciones/${idCotizacion}`)
    },
    getMaterialesCotizacion(params){
        const ApiHandler = new Api()
        return ApiHandler.get(`/cotizaciones/${params.idCotizacion}/materiales`, params.payload)
    },
    editMaterialCotizacion(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`/cotizaciones/vigentes/${params.idCotizacion}/materiales`, params.payload)
    },
    enviarCotizacion(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`/cotizaciones/vigentes/${params.idCotizacion}/enviar`, params.payload)
    },
    getRangosMateriales(idCotizacion){
        const ApiHandler = new Api()
        return ApiHandler.get(`/cotizaciones/${idCotizacion}/filtros`)
    },
}