import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { UserSolicitaVOFactory } from '~/domain/value_objects/almacen/pedidos/factories/UserSolicitaVOFactory'
import { $try } from '~/services/global'

class GetSelectWineriesUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectWineries()
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => UserSolicitaVOFactory.instantiate(e))
        })
    }
}

export default GetSelectWineriesUseCase.execute