import { ValueObject } from '~/domain/core/ValueObject'

export class InformacionOcs extends ValueObject{
    constructor(obj) {
        super({
            params: obj,
            keys: [
            'id',
            'estado', 
            'fecha_actualizacion',
            'proveedor',
            'valor',
            'fecha_creado',
            'proyecto',
            'clientes',
            'facturas',
            'valor_pagado',
            'valor_pagado_fecha',
        ]
        })
    }
}