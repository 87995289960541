import { CountryFilter } from "../CountryFilter";

export class CountryFilterFactory {
    static instantiate(obj){
        return new CountryFilter(obj)
    }

    static instantiateEmpty(){
        return new CountryFilter({
            pais: '',
            utilidad_minima: 0,
            utilidad_maxima: 0,
        })
    }
}