<template>
    <div v-if="pagination">
        <el-pagination
            :current-page.sync="pagination.current_page"
            :page-size.sync="pagination.per_page"
            :total="pagination.total"
            :layout="layout"
            @current-change="paginate"
            @size-change="paginate"
        />
    </div>
</template>

<script>
export default {
    props: {
        pagination: { type: Object, default: () => {} },
        layout: { type: String, default: 'prev, pager, next, jumper'}
    },
    methods: {
        paginate(pag) {
            this.$emit('paginate', pag);
        }
    },
}
</script>