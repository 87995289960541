import * as MaterialDomain from '~/domain/use_cases/almacen/materiales'
import * as DatosAdicionalesDomain from '~/domain/use_cases/configurar/almacen/datos_adicionales'
import { MaterialFilterFactory } from '~/domain/filters/almacen/materiales/factories/MaterialFilterFactory';
import { $try } from '~/services/global';

export const state = {
    material: {},
    materials: [],
    aditional_data:[],
    aditional_data_types:[],
    filter: MaterialFilterFactory.instantiateEmpty(),
    query: '',
    range: [],
    graphic_cotization: []
}

export const getters = {
    range: state => state.range,
    filter: state => state.filter,
    query: state => state.query,
    material: state => state.material,
    materials: state => state.materials,
    aditional_data: state => state.aditional_data,
    aditional_data_types: state => state.aditional_data_types,
    graphic_cotization: state => state.graphic_cotization
}

export const mutations = {
    set_material(state, payload){
        state.material = payload
    },
    set_materials(state, payload){
        state.materials = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_query(state, payload){
        state.query = payload
    },
    set_range(state, payload){
        state.range = payload
    },
    set_additional_data(state, payload){
        state.aditional_data = payload
    },
    set_additional_data_types(state, payload){
        state.aditional_data_types = payload
    },
    toggle_material_state(state){
        state.material.estado = state.material.estado == 0 ? 1 : 0
    },
    set_graphic_cotization(state, payload){
        state.graphic_cotization = payload
    },
}

export const actions = {
    async Action_get_materials({state, commit}, page){
        const filter = state.filter.clean();
        const buscar = state.query;
        const data = await MaterialDomain.GetMaterialsUseCase({...filter, buscar ,page }) 
        $try(() => {
            if(data) commit('set_materials', data);
        });
    },
    async Action_get_materials_range({ commit, dispatch }){
        const data = await MaterialDomain.GetMaterialsRangeUseCase()
        $try(() => {
            if(data) commit('set_range', data)
            dispatch('clear_filter')
        })
    },
    async Action_create_material({commit}, payload){
        const data = await MaterialDomain.CreateMaterialUseCase(payload)
        return data?.id
    },
    async Action_update_material({commit}, payload){
        const data = await MaterialDomain.UpdateMaterialUseCase(payload)
        return data?.id
    },
    async Action_get_material({commit}, id){
        const data = await MaterialDomain.ShowMaterialUseCase({id})
        $try(() => commit('set_material', data))
    },
    async Action_delete_material({commit}, id){
        return await MaterialDomain.DeleteMaterialUseCase(id)
    },
    async Action_change_material_state({commit}, id){
        await MaterialDomain.ChangeMaterialStateUseCase({id})
        $try(() =>  commit('toggle_material_state'))
    },
    async Action_get_additional_data({commit}, id){
        const additionalData = await DatosAdicionalesDomain.GetAdditionalDataUseCase()
        $try(() => commit('set_additional_data', additionalData))
    },
    async Action_get_additional_data_types({commit}, id){
        const additionalDataType = await DatosAdicionalesDomain.GetAdditionalDataTypesUseCase()
        $try(() => commit('set_additional_data_types', additionalDataType))
    },
    async Action_get_material_cotizations({commit}, id_almacen_material){
        const data = await MaterialDomain.GetCotizationMaterialUseCase(id_almacen_material)
        $try(() => commit('set_graphic_cotization', data))
    },
        
    clear_filter({commit}){
        $try(() => commit('set_filter', MaterialFilterFactory.instantiateEmptyWithRange(state.range)))
    }
}