import { CompraFilter } from "../CompraFilter"

export class CompraFilterFactory {
    static instantiate(obj){
        return new CompraFilter(obj)
    }

    static instantiateEmpty(){
        const filter = new CompraFilter({
            id_proyecto: null,
            id_proveedor: null,
            id_cliente: null,
            unidades_min: 0,
            unidades_max: 0,
            valor_min: 0,
            valor_max: 0,
            fecha_min: null,
            fecha_max: null,
            id_estado: ''
        })
        filter.setExceptions({
            unidades_min: 0,
            unidades_max: 0,
            valor_min: 0,
            valor_max: 0,
        })
        return filter
    }

    static instantiateEmptyWithRange(rangeUnidad, rangeValor){
        const unidades_min = rangeUnidad?.min ?? 0;
        const unidades_max = rangeUnidad?.max ?? 0;
        const valor_min = rangeValor?.min ?? 0;
        const valor_max = rangeValor?.max ?? 0;

        const filter = new CompraFilter({
            id_proyecto: null,
            id_proveedor: null,
            id_cliente: null,
            unidades_min,
            unidades_max,
            valor_min,
            valor_max,
            fecha_min: null,
            fecha_max: null,
            id_estado: ''
        })
        filter.setExceptions({
            unidades_min,
            unidades_max,
            valor_min,
            valor_max,
        })
        return filter
    }
}