<template>
    <el-radio-group  v-model="value" size="small">
        <template v-for="(radio, index) in options">
            <el-radio :label="radio.id" :key="index">{{radio.valor}}</el-radio>
        </template>
    </el-radio-group>
</template>

<script>
import ApiService from '~/services/api'
export default {
    props: {
        model: { type: Object },
        prop: { type: String, default: 'id_configuracion_datos_adicionales_tipos' },
        url: { type: String, default: undefined },
        data: { type: Array, default: () => [] },
    },
    data(){
        return {
            options: Array,
            value: '',
        }
    },
    created(){
        if(this.url) this.getSelectOptions();
    },
    watch:{
        data:{
            handler(val){
                this.options = val
            },
            deep: true
        },
        value(val){
            this.$emit('selected', val);
        },
        model(val){
            this.value = val?.hasOwnProperty(this.prop) ? val[this.prop] : '';
        },
    },
    methods:{
        clear(){
            this.value = null
        },
        async getSelectOptions(url){
            url = url ?? this.url;
            const Api = new ApiService();
            const { data }= await Api.get(url);
            
            this.$try(() => {
                this.options = data;
            });
        }
    }
}
</script>