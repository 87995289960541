import { CountryUtility } from "../CountryUtility";

export class CountryUtilityFactory {
    static instantiate(obj){
        return new CountryUtility(obj)
    }

    static instantiateEmpty(){
        return new CountryUtility({
            id: undefined,
            id_pais: null,
            pais: undefined,
            utilidad: null,
        })
    }

    static instance(){
        return CountryUtility
    }
}