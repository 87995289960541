import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { $try } from '~/services/global'

class DeleteAdditionalDataUseCase extends UseCase {
    static async execute(params){
        await AdditionalData.deleteUniqueAdditionalData(params)
    }
}

export default DeleteAdditionalDataUseCase.execute