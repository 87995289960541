import Api from '~/services/api'


const Auth = {
    getUser(){
        const User = new Api()
        return User.get('user')
    },
    login(payload){
        const Login = new Api({notification: true, delay: 3000})
        return Login.post('auth/login', payload)
    },
    logout(){
        const Logout = new Api({notification: true})
        return Logout.post('auth/logout')
    },
    logoutProveedores(){
        const Logout = new Api({notification: true})
        return Logout.post('auth/logout-proveedores')
    },
    recoverPassword(payload){
        const Recover = new Api()
        return Recover.post('auth/recuperar-password', payload)
    },
    recoverUsername(payload){
        const Recover = new Api()
        return Recover.post('auth/recuperar-usuario', payload)
    },
    validateCode(payload){
        const Validate = new Api()
        return Validate.post('auth/validar-codigo', payload)
    },
    changePassword(payload){
        const Change = new Api()
        return Change.post('auth/generar-password', payload)
    }

}

export default Auth