import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivitiesSteps } from "~/services/almacen/mantenimiento/steps"
import { ActivityFactory } from '~/domain/entities/almacen/mantenimiento/actividades/factories/ActivityFactory'
import Aws from '~/services/aws.js'
import { $try } from '~/services/global'

class UploadFileMaintenanceActivityStepsUseCase extends UseCase {
    static async execute(params){
        let model = await $try(async () => {
            const {adjunto, ...rest} = params
            const {nombre, archivo, descripcion} = adjunto
            const file = archivo[0]
            const url =  await Aws.uploadFile({file, path: 'almacen/mantenimiento/planes/'});
            return {
                ...rest,
                payload: {
                    nombre,
                    archivo: url,
                    descripcion,
                }
            }
        })

        const  data = await MaintenanceActivitiesSteps.uploadFile(model)
        // return $try(
        //     () => ActivityFactory.instantiate(data[0])
        // )

    }
}

export default UploadFileMaintenanceActivityStepsUseCase.execute