import { Entity } from '~/domain/entities'

export class ListaEquipos extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'tabla',
                'equipo',
            ]
        });
    }
}