import * as ObrasDomain from '~/domain/use_cases/obras';
import { WorkshopFilterFactory } from '~/domain/filters/obras/factories/WorkshopFilterFactory';

export const state = {
    obras: [],
    pagination: {},
    filter: WorkshopFilterFactory.instantiateEmpty(),
    range: {
        min: 0,
        max: 0
    },
}

export const mutations = {
    set_obras_decorator(state, payload){
        state.obras = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_range(state, payload){
        state.range = payload
    }
}

export const getters = {
    obras: state => state.obras.data,
    pagination: state => state.obras.pagination,
    filter: state => state.filter,
    range: state => state.range
}

export const actions = {
    async Action_get_finished_obras({ state, commit }, page = 1){
        const filter = state.filter.clean()
        const decorator = await ObrasDomain.GetFinishedObrasUseCase({ page, ...filter});

        if(decorator) commit('set_obras_decorator', decorator);
    },
    async Action_get_finished_obras_range({ commit }){
        const data = await ObrasDomain.GetFinishedObrasRangeUseCase();
        if(data) commit('set_range', data);
    },
    clear_filter({ state, commit }){
        commit('set_filter', WorkshopFilterFactory.instantiateEmptyWithRange(state.range));
    },
}