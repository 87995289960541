import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { AdditionalDataTypeVOFactory } from '~/domain/value_objects/configurar/almacen/datos_adicionales/factories/AdditionalDataTypeVOFactory'
import { $try } from '~/services/global'

class EditAdditionalDataTypeUseCase extends UseCase {
    static async execute(params){
        const { data } =  await AdditionalData.editAditionalDataTypes(params)

        return $try(
            () => AdditionalDataTypeVOFactory.instantiate(data)
        )
    }
}

export default EditAdditionalDataTypeUseCase.execute