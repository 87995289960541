import { UseCase } from "~/domain/core/UseCase"
import MenuList from "~/services/configurar/permisos/menus"

class GetMenusUseCase extends UseCase {
    static async execute(params){
        const data = await MenuList.listMenus(params)
        if(data?.data){
            return data.data;
        }
    }
} 

export default GetMenusUseCase.execute