import { Filter } from '~/domain/core/Filter';

export class StockMaterialesFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_unidad_medida',
                'id_tipo_material',
                'estado',
                'fecha_inicio',
                'fecha_fin',
                'valor_inicial',
                'valor_final'
            ]
        });
    }
}