import { PaymentMethodFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/PaymentMethodFactory';

export const state = {
    payment_methods: [],
    validate_inputs: false,
    total_percentage: 0,
    total_value: 0,
    type: 1,
}

export const getters = {
    payment_methods: state => state.payment_methods,
    validate_inputs: state => state.validate_inputs,
    total_percentage: state => state.total_percentage,
    total_value: state => state.total_value,
    type: state => state.type,
}

export const mutations = {
    set_payment_methods(state, payload){
        state.payment_methods = payload
    },
    set_validate_inputs(state, payload){
        state.validate_inputs = payload
    },
    set_type(state, payload){
        state.type = payload
    },
    set_total_value(state, payload){
        state.total_value = payload
    },
    set_total_percentage(state, payload){
        state.total_percentage = payload
    }
}

export const actions = {
    Action_calculate_total_percentage({ state, commit }){
        commit('set_total_percentage', _.sumBy(state.payment_methods, (el) => parseFloat(el.porcentaje)))
    },
    Action_calculate_total_value({state, commit}){
        commit('set_total_value', _.sumBy(state.payment_methods, (el) => parseFloat(el.valor)))
    },
    Action_add_new_payment_method({ state }, quantity) {
        // remove unsaved payment methods
        state.payment_methods = state.payment_methods.filter(el => !!el.id)
        // add new payment methods
        for (let index = 0; index < quantity; index++) {
            state.payment_methods.push(PaymentMethodFactory.instantiateEmpty())
        }
    },
    Action_remove_payment_method({ state, commit, dispatch }, id){
        // remove unsaved payment methods
        if(!id) return state.payment_methods.pop()
        // remove saved payment method
        state.payment_methods = state.payment_methods.filter(el => el.id != id)
        // calculate total percentage
        dispatch('Action_calculate_total_percentage')
    },        
    Action_calculate_values_by_value({ state, dispatch }, { index, value }){
        // set payment method value
        state.payment_methods.forEach((element, key)=> {
            if(key === index){
                element.valor = value
                element.tipo = 2
            }
        })
        // calculate total value
        dispatch('Action_calculate_total_value')
        // calculate percentage
        state.payment_methods.forEach((element, key) => {
            element.porcentaje = parseFloat(state.total_value === 0 ? 0 : (element.valor * 100 / state.total_value)).toFixed(2)
        })
        // calculate total percentage
        dispatch('Action_calculate_total_percentage')
    },
    Action_calculate_values_by_percentage({ state, getters, dispatch }, { index, percentage }){
        // calculate all values and percentages
        state.payment_methods.forEach((element, key) => {
            if(key === index){
                element.valor = (getters.total_value * percentage) / 100
                element.porcentaje = parseInt(percentage)
            }
        })
        // calculate total percentage
        dispatch('Action_calculate_total_percentage')
    }, 
    Action_calculate_values_by_total({ state, commit, getters }, total){
        // set total value
        commit('set_total_value', total)
        // calculate all values
        state.payment_methods.forEach((element) => {
            element.valor = (getters.total_value * element.porcentaje) / 100
        })
    },
    Action_save_date({ state }, { index, date: fecha_pago }){
        // set date
        state.payment_methods.forEach((element, key) => {
            if(key === index) element.fecha_pago = fecha_pago
        })
    },
    Action_save_money({ state }, { index, id_moneda }){
        state.payment_methods.forEach((element, key) => {
            if(key === index) element.id_moneda = id_moneda
        })
    },
    Action_validate_inputs({ state, commit }){
        commit('set_validate_inputs', !state.validate_inputs)
    }
}