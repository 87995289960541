import { UseCase } from "~/domain/core/UseCase";
import { Bills } from '~/services/compras/facturas'
import { $try } from '~/services/global'

class getFilterRangoMaximoUseCase extends UseCase {
    static async execute(id){
        const data  =  await Bills.getFilterRangoValor(id)

        return $try(() => data?.data)

    }
}

export default getFilterRangoMaximoUseCase.execute