import { UseCase } from "~/domain/core/UseCase";
import { PedidosInternos } from '~/services/configurar/almacen/pedidos_internos.js'
import { EstadoPedidosInternosFactory } from '~/domain/entities/configurar/almacen/pedidos_internos/factories/estadoPedidosInternosFactory.js'
import { $try } from '~/services/global'

class PostEstadosPedidosInternosUseCase extends UseCase {
    static async execute(payload){
        const data = await PedidosInternos.postEstadosPedidosInternos(payload)      
        return $try(() => {
            if(data?.data) return EstadoPedidosInternosFactory.instantiate(data.data)
        })
    }
}

export default PostEstadosPedidosInternosUseCase.execute