import { UseCase } from "~/domain/core/UseCase";
import { Bills } from '~/services/compras/facturas'
import { StateVOFactory } from '~/domain/value_objects/compras/facturas/factories/StateVOFactory'
import { $try } from '~/services/global'

class GetFilterStateUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data } =  await Bills.getFilterStates()


        $try(() => {

            if(data.length){
                data.forEach((item) =>{
                    customData.push(StateVOFactory.instantiate(item))
                })
            }

        })

        return customData
    }
}

export default GetFilterStateUseCase.execute