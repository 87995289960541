import { UseCase } from '~/domain/core/UseCase'
import Services from "~/services/configurar/talleres/tipos_servicios"
import { $try } from '~/services/global'

class GetServicesWorkshopUseCase extends UseCase {
    static async execute(){
        const data = await Services.getServicesWorkshop()
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetServicesWorkshopUseCase.execute
