import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { CategoriaVOFactory } from '~/domain/value_objects/almacen/equipos_modelos/factories/categoriaVOFactory'
import { $try } from '~/services/global'

class GetSelectCategoriasUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectCategoties(payload)
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(el => CategoriaVOFactory.instantiate(el))
        })
    }
}

export default GetSelectCategoriasUseCase.execute