import { UseCase } from '~/domain/core/UseCase'
import { WorkshopCalendarService } from '~/services/configurar/obras/calendario_talleres'
import { WorkshopCalendarActivityFactory } from '~/domain/entities/configurar/obras/calendario_talleres/factories/WorkshopCalendarActivityFactory'
import { $try } from '~/services/global'

class EditWorkshopCalendarActivityUseCase extends UseCase {
    static async execute(params){
        const { data } =  await WorkshopCalendarService.editWorkshopCalendarActivity(params)
        const { created_at,created_by, updated_at,updated_by, deleted_at, ...rest } = data
        return $try(
                () => WorkshopCalendarActivityFactory.instantiate(rest)
        )
    }
}

export default EditWorkshopCalendarActivityUseCase.execute