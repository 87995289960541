import { Entity } from '~/domain/entities'

export class FechasVencimientoOps extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'oportunidad',
                'producto',
                'etapas'
            ]
        })
    }
}