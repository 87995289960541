import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { OpportunityConfigurationFactory } from '~/domain/entities/oportunidades/productos/factories/OpportunityConfigurationFactory'
import { $try } from '~/services/global'

class EditOpportunityStageUseCase extends UseCase {
    static async execute(params){
        let customData = {}
        const { data } =  await Opportunities.editOpportunityStageConfigurations(params)

        $try(
            () => {
                customData = OpportunityConfigurationFactory.instantiate(data)
            }
        )

        return customData
    }
}

export default EditOpportunityStageUseCase.execute