import { UseCase } from "~/domain/core/UseCase"
import  { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { InformacionOcsFactory } from '~/domain/value_objects/almacen/pedidos/factories/InformacionOcsFactory'
import { $try } from '~/services/global'

class PostMaterialesPedidosInternosUseCase extends UseCase {
    static async execute(payload){
        const data = await PedidosInternosOcs.postMaterialesPedidosInternos(payload)
        return $try(() => {
            return data.data ? InformacionOcsFactory.instantiate(data.data): null 
        })
    }
}

export default PostMaterialesPedidosInternosUseCase.execute