import { UseCase } from '~/domain/core/UseCase'
import { Gallery } from '~/services/obras/gallery/gallery'
import { GalleryFactory } from '~/domain/entities/obras/galeria/factories/GalleryFactory'
import { $try } from '~/services/global' 

class CreateFolderUseCase extends UseCase {
    static async execute(params){
        const data = await Gallery.createFolder(params)

        return $try(() => {
            if(data?.data) return new GalleryFactory.instantiate(data.data)
        })
    }
}

export default CreateFolderUseCase.execute