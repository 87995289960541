import { UseCase } from "~/domain/core/UseCase";
import { VisitasService } from '~/services/obras/servicios/visitas'

class GetActivityComments extends UseCase {
    static async execute(params){
        const data = await VisitasService.getActivityComments(params)
        return data.data
    }
}

export default GetActivityComments.execute