import store from '~/store'
// Inicializar el proveedor de credenciales de Amazon Cognito
AWS.config.region = 'us-east-1'; // Región
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:6bf3c64a-a0c3-4bba-82dd-f387ff141ef6',
});
const bucket = process.env.VUE_APP_BUCKET
const aws = {
    Bucket: bucket,
    /**
     * @param  {string} Bucket='v2-fullsmart-iaf' @description aws bucket where we store our files
     */
    setBucket(Bucket = 'v2-fullsmart-iaf'){
        this.Bucket = Bucket
    },
    getInstance(){
        return new AWS.S3({
            apiVersion: '2006-03-01',
            params: { Bucket: this.Bucket }
        })
    },
    /**
     * @param  {string} namespace='' @description namespace added before generated hash
     */
    getHash(namespace = ''){
        return `${namespace === '' ? '' : `${namespace}/`}${new Date().getTime()}-${Math.random().toString(16).substr(2)}`
    },
    /**
     * @param  {file} {file @description file we want to save into aws s3
     * @param  {string} path='' @description where we want to save our file into aws s3}
     */
    async uploadFile({file, path=''}){
        if(!file) return console.warn('Es necesario el parametro file');

        const route = `iaf-software/${path}/${this.getHash()}.${file.name.split('.').pop()}`;
        let percentage = 0;
        
        const res = await this.getInstance().upload({
            Key: route,
            ContentType: file.type,
            Body: file
        }).on('httpUploadProgress',uploadEvent=>{
            percentage = Math.round((uploadEvent.loaded * 100) / uploadEvent.total );
            store.commit('loading_file/SET_PERCENTAGE', percentage);
        }).promise();

        store.commit('loading_file/SET_PERCENTAGE', 0);

        return res.key ?? false;
    }

}
export default aws
