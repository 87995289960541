import { Filter } from '~/domain/core/Filter';
export class CotizacionFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_estado',
                'materiales_min',
                'materiales_max',
                'valor_min',
                'valor_max',
                'fecha_cotizacion_min',
                'fecha_cotizacion_max',
                'dias_credito_min',
                'dias_credito_max'
            ]
        });
    }
}
