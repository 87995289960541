import { UseCase } from "~/domain/core/UseCase";
import { CheckList } from '~/services/configurar/obras/lista_chequeo.js'
import { $try } from '~/services/global'

class UpdatePositionChekListUseCase extends UseCase {
    static async execute(payload) {
        const data = await CheckList.updatePositionCheckList(payload) 
        return $try(() => data?.message)
    }
}

export default UpdatePositionChekListUseCase.execute