import * as GalleryDomain from '~/domain/use_cases/obras/gallery'
import { $try } from '~/services/global'


//state
export const state = {
    folders: [],
    folder: {},
    folderFiles: []
}

//getters
export const getters = {
    foldersList: state => state.folders,
    folderFilesList: state => state.folderFiles,
}

//mutations
export const mutations = {
    set_folders(state, payload){
        state.folders = payload
    },
    add_folder(state, payload){
        state.folders.push(payload)
    },
    edit_folder(state, payload){
        state.folders = state.folders.map(e => {
            if (e.id === payload.id) e = payload
            return e 
        })
    },
    delete_folder(state, payload){
        state.folders = state.folders.filter(e => (e.id !== payload))
    },
    set_folder_files(state, payload) {
        state.folderFiles = payload
    },
    delete_folder_file(state, payload){
        state.folderFiles.data = state.folderFiles.data.filter(e => (e.id !== payload))
    },
}

//actions
export const actions = {
    // Folders
    async Action_get_folders({ commit }, payload){
        const data = await GalleryDomain.GetFoldersUseCase(payload)
        $try(() => {
            if(data) commit('set_folders', data)
        })
    },
    async Action_create_folder({ commit }, payload) {
        const folder = await GalleryDomain.CreateFolderUseCase(payload)
        $try(() => {
            if(folder) commit('add_folder', folder)
        })
    },
    async Action_edit_folder({ commit }, payload) {
        const folder = await GalleryDomain.EditeFolderUseCase(payload)
        $try(() => {
            if(folder) commit('edit_folder', folder)
        })
    },
    async Action_delete_folder( { commit } , params) {
        const folder = await GalleryDomain.DeleteFolderUseCase(params)
        $try(() => {
            if(folder) commit('delete_folder', folder)
        })
    },
    // Folder Files
    async Action_get_folders_files({ commit }, payload) {
        const files = await GalleryDomain.GetFolderFilesUseCase(payload)
        $try(() => {
            if(files) commit('set_folder_files', files)
        })
    },
    async Action_delete_folder_file({ commit }, payload) {
        const file = await GalleryDomain.DeleteFolderFileUseCase(payload)
        $try(() => {
            if(file) commit('delete_folder_file', file)
        })
    },
    async Action_save_folder_file({ commit }, payload) {
        const file = await GalleryDomain.CreateNewFileUseCase(payload)
    },
    async Action_edit_folder_file({ commit }, payload) {
        //console.log('payload=>',payload);
        const file = await GalleryDomain.EditFolderFileUseCase(payload)
        // $try(
        //     () => commit('edit_folder', file)
        // )
    },
}