import { Filter } from '~/domain/core/Filter';

export class CotizacionPendienteFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'referencia',
                'cantidad_materiales_min',
                'cantidad_materiales_max',
                'fecha_cotizacion_inicio',
                'fecha_cotizacion_fin',
                'fecha_vencimiento_inicio',
                'fecha_vencimiento_fin',
            ]
        });
    }
}