import Api from '~/services/api'

export const Compras = {
    getComprasMateriales(id, payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/materiales/${id}/compras`, payload)
    },
    getFiltrosCompras(id){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/materiales/${id}/compras/filtros`)
    }
}