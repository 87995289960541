import Api from '~/services/api'

export const Opportunities = {

    // Listar Opportunity ----------------------------------------------------------------------------------

    getOpportunity(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/vigentes`, payload)
    },
    getOpportunityWon(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/ganadas`, payload)
    },
    getOpportunityLosses(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/perdidas`, payload)
    },

    // Filtros --------------------------------------------------------------------------------------------

    getFiltersOpportunity(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/filtros`, payload)
    },
    getCriterio(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/filtros-criterio`, payload)
    },
    guardarFiltroCriterioSegmentacion(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`oportunidades/guardar-actualizar-filtro-segmentacion`, payload)
    },
    guardarFiltroCriterioUbicacion(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`oportunidades/guardar-actualizar-filtro-ubicaciones`, payload)
    },
    guardarFiltroGeneral(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`oportunidades/guardar-wfiltros-oportunidades`, payload)
    },
    eliminarFiltros(){
        const ApiHandler = new Api()
        return ApiHandler.delete(`oportunidades/eliminar-temporal`)
    },

    // Ver Opportunity -------------------------------------------------------------------------------------

    getOpportunityInformation(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/${payload}/informacion`)
    },
    editOpportunityInformation(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`oportunidades/${params.id}/editar`, params.payload)
    },

    // Equipos Oportunidad ----------------------------------------------------------------------------------

    getEquiposOpportunity(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/${payload}/equipos`)
    },
    addEquiposOpportunity(params){
        const ApiHandler = new Api()
        return ApiHandler.post(`oportunidades/${params.id}/annadir-equipo-modelo`, params.payload)
    },

    // Ciclos Oportunidad ----------------------------------------------------------------------------------

    getCiclosOpportunity(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/${payload}/ciclos`)
    },
    editCiclosOpportunity(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`oportunidades/${params.idOportunidad}/ciclos/${params.idCiclo}/editar`, params.payload)
    },
    getInfoCiclosOpportunity(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/ciclos/${payload}`)
    },
    advanceCicloOpportunity(params){
        const ApiHandler = new Api()
        return ApiHandler.post(`oportunidades/${params.idOportunidad}/ciclos/${params.idCiclo}`, params.payload)
    },

    // Responsables Oportunidad ----------------------------------------------------------------------------------

    getResponsiblesOpportunity(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/${payload}/responsables`)
    },
    addResponsiblesOpportunity(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`oportunidades/${params.id}/annadir-responsables`, params.payload)
    },
    addPrincipalResponsiblesOpportunity(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`oportunidades/${params.id}/asignar-responsable`, params.payload)
    },
    deleteResponsiblesOpportunity(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`oportunidades/${params.id}/eliminar-responsables`, params.payload)
    },

    // Clientes Oportunidad ----------------------------------------------------------------------------------

    getCustomersOpportunity(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/${payload}/contactos`)
    },
    addCustomersOpportunity(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`oportunidades/${params.id}/annadir-contactos`, params.payload)
    },
    deleteCustomerOpportunity(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`oportunidades/${params.id}/eliminar-contactos`, params.payload)
    },

    // Métodos de pago ----------------------------------------------------------------------------------

    setPaymentMethodOpportunity(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`oportunidades/${params.id}/forma-pago`, params.payload)
    },

    getPaymentMethodOpportunity(id_oportunidad){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/${id_oportunidad}/forma-pago`)
    },

    // Etapas y configuraciones de etapa de un equipo de una oportunidad

    getOpportunityConfigurations({id_oportunidad, id_producto, tabla}){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/vigentes/${id_oportunidad}/productos/${id_producto}/etapas`, {tabla})
    },
    createOpportunityStageConfigurations({id_oportunidad, id_producto, payload, tabla}){
        const data = {... payload, tabla}
        const ApiHandler = new Api()
        return ApiHandler.post(`oportunidades/vigentes/${id_oportunidad}/productos/${id_producto}/annadir-configuracion`, data)
    },
    editOpportunityStageConfigurations({id_oportunidad, id_producto, id_etapa,payload}){
        const ApiHandler = new Api()
        return ApiHandler.put(`oportunidades/vigentes/${id_oportunidad}/productos/${id_producto}/annadir-configuracion/${id_etapa}`, payload)
    },
    deleteOpportunityStageConfigurations({id_oportunidad, id_producto, id_etapa, tabla}){
        const ApiHandler = new Api()
        return ApiHandler.delete(`oportunidades/vigentes/${id_oportunidad}/productos/${id_producto}/eliminar-configuracion/${id_etapa}`, {tabla})
    },
    deleteOpportunityModelEquipment({idOps, producto, tabla}){
        const ApiHandler = new Api()
        return ApiHandler.delete(`oportunidades/vigentes/${idOps}/productos/${producto}/eliminar`, {tabla})
    },
    //configuracion y materiales

    getConfiguracion({idOportunidad, idProducto, idConfiguracion, tabla}){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/vigentes/${idOportunidad}/productos/${idProducto}/configuracion/${idConfiguracion}`, {tabla})
    },
    getMaterialesConfiguracion({payload, filter}){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/vigentes/${payload.idOportunidad}/productos/${payload.idProducto}/configuracion/${payload.idConfiguracion}/materiales`, {...filter, tabla:payload.tabla})
    },
    putActualizarValorHorasHombre({params, payload}){
        const ApiHandler = new Api()
        return ApiHandler.put(`oportunidades/vigentes/${params.idOportunidad}/productos/${params.idProducto}/configuracion/${params.idConfiguracion}/hora-hombre`, payload)
    },
    putRestablecerValorHorasHombre({params, payload}){
        const ApiHandler = new Api()
        return ApiHandler.put(`oportunidades/vigentes/${params.idOportunidad}/productos/${params.idProducto}/configuracion/${params.idConfiguracion}/restablecer-hora-hombre`, payload)
    },
    PutActualizarMaterial({params, payload}){
        const ApiHandler = new Api()
        return ApiHandler.put(`oportunidades/vigentes/${params.idOportunidad}/productos/${params.idProducto}/configuracion/${params.idConfiguracion}/actualizar-materiales`, payload)
    },
    DeleteMaterial({params, payload}){
        const ApiHandler = new Api()
        return ApiHandler.delete(`oportunidades/vigentes/${params.idOportunidad}/productos/${params.idProducto}/configuracion/${params.idConfiguracion}/materiales`, payload)
    },
    getSelectMateriales({idOportunidad, idProducto, idConfiguracion, tabla}){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/vigentes/${idOportunidad}/productos/${idProducto}/configuracion/${idConfiguracion}/select-materiales`, {tabla})
    },
    postAgregarMaterial({params, payload}){
        const ApiHandler = new Api()
        return ApiHandler.post(`oportunidades/vigentes/${params.idOportunidad}/productos/${params.idProducto}/configuracion/${params.idConfiguracion}/agregar-materiales`,payload )
    },
    postReestablecerMantenimientos({idOportunidad, idProducto, idConfiguracion, tabla}){
        const ApiHandler = new Api()
        return ApiHandler.post(`oportunidades/vigentes/${idOportunidad}/productos/${idProducto}/configuracion/${idConfiguracion}/reestablecer-plan-mantenimiento`, {tabla})
    },
    deleteConfiguracion({params, payload}){
        const ApiHandler = new Api()
        return ApiHandler.delete(`oportunidades/vigentes/${params.idOportunidad}/productos/${params.idProducto}/configuracion/${params.idConfiguracion}`, payload)
    },
    getFiltroConfiguracion(params){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/vigentes/${params.idOportunidad}/productos/${params.idProducto}/configuracion/${params.idConfiguracion}/filtros`, {tabla:params.tabla})
    },
}