import { UseCase } from "~/domain/core/UseCase"
import { Maintenance } from '~/services/almacen/mantenimiento.js'
import { MaintenanceListFactory } from '~/domain/entities/almacen/mantenimiento/index/factories/MaintenanceListFactory.js'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetMaintenanceUseCase extends UseCase {
    static async execute(payload){
        const { data } =  await Maintenance.listMaterialesMantenimiento(payload)
        
        return $try(() => {
            if(data.data){
                return new PaginationDecorator(
                    data,
                    MaintenanceListFactory.instantiate
                ).toTable();
            }
            return []
        })
    }
}

export default GetMaintenanceUseCase.execute