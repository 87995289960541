import { ValueObject } from '~/domain/core/ValueObject'

export class ListaChequeo extends ValueObject {
    constructor(obj){
        super({
            params: obj, 
            keys: [
                "id",
                "lista_chequeo",
                "actividades"
            ]
        });
    }
}