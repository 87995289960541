import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { $try } from '~/services/global'

class GetPaymentMethodUseCase extends UseCase {
    static async execute(params){
        const data =  await Opportunities.getPaymentMethodOpportunity(params)
        return $try(() => data?.data ?? {} )
    }
}

export default GetPaymentMethodUseCase.execute