import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class UpdateStageConfUseCase extends UseCase {
    static async execute(params){
        const { id, id_etapa, ...payload } = params
        await Stages.updateConfiguration({id, id_etapa, payload})
    }
}

export default UpdateStageConfUseCase.execute