 <template>
    <div
    ref="modalgbp"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    @mouseover="out = true"
    @mouseleave="out = false"
    >
        <div
        class="modal-dialog modal-dialog-centered"
        :class="tamano ? tamano : ''"
        role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-sm-2 col-2 my-auto">
                                <div class="logo mx-auto">
                                    <img src="/img/generales/iafLogo.png" height="25" style="max-width:240px" class="obj-cover ml-2" alt="" />
                                </div>
                            </div>
                            <div class="col-sm-8 col-8 text-center my-auto">
                                <p v-if="titulo" class="modal-title text-center f-600 text-general">{{titulo}}</p>
                            </div>
                            <div class="col-sm-2 col-2" />
                            <button type="button" class="close f-300" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                       
                    </div>
                </div>
                <div class="border-modal-top"></div>
                
                <div class="modal-body overflow-auto custom-scroll" style="max-height:70vh;">
                    <!-- <div class="row my-3 mt-5 text-muted f-17 f-600 justify-center text-center px-3">
                        {{ titulo }}
                    </div> -->
                    <slot />
                    
                </div>
                <div class="border-modal-bottom"></div>
                <div class="row mx-0 justify-content-end py-2 bg-f5 br-b-5 modal-botones">
                    <slot name="start-buttons" />
                    <button
                    v-show="cancelar"
                    type="button"
                    class="btn ml-1 mr-2 btn-cerrar f-300 f-12 br-4 border f-300"
                    @click="cancelar"
                    v-text="textosBotones.cancelar"
                    />
                    <button
                    v-show="btnEliminar"
                    v-loading="cargando"
                    type="button"
                    class="btn ml-1 mr-2 f-12 btn-eliminar f-300"
                    @click="actionEliminar"
                    v-text="textosBotones.eliminar"
                    />
                    <button
                    v-show="adicional !== ''"
                    v-loading="cargando"
                    :disabled="desactivarGuardar"
                    type="button"
                    class="btn ml-1 mr-2 f-12 btn-general f-300 w-80px"
                    @click="onAdicional"
                    v-text="adicional"
                    />
                    <slot name="end-buttons" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            required: true
        },
        tamano: {
            type: String,
            default: 'modal-md'
        },
        btnEliminar: {
            type: Boolean,
            default: false
        },
        aceptar: {
            type: Boolean,
            default: false
        },
        btnCancelar: {
            type: Boolean,
            default: false
        },
        desactivarGuardar: {
            type: Boolean,
            default: false
        },
        adicional: {
            type: String,
            default: ''
        },
        cargando: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            textosBotones: {
                eliminar: 'Eliminar',
                cancelar: 'Cerrar'
            },
            visible: false,
            out: false
        }
    },
    methods: {
        toggle(){
            $(this.$refs.modalgbp).modal('toggle')
            this.visible = !this.visible
            if(!this.visible){
                this.$emit('closed')
            }
        },
        guardar(){
            this.$emit('guardar')
        },
        actionEliminar(){
            this.$emit('eliminar')
        },
        onAdicional(){
            this.$emit('adicional')
        },
        cancelar(){
            this.$emit('cancelar')
            this.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '~/assets/sass/gbp.scss';

.modal-content{
    border: none;
    border-radius: 5px;
    .border-modal{
        &-top{
            width: 100%;
            height: 1px;
            background: var(--color-general)
        }
        &-bottom{
            width: 100%;
            height: 1px;
            background: #dbdbdb;
        }
    }
    .modal-header{
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: none;
        background-size: cover;
        padding: 1rem;
        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 8px;
            font-size: 2.5em;
            padding: 0px;
            margin: 0px;
            color: var(--color-general) !important;
            &:hover{
                transition-duration: .4s;
            }
        }
        .logo{
            display: block;
            // height: 70px;
            // width: 70px;

            span{
                font-size: 1.2em;
            }
        }
       
    }
    .modal-title{
        color: var(--color-general);
        font-weight: normal;
    }
}
.img-tipo{
    position: absolute;
    top:20px;
    left: 50%;
    transform: translate(-50%);
}
</style>
