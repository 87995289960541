import { UseCase } from '~/domain/core/UseCase'
import { WorkshopCalendarService } from '~/services/configurar/obras/calendario_talleres'
import { WorkshopCalendarFactory } from '~/domain/entities/configurar/obras/calendario_talleres/factories/WorkshopCalendarFactory'
import { WorkshopCalendarActivityFactory } from '~/domain/entities/configurar/obras/calendario_talleres/factories/WorkshopCalendarActivityFactory'
import { $try } from '~/services/global'

class GetWorkshopCalendarUseCase extends UseCase {
    static async execute(params){
        const { data } =  await WorkshopCalendarService.getWorkshopCalendar()

        return $try(
            () =>  data.map((item) => {
                    return WorkshopCalendarFactory.instantiate(
                        {
                            ...item,
                            actividades: item.actividades.map(({created_at, created_by, updated_at,updated_by, ...rest }) => {
                                return WorkshopCalendarActivityFactory.instantiate(rest)
                            })
                        }
                    )
                }
            )
        )
    }
}

export default GetWorkshopCalendarUseCase.execute