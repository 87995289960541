import { MaterialFilter } from "../MaterialFilter";

export class MaterialFilterFactory {
    static instantiate(obj){
        return new MaterialFilter(obj)
    }

    static instantiateEmpty(){
        return new MaterialFilter({
            id_tipo_material: null,
            id_unidad_medida: null,
            estado_material: [],
            valor_minimo: 0,
            valor_maximo: 0,
        })
    }

    static instantiateEmptyWithRange(range){
        const valor_minimo = range?.[0] ?? 0
        const valor_maximo = range?.[1] ?? 0

        const filter = new MaterialFilter({
            id_tipo_material: null,
            id_unidad_medida: null,
            estado_material: [],
            valor_minimo,
            valor_maximo,
        })

        filter.setExceptions({
            valor_minimo,
            valor_maximo,
        })

        return filter
    }
}