import { Entity } from '~/domain/entities'

export class RangeValueMaterials extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'valor_minimo',
                'valor_maximo',
            ]
        });
    }
}