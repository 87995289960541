import { UseCase } from "~/domain/core/UseCase";
import unitMeasure from "~/services/configurar/almacen/measure_unit"

class AddPosMeasureUnitUseCase extends UseCase {
    static async execute(params){
        const data = await unitMeasure.addPosUnitMeasure(params)
        return data?.data
    }
}

export default AddPosMeasureUnitUseCase.execute