import { Entity } from '~/domain/entities'

export class Activity extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['nombre_tarea', 'id', 'horario_calendario', 'descripcion', 'id_tipo_calendario', 'calendario',
                'mantenimiento', 'adjuntos', 'unidades_tiempo',  'id_materiales_mantenimiento' 
            ]
        });

        this.show = false
    }
}