export default [
    {
        path: '/login',
        name: 'login',
        component: ()=>import('~/pages/auth/Login.vue').then(m=>m.default || m)
    },
    {
        path: '/recuperar/:tipo',
        name: 'recover',
        component: ()=>import('~/pages/auth/Recover.vue').then(m=>m.default || m)
    },
    {
        path: '/enviar/usuario',
        name: 'sended.username',
        component: ()=>import('~/pages/auth/SendedUsername.vue').then(m=>m.default || m)
    },
    {
        path: '/validar/codigo',
        name: 'validate.code',
        component: ()=>import('~/pages/auth/ValidateCode.vue').then(m=>m.default || m)
    },
    {
        path: '/validar/clave',
        name: 'validate.password',
        component: ()=>import('~/pages/auth/ValidatePassword.vue').then(m=>m.default || m)
    },
]