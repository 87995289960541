import { PurchaseOrderInfo } from "../PurchaseOrderInfo";

export class PurchaseOrderInfoFactory {
    static instantiate(obj){
        return new PurchaseOrderInfo(obj)
    }

    static instantiateEmpty(){
        return new PurchaseOrderInfo({
            id: null,
            tipo: '',
            fecha: '',
            flete: '',
            estado: '',
            descripcion: '',
            id_moneda: '',
            destino: '',
            facturas: '',
            valor_pagado: '',
            id_oportunidad: null,
            id_proveedor: null,
            oportunidad: '',
            proveedor: '',
            valor: '',
        })
    }
}