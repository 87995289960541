import { UseCase } from "~/domain/core/UseCase"
import Auth from "~/services/auth/auth"
import { $try } from '~/services/global'

class GetUserUseCase extends UseCase {
    static async execute(params){  
        const data = await Auth.getUser()
        return $try(() => {
            return data?.data
        })
    }
}

export default GetUserUseCase.execute