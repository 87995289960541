import { UseCase } from "~/domain/core/UseCase";
import { CheckList } from '~/services/configurar/obras/lista_chequeo.js'
import { CheckListFactory } from '~/domain/entities/configurar/obras/check_list/factories/CheckListFactory.js'
import { $try } from '~/services/global'

class GetActivitiesCheckListUseCase extends UseCase {
    static async execute(){
        const { data } =  await CheckList.getCheckList()
        return $try( () => data?.map(e => CheckListFactory.instantiate(e) ) ?? [])
    }
}


export default GetActivitiesCheckListUseCase.execute