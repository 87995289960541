import { UseCase } from "~/domain/core/UseCase";
import MenuList from "~/services/configurar/permisos/menus"

class CreateRutaRaizUseCase extends UseCase {
    static async execute(params){ 
        const data = await MenuList.createRutaRaiz(params)
        return data?.data
    } 
}

export default CreateRutaRaizUseCase.execute 


