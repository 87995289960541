import { UseCase } from "~/domain/core/UseCase";
import { Pedidos } from '~/services/almacen/pedidos';
import { $try } from "~/services/global";

class DeleteMaterialPedidoUseCase extends UseCase {
    static async execute(payload){
        const data = await Pedidos.deleteMaterialPedidoInterno(payload)
        
        return $try(() => data?.message)
    }
}

export default DeleteMaterialPedidoUseCase.execute;