import { UseCase } from "~/domain/core/UseCase";
import { Informacion } from '~/services/obras/informacion/informacion'
import { $try } from '~/services/global'

class GetInformacionHeaderFSUseCase extends UseCase{
    static async execute(params){
        const  data  =  await Informacion.getInfoCabeceraFS(params)
        return $try(() => {
            return data?.data ?? {}
        })
    }
}

export default GetInformacionHeaderFSUseCase.execute