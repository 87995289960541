import { UseCase } from "~/domain/core/UseCase";
import { Cotizaciones } from '~/services/users_prividers/cotizacionesPendientes.js'
import { ProviderFactory } from '~/domain/entities/users_providers/factories/ProvidersFactory.js'
import { $try } from '~/services/global'

class GetInformationProviderUseCase extends UseCase {
    static async execute(payload){
        const data =  await Cotizaciones.getInformationProvider(payload)
        return $try(() => {
            return data?.data 
                ? ProviderFactory.instantiate(data.data) 
                : ProviderFactory.instantiateEmpty()
            })
    }
}

export default GetInformationProviderUseCase.execute