import { UseCase } from "~/domain/core/UseCase";
import { CorreosNotificacion } from '~/services/configurar/proveedores/correos_notificacion'
import { CorreosProveedoresFactory } from '~/domain/entities/configurar/proveedores/factories/correosProveedoresFactory'
import { $try } from '~/services/global'

class GetEmailNotificationProvidersUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CorreosNotificacion.getEmailNotificacionProveedores()
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new CorreosProveedoresFactory.instantiate(e)) 
        })
    }
}

export default GetEmailNotificationProvidersUseCase.execute