import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class GetStageSelectUseCase extends UseCase {
    static async execute(params){
        const { id_etapa, id_configuration } = params
        const data = await Stages.getStageSelect({id_etapa, id_configuration})
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetStageSelectUseCase.execute
