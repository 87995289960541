import { Entity } from '~/domain/entities'

export class VentasPresenciaEquipos extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'tabla',
                'nombre',
                'precio',
                'etapas',
                'materiales',
                'horas_hombre',
                'fecha_venta',
                'foto_cliente',
                'cliente',
                'imagen_producto'
            ]
        });
    }
}