import { UseCase } from "~/domain/core/UseCase";
import { CountryService } from '~/services/configurar/paises/paises'
import { $try } from "~/services/global";
import { CountryFactory } from "~/domain/entities/configurar/paises/factories/CountryFactory";
import { CountryUtilityFactory } from "../../../entities/configurar/paises/factories/CountryUtilityFactory";

class CreateCountryUseCase extends UseCase {
    static async execute(country){
        const data = await CountryService.createCountry(country)
        return $try(() => {
            if(data?.data) {
                const { pais, ...rest } = data.data
                return CountryUtilityFactory.instantiate({pais: CountryFactory.instantiate(pais), ...rest})
            }
        })
    }
}

export default CreateCountryUseCase.execute