import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { CotizacionesVOFactory } from '~/domain/value_objects/users_providers/factories/CotizacionesVOFactory'
import { $try } from '~/services/global'

class GetSelectCotizacionesUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectCotizaciones(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) 
                return data.data.map(el => CotizacionesVOFactory.instantiate(el))
        })
    }
}

export default GetSelectCotizacionesUseCase.execute