import { UseCase } from "~/domain/core/UseCase";
import { Documentacion } from "~/services/obras/servicios/documetacion";
import { ArchivosFactory } from "~/domain/entities/obras/servicios/documentacion/factories/ArchivosFactory";
import { $try } from "~/services/global";

class EditarArchivosUseCase extends UseCase {
  static async execute(params) {
    const data = await Documentacion.editArchiviosCarpetas(params);
    return $try(() => {
      if (data?.data) return new ArchivosFactory.instantiate(data.data);
    })
  }
}

export default EditarArchivosUseCase.execute;
