import { UseCase } from "~/domain/core/UseCase";
import MaterialTypes from "~/services/configurar/almacen/tipos_material"
import { $try } from '~/services/global'

class EditMaterialTypeUseCase extends UseCase {
    static async execute(params){
        const { id, ...payload } = params
        const data = await MaterialTypes.editMaterialType({id, payload})

        return $try(() => data?.data)
    }
}

export default EditMaterialTypeUseCase.execute