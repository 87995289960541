import { OportunidadesVO } from "../OportunidadesVO";

export class OportunidadesVOFactory {
    static instantiate(obj){
        return new OportunidadesVO(obj)
    }

    static instantiateEmpty(){
        return new OportunidadesVO({
            id: '',
            nombre: '',
        })
    }
}