import { AdditionalDataTypesPosicion } from "../AdditionalDataTypesPosicion";

export class AdditionalDataTypesPosicionFactory {
    static instantiate(obj){
        return new AdditionalDataTypesPosicion(obj)
    }

    static instantiateEmpty(){
        return new AdditionalDataTypesPosicion({
            id_configuracion_datos_adicionales_tipos: '',
            posicion: '',
        })
    }
}