import Api from '~/services/api'


const Materials = {
    listMaterials(payload){
        const Materials = new Api()
        return Materials.get('almacen/listar-materiales', payload)
    },
    getMaterialsRange(){
        const Range = new Api()
        return Range.get('almacen/materiales/rangos')
    },
    createMaterial(payload){
        const Material = new Api({ notification: true })
        return Material.post('almacen/materiales', payload)
    },
    updateMaterial({id, ...rest}){
        const Material = new Api({ notification: true })
        return Material.put(`almacen/materiales/${id}`, rest)
    },
    showMaterial({id}){
        const Material = new Api()
        return Material.get(`almacen/ver-materiales/${id}`)
    },
    deleteMaterial(id){
        const Material = new Api({ notification: true })
        return Material.delete(`almacen/eliminar-materiales/${id}`)
    },
    changeMaterialState({id}){
        const MaterialState = new Api({ notification: true })
        return MaterialState.post(`almacen/materiales/${id}/cambiar-estado`)
    },
    getAdditionalData(){
        const MaterialAdditional = new Api()
        return MaterialAdditional.get(`configuraciones/almacen/datos-adicionales`)
    },
    getAdditionalDataTypes(){
        const MaterialTypes = new Api()
        return MaterialTypes.get(`configuraciones/almacen/datos-adicionales-tipos`)
    },

    //Cotizacion material
    getCotizationMaterial({id_almacen_material}){
        const MaterialCotization = new Api()
        return MaterialCotization.get(`almacen/materiales/${id_almacen_material}/cotizaciones/grafica`)
    }
}

export default Materials