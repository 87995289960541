import { UseCase } from "~/domain/core/UseCase"
import { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { $try } from "~/services/global"

class DeleteFacturaOrdenCompraUseCase extends UseCase {
    static async execute(id, payload) {
        const data = await PedidosInternosOcs.deleteFacturaOrdenCompra(id, payload)
        
        return $try(() => data?.message)
    }
}

export default DeleteFacturaOrdenCompraUseCase.execute;