import { Bodega } from "../Bodega";

export class BodegaFactory {
    static instantiate(obj){
        return new Bodega(obj)
    }

    static instantiateEmpty(){
        return new Bodega({
            id: '',
            idMaterial: '',
            nombre: '',
            pais: '',
            estado_bodega: 1,
            estado: '',
            ciudad: '',
            direccion: '',
            longitud: '',
            latitud: '',
            cantidad_materiales: '',
            valor_materiales: '',
            fecha_ultima_salida: '',
            fecha_ultima_entrada: '',
            bodegas_imagenes: ''
        })
    }
}