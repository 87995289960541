import { Cobrar } from "../Cobrar";

export class CobrarFactory {
    static instantiate(obj){
        return new Cobrar(obj)
    }

    static instantiateEmpty(){
        return new Cobrar({
            id: '',
            cliente: '',
            proyecto: '',
            forma_pago: '',
            plazo_pago: '',
            valor: '',
            pago: '',
            fecha_pago: ''
        })
    }
}