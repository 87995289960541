import { UseCase } from "~/domain/core/UseCase";
import { Providers } from '~/services/proveedores/proveedores'
import { ProviderFactory } from '../../entities/proveedores/factories/ProviderFactory';

class CreateProviderUseCase extends UseCase {
    static async execute(params){
        const data = await Providers.createProvider(params)

        if(data?.data) return ProviderFactory.instantiate(data.data)
    }
}

export default CreateProviderUseCase.execute