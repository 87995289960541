import { MaintenanceFilter } from "../MaintenanceFilter";

export class MaintenanceFilterFactory {
    static instantiate(obj){
        return new MaintenanceFilter(obj);
    }
    
    static instantiateEmpty(){
        return new MaintenanceFilter({
            buscar: '',
            nombre: '',
            unidad_medida: '',
            numero_planes_mantenimiento: '',
            valor_inicial: 0,
            valor_final: 0,
        })
    }
}