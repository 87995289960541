import { UseCase } from "~/domain/core/UseCase";
import { Personalizacion } from '~/services/personalizacion'
import { $try } from '~/services/global'

class GetPermisionUseCase extends UseCase {
    static async execute(){
        const  data  =  await Personalizacion.getPermision()
        return $try(() =>  data?.data ?? [])
    }
}

export default GetPermisionUseCase.execute