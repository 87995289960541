import { UseCase } from "~/domain/core/UseCase"
import Materials from "~/services/almacen/materiales"

class UpdateMaterialUseCase extends UseCase {
    static async execute(params){
        const data = await Materials.updateMaterial(params)

        if(data?.data) 
            return data.data
    }
}

export default UpdateMaterialUseCase.execute