import { UseCase } from "~/domain/core/UseCase";
import BuyOrders from '~/services/proveedores/ver/ordenes_compra'
import { limitText } from '~/domain/helpers/string'
import { $try } from '~/services/global'

class GetBuyOrdersOpportunitesUseCase extends UseCase {
    static async execute(params){
        const data = await BuyOrders.obtenerOportunidadesOrdenesCompra(params)
        return $try(() => {
            if(data?.data) 
                return data.data.map(({oportunidad, ...rest}) => 
                    ({oportunidad: limitText(oportunidad), ...rest})
                )
        })
    }
}

export default GetBuyOrdersOpportunitesUseCase.execute