import { UseCase } from "~/domain/core/UseCase";
import { PurchaseOrder } from '~/services/compras/ordenes_compra'
import { PurchaseOrderFactory } from '~/domain/entities/compras/ordenes_compra/factories/PurchaseOrderFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetPurchaseOrderListUseCase extends UseCase {
    static async execute({id, pagination, payload = {}}){
        payload = payload.clean();
        const data = await PurchaseOrder.getPurchaseOrderList({id, pagination, payload})
        return $try(() => {
            if(data.data){
                return new PaginationDecorator(
                    data.data,
                    PurchaseOrderFactory.instantiate,
                );
            } 
        });
    }
}

export default GetPurchaseOrderListUseCase.execute