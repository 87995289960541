import { Entity } from '~/domain/entities'

export class CotizacionHistorial extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'user', 
                'fecha', 
                'porcentaje',
                'id_cotizador'
            ]
        })
    }
}