import Api from '~/services/api'

export const Dashboard = {
    getDashboardBodegasMaterial(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`bodega/dashboard/materiales-bodega`, payload)
    },
    getDashboardBodegasValor(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`bodega/dashboard/valor-bodega`, payload)
    },
    getDashboardBodegasEntrada(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`bodega/dashboard/entrada-material-bodega`, payload)
    },
    getDashboardBodegasSalida(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`bodega/dashboard/salida-material-bodega`, payload)
    }
}