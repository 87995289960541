import { Filter } from '~/domain/core/Filter';

export class DashboardFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'fecha_min',
                'fecha_max',
                'id_pais',
                'id_estado'
            ]
        });
    }
}