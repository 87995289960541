<template>
    <div class="card card__size card__size cr-pointer position-relative select-none" @click="redirect">
        <div class="icon__center icon__color h-100">
            <img :src="`https://v2-full-smart-manager.s3.amazonaws.com/${element.ilustrado}`" style="height: 45px;" />
        </div>
        <div class="text-bottom">{{element.nombre}}</div>
    </div>
</template>

<script>
export default {
    props:{
        element: { type: Object, default: () => {} },
		config: { type: Boolean, default: false }
    },
    data() {
        return {
            ruta: ''
        }
    },
    created () {
        this.ruta = process.env.VUE_APP_URL_FULL_SMART;
    },
    methods:{
        redirect(){
			const route = this.config ? this.element.ruta_configurar : this.element.ruta;
            if(!route) return;
            this.$emit('hideMenu');
            window.open(`${this.ruta}${route}` ,'_self')
        }
    }
}
</script>

<style scoped>
.card__size{
    height: 100px; 
    width: 100px;
}

.card__shadow{
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
}

.icon__center{
    display: grid;
    place-items: center;
}

.icon__color{
    color: #5D5D5D;
}

.text-bottom{
    position: absolute;
    width: calc(100% + 10px);
    height: 25px;
    text-align: center;
    left: -5px;
    bottom: -30px;
    color: white;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, .8);
}
.select-none{
	user-select: none;
}
</style>