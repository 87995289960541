

import { UseCase } from '~/domain/core/UseCase'
import { Workshops } from '~/services/talleres/talleres'
import { WorkshopTypeGraphVOFactory } from '~/domain/value_objects/talleres/factories/WorkshopTypeGraphVOFactory'
import { $try } from '~/services/global'

class GetServicesTypesGraphUseCase extends UseCase {
    static async execute(){
        const data = await Workshops.getServicesTypesGraph()
        return $try(() => {            
            return data?.data?.map(WorkshopTypeGraphVOFactory.instantiate)
        })
    }
}

export default GetServicesTypesGraphUseCase.execute