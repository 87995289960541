import { UseCase } from '~/domain/core/UseCase'
import Materials from "~/services/almacen/materiales"
import { $try } from '~/services/global' 

class GetCotizationMaterialUseCase extends UseCase {
    static async execute(payload){
        const data = await Materials.getCotizationMaterial(payload)
        return $try(() => {
            return data?.data
        })
    }
}

export default GetCotizationMaterialUseCase.execute;
