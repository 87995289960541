import { Entity } from '~/domain/entities'

export class Quotation extends Entity {
	constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'referencia',
                'tipo',
                'nombre',
                'total_unidades',
                'proveedor_favorito_material',
                'proveedor_favorito_categoria',
                'valor_ultima_cotizacion',
                'id_cotizacion',
            ]
        });
    }
}