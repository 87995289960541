import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'
import { $try } from '~/services/global'

class DeleteCalendarActivityUseCase extends UseCase {
    static async execute(id){
        const data = await GeneralCalendarService.deleteGeneralCalendarActivity(id)
        return $try(() => data?.message)
    }
}

export default DeleteCalendarActivityUseCase.execute