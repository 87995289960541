import { UseCase } from "~/domain/core/UseCase";
import { MaterialCotizacion } from '~/services/almacen/cotizaciones/cotizaciones'
import { CotizacionFactory } from '~/domain/entities/almacen/cotizaciones/factories/CotizacionFactory'
import { $try } from '~/services/global'

class GetCotizacionUseCase extends UseCase{
    static async execute(idMaterial, payload){
        const data = await MaterialCotizacion.getMaterialCotizacionFilter(idMaterial, payload)
        return $try(() => {
            if(!Array.isArray(data?.data)) return []

            return data.data.map(CotizacionFactory.instantiate) 
        })
    }
}

export default GetCotizacionUseCase.execute