import { UseCase } from "~/domain/core/UseCase";
import { Work } from '~/services/configurar/obras/obras'
import { $try } from '~/services/global'

class DeleteFolderUseCase extends UseCase {
    static async execute(params){
        const data = await Work.deleteFolder(params)

        return $try(() => data?.message)
    }
}

export default DeleteFolderUseCase.execute