import Api from '~/services/api'

export const VisitasService = {
    getVisitas(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas`)
    },
    createVisit(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas`, payload)
    },
    getVisitDetails(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas/${payload.id_control_piso_visita}`)
    },
    getVisitComments(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas/${payload.id_control_piso_visita}/comentarios`)
    },
    createVisitComment(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas/${payload.id_control_piso_visita}/comentarios`, payload)
    },
    editVisit(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas/${payload.id_control_piso_visita}`, payload)
    },
    deleteVisit(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas/${payload.id_control_piso_visita}`)
    },
    addActivityPercentage({id_proyecto, id_control_piso, id_control_piso_visita,id_control_piso_visitas_actividades,porcentaje_avance}){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/visitas/${id_control_piso_visita}/actividades/${id_control_piso_visitas_actividades}/porcentaje-avance`, {porcentaje_avance})
    },
    getActivityAllFiles({id_proyecto, id_control_piso, id_control_piso_visita,id_control_piso_visitas_actividades}){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/visitas/${id_control_piso_visita}/actividades/${id_control_piso_visitas_actividades}/adjuntos`)
    },
    getActivityComments({id_proyecto, id_control_piso, id_control_piso_visita,id_control_piso_visitas_actividades}){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/visitas/${id_control_piso_visita}/actividades/${id_control_piso_visitas_actividades}/comentarios`)
    },
    createActivityComments({id_proyecto, id_control_piso, id_control_piso_visita,id_control_piso_visitas_actividades, comentario}){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${id_proyecto}/servicios/${id_control_piso}/visitas/${id_control_piso_visita}/actividades/${id_control_piso_visitas_actividades}/comentarios`,{comentario})
    },
    addAttachmentAct(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas/${payload.id_control_piso_visita}/actividades/${payload.id_control_piso_visitas_actividades}/adjuntos`, payload)
    },
    endVisit(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/visitas/${payload.id_control_piso_visita}/finalizar-visita`, payload)
    },
}