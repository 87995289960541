import Api from '~/services/api'

export const Services = {
    getServiciosTalleres(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`control-piso`, payload)
    },
    createServicioTaller(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`control-piso`, payload)
    },
    editServicioTaller(payload){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.put(`control-piso/${payload.id_control_piso}`, payload)
    },
    deleteServicioTaller({id}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`control-piso/${id}`)
    },
    finalizarServicioTaller(payload){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.post(`/obras/vigentes/${payload.id_proyecto}/servicios/${payload.id_control_piso}/finalizar-servicio`, payload)
    },
    obtenerAspectosCalificar(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`/aspectos-calificar`, payload)
    },
}