import { UseCase } from "~/domain/core/UseCase";
import ViewInfoProvider from '~/services/proveedores/ver/ver'
import { $try } from '~/services/global'

class GetProviderBuyOrdersUseCase extends UseCase {
    static async execute(params){
        const data = await ViewInfoProvider.obtenerOrdenesCompraProveedor(params)
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetProviderBuyOrdersUseCase.execute