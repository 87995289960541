<template>
    <div>
        <l-map :zoom="zoom" :center="center" @click="setMarker" @update:zoom="updateZoom" @update:center="updateCenter">
        <l-tile-layer :url="url" :title="title"></l-tile-layer>
        <l-marker :lat-lng="marker"></l-marker>
        </l-map>
    </div>
</template>

<script>
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    name: 'MyAwesomeMap',
    components: {
        LMap,
        LTileLayer,
        LMarker,
    },
    data() {
        return {
            zoom:13,
            center: L.latLng(7.08086142192428, -73.1217),
            title: 'Este es el titulo',
            url:'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
            marker: L.latLng(7.08086142192428, -73.1217),
        }
    },
    methods: {
        updateZoom(zoom){
            this.zoom = zoom
        },
        updateCenter(center){
            this.center = center
        },
        setMarker(event) {
            const localizacion = { latitud: event.latlng.lat, longitud: event.latlng.lng}
            this.marker = L.latLng(localizacion.latitud, localizacion.longitud)
            this.$emit('localizacion', localizacion)
        },
        setLocalizacionProp(localizacion){
            this.marker = L.latLng(localizacion.latitud, localizacion.longitud)
            this.center = L.latLng(localizacion.latitud, localizacion.longitud)
            setTimeout(() => {
                this.zoom = localizacion.zoom
            },300)
            this.$emit('localizacion', { latitud: localizacion.latitud, longitud: localizacion.longitud})
        },
    },
}
</script>

<style lang="scss" scoped>
</style>