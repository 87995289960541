<template>
    <div class="container drag-upload">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <p class="f-12">Arrastra tu archivo aqui <required v-if="required"/></p>

                <div ref="drag" class="drag border br-5 cr-pointer drag my-2 mx-auto d-middle-center" 
                    alt="logo" @click="$refs.fileInput.click()" 
                    @mouseover="$refs.drag.classList.toggle('op-04')" 
                    @mouseleave="$refs.drag.classList.toggle('op-04')"
                >
                <div class=" ">
                    <i class="icon-paperclip f-40" />
                    <p class="f-8">Arrastre el archivo</p>
                </div>
                </div>

                <input ref="fileInput" type="file" :multiple="multiple" class="d-none" name="fileInput" id="" @change="uploadFilesController">
            </div>
            <p class="text-center text-a5 f-11">*Puede adjuntar archivos PNG, JPG, PDF, MOV, MP4</p>
            <div class="col-lg-12 flex-wrap gap-1" :class="filesToUpload.length > 1 ? 'd-middle m-3' : 'd-middle-center'">
                <div v-for="(file, index) in filesToUpload" :key="index" class="text-muted d-middle justify-content-between border-general br-5" style="width: 185px;">
                    <p class="tres-puntos f-12 ml-2">{{ file.name }}</p>
                    <div class="br-3 bg-general text-white px-1">
                        <i :ref="`file-${index}`" class="icon-cancel f-16 cr-pointer" @click="removeFile(file)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            filesToUpload: []
        }
    },
    methods: {
        uploadFilesController(e){
            let fileArray = Array.from(e.target.files)
            if(this.multiple) {
                fileArray.forEach(file => {
                    if(!this.filesToUpload.filter(fu => fu.name === file.name).length){
                        this.filesToUpload.push(file)
                    }
                })
            } else {
                this.filesToUpload = [fileArray[0]]
            }
            this.$emit('input', this.filesToUpload)
        },
        dropController(e){
            e.preventDefault()
            const data = e.dataTransfer.files[0]
            
            if(this.multiple){
                this.filesToUpload = []
            }

             if(this.filesToUpload.indexOf(data) == -1){
                this.filesToUpload.push(data)
                this.$emit('input', this.filesToUpload)
            } else {
                this.$notify({title: 'Este archivo ya está en la lista', type:'info'})
            }
        },
        removeFile(file){
            this.filesToUpload = this.filesToUpload.filter(upload => upload !== file)
            this.$emit('input', this.filesToUpload)
        },
        clearAll(){
            this.$refs.fileInput.value = ''
            this.filesToUpload = []
        }
    },
    mounted(){
        const drag = this.$refs.drag

        drag.ondragover = drag.ondragenter = (e) => {
            e.preventDefault()
        }
        
        drag.ondrop = this.dropController

    }
}
</script>

<style lang="scss" scoped>
    .drag-upload {
        .drag {
            width: 98px;
            height: 90px;
            /*background-image: url('/img/generales/iafLogo.png');
            background-size: cover;
            background-position: center;
            width: 18rem;
            height: 10rem; */
        }
    }
    .text-a5{
        color:#A5A5A5;
    }
</style>