import * as ProductsResumeDomain from '~/domain/use_cases/oportunidades/productos/resumen'
import { OpportunityProductResumeFactory } from '~/domain/entities/oportunidades/productos/factories/OpportunityProductResumeFactory';

export const state = {
    percentage_increases: [],
    resume: OpportunityProductResumeFactory.instantiateEmpty(),
    detalleEtapa: {},

}

export const getters = {
    percentage_increases: state => state.percentage_increases,
    resume: state => state.resume,
    detalleEtapa: state => state.detalleEtapa,
}

export const mutations = {
    set_percentage_increases(state, payload){
        state.percentage_increases = payload
    },
    set_resume(state, payload){
        state.resume = payload
    },
    set_detalle_etapa(state, payload){
        state.detalleEtapa = payload
    }
}

export const actions = {
    async Action_get_percentage_increases({ commit }){
        const data = await ProductsResumeDomain.GetPercentageIncreasesUseCase()
        if(data) commit('set_percentage_increases', data)
    },
    async Action_get_opportunity_product_resume({ commit }, payload){
        const data = await ProductsResumeDomain.GetOpportunityProductResumeUseCase(payload)
        if(data) commit('set_resume', data)
    },
    async Action_save_additional_hours({ commit }, payload){
        await ProductsResumeDomain.SaveAdditionalHoursUseCase(payload)
    },
    async Action_save_percentage_increase({ state, commit }, payload){
        const data = await ProductsResumeDomain.SavePercentageIncreaseUseCase(payload)
        if(data) {
            const resume = state.resume
            resume.incrementos_porcentuales.push(data)
            commit('set_resume', resume)
        }
    },
    async Action_update_percentage_increase({ state, commit }, payload){
        const { id, old_porcentaje, ...rest } = payload

        state.resume.incrementos_porcentuales = state.resume.incrementos_porcentuales.map(increase => ({...increase, porcentaje: 0 }))

        const data = await ProductsResumeDomain.UpdatePercentageIncreaseUseCase(id, rest)
        
        const resume = state.resume

        resume.incrementos_porcentuales = resume.incrementos_porcentuales.map(increase => {
            if(increase.id === id)  increase.porcentaje = data ? data.porcentaje : old_porcentaje
            return increase
        })

        commit('set_resume', resume)
    },
    async Action_delete_percentage_increase({ state, commit }, id_oportunidades_resumen_incrementos_porcentuales){
        const data = await ProductsResumeDomain.DeletePercentageIncreaseUseCase(id_oportunidades_resumen_incrementos_porcentuales)
        if(data) {
            const resume = state.resume
            resume.incrementos_porcentuales = resume.incrementos_porcentuales.filter(increase => increase.id != id_oportunidades_resumen_incrementos_porcentuales)
            commit('set_resume', resume)
        }
    },
    async Action_get_detalle_etapa({ state, commit }, payload){
        const data = await ProductsResumeDomain.GetDetalleEtapaOportunidadEquipo(payload)
        if(data) {
            commit('set_detalle_etapa', data)
        }
    },

}