import { GraficaPagar } from "../GraficaPagar";

export class GraficaPagarFactory {
    static instantiate(obj){
        return new GraficaPagar(obj)
    }

    static instantiateEmpty(){
        return new GraficaPagar({
            total_pagar: '',
            grafica: '',
            tabla: '',
        })
    }
}