import { UseCase } from '~/domain/core/UseCase'
import { Selects } from '~/services/selects/selects'
import { MaterialVOFactory } from '~/domain/value_objects/almacen/materiales/factories/MaterialVOFactory'
import { $try } from '~/services/global'

class GetSelectMaterialUseCase extends UseCase{
    static async execute(){
        const data = await Selects.GetSelectMaterials()
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) 
                return data.data.map(el => MaterialVOFactory.instantiate(el))
        })
    }
}

export default GetSelectMaterialUseCase.execute