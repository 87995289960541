import Api from '~/services/api'

export const PedidosInternos = {
    getEstadosPedidosInternos(){
        const Service = new Api()
        return Service.get('/almacen/pedidos-internos/estados')
    },

    postEstadosPedidosInternos(payload){
        const Service = new Api()
        return Service.post('/almacen/pedidos-internos/estados', payload)
    },

    putEstadosPedidosInternos(payload){
        const Service = new Api()
        return Service.put(`/almacen/pedidos-internos/estados/${payload.id}`, payload.payload)
    },

    deleteEstadosPedidosInternos(payload){
        const Service = new Api()
        return Service.delete(`/almacen/pedidos-internos/estados/${payload}`)
    },

    changePositionEstadosPedidosInternos(payload){
        const Service = new Api({ notification: true })
        return Service.put(`/almacen/pedidos-internos/cambiar-posicion/estados`, payload)
    },
}
