
import Api from '~/services/api'

export const Work = {
    getFolders(){
        const Service = new Api()
        return Service.get('configuracion/obras/carpetas')
    },
    createFolder(payload){
        const Service = new Api({ notification: true })
        return Service.post('configuracion/obras/carpetas', payload)
    },
    editFolder({id, payload}){
        const Service = new Api({ notification: true })
        return Service.put(`configuracion/obras/carpetas/${id}`, payload)
    },
    deleteFolder({id}){
        const Service = new Api({ notification: true })
        return Service.delete(`configuracion/obras/carpetas/${id}`)
    },
}
