import { PurchaseOrder } from "../PurchaseOrder";

export class PurchaseOrderFactory {
    static instantiate(obj){
        return new PurchaseOrder(obj)
    }

    static instantiateEmpty(){
        return new PurchaseOrder({
            id: null,
            id_oportunidad: null,
            id_proveedor: null,
            id_moneda: null,
            flete: null,
            id_ordenes_compras_grupos: null,
            tipo: '',
            estado: '',
            id_cliente: null,
            oportunidad: '',
            valor: '',
            proveedor: '',
        })
    }
}