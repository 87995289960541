import { OpportunityProductResume } from '../OpportunityProductResume'

export class OpportunityProductResumeFactory {
    static instantiate(obj){
        return new OpportunityProductResume(obj)
    }

    static instantiateEmpty(){
        return new OpportunityProductResume({
            id: null,
            horas_adicionales: 0,
            materiales: 0,
            horas_totales: 0,  
            valor_ultima_cotizacion: 0,
            incrementos_porcentuales: [],
        })
    }
}