import { UseCase } from "~/domain/core/UseCase"
import { Cartera } from "~/services/cobro_cartera/cartera"
import { CobrarFactory } from "~/domain/entities/cobro_cartera/factories/CobrarFactory"
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from "~/services/global"

class GetCobrarUseCase extends UseCase{
    static async execute(payload){
        const data = await Cartera.getCobroCarteraCobrar(payload)
        return $try(() => {
            if(data?.data) 
                return new PaginationDecorator(data.data, CobrarFactory.instantiate);
        })
    }
}

export default GetCobrarUseCase.execute