import { UseCase } from "~/domain/core/UseCase"
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'
import { ConfiguracionEtapaFactory } from '~/domain/entities/cotizaciones/cotizaciones_clientes/factories/configuracionEtapaFactory'
import { $try } from '~/services/global'


class PostAddConfigurationUseCase extends UseCase {
    static async execute(payload){
        const  data  =  await CotizacionCliente.postAddConfiguracion(payload)
        return $try(() => {
            if(data?.data) return new ConfiguracionEtapaFactory.instantiate(data.data)
        })
    }
}

export default PostAddConfigurationUseCase.execute