import { UseCase } from "~/domain/core/UseCase";
import { Selects } from "~/services/selects/selects";
import { $try } from "~/services/global";
import { BillVOFactory } from "~/domain/value_objects/compras/servicios/factories/BillVOFactory";

class GetSelectBillsUseCase extends UseCase {
    static async execute() {
        const data = await Selects.GetSelectBills()
        return $try(() => {
            if (!Array.isArray(data?.data)) return []
            return data.data.map(BillVOFactory.instantiate)
        })
    }
}

export default GetSelectBillsUseCase.execute;