import * as EquiposModelos from '~/domain/use_cases/almacen/equipos_modelos'
import { $try } from '~/services/global'

export const state = {
    equipos: {},
    detalleEquipos: {},
    etapasConfiguraciones: [],
    detalleCabecera: {},
}

export const getters = {
    equipos: state => state.equipos,
    detalleEquipos: state => state.detalleEquipos,
    etapasConfiguraciones: state => state.etapasConfiguraciones,
    detalleCabecera: state => state.detalleCabecera,
}

export const mutations = {
    set_equipos(state, payload) {
        state.equipos = payload
    },
    set_detalle_equipos(state, payload) {
        state.detalleEquipos = payload
    },
    set_etapas_configuraciones(state, payload) {
        state.etapasConfiguraciones = payload
    },
    set_detalle_cabecera(state, payload) {
        state.detalleCabecera = payload
    },
}

export const actions = {
    async Action_get_equipos_modelos({ commit }, payload) {
        const data = await EquiposModelos.GetEquiposModelosUseCase(payload)
        console.log("🚀 ~ file: equiposModelos.js:36 ~ Action_get_equipos_modelos ~ data", data)
        $try ( () => { commit('set_equipos', data) } )
    },
    async Action_create_equipos_modelos(_, payload) {
        await EquiposModelos.CreateEquiposModelosUseCase(payload)
    },
    async Action_get_detalle_equipos_modelos({ commit }, payload) {
        const data = await EquiposModelos.GetDetalleEquipoUseCase(payload)
        $try ( () => { commit('set_detalle_equipos', data) } )
    },
    async Action_edit_equipo_modelo(_, params) {
        await EquiposModelos.EditEquipoUseCase(params)
    },
    async Action_delete_equipo_modelo(_, payload) {
        await EquiposModelos.DeleteEquipoUseCase(payload)
    },
    async Action_guardar_config_equipo(_, params) {
        await EquiposModelos.GuardarConfiguracionUseCase(params)
    },
    async Action_get_etapas_configuraciones({ commit }, payload) {
        const data = await EquiposModelos.GetEtapasConfiguracionesUseCase(payload)
        $try ( () => { commit('set_etapas_configuraciones', data) } )
    },
    async Action_get_detalle_cabecera({ commit }, payload) {
        const data = await EquiposModelos.GetDetalleCabeceraUseCase(payload)
        $try ( () => { commit('set_detalle_cabecera', data) } )
    },
}