import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { PurchaseOrderFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/PurchaseOrderFactory'
import { $try } from '~/services/global'

class GetPurchaseOrderInfoUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso, id_orden_compra, filter }){
        const data = await PurchaseOrder.getPurchaseOrderInfo(id_proyecto, id_control_piso, id_orden_compra, filter)
        return $try(() => {
            if(!data?.data) return PurchaseOrderFactory.instantiateEmpty();

            return PurchaseOrderFactory.instantiate(data.data);
        })
    }
}

export default GetPurchaseOrderInfoUseCase.execute;