import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { OpportunityConfigurationFactory } from '~/domain/entities/oportunidades/productos/factories/OpportunityConfigurationFactory'
import { $try } from '~/services/global'

class CreateOpportunityStageUseCase extends UseCase {
    static async execute(params){
        const { id_configuracion, ...rest } = params
        await Opportunities.createOpportunityStageConfigurations({payload: {id_configuracion, tabla:params.tabla}, ...rest})
    }
}

export default CreateOpportunityStageUseCase.execute