import { Entity } from '~/domain/entities'

export class WorkshopCalendarActivity extends Entity{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'nombre',
                'id',
                'duracion_dias',
                'dia_inicio',
                'id_actividad_predecesora',
                'descripcion',
                'color',
                'deleted_at',
                'id_configuracion_calendarios_talleres',
                'posicion',
            ]
        })
    }
}