import * as SelectDomain from "~/domain/use_cases/selects"

import { $try } from '~/services/global'

export const state = {
    select_projects: [],
    select_providers: [],
    select_clients: [],
    select_countries: [],
    select_opportunity_type: [],
    select_material_types: [],
    select_measure_units: [],
    select_opportunity_responsibles: [],
    select_opportunity_customers: [],
    select_opportunity_equipos: [],
    select_opportunity_equipos_modelos: [],
    select_materials: [],
    select_cotizaciones: [],
    select_bodegas: [],
    select_states: [],
    select_states_paginated: [],
    select_responsible_users: [],
    select_city_paginated: [],
    list_check: [],
    select_talleres: [],
    select_configuraciones: [],
    select_configuraciones_secciones: [],
    select_responsible_workshops: [],
    select_categorias: [],
    select_sub_categorias: [],
    select_bills: [],
    select_actividad_predecesora: [],
    select_proyecto_pedido: [],
    select_producto_pedido: [],
    select_materiales_2: [],
    select_categorias: [],
    select_espesor: [],
    select_user_solicita: [],
    select_wineries: [],
    select_monedas:[],
    select_encargados_users:[],
    select_equipos_modelos:[],
    select_responsables_proveedores:[],
	select_users_internos:[],
	select_all_projects:[]
}

export const getters = {
    select_projects: state => state.select_projects,
    select_providers: state => state.select_providers,
    select_clients: state => state.select_clients,
    select_countries: state => state.select_countries,
    select_opportunity_type: state => state.select_opportunity_type,
    select_material_types: state => state.select_material_types,
    select_measure_units: state => state.select_measure_units,
    select_opportunity_responsibles: state => state.select_opportunity_responsibles,
    select_opportunity_customers: state => state.select_opportunity_customers,
    select_opportunity_equipos: state => state.select_opportunity_equipos,
    select_opportunity_equipos_modelos: state => state.select_opportunity_equipos_modelos,
    select_materials: state => state.select_materials,
    select_cotizaciones: state => state.select_cotizaciones,
    select_bodegas: state => state.select_bodegas,
    select_states: state => state.select_states,
    select_states_paginated: state => state.select_states_paginated,
    select_responsible_users: state => state.select_responsible_users,
    select_city_paginated: state => state.select_city_paginated,
    select_city_paginated: state => state.select_city_paginated,
    list_check: state => state.list_check,
    select_talleres: state => state.select_talleres,
    select_configuraciones: state => state.select_configuraciones,
    select_configuraciones_secciones: state => state.select_configuraciones_secciones,
    select_responsible_workshops: state => state.select_responsible_workshops,
    select_categorias: state => state.select_categorias,
    select_sub_categorias: state => state.select_sub_categorias,
    select_bills: state => state.select_bills,
    select_actividad_predecesora: state => state.select_actividad_predecesora,
    select_proyecto_pedido: state => state.select_proyecto_pedido,
    select_producto_pedido: state => state.select_producto_pedido,
    select_materiales_2: state => state.select_materiales_2,
    select_categorias: state => state.select_categorias,
    select_espesor: state => state.select_espesor,
    select_user_solicita: state => state.select_user_solicita,
    select_wineries: state => state.select_wineries,
    select_encargados_users: state => state.select_encargados_users,
    select_monedas: state => state.select_monedas,
    select_equipos_modelos: state => state.select_equipos_modelos,
    select_responsables_proveedores: state => state.select_responsables_proveedores,
	select_users_internos: state => state.select_users_internos,
	select_all_projects: state => state.select_all_projects,
}

export const mutations = {
    set_select_projects(state, payload){
        state.select_projects = payload
    },
    set_select_providers(state, payload){
        state.select_providers = payload
    },
    set_select_clients(state, payload){
        state.select_clients = payload
    },
    set_select_countries(state, payload){
        state.select_countries = payload
    },
    set_select_opportunity_type(state, payload){
        state.select_opportunity_type = payload
    },
    set_select_material_types(state, payload){
        state.select_material_types = payload
    },
    set_select_measure_units(state, payload){
        state.select_measure_units = payload
    },
    set_select_opportunity_equipos(state, payload){
        state.select_opportunity_equipos = payload
    },
    set_select_opportunity_equipos_modelos(state, payload){
        state.select_opportunity_equipos_modelos = payload
    },
    set_select_opportunity_responsibles(state, payload){
        state.select_opportunity_responsibles = payload
    },
    set_select_opportunity_customers(state, payload){
        state.select_opportunity_customers = payload
    },
    set_select_materials(state, payload){
        state.select_materials = payload
    },
    set_select_cotizaciones(state, payload){
        state.select_cotizaciones = payload
    },
    set_select_bodegas(state, payload){
        state.select_bodegas = payload
    },
    set_select_states(state, payload){
        state.select_states = payload
    },
    set_select_states_paginated(state, payload){
        state.select_states_paginated = payload
    },
    set_selects_responsible_users(state, payload){
        state.select_responsible_users = payload
    },
    set_select_city_paginated(state, payload){
        state.select_city_paginated = payload
    },
    set_select_list_check(state, payload){
        state.list_check = payload
    },
    set_select_talleres(state, payload){
        state.select_talleres = payload
    },
    set_select_configuraciones(state, payload){
        state.select_configuraciones = payload
    },
    set_select_configuraciones_secciones(state, payload){
        state.select_configuraciones_secciones = payload
    },
    set_select_responsible_workshops(state, payload){
        state.select_responsible_workshops = payload
    },
    set_select_categorias(state, payload){
        state.select_categorias = payload
    },
    set_select_sub_categorias(state, payload){
        state.select_sub_categorias = payload
    },
    set_select_bills(state, payload){
        state.select_bills = payload
    },
    set_select_actividad_predecesora(state, payload){
        state.select_actividad_predecesora = payload
    },
    set_select_proyecto_pedido(state, payload){
        state.select_proyecto_pedido = payload
    },
    set_select_producto_pedido(state, payload){
        state.select_producto_pedido = payload
    },
    set_select_materiales_2(state, payload){
        state.select_materiales_2 = payload
    },
    set_select_categorias(state, payload){
        state.select_categorias = payload
    },
    set_select_espesor(state, payload){
        state.select_espesor = payload
    },
    set_select_user_solicita(state, payload){
        state.select_user_solicita = payload
    },
    set_select_wineries(state, payload){
        state.select_wineries = payload
    },
    set_select_encargados_users(state, payload){
        state.select_encargados_users = payload
    },
    set_select_monedas(state, payload){
        state.select_monedas = payload
    },
    set_select_equipos_modelos(state, payload){
        state.select_equipos_modelos = payload
    },
    set_select_responsables_proveedores(state, payload){
        state.select_responsables_proveedores = payload
    },
	set_select_users_internos(state, payload){
        state.select_users_internos = payload
    },
	set_select_all_projects(state, payload){
        state.select_all_projects = payload
    },
}

export const actions = {
    async sync({dispatch}, arr){
        if(!(arr && Array.isArray(arr))) throw Error('El parametro esperado no es iterable');
        const requests = arr.map(el => dispatch(el))
        await Promise.all(requests);
    },
    async Action_get_select_projects({commit}){
        const data = await SelectDomain.GetSelectProjectsUseCase()

        $try(() => {
            commit('set_select_projects', data);
        });
    },
    async Action_get_select_providers({commit}){
        const data = await SelectDomain.GetSelectProvidersUseCase()

        $try(() => {
            commit('set_select_providers', data);
        });
    },
    async Action_get_select_clients({commit}){
        const data = await SelectDomain.GetSelectClientsUseCase()

        $try(() => {
            commit('set_select_clients', data);
        });
    },
    async Action_get_select_countries({commit}){
        const data = await SelectDomain.GetSelectCountriesUseCase()
        $try(() => {
            commit('set_select_countries', data);
        });
    },
    async Action_get_select_material_types({commit}){
        const  data = await SelectDomain.GetSelectMaterialTypesUseCase() 
        $try(() => 
            commit('set_select_material_types', data)
        );
    },
    async Action_get_select_measure_units({commit}){
        const  data = await SelectDomain.GetSelectMeasureUnitsUseCase() 
        $try(() => 
            commit('set_select_measure_units', data)
        );
    },
    async Action_get_select_opportunity_type({commit}){
        const data = await SelectDomain.GetSelectOpportunityTypeUseCase()
        $try(() => {
            commit('set_select_opportunity_type', data);
        });
    },
    async Action_get_select_opportunity_equipos({commit}){
        const data = await SelectDomain.GetSelectOppotunityEquiposUseCase()
        $try(() => {
            commit('set_select_opportunity_equipos', data);
        });
    },
    async Action_get_select_opportunity_equipos_modelos({commit}, payload){
        const data = await SelectDomain.GetSelectOppotunityEquiposModelosUseCase(payload)
        $try(() => {
            commit('set_select_opportunity_equipos_modelos', data);
        });
    },
    async Action_get_select_opportunity_responsibles({commit}, payload){
        const data = await SelectDomain.GetSelectOpportunityResponsiblesUseCase(payload)
        $try(() => {
            commit('set_select_opportunity_responsibles', data);
        });
    },
    async Action_get_select_opportunity_customers({commit}, payload){
        const data = await SelectDomain.GetSelectOppotunityCustomersUseCase(payload)
        $try(() => {
            commit('set_select_opportunity_customers', data);
        });
    },

    async Action_get_select_materials({ commit }){
        const data = await SelectDomain.GetSelectMaterialUseCase()
        $try(() => {
            commit('set_select_materials', data)
        })
    },

    async Action_get_select_cotizaciones({commit}, payload){
        const data = await SelectDomain.GetSelectCotizacionesUseCase(payload)
        $try(() => {
            commit('set_select_cotizaciones', data);
        });
    },

    async Action_get_selects_bodegas({commit}){
        const data = await SelectDomain.GetSelectFiltersBodegasUseCase()
        $try(() => {
            commit('set_select_bodegas', data ?? []);
        });
    },

    async Action_get_select_states({commit}, payload){
        const data = await SelectDomain.GetSelectStatesUseCase(payload)
        $try(() => {
            commit('set_select_states', data ?? []);
        });
    },
    async Action_get_selects_states_paginated({commit}, payload){
        const data = await SelectDomain.GetSelectStatesPaginatedUseCase(payload)
        $try(() => {
            commit('set_select_states_paginated', data)
        })
    },
    async Action_get_selects_responsible_users({commit}, payload){
        const data = await SelectDomain.GetSelectResposibleUserUseCase()
        $try(() => {
            commit('set_selects_responsible_users',data)
        })
    },
    async Action_get_selects_city_paginated({commit}, payload){
        const data = await SelectDomain.GetSelectCityPaginatedUseCase(payload)
        $try(() => {
            commit('set_select_city_paginated', data)
        })
    },
    async Action_get_selects_list_check({commit}){
        const data = await SelectDomain.GetSelectListaChequeoUseCase()
        $try(() => {
            commit('set_select_list_check', data)
        })
    },
    async Action_get_selects_talleres({commit}){
        const data = await SelectDomain.GetSelectTalleresUseCase()
        $try(() => {
            commit('set_select_talleres', data)
        })
    },
    async Action_get_selects_configuraciones({commit}, payload){
        const data = await SelectDomain.GetSelectConfiguracionUseCase(payload)
        $try(() => {
            commit('set_select_configuraciones', data)
        })
    },
    async Action_get_selects_configuraciones_secciones({commit}, payload){
        const data = await SelectDomain.GetSelectConfiguracionSeccionUseCase(payload)
        $try(() => {
            commit('set_select_configuraciones_secciones', data)
        })
    },
    async Action_get_selects_responsible_workshops({commit}){
        const data = await SelectDomain.GetSelectResponsibleWorkshopsUseCase()
        $try(() => {
            commit('set_select_responsible_workshops', data)
        })
    },
    async Action_get_selects_categorias({commit}, payload){
        const data = await SelectDomain.GetSelectCategoriasUseCase(payload)
        $try(() => {
            payload
                ? commit('set_select_sub_categorias', data)
                : commit('set_select_categorias', data)
            })
    },
    async Action_get_select_bills({commit}){
        const data = await SelectDomain.GetSelectBillsUseCase()
        $try(() => {
            commit('set_select_bills', data)
        })
    },
    async Action_get_actividad_predecesora({commit}, payload){
        const data = await SelectDomain.GetActividadesPredecesorasUseCase(payload)
        $try(() => {
            commit('set_select_actividad_predecesora', data)
        })
    },
    async Action_get_actividad_predecesora_talleres({commit}, payload){
        const data = await SelectDomain.GetActividadesPredecesorasTalleresUseCase(payload)
        $try(() => {
            commit('set_select_actividad_predecesora', data)
        })
    },
    async Action_get_select_proyecto_pedido({commit}){
        const data = await SelectDomain.GetSelectProyectoPedidoUseCase()
        $try(() => {
            commit('set_select_proyecto_pedido', data)
        })
    },
    async Action_get_select_producto_pedido({commit}, payload){
        const data = await SelectDomain.GetSelectProductoPedidoUseCase(payload)
        $try(() => {
            commit('set_select_producto_pedido', data)
        })
    },
    async Action_get_select_categorias({commit}){
        const data = await SelectDomain.GetSelectCategoriaUseCase()
        $try(() => {
            commit('set_select_categorias', data)
        })
    },
    async Action_get_select_user_solicita({commit}){
        const data = await SelectDomain.GetSelectUserSolicitaUseCase()
        $try(() => {
            commit('set_select_user_solicita', data)
        })
    },
    async Action_get_select_materiales_2({commit}, payload){
        const data = await SelectDomain.GetSelectMateriales2UseCase(payload)
        $try(() => {
            commit('set_select_materiales_2', data)
        })
    },
    async Action_get_select_espesor({commit}, payload){
        const data = await SelectDomain.GetSelectEspesorUseCase(payload)
        $try(() => {
            commit('set_select_espesor', data)
        })
    },
    async Action_get_select_wineries({commit}){
        const data = await SelectDomain.GetSelectWineriesUseCase()
        $try(() => {
            commit('set_select_wineries', data)
        })
    },
    async Action_get_select_monedas({commit}){
        const data = await SelectDomain.GetSelectMonedasUseCase()
        $try(() => {
            commit('set_select_monedas', data)
        })
    },
    async Action_get_selects_encargados_users({commit}){
        const data = await SelectDomain.GetSelectEncargadosUserUseCase()
        $try(() => {
            commit('set_select_encargados_users', data)
        })
    },
    async Action_get_selects_all_equipos_modelos({commit}){
        const data = await SelectDomain.GetSelectAllEquiposModelosUseCase()
        $try(() => {
            commit('set_select_equipos_modelos', data)
        })
    },
    async Action_get_select_proveedores({commit}){
        const data = await SelectDomain.GetSelectResponsableProveedoresUseCase()
        $try(() => {
            commit('set_select_responsables_proveedores', data)
        })
    },
	async Action_get_selects_users_internos({commit}){
        const data = await SelectDomain.GetSelectUsersInternosUseCase()
        $try(() => {
            commit('set_select_users_internos', data)
        })
    },
	async Action_get_selects_all_projects({commit}){
        const data = await SelectDomain.GetSelectAllProjectsUseCase()
        $try(() => {
            commit('set_select_all_projects', data)
        })
    },
}