import Api from '~/services/api'

export const PresenciaEquipos = {
    getVentasPresenciaEquipos(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ventas/presencia-equipos`, payload)
    },
    getEquipos(){
        const ApiHandler = new Api()
        return ApiHandler.get(`select/equipos-vendidos`)
    },
    getClientes(){
        const ApiHandler = new Api()
        return ApiHandler.get(`select/clientes-documentos`)
    },
    getRangos(){
        const ApiHandler = new Api()
        return ApiHandler.get(`ventas/presencia-equipos/filtros`)
    },
    getdetalleEquipoVendido(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ventas/presencia-equipos/${payload.id}`, { source:payload.tabla })
    },
    getMaterialesConfiguracion(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ventas/configuracion-detalle/${payload.id}/materiales`, payload)
    },
    getCabeceraDetalleMateriales(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ventas/configuracion-detalle/${payload.id}/detalle`, { source:payload.tabla})
    },
    getFiltroMateriales(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ventas/configuracion-detalle/${payload.id}/filtros`, { source:payload.tabla})
    },
    
}