import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { EquiposFactory } from '~/domain/entities/oportunidades/information/factories/EquiposFactory'
import { $try } from '~/services/global'

class GetOpportunityEquiposUseCase extends UseCase {
    static async execute(params){
        const data =  await Opportunities.getEquiposOpportunity(params)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(type => EquiposFactory.instantiate(type))
        })
    }
}

export default GetOpportunityEquiposUseCase.execute