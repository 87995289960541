import Api from '~/services/api'

export const AdditionalData = {
    getAdditionalData(){
        const Service = new Api()
        return Service.get('configuraciones/almacen/datos-adicionales')
    },
    getAdditionalDataPosicion(payload) {
        const Service = new Api({ notification: true })
        return Service.post('configuraciones/almacen/datos-adicionales/posicion', payload)
    },
    getUniqueAdditionalData({id}){
        const Service = new Api()
        return Service.get(`configuraciones/almacen/datos-adicionales/${id}`)
    },
    getAdditionalDataTypes(){
        const Service = new Api()
        return Service.get('configuraciones/almacen/datos-adicionales-tipos')
    },
    getAdditionalDataTypesPosicion(payload){
        const Service = new Api({ notification: true })
        return Service.post('configuraciones/almacen/datos-adicionales-tipos/posicion', payload)
    },
    getUniqueAdditionalDataType({id}){
        const Service = new Api()
        return Service.get(`configuraciones/almacen/datos-adicionales-tipos/${id}`)
    },
    createAditionalDataTypes(payload){
        const Service = new Api({ notification: true })
        return Service.post('configuraciones/almacen/datos-adicionales-tipos', payload)
    },
    createAditionalData(payload){
        const Service = new Api({ notification: true })
        return Service.post('configuraciones/almacen/datos-adicionales', payload)
    },
    editAditionalDataTypes({id, payload}){
        const Service = new Api({ notification: true })
        return Service.put(`configuraciones/almacen/datos-adicionales-tipos/${id}`, payload)
    },
    editAditionalData({id, payload}){
        const Service = new Api({ notification: true })
        return Service.put(`configuraciones/almacen/datos-adicionales/${id}`, payload)
    },
    deleteAditionalDataTypes({id}){
        const Service = new Api({ notification: true })
        return Service.delete(`configuraciones/almacen/datos-adicionales-tipos/${id}`)
    },
    deleteUniqueAdditionalData({id}){
        const Service = new Api({ notification: true })
        return Service.delete(`configuraciones/almacen/datos-adicionales/${id}`)
    },
}
