<template>
    <div class="main-layout">
        <navbar />
        <navigation-drawer />
        <div class="position-relative app-wrapperPrueba">
            <div class="contenedorPrueba">
                <child />
            </div>
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
export default {
    name: 'PruebaSections',
}
</script>
<style lang="scss" >
main{
    padding-left: 65px !important
}
.app-wrapperPrueba {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: var(--color-5d);
    //height: calc(100vh - 133px);
    width:calc(100vw - 75px);
    margin-left: auto;
    top: 90px;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
.contenedorPrueba{
    //min-height: 95%;
    width: 98%;
    //margin: auto 0;
    //box-shadow: 0 3px 6px 0 #00000029;
    //border-radius: 4px;

}
</style>
