import { UseCase } from "~/domain/core/UseCase";
import { OrdenesCompras } from '~/services/obras/ordenes_compras/ordenesCompras'
import { InventarioMaterialFactory } from '~/domain/entities/obras/ordenes_compra/factories/InventarioMaterialFactory'
import { $try } from '~/services/global'

class GetInventarioMaterialUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await OrdenesCompras.getInventarioMaterial(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new InventarioMaterialFactory.instantiate(e)) 
        })
    }
}

export default GetInventarioMaterialUseCase.execute