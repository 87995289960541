import { Entity } from '~/domain/entities'

export class PurchaseOrderInfo extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                'id',
                'destino',
                'estado',
                'facturas',
                'fecha',
                'flete',
                'valor_pagado',
                'id_oportunidad',
                'descripcion',
                'id_moneda',
                'id_proveedor',
                'oportunidad',
                'proveedor',
                'tipo',
                'valor',
            ]
        });
    }
}