import { Material } from "../Material";

export class MaterialFactory {
    static instantiate(obj){
        return new Material(obj)
    }

    static instantiateEmpty(){
        return new Material({
            id: '',
            cantidad: '',
            nombre: '',
            id_oportunidad: '',
            proveedor: [],
            bodega: [],
        })
    }
}