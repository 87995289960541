import { UseCase } from "~/domain/core/UseCase";
import { Documents } from "~/services/talleres/ver_documentos";
import { FolderFactory } from "~/domain/entities/talleres/factories/FolderFactory";
import { $try } from "~/services/global";

class UpdateFolderUseCase extends UseCase {
    static async execute({ id_taller, folder, id_folder }) {
        const data = await Documents.updateFolder(id_taller, id_folder, folder);
        return $try(() => {
            if(data.data){
                return FolderFactory.instantiate(data.data)
            }
        })
    }
}

export default UpdateFolderUseCase.execute