import { Filter } from '~/domain/core/Filter';

export class CotizacionVencidaFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'referencia',
                'fecha_inicio_cotizacion',
                'fecha_fin_cotizacion',
            ]
        });
    }
}