import { Entity } from '~/domain/entities'

export class Category extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'nombre', 
                'imagen',
                'cantidad_materiales', 
                'valor', 
                'etapas', 
                'planes_mantenimiento', 
                'materiales_activos',
                'materiales_inactivos',
                'id_proveedor_favorito',
                'proveedor',
                'created_at'
            ]
        })
    }
}