import Api from '~/services/api'

const ApiHandler = new Api()

export const Bills = {
    getBills(id, filters){
        return ApiHandler.get(`compras/facturas/${id}`, filters)
    },
    getFilterProviders(){
        return ApiHandler.get('compras/facturas/filtros/proveedores')
    },
    getFilterStates(){
        return ApiHandler.get('compras/facturas/filtros/estados')
    },
    getFacturas(id){
        return ApiHandler.get(`compras/facturas/${id}`)
    },
    getOrdenesCompra(id, payload){
        return ApiHandler.get(`compras/facturas/${id}/ordenes-compra`, payload)
    },
    getFilterClientes(){
        return ApiHandler.get('compras/facturas/filtros/clientes')
    },
    getFilterOportunidades(){
        return ApiHandler.get('compras/facturas/filtros/oportunidades')
    },
    getFilterRangoValor(id){
        return ApiHandler.get(`compras/facturas/filtros/valor-maximo/${id}`)
    }
}