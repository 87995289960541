import { UseCase } from "~/domain/core/UseCase";
import { ListaChequeo } from '~/services/obras/servicios/lista_chequeo'
import { DetalleServicioFactory } from '~/domain/entities/obras/servicios/lista_chequeo/factories/detalleServicioFactory'
import { $try } from '~/services/global'

class GetDetalleServicioUseCase extends UseCase {
    static async execute(payload){
        const data =  await ListaChequeo.getDetalleServicio(payload)
        return $try(() => {
            return data?.data ? DetalleServicioFactory.instantiate(data.data) : {}
        })
    }
}

export default GetDetalleServicioUseCase.execute