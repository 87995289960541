
import { UseCase } from '~/domain/core/UseCase'
import { Workshops } from '~/services/talleres/talleres'

class DeleteWorkshopUseCase extends UseCase {
    static async execute(id_taller){
        await Workshops.deleteWorkshop(id_taller)
    }
}

export default DeleteWorkshopUseCase.execute