import { UseCase } from "~/domain/core/UseCase";
import { MaterialCotizacion } from '~/services/almacen/cotizaciones/cotizaciones'
import { CotizacionOptionFactory } from '~/domain/entities/almacen/cotizaciones/factories/CotizacionOptionFactory'
import { $try } from '~/services/global'

class GetCotizacionOptionUseCase extends UseCase{
    static async execute(idMaterial){
        const data = await MaterialCotizacion.getOptionsMaterialCotizacion(idMaterial)
        return $try(() => {
            return data?.data ? CotizacionOptionFactory.instantiate(data.data): null
        })
    }
}
export default GetCotizacionOptionUseCase.execute