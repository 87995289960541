import { CotizacionesVO } from "../CotizacionesVO";

export class CotizacionesVOFactory {
    static instantiate(obj){
        return new CotizacionesVO(obj)
    }

    static instantiateEmpty(){
        return new CotizacionesVO({
            id: '',
            referencia: '',
            fecha_vencimiento: '',
        })
    }
}