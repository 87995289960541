import { UseCase } from "~/domain/core/UseCase";
import { CotizacionVigente } from '~/services/cotizaciones/cotizacion_vigente'
import { $try } from '~/services/global'

class GetEstadoCotizacionUseCase extends UseCase{
    static async execute(){
        const  data  =  await CotizacionVigente.getEstadoCotizacion()
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data
            return []
        })
    }
}

export default GetEstadoCotizacionUseCase.execute