import { UseCase } from "~/domain/core/UseCase"
import Materials from "~/services/almacen/materiales"

class CreateMaterialUseCase extends UseCase {
    static async execute(params){
        const data = await Materials.createMaterial(params)

        if(data?.data) return data.data
    }
}

export default CreateMaterialUseCase.execute
