import { UseCase } from '~/domain/core/UseCase'
import Services from '~/services/configurar/talleres/tipos_servicios'

class DeleteServicesWorkshopUsecase extends UseCase {
    static async execute(id){
        const data = await Services.deleteServicesWorkshop(id);

        return data?.message
    }
}

export default DeleteServicesWorkshopUsecase.execute