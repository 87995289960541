import { PurchaseOrderListFilter } from "../PurchaseOrderListFilter";

export class PurchaseOrderListFilterFactory {
    static instantiate(obj) {
        return new PurchaseOrderListFilter(obj)
    }

    static instantiateEmpty() {
        return new PurchaseOrderListFilter({
            id_oportunidad: null,
            proveedores: null,
            id_cliente: null,
            fecha_inicio: null,
            fecha_fin: null,
            valor_inicial: null,
            valor_final: null,
            estado: null,
            estado_pago: null
        })
    }

    static instantiateEmptyWithRange(rango) {
        const valor_inicial = rango?.valor_inicial ?? 0;
        const valor_final = rango?.valor_final ?? 0;

        const filter = new PurchaseOrderListFilter({
            id_oportunidad: null,
            proveedores: null,
            id_cliente: null,
            fecha_inicio: null,
            fecha_fin: null,
            valor_inicial,
            valor_final,
            estado: null,
            estado_pago: null
        })

        filter.setExceptions({
            valor_inicial,
            valor_final
        })

        return filter
    }
}