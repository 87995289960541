import { UseCase } from "~/domain/core/UseCase";
import { VisitasService } from '~/services/obras/servicios/visitas'

class DeleteVisitUseCase extends UseCase {
    static async execute(payload){
        const data =  await VisitasService.deleteVisit(payload)
        return data?.message
    }
}

export default DeleteVisitUseCase.execute