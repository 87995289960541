import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/forma_pago';
import { PaymentMethodFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/PaymentMethodFactory';
import { $try } from '~/services/global';

class SavePaymentMethodFeesUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso, payload }) {
        const data = await PurchaseOrder.savePaymentFee(id_proyecto, id_control_piso, payload);

        return $try(() => {
            if(!data?.data) return [];
            return data.data.map(el => PaymentMethodFactory.instantiate({...el, valor: parseFloat(el.valor) }));
        });  
    }
}

export default SavePaymentMethodFeesUseCase.execute;