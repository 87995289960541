import { ValueObject } from '~/domain/core/ValueObject'

export class CalendarSubactivityVO extends ValueObject{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'nombre', 
                'id', 
                'created_at', 
                'posicion' , 
                'duracion_dias', 
                'dia_inicio', 
                'id_actividad_predecesora', 
                'descripcion', 
                'color', 
                'id_configuracion_calendarios_actividades'
            ]
        })
    }
}