import { UseCase } from "~/domain/core/UseCase";
import { PresenciaEquipos } from '~/services/ventas/presenciaEquipos'
import { ListaClientesFactory } from '~/domain/entities/ventas/factories/ListaClientesFactory'
import { $try } from '~/services/global'

class GetClientesUseCase extends UseCase {
    static async execute(){
        const data = await PresenciaEquipos.getClientes()
        return $try(() => {
            if(!Array.isArray(data?.data)) return []
            return data.data.map(ListaClientesFactory.instantiate)
        })
    }
}

export default GetClientesUseCase.execute