import { UseCase } from "~/domain/core/UseCase";
import { PresenciaEquipos } from '~/services/ventas/presenciaEquipos'
import { MaterialesFactory } from '~/domain/entities/ventas/factories/MaterialesFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetMaterialesConfiguracionUseCase extends UseCase {
    static async execute(payload){
        const data =  await PresenciaEquipos.getMaterialesConfiguracion(payload)
        return $try(() => {
            if( data?.data ){
                return new PaginationDecorator( data.data, MaterialesFactory.instantiate).toTable();
            }
        })
    }
}

export default GetMaterialesConfiguracionUseCase.execute