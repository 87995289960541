import { Category } from "../Category";

export class CategoryFactory {
    static instantiate(obj){
        return new Category(obj);
    }
    static instantiateEmpty(){
        return new Category({
            id: null,
            nombre: '',
            cantidad_materiales: null,
            valor: 0,
            etapas: null,
            imagen: null,
            planes_mantenimiento: null,
            materiales_activos: null,
            materiales_inactivos: null,
            id_proveedor_favorito: null,
            proveedor: {
                id: null,
                nombre: ''
            },
            created_at: '',
        })
    }
}