import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { $try } from '~/services/global'

class GetSelectMaterialesUseCase extends UseCase {

    static async execute(payload){
        const data =  await Opportunities.getSelectMateriales(payload)
        return $try( () => data?.data ?? [])
    }
}

export default GetSelectMaterialesUseCase.execute