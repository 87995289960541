import { UseCase } from '~/domain/core/UseCase';
import { WorkshopView } from '~/services/talleres/ver'
import { WorkshopFactory } from '~/domain/entities/talleres/factories/WorkshopFactory'
import { $try } from '~/services/global'

class GetWorkshopInfoUseCase extends UseCase{
    static async execute(id_taller){
        const data = await WorkshopView.getWorkshopInfo(id_taller);
        return $try(() => {
            if(!data?.data) return {}
            return WorkshopFactory.instantiate({...data.data, calificacion: data.data?.calificacion ?? 0})
        })
    }
}

export default GetWorkshopInfoUseCase.execute;