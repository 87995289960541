import * as DocumentsDomain from '~/domain/use_cases/talleres/ver/documentos';

export const state = {
    id_taller: null,
    id_folder: null,
    folders: [],
    files: [],
}

export const mutations = {
    set_folders(state, payload){
        state.folders = payload
    },
    set_id_taller(state, payload){
        state.id_taller = payload
    },
    set_files(state, payload){
        state.files = payload
    },
    set_id_folder(state, payload){
        state.id_folder = payload
    }
}

export const getters = {
    folders: state => state.folders,
    files: state => state.files,
    id_folder: state => state.id_folder,
}

export const actions = {
    async Action_get_folders({ commit, }){
        const data = await DocumentsDomain.GetFoldersUseCase({id_taller: state.id_taller});
        commit('set_folders', data);
    },
    async Action_save_folder({ state, commit }, folder){
        const data = await DocumentsDomain.SaveFolderUseCase({id_taller: state.id_taller, folder})
        if(data){
            commit('set_folders', [...state.folders, data])
        }
    },
    async Action_update_folder({ state, commit }, {id_folder, folder}){
        const data = await DocumentsDomain.UpdateFolderUseCase({id_taller: state.id_taller, folder, id_folder })
        if(data){
            commit('set_folders', state.folders.map(f => f.id == data.id ? data : f))
        }
    },
    async Action_delete_folder({ state, commit }, id_folder){
        const data = await DocumentsDomain.DeleteFolderUseCase({id_taller: state.id_taller, id_folder})
        if(data){
            commit('set_folders', state.folders.filter(f => f.id !== id_folder))
        }
    },
    async Action_get_files({ state, commit }, whenNoFolderFound){
        const data = await DocumentsDomain.GetFilesUseCase({id_taller: state.id_taller, id_folder: state.id_folder}, whenNoFolderFound)
        commit('set_files', data)
    },
    async Action_save_file({ state, commit }, file){
        const data = await DocumentsDomain.SaveFileUseCase({id_taller: state.id_taller, id_folder: state.id_folder, file})
        if(data) {
            commit('set_files', [...state.files, data])
            state.folders.forEach(f => {
                if(f.id == state.id_folder) f.cantidad_archivos++
            })
        }
    },
    async Action_update_file_name({ state, commit }, {id_file, nombre = null}){
        const data = await DocumentsDomain.UpdateFileNameUseCase({id_taller: state.id_taller, id_folder: state.id_folder, id_file, nombre})
        if(data) commit('set_files', state.files.map(f => f.id == data.id ? data : f))
    },
    async Action_remove_file({ state, commit }, id_file){
        const data = await DocumentsDomain.DeleteFileUseCase({id_taller: state.id_taller, id_folder: state.id_folder, id_file})
        if(data) {
            commit('set_files', state.files.filter(f => f.id !== id_file))
            state.folders.forEach(f => {
                if(f.id == state.id_folder) f.cantidad_archivos--
            })
        }
    }
}