import { Entity } from "~/domain/entities";

export class CheckList extends Entity {
    constructor(obj) {
        super({
        params: obj,
        keys: [
            "id",
            "id_configuracion_control_piso_lista_chequeo",
            "nombre",
            "actividades"
        ]});
    } 
}
