import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { ResponsiblesVOFactory } from '~/domain/value_objects/opportunity/factories/responsiblesVOFactory'
import { $try } from '~/services/global'

class GetSelectOpportunityResponsiblesUseCase extends UseCase {
    static async execute(payload){
        const { data } = await Selects.GetSelectOpportunityResponsibles(payload)
        return $try(() => {
            if(data && Array.isArray(data)) return data.map(type => ResponsiblesVOFactory.instantiate(type))
        })
    }
}

export default GetSelectOpportunityResponsiblesUseCase.execute