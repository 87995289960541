import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { CiclosFactory } from '~/domain/entities/oportunidades/information/factories/CiclosFactory'
import { $try } from '~/services/global'

class GetOpportunityCiclosUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getCiclosOpportunity(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(type => CiclosFactory.instantiate(type))
        })
    }
}

export default GetOpportunityCiclosUseCase.execute