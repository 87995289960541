import { Entity } from '~/domain/entities'

export class Materiales extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'ref',
                'nombre',
                'tipo',
                'unidad_medida',
                'unidades',
                'plan_mantenimiento'
            ]
        });
    }
}