import { UseCase } from '~/domain/core/UseCase';
import { Documents } from '~/services/talleres/ver_documentos';
import { FolderFactory } from '~/domain/entities/talleres/factories/FolderFactory';
import { $try } from '~/services/global';

class SaveFolderUseCase extends UseCase {
    static async execute({id_taller, folder}) {
        const data = await Documents.saveFolder(id_taller, folder);
        return $try(() => {
            if(data?.data) {
                return FolderFactory.instantiate(data.data)
            }
        })
    }
}

export default SaveFolderUseCase.execute;