import { Entity } from '~/domain/entities'

export class opcionCalificacion extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['id','opcion','valor','id_configuracion_control_piso_aspectos_calificar']
        });
    }
}
