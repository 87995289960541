import { UseCase } from "~/domain/core/UseCase"
import MenuList from "~/services/configurar/permisos/menus"

class GetHeadersUseCase extends UseCase {
    static async execute(params){
        const data = await MenuList.getHeaders(params)
        if(data?.data){
            return data.data;
        }
    }
} 
  
export default GetHeadersUseCase.execute

