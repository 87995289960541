import { Filter } from '~/domain/core/Filter';

export class BodegasFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                "buscar",
                'nombre',
                'valor_inicial',
                'valor_final',
                'fecha_inicial_ultima_salida',
                'fecha_final_ultima_salida',
                'fecha_inicial_ultima_entrada',
                'fecha_final_ultima_entrada',
                'pais',
                'estado',
                'tipo_material',
                'cantidad_inicial',
                'cantidad_final',
            ]
        });
    }
}