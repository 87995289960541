import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'
import { OpcionCalificacionFactory } from '~/domain/entities/configurar/obras/calificacion_servicio/factories/opcionCalificacionFactory'
import { $try } from '~/services/global'

class CreateOptionQuestionsUseCase extends UseCase {
    static async execute(params){
        const data = await CalificacionService.addOptionQuestion(params)
        return data?.data
    }
}

export default CreateOptionQuestionsUseCase.execute