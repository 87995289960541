import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { ConfiguracionSeccionVOFactory } from '~/domain/value_objects/servicios/factories/configuracionSeccionVOFactory'
import { $try } from '~/services/global'

class GetSelectConfiguracionSeccionUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectConfiguracionesSecciones(payload)
        return $try(() => {
            if(data?.data) return data.data.map(el => ConfiguracionSeccionVOFactory.instantiate(el))
            return []
        })
    }
}

export default GetSelectConfiguracionSeccionUseCase.execute;