import { Entity } from "~/domain/entities";

export class Adjunto extends Entity {
    constructor(obj) {
        super({
        params: obj,
        keys: [
            "id",
            "id_tipo",
            "tipo",
            "archivo",
            "id_control_piso_actividades"
        ]});
    } 
}
