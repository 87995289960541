export default [
	{
        path: '/ventas',
        name: 'ventas.main',
        meta: {title: 'Ventas'},
        redirect: { name: 'ventas.presencia-equipos'},
        component: () => import('../../pages/ventas/index').then(m => m.default || m),
        children: [
            {
                path: 'presencia-equipos',
                name: 'ventas.presencia-equipos',
                component: () => import('../../pages/ventas/presenciaEquipos').then(m => m.default || m),
            }
        ],
    },
    {
        path: '/ventas/presencia-de-equipos/detalle/:id',
        name: 'ventas.presencia-equipos.detalle',
        component: () => import('../../pages/ventas/verEquipo').then(m => m.default || m),
    },
    {
        path: '/ventas/presencia-de-equipos/detalle/:id/configuracion/:id_configuracion',
        name: 'ventas.presencia-equipos.detalle.configuracion',
        component: () => import('../../pages/ventas/verConfiguracion').then(m => m.default || m),
    }
]