import { UseCase } from "~/domain/core/UseCase";
import { Cotizaciones } from '~/services/cotizaciones/detalle_cotizaciones/detalle_cotizaciones'
import { $try } from '~/services/global'

class GetInfoDetalleCotizacionUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await Cotizaciones.getInfoDetalleCotizacion(payload)
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default GetInfoDetalleCotizacionUseCase.execute