import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { StatesVOFactory } from '~/domain/value_objects/bodegas/factories/statesVOFactory'
import { $try } from '~/services/global'

class GetSelectStatesUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectStateSearch(payload)
        return $try(() => {
            if(data?.data) {
                return data.data.map(e => StatesVOFactory.instantiate(e))
            }
        })
    }
}

export default GetSelectStatesUseCase.execute;