import { UseCase } from "~/domain/core/UseCase"
import { Maintenance } from '~/services/almacen/mantenimiento'
import { $try } from '~/services/global'

class DeleteMaintenancePlanUseCase extends UseCase {
    static async execute(params){
        const data = await Maintenance.deleteMaintenancePlan(params)
        return $try(() => {
            return data?.message
        })
    }
}

export default DeleteMaintenancePlanUseCase.execute