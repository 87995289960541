import * as UseCases from "~/domain/use_cases/oportunidades/cotizaciones"

import { $try } from '~/services/global'

export const state = {
    cotizacionesOps: {},
}

export const getters = {
    cotizacionesOps: state => state.cotizacionesOps,
}

export const mutations = {
    set_cotizaciones_ops(state, payload) {
        state.cotizacionesOps = payload
    },
}

export const actions = {
    async Action_get_cotizaciones_oportunidad({ commit }, params) {
        const data = await UseCases.GetCotizacionesOportunidadUseCase(params)
        $try(
            () => commit('set_cotizaciones_ops', data)
        )
    },



}

