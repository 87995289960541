import { UseCase } from '~/domain/core/UseCase';
import { WorkshopView } from '~/services/talleres/ver'
import { ServiceFactory } from '~/domain/entities/talleres/factories/ServiceFactory'
import { $try } from '~/services/global'

class GetWorkshopHistoryServicesUseCase extends UseCase {
    static async execute(id_taller, filter) {
        const data = await WorkshopView.getWorkshopHistoryServices(id_taller, filter);
        return $try(() => {
            if (!Array.isArray(data?.data)) return [];
            return data.data.map(ServiceFactory.instantiate);
        })
    }
}

export default GetWorkshopHistoryServicesUseCase.execute;