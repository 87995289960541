export default [
    {
        path: '/compras/matriz',
        name: 'compras.matrix',
        meta: {title: 'Compras - matriz'},
        component: () => import('../../pages/compras/matriz').then(m => m.default || m),
    },
    {
        path: '/compras/:id',
        name: 'compras.main',
        meta: {title: 'Compras'},
        redirect: {name: 'compras.ordenes'},
        component: () => import('../../pages/compras').then(m => m.default || m),
        children: [
            {
                path: 'ordenes_de_compra/',
                name: 'compras.ordenes',
                meta: {title: 'Compras - Orden de compra'},
                redirect: {name: 'compras.ordenes.listado'},
                component: () => import('../../pages/compras/ordenCompra').then(m => m.default || m),
                children: [
                    {
                        path: 'listado',
                        name: 'compras.ordenes.listado',
                        meta: {title: 'Compras - Orden de compra - listado'},
                        component: () => import('../../pages/compras/listadoComprasOrdenes').then(m => m.default || m),
                    },
                    {
                        path: 'semanas',
                        name: 'compras.ordenes.semanas',
                        meta: {title: 'Compras - Orden de compra -semanas'},
                        component: () => import('../../pages/compras/semanas').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'facturas',
                name: 'orden.compra.facturas',
                meta: {title: 'Compras - Facturas'},
                redirect: {name: 'orden.compra.facturas.listado'},
                component: () => import('../../pages/compras/facturas').then(m => m.default || m),
                children: [
                    {
                        path: 'listado',
                        name: 'orden.compra.facturas.listado',
                        meta: {title: 'Compras - Facturas'},
                        component: () => import('../../pages/compras/facturas/listado').then(m => m.default || m),
                    },
                    {
                        path: ':id_factura',
                        name: 'orden.compra.facturas.ver',
                        meta: {title: 'Compras - Factura'},
                        component: () => import('../../pages/compras/facturas/verFactura').then(m => m.default || m),
                    },
                ]
            },
            
        ]
    },
    {
        path: '/compras/:id/orden_de_compra/:id_orden',
        name: 'compras.ver.orden',
        meta: {title: 'Compras - Orden de compra'},
        component: () => import('../../pages/compras/verOrdenSection').then(m => m.default || m),
    },
    {
        path: '/compras/seleccionar/etapa-configuracion',
        name: 'compras.crear.matriz.etapa.configurar',
        meta: {title: 'Compras - Crear matriz'},
        component: () => import('../../pages/compras/selectEtapasConfiguraciones').then(m => m.default || m),
    },
    {
        path: '/compras/1/seleccionar/proveedores',
        name: 'compras.crear.matriz.proveedores',
        meta: {title: 'Compras - Crear matriz'},
        component: () => import('../../pages/compras/selectProveedores').then(m => m.default || m),
    },
    {
        path: '/compras/1/seleccionar/proveedores/:id/bodegas',
        name: 'compras.crear.matriz.bodegas',
        meta: {title: 'Compras - Crear matriz'},
        component: () => import('../../pages/compras/selectBodegas').then(m => m.default || m),
    },
]
