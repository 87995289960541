import { Salida } from "../Salida";

export class SalidaFactory {
    static instantiate(obj){
        return new Salida(obj)
    }

    static instantiateEmpty(){
        return new Salida({
            mes: '',
			mes_abre: '',
			anio: '',
			cantidad: ''
        })
    }
}