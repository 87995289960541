import { AdditionalDataPosicion } from "../AdditionalDataPosicion";

export class AdditionalDataPosicionFactory {
    static instantiate(obj){
        return new AdditionalDataPosicion(obj)
    }

    static instantiateEmpty(){
        return new AdditionalDataPosicion({
            id_configuracion_datos_adicionales: '',
            posicion: '',
        })
    }
}