import { Provider } from "../Provider";

export class ProviderFactory {
    static instantiate(obj){
        return new Provider(obj)
    }

    static instantiateEmpty(){
        return new Provider({
            id: '-',
            nombre: '-',
            tipos_materiales: ["-"],
            responsable: '-',
            ciudad: '-',
            telefono: '-',
            direccion: '-',
            correo: '-',
            descripcio: '-',
        })
    }
}