<template>
    <div class="card-obra-vigente border br-8 pt-2 cr-pointer bg-f9">
        <div class="d-flex mx-2 py-2">
            <img src="/img/pruebas/logoOp.png" alt="" class="wh-60 rounded-circle">
            <div class="ml-3 pr-3">
                <p class="f-14 f-600 tres-puntos"> {{ name }} </p>
                <p class="f-12 mb-1"> {{ nameProject }} </p>
                <div class="row mt-3">
                    <div class="col-auto">
                        <p class="d-middle f-12">
                            <i class="icon-history f-20" />
                            {{ date }}
                        </p>
                    </div>
                    <div class="col-auto">
                        <p class="d-middle f-12">
                            <i class="icon-robot-industrial f-20" />
                            {{ cantidad }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-db w-100 mt-1" style="height: 4px;">
            <div class="bg-general" :style="`width: ${percentage || 0}%; height: 4px;`" />
        </div>
        <div class="bg-porcentaje py-2 row mx-0">
            <div class="col-6 my-auto">
                <p class="text-general f-18 f-700"> {{ percentage || 0}}%</p>
            </div>
            <div class="col-6 text-right my-auto">
                <p>{{ formatoMoneda(totalValue) }} </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        name:{
            type: String,
        },
        nameProject:{
            type: String,
        },
        type:{
            type: String
        },
        date:{
            type: String
        },
        cantidad:{
            type: Number
        },
        percentage:{
            type: Number
        },
        totalValue:{
            type: Number
        },

    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
.card-obra-vigente{
    min-width: 437px;
    max-width: 439px;
    .icon-history{
        &::before{
            margin-left: 0px;
        }
    }
    .bg-porcentaje{
        background: #F1F2F4;
    }
}
</style>