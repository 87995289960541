import { UseCase } from "~/domain/core/UseCase"
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'

class DeleteCotizacionUseCase extends UseCase {
    static async execute(payload){
        const data = await CotizacionCliente.deleteCotizacion(payload)
        return data?.message
    }
}

export default DeleteCotizacionUseCase.execute