import { Entity } from '~/domain/entities'

export class Provider extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'nombre',
                'imagen',
                'tipos_materiales',
                'responsable',
                'ciudad',
                'telefono',
                'direccion',
                'correo',
                'descripcion'
            ]
        });
    }
}