import { UseCase } from "~/domain/core/UseCase";
import { Documents } from "~/services/talleres/ver_documentos";
import { FileFactory } from "~/domain/entities/talleres/factories/FileFactory";
import { $try } from "~/services/global";

class GetFilesUseCase extends UseCase {
    static async execute({ id_taller, id_folder }, callback) {
        const data = await Documents.getFiles(id_taller, id_folder, callback);
        return $try(() => {
            if(!Array.isArray(data?.data)) return []
        
            return data.data.map(FileFactory.instantiate)
        })
    }
}

export default GetFilesUseCase.execute;