import Api from '~/services/api'

export const Funcionariosroveedores = {
    postUsuarioProveedor({payload, idProveedor}){
        const Provider = new Api()
        return Provider.post(`/proveedores/${idProveedor}/funcionarios`, payload)
    },
    getUsuarioProveedor({idProveedor, page}){
        const Provider = new Api()
        return Provider.get(`/proveedores/${idProveedor}/funcionarios`, {page})
    },
    putUsuarioProveedor({payload, idProveedor, idFuncionario}){
        const Provider = new Api()
        return Provider.put(`/proveedores/${idProveedor}/funcionarios/${idFuncionario}`, payload)
    },
    deleteUsuarioProveedor({idProveedor, idFuncionario}){
        const Provider = new Api()
        return Provider.delete(`/proveedores/${idProveedor}/funcionarios/${idFuncionario}`)
    },

}