import { UseCase } from "~/domain/core/UseCase"
import { Pedidos } from '~/services/almacen/pedidos'
import { PedidoInternoFiltrosFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoFiltrosFactory'
import { $try } from "~/services/global"

class GetFiltrosPedidoInternoUseCase extends UseCase{
    static async execute(){
        const data =  await Pedidos.getFiltrosPedidosInternos()
        return $try(() => {
            return data.data ? PedidoInternoFiltrosFactory.instantiate(data.data): null 
        })
    } 
}

export default GetFiltrosPedidoInternoUseCase.execute