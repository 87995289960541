import { Entity } from '~/domain/entities'

export class CorreosProveedores extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id',
                'correo',
                'created_by',
                'updated_by',
                'created_at',
                'updated_at',
                'user_creo',
                'user_actualizo',
                'proveedores_correos',
            ]
        })
    }
}