import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { EquiposModelosVOFactory } from '~/domain/value_objects/opportunity/factories/equiposModelosVOFactory'
import { $try } from '~/services/global'

class GetSelectOppotunityEquiposModelosUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectOpportunityEquiposModelos(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(modelo => EquiposModelosVOFactory.instantiate(modelo))
        })
    }
}

export default GetSelectOppotunityEquiposModelosUseCase.execute