import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { OppotunityInformationFactory } from '~/domain/entities/oportunidades/information/factories/OppotunityInformationFactory'
import { $try } from '~/services/global'

class EditOpportunityInformationUseCase extends UseCase {
    static async execute(params){
        const data =  await Opportunities.editOpportunityInformation(params)
        return $try( () => data?.data ? OppotunityInformationFactory.instantiate(data.data) : [])
    }
}

export default EditOpportunityInformationUseCase.execute