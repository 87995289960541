import { UseCase } from "~/domain/core/UseCase"
import Materials from "~/services/almacen/materiales"

class DeleteMaterialUseCase extends UseCase {
    static async execute(id){
        const data = await Materials.deleteMaterial(id)
        return data?.message
    }
}

export default DeleteMaterialUseCase.execute