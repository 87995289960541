import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'

class GetSelectUsersInternosUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectUsersInternos()
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default GetSelectUsersInternosUseCase.execute;