import { Entity } from '~/domain/entities'

export class FiltroCompra extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
				'proyectos',
				'proveedores',
				'clientes',
				'unidades_min',
				'unidades_max',
				'valor_min',
				'valor_max',
				'estados'
            ]
        })
    }
}