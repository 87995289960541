import { Entity } from '~/domain/entities'

export class CotizacionesVencidas extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'referencia',
                'dias_credito',
                'estado',
                'id_proveedor',
                'proveedores',
                'created_at',
                'fecha_cotizacion',
                'fecha_vencimiento',
                'id_oportunidad',
                'cantidad_materiales',
                'valor_total',
            ]
        });
    }
}