import * as WorkshopViewDomain from '~/domain/use_cases/talleres/ver'
import { ServiceFilterFactory } from '~/domain/filters/talleres/factories/ServiceFilterFactory'

export const state = {
    workshop: {},
    services_graph: [],
    active_services: [],
    history_services: [],
    select_service_types: [],
    filter: ServiceFilterFactory.instantiateEmpty(),
    query: '',
    range: {},
}

export const getters = {
    workshop: state => state.workshop,
    services_graph: state => state.services_graph,
    active_services: state => state.active_services,
    history_services: state => state.history_services,
    select_service_types: state => state.select_service_types,
    filter: state => state.filter,
    range: state => state.range,
    query: state => state.query,
}

export const mutations = {
    set_workshop(state, payload){
        state.workshop = payload
    },
    set_services_graph(state, payload){
        state.services_graph = payload
    },
    set_active_services(state, payload){
        state.active_services = payload
    },
    set_history_services(state, payload){
        state.history_services = payload
    },
    set_select_service_types(state, payload){
        state.select_service_types = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_range(state, payload){
        state.range = payload
    },
    set_query(state, payload){
        state.query = payload
    }
}

export const actions = {
    async Action_get_workshop_info({ state, commit }, id_taller){
        const data = await WorkshopViewDomain.GetWorkshopInfoUseCase(id_taller)
        if(data) commit('set_workshop', data)
    },
    async Action_get_workshop_services({ state, commit }, id_taller){
        const data = await WorkshopViewDomain.GetWorkshopServicesUseCase(id_taller)
        if(data) commit('set_services_graph', data)
    },
    async Action_get_workshop_active_services({ state, commit }, id_taller){
        const data = await WorkshopViewDomain.GetWorkshopActiveServicesUseCase(id_taller)
        if(data) commit('set_active_services', data)
    },
    async Action_get_workshop_service_types_and_range({ state, commit }, id_taller){
        const data = await WorkshopViewDomain.GetWorkshopServiceTypesAndRangeUseCase(id_taller)
        if(data?.service_types) commit('set_select_service_types', data.service_types)
        if(data?.range) commit('set_range', data.range)
    },
    async Action_get_workshop_history_services({ state, commit }, id_taller){
        const filter = state.filter.clean()
        const buscar = state.query || null
        const data = await WorkshopViewDomain.GetWorkshopHistoryServicesUseCase(id_taller, {...filter, buscar: buscar})
        if(data) commit('set_history_services', data)
    },
    clear_filter({ state, commit }){
        commit('set_filter', ServiceFilterFactory.instantiateEmptyWithRange(state.range))
    }
}