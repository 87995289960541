import { Entity } from '~/domain/entities'

export class RangoStockMateriales extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
				'valor_min',
				'valor_max'
            ]
        })
    }
}