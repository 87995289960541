import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'

class DeleteQuestionUseCase extends UseCase {
    static async execute(id){
        const data = await CalificacionService.deleteQuestion(id)
        return data?.message 
    }
}

export default DeleteQuestionUseCase.execute