import { UseCase } from "~/domain/core/UseCase";
import { Documentacion } from "~/services/obras/servicios/documetacion";
import { DocumentacionFactory } from "~/domain/entities/obras/servicios/documentacion/factories/DocumentacionFactory";
import { $try } from "~/services/global";

class GetDocumentacionCarpeta extends UseCase {
  static async execute(params) {
    const data = await Documentacion.getDocumentosCarpetas(params);
    return $try(() => {
      let newData = data.data;
      if (newData && Array.isArray(newData)) {
        return newData.map((type) => DocumentacionFactory.instantiate(type));
      }
    });
  }
}

export default GetDocumentacionCarpeta.execute;
