import { UseCase } from "~/domain/core/UseCase";
import { Documentacion } from "~/services/obras/servicios/documetacion";
import { DocumentacionFactory } from "~/domain/entities/obras/servicios/documentacion/factories/DocumentacionFactory";
import { $try } from "~/services/global";

class CreateDocumentoCarpetaUseCase extends UseCase {
  static async execute(params) {
    const data = await Documentacion.createDocumentosCarpetas(params)
    return $try(() => {
      if (data?.data) return new DocumentacionFactory.instantiate(data.data)
    })
  }
}

export default CreateDocumentoCarpetaUseCase.execute
