import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { MatrizListFactory } from '~/domain/entities/compras/matriz/factories/MatrizListFactory'
import { $try } from '~/services/global'

class GetBuyOrdersGroupUseCase extends UseCase {
    static async execute(params){
        let customData = {}
        const { data } =  await Matriz.getGroups(params)
        const dataEntries = Object.entries(data)


        $try(() => {

            if(dataEntries.length){
                dataEntries.forEach((member) =>{
                    customData[member[0]] = [
                        ...member[1].map(subMember => MatrizListFactory.instantiate(subMember)) 
                    ]
                })
            }

        })

        return customData
    }
}

export default GetBuyOrdersGroupUseCase.execute