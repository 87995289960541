import { Entity } from '~/domain/entities'

export class CotizacionCliente extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'nombre', 
                'porcentaje_utilidad_pais',
                'porcentaje_utilidad', 
                'id_configuracion_pais_utilidad', 
                'configuracion_pais_utilidad', 
                'cantidad_equipos', 
                'cantidad_etapas',
                'cantidad_configuraciones',
                'created_by',
                'user',
                'valor',
                'created_at'
            ]
        })
    }
}