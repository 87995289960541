import { UseCase } from "~/domain/core/UseCase"
import { Calendario } from "~/services/calendario/calendario"
import { CalendarioMesFactory } from "~/domain/entities/calendario/factories/CalendarioMesFactory"
import { $try } from "~/services/global"

class GetCalendarioMesUseCase extends UseCase{
    static async execute(payload){
        const data = await Calendario.getCalendarioMes(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => CalendarioMesFactory.instantiate(e))
        })
    }
}

export default GetCalendarioMesUseCase.execute