import { Filter } from '~/domain/core/Filter'

export class WorkshopFilter extends Filter {
    constructor(obj){
        super({
            params: obj,
            keys: [ 
                'nombre_taller',
                'direccion',
                'telefono',
                'responsables',
                'servicios_min',
                'servicios_max' 
            ]
        })
    }
}