import { Filter } from '~/domain/core/Filter';

export class PagoFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_tipo',
                'id_proveedor',
                'id_estado',
                'fecha_orden',
                'fecha_vencimiento',
                'valor_min',
                'valor_max',
                'dias_vencimiento_min',
                'dias_vencimiento_max'
            ]
        });
    }
}