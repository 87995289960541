import { UseCase } from "~/domain/core/UseCase";
import { Selects } from "~/services/selects/selects";
import { $try } from '~/services/global'

class GetActividadesPredecesorasTalleresUseCase extends UseCase {
    static async execute(payload) {
        const data = await Selects.GetSelectActividadPredecesoraTalleres(payload)
        return $try(() => data?.data ?? [] )
    }
}

export default GetActividadesPredecesorasTalleresUseCase.execute