<template>
    <modal ref="modalAddImg" titulo="Añadir imágen" tamano="modal-lg" adicional="Añadir" @adicional="agregarImagen">
        <div class="row justify-content-center mx-3 f-15">
            <div class="col-8 my-5">
                <!-- <slim-cropper ref="fotoReclamo1" :options="slimOptions" class="border cr-pointer" style="width: 458px; height: 256px;background-color:#F5F5F5;border-radius:6px;" /> -->
                 <clean-cropper ref="cleanCropper" v-model="image" :image="imagen" /> 
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Añada la imágen aquí',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            image: null,
            imagen: null,
            editing: false
            //uploadFile: 'files/files/Action_save_file_to_aws',
        }
    },
    methods: {
        toggle(data){
            if (data) {
                this.editing = !this.editing
                this.imagen = data.archivo
            }
            this.$refs.modalAddImg.toggle()
        },
        agregarImagen(){
            if (this.editing) {
                this.$emit("editImage",this.image)
                this.$refs.cleanCropper.reset();
                this.$refs.modalAddImg.toggle()
            } else {
                this.$emit("addImage",this.image)
                this.$refs.cleanCropper.reset();
                this.$refs.modalAddImg.toggle()
            }
        }
    }
}
</script>