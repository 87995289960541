import { Filter } from '~/domain/core/Filter';

export class CotizacionRealizadaFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'referencia',
                'valor_minimo',
                'valor_maximo',
                'fecha_cotizacion_inicio',
                'fecha_cotizacion_fin',
            ]
        });
    }
}