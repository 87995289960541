import { UseCase } from "~/domain/core/UseCase";
import { Funcionariosroveedores } from '~/services/proveedores/funcionariosProveedores'
import { $try } from '~/services/global'

class PutFuncionariosProveedoresUseCase extends UseCase {
    static async execute({payload, idProveedor, idFuncionario}){
        const data = await Funcionariosroveedores.putUsuarioProveedor({payload, idProveedor, idFuncionario})
        return $try(() => {
            return data.message
        })
    }
}

export default PutFuncionariosProveedoresUseCase.execute