import { PurchaseOrder } from '../PurchaseOrder';

export class PurchaseOrderFactory {
    static instantiate(obj) {
        return new PurchaseOrder(obj);
    }

    static instantiateEmpty() {
        return new PurchaseOrder({
            id: '',
            estado: null,
            created_at: '',
            valor: '',
            id_taller: null,
            proveedor: '',
            destino: '',
            servicio: '',
            forma_pago: '',
            valor_pagado: '',
            descripcion: '',
            materiales: [],
        });
        
    }
}