import { UseCase } from "~/domain/core/UseCase";
import { Services } from '~/services/obras/servicios/servicios'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { ServicesFactory } from '~/domain/entities/obras/servicios/factories/servicesFactory'
import { $try } from '~/services/global'

class GetServicesUseCase extends UseCase {
    static async execute(payload){
        const data =  await Services.getServiciosTalleres(payload)
        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    ServicesFactory.instantiate
                );
            }
        })
    }
}

export default GetServicesUseCase.execute