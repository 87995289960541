import { OrdenesCompras } from "../OrdenesCompras";

export class OrdenesCompraFactory {
    static instantiate(obj){
        return new OrdenesCompras(obj)
    }

    static instantiateEmpty(){
        return new OrdenesCompras({
            id: '',
            estado: '',
            id_estado: '',
            proveedor: '',
            numero_materiales: '',
            cliente: '',
            valor: ''
        })
    }
}
