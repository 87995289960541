import { ProviderPurchaseOrderFilter } from "../ProviderPurchaseOrderFilter";

export class ProviderPurchaseOrderFilterFactory {
    static instantiate(obj){
        return new ProviderPurchaseOrderFilter(obj);
    }
    
    static instantiateEmpty(){
        return new ProviderPurchaseOrderFilter({
            estado: '',
            id_cliente: '',
            id_oportunidad: '',
            valor_inicial: 0,
            valor_final: 0,
        })
    }
}