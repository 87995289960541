import Api from '~/services/api'

export const OrdenesCompras = {
    getListarOrdenesCompras(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload.id_proyecto}/orden-compra`, payload)
    },
    getOrdenCompra(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload.id_proyecto}/orden-compra/${payload.idOrdenesCompras}`, payload)
    },
    getInventarioMaterial(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ordenes-compras/configuraciones/inventario-almacen-materiales/${payload}`)
    },
    getValorMaterialPvs(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`ordenes-compras/configuraciones/valor-almacen-materiales`, payload)
    },
}