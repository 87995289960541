import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { BillFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/BillFactory';
import { $try } from '~/services/global';


class GetPurchaseOrderBillsUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso, id_orden_compra }) {
        const data = await PurchaseOrder.getPurchaseOrderBills(id_proyecto, id_control_piso, id_orden_compra);
        return $try(() => {
            if (!Array.isArray(data?.data)) return [];
            return data.data.map(BillFactory.instantiate);
        });
    }
}

export default GetPurchaseOrderBillsUseCase.execute;