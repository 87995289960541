import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class GetStageConfUseCase extends UseCase {
    static async execute(params){
        const { id } = params
        const data = await Stages.getStageConfiguration({id})
        console.log("🚀 ~ file: getStageConfUseCase.js:9 ~ GetStageConfUseCase ~ execute ~ data", data)
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetStageConfUseCase.execute