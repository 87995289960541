import { UseCase } from "~/domain/core/UseCase";
import { Services } from '~/services/obras/servicios/servicios'
import { $try } from '~/services/global'

class GetItemsCalificar extends UseCase {
    static async execute(payload) {
        const data = await Services.obtenerAspectosCalificar(payload)
        return $try(() => {
            return data?.data
        })
    }
}

export default GetItemsCalificar.execute