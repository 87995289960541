import { Entity } from '~/domain/entities'

export class DetailQuote extends Entity {
	constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'tipo',
                'nombre',
                'total_unidades',
                'proveedores'
            ]
        });
    }
}