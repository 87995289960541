import { UseCase } from '~/domain/core/UseCase';
import { Selects } from '~/services/selects/selects'
import { MonedasVOFactoy } from '../../value_objects/moneda/factories/MonedasVOFactory';
import { $try } from '~/services/global'

class GetSelectMonedasUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectMonedas()
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => MonedasVOFactoy.instantiate(e))
        })
    }
}

export default GetSelectMonedasUseCase.execute