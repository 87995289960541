import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { OpportunityConfigurationFactory } from '~/domain/entities/oportunidades/productos/factories/OpportunityConfigurationFactory'
import { $try } from '~/services/global'

class GetOpportunityConfigurationsUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data } =  await Opportunities.getOpportunityConfigurations(params)

        $try(
            () => {
                if(data?.length){
                    data.forEach(item => customData.push(OpportunityConfigurationFactory.instantiate(item)))
                }
            }
        )

        return customData
    }
}

export default GetOpportunityConfigurationsUseCase.execute