import { Entity } from '~/domain/entities'

export class RangeCantValVerMaterials extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'cantidad_minima',
                'cantidad_maxima',
                'valor_unidad_minimo',
                'valor_unidad_maximo',
            ]
        });
    }
}