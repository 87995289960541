import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { AdditionalDataTypeVOFactory } from '~/domain/value_objects/configurar/almacen/datos_adicionales/factories/AdditionalDataTypeVOFactory'
import { $try } from '~/services/global'

class GetAdditionalDataTypesUseCase extends UseCase {
    static async execute(params){
        const data =  await AdditionalData.getAdditionalDataTypes()

        if(data?.data && Array.isArray(data.data))
            return data.data.map((item) => AdditionalDataTypeVOFactory.instantiate(item))
    }
}

export default GetAdditionalDataTypesUseCase.execute