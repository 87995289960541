import * as PasosDomain from '~/domain/use_cases/almacen/mantenimiento/pasos'

import { $try } from '~/services/global'

export const state = {
    steps: [],
    eliminado: false
}

export const getters = {
    steps: state => state.steps,
    eliminado: state => state.eliminado
}

export const mutations = {
    set_steps(state, steps){
        state.steps = steps
    },
    set_eliminado(state, payload){
        state.eliminado = payload
    }
}

export const actions = {
    async Action_get_activities_steps({ commit }, payload) {
        const steps = await PasosDomain.GetMaintenanceActivityStepsUseCase(payload)
        $try(
            () => commit('set_steps', steps)
        )
    },
    async Action_create_activities_step({ commit }, payload) {
        await PasosDomain.CreateMaintenanceActivitiyStepUseCase(payload)
    },
    async Action_edit_activities_step({ commit }, payload) {
        await PasosDomain.EditMaintenanceActivitiyStepUseCase(payload)
    },
    async Action_delete_activities_step({ commit }, payload){
        await PasosDomain.DeleteMaintenanceActivityStep(payload)
    },
    async Action_upload_file_activities_step({ commit }, payload) {
        await PasosDomain.UploadFileMaintenanceActivityStepsUseCase(payload)
    },
    async Action_delete_file_activities_step({ commit }, payload){
        const data = await PasosDomain.DeleteFileMaintenanceActivityStepUseCase(payload)
        commit('set_eliminado', data ? true : false)
    },
    async Action_update_position_activities_step({ commit }, payload){
        await PasosDomain.SwitchStepsMaintenanceActivityStepsUseCase(payload)
    },
}
