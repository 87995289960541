import * as DatosAdicionalesDomain from '~/domain/use_cases/configurar/almacen/datos_adicionales'

import { $try } from '~/services/global'
// state
export const state = {
    datos_adicionales: [],
    datos_adicionales_posicion: [],
    datos_adicionales_tipos: [],
    datos_adicionales_tipos_posicion: [],
}

// getters
export const getters = {
    datos_adicionales: state => state.datos_adicionales,
    datos_adicionales_posicion: state => state.datos_adicionales_posicion,
    datos_adicionales_tipos: state => state.datos_adicionales_tipos,
    datos_adicionales_tipos_posicion: state => state.datos_adicionales_tipos_posicion,
}

// mutations
export const mutations = {
    set_datos_adicionales_tipos(state, datos_adicionales_tipos){
        state.datos_adicionales_tipos = datos_adicionales_tipos
    },
    set_datos_adicionales_tipos_posicion(state, payload){
        state.datos_adicionales_tipos_posicion = payload
    },
    set_datos_adicionales(state, datos_adicionales){
        state.datos_adicionales = datos_adicionales
    },
    set_datos_adicionales_posicion(state, payload){
        state.datos_adicionales_posicion = payload
    },
    create_datos_adicionales(state, datos_adicional){
        const clonedState = [...state.datos_adicionales]
        clonedState.push(datos_adicional)
        state.datos_adicionales = clonedState
    },
    create_datos_adicionales_tipos(state, datos_tipo){
        const clonedState = [...state.datos_adicionales_tipos]
        clonedState.push(datos_tipo)
        state.datos_adicionales_tipos = clonedState
    },
    edit_datos_adicionales(state, datos_adicionales){
        let clonedState = [...state.datos_adicionales]
        clonedState.forEach((item, index) => {
            if(item.id === datos_adicionales.id){
                clonedState[index] = datos_adicionales
            }
        })
        state.datos_adicionales = clonedState
    },
    edit_datos_adicionales_tipos(state, datos_tipo){
        let clonedState = [...state.datos_adicionales_tipos]
        clonedState.forEach((item, index) => {
            if(item.id === datos_tipo.id){
                clonedState[index] = datos_tipo
            }
        })
        state.datos_adicionales_tipos = clonedState
    },
    delete_datos_adicionales(state, id){
        let clonedState = [...state.datos_adicionales]
        clonedState = clonedState.filter(item => item.id !== id)
        state.datos_adicionales = clonedState
    },
    delete_datos_adicionales_tipos(state, id){
        let clonedState = [...state.datos_adicionales_tipos]
        clonedState = clonedState.filter(item => item.id !== id)
        state.datos_adicionales_tipos = clonedState
    },
}

// actions
export const actions = {
    async Action_get_datos_adicionales({commit}){
        const datos_adicionales = await DatosAdicionalesDomain.GetAdditionalDataUseCase()
        $try(
            () => commit('set_datos_adicionales', datos_adicionales)
        )
    },

    async Action_get_datos_adicionales_posicion({commit}, payload){
        const datos_adicionales_posicion = await DatosAdicionalesDomain.GetAdditionalDataPosicionUseCase(payload)
        $try(
            () => commit('set_datos_adicionales_posicion', datos_adicionales_posicion)
        )
    },

    async Action_get_datos_adicionales_tipos_posicion({commit}, payload){
        const datos_adicionales_tipos_posicion = await DatosAdicionalesDomain.GetAdditionalDataTypesPosicionUseCase(payload)
        $try(
            () => commit('set_datos_adicionales_tipos_posicion', datos_adicionales_tipos_posicion)
        )
    },

    async Action_get_datos_adicionales_tipos({commit}){
        const datos_adicionales_tipos = await DatosAdicionalesDomain.GetAdditionalDataTypesUseCase()
        $try(
            () => commit('set_datos_adicionales_tipos', datos_adicionales_tipos)
        )
    },

    async Action_get_datos_adicionales_tipos_unico({commit}, payload){
        const datos_adicionales_tipos = await DatosAdicionalesDomain.GetUniqueAdditionalDataTypeUseCase(payload)
        $try(
            () => commit('set_datos_adicionales_tipos', datos_adicionales_tipos)
        )
    },

    async Action_create_datos_adicionales({commit}, payload){
        const dato_adicional = await DatosAdicionalesDomain.CreateAdditionalDataUseCase(payload)
        $try(
            () => commit('create_datos_adicionales', dato_adicional)
        )
    },

    async Action_create_datos_adicionales_tipos({commit}, payload){
        const dato_tipo = await DatosAdicionalesDomain.CreateAdditionalDataTypeUseCase(payload)
        $try(
            () => commit('create_datos_adicionales_tipos', dato_tipo)
        )
    },

    async Action_delete_datos_adicionales({commit}, payload){
        await DatosAdicionalesDomain.DeleteAdditionalDataUseCase(payload)
        $try(
            () => commit('delete_datos_adicionales', payload.id)
        )
    },

    async Action_delete_datos_adicionales_tipos({commit}, payload){
        await DatosAdicionalesDomain.DeleteAdditionalDataTypeUseCase(payload)
        $try(
            () => commit('delete_datos_adicionales_tipos', payload.id)
        )
    },

    async Action_edit_datos_adicionales({commit}, payload){
        const dato_tipo = await DatosAdicionalesDomain.EditAdditionalDataUseCase(payload)
        $try(
            () => commit('edit_datos_adicionales', dato_tipo)
        )
    },

    async Action_edit_datos_adicionales_tipos({commit}, payload){
        const dato_tipo = await DatosAdicionalesDomain.EditAdditionalDataTypeUseCase(payload)
        $try(
            () => commit('delete_datos_adicionales_tipos', dato_tipo)
        )
    },
}