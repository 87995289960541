import { UseCase } from "~/domain/core/UseCase";
import { PurchaseOrder } from '~/services/compras/ordenes_compra'
import { OrdenesComprasListadoVOFactory } from '~/domain/value_objects/compras/ordenes_compra/factories/OrdenesComprasListadoVOFactory'
import { $try } from '~/services/global'

class GetPurchaseOrderListValuesUseCase extends UseCase {
    static async execute({id}){
        const data = await PurchaseOrder.getPurchaseOrderListValues({id})
        return $try(() => {
            if(data.data){
                return new OrdenesComprasListadoVOFactory.instantiate(data.data)
            } 
        });
    }
}

export default GetPurchaseOrderListValuesUseCase.execute