import { UseCase } from "~/domain/core/UseCase";
import { PresenciaEquipos } from '~/services/ventas/presenciaEquipos'
import { ListaEquiposFactory } from '~/domain/entities/ventas/factories/ListaEquiposFactory'
import { $try } from '~/services/global'

class GetEquiposUseCase extends UseCase {
    static async execute(){
        const data = await PresenciaEquipos.getEquipos()
        return $try(() => {
            if(!Array.isArray(data?.data)) return []
            return data.data.map(ListaEquiposFactory.instantiate)
        })
    }
}

export default GetEquiposUseCase.execute