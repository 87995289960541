import { PagoFilter } from "../PagoFilter"

export class PagoFilterFactory {
    static instantiate(obj){
        return new CompraFilter(obj)
    }

    static instantiateEmpty(){
        const filter = new PagoFilter({
            id_tipo: null,
            id_proveedor: null,
            id_estado: null,
            fecha_orden: null,
            fecha_vencimiento: null,
            valor_min: 0,
            valor_max: 0,
            dias_vencimiento_min: 0,
            dias_vencimiento_max: 0
        })
        filter.setExceptions({
            valor_min: 0,
            valor_max: 0,
            dias_vencimiento_min: 0,
            dias_vencimiento_max: 0
        })
        return filter
    }

    static instantiateEmptyWithRange(rangeValor,rangeDias){
        const valor_min = rangeValor?.min ?? 0;
        const valor_max = rangeValor?.max ?? 0;
        const dias_vencimiento_min = rangeDias?.min ?? 0;
        const dias_vencimiento_max = rangeDias?.max ?? 0;

        const filter = new PagoFilter({
            id_tipo: null,
            id_proveedor: null,
            id_estado: null,
            fecha_orden: null,
            fecha_vencimiento: null,
            valor_min,
            valor_max,
            dias_vencimiento_min,
            dias_vencimiento_max
        })
        filter.setExceptions({
            valor_min,
            valor_max,
            dias_vencimiento_min,
            dias_vencimiento_max
        })
        return filter
    }
}