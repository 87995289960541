import { UseCase } from "~/domain/core/UseCase";
import { Pedidos } from '~/services/almacen/pedidos';
import { $try } from "~/services/global";

class DeletePedidoInternoUseCase extends UseCase {
    static async execute(params){
        const data = await Pedidos.deletePedidoInterno(params)
        
        return $try(() => data?.message)
    }
}

export default DeletePedidoInternoUseCase.execute;