
import { ValueObject } from '~/domain/core/ValueObject'

export class StateVO extends ValueObject{
    constructor(obj){
        super({
            params: obj, 
            keys: ['id', 'estado']
        });
    }
}