import * as StockDomain from '~/domain/use_cases/almacen/stock'
import { StockMaterialesFilterFactory } from '~/domain/filters/almacen/stock/factories/StockMaterialesFilterFactory'
import { $try } from '~/services/global'

// state
export const state = {
    stockMaterials: {
        data: [],
        pagination: {
            current_page: 1
        }
    },
    materialHistory: [],
    bodegas: [],
    query: '',
    rango_unidades: {},
    filter: StockMaterialesFilterFactory.instantiateEmpty(),
    cabeceraStock: {},

}

// getters
export const getters = {
    stockMaterials: state => state.stockMaterials.data,
    pagination: state => state.stockMaterials.pagination,
    materialHistory: state => state.materialHistory,
    bodegas: state => state.bodegas,
    query: state => state.query,
    rango_unidades: state => state.rango_unidades,
    filter: state => state.filter,
    cabeceraStock: state => state.cabeceraStock
}

// mutations
export const mutations = {
    set_stock_materials(state, payload){
        state.stockMaterials = payload
    },
    set_bodegas(state, payload){
        state.bodegas = payload
    },
    set_material_history(state, payload){
        state.materialHistory = payload
    },
    set_pagination(state, payload){
        state.stockMaterials.pagination = payload
    },
    set_query(state, payload){
        state.query = payload
    },
    set_rango_unidades(state, payload){
        state.rango_unidades = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    sync_list_stock_materials(state, data){
        let customStockMaterials  = [...state.stockMaterials.data]

        customStockMaterials.forEach((material, index) => {
            if(material.material.id == data.material.id){
                customStockMaterials[index].unidades_disponibles = data.unidades_disponibles
            }
        })

        const stockMaterialReplacement = {... state.stockMaterials.data, data: customStockMaterials}
        state.stockMaterials =  stockMaterialReplacement
    },
    set_cabecera_stock(state, payload){
        state.cabeceraStock = payload
    },
}

// actions
export const actions = {
    async Action_list_stock_materials({state, commit, getters}, tipo=0){
        const buscar = state.query
        const filter = state.filter.clean()
        const page = getters.pagination.current_page ?? 1
        let data = {}
        if (tipo == 1) data = await StockDomain.GetStockMaterialsUseCase({ buscar, page})
        else data = await StockDomain.GetStockMaterialsUseCase({ buscar, page,...filter})
        $try(() => {
            commit('set_stock_materials', data)
        })
    },
    async Action_list_bodegas({commit}){
        $try(async () => {
            const stockBodegas = await StockDomain.GetBodegasUseCase()
            commit('set_bodegas', stockBodegas)
        })
    },
    async Action_get_rango_unidades_materiales({commit}){
        const data = await StockDomain.GetRangoUnidadesMaterialesUseCase()
        $try(() => {
            commit('set_rango_unidades', data)
        })
    },
    Action_clear_filter({ state, commit }){
        const rango_unidades = {
            min: state.rango_unidades.valor_min,
            max: state.rango_unidades.valor_max
        }
        commit('set_filter', StockMaterialesFilterFactory.instantiateEmptyWithRange(rango_unidades))
    },
    async Action_in_out_operation_stock({commit}, payload){
        $try(async () => {
            const stockMaterials = await StockDomain.InOutOperationUseCase(payload)
            commit('sync_list_stock_materials', stockMaterials)
        })
    },
    async Action_get_material_history({commit}, payload){
        $try(async () => {
            const materialHistory = await StockDomain.GetMaterialHistoryUseCase(payload)
            commit('set_material_history', materialHistory)
        })
    },
    async Action_get_cabecera_stock({commit}, payload){
        $try(async () => {
            const data = await StockDomain.GetCabeceraStockUseCase(payload)
            commit('set_cabecera_stock', data)
        })
    }

}