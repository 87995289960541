<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <template v-for="(breadcumb, index) in breadcumbs">
            <el-breadcrumb-item :key="index">
                <strong class="text-general cr-pointer" @click="redirect(breadcumb)">
                    {{limit(breadcumb.name, 25)}}
                </strong>
            </el-breadcrumb-item>
        </template>
    </el-breadcrumb>
</template>
<script>
import { mapGetters } from 'vuex'
import { limitText } from '~/domain/helpers/string'
const FS = process.env.VUE_APP_URL_FULL_SMART
export default {
    computed:{
        ...mapGetters({
            breadcumbs: 'navigation/breadcumbs/breadcumbs'
        }),
        limit: () => limitText
    },
    methods:{
        redirect(breadcumb){
            if(breadcumb.fs) window.open(`${FS}/proyectos/proceso/mosaico`, "_self")
            this.navigateTo({ name: breadcumb.route, params: breadcumb.params ?? false })
        }
    }
}
</script>