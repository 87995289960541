import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class CreateConfMaterialUseCase extends UseCase {
    static async execute(params){
        const { id_etapa, id_configuration, ...payload } = params
        const data = await Stages.createConfigurationMaterial({id_etapa, id_configuration, payload})
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default CreateConfMaterialUseCase.execute