import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { AdditionalDataFactory } from '~/domain/entities/configurar/almacen/datos_adicionales/factories/AdditionalDataFactory'
import { $try } from '~/services/global'

class EditAdditionalDataUseCase extends UseCase {
    static async execute(params){
        const { data } =  await AdditionalData.editAditionalData(params)

        return $try(
            () => AdditionalDataFactory.instantiate(data)
        )
    }
}

export default EditAdditionalDataUseCase.execute