export const state = {
    percentage: 0,
}

export const getters = {
    percentage: state => state.percentage
}

export const mutations = {
    SET_PERCENTAGE (state, payload){
        state.percentage = payload
    }
}