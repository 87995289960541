import { UseCase } from "~/domain/core/UseCase";
import { ListaChequeo } from '~/services/obras/servicios/lista_chequeo'

class DeleteListaChequeoUseCase extends UseCase {
    static async execute(payload){
        const data =  await ListaChequeo.deleteCheckListies(payload)
        return data?.message
    }
}

export default DeleteListaChequeoUseCase.execute