import { notify } from '~/services/helper';
import { $try, navigateTo, base64ToFile } from '~/services/global';
import { mapGetters } from 'vuex';
import { Notification } from 'element-ui';

export default {
    computed: {
        ...mapGetters({
            user: 'auth/user',
            monedaGlobal: 'formato_moneda/formato_moneda',
            permision: 'personalizacion/permision'
        })
    },
    methods:{
        /**
         * @param  {string} url @description image as (base64, URI) that we want to convert 
         * @param  {string} filename @description name of file including its extension
         * @param  {string} mimeType='image/png' @description mimetype
         */
        async base64ToFile(url, filename, mimeType){
            return base64ToFile(url, filename, mimeType);
        },
        /**
         * @param  {object} res @description request response or error catched that we want to notify to the user
         */
        $notification(res){
            notify(res);
        },
        /**
         * @param  {function} callback @description function passed to evaluate if there is any error
         * @param  {boolean} hasReturn @description if the callback return something
         */
        $try(callback, errorCallback = () =>{} ){
            return $try(callback, errorCallback);
        },
        /**
         * @param  {string} route @description route to redirect
         * @param  {context} context @description context this vue
         */
        navigateTo(route){
            navigateTo(route, this);
        },
        formatoMoneda(valor){
            const { decimales_valor, moneda, separador_decimales } = this.monedaGlobal ?? {}

            const DEFAULT_CURRENCY = 'USD'
            const DEFAULT_SYMBOL_CURRENCY = '$'
            const DEFAULT_DECIMAL = '.'
			const DEFAULT_DECIMAL_VALUE = 0

            const decimals = {
                1: '.',
                2: ','
            }

            const monedas = {
                'Dólar': '$',
            }

            const sigla = moneda?.sigla ?? DEFAULT_CURRENCY
            const symbol = monedas[moneda?.nombre] ?? DEFAULT_SYMBOL_CURRENCY
            const decimalSeparator = decimals[separador_decimales] ?? DEFAULT_DECIMAL
			const decimalesValor = decimales_valor ?? DEFAULT_DECIMAL_VALUE

            return this.$options.filters['currency'](valor, `${sigla} ${symbol}`, decimalesValor, { thousandsSeparator: '.', decimalSeparator });
        },
        /**
         * @param  {string} route @description Permision to action
         */
        permitAction(permision){  
            // this.user.super_admin = 0;
            return this.user.super_admin ? true : this.user.permisos.acciones.some(el => el.name_ruta == permision)
        },
        functionPermitAction(permision, fun = () => {}, params = []){
            const permisos = this.permision.find(el => el.guardian == permision)
            if (this.permitAction(permision)) {
                fun(...params)
            } else {
                new Notification({
                    message:`No tiene permisos para esta acción: ${permisos.nombre}`,
                    type:'error',
                    dangerouslyUseHTMLString: false
                })
            }
        }
    }
}