import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { EspesorVOFactory } from '~/domain/value_objects/almacen/pedidos/factories/EspesorVOFactory'
import { $try } from '~/services/global'

class GetSelectEspesorUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectEspesor(payload)
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => EspesorVOFactory.instantiate(e))
        })
    }
}

export default GetSelectEspesorUseCase.execute