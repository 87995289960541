import * as ActividadesDomain from '~/domain/use_cases/almacen/mantenimiento/actividades'
import { Activity } from '~/domain/entities/almacen/mantenimiento/actividades/Activity'
import { $try } from '~/services/global'
// state
export const state = {
    actividades: [],
    tipos_calendario: [],
}

// getters  
export const getters = {
    actividades: state => state.actividades,
    tipos_calendario: state => state.tipos_calendario,
}

// mutations
export const mutations = {
    set_actividades(state, actividades) {
        state.actividades = actividades
    },

    set_tipos_calendario(state, tipos_calendario) {
        state.tipos_calendario = tipos_calendario
    },

    update_show_state(state, index){
        let clonedState = [...state.actividades.data]
        clonedState[index].show = !clonedState[index].show
        state.actividades.data = clonedState
    },

    update_attachments(state, {id_actividad, adjunto}){
        let clonedState = [...state.actividades.data]
        const index = clonedState.indexOf(
            find(item => item.id == id_actividad)
        )
    },

    create_actividades(state, actividad) {
        state.actividades.data = [...state.actividades.data, actividad]
    },

    edit_actividades(state, actividad) {
        state.actividades.data =  state.actividades.data.map(el => el.id === actividad.id ? actividad : el)
    },

    delete_actividades(state, id) {
        state.actividades.data = state.actividades.data.filter(item => item.id !== id)
    },
}

// actions
export const actions = {
    Action_update_show_actividad({commit}, index){
        $try(
            () => commit('update_show_state', index)
        )
    },

    async Action_get_tipos_calendario({ commit }) {
        const tipos_calendario = await ActividadesDomain.GetCalendarTypesUseCase()
        $try(
            () => commit('set_tipos_calendario', tipos_calendario)
        )
    },

    async Action_get_actividad({ commit }, payload) {
        const actividades = await ActividadesDomain.GetMaintenanceActivitiesUseCase(payload)
        $try(
            () => commit('set_actividades', actividades)
        )
    },

    async Action_create_actividad({ commit }, payload) {
        const actividad = await ActividadesDomain.CreateMaintenanceActivitiyUseCase(payload)
        $try(
            () => commit('create_actividades', actividad)
        )
    },

    async Action_edit_actividad({ commit }, payload) {
        const actividad = await ActividadesDomain.EditMaintenanceActivityUseCase(payload)
        $try(() =>{
            if(actividad instanceof Activity) commit('edit_actividades', actividad)
        })
    },

    async Action_delete_actividad({ commit }, payload) {
        const deleted = await ActividadesDomain.DeleteMaintenanceActivityUseCase(payload)
        $try(() => {
            if(deleted) commit('delete_actividades', payload.id_actividad)
        })
    },

    //Attachments
    async Action_create_adjunto({ commit }, payload) {
        const adjunto = await ActividadesDomain.CreateAttachmentUseCase(payload)
        $try(() => {
            if(adjunto) commit('update_attachments', payload)
        })
    },

}