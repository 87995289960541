import Api from '~/services/api';
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { QuotationFactory } from '~/domain/entities/oportunidades/ver/factories/QuotationFactory.js'

export async function getCotizaciones(id_oportunidad, payload){
	const data = await new Api().get(`oportunidades/vigentes/${id_oportunidad}/cotizaciones`, payload)
	if(!data?.data) return null;

	return new PaginationDecorator(
		data.data,
		QuotationFactory.instantiate
	)
}