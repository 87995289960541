import Api from '~/services/api'
export const Selects = {
    GetSelectProjects(){
        const Select = new Api();
        return Select.get(`select/proyectos`)
    },
    GetSelectProviders(){
        const Select = new Api();
        return Select.get(`select/proveedores`)
    },
    GetSelectResponsableProveedor(){
        const Select = new Api();
        return Select.get(`select/responsables-proveedores`)
    },
    GetSelectClients(){
        const Select = new Api();
        return Select.get(`select/clientes`)
    },
    GetSelectCountries(){
        const Select = new Api();
        return Select.get('select/paises')
    },
    GetSelectCountriesSearch(payload){
        const Select = new Api();
        return Select.get('paises', {'nombre': payload})
    },
    GetSelectStateSearch(payload){
        const Select = new Api();
        return Select.get('departamentos', payload)
    },
    GetSelectMaterials(){
        const Select = new Api();
        return Select.get(`select/materiales`)
    },
    GetSelectOpportunityType(){
        const Select = new Api();
        return Select.get('oportunidades/tipos-oportunidades')
    },
    GetMaterialTypes(){
        const Select = new Api();
        return Select.get('select/tipos-materiales')
    },
    GetMeasureUnits(){
        const Select = new Api();
        return Select.get('select/unidades-medida')
    },
    GetSelectOpportunityEquipos(){
        const Select = new Api();
        return Select.get(`oportunidades/equipos-modelos`)
    },
    GetSelectOpportunityEquiposModelos(payload){
        const Select = new Api();
        return Select.get(`oportunidades/${payload}/equipos-modelos`)
    },
    GetSelectOpportunityResponsibles(payload){
        const Select = new Api();
        return Select.get(`oportunidades/${payload}/select-responsables`)
    },
    GetSelectOpportunityCustomers(payload){
        const Select = new Api();
        return Select.get(`oportunidades/${payload}/select-contactos`)
    },
    GetSelectCotizaciones(payload){
        const Select = new Api();
        return Select.get(`select/cotizaciones`, payload)
    },
    GetSelectFiltroBodegas(){
        const Select = new Api();
        return Select.get(`bodega/filtro`)
    },
    GetSelectResponsables(){
        const Select = new Api();
        return Select.get(`proveedores/usuarios-proveedores`)
    },
    GetSelectStatesPaginated(payload){
        const Select = new Api();
        return Select.get('select/estados', payload)
    },
    GetSelectCityPaginated(payload){
        const Select = new Api();
        return Select.get('select/ciudades', payload)
    },
    GetSelectListaChequeosObras(){
        const Select = new Api();
        return Select.get('select/lista-chequeo')
    },
    GetSelectTalleres(){
        const Select = new Api();
        return Select.get('select/talleres')
    },
    GetSelectConfiguraciones(payload){
        const Select = new Api();
        return Select.get('select-configuraciones', payload)
    },
    GetSelectConfiguracionesSecciones(payload){
        const Select = new Api();
        return Select.get('select-configuraciones-secciones', payload)
    },
    GetSelectResponsibleWorkshops(){
        const Select = new Api();
        return Select.get(`talleres/usuarios-responsables`)
    },
    GetSelectCategoties(payload){
        const Select = new Api();
        return Select.get(`select-categorias`, payload)
    },
    GetSelectBills(){
        const Select = new Api();
        return Select.get(`select/facturas`)
    },
    GetSelectActividadPredecesora(payload){
        const Select = new Api();
        return Select.get(`select/actividad-predecesora-calendario-general`, payload)
    },
    GetSelectActividadPredecesoraTalleres(payload){
        const Select = new Api();
        return Select.get(`select/actividad-predecesora-calendario-talleres`, payload)
    },
    GetSelectProyectosPedidos(){
        const Select = new Api();
        return Select.get(`select/proyectos-pedido`)
    },
    GetSelectProductoPedidos(payload){
        const Select = new Api();
        return Select.get(`select/productos-pedido`, payload)
    },
    GetSelectCategorias(){
        const Select = new Api();
        return Select.get(`select-categorias-materiales`)
    },
    GetSelectUserSolicita(){
        const Select = new Api();
        return Select.get(`select/solicita-pedido`)
    },
    GetSelectMateriales2(payload){
        const Select = new Api();
        return Select.get(`select-materiales-2`, payload)
    },
    GetSelectEspesor(payload){
        const Select = new Api();
        return Select.get(`select-espesor`, payload)
    },
    GetSelectWineries(){
        const Select = new Api();
        return Select.get(`select/wineries`)
    },
    GetSelectMonedas(){
        const Select = new Api();
        return Select.get(`select/monedas`)
    },
    GetSelectEncargadosUsers(){
        const Select = new Api();
        return Select.get('users')
    },
    GetSelectAllEquiposModelos(){
        const Select = new Api();
        return Select.get('select/equipos-modelos')
    },
    GetSelectUsersInternos(){
        const Select = new Api();
        return Select.get('select/users-internos')
    },
    GetSelectAllProjects(){
        const Select = new Api();
        return Select.get('select/proyectos-fs')
    }
}