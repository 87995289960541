import { Filter } from '~/domain/core/Filter';

export class ConfiguracionFilter extends Filter{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'buscar',
                'id_tipo_material',
                'id_unidad_medida',
                'valor_minimo',
                'valor_maximo',
            ]
        });
    }
}