import { Entity } from '~/domain/entities';

export class OpportunityProductResume extends Entity{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id',
                'horas_adicionales', 
                'materiales', 
                'horas_totales', 
                'valor_ultima_cotizacion', 
                'incrementos_porcentuales'
            ],
        })
    }
}