import { UseCase } from "~/domain/core/UseCase"
import { Maintenance } from '~/services/almacen/mantenimiento'
import { MaintenanceFactory } from '~/domain/entities/almacen/mantenimiento/factories/MaintenanceFactory'
import { $try } from '~/services/global'

class CreateMaintenancePlanUseCase extends UseCase {
    static async execute(params){
        const data =  await Maintenance.createMaintenancePlan(params)
        
        return $try(() => {
            return data.data 
                ? MaintenanceFactory.instantiate(data.data)
                : null
        })
    }
}

export default CreateMaintenancePlanUseCase.execute