import { UseCase } from "~/domain/core/UseCase";
import { Gallery } from '~/services/obras/gallery/gallery'
import { $try } from '~/services/global'

class DeleteFolderFileUseCase extends UseCase {
    static async execute(params){
        const data = await Gallery.deleteFolderFile(params)
        return $try(() => {
            if(data?.message){
                return params.idArchivo
            } 
        })
    }
}

export default DeleteFolderFileUseCase.execute