import Api from '~/services/api'

const ApiHandler = new Api()

const BuyOrders = {
  obtenerOportunidadesOrdenesCompra({id_proveedor, payload}){
    return ApiHandler.get(`/proveedores/${id_proveedor}/ordenes_de_compra/oportunidades`, payload)
  },
  obtenerClientesOrdenesCompra({id_proveedor, payload}){
    return ApiHandler.get(`/proveedores/${id_proveedor}/ordenes_de_compra/clientes`, payload)
  },
}

export default BuyOrders