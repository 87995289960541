import { Entity } from '~/domain/entities'

export class MaterialCotizacion extends Entity{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id',
                'nombre',
                'referencia',
                'id_tipo_material',
                'configuracion_tipos_materiales',
                'id_unidad_medida',
                'configuracion_unidad_medidas',
                'valor',
                'cantidad',
                'id_cotizacion',
                'id_material',
            ]
        })
    }
}