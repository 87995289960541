import { Entity } from '~/domain/entities'

export class FiltroMateriales extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'tipo_materiales',
                'unidad_medida',
                'valor_min',
                'valor_max'

            ]
        });
    }
}