import { UseCase } from '~/domain/core/UseCase'
import { Equipos } from '~/services/almacen/equiposModelos'
import { EquipoFactory } from '~/domain/entities/almacen/equipos_modelos/factories/equipoFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetEquiposModelosUseCase extends UseCase {
    static async execute(payload){
        const data = await Equipos.getEquipos(payload)
        return $try(() => {
            if(data?.data?.data)
                return new PaginationDecorator(data.data, EquipoFactory.instantiate)
        })
    }
}

export default GetEquiposModelosUseCase.execute