import { OrdenCompraFilter } from "../OrdenCompraFilter"

export class OrdenCompraFilterFactory {
    static instantiate(obj){
        return new OrdenCompraFilter(obj)
    }

    static instantiateEmpty(){
        const filter = new OrdenCompraFilter({
            id_orden_compra: '',
            id_proyecto: '',
            estado: '',
            valor_min: 0,
            valor_max: 0,
        })
        filter.setExceptions({
            valor_min: 0,
            valor_max: 0,
        })
        return filter
    }

    static instantiateEmptyWithRange(rangeValor){
        const valor_min = rangeValor?.min ?? 0;
        const valor_max = rangeValor?.max ?? 0;

        const filter = new OrdenCompraFilter({
            id_orden_compra: '',
            id_proyecto: '',
            estado: '',
            valor_min,
            valor_max,
        })
        filter.setExceptions({
            valor_min,
            valor_max,
        })
        return filter
    }
}