import Api from '~/services/api'

export const Documents = {
    // Carpetas
    getFolders(id_taller){
        const Service = new Api()
        return Service.get(`talleres/${id_taller}/carpetas`)
    },
    saveFolder(id_taller, folder){
        const Service = new Api({ notification: true })
        return Service.post(`talleres/${id_taller}/carpetas`, folder)
    },
    updateFolder(id_taller, id_folder, folder){
        const Service = new Api({ notification: true })
        return Service.put(`talleres/${id_taller}/carpetas/${id_folder}`, folder)
    },
    deleteFolder(id_taller, id_folder){
        const Service = new Api({ notification: true })
        return Service.delete(`talleres/${id_taller}/carpetas/${id_folder}`)
    },
    // Archivos
    getFiles(id_taller, id_folder, callback){
        const Service = new Api()
        return Service.get(`talleres/${id_taller}/carpetas/${id_folder}/archivos`, {}, {}, callback)
    },
    saveFile(id_taller, id_folder, file){
        const Service = new Api({ notification: true })
        return Service.post(`talleres/${id_taller}/carpetas/${id_folder}/archivos`, file)
    },
    updateFileName(id_taller, id_folder, id_file, nombre = null){
        const Service = new Api({ notification: true })
        return Service.put(`talleres/${id_taller}/carpetas/${id_folder}/archivos/${id_file}`, { nombre })
    },
    deleteFile(id_taller, id_folder, id_file){
        const Service = new Api({ notification: true })
        return Service.delete(`talleres/${id_taller}/carpetas/${id_folder}/archivos/${id_file}`)
    }
}