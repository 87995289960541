import { UseCase } from "~/domain/core/UseCase";
import ViewInfoProvider from '~/services/proveedores/ver/ver'
import { $try } from '~/services/global'

class GetProviderDetailsUseCase extends UseCase {
    static async execute(params){
        const { id_proveedor } = params
        const data = await ViewInfoProvider.obtenerDetallesProveedor({id_proveedor})

        return $try(() => {
            return data?.data
        })
    }
}

export default GetProviderDetailsUseCase.execute