import { Entity } from '~/domain/entities'

export class Folder extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['nombre', 'id']
        });
    }

    setId(id){
        this.id = id
    }

    setNombre(nombre){
        this.nombre = nombre
    }
}