import * as Bodega from '~/domain/use_cases/bodegas/bodega'
import * as Calendario from '~/domain/use_cases/calendario'
import { $try } from '~/services/global'

export const state = {
    calendarioAnnio: [],
    equipos:[],
    fechasProximas:[],
    cabecera:{},
    calendarioMes:[],
    calendarioSemana:[],
    detalleTarea:{},
    fechaCalendario: ''
}

export const getters = {
    calendarioAnnio: state => state.calendarioAnnio,
    equipos: state => state.equipos,
    fechasProximas: state => state.fechasProximas,
    cabecera: state => state.cabecera,
    calendarioMes: state => state.calendarioMes,
    calendarioSemana: state => state.calendarioSemana,
    detalleTarea: state => state.detalleTarea,
    fechaCalendario: state => state.fechaCalendario,
}

export const mutations = {
    setCalendarioAnnio(state, payload){
        state.calendarioAnnio = payload
    },
    setEquipos(state, payload){
        state.equipos = payload
    },
    setFechasProximas(state, payload){
        state.fechasProximas = payload
    },
    setCabecera(state, payload){
        state.cabecera = payload
    },
    setCalendarioMes(state, payload){
        state.calendarioMes = payload
    },
    setCalendarioSemana(state, payload){
        state.calendarioSemana = payload
    },
    setDetalleTarea(state, payload){
        state.detalleTarea = payload
    },
    setFechaCalendario(state, payload){
        state.fechaCalendario = payload
    },
}

export const actions = {
    async Action_get_calendario_annio({commit}, payload){
        const data = await Calendario.GetCalendarioAnnioUseCase(payload)
        $try( () => { commit('setCalendarioAnnio', data) })
    },
    async Action_get_equipos({commit}){
        const data = await Calendario.GetEquiposUseCase()
        $try( () => { commit('setEquipos', data) })
    },
    async Action_get_ops_fechas_proximas({commit}){
        const data = await Calendario.GetOpsFechasProximas()
        $try( () => { commit('setFechasProximas', data) })
    },
    async Action_get_cabecera_calendario({commit}, payload){
        const data = await Calendario.GetCabeceraCalendarioUseCase(payload)
        $try( () => { commit('setCabecera', data) })
    },
    async Action_get_calendario_mes({commit}, payload){
        const data = await Calendario.GetCalendarioMesUseCase(payload)
        $try( () => { commit('setCalendarioMes', data) })
    },
    async Action_get_calendario_semana({commit}, payload){
        const data = await Calendario.GetCalendarioSemanaUseCase(payload)
        $try( () => { commit('setCalendarioSemana', data) })
    },
    async Action_get_detalle_tarea({commit}, payload){
        const data = await Calendario.GetDetalleTareaUseCase(payload)
        $try( () => { commit('setDetalleTarea', data) })
    },

}