import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { ProductoVOFactory } from '~/domain/value_objects/almacen/pedidos/factories/ProductoVOFactory'
import { $try } from '~/services/global'

class GetSelectProductoPedidoUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectProductoPedidos(payload)
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => ProductoVOFactory.instantiate(e))
        })
    }
}

export default GetSelectProductoPedidoUseCase.execute