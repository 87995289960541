import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'
import { CalendarActivityFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/CalendarActivityFactory'
import { $try } from '~/services/global'

class EditCalendarActivityUseCase extends UseCase {
    static async execute(params){
        const data = await GeneralCalendarService.editGeneralCalendarActivity(params)

        return $try(() => {
            if(data?.data) return CalendarActivityFactory.instantiate(data)
        })
    }
}

export default EditCalendarActivityUseCase.execute