import { UseCase } from "~/domain/core/UseCase";
import { CorreosNotificacion } from '~/services/configurar/proveedores/correos_notificacion'


class DeleteEmailUseCase extends UseCase{
    static async execute(payload){
        const data = await CorreosNotificacion.deleteEmail(payload)
        return data?.message
    }
}

export default DeleteEmailUseCase.execute