import { UseCase } from '~/domain/core/UseCase';
import { Resumen } from '~/services/oportunidades/productos/resumen';
import { OpportunityProductResumeFactory } from '~/domain/entities/oportunidades/productos/factories/OpportunityProductResumeFactory';
import { $try } from '~/services/global';

class GetOpportunityProductResumeUseCase extends UseCase {
    static async execute(params) {
        const data = await Resumen.getOpportunityProductResume(params);

        return $try(() => {
            if(!data?.data) return OpportunityProductResumeFactory.instantiateEmpty();
            
            return OpportunityProductResumeFactory.instantiate(data.data);
        })
    }
}

export default GetOpportunityProductResumeUseCase.execute