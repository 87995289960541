import * as ServiciosVisitsUseCase from '~/domain/use_cases/obras/servicios/visitas/index';
import { $try } from '~/services/global'

export const state = {
    visitas: [],
    visitDetail: {},
    visitDetailsComments: [],
    visitDetailsCommentsAct: [],
}

export const getters = {
    visitas: state => state.visitas,
    visitDetails: state => state.visitDetail,
    visitDetailsComments: state => state.visitDetailsComments,
    visitDetailsCommentsAct: state => state.visitDetailsCommentsAct,
}

export const mutations = {
    set_visitas(state, payload) {
        state.visitas = payload
    },
    set_details_visit(state, payload) {
        state.visitDetail = payload
    },
    set_details_comments_visit(state, payload) {
        state.visitDetailsComments = payload
    },
    set_details_comments_visit_act(state, payload) {
        state.visitDetailsCommentsAct = payload
    },
}

export const actions = {
    async Action_get_servicios({ commit, state }, payload) {
        const data = await ServiciosVisitsUseCase.GetVisitasUseCase(payload);
        $try(() => {
            if(data && Array.isArray(data)) commit('set_visitas', _.orderBy(data, ['id'], ['desc']))
        })
    },
    async Action_create_visit({ commit, state }, payload) {
        const visit = await ServiciosVisitsUseCase.CreateVisitUseCase(payload);
        return $try(() => {
            if (visit){
                commit('set_visitas', [...state.visitas, visit])
                return { id: visit.id };  
            } 
        })
    },
    async Action_get_visit_details({ commit, state }, payload) {
        const data = await ServiciosVisitsUseCase.GetVisitDetailsUseCase(payload);
        $try(() => {
            if(data) commit('set_details_visit', data)
        })
    },
    async Action_get_visit_details_comments({ commit, state }, payload) {
        const data = await ServiciosVisitsUseCase.GetVisitCommentsUseCase(payload);
        $try(() => {
            if(data) commit('set_details_comments_visit', data)
        })
    },
    async Action_create_visit_comment({ commit, state }, payload) {
        const comment = await ServiciosVisitsUseCase.CreateVisitCommentUseCase(payload);
        if (comment) commit('set_details_comments_visit', [...state.visitDetailsComments, comment])
    },
    async Action_edit_visit({ commit, state }, payload) {
        const data = await ServiciosVisitsUseCase.EditVisitUseCase(payload);
    },
    async Action_delete_visit({ state, commit }, payload) {
        const data = await ServiciosVisitsUseCase.DeleteVisitUseCase(payload)
    },
    async Action_add_activity_percentage({ state, commit }, payload) {
        const data = await ServiciosVisitsUseCase.AddActivityPercentageUseCase(payload)
    },
    async Action_get_activity_files({ commit, state }, payload) {
        const data = await ServiciosVisitsUseCase.GetActivityFiles(payload);
        return $try(() => {
            if (data){
                return data
            } 
        })
    },
    async Action_get_activity_comments({ commit, state }, payload) {
        const data = await ServiciosVisitsUseCase.GetActivityComments(payload);
        $try(() => {
            if(data) commit('set_details_comments_visit_act', data)
        })
    },
    async Action_create_visit_act_comment({ commit, state }, payload) {
        const comment = await ServiciosVisitsUseCase.CreateVisitActCommentUseCase(payload);
        if (comment) commit('set_details_comments_visit_act', [...state.visitDetailsCommentsAct, comment])
    },
    async Action_add_attachment_act({ commit, state }, payload) {
        const data = await ServiciosVisitsUseCase.AddAttachmentActUseCase(payload);
        //if (comment) commit('set_details_comments_visit_act', [...state.visitDetailsCommentsAct, comment])
    },
    async Action_end_visit({ commit, state }, payload) {
        const data = await ServiciosVisitsUseCase.EndVisitUseCase(payload);
        console.log('data=>',data);
    },
}