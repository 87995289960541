import { OrdenCompraFilter } from "../OrdenCompraFilter"

export class DetalleOrdenCompraFilterFactory {
    static instantiateEmpty(){
        const filter = new OrdenCompraFilter({
            id_tipo_material:'',
            unidades_min:0,
            unidades_max:0,
        })
        filter.setExceptions({
            unidades_min: 0,
            unidades_max: 0,
        })
        return filter
    }

    static instantiateEmptyWithRange(rangeUnidades){
        const unidades_min = rangeUnidades?.min ?? 0;
        const unidades_max = rangeUnidades?.max ?? 0;

        const filter = new OrdenCompraFilter({
            id_tipo_material: '',
            unidades_min,
            unidades_max,
        })
        filter.setExceptions({
            unidades_min,
            unidades_max,
        })
        return filter
    }
}