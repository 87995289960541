import { UseCase } from '~/domain/core/UseCase'
import { WorkshopCalendarService } from '~/services/configurar/obras/calendario_talleres'
import { WorkshopCalendarFactory } from '~/domain/entities/configurar/obras/calendario_talleres/factories/WorkshopCalendarFactory'
import { $try } from '~/services/global'

class EditWorkshopCalendarUseCase extends UseCase {
    static async execute(params){
        const { data } =  await WorkshopCalendarService.editWorkshopCalendar(params)

        return $try(
                () => WorkshopCalendarFactory.instantiate(data)
        )
    }
}

export default EditWorkshopCalendarUseCase.execute