import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivities } from "~/services/almacen/mantenimiento/actividades"

class DeleteMaintenanceActivityUseCase extends UseCase {
    static async execute(params){
        const data = await MaintenanceActivities.deleteMaintenanceActivity(params)
        return data?.message;
    }
}

export default DeleteMaintenanceActivityUseCase.execute