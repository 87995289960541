import { UseCase } from "~/domain/core/UseCase"
import { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { $try } from "~/services/global"

class AsociarFacturaUseCase extends UseCase {
    static async execute(payload) {
        const data = await PedidosInternosOcs.asociarFactura(payload)
        
        return $try(() => data?.data)
    }
}

export default AsociarFacturaUseCase.execute;
