import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { StageFactory } from '~/domain/entities/compras/matriz/factories/StageFactory'
import { $try } from '~/services/global'

class GetProductStagesUseCase extends UseCase {
    static async execute(params, payload){
        const data =  await Matriz.getProductStages(params, payload)
        return $try(() => {
            if(!Array.isArray(data?.data)) return []
            return data.data.map(e => new StageFactory.instantiate(e)) 
        })
    }
}

export default GetProductStagesUseCase.execute