import { Entity } from '~/domain/entities'

export class GraficaPagar extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'total_pagar',
                'grafica',
                'tabla',
            ]
        })
    }
}