import { ValueObject } from '~/domain/core/ValueObject'

export class FiltersBodegasVO extends ValueObject {
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'bodegas',
                'valor_minimo',
                'valor_maximo',
                'materiales_minimo',
                'materiales_maximo',
                'tipos_materiales'
            ]
        });
    }
}