import { StageFilter } from "../StageFilter"

export class StageFilterFactory {
    static instantiate(obj){
        return new StageFilter(obj)
    }

    static instantiateEmpty(){
        const filter = new StageFilter({
            id_tipo_material: '',
            id_unidad_medida: '',
            valor_minimo: 0,
            valor_maximo: 0,
        })
        filter.setExceptions({
            valor_minimo: 0,
            valor_maximo: 0,
        })
        return filter
    }

    static instantiateEmptyWithRange(rango){
        const valor_minimo = rango?.valor_minimo ?? 0;
        const valor_maximo = rango?.valor_maximo ?? 0;

        const filter = new StageFilter({
            id_tipo_material: '',
            id_unidad_medida: '',
            valor_minimo,
            valor_maximo
        })
        filter.setExceptions({
            valor_minimo,
            valor_maximo
        })
        return filter
    }
}