import { getCotizaciones } from '~/services/oportunidades/ver/cotizaciones';
import { CotizacionFilterFactory } from '~/domain/filters/opportunity/ver/factories/CotizacionFilterFactory'
import { getCotizacionesEnviar } from '~/services/oportunidades/ver/enviar-cotizacion';
import { postEnviarCotizaciones } from '~/services/oportunidades/ver/post-cotizaciones-enviar';
import { getDetalleCotizaciones } from '~/services/oportunidades/ver/detalle-cotizaciones';
import { getCotizacionesEstado } from '~/services/oportunidades/ver/estado-cotizaciones';
import { getDetalleProveedoresCotizaciones } from '~/services/oportunidades/ver/detalle-cotizaciones-proveedores';
import { postCotizacionesHabilitar } from '~/services/oportunidades/ver/post-cotizaciones-habilitar';

export const state = {
	cotizaciones: {
		pagination: {
			current_page: 1,
		}
	},
	detalleCotizaciones: {
		pagination: {
			current_page: 1,
		}
	},
	cotizacionesEnviar: {
		materiales: [],
		proveedores: []
	},
	cotizacionesEstado: [],
	filter: CotizacionFilterFactory.instantiateEmpty(),
	queryState: '',
	detalleProveedores: [],
	query: '',
}

export const getters = {
	cotizaciones: state => state.cotizaciones.data,
	detalleCotizaciones: state => state.detalleCotizaciones.data,
	cotizacionesEnviar: state => state.cotizacionesEnviar,
	cotizacionesEstado: state => state.cotizacionesEstado,
	detalleProveedores: state => state.detalleProveedores,
	pagination: state => state.cotizaciones.pagination,
	paginationDetail: state => state.detalleCotizaciones.pagination,
	filter: state => state.filter,
	query: state => state.query,
	queryState: state => state.queryState,
}

export const mutations = {
	set_cotizaciones(state, payload){
		state.cotizaciones = payload
	},
	set_cotizaciones_detalle(state, payload){
		state.detalleCotizaciones = payload
	},
	set_cotizaciones_enviar(state, payload){
		state.cotizacionesEnviar = payload
	},
	set_cotizaciones_estado(state, payload){
		state.cotizacionesEstado = payload
	},
	set_detalle_proveedores(state, payload){
		state.detalleProveedores = payload
	},
	set_filter(state, payload){
		state.filter = payload
	},
	set_query(state, payload){
		state.query = payload
	},
	set_query_state(state, payload){
		state.queryState = payload
	},
	set_pagination(state, payload){
		state.cotizaciones.pagination = payload
	},
	set_pagination_detail(state, payload){
		state.detalleCotizaciones.pagination = payload
	},
}

export const actions = {
	async Action_get_cotizaciones({ state, commit }, id){	
		const buscar = state.query || null 
		const page = state.cotizaciones?.pagination?.current_page ?? 1
		const filter = state.filter.clean()

		const data = await getCotizaciones(id, {...filter, page, buscar})
		
		if(data) commit('set_cotizaciones', data)
	},
	async Action_get_detalle_cotizaciones({ state, commit }, {idOportunidad, idCotizacion}){	
		const buscar = state.query || null 
		const page = state.detalleCotizaciones?.pagination?.current_page ?? 1
		const filter = state.filter.clean()

		const data = await getDetalleCotizaciones(idOportunidad, idCotizacion, {...filter, page, buscar})
		
		if(data) commit('set_cotizaciones_detalle', data)
	},
	async Action_get_cotizaciones_enviar({ commit }, id){	
		const data = await getCotizacionesEnviar(id)
		if(data) commit('set_cotizaciones_enviar', data)
	},
	async Action_get_cotizaciones_estado({ commit }, payload){
		const buscar = state.queryState || null;	
		const data = await getCotizacionesEstado(payload, {buscar})
		if(data) commit('set_cotizaciones_estado', data)
	},
	async Action_enviar_cotizaciones(_, {id, payload}){
		await postEnviarCotizaciones(id, payload)
	},
	async Action_cotizaciones_habilitar(_, {id, payload}){
		console.log(id)
		await postCotizacionesHabilitar(id, payload)
	},
	async Action_get_detalle_proveedores({commit}, id){
		const data = await getDetalleProveedoresCotizaciones(id)
		if(data) commit('set_detalle_proveedores', data)
	},
	clearFilter({ commit }){
		commit('set_filter', CotizacionFilterFactory.instantiateEmpty())
	},
}
