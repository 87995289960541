import { ValueObject } from '~/domain/core/ValueObject'

export class WorkshopGraphVO extends ValueObject {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'mes',
                'mes_abre',
                'numero_mes',
                'anio',
                'numero_servicios',
            ]
        })
    }
}