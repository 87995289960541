import { Filter } from '~/domain/core/Filter';

export class CotizacionesFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'buscar',
                'id_proveedor',
                'materiales_no_cotizados',
                'materiales_cotizados',
                'id_tipo_material',
                'ordena_proveedor_por',
            ]
        });
    }
}