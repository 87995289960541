import { UseCase } from "~/domain/core/UseCase"
import  { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { SelectPedidosFactoy } from '~/domain/value_objects/almacen/pedidos/factories/SelectPedidosFactoy'
import { $try } from '~/services/global'

class GetSelectPedidosUseCase extends UseCase {
    static async execute(){
        const data = await PedidosInternosOcs.getSelectPedidosInternos()
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => SelectPedidosFactoy.instantiate(e))
        })
    }
}

export default GetSelectPedidosUseCase.execute