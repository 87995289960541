import { UseCase } from "~/domain/core/UseCase";
import { Informacion } from '~/services/obras/informacion/informacion'
import { InformacionFactory } from '~/domain/entities/obras/informacion/factory/informacionFactory'
import { $try } from '~/services/global'

class GetInformacionHeaderUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await Informacion.getInfoCabecera(payload)
        return $try(() => {
            if(data?.data) return InformacionFactory.instantiate(data.data)
        })
    }
}

export default GetInformacionHeaderUseCase.execute