import { MatrizList } from "../MatrizList";

export class MatrizListFactory {
    static instantiate(obj){
        return new MatrizList(obj)
    }

    static instantiateEmpty(){
        return new MatrizList({
            id: '',
            oportunidad: '',
            bodegas: '',
            oc: '',
            producto: '',
            proveedores: '',
        })
    }
}