import { Filter } from '~/domain/core/Filter';

export class MaterialesFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'nombre',
                'id_unidad_medida',
                'cantidad_min',
                'cantidad_max'
            ]
        });
    }
}