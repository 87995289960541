import { ValueObject } from '~/domain/core/ValueObject'

export class WorkshopTypeGraphVO extends ValueObject {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'servicio',
                'servicios_vigentes',
            ]
        })
    }
}