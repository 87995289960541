import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'

class GetSelectAllProjectsUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectAllProjects()
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default GetSelectAllProjectsUseCase.execute;