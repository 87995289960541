import { ClientesVO } from "../ClientesVO";

export class ClientesVOFactory {
    static instantiate(obj){
        return new ClientesVO(obj)
    }

    static instantiateEmpty(){
        return new ClientesVO({
            id: '',
            nombre_corto: '',
        })
    }
}