import { Entity } from '~/domain/entities'

export class InventarioMaterial extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                "id",
                "nombre",
                "disponible"
            ]
        })
    }
}