import { MatsFilter } from "../MatsFilter";

export class MatsFilterFactory {
    static instantiate(obj){
        return new MatsFilter(obj)
    }

    static instantiateEmpty(){
        return new MatsFilter({
            id_tipo_material: null,
            id_unidad_medida: null,
            materiales_no_cotizados: null,
            materiales_cotizados: null
        })
    }
}