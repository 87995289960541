export default [
    {
        path: '/usuarios/proveedores',
        name: 'proveedores.usuarios',
        redirect: { name: 'proveedores.usuarios.cotizaciones.pendientes' },
        component: () => import('../../pages/proveedores/usuarios').then(m => m.default || m),
        children: [
            {
                path: 'cotizaciones/pendientes',
                name: 'proveedores.usuarios.cotizaciones.pendientes',
                component: () => import('../../pages/proveedores/usuarios/cotizacionesPendientes').then(m => m.default || m),
            },
            {
                path: 'cotizaciones/realizadas',
                name: 'proveedores.usuarios.cotizaciones.realizadas',
                component: () => import('../../pages/proveedores/usuarios/cotizacionesRealizadas').then(m => m.default || m),
            },
            {
                path: 'cotizaciones/vencidas',
                name: 'proveedores.usuarios.cotizaciones.vencidas',
                component: () => import('../../pages/proveedores/usuarios/cotizacionesVencidas').then(m => m.default || m),
            },
            {
                path: 'informacion',
                name: 'proveedores.usuarios.informacion',
                component: () => import('../../pages/proveedores/usuarios/informacion').then(m => m.default || m),
            },
        ]
    },
]