import { DomainObject } from "~/domain/core/DomainObject";

export class Filter extends DomainObject{
    #exceptions = new Object()

    constructor({params: obj, keys: KEYS}){
        super({
            params: obj,
            keys: KEYS
        })
    }
    count(){
        const exceptions = this.#exceptions ? Object.entries(this.#exceptions) : [];
        return Object.entries(this).filter(([key, filter]) => {
            const exception = exceptions.find((exception) => exception[0] === key)
            const test = exception ? key === exception[0] && filter != exception[1] : filter != 0
            if(typeof filter == 'number' && test) return filter
            if(filter instanceof Date) return filter
            if(Array.isArray(filter) && filter.length > 0) return filter
            if(typeof filter == 'string' && filter != '') return filter
        }).length
    }
    clean(){
        const Obj = new Object();
        Object.entries(this).forEach(([key,value]) => {
            if(Array.isArray(value) && value.length == 0) Obj[key] = null
            else if(typeof value == 'string' && value == '') Obj[key] = null
            else Obj[key] = value
        })
        return Obj;
    }
    setExceptions(val){
        this.#exceptions = val;
    }
    getExceptions(){
        return this.#exceptions;
    }
}