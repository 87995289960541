import { Entity } from '~/domain/entities'

export class PaymentMethod extends Entity {
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'cupo',
                'forma_pago',
                'fecha_pago',
            ]
        });
    }
}