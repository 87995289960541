import { Bill } from "../Bill";

export class BillFactory {
    static instantiate(obj){
        return new Bill(obj)
    }

    static instantiateEmpty(){
        return new Bill({
            referencia: '',
            id: '',
            fecha_pago: '',
            valor: '',
            ordenes_compra: '',
            estado: '',
        })
    }
}