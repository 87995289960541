import GetPersonalizacionUseCase from '~/domain/use_cases/personalizacion/getPersonalizacionUseCase'
import GetPermisionUseCase from '~/domain/use_cases/personalizacion/getPermisionUseCase'
import { $try } from '~/services/global'

export const state = {
    personalizacion: {},
    permision:[]
}

export const getters = {
    personalizacion: state => state.personalizacion,
    permision: state => state.permision,
}

export const mutations = {
    setPersonalizacion(state, payload){
        state.personalizacion = payload
    },
    setPermision(state, payload){
        state.permision = payload
    }
}

export const actions = {
    async Action_get_personalizacion({commit}){
        const data = await GetPersonalizacionUseCase()
        $try (() => {
            if (!data || !Object.entries(data).length) return
            const root = document.documentElement
            root.style.setProperty('--color-general', data.general_color)
            root.style.setProperty('--text-navbar', data.text_navbar)
            root.style.setProperty('--bg-navbar', data.bg_navbar)
            root.style.setProperty('--bg-drawer', data.bg_drawer)
            commit('setPersonalizacion', data)
        })
    },
    async Action_get_permision({commit}){
        const data = await GetPermisionUseCase()
        commit('setPermision', data)
    }
}