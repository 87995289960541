import { CotizacionesMateriales } from "../CotizacionesMateriales";

export class CotizacionesMaterialesFactory {
    static instantiate(obj){
        return new CotizacionesMateriales(obj)
    }

    static instantiateEmpty(){
        return new CotizacionesMateriales({
            id: undefined,
            nombre: '',
            cantidad: '',
            tipo: '',
            unidad_medida: '',
            valor_unidad: '',
            valor_parcial: '',
            dias_entrega: '',
            garantia: ''
        })
    }
}