import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { ResponsibleFactory } from '~/domain/entities/oportunidades/information/factories/ResponsibleFactory'
import { $try } from '~/services/global'

class AddOpportunityResponsibleUseCase extends UseCase {
    static async execute(params){
        const data =  await Opportunities.addResponsiblesOpportunity(params)
        return $try(() => data?.data ? ResponsibleFactory.instantiate(data.data[0]) : {} )
    }
}

export default AddOpportunityResponsibleUseCase.execute