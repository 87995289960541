import { Entity } from "~/domain/entities";

export class Archivos extends Entity {
  constructor(obj) {
    super({
      params: obj,
      keys: [
        "id",
        "nombre",
        "ruta",
        "id_tipo",
        "tipo",
        "visualizador_archivo",
        "id_control_piso_documentacion_carpetas",
        "created_by",
        "created_at",
        "user"
      ],
    });
  } 
}
