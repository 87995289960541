<template>
    <el-drawer :visible.sync="drawer" :title="titulo">
        <div v-if="comentar" class="row mx-0 mb-3">
            <div class="col-12">
                <div class="d-flex">
                    <img v-if="imgUser" :src="imgUser" alt="" class="rounded-circle wh-46 mr-3">
                    <img v-else :src="user.foto_perfil | helper-storage" alt="" class="rounded-circle wh-46 mr-3">
                    <ValidationObserver ref="addComment" class="w-100">
                        <ValidationProvider v-slot="{errors}" name="comentario" rules="required">
                            <div class="add-comment flex-fill">
                                <el-input v-model="comentario" type="textarea" placeholder="Añade un comentario" :autosize="{ minRows: 4, maxRows: 4}" class="w-100" />
                            </div>
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </ValidationObserver>
                </div> 
            </div>
            <div class="col-12 text-right mt-2">
                <button class="btn btn-general text-white f-12 px-3" @click="addComment">Comentar</button>
            </div>
        </div>
        <div class="comments-list">
            <div v-for="(data,key) in comments" :key="key" class="row mx-0 mb-3">
                <div class="col-12">
                    <div class="d-flex">
                        <img :src="data.user.foto |  helper-storage" alt="" class="rounded-circle wh-46 mr-3">
                        <div class="individual-comment flex-fill border px-3 py-2 bg-f5">
                            <div class="d-flex justify-content-between mb-1">
                                <p class="f-12 f-600">{{ data.user.nombre }}</p>
                                <p class="f-10 font-italic"> {{ data.created_at | helper-fecha('DD/MM/YYYY, h:mm a') }} </p>
                            </div>
                            <p class="lh-15 f-10">{{ data.comentario }}</p>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div v-if="vacio && comments.length === 0" class="d-middle-center flex-column mt-5">
            <img src="/img/default/no-comment.png" class="mb-3 wh-200" style="" >
            <p :class="classTextVacio">{{ textVacio }}</p>
        </div>
    </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        imgUser: { 
            type: String,
            default: ''
        },
        titulo: { 
            type: String,
            default: ''
        },
        comentar: { 
            type: Boolean,
            default: true
        },
        comments:{
            type: Array,
			default: () => []
        },
        vacio:{
            type: Boolean,
            default: true
        },
        textVacio:{
            type: String,
            default: 'No se han realizado comentarios.'
        },
        classTextVacio:{
            type: String,
            default: 'f-15 text-muted text-center px-5'
        }
    },
    data(){
        return {
            drawer: false,
            comentario: '',
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
        },
        async addComment(){
            let valid = await this.$refs.addComment.validate()
            if (!valid) return
            this.$emit('addComment', this.comentario)
            this.comentario = ''
            this.$refs.addComment.reset()
        }
    }
}
</script>
<style lang="scss" scoped>
.add-comment{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: white;
        z-index: 1;
        top: 14px;
        left: -4px;
        transform: rotate(45deg);
        border-left: 1px solid #DCDFE6;
        border-bottom: 1px solid #DCDFE6;
    }
}
.individual-comment{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #f5f5f5;
        z-index: 1;
        top: 14px;
        left: -4px;
        transform: rotate(45deg);
        border-left: 1px solid #DCDFE6;
        border-bottom: 1px solid #DCDFE6;
    }
}
</style>
