import { UseCase } from '~/domain/core/UseCase';
import { WorkshopView } from '~/services/talleres/ver';
import { ServiceTypeVOFactory } from '~/domain/value_objects/talleres/factories/ServiceTypeVOFactory';
import { $try } from '~/services/global';

class GetWorkshopServiceTypesAndRangeUseCase extends UseCase {
    static async execute(id_taller) {
        const data = await WorkshopView.getWorkshopServiceTypesAndRange(id_taller);
        return $try(() => {
            if(data?.data){
                return ({
                    service_types: data.data.tipos_servicios.map(ServiceTypeVOFactory.instantiate),
                    range: {
                        min: data.data.valor_min,
                        max: data.data.valor_max,
                    }
                })
            }
        })
    }
}

export default GetWorkshopServiceTypesAndRangeUseCase.execute;