import * as Configuracion from '~/domain/use_cases/obras/configuracion'
import { ConfiguracionFilterFactory } from '~/domain/filters/obras/factories/configuracionFilterFactory'
import { $try } from '~/services/global'


//state
export const state = {
    configuracion: {},
    selectMateriales: [],
    materialesConfiguracion: [],
    dataFiltro: {},
    filters: ConfiguracionFilterFactory.instantiateEmpty(),
}

//getters
export const getters = {
    configuracion: state => state.configuracion,
    selectMateriales: state => state.selectMateriales,
    materialesConfiguracion: state => state.materialesConfiguracion,
    filters: state => state.filters,
    dataFiltro: state => state.dataFiltro,
}

//mutations
export const mutations = {
    set_configuracion(state, payload) {
        state.configuracion = payload
    },
    set_select_materiales(state, payload) {
        state.selectMateriales = payload
    },
    set_materiales(state, payload) {
        state.materialesConfiguracion = payload
    },
    set_filters(state, payload) {
        state.filters = payload
    },
    set_data_filtro(state, payload) {
        state.dataFiltro = payload
    },
}

//actions
export const actions = {
    async Action_get_configuracion({ commit }, payload) {
        const data = await Configuracion.GetConfiguracionUseCase(payload)
        $try(() => {
            if (data) commit('set_configuracion', data)
        })
    },
    async Action_get_select_materiales({ commit }, payload) {
        const data = await Configuracion.GetSelectMaterialesUseCase(payload)
        $try(() => {
            if (data) commit('set_select_materiales', data)
        })
    },
    async Action_get_materiales_configuracion({ commit }, payload) {
        let data = []
        if (payload.tipo == 1) {
            data = await Configuracion.GetMaterialesUseCase(payload)
        }else{
            data = await Configuracion.GetMaterialesUseCase({...state.filters, ...payload})
        }
        $try(() => {
            if (data) commit('set_materiales', data)
        })
    },
    async Action_get_data_filtro({ commit }, payload) {
        const data = await Configuracion.GetDataFiltroUseCase(payload)
        $try(() => {
            if (data) commit('set_data_filtro', data)
        })
    },
    async Action_post_materiales_configuracion({ commit }, payload) {
        await Configuracion.PostAnnadirMaterialUseCase(payload)
    },
    async Action_put_actualizar_material({ commit }, payload) {
        await Configuracion.PutActualizarMaterialUseCase(payload)
    },
    async Action_delete_material({ commit }, payload) {
        await Configuracion.DeleteMaterialUseCase(payload)
    },
    async Action_delete_configuracion({ commit }, payload) {
        await Configuracion.DeleteConfiguracionUseCase(payload)
    },
    Action_limpiar_filtros({ commit }) {
        commit('set_filters', ConfiguracionFilterFactory.instantiateEmptyWithRange({ min: state.dataFiltro.rango_valor[0], max: state.dataFiltro.rango_valor[1] }))
    },
}