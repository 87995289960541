import { Filter } from '~/domain/core/Filter';

export class CotizacionFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_proveedor',
				'materiales_no_cotizados',
				'materiales_cotizados',
				'id_tipo_material',
				'ordenar_por',
            ]
        });
    }
}