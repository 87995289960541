import * as CalendarioTalleresDomain from '~/domain/use_cases/configurar/obras/calendario_talleres'

import { $try } from '~/services/global'
// state
export const state = {
    calendario_talleres: [],
}

// getters
export const getters = {
    calendario_talleres: state => state.calendario_talleres,
}

// mutations
export const mutations = {
    set_calendario_talleres(state, calendario_talleres){
        state.calendario_talleres = calendario_talleres
    }
}

// actions
export const actions = {

    async Action_get_calendario_talleres({ commit }){
        const calendario_talleres  = await CalendarioTalleresDomain.GetWorkshopCalendarUseCase()
        $try (
            () => commit('set_calendario_talleres', calendario_talleres)
        )
    },

    async Action_create_calendario_talleres({ state, commit }, payload){
        const calendario_talleres = await CalendarioTalleresDomain.CreateWorkshopCalendarUseCase(payload)
        $try (() => {
            if(calendario_talleres) commit('set_calendario_talleres', [...state.calendario_talleres, calendario_talleres])
        })
    },

    async Action_edit_calendario_talleres({ state, commit }, payload){
        const calendario_talleres = await CalendarioTalleresDomain.EditWorkshopCalendarUseCase(payload)
        $try (() => {
            if(calendario_talleres) commit('set_calendario_talleres', state.calendario_talleres.map(item => item.id == calendario_talleres.id ? calendario_talleres : item))
        })
    },

    async Action_delete_calendario_talleres({ state, commit }, payload){
        const data = await CalendarioTalleresDomain.DeleteWorkshopCalendarUseCase(payload)
        $try (() => {
            if(data) commit('set_calendario_talleres', state.calendario_talleres.filter(el => el.id != payload.id))
        })
    },
    async Action_create_calendario_talleres_actividad({ state, commit}, payload){
        const actividad = await CalendarioTalleresDomain.CreateWorkshopCalendarActivityUseCase(payload)
        $try (() => {
            if(actividad) {
                const calendario_talleres = state.calendario_talleres.map(item => {
                    if(item.id != actividad.id_configuracion_calendarios_talleres) return item
                    return ({
                        ...item,
                        actividades: [...item.actividades, actividad]
                    })
                })
                commit('set_calendario_talleres', calendario_talleres)
            }
        })
    },

    async Action_edit_calendario_talleres_actividad({commit}, payload){
        const actividad = await CalendarioTalleresDomain.EditWorkshopCalendarActivityUseCase(payload)
        $try (() => {
            if(actividad) {
                const calendario_talleres = state.calendario_talleres.map(item => {
                    if(item.id != actividad.id_configuracion_calendarios_talleres) return item
                    return ({
                        ...item,
                        actividades: item.actividades.map(act => act.id == actividad.id ? actividad : act)
                    })
                })
                commit('set_calendario_talleres', calendario_talleres)
            }
        })
    },

    async Action_delete_calendario_talleres_actividad({commit}, payload){
        const id = await CalendarioTalleresDomain.DeleteWorkshopCalendarActivityUseCase(payload)
        $try (() => {
            if(actividad) {
                const calendario_talleres = state.calendario_talleres.map(item => {
                    if(item.id != actividad.id_configuracion_calendarios_talleres) return item
                    return ({
                        ...item,
                        actividades: item.actividades.filter(act => act.id != id)
                    })
                })
                commit('set_calendario_talleres', calendario_talleres)
            }
        })
    },
    async Action_update_position_calendar_activity({commit}, payload){
        const activity = await CalendarioTalleresDomain.UpdatePositionActivities(payload)
    },
}