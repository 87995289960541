export default [
    {
        path: '/obras',
        name: 'obras.main',
        meta: {title: 'Obras - main'},
        redirect: { name: 'obras.vigentes.cards' },
        component: () => import('../../pages/obras/index').then(m => m.default || m),
        children: [
            {
                path: 'vigentes',
                name: 'obras.vigentes.cards',
                meta: {title: 'Obras - Vigentes'},
                component: () => import('../../pages/obras/vigentes').then(m => m.default || m),
            },
            {
                path: 'finalizadas',
                meta: {title: 'Obras - Finalizadas'},
                name: 'obras.vigentes.finalizadas',
                component: () => import('../../pages/obras/finalizadas').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/obras/vigentes/:id_proyecto/servicios/:id_control_piso',
        name: 'obras.vigentes',
        meta: {title: 'Obras - Servicios'},
        redirect: {name: 'obras.vigentes.lista.chequeo'},
        component: () => import('../../pages/obras/servicios').then(m => m.default || m),
        children: [
            {
                path: 'lista_de_chequeo',
                name: 'obras.vigentes.lista.chequeo',
                meta: {title: 'Obras - Lista de chequeo'},
                component: () => import('../../pages/obras/servicios/listaChequeo').then(m => m.default || m),
            },
            {
                path: 'visitas',
                name: 'obras.vigentes.servicios.visitas',
                meta: {title: 'Obras - Visitas'},
                redirect: { name: 'obras.vigentes.servicios.visitas.listado' },
                component: () => import('../../pages/obras/servicios/indexVisitas').then(m => m.default || m),
                children: [
                    {
                        path: '',
                        name: 'obras.vigentes.servicios.visitas.listado',
                        meta: {title: 'Obras - Visitas - Listado'},
                        component: () => import('../../pages/obras/servicios/visitas').then(m => m.default || m),
                    },
                    {
                        path: ':id_visita/ver',
                        name: 'obras.vigentes.servicios.visitas.ver',
                        meta: {title: 'Obras - Visita - Ver'},
                        component: () => import('../../pages/obras/servicios/verVisita').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'documentacion',
                name: 'obras.vigentes.servicios.documentacion',
                meta: {title: 'Obras - Documentación'},
                component: () => import('../../pages/obras/servicios/documentacion').then(m => m.default || m),
            },
            {
                path: 'orden_de_compra',
                name: 'obras.vigentes.orden.compra',
                meta: {title: 'Obras - Orden de compra'},
                component: () => import('../../pages/obras/servicios/ordenCompra').then(m => m.default || m),
            },
            {
                path: 'calendario',
                name: 'obras.vigentes.calendario',
                meta: {title: 'Obras - Calendario'},
                component: () => import('../../pages/obras/servicios/calendario').then(m => m.default || m),
            },
            {
                path: 'forma_de_pago',
                name: 'obras.vigentes.forma.pago',
                meta: {title: 'Obras - Forma de pago'},
                component: () => import('../../pages/obras/servicios/formaPago').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/obras/vigentes/:id_proyecto/servicios/:id_control_piso/orden_de_compra/:id_orden_compra',
        name: 'obras.vigentes.orden.compra.ver',
        meta: {title: 'Obras - Servicios - Orden de compra - Ver'},
        component: () => import('../../pages/obras/servicios/verOdenCompra').then(m => m.default || m),
    },
    {
        path: '/obras/vigentes/:id',
        name: 'obras.vigentes.ver',
        redirect: { name: 'obras.vigentes.ver.informacion'},
        component: () => import('../../pages/obras/ver/index').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                name: 'obras.vigentes.ver.informacion',
                meta: {title: 'Obras - Ver - Información'},
                component: () => import('../../pages/obras/ver/informacion').then(m => m.default || m),
            },
            {
                path: 'equipos',
                name: 'obras.vigentes.ver.equipos',
                meta: {title: 'Obras - Ver - Equipos'},
                component: () => import('../../pages/obras/ver/equipos').then(m => m.default || m),
            },
            {
                path: 'cotizaciones',
                name: 'obras.vigentes.ver.cotizaciones',
                meta: {title: 'Obras - Ver - Cotizaciones'},
                component: () => import('../../pages/obras/ver/cotizaciones').then(m => m.default || m),
            },
            {
                path: 'ordenes_de_compra',
                name: 'obras.vigentes.ver.orden.compra',
                meta: {title: 'Obras - Ver - Ordenes de compra'},
                component: () => import('../../pages/obras/ver/ordenesCompra').then(m => m.default || m),
            },
            {
                path: 'servicios',
                name: 'obras.vigentes.ver.servicios',
                meta: {title: 'Obras - Ver - Servicios'},
                component: () => import('../../pages/obras/ver/servicios').then(m => m.default || m),
            },
            {
                path: 'control_de_obra',
                name: 'obras.vigentes.ver.control.obra',
                meta: {title: 'Obras - Ver - Control de obra'},
                component: () => import('../../pages/obras/ver/controlObra').then(m => m.default || m),
            },
            {
                path: 'galeria',
                name: 'obras.vigentes.ver.galeria',
                meta: {title: 'Obras - Ver - Galeria'},
                component: () => import('../../pages/obras/ver/galeria').then(m => m.default || m),
            },
            {
                path: 'documentacion',
                name: 'obras.vigentes.ver.documentacion',
                meta: {title: 'Obras - Ver - Documentación'},
                component: () => import('../../pages/obras/ver/documentacion').then(m => m.default || m),
            },
            {
                path: 'forma_de_pago',
                name: 'obras.vigentes.ver.forma.pago',
                meta: {title: 'Obras - Ver - Forma de pago'},
                component: () => import('../../pages/obras/ver/formaPago').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/obras/vigentes/:id/configuracion/:id_configuracion/:tabla',
        name: 'obras.vigentes.ver.configuracion.materiales',
        meta: {title: 'Obras - Ver - Configuracion'},
        component: () => import('../../pages/obras/ver/verConfiguracion').then(m => m.default || m),
    },
]
