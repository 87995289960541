import { UseCase } from "~/domain/core/UseCase";
import { Equipos } from '~/services/obras/equipos/equipo'


class DeleteEquipoUseCase extends UseCase{
    static async execute(payload){
        await Equipos.deleteEquipo(payload)
    }
}

export default DeleteEquipoUseCase.execute