import { UseCase } from '~/domain/core/UseCase'
import StockMaterials from '~/services/almacen/stock'
import { $try } from '~/services/global'

class GetMaterialHistoryUseCase extends UseCase {
    static async execute(params){
        const { id } = params
        const data = await StockMaterials.getMaterialHistory({id})
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetMaterialHistoryUseCase.execute
