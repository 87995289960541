import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { CountryFactory } from '~/domain/entities/configurar/paises/factories/CountryFactory'
import { limitText } from '~/domain/helpers/string'
import { $try } from '~/services/global'

class GetSelectCountriesUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectCountries()
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) 
                return data.data.map(({ pais,...rest }) => CountryFactory.instantiate({ pais: limitText(pais, 28), ...rest}))
        })
    }
}

export default GetSelectCountriesUseCase.execute