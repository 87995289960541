<template>
    <section>
        <div class="row justify-content-center mx-0">
            <div class="col-12 col-lg-11">
                <div class="pills overflow-hidden w-100 rounded-pill bg-f5">
                    <template v-if="tooltip">
                        <el-tooltip placement="top" v-for="(element, index) in iterable" :key="index">
                            <template #content>
                                 <div class="text-center f-10 px-3">
                                    <p class="mb-1">{{ element[label] }}</p>
                                    <p>{{ element[counter] }} servicios</p>
                                </div>
                                <slot name="tooltip" :row="element"/>
                            </template>
                            <div class="pill" :style="`background: ${colors[index]}; left:${getPosition(index)}%; width:${getPercentage(element[counter], index)}%;`" />
                        </el-tooltip>
                    </template>
                    <template v-else v-for="(element, index) in iterable">
                        <div class="pill" :key="index" :style="`background: ${colors[index]}; left:${getPosition(index)}%; width:${getPercentage(element[counter], index)}%;`" />
                    </template>
                </div>
            </div>
        </div>
        <div v-if="labels" class="row justify-content-center mt-3 mx-0">
            <div class="col-12 col-lg-11 d-flex justify-content-between">
                <div class="d-middle" v-for="(service, index) in iterable" :key="index">
                    <div class="circle wh-12 rounded-circle" :style="`background: ${colors[index]}`"/>
                    <p class="f-12 ml-2">{{ service[label] }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        iterable: {
            type: Array,
            required: true,
            default: () => [],
        },
        colors: {
            type: Array, 
            default: () => ['#FC5C65', '#FD9644', '#FED330', '#26DE81', '#22A6B3', '#975BFF', '#FF5BCE']
        },
        tooltip: {
            type: Boolean,
            default: false
        },
        labels: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'name'
        },
        counter: {
            type: String,
            default: 'count'
        }
    },
    computed:{
        total(){
            return this.iterable.reduce((acc, curr) => acc + curr.servicios_vigentes, 0)
        }
    },
    methods: {
        getPosition(index){
            return this.iterable.reduce((acc, curr, key) => {
                if(key >= index) return acc
                return acc + ((curr.servicios_vigentes * 100) / this.total) 
            },0) 
        },
        getPercentage(value, index){
            return (value * 100) / this.total
        }
    }
}
</script>

<style lang="scss" scoped>
.pills{
    height: 10px;
    position: relative;
    @for $servicio from 1 through 7 {
        .pill:nth-child(#{$servicio}) {
            z-index: 10 - $servicio !important;
        }
    }
    .pill{
        height: 10px;
        position: absolute;
    }
}
</style>