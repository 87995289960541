import * as Cartera from  "~/domain/use_cases/cobro_cartera"
import { $try } from "~/services/global"
import { PagoFilterFactory } from '~/domain/filters/cobroCartera/factories/PagoFilterFactory'
import { CobroFilterFactory } from '~/domain/filters/cobroCartera/factories/CobroFilterFactory'

export const state = {
    filtroPagar: [],
    filtroCobrar: [],
    graficaPagar:[],
    graficaCobrar:[],
    pagar: {
        data: [],
        pagination: {
            current_page: 1
        },
    },
    cobrar: {
        data: [],
        pagination: {
            current_page: 1
        },
    },
    filterPago: PagoFilterFactory.instantiateEmpty(),
    filterCobro: CobroFilterFactory.instantiateEmpty(),
    query: ''
}

export const getters = {
    filtroPagar: state => state.filtroPagar,
    filtroCobrar: state => state.filtroCobrar,
    graficaPagar: state => state.graficaPagar,
    graficaCobrar: state => state.graficaCobrar,
    pagar: state => state.pagar,
    paginationPagar: state => state.pagar.pagination,
    cobrar: state => state.cobrar,
    paginationCobrar: state => state.cobrar.pagination,
    filterPago: state => state.filterPago,
    filterCobro: state => state.filterCobro,
    query: state => state.query
}

export const mutations = {
    set_filtro_pagar(state, payload){
        state.filtroPagar = payload
    },
    set_filtro_cobrar(state, payload){
        state.filtroCobrar = payload
    },
    set_grafica_pagar(state, payload){
        state.graficaPagar = payload
    },
    set_grafica_cobrar(state, payload){
        state.graficaCobrar = payload
    },
    set_pagar(state, payload){
        state.pagar = payload
    },
    set_cobrar(state, payload){
        state.cobrar = payload
    },
    set_filter_pago(state, payload){
        state.filterPago = payload
    },
    set_filter_cobro(state, payload){
        state.filterCobro = payload
    },
    set_pagination_cobrar(state, payload){
        state.cobrar.pagination = payload
    },
    set_pagination_pagar(state, payload){
        state.pagar.pagination = payload
    },
    set_query(state, payload) {
        state.query = payload
    }
}

export const actions = {
    async Action_get_pagar({state, commit, getters}, payload){
        const buscar = state.query
        const filterP = state.filterPago.clean()
        const page = getters.paginationPagar.current_page ?? 1
        const data = await Cartera.GetPagarUseCase({...filterP, page, buscar})
        $try( () => { commit('set_pagar', data) })
    },
    async Action_get_filtro_pagar({commit}, payload){
        const data = await Cartera.GetFiltroPagarUseCase(payload)
        $try( () => { commit('set_filtro_pagar', data) })
    },
    Action_clear_filter_pagar({ state, commit }){
        const rangeValor = {
            min: state.filtroPagar.valor_min,
            max: state.filtroPagar.valor_max
        }
        const rangeDias = {
            min: state.filtroPagar.dias_vencimiento_min,
            max: state.filtroPagar.dias_vencimiento_max
        }
        commit('set_filter_pago', PagoFilterFactory.instantiateEmptyWithRange(rangeValor, rangeDias))
    },
    async Action_get_cobrar({state, commit, getters}, payload){
        const buscar = state.query
        const filterC = state.filterCobro.clean()
        const page = getters.paginationCobrar.current_page ?? 1
        const data = await Cartera.GetCobrarUseCase({...filterC, page, buscar})
        $try( () => { commit('set_cobrar', data) })
    },
    async Action_get_filtro_cobrar({commit}, payload){
        const data = await Cartera.GetFiltroCobrarUseCase(payload)
        $try( () => { commit('set_filtro_cobrar', data) })
    },
    Action_clear_filter_cobrar({ state, commit }){
        const rangeValor = {
            min: state.filtroCobrar.valor_min,
            max: state.filtroCobrar.valor_max
        }
        const rangeDias = {
            min: state.filtroCobrar.dias_vencimiento_min,
            max: state.filtroCobrar.dias_vencimiento_max
        }
        commit('set_filter_cobro', CobroFilterFactory.instantiateEmptyWithRange(rangeValor, rangeDias))
    },
    async Action_get_grafica_pagar({commit}, payload){
        const data = await Cartera.GetGraficaPagarUseCase(payload)
        $try( () => { commit('set_grafica_pagar', data) })
    },
    async Action_get_grafica_cobrar({commit}, payload){
        const data = await Cartera.GetGraficaCobrarUseCase(payload)
        $try( () => { commit('set_grafica_cobrar', data) })
    }
}