import { UseCase } from '~/domain/core/UseCase'
import Services from "~/services/configurar/talleres/tipos_servicios"
import { $try } from '~/services/global'

class CreateServicesWorkshopUseCase extends UseCase {
    static async execute(payload){
        const data = await Services.createServicesWorkshop(payload)
        return $try(() => {
			if(data?.data) return data.data
        })
    }
}

export default CreateServicesWorkshopUseCase.execute