import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { ActividadListaFactory } from '~/domain/value_objects/obras/factories/actividadListaFactory'
import { ListaChequeoFactory } from '~/domain/value_objects/obras/factories/listaChequeoFactory'
import { $try } from '~/services/global'

class GetSelectListaChequeoUseCase extends UseCase {
    static async execute(){
        const data =  await Selects.GetSelectListaChequeosObras()
        return $try(() => {
            if(!data?.data) return []

            return data.data.map(({actividades, ...rest}) => ListaChequeoFactory.instantiate({
                actividades: actividades.map(ActividadListaFactory.instantiate),
                ...rest
            }))
        })
    }
}

export default GetSelectListaChequeoUseCase.execute
