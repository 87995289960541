import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class UpdateStageUseCase extends UseCase {
    static async execute(params){
        const { id, ...payload } = params
        const data = await Stages.updateStage({id, payload})
        return $try(() => {
            return data?.data ?? {}
        })
    }
}

export default UpdateStageUseCase.execute