import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { $try } from '~/services/global'

class DeleteAdditionalDataTypeUseCase extends UseCase {
    static async execute(params){
        await AdditionalData.deleteAditionalDataTypes(params)
    }
}

export default DeleteAdditionalDataTypeUseCase.execute