import { PurchaseOrderMaterial } from "../PurchaseOrderMaterial";

export class PurchaseOrderMaterialFactory {
    static instantiate(obj){
        return new PurchaseOrderMaterial(obj)
    }

    static instantiateEmpty(){
        return new PurchaseOrderMaterial({
            id: '',
            referencia: '',
            id_tipo_material: '',
            id_moneda: '',
            tipo_material: '',
            valor_unitario: '',
            total_unidades: '',
            unidades_orden: '',
        })
    }
}