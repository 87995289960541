import { UseCase } from "~/domain/core/UseCase";
import ViewInfoProvider from '~/services/proveedores/ver/ver'
import { $try } from '~/services/global'

class GetProviderFilesUseCase extends UseCase {
    static async execute(params){
        const { id_proveedor } = params
        const data = await ViewInfoProvider.obtenerArchivosProveedor({id_proveedor})
        return $try(() => data?.data ?? [])
    }
}

export default GetProviderFilesUseCase.execute
