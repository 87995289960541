import { UseCase } from "~/domain/core/UseCase";
import { PresenciaEquipos } from '~/services/ventas/presenciaEquipos'
import { ListarRangosFactory } from '~/domain/entities/ventas/factories/ListarRangosFactory'
import { $try } from '~/services/global'

class getRangosUseCase extends UseCase {
    static async execute(){
        const data = await PresenciaEquipos.getRangos()
        return $try(() => {
            if(data?.data) return new ListarRangosFactory.instantiate(data.data)
        })
    }
}

export default getRangosUseCase.execute