import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class GetStateConfDetailsUseCase extends UseCase {
    static async execute(params){
        const { id, id_etapa,...payload } = params
        const data = await Stages.getConfigurationDetail({id, id_etapa, payload})
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetStateConfDetailsUseCase.execute