import { CotizacionOption } from "../CotizacionOption";

export class CotizacionOptionFactory {
    static instantiate(obj){
        return new CotizacionOption(obj)
    }

    static instantiateEmpty(){
        return new CotizacionOption({  
            estados: [],
            materiales_min: '',
            materiales_max: '',
            valor_min: '',
            valor_max: '',
            fecha_cotizacion_min: '',
            fecha_cotizacion_max: '',
            dias_credito_min: '',
            dias_credito_max: ''
        })
    }
}