import Api from '~/services/api'

export const Category = {
    getCategories({ filter, page }){
        const Categories = new Api(); 
        return Categories.get('almacen/categorias-materiales', {...filter, page });
    },
    getCategoryRanges(){
        const Ranges = new Api();
        return Ranges.get(`almacen/categorias-materiales/rangos-filtros`);
    },
    createCategory(payload){
        const Category = new Api({ notification: true });
        return Category.post('almacen/categorias-materiales', payload);
    },
    updateCategory(payload){
        const { id, ...rest } = payload
        const Category = new Api({ notifcation: true });
        return Category.put(`almacen/categorias-materiales/${id}`, rest);
    },
    deleteCategory(id){
        const Category = new Api({ notification: true });
        return Category.delete(`almacen/categorias-materiales/${id}`);
    },
    updateFavoriteProvider(id, payload){
        const Provider = new Api();
        return Provider.put(`almacen/categorias-materiales/${id}/agregar-proveedor`, payload);
    }

}