import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { MaterialFactory } from '~/domain/entities/compras/matriz/factories/MaterialFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetConfigurationMaterialsUseCase extends UseCase {
    static async execute(params){
        const data =  await Matriz.getConfigurationMaterials(params)
        return $try(() => {
            if(data?.data) 
                return new PaginationDecorator(data.data, MaterialFactory.instantiate);
        })
    }
}

export default GetConfigurationMaterialsUseCase.execute