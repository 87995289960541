import { CotizacionesFilter } from "../cotizacionesFilter";

export class cotizacionesFilterFactory {
    static instantiate(obj){
        return new CotizacionesFilter(obj)
    }

    static instantiateEmpty(){
        return new CotizacionesFilter({
            buscar: '',
            id_proveedor: '',
            materiales_no_cotizados: null,
            materiales_cotizados: null,
            id_tipo_material: '',
            ordena_proveedor_por: null,
        })
    }
}