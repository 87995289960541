import { UseCase } from '~/domain/core/UseCase'
import Service from '~/services/configurar/talleres/tipos_servicios'

class UpdateServicesWorkshopUseCase extends UseCase {
    static async execute(payload){
        const data = await Service.updateServicesWorkshop(payload)
        return data?.data?.id
    }
}

export default UpdateServicesWorkshopUseCase.execute