import { UseCase } from "~/domain/core/UseCase";
import { ListaChequeo } from '~/services/obras/servicios/lista_chequeo'

class EditListaChequeoUseCase extends UseCase {
    static async execute(params){
        const data =  await ListaChequeo.editCheckListies(params)
        return data?.message
    }
}

export default EditListaChequeoUseCase.execute
