import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { MaterialesConfiguracionFactory } from '~/domain/entities/oportunidades/information/factories/MaterialesConfiguracionFactory'
import { $try } from '~/services/global'

class GetMaterialesConfiguracionUseCase extends UseCase {

    static async execute(payload){
        const data =  await Opportunities.getMaterialesConfiguracion(payload)

        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    MaterialesConfiguracionFactory.instantiate
                ).toTable();
            }
        })
    }
}

export default GetMaterialesConfiguracionUseCase.execute