<template>
    <span class="text-danger f-10 d-block">
        {{text}}
    </span>
</template>

<script>
export default {
    name: 'VeeError',
    props: {
        text: {
            type: String,
            default: ''
        }
    }
}
</script>
