<template>
<section>
    <titulo-divisor titulo="Archivos extras">
        <el-tooltip content="Añadir archivo" placement="bottom" effect="light" visible-arrow>
            <div class="wh-32 rounded-circle bg-5d d-middle-center icon-option-hover cr-pointer" @click="agregarArchivoCiclo">
                <i class="icon-paperclip text-white f-20" />
            </div>
        </el-tooltip>
    </titulo-divisor>
    <div class="row mx-0">
        <div v-for="(data,i) in filesList" :key="i" class="col-6 col-lg-6 mb-3">
            <div class="border br-4 d-flex tres-puntos">
                <i class="f-20" :class="typeFile(data.fileType)" />
                <p class="tres-puntos f-12 my-auto"> {{ data.fileName }} </p>
                <i class="icon-pencil text-ddd f-20 ml-auto cr-pointer" @click="cambiarNombreArchivo" />
                <i class="icon-trash-can-outline text-ddd f-20 cr-pointer" @click="eliminarArchivoExtra" />
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    props:{
        filesList: {
            type: Array,
            default: () => []
        }
    },
    methods:{
        agregarArchivoCiclo(){
            this.$emit('addFile')
        },
        cambiarNombreArchivo(){
            this.$emit('changeFileName')
        },
        eliminarArchivoExtra(){
            this.$emit('deleteFile')
        },
        typeFile(type){
            switch (type) {
                case 0:
                    return 'icon-pdf text-red'
                    break;
                case 1:
                    return 'icon-image text-success'
                    break;
            
                default: 
                    break;
            }
        }
    }

}
</script>

<style>

</style>