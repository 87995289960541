import { UseCase } from "~/domain/core/UseCase";
import { Compras } from '~/services/almacen/compras'
import { CompraFactory } from '~/domain/entities/almacen/materiales/compras/factories/CompraFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetCompraUseCase extends UseCase{
    static async execute(id, payload){
        const data = await Compras.getComprasMateriales(id, payload)
        return $try(() => {
            if(data?.data) 
                return new PaginationDecorator(data.data, CompraFactory.instantiate);
        })
    }
}

export default GetCompraUseCase.execute