

import { Entity } from '~/domain/entities'

export class OpportunityConfiguration extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['id', 'id_almacen_etapas', 'almacen_etapas', 'almacen_etapas_configuraciones'],
        });
    }
}