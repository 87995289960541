import { UseCase } from "~/domain/core/UseCase"
import RolesAreasList from "~/services/configurar/permisos/rolesAreas"

class GetRolesAreasUseCase extends UseCase {
    static async execute(params){
        const data = await RolesAreasList.listRolesAreas(params)
        if(data?.data){
            return data.data;
        }
    }
}

export default GetRolesAreasUseCase.execute