import { render, staticRenderFns } from "./adjuntosGeneral.vue?vue&type=template&id=0ac8f1cb&scoped=true&"
import script from "./adjuntosGeneral.vue?vue&type=script&lang=js&"
export * from "./adjuntosGeneral.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ac8f1cb",
  null
  
)

export default component.exports