import * as Compras from '~/domain/use_cases/almacen/compras'
import { CompraFilterFactory } from '~/domain/filters/almacen/materiales/compras/factories/CompraFilterFactory'
import { $try } from '~/services/global'

export const state = {
    compras: {
        data: [],
        pagination: {
            current_page: 1
        },
    },
    filtro_compras: [],
    filter: CompraFilterFactory.instantiateEmpty(),
    query: ''
}

export const getters = {
    compras: state => state.compras.data,
    pagination: state => state.compras.pagination,
    filtro_compras: state => state.filtro_compras,
    filter: state => state.filter,
    query: state => state.query
}

export const mutations = {
    set_compras(state, payload){
        state.compras = payload
    },
    set_filtro_compras(state, payload){
        state.filtro_compras = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_query(state, payload){
        state.query = payload
    },
    set_pagination(state, payload){
        state.compras.pagination = payload
    }
}

export const actions = {
    async Action_get_compras({state, commit, getters}, id){
        const buscar = state.query
        const filter = state.filter.clean()
        const page = getters.pagination.total > 15 ? getters.pagination.current_page : 1
        const data = await Compras.GetCompraUseCase(id, {...filter, buscar, page})
        if (data) commit('set_compras', data)
    },
    async Action_get_filtro_compras({commit}, id){
        const data = await Compras.GetFiltroCompraUseCase(id)
        $try( () => { commit('set_filtro_compras', data) })
    },
    Action_clear_filter({ state, commit }){
        const rango_unidades = {
            min: state.filtro_compras.unidades_min,
            max: state.filtro_compras.unidades_max
        }
        const rango_valores = {
            min: state.filtro_compras.valor_min,
            max: state.filtro_compras.valor_max
        }
        commit('set_filter', CompraFilterFactory.instantiateEmptyWithRange(rango_unidades, rango_valores))
    }
}