import * as CheckListDomain from '~/domain/use_cases/configurar/obras/check_list'
import * as ActivitiesCheckListDomain from '~/domain/use_cases/configurar/obras/check_list_activities'

import { $try } from '~/services/global'
import { CheckListFactory } from '~/domain/entities/configurar/obras/check_list/factories/CheckListFactory.js'
import { ActivitiesCheckListFactory } from '~/domain/entities/configurar/obras/check_list/factories/ActivitiesCheckListFactory.js'

export const state = {
    checkLists: [],
    checkList: CheckListFactory.instantiateEmpty(),
    editCheckList: CheckListFactory.instantiateEmpty(),
    activitiesCheckList: [],
    activityCheckList: ActivitiesCheckListFactory.instantiateEmpty()
}

export const getters = {
    checkLists: state => state.checkLists,
    checkList: state => state.checkList,
    editCheckList: state => state.editCheckList,

}

export const mutations = {
    set_check_list(state, payload){
        state.checkList = payload
    },
    set_check_lists(state, payload){
        state.checkLists = payload
    },
    set_activity_check_list(state, payload){
        state.activitiesCheckList = payload
    },
}

export const actions = {
    async ACTION_GET_CHECK_LISTS({ commit }){
        const checkLists = await CheckListDomain.GetCheckListUseCase()
        $try(() => {
            if(checkLists) commit('set_check_lists', checkLists);
        });
    },
    async ACTION_CREATE_CHECK_LIST({ commit }){
        const checkList = await CheckListDomain.CreateCheckListUseCase(state.checkList)
        $try(() => {
            commit('set_check_lists',checkList)   
            state.checkList = CheckListFactory.instantiateEmpty()
        })
    },
    async ACTION_EDIT_CHECK_LIST({ state, commit }){
        const checkList = await CheckListDomain.EditCheckListUseCase(state.editCheckList)
        $try(() => {
            if(checkList) commit('set_check_lists', state.checkLists.map(e => e.id === checkList.id ? checkList : e))
        })
    },
    async ACTION_DELETE_CHECK_LIST({ commit }, payload) {
        const data = await CheckListDomain.DeleteCheckListUseCase(payload)
        $try(() => {
            if(data) {
                const edited = state.checkLists.filter(e => e.id != payload)
                commit('set_check_lists', edited) 
            }
          
        });
    },
    // actividades lista
    async ACTION_CREATE_ACTIVITY_CHECK_LIST({ state, commit }, payload){
        const activity = await ActivitiesCheckListDomain.CreateActivityCheckListUseCase(payload)
        $try(() => {
            if(activity) {
                const checkList = state.checkLists.map(check => {
                    if(check.id != payload.id_lista_chequeo) return check
                    return ({
                        ...check,
                        actividades: [...check.actividades, activity]
                    })
                })
                commit('set_check_lists',checkList) 
                state.activityCheckList = ActivitiesCheckListFactory.instantiateEmpty()
            }
        })
    },
    async ACTION_EDIT_ACTIVITY_CHECK_LIST({ state, commit}, {obj, indexList, indexAct}){
        const activity = await ActivitiesCheckListDomain.EditActivityCheckListUseCase(obj)
        $try(() => {
            if(activity) {
                const edited = _.clone( state.checkLists )
                edited[indexList].actividades[indexAct] = activity
                commit('set_check_lists', edited) 
            }
        })
    },
    async ACTION_DELETE_ACTIVITY({ state, commit }, { id_configuracion_lista_chequeo, id_actividad,  indexList, indexAct }) {
        const data = await ActivitiesCheckListDomain.DeleteActivityCheckListUseCase(id_actividad)
        if(data) {
            const edited = _.clone( state.checkLists )
            edited[indexList].actividades.splice(indexAct,1)
            commit('set_check_lists', edited) 
        }
    },
    async ACTION_UPDATE_POSITION_ACTIVITY({ commit }, payload){
        const activityCheckList = await ActivitiesCheckListDomain.UpdatePositionActivitiesCheckListUseCase(payload)
    },
    async ACTION_UPDATE_POSITION_CHECK_LIST({ commit }, payload){
        const checkList = await ActivitiesCheckListDomain.UpdatePositionChekListUseCase(payload)
    },
}