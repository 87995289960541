import { UseCase } from "~/domain/core/UseCase";
import { Providers } from '~/services/proveedores/proveedores'
import { ProviderFactory } from '../../entities/proveedores/factories/ProviderFactory'


class EditProviderUseCase extends UseCase {
    static async execute(params){
        const { id, ...payload } = params
        const data = Providers.updateProvider({ id, payload })

        if(data?.data) return ProviderFactory.instantiate(data.data)
    }
}

export default EditProviderUseCase.execute