import { BodegasFilter } from "../bodegasFilter";

export class BodegasFilterFactory {
    static instantiate(obj){
        return new BodegasFilter(obj)
    }

    static instantiateEmpty(){
        return new BodegasFilter({
            buscar: '',
            nombre: '',
            valor_inicial: 0,
            valor_final: 0,
            fecha_inicial_ultima_salida: null,
            fecha_final_ultima_salida: null,
            fecha_inicial_ultima_entrada: null,
            fecha_final_ultima_entrada: null,
            pais: '',
            estado: '',
            tipo_material: [],
            cantidad_inicial: 0,
            cantidad_final: 0,
        })
    }

    static instantiateEmptyWithRange(rangeVal, rangeCant){
        const valor_inicial = rangeVal?.[0] ?? 0
        const valor_final = rangeVal?.[1] ?? 0
        const cantidad_inicial = rangeCant?.[0] ?? 0
        const cantidad_final = rangeCant?.[1] ?? 0

        const filter = new BodegasFilter({
            buscar: '',
            nombre: '',
            valor_inicial,
            valor_final,
            fecha_inicial_ultima_salida: null,
            fecha_final_ultima_salida: null,
            fecha_inicial_ultima_entrada: null,
            fecha_final_ultima_entrada: null,
            pais: '',
            estado: '',
            tipo_material: [],
            cantidad_inicial,
            cantidad_final,
        })

        filter.setExceptions({
            valor_inicial,
            valor_final,
            cantidad_inicial,
            cantidad_final,
        })

        return filter
    }
}