import { Filter } from '~/domain/core/Filter';

export class PurchaseOrderListFilter extends Filter {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                'id_oportunidad',
                'proveedores',
                'id_cliente',
                'fecha_inicio',
                'fecha_fin',
                'valor_inicial',
                'valor_final',
                'estado',
                'estado_pago'
            ]
        });
    }
}