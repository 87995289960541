import * as CotizacionesDomain from '~/domain/use_cases/cotizaciones'
import {$try} from '~/services/global'
import { vigentesFilterFactory } from '~/domain/filters/cotizaciones/factories/vigentesFilterFactory'
// state
export const state = {
    cotizacionesVigentes: [],
    cotizacionesEnviadas: [],
    filter: vigentesFilterFactory.instantiateEmpty(),
    estados:[]
}

// getters
export const getters = {
    getCotizacionesVigentes: state => state.cotizacionesVigentes,
    getListCotizacionesEnviadas: state => state.cotizacionesEnviadas,
    filter: state => state.filter,
    estados: state => state.estados,
}

// mutations
export const mutations = {
    set_cotizaciones_vigentes(state, payload){
        state.cotizacionesVigentes = payload
    },
    set_cotizaciones_enviadas(state, payload){
        state.cotizacionesEnviadas = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_estados(state, payload){
        state.estados = payload
    },
}

// actions
export const actions = {

    async Action_get_cotizaciones({commit}){
        const cotizaciones = await CotizacionesDomain.GetCotizacionVigente(state.filter)
        $try(() => {
            commit('set_cotizaciones_vigentes', cotizaciones)
        });
    },
    async Action_get_cotizaciones_enviadas({commit}, payload){
        const cotizaciones = await CotizacionesDomain.GetCotizacionEnviada()
        $try(() => {
            commit('set_cotizaciones_enviadas', cotizaciones)
        });
    },
    async Action_get_estados({commit}){
        const estados = await CotizacionesDomain.GetEstadoCotizacionUseCase()
        $try(() => {
            commit('set_estados', estados)
        });
    },
    Action_clear_filter({ state, commit }){
        commit('set_filter', vigentesFilterFactory.instantiateEmpty())
    },
}