import { UseCase } from "~/domain/core/UseCase"
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'

class GetSelectAllEquiposModelosUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectAllEquiposModelos()
        return $try(() => {
            if(data?.data) return data?.data
            return []
        })
    }
}

export default GetSelectAllEquiposModelosUseCase.execute;