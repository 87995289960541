import { UseCase } from "~/domain/core/UseCase";
import { Personalizacion } from '~/services/personalizacion'
import { $try } from '~/services/global'

class getPersonalizacionUseCase extends UseCase {
    static async execute(){
        const  data  =  await Personalizacion.getPersonalizacion()
        return $try(() =>  data ?? {})
    }
}

export default getPersonalizacionUseCase.execute