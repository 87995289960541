<template>
    <div class="ring text-white f-20 d-middle-center rounded-circle position-relative" :style="`width:${wh}px;height:${wh}px; background: ${bg};`">
        <span :class="size">
            {{percentage}}%
        </span>
        <svg class="progress-ring">
            <circle class="progress-ring__circle" :cx="`${wh/2}`" :cy="`${wh/2}`" :r="`${(wh/2)-5}`" />
        </svg>
    </div>
</template>

<script>
export default {
    props:{
        percentage: {
            required: true
        },
        size:{
            type: String,
            default: 'small'
        },
        wh:{
            type: String,
        },
        bg:{
            type: String,
            default: 'var(--color-general)'
        }
    },
    data() {
        return {
            radiusC: null
        }
    },
    mounted(){
        this.setPorcentajeCirculo(this.percentage)
    },
    watch:{
        percentage(val){
            this.setPorcentajeCirculo(val)
        }
    },
    methods:{
        setPorcentajeCirculo(val){
            var circle = document.querySelector('circle');
            var radius = circle.r.baseVal.value;
            var circumference = radius * 2 * Math.PI;
            circle.style.strokeDasharray = `${circumference} ${circumference}`;
            circle.style.strokeDashoffset = `${circumference}`;
            const offset = circumference - val / 100 * circumference;
            circle.style.strokeDashoffset = offset;
        },
    }
}

</script>

<style lang="scss" scoped>
.ring{
    width:64px;
    height:64px;
    .progress-ring{
        position: absolute;
        width: 100%;
        height: 100%;
        &__circle{
            stroke: #FFF;
            stroke-width: 2;
            fill: transparent;
            transition-delay: 0.3s;
            animation-direction: normal;
            transition: 0.35s stroke-dashoffset;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
        }
    }
}

.small{
    font-size: 12px;
}

.medium{
    font-size: 14px;
}

.large{
    font-size: 16px;
}
</style>
