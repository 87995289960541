import { PermisoRuta } from "../PermisoRuta";

export class PermisoRutaFactory {
    static instantiate(obj){
        return new PermisoRuta(obj)
    }

    static instantiateEmpty(){
        return new PermisoRuta({
            id_menu: '',
            id_rol: '',
            id_permiso: ''
        })
    }
}