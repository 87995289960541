import { UseCase } from '~/domain/core/UseCase'
import { PurchaseOrder } from '~/services/obras/servicios/forma_pago';
import { $try } from '~/services/global';

class GetPaymentMethodResumeUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso }){
        const data = await PurchaseOrder.getPaymentMethodResume(id_proyecto, id_control_piso)
        return $try(() => {
            if(!data?.data) return {}
            return ({
                total_pagado: parseFloat(data.data.total_pagado),
                total_valor_pagar: parseFloat(data.data.total_valor_pagar),
                fecha_pago: data.data.fecha_pago
            })
        })
    }
}

export default GetPaymentMethodResumeUseCase.execute