import { ValueObject } from '~/domain/core/ValueObject'

export class AdditionalDataTypeVO extends ValueObject{
    constructor(obj){
        super({
            params: obj,
            keys: ['valor', 'id_configuracion_datos_adicionales', 'id','posicion']
        })
        this.edit = false
    }
}