import Api from '~/services/api'

export const Proveedores = {
    getDetalleCotizacion(idProveedor, idCotizacion){
        const Provider = new Api()
        return Provider.get(`/proveedores/${idProveedor}/cotizaciones/${idCotizacion}`)
    },
    getDetalleCotizacionMateriales(idProveedor, idCotizacion, payload){
        const Provider = new Api()
        return Provider.get(`/proveedores/${idProveedor}/cotizaciones/${idCotizacion}/materiales`, payload)
    }
}