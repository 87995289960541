import { UseCase } from "~/domain/core/UseCase";
import { Documents } from "~/services/talleres/ver_documentos";
import { FileFactory } from "~/domain/entities/talleres/factories/FileFactory";
import { $try } from "~/services/global";

class UpdateFileNameUseCase extends UseCase {
    static async execute({ id_taller,  id_folder, id_file, nombre }) {
        const data = await Documents.updateFileName(id_taller, id_folder, id_file, nombre);
        return $try(() => {
            if(data.data){
                return FileFactory.instantiate(data.data)
            }
        })
    }
}

export default UpdateFileNameUseCase.execute