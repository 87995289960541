import { UseCase } from '~/domain/core/UseCase'
import { Category } from '~/services/almacen/categorias/categorias'

class DeleteCategoryUseCase extends UseCase {
    static async execute(id){
        const data = await Category.deleteCategory(id);

        return data?.message
    }
}

export default DeleteCategoryUseCase.execute