import { Entity } from '~/domain/entities'

export class CalendarActivity extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'nombre',
                'id_configuracion_calendarios',
                'actividades',
                'id',
                'posicion'
            ]
        });
    }
}