export default [
    {
        path: '/talleres',
        name: 'talleres.main',
        meta: {title: 'Talleres'},
        component: () => import('../../pages/talleres/index').then(m => m.default || m),
    },
    {
        path: '/talleres/:id',
        name: 'talleres.ver',
        meta: {title: 'Talleres'},
        redirect: {name: 'talleres.ver.informacion'},
        component: () => import('../../pages/talleres/verTaller').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                name: 'talleres.ver.informacion',
                meta: { title: 'Taller - informacion' },
                component: () => import('../../pages/talleres/verInformacion').then(m => m.default || m),
            },
            {
                path: 'documentos',
                name: 'talleres.ver.documentos',
                meta: { title: 'Taller - documentos' },
                component: () => import('../../pages/talleres/verDocumentos').then(m => m.default || m),
                children: [
                    {
                        path: ':id_folder/archivos',
                        name: 'talleres.ver.documentos.archivos',
                        meta: { title: 'Taller - documentos - archivos' },
                        component: () => import('../../pages/talleres/listadoArchivos').then(m => m.default || m),
                    }
                ]
            },
        ]
    },
]
