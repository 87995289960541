import { UseCase } from "~/domain/core/UseCase"
import Auth from "~/services/auth/auth"

class LogoutProveedoresUseCase extends UseCase {
    static async execute(){
        const data = await Auth.logoutProveedores()
        return data?.message
    }
}

export default LogoutProveedoresUseCase.execute
