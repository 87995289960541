import { UseCase } from "~/domain/core/UseCase";
import MaterialTypes from "~/services/configurar/almacen/tipos_material"
import { $try } from '~/services/global'

class addPositionMaterialTypesUseCase extends UseCase {
    static async execute(params){
        const data = await MaterialTypes.addPositionMaterialTypes(params)
        
        return $try(() => data?.data)
    }
}

export default addPositionMaterialTypesUseCase.execute