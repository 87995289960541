import { UseCase } from "~/domain/core/UseCase"
import  { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { FiltroOrdenesComprasFactory } from '~/domain/value_objects/almacen/pedidos/factories/FiltroOrdenesComprasFactory'
import { $try } from '~/services/global'

class GetFiltroOrdenesComprasUseCase extends UseCase {
    static async execute(){
        const data = await PedidosInternosOcs.getFiltroOrdenesCompra()
        return $try(() => {
            return data.data ? FiltroOrdenesComprasFactory.instantiate(data.data): null 
        })

    }
}

export default GetFiltroOrdenesComprasUseCase.execute