<template>
    <el-input 
    v-model="value" :type="type" :size="size" :placeholder="placeholder" class="w-100" 
    @input="$emit('input', $event)"
    />
</template>

<script>
export default {
    props: {
        model: { type: Object },
        size: { type: String, default: 'small' },
        type: { type: String, default: 'text' },
        prop: { type: String, default: 'valor' },
        placeholder: { type: String, default: '' },
    },
    data(){
        return {
            value: ''
        }
    },
    watch:{
        model(val){
            this.value = val?.hasOwnProperty(this.prop) ? val[this.prop] : '';
        }
    },
    created(){
        this.value = this.model && this.model.hasOwnProperty(this.prop) ? this.model[this.prop] : '';
    },
    methods:{
        clear(){
            this.value = null
        },
    }
}
</script>