import { Entity } from '~/domain/entities'

export class Equipo extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                "id",
                "foto",
                "nombre",
                "precio",
                "numero_etapas",
                "materiales",
                "horas_hombre",
                "descripcion",
                "etapas", 
                "id_oportunidad",
                "id_producto",
                "id_proyecto"

            ]
        })
    }
}


