import { ServiceFilter } from "../ServiceFilter";

export class ServiceFilterFactory {
    static instantiate(obj){
        return new ServiceFilter(obj)
    }

    static instantiateEmpty(){
        return new ServiceFilter({
            id_servicio: null,
            fecha_inicio: '',
            fecha_fin: '',
            valor_min: 0,
            valor_max: 0,
        })
    }

    static instantiateEmptyWithRange(range){
        const valor_min = range?.min ?? 0
        const valor_max = range?.max ?? 0
        
        const service = new ServiceFilter({
            id_servicio: null,
            fecha_inicio: '',
            fecha_fin: '',
            valor_min,
            valor_max,
        })

        service.setExceptions({
            valor_min,
            valor_max,
        })

        return service
    }
}