import * as NotificacionEmail from '~/domain/use_cases/configurar/proveedores'
import {$try} from '~/services/global'

// state
export const state = {
    emailProveedores: [],
}

// getters
export const getters = {
    emailProveedores: state => state.emailProveedores,
}

// mutations
export const mutations = {
    set_email_proveedores(state, providers){
        state.emailProveedores = providers
    },

}

// actions
export const actions = {
    async Action_get_notificacion_email_pvs({commit}, payload){
        const providers = await NotificacionEmail.GetEmailNotificationProvidersUseCase()
        $try(() => { 
            commit('set_email_proveedores', providers)
        });
    },
    async Action_post_notificacion_email_pvs({state, commit}, payload){
        const providers = await NotificacionEmail.PostEmailNotificationProvidersUseCase(payload)
        console.log("ladaatatata", providers);
        $try(() => { 
            if (providers) {
                let proveedores = state.emailProveedores
                proveedores.push(providers)
                commit('set_email_proveedores', proveedores)
            }
        });
    },
    async Action_delete_email({commit}, payload){
        return await NotificacionEmail.DeleteEmailUseCase(payload)
    },
    async Action_put_notificacion_email_pvs({state, commit}, payload){
        const providers = await NotificacionEmail.PutEmailNotificationProvidersUseCase(payload)
        $try(() => { 
            if (providers) {
                let pvs = state.emailProveedores.filter(e => e.id != providers.id)
                pvs.push(providers)
                commit('set_email_proveedores', pvs)
            }
        });
    },
    async Action_delete_proveedor({commit}, payload){
        return await NotificacionEmail.DeleteProveedorUseCase(payload)
    },

}