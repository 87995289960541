import { UseCase } from '~/domain/core/UseCase'
import { Category } from '~/services/almacen/categorias/categorias'

class UpdateCategoryUseCase extends UseCase {
    static async execute(payload){
        const data = await Category.updateCategory(payload)
        return data?.data?.id
    }
}

export default UpdateCategoryUseCase.execute