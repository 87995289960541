import { Filter } from '~/domain/core/Filter';

export class ProveedoresFilter extends Filter{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'buscar',
                'ciudad',
                'id_proveedor',
                'id_responsable'
            ]
        });
    }
}