import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { ResponsibleFactory } from '~/domain/entities/oportunidades/information/factories/ResponsibleFactory'
import { $try } from '~/services/global'

class GetOpportunityResponsibleUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getResponsiblesOpportunity(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(type => ResponsibleFactory.instantiate(type))
        })
    }
}

export default GetOpportunityResponsibleUseCase.execute