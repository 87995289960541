import { MaterialFilter } from "../MaterialFilter";

export class MaterialFilterFactory {
    static instantiate(obj) {
        return new MaterialFilter(obj);
    }

    static instantiateEmpty() {
        return new MaterialFilter({
            id_tipo_material: null,
            unidades_min: 0,
            unidades_max: 0,
            referencia: null,
        });
    }

    static instantiateEmptyWithRange(range) {
        const unidades_min = range?.min ?? 0
        const unidades_max = range?.max ?? 0

        const filter = new MaterialFilter({
            id_tipo_material: null,
            unidades_min,
            unidades_max,
            referencia: null,
        });

        filter.setExceptions({
            unidades_min, 
            unidades_max,
        })

        return filter;
    }

}