import { Permisos } from "../Permisos";

export class PermisoFactory {
    static instantiate(obj){
        return new Permisos(obj)
    }

    static instantiateEmpty(){
        return new Permisos({
            id_rol: '',
            id_permiso: ''
        })
    }
}