import * as NumeroIdentificacionDomain from '~/domain/use_cases/obras/informacion_header'

import { $try } from '~/services/global'

export const state = {
    identificacion: [],
}

export const getters = {
    identificacion: state => state.identificacion,
}

export const mutations = {
    setIdentificacion(state, payload){
        state.identificacion = payload
    },
}

export const actions = {
    async Action_post_numero_identificacion({commit}, payload){
        const data = await NumeroIdentificacionDomain.CreateNumberIdentificationUseCase(payload)
        $try ( () => { commit('setIdentificacion', data) } )
    },
}