import { CategoryMaterials } from "../CategoryMaterials";

export class CategoryMaterialsFactory {
    static instantiate(obj){
        return new CategoryMaterials(obj);
    }
    static instantiateEmpty(){
        return new CategoryMaterials({
            idMaterialConfig: null,
            unidades: null,
            id: null,
            referencia: '',
            nombre: '',
            descripcion: '',
            estado: null,
            id_unidad_medida: null,
            id_tipo_material: null,
            created_at: '',
            created_by: '',
            tipo: 0,
            unidad_medida: null,
            valor_cotizado: 0,
            mantenimiento: [],
            mantenimientos: []
        })
    }
}