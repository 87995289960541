import { DomainObject } from "~/domain/core/DomainObject";

export class Entity extends DomainObject {
    constructor({params: obj, keys: KEYS}){
        super({
            params: obj,
            keys: KEYS
        })
    }
    map(format = {}){
        if(typeof format != 'object') throw Error("El formato pasado no es un error");
        let obj = new Object();
        Object.entries(this).forEach(([key, value]) => {
            const prop = format[key] ?? key
            obj[prop] = value;
        })
        return obj
    }
}