import { UseCase } from '~/domain/core/UseCase'
import StockMaterials from '~/services/almacen/stock'
import { RangoStockMaterialesFactory } from '~/domain/entities/almacen/stock/factories/RangoStockMaterialesFactory'
import { $try } from '~/services/global'

class GetRangoUnidadesMaterialesUseCase extends UseCase {
    static async execute(){
        const data = await StockMaterials.getRangoValoresStock()
        return $try(() => {
            return data.data ? RangoStockMaterialesFactory.instantiate(data.data): null
        })
    }
}

export default GetRangoUnidadesMaterialesUseCase.execute
