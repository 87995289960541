import Api from '~/services/api'


const UnitMeasure = {
    async listUnitMeasure(){
        const Service = new Api()
        return Service.get('/select/unidades-medida')
    },
    createUnitMeasure({payload}){
        const Service = new Api({notification: true})
        return Service.post('/configuraciones/almacen/unidad-medidas', payload)
    },
    addPosUnitMeasure(payload){
        const Service = new Api({notification: true})
        return Service.post('/configuraciones/almacen/unidad-medidas/posicion', payload)
    },
    editUnitMeasure({id, payload}){
        const Service = new Api({notification: true})
        return Service.put(`/configuraciones/almacen/unidad-medidas/${id}`, payload)
    },
    deleteUnitMeasure(id){
        const Service = new Api({notification: true})
        return Service.delete(`/configuraciones/almacen/unidad-medidas/${id}`)
    },

}

export default UnitMeasure