import { UseCase } from '~/domain/core/UseCase';
import { Resumen } from '~/services/oportunidades/productos/resumen';
import { PercentageIncreaseResumeFactory } from '~/domain/entities/oportunidades/productos/factories/PercentageIncreaseResumeFactory';
import { $try } from '~/services/global';

class SavePercentageIncreaseUseCase extends UseCase {
    static async execute(params) {
        const data = await Resumen.savePercetangeIncrease(params);

        return $try(() => { 
            if(data?.data){
                return PercentageIncreaseResumeFactory.instantiate(data.data)
            }
        })
    }
}

export default SavePercentageIncreaseUseCase.execute