import { MaterialesConfiguracion } from "../MaterialesConfiguracion";

export class MaterialesConfiguracionFactory {
    static instantiate(obj){
        return new MaterialesConfiguracion(obj)
    }
    static instantiateEmpty() {
        return new MaterialesConfiguracion({
            id: '',
            cantidad: '',
            nombre: '',
            referencia: '',
            sigla: '',
            tabla: '',
            planes_mantenimiento: '',
            id_oportunidades_equipos_modelos_etapas_configuracion: '',
            tipo_configuracion: '',
            id_oportunidades_equipos_modelos_etapas_materiales: '',
            id_plan_mantenimiento: '',
            tipo_material: '',
            unidad_medida: '',
            valor_cotizado: ''
        });
    }
}