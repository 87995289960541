import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { CategoryMaterialsFactory } from '~/domain/entities/almacen/categorias/factories/CategoryMaterialsFactory'
import { $try } from '~/services/global'

class GetTablesInformationUseCase extends UseCase {
    static async execute(id_etapa, id_configuration, payload) {
        const data = await Stages.getTableInfo(id_etapa, id_configuration, payload)
        return $try(() => {
            return new PaginationDecorator(data.data, CategoryMaterialsFactory.instantiate);
        })
    }
}

export default GetTablesInformationUseCase.execute