import { UseCase } from "~/domain/core/UseCase"
import Auth from "~/services/auth/auth"
import { $try } from '~/services/global'

class LoginUseCase extends UseCase {
    static async execute(params){
        const data = await Auth.login(params)
        return $try(() => {
            return data?.token
        })
    }
}

export default LoginUseCase.execute
