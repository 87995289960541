import { UseCase } from "~/domain/core/UseCase"
import { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { $try } from "~/services/global"

class ReportarPagoUseCase extends UseCase {
    static async execute(id, payload) {
        const data = await PedidosInternosOcs.reportarPago(id, payload)
        
        return $try(() => data?.data)
    }
}

export default ReportarPagoUseCase.execute;
