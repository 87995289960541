import { Filter } from '~/domain/core/Filter';

export class PedidoMaterialFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_categoria',
                'id_material',
                'id_espesor',
                'id_tipo_material',
                'cantidad_min',
                'cantidad_max',
                'id_estado',
                'id_proveedor'
            ]
        });
    }
}