import { UseCase } from "~/domain/core/UseCase"
import Auth from "~/services/auth/auth"

class LogoutUseCase extends UseCase {
    static async execute(){
        const data = await Auth.logout()
        return data?.mensaje
    }
}

export default LogoutUseCase.execute
