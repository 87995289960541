import * as CarpetasDomain from '~/domain/use_cases/configurar/obras/carpetas'

import { $try } from '~/services/global'
// state
export const state = {
    carpetas: [],
}

// getters
export const getters = {
    carpetas: state => state.carpetas,
}

// mutations
export const mutations = {
    set_carpetas(state, carpetas){
        state.carpetas = carpetas
    }
}

// actions
export const actions = {

    async Action_get_carpetas_obras({ commit }){
        const carpetas  = await CarpetasDomain.GetFoldersUseCase()  
        $try (
            () => commit('set_carpetas', carpetas)
        )
    },
    async Action_create_carpetas_obras({ state, commit }, payload){
        const carpeta = await CarpetasDomain.CreateFolderUseCase(payload)
        $try (() => {
            if(carpeta) commit('set_carpetas', [...state.carpetas, carpeta])
        })
    },
    async Action_edit_carpetas_obras({ state, commit }, payload){
        const carpeta = await CarpetasDomain.EditFolderUseCase(payload)
        $try (() => {
            if(carpeta) commit('set_carpetas', state.carpetas.map(item => item.id == carpeta.id ? carpeta : item))
        })
    },
    async Action_delete_carpetas_obras({ state, commit }, payload){
        const data = await CarpetasDomain.DeleteFolderUseCase(payload)
        $try (() => {
            if(data) commit('set_carpetas', state.carpetas.filter(item => item.id != payload.id))
        })
    },
}