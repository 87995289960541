import Api from '~/services/api'


const ViewInfoProvider = {
    obtenerDetallesProveedor({id_proveedor}){
        const Provider = new Api()
        return Provider.get(`/proveedores/proveedores/${id_proveedor}`)
    },
    subirArchivoProveedor({id_proveedor, payload}){
        const UploadFile = new Api({notification: true})
        return UploadFile.post(`/proveedores/proveedores/${id_proveedor}/archivos`, payload)
    },
    obtenerArchivosProveedor({id_proveedor}){
        const Provider = new Api()
        return Provider.get(`/proveedores/proveedores/${id_proveedor}/archivos`)
    },
    obtenerMaterialesProveedor({id_proveedor, payload}){
        const Provider = new Api()
        return Provider.get(`/proveedores/${id_proveedor}/materiales`, payload)
    },
    obtenerCotizacionesProveedor({id_proveedor, payload}){
        const Provider = new Api()
        return Provider.get(`/proveedores/${id_proveedor}/cotizaciones`, payload)
    },
    obtenerOrdenesCompraProveedor({id_proveedor, payload}){
        const Provider = new Api()
        return Provider.get(`/proveedores/${id_proveedor}/ordenes_de_compra`, payload)
    },
}

export default ViewInfoProvider