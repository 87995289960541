import { Entity } from '~/domain/entities'

export class ValorMaterial extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                "id",
                "materiales",
                "proveedor",
                "index"
            ]
        })
    }
}