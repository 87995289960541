import { UseCase } from "~/domain/core/UseCase";
import { VisitasService } from '~/services/obras/servicios/visitas'

class EndVisitUseCase extends UseCase {
    static async execute(params){
        const data =  await VisitasService.endVisit(params)
        if (data?.data) {
            return data?.data
        }
    }
}

export default EndVisitUseCase.execute
