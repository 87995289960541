<template>
    <span class="text-danger f-11">
        <span class="f-600">* </span>
        <span v-if="text" class="f-500">{{ text }}</span>
    </span>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ''
        }
    }
}
</script>