import { UseCase } from "~/domain/core/UseCase"
import { Calendario } from "~/services/calendario/calendario"
import { EquiposFactory } from "~/domain/entities/calendario/factories/EquiposFactory"
import { $try } from "~/services/global"

class GetEquiposUseCase extends UseCase{
    static async execute(){
        const data = await Calendario.getEquipos()
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => EquiposFactory.instantiate(e))
        })
    }
}

export default GetEquiposUseCase.execute