import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { MaterialVOFactory } from '~/domain/value_objects/almacen/pedidos/factories/MaterialVOFactory'
import { $try } from '~/services/global'

class GetSelectMateriales2UseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectMateriales2(payload)
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => MaterialVOFactory.instantiate(e))
        })
    }
}

export default GetSelectMateriales2UseCase.execute