<template>
    <div class="section-top">
        <div class="d-middle header-top" style="height: 65px;">
            <div class="col-auto my-auto d-flex align-items-center">
                <div class="caja-modulo d-middle justify-center bg-general">
                    <i class="text-white" :class="icon" />
                </div>
                <div class="d-middle mx-0 text-general f-18 f-600">
                    <p class="pl-2">
                        <Breadcumbs/>
                    </p>
                </div>
            </div>
            <div class="my-auto d-flex col-auto ml-auto justify-content-end align-items-center">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavbarAdmin',
    props:{
        titulo: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'icon-menu'
        }
    },
    data() {
        return {
            tituloModulo: '',
        }
    },
    mounted(){
      
    },
    methods: {
        
    },
}
</script>
<style lang="scss" scoped>
.section-top{
    // margin-left: 65px;
    height: 65px;
    position: fixed;
    top: 65px;
    left: 54px;
    width: calc(100vw - 54px);
    background: white;
    z-index:12;
}
</style>
