import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { ClientVOFactory } from '~/domain/value_objects/clientes/factories/ClientVOFactory'
import { $try } from '~/services/global'

class GetSelectClientsUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectClients()
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(client => ClientVOFactory.instantiate(client))
        })
    }
}

export default GetSelectClientsUseCase.execute