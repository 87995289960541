import { Entity } from '~/domain/entities'

export class FiltroCobrar extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'proyectos',
                'valor_min',
                'valor_max',
                'dias_vencimiento_min',
                'dias_vencimiento_max'
            ]
        })
    }
}