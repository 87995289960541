import { UseCase } from '~/domain/core/UseCase'
import { Equipos } from '~/services/almacen/equiposModelos'
import { EquipoFactory } from '~/domain/entities/almacen/equipos_modelos/factories/equipoFactory'
import { $try } from '~/services/global'

class GetEtapasConfiguracionesUseCase extends UseCase {
    static async execute(payload){
        const data = await Equipos.getEtapasConfiguraciones(payload)
        return $try(() => {
            if(!Array.isArray(data?.data)) return []
            return data.data.map(EquipoFactory.instantiate)
        })
    }
}

export default GetEtapasConfiguracionesUseCase.execute