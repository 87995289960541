import { UseCase } from "~/domain/core/UseCase";
import { Cotizacion } from '~/services/obras/cotizaciones/cotizaciones'
import { CalificacionFactory } from '~/domain/entities/configurar/obras/calificacion_servicio/factories/calificacionFactory'
import { $try } from '~/services/global'
class GetCotizacionesUseCase extends UseCase {
    static async execute(params) {
        const data = await Cotizacion.getMaterials(params)
        return data.data
    }
}

export default GetCotizacionesUseCase.execute