import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { $try } from '~/services/global'

class PutActualizarValorHorasHombreUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.putActualizarValorHorasHombre(payload)
        return $try( () => data?.data?.message ?? null )
    }
}

export default PutActualizarValorHorasHombreUseCase.execute