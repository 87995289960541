import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { $try } from '~/services/global'

class PutRestablecerValorHorasHombreUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.putRestablecerValorHorasHombre(payload)
        return $try( () => data?.data?.message ?? null )
    }
}

export default PutRestablecerValorHorasHombreUseCase.execute