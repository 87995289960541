import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { MaterialFactory } from '~/domain/entities/compras/matriz/factories/MaterialFactory'
import { $try } from '~/services/global'

class GetWarehouseMaterialQuantityUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data } =  await Matriz.getWarehouseMaterialQuantity(params)

        $try(() => {
            if(data.length){
                data.forEach((item) =>{
                    customData.push(
                        MaterialFactory.instantiate(item))
                })
            }
        })

        return customData
    }
}

export default GetWarehouseMaterialQuantityUseCase.execute