import { Entity } from '~/domain/entities'

export class Cotizacion extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [  
                'id',
                'nombre',
                'cantidad_cotizaciones',
                'cotizaciones',
            ]
        });
    }
}