import store from '~/store'


export default async(to, from, next) => {
    const token = store.getters['auth/token'];

    if(token) await store.dispatch('auth/FETCH_USER');

    const user = store.getters['auth/user'];

    if (user)  return next({ name: 'home' });

    return next();
}
