import { FiltroPagar } from "../FiltroPagar";

export class FiltroPagarFactory {
    static instantiate(obj){
        return new FiltroPagar(obj)
    }

    static instantiateEmpty(){
        return new FiltroPagar({
            tipos: '',
            proveedores: '',
            estado: '',
            valor_min: '',
            valor_max: '',
            dias_vencimiento_min: '',
            dias_vencimiento_max: ''
        })
    }
}