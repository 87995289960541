import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { $try } from '~/services/global'

class GetInfoCicloOpportunityUseCase extends UseCase {
    static async execute(payload){
        const data = await Opportunities.getInfoCiclosOpportunity(payload)
        return $try( () => data?.data ?? {})
    }
}

export default GetInfoCicloOpportunityUseCase.execute