import { UseCase } from '~/domain/core/UseCase'
import { Material } from "~/services/almacen/categorias/materiales"

class GetCategoryMaterialsRangeUseCase extends UseCase{
    static async execute(category_id){
        const data = await Material.getCategoryMaterialsRange(category_id)
        if(data?.data?.valor){
            return [
                parseInt(data.data.valor?.[0] ?? 0),
                parseInt(data.data.valor?.[1] ?? 0),
            ]
        }
    }
}

export default GetCategoryMaterialsRangeUseCase.execute
