

export class DomainObject {
    constructor({params: obj, keys: KEYS}){
        const objKeys = Object.keys(obj)
        const name = this.constructor?.name

        if(objKeys.length){
            const hasValidKeys = (objKeys.length == KEYS.length) && 
            (objKeys.every(key => KEYS.indexOf(key) != -1))

            if(!hasValidKeys){
                const iterable = objKeys.length > KEYS.length ? objKeys : KEYS
                const mapped = new Object();

                iterable.sort().forEach((el) => {
                    mapped[el] = {
                        enviada: objKeys.sort().includes(el) ? el : '-', 
                        esperada: KEYS.sort().includes(el) ? el : '-'
                    }
                })

                const error = `El objecto no es del tipo ${name?.replace('','')}`

                console.groupCollapsed(error);
                    console.table(mapped);
                console.groupEnd();

                // throw Error(error); 
            }
        } 

        objKeys.forEach(key => this[key] = obj[key] ?? null)
    }
}