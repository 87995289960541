<template>
    <section>
        <slot name="top"/>
        <div ref="drag" class="drag border br-10 cr-pointer drag my-2 mx-auto d-middle-center w-fit-content text-general" alt="logo" @click="$refs.inputFile.click()">
            <i class="icon-image f-80" />
        </div>
        <input ref="inputFile" type="file" class="d-none" name="inputFile" @change="uploadFilesController">
        <slot name="bottom"/>
        <div class="col-lg-12 my-3 text-center">
            <p v-for="(file, index) in fileList" :key="index" class="f-12">
                {{ file.name }}
                <i class="icon-ok-circled-outline f-12 text-success" /> 
                <i :ref="`file-${index}`" class="icon-close-circle f-12 cr-pointer" @click="removeFile(index)" /> 
            </p>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        multiple: { type: Boolean, default: false }
    },
    data(){
        return {
            fileList: new Array()
        }
    },
    watch: {
        fileList: {
            handler(val){
                this.$emit('input', val)
            },
            deep: true
        }
    },
    mounted() {
        const drag = this.$refs.drag
        drag.ondragover = drag.ondragenter = (e) => e.preventDefault()
        drag.ondrop = this.dropController
    },
    methods:{
        uploadFilesController(e){
            let arr = Array.from(e.target.files)
            
            if(!this.multiple) this.fileList = [arr[0]]

            arr.forEach(file => {
                if(!this.fileList.filter(fu => fu.name === file.name).length) 
                    this.fileList.push(file)
            })

            this.$emit('input', this.fileList)
        },
        dropController(e){
            e.preventDefault()
            const data = e.dataTransfer.files[0]
            
            if(this.multiple) this.fileList = new Array()

            if(this.fileList.indexOf(data) != -1) 
                return this.$notify({title: 'El archivo ya se encuentra en la lista', type:'info'})

            this.fileList.push(data)
            this.$emit('input', this.fileList)
        },
        removeFile(index){
            this.fileList.splice(index,1)
        }
    }
}
</script>


<style scoped>
.f-80{
    font-size: 80px;
}
</style>