import { UseCase } from "~/domain/core/UseCase";
import { Work } from '~/services/configurar/obras/obras'
import { FolderFactory } from '~/domain/entities/configurar/obras/carpetas/factories/FolderFactory'
import { $try } from '~/services/global'

class EditFolderUseCase extends UseCase {
    static async execute(params){
        const data =  await Work.editFolder(params)

        return $try(() => {
            if(data?.data) {
                const { created_at, created_by, updated_at, updated_by, deleted_at, ...rest } = data.data
                return FolderFactory.instantiate(rest)                
            }
        })
    }
}

export default EditFolderUseCase.execute