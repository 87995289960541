import { CalendarSubactivityVO } from "../CalendarSubactivityVO";

export class CalendarSubactivityVOFactory {
    static instantiate(obj){
        return new CalendarSubactivityVO(obj)
    }

    static instantiateEmpty(){
        return new CalendarSubactivityVO({
            id:'',
            nombre: '',
            created_at: '',
            duracion_dias: '',
            dia_inicio: '',
            id_actividad_predecesora: '',
            descripcion: '',
            posicion: '',
            color:'',
            id_configuracion_calendarios_actividades:''
        })
    }
}