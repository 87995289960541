import { UseCase } from '~/domain/core/UseCase';
import { Resumen } from '~/services/oportunidades/productos/resumen';
import { $try } from '~/services/global';

class DeletePercentageIncreaseUseCase extends UseCase {
    static async execute(id) {
        const data = await Resumen.removePercetangeIncrease(id);

        return $try(() => data?.message);
    }
}

export default DeletePercentageIncreaseUseCase.execute;