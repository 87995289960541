import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { EquiposVOFactory } from '~/domain/value_objects/opportunity/factories/equiposVOFactory'
import { $try } from '~/services/global'

class GetSelectOppotunityEquiposUseCase extends UseCase {
    static async execute(){
        const { data } = await Selects.GetSelectOpportunityEquipos()
        return $try(() => {
            if(data && Array.isArray(data)) return data.map(type => EquiposVOFactory.instantiate(type))
        })
    }
}

export default GetSelectOppotunityEquiposUseCase.execute