import { UseCase } from "~/domain/core/UseCase"
import { Calendario } from "~/services/calendario/calendario"
import { CalendarioSemanaFactory } from "~/domain/entities/calendario/factories/CalendarioSemanaFactory"
import { $try } from "~/services/global"

class getCalendarioSemanaUseCase extends UseCase{
    static async execute(payload){
        const data = await Calendario.getCalendarioSemana(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => CalendarioSemanaFactory.instantiate(e))
        })
    }
}

export default getCalendarioSemanaUseCase.execute