import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { OpportunityTypeVOFactory } from '~/domain/value_objects/opportunity/factories/opportunityTypeVOFactory'
import { $try } from '~/services/global'

class GetSelectOpportunityTypeUseCase extends UseCase {
    static async execute(){
        const { data } = await Selects.GetSelectOpportunityType()
        return $try(() => {
            if(data && Array.isArray(data)) return data.map(type => OpportunityTypeVOFactory.instantiate(type))
        })
    }
}

export default GetSelectOpportunityTypeUseCase.execute