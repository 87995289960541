import { Filter } from '~/domain/core/Filter'

export class ProviderMaterialFilter extends Filter{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'fecha_compra_inicio',
                'fecha_compra_fin',
                'fecha_cotizacion_inicio',
                'fecha_cotizacion_fin',
                'valor_cotizado_inicio',
                'valor_cotizado_fin',
                'valor_compra_inicio',
                'valor_compra_fin',
            ]
        })
    }
}