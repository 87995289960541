import { Entity } from '~/domain/entities'

export class Service extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'calificacion',
                'configuracion',
                'created_at',
                'descripcion',
                'estado',
                'fecha_finalizado',
                'id',
                'id_almacen_etapas_configuraciones',
                'id_almacen_etapas_configuraciones_secciones',
                'id_taller',
                'orden_compra',
                'porcentaje',
                'proyecto',
                'seccion',
                'valor',
            ]
        })
    }
}