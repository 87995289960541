import { UseCase } from "~/domain/core/UseCase";
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'
import { UtilidadPaisFactory } from '~/domain/entities/cotizaciones/cotizaciones_clientes/factories/utilidadPaisFactory'
import { $try } from '~/services/global'

class GetQuotationCountryUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CotizacionCliente.getUtilidadPaises(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new UtilidadPaisFactory.instantiate(e)) 
        })
    }
}

export default GetQuotationCountryUseCase.execute