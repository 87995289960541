<template>
    <modal ref="modalAgregarArchivos" titulo="Añadir archivo" adicional="Añadir" @adicional="guardarArchivo">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-14">
                <div class="col-12 mb-3">
                    <p class="input-label-top">Nombre del archivo</p>
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                        <el-input v-model="modelUploadFile.fileName" placeholder="Nombre del archivo" size="small" class="w-100"  />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-12 mb-4">
                    <ValidationProvider v-slot="{ errors }" name="tipo" rules="required" tag="div" class="d-flex">
                        <el-radio v-model="modelUploadFile.fileType" :label="1">PDF</el-radio>
                        <el-radio v-model="modelUploadFile.fileType" :label="2">Imagen</el-radio>
                        <el-radio v-model="modelUploadFile.fileType" :label="3">Video</el-radio>
                        <el-radio v-model="modelUploadFile.fileType" :label="4">Otros</el-radio>
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-12 px-0">
                    <upload-button ref="uploadButton" :fileEdit="fileEdit" :fileType="modelUploadFile.fileType" v-model="filesToUpload" />
                </div>
                <div class="col-12">
                    <p v-if="message" class="f-10 mt-2">{{ message }}</p>
                </div>
                <div class="col-12">
                    <vee-error :text="fileError"></vee-error>
                </div>
                <div v-if="viewFile" class="col-12 mt-4 mb-3">
                    <p class="input-label-top f-13">Visualizar archivo</p>
                    <ValidationProvider v-slot="{ errors }" name="visualizar" rules="required">
                        <el-radio v-model="modelUploadFile.radioVisualizar" :label="1">Si</el-radio>
                        <el-radio v-model="modelUploadFile.radioVisualizar" :label="0">No</el-radio>
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    props: {
        viewFile: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            fileName:'',
            fileType: 4,
            radioVisualizar: 1,
            filesToUpload: [],
            modelUploadFile:{
                fileName: '',
                fileType: 4,
                radioVisualizar: 1,
                filesToUpload: [],
                idFile: null
            },
            editing: false,
            fileEdit: [],
            fileError: ''
        }
    },
    watch:{
        filesToUpload: {
            handler(val){
                this.fileError = ''
                this.filesToUpload = val
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            uploadFiles: 'proveedores/ver/ver/Action_upload_provider_file'
        }),
        toggle(data){
            this.cleanForm();
            if (data) {
                this.editing = true
                this.fileEdit = [data.nombre, data.archivo]
                this.modelUploadFile = {
                    fileName: data.nombre,
                    fileType: data.id_tipo,
                    radioVisualizar: data.visualizador_archivo,
                    idFile: data.id
                }
            }
            this.$refs.modalAgregarArchivos.toggle()
        },
        async guardarArchivo(){
            const valid = await this.$refs.validator.validate();
            if(this.filesToUpload.length == 0) return this.fileError = 'Debe seleccionar un archivo'

            if(!valid) return;
                
            this.fileError = ''
            const file = this.filesToUpload[0]
            this.modelUploadFile.filesToUpload = file

            this.$emit("saveFile", [this.modelUploadFile, this.editing])
        },
        cleanForm(){
            this.$refs.validator.reset();
            this.$refs.uploadButton.cleanFileList()
            this.modelUploadFile = {
                fileName: '',
                fileType: 4,
                radioVisualizar: 1,
            }
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
