import { PaymentMethod } from '../PaymentMethod';

export class PaymentMethodFactory {
    static instantiate(obj) {
        return new PaymentMethod(obj);
    }

    static instantiateEmpty(){
        return new PaymentMethod({
            id: null,
            fecha_pago: '',
            porcentaje: 0,
            valor: 0,
            tipo: 1,
        })
    }
}