<template>
<div  class="carpeta border br-8 p-2 d-flex mb-3" @click="$emit('action')">
    <div class="d-middle tres-puntos flex-fill">
        <i class="icon-folder f-20" />
        <p class="tres-puntos f-12 pr-2"> {{ folderName }} </p>
    </div>
    <div class="cant-archivos bg-general rounded-pill px-2 text-center text-white f-12 my-auto d-middle-center">
        {{ cantFiles }}
    </div>
    <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
        <template #content>
            <p class="px-1 py-2 cr-pointer item" @click="editFolder">Editar</p>
            <p class="px-1 py-2 cr-pointer item" @click="deleteFolder">Eliminar</p>
        </template>
        <i class="icon-dots-vertical my-auto f-20" />
    </el-tooltip>
</div>

</template>

<script>
export default {
    props:{
        folderName:{
            type: String,
            required: true
        },
        cantFiles:{
            type: Number,
            required: true,
            default: 0
        }
    },
    data(){
        return{

        }
    },
    methods:{
        editFolder(){
            this.$emit('editFolder')
        },
        deleteFolder(){
            this.$emit('deleteFolder')
        },
    }

}
</script>

<style lang="scss" scoped>
.carpeta{
    .cant-archivos{
        height: 16px;
    }
    &.selected{
        background: var(--color-general);
        color: #fff !important;
        cursor: pointer;
        .cant-archivos{
            background: white;
            color: var(--color-general) !important;
        }
    }
    &:hover{
        background: var(--color-general);
        color: #fff !important;
        cursor: pointer;
        .cant-archivos{
            background: white;
            color: var(--color-general) !important;
        }
    }
}
</style>