import { UseCase } from '~/domain/core/UseCase'
import { Category } from '~/services/almacen/categorias/categorias'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { CategoryFactory } from '~/domain/entities/almacen/categorias/factories/CategoryFactory'

class GetCategoryListUseCase extends UseCase{
    static async execute(payload){
        const data = await Category.getCategories(payload);

        if(data?.data){
            return new PaginationDecorator(
                data.data,
                CategoryFactory.instantiate
            );
        }
    }
}

export default GetCategoryListUseCase.execute