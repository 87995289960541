import { PedidoInternoFilter } from "../PedidoInternoFilter"

export class PedidoInternoFilterFactory {
    static instantiate(obj){
        return new PedidoInternoFilter(obj)
    }

    static instantiateEmpty(){
        const filter = new PedidoInternoFilter({
            id_solicitante: '',
            id_proyecto: '',
            id_equipo: '',
            id_equipo_modelo: '',
            fecha_solicitud_hasta: '',
            fecha_solicitud_desde: '',
            fecha_llegada_hasta: '',
            fecha_llegada_desde: ''
        })
        return filter
    }
}