import { Entity } from '~/domain/entities'

export class OrdenCompra extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                "id",
                "nombre",
                "id_tipo_material",
                "tipo_material",
                "total_unidades",
                "ordenes_compra"
            ]
        })
    }
}