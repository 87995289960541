import Api from '~/services/api'

export const Matriz = {
    //
    getGroups(filters){
        const ApiHandler = new Api()
        return ApiHandler.get('/ordenes-compras/grupos', filters)
    },
    //
    getOpportunities(){
        const ApiHandler = new Api()
        return ApiHandler.get(`ordenes-compras/oportunidades`)
    },
    //
    getOpportunityProducts({id_oportunidad}){
        const ApiHandler = new Api()
        return ApiHandler.get(`ordenes-compras/oportunidades/${id_oportunidad}/equipos`)
    },
    //
    getProductStages({id_ops, id_producto, tabla}, payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ordenes-compras/oportunidades/${id_ops}/${id_producto}/etapas`, {tabla})
    },
    //
    getStageConfiguration(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`ordenes-compras/configuraciones`, { data: payload })
    },
    //
    getConfigurationMaterials(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`ordenes-compras/configuraciones/almacen-materiales`, { data: payload.configuraciones, page: payload.page })
    },
    //
    getProviders(){
        const ApiHandler = new Api()
        return ApiHandler.get(`ordenes-compras/proveedores`)
    },
    //
    getProviderMaterialValue(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ordenes-compras/proveedores-valor-material`, payload)
    },
    //
    getWarehouses(){
        const ApiHandler = new Api()
        return ApiHandler.get(`ordenes-compras/bodegas`)
    },
    //
    getWarehouseMaterialQuantity(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`ordenes-compras/bodegas-cantidad-material`, payload)
    },
    //
    createBuyOrder(payload){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post('/ordenes-compras', payload)
    },
    //
    getOpportunityConfigurations({id_oportunidad, id_producto}){
        const ApiHandler = new Api()
        return ApiHandler.get(`oportunidades/vigentes/${id_oportunidad}/productos/${id_producto}/etapas`)
    },
}