import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { PaymentFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/PaymentFactory';
import { $try } from '~/services/global'

class FinishPurchaseOrderPaymentUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso, id_orden_compra }) {
        const data = await PurchaseOrder.finishPurchaseOrderPayment(id_proyecto, id_control_piso, id_orden_compra);

        return $try(() => {
            if(data?.data) return PaymentFactory.instantiate(data.data);
        });
    }
}

export default FinishPurchaseOrderPaymentUseCase.execute;