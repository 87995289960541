import { Entrada } from "../Entrada";

export class EntradaFactory {
    static instantiate(obj){
        return new Entrada(obj)
    }

    static instantiateEmpty(){
        return new Entrada({
            mes: '',
			mes_abre: '',
			anio: '',
			cantidad: ''
        })
    }
}