export default [
    {
        path: '/oportunidades',
        name: 'oportunidades.main',
        meta: { title: 'oportunidades' },
        redirect: {name: 'oportunidades.listado.vigentes' },
        component: () => import('../../pages/oportunidades').then(m => m.default || m),
        children: [
            {
                path: 'vigentes',
                name: 'oportunidades.listado.vigentes',
                meta: {title: 'oportunidades - vigentes' },
                component: () => import('../../pages/oportunidades/listadoVigentes').then(m => m.default || m),
            },
            {
                path: 'ganadas',
                name: 'oportunidades.listado.ganadas',
                meta: {title: 'oportunidades - ganadas' },
                component: () => import('../../pages/oportunidades/listadoGanadas').then(m => m.default || m),
            },
            {
                path: 'perdidas',
                name: 'oportunidades.listado.perdidas',
                meta: {title: 'oportunidades - perdidas' },
                component: () => import('../../pages/oportunidades/listadoPerdidas').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/oportunidades/:tipo/:id',
        name: 'oportunidades.ver',
        meta: { title: 'Oportunidades' },
        redirect: {name: 'oportunidades.ver.informacion' },
        component: () => import('../../pages/oportunidades/ver').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                name: 'oportunidades.ver.informacion',
                meta: {title: 'oportunidades - vigentes' },
                component: () => import('../../pages/oportunidades/ver/informacion').then(m => m.default || m),
            },
            {
                path: 'productos',
                name: 'oportunidades.ver.productos',
                meta: {title: 'oportunidades - ganadas' },
                component: () => import('../../pages/oportunidades/ver/productos').then(m => m.default || m),
            },
            {
                path: 'cotizaciones',
                name: 'oportunidades.ver.cotizaciones',
                meta: {title: 'oportunidades - perdidas' },
                redirect: {name: 'oportunidades.ver.cotizaciones.cotizaciones'},
                component: () => import('../../pages/oportunidades/ver/cotizaciones').then(m => m.default || m),
                children: [
                    {
                        path: '',
                        name: 'oportunidades.ver.cotizaciones.cotizaciones',
                        component: () => import('../../pages/oportunidades/ver/cotizaciones/cotizaciones').then(m => m.default || m),
                    },
                    {
                        path: '',
                        name: 'oportunidades.ver.cotizaciones.materiales',
                        component: () => import('../../pages/oportunidades/ver/cotizaciones/materiales').then(m => m.default || m),
                    },
                    {
                        path: 'detalle/:id_cotizacion/:name_cotizacion',
                        name: 'oportunidades.ver.cotizaciones.ver',
                        component: () => import('../../pages/oportunidades/ver/cotizaciones/verCotizaciones').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'bitacora',
                name: 'oportunidades.ver.bitacora',
                meta: {title: 'oportunidades - perdidas' },
                redirect: {name: 'oportunidades.ver.bitacora.calendario'},
                component: () => import('../../pages/oportunidades/ver/bitacora').then(m => m.default || m),
                children:[
                    {
                        path: 'calendario',
                        name: 'oportunidades.ver.bitacora.calendario',
                        meta: {title: 'oportunidades - bitacora - calendario'},
                        component: () => import('../../pages/oportunidades/ver/bitacora/calendario').then(m => m.default || m),
                    },
                    {
                        path: 'mes',
                        name: 'oportunidades.ver.bitacora.mes',
                        meta: {title: 'oportunidades - bitacora - mes'},
                        component: () => import('../../pages/oportunidades/ver/bitacora/mes').then(m => m.default || m),
                    },
                    {
                        path: 'lista',
                        name: 'oportunidades.ver.bitacora.lista',
                        meta: {title: 'oportunidades - bitacora - listado'},
                        component: () => import('../../pages/oportunidades/ver/bitacora/lista').then(m => m.default || m),
                    },
                    {
                        path: 'semana',
                        name: 'oportunidades.ver.bitacora.semana',
                        meta: {title: 'oportunidades - bitacora - semana'},
                        component: () => import('../../pages/oportunidades/ver/bitacora/semana').then(m => m.default || m),
                    },
                ]
            },
        ]
    },
    {
        path: '/oportunidades/:tipo/:id/avanzar_ciclo/:id_ciclo_ops',
        name: 'oportunidades.ciclos.avanzar',
        meta: {title: 'oportunidades - ciclos - avanzar'},
        component: () => import('~/pages/oportunidades/ver/informacion/ciclos/avanzarCiclos').then(m => m.default || m),
    },
    {
        path: '/oportunidades/vigentes/:idOportunidad/productos/:idProducto/configuracion/:idConfiguracion/:tabla',
        name: 'oportunidades.ver.productos.config',
        component: () => import('../../pages/oportunidades/ver/productos/configurar').then(m => m.default || m),
    },
    {
        path: '/oportunidades/:tipo/:id/ciclo_finalizado/:nom_ciclo',
        name: 'oportunidades.ciclos.finalizados',
        meta: {title: 'oportunidades - ciclos'},
        component: () => import('~/pages/oportunidades/ver/informacion/ciclos/ciclosFinalizados').then(m => m.default || m),
    },
    // bitacora
   
]
