import { UseCase } from "~/domain/core/UseCase";
import { Equipos } from '~/services/obras/equipos/equipo'
import { EtapasFactory } from '~/domain/entities/obras/equipos/factory/etapasFactory'
import { $try } from '~/services/global'

class GetListarEtapasUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await Equipos.getEtapas(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new EtapasFactory.instantiate(e)) 
        })
    }
}

export default GetListarEtapasUseCase.execute