import { UseCase } from "~/domain/core/UseCase";
import PermisosAccionesList from "~/services/configurar/permisos/acciones"
import { $try } from '~/services/global'

class CreateRutaPermisoUseCase extends UseCase {
    static async execute(params){ 
        const data = await PermisosAccionesList.createRutaPermiso(params)
        return data?.data
    } 
}

export default CreateRutaPermisoUseCase.execute 


