import { Entity } from '~/domain/entities'

export class Payment extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                'id',
                'pago',
                'fecha_pago'
            ]
        });
    }
}