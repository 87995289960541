import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { PurchaseOrderFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/PurchaseOrderFactory';
import { $try } from '~/services/global'

class GetPurchaseOrdersUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso }) {
        const data = await PurchaseOrder.getPurchaseOrders(id_proyecto, id_control_piso);

        return $try(() => {
            if(!data?.data) return [];
            return data.data.map(el => PurchaseOrderFactory.instantiate({...el, descripcion: null, materiales: null}));
        });
    }   
}

export default GetPurchaseOrdersUseCase.execute;