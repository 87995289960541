import { UseCase } from "~/domain/core/UseCase";
import unitMeasure from "~/services/configurar/almacen/measure_unit"

class EditUnitMeasureTypeUseCase extends UseCase {
    static async execute(param){
        const data = await unitMeasure.editUnitMeasure(param)
        return data?.data
    }
}

export default EditUnitMeasureTypeUseCase.execute