<template>
    <el-menu default-active="2" class="el-menu-vertical-demo bg-drawer position-fixed h-100 select-none" :collapse="true" style="z-index:15;top:0;width: 52px;">
        <div class="row mx-0 justify-content-center border-bottom py-3">
            <img src="/img/generales/logo-gbp.png" class="rounded-circle obj-cover" width="30" height="30" alt="" />
        </div>
        <template v-if="!user_data || !(user_data.tipo === 4)">
            <el-menu-item class="centrar-icono border-bottom" @click="openMenu('menu')">
                <i class="el-icon-s-grid f-24" />
                <span slot="title">Menu</span>
            </el-menu-item>
            <div v-for="(favoritoFS, index) in favoritos_FS" :key="index">
                <el-menu-item class="centrar-icono" @click="redirectFS(favoritoFS.ruta)">
                    <img :src="favoritoFS.ilustrado | helper-storage-manager" style="height: 25px;" />
                    <span slot="title">{{ favoritoFS.nombre }}</span>
                </el-menu-item>
            </div>
            <div class="row mx-0 justify-center border-top">
                <i id="btn-u" class="mdi mdi-chevron-up icono-fle d-none mx-auto my-1 f-17" />
            </div>
            <!--
            <draggable id="scrollMenu" class="dragArea overflow-hidden list-group border-top d-none" :list="favoritos" group="favoritos" >
                <div v-for="(m, key) in favoritos" :key="key">
                    <can :key="key" :permiso="m.id_permiso_guardian || ''">
                        <el-menu-item class="menu-icono centrar-icono">
                            <i :class="`icono-ajuste f-21 icon-${m.icono}`" @click="redireccionar(m)" />
                            <span slot="title">{{m.alias_real ? m.alias_real : m.nombre}}</span>
                        </el-menu-item>
                    </can>
                </div>
            </draggable> -->
            <div class="row mx-0 justify-center border-top">
                <i id="btn-d" class="mdi mdi-chevron-down icono-fle mx-auto d-none my-1 f-17" />
            </div>
            <!--
            <draggable class="list-group group-trash icono-delete d-none" :list="ListTrash" group="favoritos">
                <el-menu-item class="centrar-icono d-middle justify-center item-delete">
                    <i class="mdi f-30 mdi-trash-can-outline" />
                    <span slot="title">Arrastre un menú favorito aquí
                        <br />
                        para eliminarlo del listado
                    </span>
                </el-menu-item>
            </draggable> -->
            <el-menu-item v-if="user_data.permisos_configuracion == 1 || user_data.super_admin == 1 || user_data.mostrar_configuracion == 1"  class="centrar-icono icono-confi" @click="openMenu('config')">
                <i class="el-icon-setting" />
                <span slot="title">Configuración</span>
            </el-menu-item>
        </template>

    </el-menu>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    data(){
        return {
            type: '',
			listTrash: [],
            rutaFS: process.env.VUE_APP_URL_FULL_SMART
        }
    },
    computed:{
        ...mapGetters({
            user_data: 'auth/user',
        }),
        favoritos_FS(){
            return this.user_data?.menus_fs_favoritos ?? []
        },
        actual_menu:{
            get(){
                return this.$store.getters['menu/actual_menu'];
            },
            set(val){
                this.$store.commit('menu/SET_ACTUAL_MENU', val);
            }
        },
        show: {
            get(){
                return this.$store.getters['menu/show_menu'];
            },
            set(val){
                return this.$store.commit('menu/SHOW_MENU', val);
            }
        }
    },
    methods: {
		...mapActions({
			FETCH_USER: 'auth/FETCH_USER',
			Action_save_menu_favorite: 'menu/Action_save_menu_favorite',
			Action_delete_menu_favorite: 'menu/Action_delete_menu_favorite',
			Action_change_position_menu_favorite: 'menu/Action_change_position_menu_favorite'
		}),
        openMenu(actual_menu="menu"){
            this.$store.commit('menu/CHANGE_MENU', actual_menu);
            if(this.actual_menu == actual_menu){
                setTimeout(() => document.getElementById('menu').style.display = 'none' , 300);
                this.actual_menu = '';
                return this.show = false;
            }

            document.getElementById('menu').style.display = 'block';
            this.show = true;
            this.actual_menu = actual_menu;
        },
        redirectFS(menu){
			const route = this.user_data.menu_fs.find(e => e.id === menu.id)?.ruta ?? null
            if(!route) return;
            window.open(`${this.rutaFS}${route}`, "_self")
        },
		changeFavorite(event){
			if (event.added){
				const payload = { data: event.added.element, posicion: event.added.newIndex }
				this.Action_save_menu_favorite(payload)
			}
			if (event.moved){
				const payload = { menus: this.favoritos_FS.map((el, idx) => ({ id: el.id_favorito, posicion: idx + 1 })) }
				this.Action_change_position_menu_favorite(payload)
			}
			if (event.removed){
				this.Action_delete_menu_favorite(event.removed.element.id_favorito)
			}
			this.FETCH_USER()
		},
        ...mapMutations({
            SHOW_MENU: 'menu/SHOW_MENU'
        })
    }
}
</script>
<style lang="scss" scoped>
.dragArea{
    height: calc(100vh - 144px);
    overflow: auto;
}
.permiso{
    height: calc(100vh - 220px);
    overflow: auto;
}
.icono-fle{
    color: var(--text-drawer);
}
.icono-confi{
    position: absolute;
    bottom: 2px;
    width: 100%;
}
.icono-delete{
    position: absolute;
    bottom: 40px;
    width: 100%;
    height: 40px;
}
.item-delete{
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 5;
    background: var(--bg-drawer);
}
.list-fav{
	overflow-y: auto;
	overflow-x: hidden;
}
.select-none{
	user-select: none;
}
</style>
