import { UseCase } from "~/domain/core/UseCase"
import { Pedidos } from '~/services/almacen/pedidos'
import { EstadoVOFactory } from '~/domain/value_objects/almacen/pedidos/factories/EstadoVOFactory'
import { $try } from '~/services/global'

class GetSelectEstadoPedidoUseCase extends UseCase {
    static async execute(){
        const data = await Pedidos.getSelectEstadoPedidoMaterial()
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => EstadoVOFactory.instantiate(e))
        })
    }
}

export default GetSelectEstadoPedidoUseCase.execute