export default [
    {
        path: '/calendario',
        name: 'calendario.main',
        meta: { title: 'Calendario' },
        redirect: { name: 'calendario.year' },
        component: () => import('../../pages/calendario/').then(m => m.default || m),
        children:[
            {
                path: '/calendario/completo',
                name: 'calendario.year',
                meta: { title: 'Calendario - completo' },
                component: () => import('../../pages/calendario/year').then(m => m.default || m),
            },
            {
                path: '/calendario/mes',
                name: 'calendario.mes',
                meta: { title: 'Calendario - mes' },
                component: () => import('../../pages/calendario/mes').then(m => m.default || m),
            },
            {
                path: '/calendario/semanas',
                name: 'calendario.semanas',
                meta: { title: 'Calendario - semana' },
                component: () => import('../../pages/calendario/semanas').then(m => m.default || m),
            },
            {
                path: '/calendario/lista',
                name: 'calendario.lista',
                meta: { title: 'Calendario - lista' },
                component: () => import('../../pages/calendario/listado').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/calendario/:id/ver-detalle',
        name: 'calendario.ver.detalle',
        component: () => import('../../pages/calendario/verDetalle').then(m => m.default || m),
    },
    {
        path: '/calendario/:id/ver-detalle/:id_configuracion/configuracion',
        name: 'calendario.ver.detalle.configuracion',
        component: () => import('../../pages/calendario/verDetalleConfiguracion').then(m => m.default || m),
    },
    {
        path: '/calendario/:id/ver-detalle/:id_configuracion/configuracion/:id_mantenimiento/mantenimiento',
        name: 'calendario.ver.detalle.configuracion.mantenimiento',
        component: () => import('../../pages/calendario/verDetalleConfiguracionMantenimiento').then(m => m.default || m),
    },
  
]