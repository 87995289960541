import Api from './api'

export const Personalizacion = {
    getPersonalizacion(){
        const ApiHandler = new Api()
        return ApiHandler.get(`personalizacion`)
    },
    getPermision(){
        const ApiHandler = new Api()
        return ApiHandler.get(`configuracion/permisos/permisos`)
    }
}