import Api from '~/services/api'

export const Equipos = {
    getInfoEquipos(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload}/equipos`)
    },

    getEtapas(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload}/equipos/lista-etapas`)
    },

    addEtapas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`obras/vigentes/${payload.id_proyecto}/equipos/${payload.id_producto}/annadir-etapa`, payload)
    },

    putEditEtapas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`obras/vigentes/${payload.id_proyecto}/equipos/${payload.id_producto}/annadir-etapa/${payload.id_oportunidades_producto_etapas}`, payload)
    },

    deleteEtapas(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`obras/vigentes/${payload.id_proyecto}/equipos/${payload.id_producto}/annadir-etapa/${payload.id_oportunidades_producto_etapas}`, {tabla:payload.tabla})
    },

    deleteEquipo(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`obras/vigentes/${payload.id_proyecto}/equipos/${payload.id_proyecto_producto}`,{tabla:payload.tabla})
    },


}