import { Entity } from '~/domain/entities'

export class FinishedObra extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                'id',
                'proyecto',
                'cliente',
                'estado',
                'fecha_inicio',
                'fecha_fin',
                'valor',
            ]
        })
    }
}