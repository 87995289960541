import Api from '~/services/api'


const PermisosAccionesList = {
    listPermisosAcciones(){ 
        const Service = new Api()
        return Service.get('configuracion/permisos/listar-roles')
    }, 
    createPermiso(payload){ 
        const Service = new Api({ notification: true })
        return Service.post('configuracion/permisos/permisos-accion', payload)
    },
    createPermisoRuta(payload){ 
        const Service = new Api({ notification: true })
        return Service.post('configuracion/permisos/permisos-ruta', payload)
    },
    addPermisoRoles(payload){ 
        const Service = new Api({ notification: true })
        return Service.post('configuracion/permisos/permisos-acciones-roles', payload)
    },
    addRolPermisos(payload){  
        const Service = new Api({ notification: true }) 
        return Service.post('configuracion/permisos/roles-acciones-permisos', payload)
    },
    addRolPermisosRuta(payload){  
        const Service = new Api({ notification: true }) 
        return Service.post('configuracion/permisos/permisos-ruta-permisos', payload)
    },
    addRolPermisosRutaRoles(payload){  
        const Service = new Api({ notification: true }) 
        return Service.post('configuracion/permisos/permisos-ruta-roles', payload)
    },
    createRutaPermiso(payload){ 
        const Service = new Api({ notification: true })
        return Service.post('configuracion/permisos/permiso', payload)
    }, 
    addPermisosRutaGenerales(payload){  
        const Service = new Api({ notification: true }) 
        return Service.post('configuracion/permisos/permisos-ruta-general', payload)
    },
} 

export default PermisosAccionesList

