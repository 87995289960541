import * as ServiciosUseCase from '~/domain/use_cases/obras/servicios/index';
import { ServicioFilterFactory } from '~/domain/filters/obras/factories/servicioFilterFactory'
import { $try } from '~/services/global'

export const state = {
    servicios: [],
    filtros: ServicioFilterFactory.instantiateEmpty(),
    questions_service: [],
}

export const getters = {
    servicios: state => state.servicios,
    filtros: state => state.filtros,
    questions_service: state => state.questions_service
}

export const mutations = {
    set_servicios(state, payload) {
        state.servicios = payload
    },
    set_filtros(state, payload) {
        state.filtros = payload
    },
    set_questions_service(state, payload) {
        state.questions_service = payload
    }
}

export const actions = {
    async Action_get_servicios({ commit, state }, payload) {
        const data = await ServiciosUseCase.GetServicesUseCase({ ...payload, ...state.filtros.clean() });
        commit('set_servicios', data);
    },
    async Action_create_servicio({ commit, state }, payload) {
        await ServiciosUseCase.CreateServiceUseCase(payload);
    },
    Action_limpiar_filtros({ commit }) {
        commit('set_filtros', ServicioFilterFactory.instantiateEmpty())
    },
    async Action_edit_servicio({ commit }, params) {
        const data = await ServiciosUseCase.EditServiceUseCase(params);
    },
    async Action_delete_servicio({ commit }, params) {
        const data = await ServiciosUseCase.DeleteServiceUseCase(params);
    },
    async Action_get_aspectos_calificar({ commit, state }, payload) {
        const data = await ServiciosUseCase.GetItemsCalificar(payload);
        
        $try(() => {
            if(data) {
                data.forEach(e => {
                    if (e.id_tipo == 5) {
                        e.vmodel = ''
                    } else {
                        e.vmodel = 0
                    }
                });  
                commit('set_questions_service', data)
            } 
        })
    },
    async Action_finalizar_servicio({ commit }, params) {
        const data = await ServiciosUseCase.EndServiceUseCase(params);
        console.log('finalizar servicio',data);
    },
}