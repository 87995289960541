import { ValueObject } from '~/domain/core/ValueObject'

export class InformacionDetalleOcs extends ValueObject{
    constructor(obj) {
        super({
            params: obj,
            keys: [
            'proveedor',
            'bodega', 
            'equipos',
            'proyectos',
            'solicitantes',
            'materiales',
        ]
        })
    }
}