<template>
    <section class="overflow-hidden">
        <el-carousel ref="carousel" :interval="0" height="400px" trigger="click" indicator-position="none" :autoplay="false" @change="actual" >
            <el-carousel-item v-for="(imagen, idx) in imagenes" :key="idx" class="d-middle-center">
                <div class="d-middle-center">
                    <img :src=" imagen | helper-storage " class="img" />
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="d-middle-center w-95 mx-auto">
            <div 
            class="mt-4 d-flex gap-1 mx-auto pb-2 px-3 custom-scroll scroll-none" 
            style="width: max-content; height: 80px; overflow-x: auto; overflow-y: hidden;"
            >
                <div v-for="(imagen, idx) in imagenes" :key="idx"
                    class="d-middle-center cr-pointer"
                    :class="imgActual != idx ? 'op-04' : 'zoom'"
                    @click="cambiarVerImg(idx)">
                    <img :ref="`imgMini-${idx}`" :src=" imagen | helper-storage " class="imgMini" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        imagenes: {
            type: Array,
            default: []
        },
        titulo: {
            type: String,
            default: ''
        },
        imgInicial: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            imgActual: 0
        }
    },
    watch: {
        imgInicial(val) {
            setTimeout(() => {
                this.$refs.carousel.setActiveItem(val)
            }, 100);
        }
    },
    methods: {
        actual(evt){
            this.$refs[`imgMini-${evt}`][0].scrollIntoView({behavior: 'smooth', inline: 'center'});
            this.imgActual = evt
        },
        cambiarVerImg(index){
            this.$refs.carousel.setActiveItem(index)
        }
    }
}
</script>

<style lang="scss" scoped>
    .img {
        background-size: 100% 100%;
        background-repeat:no-repeat;
        background-size: cover;
        height: 400px;
    }

    .zoom{
        transition: transform .5s;
        transform: scale(1.3);
    }

    .imgMini {
        background-size: 100% 100%;
        background-repeat:no-repeat;
        background-size: cover;
        height: 50px;
        max-width: 80px;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #dfdfdf15;
    }
</style>
