import { Entity } from '~/domain/entities'

export class CabeceraCalendario extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id_ops',
                'id_producto',
                'imagen',
                'nombre',
                'etapas',
                'configuraciones',
                'plan_mantenimiento',
                'materiales',
            ]
        })
    }
}