import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { OppotunityInformationFactory } from '~/domain/entities/oportunidades/information/factories/OppotunityInformationFactory'
import { $try } from '~/services/global'

class GetOpportunityInformationUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getOpportunityInformation(payload)
        return $try( () => data?.data ? OppotunityInformationFactory.instantiate(data.data) : {})
    }
}

export default GetOpportunityInformationUseCase.execute