import { UseCase } from "~/domain/core/UseCase";
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'
import { EtapasConfiguracionEquipoFactory } from '~/domain/entities/cotizaciones/cotizaciones_clientes/factories/etapasConfiguracionEquipoFactory'
import { $try } from '~/services/global'

class GetConfigurationStageEquipmentUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CotizacionCliente.getConfiguracionEtapasEquipo(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new EtapasConfiguracionEquipoFactory.instantiate(e)) 
        })
    }
}

export default GetConfigurationStageEquipmentUseCase.execute