import { UseCase } from '~/domain/core/UseCase';
import { Pedidos } from '~/services/almacen/pedidos';
import { PedidoInternoFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoFactory';
import { $try } from '~/services/global';

class GetUnitPedidoInternoUseCase extends UseCase {
    static async execute(payload) {
        const data = await Pedidos.getPedidoInterno(payload)
        return $try(() => {
            if (data?.data)
                return PedidoInternoFactory.instantiate(data.data);
        })
    }
}

export default GetUnitPedidoInternoUseCase.execute