import * as ProviderDomain from '~/domain/use_cases/proveedores'
import { Pagination } from '../../../domain/entities/decorators/pagination/Pagination'
import { Provider } from '../../../domain/entities/proveedores/Provider'
import { ProveedoresFilterFactory } from '~/domain/filters/proveedores/proveedores/factories/proveedoresFilterFactory'
import { $try } from '~/services/global'

// state
export const state = {
    proveedores: [],
    regenerar_proveedores: [],
    filter: ProveedoresFilterFactory.instantiateEmpty(),
}

// getters
export const getters = {
    proveedores: state => state.proveedores,
    filter: state => state.filter,

}

// mutations
export const mutations = {
    set_providers(state, providers){
        state.proveedores = providers
    },

    set_providers_data(state, providers_data){
        state.proveedores.data = providers_data
    },

    set_regenerate_providers_data(state, regenerar_data){
        state.regenerar_proveedores.data = regenerar_data
    },

    set_filter(state, payload){
        state.filter = payload
    },
}

// actions
export const actions = {
    async Action_list_providers({ commit }, ){
        const providers = await ProviderDomain.GetProviderListUseCase(state.filter)

        if(providers instanceof Pagination){
            commit('set_providers', providers)
            commit('set_regenerate_providers_data', providers)
        }
    },
    async Action_create_provider({ state, commit }, model){
        await ProviderDomain.CreateProviderUseCase(model)
    },
    async Action_edit_provider({ commit }, model){
        await ProviderDomain.EditProviderUseCase(model)
    },
    Action_clear_filter({ state, commit }){
        commit('set_filter', ProveedoresFilterFactory.instantiateEmpty())
    },

}