import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'

class UpdatePositionCalendarActivityChildrensUseCase extends UseCase {
    static async execute(payload){
        await GeneralCalendarService.changePositionCalendarActivityChildrens(payload)
    }
}

export default UpdatePositionCalendarActivityChildrensUseCase.execute
