import * as CategoryDomain from '~/domain/use_cases/almacen/categorias'
import { Category } from '~/domain/entities/almacen/categorias/Category'
import { CategoryFilterFactory } from '~/domain/filters/almacen/categorias/factories/CategoryFilterFactory'
import { $try } from '~/services/global'

export const state = {
    categories: [],
    select_category_names: [],
    filter: CategoryFilterFactory.instantiateEmpty(),
    pagination: {},
    ranges: {},
}

export const getters = {
    categories: state => state.categories,
    pagination: state => state.pagination,
    select_category_names: state => state.select_category_names,
    filter: state => state.filter,
    ranges: state => state.ranges,
}

export const mutations = {
    set_categories(state, payload){
        state.categories = payload
    },
    set_select_category_names(state, payload){
        state.select_category_names = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_ranges(state, payload){
        state.ranges = payload
    },
    set_pagination(state, payload){
        state.pagination = payload
    }
}

export const actions = {
    async Action_get_category_list({ state, commit }, page){
        const filter = state.filter.clean()
        const categories = await CategoryDomain.GetCategoryListUseCase({ filter, page })
        $try(() => {
            if(categories?.data) commit('set_categories', categories.data)
            if(categories?.pagination) commit('set_pagination', categories.pagination)
        })
        
    },

    async Action_create_category({ dispatch }, payload){
        const created = await CategoryDomain.CreateCategoryUseCase(payload)
        $try( async () => {
            await dispatch('Action_get_category_ranges')
            if(created) dispatch('Action_get_category_list', state.pagination.current_page)
        })
    },

    async Action_update_category({ state, dispatch }, payload){
        const updated = await CategoryDomain.UpdateCategoryUseCase(payload)
        $try( async () => {
            await dispatch('Action_get_category_ranges')
            if(updated) dispatch('Action_get_category_list', state.pagination.current_page)
        })
    },

    async Action_delete_category({ dispatch }, id){
        const deleted = await CategoryDomain.DeleteCategoryUseCase(id)
        $try( async () => {
            await dispatch('Action_get_category_ranges')
            if(deleted) dispatch('Action_get_category_list', state.pagination.current_page)
        })
    },

    async Action_get_category_ranges({ commit, dispatch }){
        const data = await CategoryDomain.GetCategoryRangesUseCase()

        $try(() => {    
            if(data) commit('set_ranges', data)
            dispatch('clear_filter')
        })
    },

    
    get_select_category_names({ state, commit }){
        commit('set_select_category_names', state.categories.map(({id, nombre}) => ({id, name: nombre})) )
    },

    clear_filter({ state, commit }){
        commit('set_filter', CategoryFilterFactory.instantiateEmptyWithRanges(state.ranges))
    }
}