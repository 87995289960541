import { Entity } from "~/domain/entities";

export class Actividad extends Entity {
    constructor(obj) {
        super({
        params: obj,
        keys: [
            "id",
            "actividad",
            "peso",
            "id_lista_chequeo",
            "adjuntos"
        ]});
    } 
}
