import { UseCase } from '~/domain/core/UseCase'
import { Workshops } from '~/services/talleres/talleres'
import { ResponsibleVOFactory } from '~/domain/value_objects/talleres/factories/ResponsibleVOFactory'
import { $try } from '~/services/global'

class getSelectResponsibleUsersUseCase extends UseCase {
    static async execute(){
        const data = await Workshops.getSelectResponsibleUsers()
        return $try(() => {
            if(!Array.isArray(data?.data)) return []
            return data.data.map(ResponsibleVOFactory.instantiate)
        })
    }
}

export default getSelectResponsibleUsersUseCase.execute