import { UseCase } from "~/domain/core/UseCase";
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'
import { CotizacionDetalleClienteFactory } from '~/domain/entities/cotizaciones/cotizaciones_clientes/factories/cotizacionDetalleClienteFactory'
import { $try } from '~/services/global'

class PostAddStageUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CotizacionCliente.postAgregarEtapaEquipo(payload)
        return $try(() => {
            if(data?.data) return new CotizacionDetalleClienteFactory.instantiate(data.data)
        })
    }
}

export default PostAddStageUseCase.execute