import { Entity } from '~/domain/entities'

export class CotizacionVigente extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'referencia', 
                'fecha_solicitud',
                'estado', 
                'materiales_cotizar'
            ]
        })
    }
}