import * as FilesDomain from "~/domain/use_cases/files"

export const state = {
    key: null,
    percentage: 0,
}

export const getters = {
    key: state => state.key,
    percentage: state => state.percentage
}

export const mutations = {
    set_key(state, payload){
        state.key = payload
    },
    set_percentage(state, payload){
        state.percentage = payload
    }
}

export const actions = {
    async Action_save_file_to_aws({commit}, payload){
        const response = await FilesDomain.SaveFileToAWSUseCase(
            payload.file, 
            payload.path, 
            (percentage) => {
                commit('set_percentage', percentage)
            }
        )
        commit('set_percentage', 0)
        commit('set_key', response.key)
    }
}