import { UseCase } from '~/domain/core/UseCase';
import { Obras } from '~/services/obras/obras';
import { FinishedObraFactory } from '~/domain/entities/obras/factories/FinishedObraFactory';
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator';
import { $try } from '~/services/global';

class GetFinishedObrasUseCase extends UseCase {
  static async execute(params) {
    const data = await Obras.getFinishedObras(params);
    return $try(() => {
      if(data?.data){
        return new PaginationDecorator(
          data.data,
          FinishedObraFactory.instantiate
        )
      }
    });
  }
}

export default GetFinishedObrasUseCase.execute