import * as PurchaseOrderDomain from '~/domain/use_cases/obras/servicios/orden_compra';
import { PurchaseOrderFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/PurchaseOrderFactory';
import { MaterialFilterFactory } from '~/domain/filters/obras/servicios/orden_compra/factories/MaterialFilterFactory';
import store from '~/store'

export const state = {
    purchase_orders: [],
    purchase_order: PurchaseOrderFactory.instantiateEmpty(),
    filter: MaterialFilterFactory.instantiateEmpty(),
    range: { min: 0, max: 0 },
    bills: [],
}

export const getters = {
    purchase_orders: state => state.purchase_orders,
    purchase_order: state => state.purchase_order,
    filter: state => state.filter,
    filter_count: state => state.filter.count(),
    range: state => state.range,
    bills: state => state.bills,
    selected_bills: state => state.bills.map(bill => bill.id_factura),
}

export const mutations = {
    set_purchase_order(state, payload){
        state.purchase_orders = payload
    },
    set_purchase_order_info(state, payload){
        state.purchase_order = payload
    },
    set_bill_list(state, payload){
        state.bills = payload
    },
    set_range(state, payload){
        state.range = payload
    },
    set_filter(state, payload){
        state.filter = payload
    }
}

export const actions = {
    async Action_get_purchase_orders({ commit }, payload) {
        const data = await PurchaseOrderDomain.GetPurchaseOrdersUseCase(payload);
        commit('set_purchase_order', data);
    },
    async Action_get_payment_methods({ commit }, payload) {
        const data = await PurchaseOrderDomain.GetPaymentMethodsUseCase(payload);
        store.commit('obras/servicios/forma_pago/set_payment_methods', data);
        store.commit('obras/servicios/forma_pago/set_total_percentage', data.reduce((acc, curr) => (acc + curr.porcentaje), 0))
        store.commit('obras/servicios/forma_pago/set_total_value', data.reduce((acc, curr) => (acc + curr.valor), 0))
    },
    async Action_save_payment_fees({ state, commit }, payload) {
        const data = await PurchaseOrderDomain.SavePaymentFeesUseCase(payload);
        const payment_methods = store.getters['obras/servicios/forma_pago/payment_methods']
        data.forEach(item => {
            const formas = payment_methods.map(el => el.id == item.id ? item : el);
            store.commit('obras/servicios/forma_pago/set_payment_methods', formas);
        })
    },
    async Action_get_purchase_order_info({ state, commit }, payload) {
        const filter = state.filter.clean();
        const data = await PurchaseOrderDomain.GetPurchaseOrderInfoUseCase({...payload, filter });
        commit('set_purchase_order_info', data);
    },
    async Action_send_purchase_order({ commit }, payload) {
        const data = await PurchaseOrderDomain.SendPurchaseOrderUseCase(payload);
        if(data) commit('set_purchase_order_info', data);
    },
    async Action_get_purchase_order_bills({ commit }, payload) {
        const data = await PurchaseOrderDomain.GetPurchaseOrderBillsUseCase(payload);
        commit('set_bill_list', data);
    },
    async Action_save_purchase_order_bill({ state, commit }, payload) {
        const data = await PurchaseOrderDomain.SavePurchaseOrderBillUseCase(payload);
        if(data) commit('set_bill_list', [...state.bills, data]);
    },
    async Action_update_purchase_order_bill({ state, commit }, payload) {
        const data = await PurchaseOrderDomain.UpdatePurchaseOrderBillUseCase(payload);
        if(data){
            const bills = state.bills.map(bill => bill.id == data.id ? data : bill);
            commit('set_bill_list', bills);
        }
    },
    async Action_delete_purchase_order_bill({ state, commit }, payload) {
        const data = await PurchaseOrderDomain.DeletePurchaseOrderBillUseCase(payload);
        if(data){
            const bills = state.bills.filter(bill => bill.id != payload.id_ordenes_compras_facturas);
            commit('set_bill_list', bills);
        }
    },
    async Action_report_purchase_order_payment({ commit }, payload) {
        const data = await PurchaseOrderDomain.ReportPurchaseOrderPaymentUseCase(payload);
        if(data) {
            const order = state.purchase_order
            order.valor_pagado+= parseFloat(data.pago)
            commit('set_purchase_order', order);
        }
    },
    async Action_finish_purchase_order_payment({ commit }, payload) {
        const data = await PurchaseOrderDomain.FinishPurchaseOrderPaymentUseCase(payload);
        if(data) {
            const order = state.purchase_order
            order.valor_pagado = order.valor
            commit('set_purchase_order', order);
        }
    },
    async Action_deliver_purchase_order({ commit }, payload) {
        const data = await PurchaseOrderDomain.DeliverPurchaseOrderUseCase(payload);
        if(data) commit('set_purchase_order_info', data);
    },
    async Action_get_purchase_order_materials_range({ commit }, payload) {
        const data = await PurchaseOrderDomain.GetPurchaseOrderMaterialsRangeUseCase(payload);
        if(data) commit('set_range', data);
    },
    Action_update_purchase_order_price({ commit, state }, { id_orden_compra, total }){
        const purchase_orders = state.purchase_orders.map(el => el.id == id_orden_compra ? PurchaseOrderFactory.instantiate({ ...el, valor: total }) : el)
        commit('set_purchase_order', purchase_orders);
    },
    clear_filter({ state, commit }){
        commit('set_filter', MaterialFilterFactory.instantiateEmptyWithRange(state.range));
    }
}