import { Entity } from '~/domain/entities'

export class Salida extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'mes',
                'mes_abre',
                'anio',
                'cantidad'
            ]
        })
    }
}