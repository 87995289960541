import * as MantenimientoDomain from '~/domain/use_cases/almacen/mantenimiento'
import { MaintenanceFilterFactory } from '~/domain/filters/almacen/materiales/mantenimiento/factories/MaintenanceFilterFactory'

import { $try } from '~/services/global'
// state
export const state = {
    mantenimientos: [],
    listMaterialesMantenimientos: [],
    filter: MaintenanceFilterFactory.instantiateEmpty(),
}

// getters  
export const getters = {
    mantenimientos: state => state.mantenimientos,
    listMaterialesMantenimientos: state => state.listMaterialesMantenimientos,
    filter: state => state.filter,
}

// mutations
export const mutations = {
    set_mantenimientos(state, mantenimientos) {
        state.mantenimientos = mantenimientos
    },

    set_materiales_mantenimientos(state, mantenimientos) {
        state.listMaterialesMantenimientos = mantenimientos
    },

    set_filter(state, payload){
        state.filter = payload
    }
}

// actions
export const actions = {
    async Action_get_materiales_mantenimientos({ commit }, payload) {
        const mantenimientos = await MantenimientoDomain.GetMaintenanceUseCase(payload)
        $try(
            () => {
                commit('set_materiales_mantenimientos', mantenimientos)
            }
        )
    },

    async Action_get_plan_mantenimientos({ commit }, payload) {
        const mantenimientos = await MantenimientoDomain.GetMaintenancePlansUseCase(payload)
        $try(
            () => commit('set_mantenimientos', mantenimientos)
        )
    },

    async Action_create_mantenimientos({ state, commit }, payload) {
        const mantenimiento = await MantenimientoDomain.CreateMaintenancePlanUseCase(payload)
        $try(() => {
            if(mantenimiento) commit('set_mantenimientos', [...state.mantenimientos, mantenimiento])
        })
    },

    async Action_edit_mantenimientos({ state, commit }, payload) {
        const mantenimiento = await MantenimientoDomain.EditMaintenancePlanUseCase(payload)
        $try(() => {
            if(mantenimiento) commit('set_mantenimientos', state.mantenimientos.map(el => mantenimiento.id === el.id ? mantenimiento : el))
        })
    },

    async Action_delete_mantenimientos({ state, commit }, payload) {
        const mantenimiento = await MantenimientoDomain.DeleteMaintenancePlanUseCase(payload)
        $try(() => {
            if(mantenimiento) commit('set_mantenimientos', state.mantenimientos.filter(el => payload.id_mantenimiento != el.id))
        })
    },

    clear_filter({commit}){
        commit('set_filter', MaintenanceFilterFactory.instantiateEmpty())
    },

}