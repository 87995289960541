import { Factura } from "../Factura";

export class FacturaFactory {
    static instantiate(obj){
        return new Factura(obj)
    }

    static instantiateEmpty(){
        return new Factura({
            id: '',
            referencia: '',
            id_estado: '',
            facturas_estado: '',
            fecha_pago: '',
            valor: '',
        })
    }
}