import { ProviderVO } from "../ProviderVO";

export class ProviderVOFactory {
    static instantiate(obj){
        return new ProviderVO(obj)
    }

    static instantiateEmpty(){
        return new ProviderVO({
            id: '',
            nombre: '',
        })
    }
}