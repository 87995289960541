import { incrementos } from "../incrementos";

export class incrementosFactory {
    static instantiate(obj){
        return new incrementos(obj)
    }

    static instantiateEmpty(){
        return new incrementos({
            id: undefined,
            nombre: '',
            porcentaje: '',
            posicion: ''
        })
    }
}