import { UseCase } from "~/domain/core/UseCase";
import { Services } from '~/services/obras/servicios/servicios'

class EditServiceUseCase extends UseCase {
    static async execute(payload) {
        const data = await Services.editServicioTaller(payload)
        return data?.message
    }
}

export default EditServiceUseCase.execute