import { Entity } from '~/domain/entities'

export class MaterialesConfiguracionSelect extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'nombre', 
                'disabled'
            ]
        })
    }
}