<template>
    <form autocomplete="off" @submit="$event.preventDefault()">
        <el-input 
        class="position-relative"
        v-model="queryString" 
        :placeholder="placeholder" 
        :size="size" 
        v-on:keyup.native.enter="handleSearch"
        prefix-icon="el-icon-search"
        suffix-icon="default"
        >
            <div v-if="loading" slot="suffix" class="el-loading-spinner position-absolute" style="right: 35px;">
                <svg viewBox="25 25 50 50" class="circular">
                    <circle cx="50" cy="50" r="10" fill="none" class="path" style="stroke: var(--color-general)"/>
                </svg>
            </div>
        </el-input>
    </form>
</template>

<script>
let interval = null;
export default {
    props: {
        search: { type: Function, default: () => {} },
        autoSearch: { type: Boolean, default: false },
        size: { type: String, default: 'small' },
        placeholder: { type: String, default: 'Buscar' },
        writeDelay: { type: Number, default: 2000 },
        queryKey: { type: String, default: 'filtro' },
        mode: { type: String, default: 'server' },
        data: { type: Array, default: () => [] },
        value: String
    },
    data(){
        return {
            queryString: '',
            writing: false,
            loading: false,
            delay: 0,
        }
    },
    watch: {
        value(val){
            this.queryString = val
        },
        queryString(val){
            this.$emit('input',val)
            this.writing = true;
            if(this.autoSearch) this.handleAutoSearch()
            this.writing = false
        }
    },
    created(){
        this.delay = this.mode === 'server' ? this.writeDelay : 0
    },
    methods:{
        handleAutoSearch(){
            if(this.writing) clearInterval(interval)
            interval = setInterval(() => {
                this.handleSearch()
                clearInterval(interval)
            }, this.delay);
        },
        async handleSearch(){
            clearInterval(interval)
            this.loading = true
            if(this.mode === 'server') await this.search(this.queryString);
            if(this.mode === 'client') {
                const data = this.data
                const result = data.filter(value => {
                    const words = this.queryString.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' ')
                    const row = Object.values(value).join(', ').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
                    return words.every(word => row.includes(word))
                })
                this.$emit('result', result)
            }
            this.loading = false
        }
    }
}
</script>

