import { DashboardFilter } from "../dashboardFilter";

export class DashboardFilterFactory {
    static instantiate(obj){
        return new DashboardFilter(obj)
    }

    static instantiateEmpty(){
        return new DashboardFilter({
            fecha_min: '',
            fecha_max: '',
            id_pais: '',
            id_estado: ''
        })
    }
}