
import { UseCase } from "~/domain/core/UseCase";
import { Documents } from '~/services/talleres/ver_documentos';
import { $try } from '~/services/global';

class DeleteFileUseCase extends UseCase {
    static async execute({id_taller, id_folder, id_file}) {
        const data = await Documents.deleteFile(id_taller, id_folder, id_file);
        return $try(() => data?.message)
    }
}

export default DeleteFileUseCase.execute;
