import { UseCase } from "~/domain/core/UseCase";
import { Bills } from '~/services/compras/facturas'
import { OrdenesCompraFactory } from '~/domain/entities/compras/facturas/factories/OrdenesCompraFactory'
import { $try } from '~/services/global'

class GetOrdenesCompraUseCase extends UseCase {
  static async execute(id, filter) {
    const data = await Bills.getOrdenesCompra(id, filter);
    return $try(() => {
      let newData = data?.data;
      if (newData && Array.isArray(newData)) {
        return newData.map((type) => OrdenesCompraFactory.instantiate(type));
      }
    });
  }
}

export default GetOrdenesCompraUseCase.execute;