import { UseCase } from "~/domain/core/UseCase";
import { VisitasService } from '~/services/obras/servicios/visitas'

class GetVisitDetailsUseCase extends UseCase {
    static async execute(params){
        const data = await VisitasService.getVisitDetails(params)
        return data.data
    }
}

export default GetVisitDetailsUseCase.execute