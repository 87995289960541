import { UseCase } from "~/domain/core/UseCase";
import { Funcionariosroveedores } from '~/services/proveedores/funcionariosProveedores'
import { $try } from '~/services/global'

class DeleteFuncionariosProveedoresUseCase extends UseCase {
    static async execute({idProveedor, idFuncionario}){
        const data = await Funcionariosroveedores.deleteUsuarioProveedor({idProveedor, idFuncionario})
        return $try(() => {
            return data.message
        })
    }
}

export default DeleteFuncionariosProveedoresUseCase.execute