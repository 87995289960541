import { Entity } from '~/domain/entities'

export class AdditionalData extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['pregunta', 'tipo', 'id','posicion']
        });

        //To dragabble
        this.edit = false
    }
}