import { Entity } from '~/domain/entities'

export class PedidoInternoMaterial extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
				'id_material',
				'id_categoria',
				'id_espesor',
				'categoria',
				'material',
				'espesor',
				'tipo_material',
				'unidad_medida',
				'cantidad',
				'id_estado',
				'id_proveedor'
            ]
        })
    }
}