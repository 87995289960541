import { Entity } from '~/domain/entities'

export class ActiveObra extends Entity {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                'id',
                'nombre',
                'cliente',
                'logo',
                'fecha_inicio',
                'fecha_fin',
                'numero_equipos',
                'porcentaje',
                'valor',
            ]
        })
    }
}