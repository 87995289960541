import { UseCase } from "~/domain/core/UseCase";
import { PedidosInternos } from '~/services/configurar/almacen/pedidos_internos.js'
import { $try } from '~/services/global'

class DeleteEstadosPedidosInternosUseCase extends UseCase {
    static async execute(payload){
        const data = await PedidosInternos.deleteEstadosPedidosInternos(payload)      
        return $try(() => {
            return $try(() => data?.message)
        })
    }
}

export default DeleteEstadosPedidosInternosUseCase.execute