import { Oportunity } from "../Oportunity";

export class OportunityFactory {
    static instantiate(obj){
        return new Oportunity(obj)
    }

    static instantiateEmpty(){
        return new Oportunity({
            id: '',
            oportunidad: '',
        })
    }
}