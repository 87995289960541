import { PaginationFactory } from '~/domain/entities/decorators/pagination/factories/PaginationFactory'
export class PaginationDecorator {
    constructor(data, dataInstancer){
        if(!(data && typeof data == 'object')) throw Error("Se espera un objeto como primer parametro");

        const { data: iterable, ...rest } = data;

        if(!Array.isArray(iterable)) throw Error("El parametro data no es iterable");
        
        this.data = iterable.map(el => dataInstancer(el));

        this.pagination = PaginationFactory.instantiate({ data: null, ...rest })
    }
    toTable(){
        return PaginationFactory.instantiate({
            ...this.pagination,
            data: [...this.data]
        })
    }
}
