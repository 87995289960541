import { UseCase } from "~/domain/core/UseCase"
import { Calendario } from "~/services/calendario/calendario"
import { CalendarioAnnioFactory } from "~/domain/entities/calendario/factories/CalendarioAnnioFactory"
import { $try } from "~/services/global"

class GetCalendarioAnnioUseCase extends UseCase{
    static async execute(payload){
        const data = await Calendario.getCalendarioAnnio(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => CalendarioAnnioFactory.instantiate(e))
        })
    }
}

export default GetCalendarioAnnioUseCase.execute