import { UseCase } from '~/domain/core/UseCase'
import StockMaterials from '~/services/almacen/stock'
import { StockMaterialesFactory } from '~/domain/entities/almacen/stock/factories/StockMaterialesFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetStockMaterialsUseCase extends UseCase {
    static async execute(payload){
        const data = await StockMaterials.listMaterialInStock(payload)
        return $try(() => {
            if(data?.data) 
            return new PaginationDecorator(data.data, StockMaterialesFactory.instantiate);
        })
    }
}

export default GetStockMaterialsUseCase.execute
