import { Filter } from '~/domain/core/Filter';

export class OrdenCompraFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                "orden_compra",
                "proyectos",
                "estado",
                "valor_min",
                "valor_max"
            ]
        });
    }
}