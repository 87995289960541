import { UseCase } from '~/domain/core/UseCase'
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { OpportunityWonFactory } from '~/domain/entities/oportunidades/factories/OpportunityWonFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetOpportunityesWonUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getOpportunityWon(payload)
        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    OpportunityWonFactory.instantiate
                ).toTable();
            }
        })
    }
}

export default GetOpportunityesWonUseCase.execute