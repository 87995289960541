import { UseCase } from '~/domain/core/UseCase';
import { Documents } from '~/services/talleres/ver_documentos';
import { FileFactory } from '~/domain/entities/talleres/factories/FileFactory';
import { $try } from '~/services/global';

class SaveFileUseCase extends UseCase {
    static async execute({id_taller, id_folder, file}) {
        const data = await Documents.saveFile(id_taller, id_folder, file);
        return $try(() => {
            if(data?.data) {
                return FileFactory.instantiate(data.data)
            }
        })
    }
}

export default SaveFileUseCase.execute;