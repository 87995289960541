import { UseCase } from "~/domain/core/UseCase";
import { CheckList } from '~/services/configurar/obras/lista_chequeo.js'
import { ActivitiesCheckListFactory } from '~/domain/entities/configurar/obras/check_list/factories/ActivitiesCheckListFactory.js'
import { $try } from '~/services/global'

class EditActivityCheckListUseCase extends UseCase {
    static async execute(payload){
        const data = await CheckList.editActivityCheckList(payload)
        return $try( () => {
            if (data?.data) {
                // const { created_at, created_by, updated_at, updated_by, actividad, ...rest } = data.data;
                return ActivitiesCheckListFactory.instantiate(data.data);
            }
        })

    }

}

export default EditActivityCheckListUseCase.execute