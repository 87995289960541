import { UseCase } from '~/domain/core/UseCase';
import { WorkshopView } from '~/services/talleres/ver';
import { ServiceFactory } from '~/domain/entities/talleres/factories/ServiceFactory';
import { $try } from '~/services/global';

class GetWorkshopActiveServicesUseCase extends UseCase {
    static async execute(id_taller) {
        const data = await WorkshopView.getWorkshopActiveServices(id_taller);
        return $try(() => {
            if (!Array.isArray(data?.data)) return []
            return data.data.map(ServiceFactory.instantiate)
        })
    }
}

export default GetWorkshopActiveServicesUseCase.execute;