import { Filter } from '~/domain/core/Filter';

export class MaterialFilter extends Filter {
    constructor(obj) {
        super({
            params: obj,
            keys: [
                'id_tipo_material',
                'unidades_min',
                'unidades_max',
                'referencia'
            ]
        });
    }
}