import { CotizacionRealizadaFilter } from "../CotizacionRealizadaFilter";

export class CotizacionRealizadaFilterFactory {
    static instantiate(obj){
        return new CotizacionRealizadaFilter(obj)
    }

    static instantiateEmpty(){
        return new CotizacionRealizadaFilter({
            referencia: null,
            valor_minimo: 0,
            valor_maximo: 0,
            fecha_cotizacion_inicio: null,
            fecha_cotizacion_fin: null,
        })
    }

    static instantiateEmptyWithRange(range){
        const valor_minimo = range?.valor_minimo ?? 0;
        const valor_maximo = range?.valor_maximo ?? 0;

        const service = new CotizacionRealizadaFilter({
            referencia: null,
            valor_minimo: range?.valor_minimo ?? 0,
            valor_maximo: range?.valor_maximo ?? 0,
            fecha_cotizacion_inicio: null,
            fecha_cotizacion_fin: null,
        })

        service.setExceptions({
            valor_minimo,
            valor_maximo
        });

        return service;
    }
}