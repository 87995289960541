
import { UseCase } from '~/domain/core/UseCase'
import { Workshops } from '~/services/talleres/talleres'
import { ResponsibleVOFactory } from '~/domain/value_objects/talleres/factories/ResponsibleVOFactory'
import { $try } from '~/services/global'

class SaveFunctionaryUseCase extends UseCase {
    static async execute(payload){
        const data = await Workshops.saveFunctionary(payload)
        return $try(() => {
            if(data?.data) {
                return ResponsibleVOFactory.instantiate(data.data)
            }
        })
    }
}

export default SaveFunctionaryUseCase.execute