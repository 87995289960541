<template>
    <el-select
        v-model="valueModel"
        placeholder="Seleccionar ciudad"
        filterable
        remote
        :size="size"
        :multiple="multiple"
        :remote-method="listCitys"
        :loading="loading"
        @change="selected"
    >
      <el-option
        v-for="(data, idx) in ciudades"
        :key="idx"
        :label="data.ciudad"
        :value="data.id"
      > {{data.ciudad}} - {{data.estado}} </el-option>
    </el-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
    export default {
        props: {
            multiple: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: 'small' 
            },
            value: {
                type: [String, Number],
                default: '' 
            },
        },
        data() {
            return {
                loading: false,
                valueModel:''
            }
        },
        watch: {
            value(newValue) {
                this.valueModel = newValue
            }
        },
        computed: {
            ...mapGetters({
                ciudades: 'selects/selects/select_city_paginated'
            })
        },
        created () {
            this.Action_get_selects_city_paginated()
            this.initialValue()
            this.valueModel = this.value
        },
        methods: {
            ...mapActions({
                Action_get_selects_city_paginated: 'selects/selects/Action_get_selects_city_paginated',
            }),
            selected() {
                this.$emit('input', this.valueModel)
            },
            async listCitys(query) {
                this.loading = true
                await this.Action_get_selects_city_paginated({query})
                this.loading = false
            },
            async initialValue(){
                if(!this.value) return
                let inicial = this.multiple ? this.value : [this.value]
                await this.Action_get_selects_city_paginated({inicial})
            }
        },
    }
</script>

<style scoped>

</style>