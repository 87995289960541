import * as PedidosInternos from '~/domain/use_cases/configurar/almacen/pedidos_internos/index.js'
import { PedidosInternos as Pedidos } from '~/services/configurar/almacen/pedidos_internos.js'

import { $try } from '~/services/global'
// state
export const state = {
    estadoPedidosInternos: []
}

// getters
export const getters = {
    estadoPedidosInternos: state => state.estadoPedidosInternos,
}

// mutations
export const mutations = {
    set_estado_pedidos_internos(state, payload){
        state.estadoPedidosInternos = payload
    }
}

// actions
export const actions = {
    async Action_get_estados_pedidos_internos({commit}){
        const data = await PedidosInternos.GetEstadosPedidosInternosUseCase()
        $try(() => {
            if(data) commit('set_estado_pedidos_internos', data)
        })
    },

    async Action_post_crear_estado({ commit }, payload){
        const data = await PedidosInternos.PostEstadosPedidosInternosUseCase(payload)
        $try(() => {
            if (data) {
                commit('set_estado_pedidos_internos', [...state.estadoPedidosInternos, data])
            }
        })
    },

    async Action_update_estados_pedidos_internos({ commit }, payload){
        const data = await PedidosInternos.PutEstadosPedidosInternosUseCase(payload)
        $try(() => {
            if (data) {
                commit('set_estado_pedidos_internos', [...state.estadoPedidosInternos.filter(e => e.id != payload.id), data])
            }
        })
    },

    async Action_eliminar_estados_pedidos_internos({ commit }, payload){
        const data = await PedidosInternos.DeleteEstadosPedidosInternosUseCase(payload)
        $try(() => {
            if (data) {
                let estados = state.estadoPedidosInternos.filter(e => e.id != payload)
                commit('set_estado_pedidos_internos', estados)
            }
        })
    },

    async Action_cambiar_posicion_pedidos_internos({ commit }, payload){
        await Pedidos.changePositionEstadosPedidosInternos(payload)
    },

}