import { Entity } from '~/domain/entities'

export class MaterialesConfiguracion extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'cantidad',
                'id_oportunidades_equipos_modelos_etapas_materiales',
                'id_plan_mantenimiento',
                'nombre',
                'referencia',
                'sigla',
                'tabla',
                'planes_mantenimiento',
                'id_oportunidades_equipos_modelos_etapas_configuracion',
                'tipo_configuracion',
                'tipo_material',
                'unidad_medida',
                'valor_cotizado'
            ]
        });
    }
}