import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'

class AdvanceCicloOpportunityUseCase extends UseCase {
    static async execute(params){
        const data = await Opportunities.advanceCicloOpportunity(params)
        return data?.message
    }
}

export default AdvanceCicloOpportunityUseCase.execute