import { PedidoInternoMaterial } from "../PedidoInternoMaterial";

export class PedidoInternoMaterialFactory {
    static instantiate(obj) {
        return new PedidoInternoMaterial(obj);
    }

    static instantiateEmpty() {
        return new PedidoInternoMaterial({
            id: '',
            id_material: '',
            id_categoria: '',
            id_espesor: '',
            categoria: '',
            material: '',
            espesor: '',
            tipo_material: '',
            unidad_medida: '',
            cantidad: '',
            id_estado: '',
            id_proveedor: ''
        });
    }
}