import { PedidoInternoMaterialFiltro } from "../PedidoInternoMaterialFiltro";

export class PedidoInternoMaterialFiltroFactory {
    static instantiate(obj) {
        return new PedidoInternoMaterialFiltro(obj);
    }

    static instantiateEmpty() {
        return new PedidoInternoMaterialFiltro({
            categorias: '',
            materiales: '',
            espesor: '',
            tipos_materiales: '',
            estados: '',
            proveedores: '',
            cantidad_max: ''
        });
    }
}