import { UseCase } from '~/domain/core/UseCase'
import { Workshops } from '~/services/talleres/talleres'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { WorkshopFactory } from '~/domain/entities/talleres/factories/WorkshopFactory'
import { $try } from '~/services/global'

class GetWorkshopsUseCase extends UseCase {
    static async execute(payload){
        const data = await Workshops.getWorkshops(payload)
        return $try(() => {
            if(data?.data) {
                let iterable = {
                    ...data.data,
                    data: data.data.data.map(el => ({...el, imagen: null, calificacion: null}))
                }
                return new PaginationDecorator(iterable, WorkshopFactory.instantiate)
            }
        })
    }
}

export default GetWorkshopsUseCase.execute