import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { AdditionalDataFactory } from '~/domain/entities/configurar/almacen/datos_adicionales/factories/AdditionalDataFactory'
import { $try } from '~/services/global'

class GetAdditionalDataUseCase extends UseCase {
    static async execute(params){
        const data =  await AdditionalData.getAdditionalData()

        return $try(() => {
            return data?.data?.map(item => AdditionalDataFactory.instantiate(item)) ?? []
        })
    }
}

export default GetAdditionalDataUseCase.execute