import { GruposOrdenesCompraFilter } from "../GruposOrdenesCompraFilter";

export class GruposOrdenesComprasFactory {
    static instantiate(obj){
        return new GruposOrdenesCompraFilter(obj)
    }

    static instantiateEmpty(){
        return new GruposOrdenesCompraFilter({
            id_oportunidad: null,
            id_cliente: null,
            id_proveedor: null,
            id_bodega: null,
            buscar:''
        })
    }
}