import * as RolesAreasDomain from '~/domain/use_cases/configurar/permisos'
import { $try } from '~/services/global';

export const state = {
    rolesAreas: [],
}

export const getters = {
    rolesAreas: state => state.rolesAreas
}

export const mutations = {
    set_rolesAreas(state, payload){
        state.rolesAreas = payload
    }
}

export const actions = {
    async Action_get_rolesAreas({state, commit}, page){ 
        const data = await RolesAreasDomain.GetRolesAreasUseCase({ page }) 
        $try(() => {
            if(data) commit('set_rolesAreas', data);
        });
    },
}