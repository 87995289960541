import { Notification } from 'element-ui'
import { HelperUcfirst } from '~/filters/global'

export const notify = (res, allow, delay = 0) => {
    const types = {
        success: [200, 201, 202],
        warning: [400, 401],
        info: [403, 404, 405, 406],
        error: [500, 501, 502, 503],
    }
    
    if(res?.error?.mensaje_personalizado ?? res.error)
        notification('', HelperUcfirst(res.error.mensaje_personalizado ?? res.error), 'error');

    if(!allow) 
        return;
        
    Object.entries(types).forEach(([key, value]) => {
        if(value.includes(res.status)){
            setTimeout(() => notification('', HelperUcfirst(res.data.message), key), delay)
        }  
    })
}

export const notification = (title, message, type = 'info', duration = 3000, isHtml = false) => {
    Notification({ title, message, type, duration, dangerouslyUseHTMLString: isHtml })
}