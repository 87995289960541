import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { ProviderVOFactory } from '~/domain/value_objects/proveedores/factories/ProviderVOFactory'
import { $try } from '~/services/global'

class GetSelectProvidersUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectProviders()
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(provider => ProviderVOFactory.instantiate(provider))
        })
    }
}

export default GetSelectProvidersUseCase.execute