import { OpportunityFilter } from "../OpportunityFilter";

export class OpportunityFilterFactory {
    static instantiate(obj){
        return new OpportunityFilter(obj)
    }

    static instantiateEmpty(){
        return new OpportunityFilter({
            fecha_cierre_inicio: null,
            fecha_cierre_fin: null,
            probabilidad_minima: 0,
            probabilidad_maxima: 0,
            valor_minimo: 0,
            valor_maximo: 0,
            ciclos: [],
            tipos: [],
            responsables: [],
            criterios: []
        })
    }

    static instantiateEmptyWithRange(rangeProb, rangeVal){
        const probabilidad_minima = rangeProb?.[0] ?? 0
        const probabilidad_maxima = rangeProb?.[1] ?? 0
        const valor_minimo = rangeVal?.[0] ?? 0
        const valor_maximo = rangeVal?.[1] ?? 0

        const filter = new OpportunityFilter({
            fecha_cierre_inicio: null,
            fecha_cierre_fin: null,
            ciclos: [],
            tipos: [],
            responsables: [],
            criterios: [],
            probabilidad_minima,
            probabilidad_maxima,
            valor_minimo,
            valor_maximo
        })

        filter.setExceptions({
            probabilidad_minima,
            probabilidad_maxima,
            valor_minimo,
            valor_maximo,
        })

        return filter
    }
}