import Api from '~/services/api'

export const Cotizaciones = {
    getCotizacionesPendientes(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`usuario-proveedor/cotizaciones-pendientes`, payload)
    },
    getCotizacionesRealizadas(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`usuario-proveedor/cotizaciones-realizadas`, payload)
    },
    getCotizacionesVencidas(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`usuario-proveedor/cotizaciones-vencidas`, payload)
    },
    getMaterialesCotizaciones(params){
        const ApiHandler = new Api()
        return ApiHandler.get(`usuario-proveedor/cotizaciones-${params?.cotizacion}/${params?.id}/materiales`, params?.payload ?? {})
    },
    getInformationProvider(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`usuario-proveedor/${payload}/informacion`)
    },
    setPriceMaterials(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`usuario-proveedor/agregar-precio-material`, payload)
    },
    getRangeCantMaterials(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`select/cotizaciones-materiales`, payload)
    },
    getRangeValueMaterials(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`select/cotizaciones-valor`, payload)
    },
    getRangeCantValorVerMaterials(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`select/cotizaciones-cantidad`, payload)
    },
}