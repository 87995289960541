
import { UseCase } from '~/domain/core/UseCase'
import { Workshops } from '~/services/talleres/talleres'
import { WorkshopFactory } from '~/domain/entities/talleres/factories/WorkshopFactory'
import { $try } from '~/services/global'

class SaveWorkshopUseCase extends UseCase {
    static async execute(payload){
        const data = await Workshops.saveWorkshop(payload)
        return $try(() => {
            if(data?.data) {
                return WorkshopFactory.instantiate(data.data)
            }
        })
    }
}

export default SaveWorkshopUseCase.execute