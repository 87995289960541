import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { MatrizFactory } from '~/domain/entities/compras/matriz/factories/MatrizFactory'
import { $try } from '~/services/global'

class CreateBuyOrderUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data } =  await Matriz.createBuyOrder(params)

        $try(() => {
            if(data.length){
                data.forEach((item) =>{
                    customData.push(
                        MatrizFactory.instantiate(item))
                })
            }
        })

        return customData
    }
}

export default CreateBuyOrderUseCase.execute