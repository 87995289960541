import { Entity } from '~/domain/entities';

export class PercentageIncrease extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [ 
                'id',
                'nombre',
                'porcentaje',
                'posicion'
            ]
        });
    }
}