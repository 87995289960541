import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { MeasureUnitVOFactory } from "~/domain/value_objects/almacen/materiales/factories/MeasureUnitVOFactory"
import { $try } from '~/services/global'

class GetSelectMeasureUnitsUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetMeasureUnits()
        return $try(async () => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(el => MeasureUnitVOFactory.instantiate(el))
        })
    }
}

export default GetSelectMeasureUnitsUseCase.execute