import { UseCase } from '~/domain/core/UseCase'
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { $try } from '~/services/global'

class GetFilterCriterioOpportunityUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getCriterio(payload)
        return $try( () => data?.data ?? [] )
    }
}

export default GetFilterCriterioOpportunityUseCase.execute