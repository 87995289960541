import { ConfiguracionFilter } from "../configuracionFilter";

export class ConfiguracionFilterFactory {
    static instantiate(obj) {
        return new ConfiguracionFilter(obj);
    }

    static instantiateEmpty() {
        return new ConfiguracionFilter({
            buscar:'',
            id_tipo_material: null,
            id_unidad_medida: null,
            valor_minimo: 0,
            valor_maximo: 0,
        });
    }

    static instantiateEmptyWithRange(range) {
        const valor_minimo = range?.min ?? 0;
        const valor_maximo = range?.max ?? 0;

        const filter = new ConfiguracionFilter({
            buscar: '',
            id_tipo_material: null,
            id_unidad_medida: null,
            valor_minimo,
            valor_maximo,
        });

        filter.setExceptions({
            valor_minimo,
            valor_maximo,
        });

        return filter
    }
}