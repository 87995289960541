import { Entity } from '~/domain/entities'

export class PedidoInternoMaterialFiltro extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'categorias',
				'materiales',
				'espesor',
				'tipos_materiales',
				'estados',
				'proveedores',
				'cantidad_min',
				'cantidad_max'
            ]
        })
    }
}