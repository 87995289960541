import * as Dashboard from '~/domain/use_cases/bodegas/dashboard'
import { DashboardFilterFactory }from '~/domain/filters/almacen/bodegas/factories/dashboardFilterFactory'
import { $try } from '~/services/global'

export const state = {
    material: [],
    valor: [],
    filters: DashboardFilterFactory.instantiateEmpty(),
    entrada: [],
    salida: []
}

export const getters = {
    material: state => state.material,
    valor: state => state.valor,
    filters: state => state.filters,
    entrada: state => state.entrada,
    salida: state => state.salida
}

export const mutations = {
    setMaterial(state, payload){
        state.material = payload
    },
    setValor(state, payload){
        state.valor = payload
    },
    set_filters(state, payload){
        state.filters = payload
    },
    set_entrada(state, payload){
        state.entrada = payload
    },
    set_salida(state, payload){
        state.salida = payload
    }
}

export const actions = {
    async Action_get_dashboard_bodegas_material({commit}, payload){
        payload = state.filters.clean()
        const data = await Dashboard.GetMaterialUseCase(payload)
        $try( () => { commit('setMaterial', data) })
    },
    async Action_get_dashboard_bodegas_valor({commit}, payload){
        payload = state.filters.clean()
        const data = await Dashboard.GetValorUseCase(payload)
        $try( () => { commit('setValor', data) })
    },
    async Action_get_dashboard_bodegas_entrada({commit}, payload){
        const data = await Dashboard.GetEntradaUseCase(payload)
        $try( () => { commit('set_entrada', data) })
    },
    async Action_get_dashboard_bodegas_salida({commit}, payload){
        const data = await Dashboard.GetSalidaUseCase(payload)
        $try( () => { commit('set_salida', data) })
    },
    Action_clear_filters({ commit, dispatch }){
        commit('set_filters', DashboardFilterFactory.instantiateEmpty())
        dispatch('Action_get_dashboard_bodegas_material')
        dispatch('Action_get_dashboard_bodegas_valor')
    },
}