<template>
    <modal ref="modalAgregarArchivos" titulo="Añadir archivo" adicional="Añadir" @adicional="guardarArchivo">
        <div class="row justify-content-center m-3 f-14">
            <div class="col-12 mb-3">
                <p class="input-label-top">Nombre del archivo</p>
                <ValidationObserver ref="validator">
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                        <el-input v-model="nombreArchivo" placeholder="Nombre del archivo" size="small" class="w-100"  />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </ValidationObserver>
            </div>
            <div class="col-12 mb-4">
                <el-radio v-model="radioArchivo" :label="1">PDF</el-radio>
                <el-radio v-model="radioArchivo" :label="2">Imagen</el-radio>
                <el-radio v-model="radioArchivo" :label="3">Video</el-radio>
                <el-radio v-model="radioArchivo" :label="4">Cualquiera</el-radio>
            </div>
            <div class="col-12">
               <upload-button ref="uploadButton" v-model="filesToUpload" />
            </div>
        </div>
    </modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    data(){
        return {
            id_proveedor: this.$route.params.id_proveedor,
            nombreArchivo:'',
            radioArchivo: 4,
            filesToUpload: [],
        }
    },
    watch:{
        filesToUpload: {
            handler(val){
                this.filesToUpload = val
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            uploadFiles: 'proveedores/ver/ver/Action_upload_provider_file'
        }),
        toggle(){
            this.cleanForm();
            this.$refs.modalAgregarArchivos.toggle()
        },
        async guardarArchivo(){
            const valid = await this.$refs.validator.validate();
            if(valid) {
                if(this.filesToUpload.length == 0)
                    return this.$notify({
                        title: `Debe seleccionar un archivo`,
                        type:'warning',
                        duration: 2000,
                    })
                
                const file = this.filesToUpload[0]
                await this.uploadFiles({id_proveedor: this.id_proveedor, archivo: file, nombre: this.nombreArchivo, tipo: this.radioArchivo})
                this.toggle()
            };

        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`El límite es 3, haz seleccionado ${files.length} archivos esta vez, añade hasta ${files.length + fileList.length}`);
        },
        cleanForm(){
            this.nombreArchivo = ''
            this.$refs.validator.reset();
            this.$refs.uploadButton.cleanFileList()
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
