import { UseCase } from "~/domain/core/UseCase";
import { AdditionalData } from '~/services/configurar/almacen/datos_adicionales'
import { AdditionalDataTypeVOFactory } from '~/domain/value_objects/configurar/almacen/datos_adicionales/factories/AdditionalDataTypeVOFactory'
import { $try } from '~/services/global'

class GetUniqueAdditionalDataTypeUseCase extends UseCase {
    static async execute(params){
        const customData = []
        const { data } =  await AdditionalData.getUniqueAdditionalDataType(params)

        $try(() => {
            if(data.length){
                data.forEach((item) =>{
                    customData.push(
                        AdditionalDataTypeVOFactory.instantiate(item))
                })
            }
        })

        return customData
    }
}

export default GetUniqueAdditionalDataTypeUseCase.execute