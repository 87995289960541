import { UseCase } from "~/domain/core/UseCase"
import { Calendario } from "~/services/calendario/calendario"
import { CabeceraCalendarioFactory } from "~/domain/entities/calendario/factories/CabeceraCalendarioFactory"
import { $try } from "~/services/global"

class GetCalendarioAnnioUseCase extends UseCase{
    static async execute(payload){
        const data = await Calendario.getCabeceraCalendario(payload)
        return $try(() => {
            return $try( () => data?.data ? CabeceraCalendarioFactory.instantiate(data.data) : {})

        })
    }
}

export default GetCalendarioAnnioUseCase.execute