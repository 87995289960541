import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { ConfigurationFactory } from '~/domain/entities/compras/matriz/factories/ConfigurationFactory'
import { $try } from '~/services/global'

class GetStageConfigurations extends UseCase {
    static async execute(params){
        const data = await Matriz.getStageConfiguration(params)
        return $try(() => {
            if(!Array.isArray(data?.data)) return []
            return data.data.map(e => new ConfigurationFactory.instantiate(e))
        })
    }
}

export default GetStageConfigurations.execute