import { UseCase } from "~/domain/core/UseCase";
import { Bodegas } from '~/services/bodegas/bodegas'
import { BodegaFactory } from '~/domain/entities/bodegas/factories/BodegaFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetBodegasUseCase extends UseCase {
    static async execute(payload){
        const  data  =  await Bodegas.getBodegas(payload)
        
        return $try(() => {
            if(data?.data?.data) 
                return new PaginationDecorator(data.data, BodegaFactory.instantiate)
        })
    }
}
export default GetBodegasUseCase.execute