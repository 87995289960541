import { UseCase } from "~/domain/core/UseCase";
import { CountryService } from '~/services/configurar/paises/paises'
import { PaginationDecorator } from '../../../decorators/PaginationDecorator';
import { CountryFactory } from '../../../entities/configurar/paises/factories/CountryFactory';
import { CountryUtilityFactory } from '../../../entities/configurar/paises/factories/CountryUtilityFactory';
import { $try } from '~/services/global'


class GetCountriesUseCase extends UseCase {
    static async execute(payload){
        const data = await CountryService.getCountries(payload);
        return $try(() => {
            if(data?.data?.data && Array.isArray(data.data.data))
                data.data.data = data.data.data.map(({pais,...rest}) => ({ pais: CountryFactory.instantiate(pais), ...rest }))

            return new PaginationDecorator(
                data.data,
                CountryUtilityFactory.instantiate
            ).toTable()
        })
    }
}

export default GetCountriesUseCase.execute