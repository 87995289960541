import { UseCase } from "~/domain/core/UseCase";
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'
import { ListarMaterialesConfiguracionFactory } from '~/domain/entities/cotizaciones/cotizaciones_clientes/factories/listarMaterialesConfiguracionFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetConfigurationMaterialsUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CotizacionCliente.getMaterialesConfiguracion(payload)
        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    ListarMaterialesConfiguracionFactory.instantiate
                ).toTable();
            }
        })
    }
}

export default GetConfigurationMaterialsUseCase.execute