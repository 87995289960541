<template>
    <div class="container file-upload">
        <div class="row">
            <div class="col-4">
                <input ref="uploaderInput" class="d-none" type="file" :accept="whatAccept(fileType)" name="fileUploader" @change="setFiles" />
                <input class="px-2 px-3 f-12 f-600 text-white p-1 bg-general border-0 br-2" type="button" name="fileUploaderTrigger" value="Subir archivo" @click="$refs.uploaderInput.click()" />
            </div>
            <div :class="this.multipleUpload ? 'col-12': 'col-8'">
                <p v-for="(file, index) in filesToUpload" :key="index"  class="f-12">
                    {{ file.name }} <i class="icon-ok-circled-outline f-12 text-success" /> 
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fileType: {
            type: Number,
            default: 4
        },
        multipleUpload: {
            type: Boolean,
            default: false
        },
        fileEdit: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            filesToUpload: [],
        }
    },
    watch:{
        filesToUpload:{
            handler(val){
                this.$emit('input', val)
            },
            deep: true
        },
        fileEdit(val){
            if (val.length) {
                this.filesToUpload = [{ name: val[0],  lastModified: 0, route: val[1]}]
            }
        }
    },
    methods:{
        setFiles(e){
            if (this.multipleUpload) {
                this.filesToUpload = [e.target.files[0]]
                this.$refs.uploaderInput.value = ''
            }else{
                let fileArray = Array.from(e.target.files)
                fileArray.forEach(file => {
                    if(!this.filesToUpload.filter(fu => fu.name === file.name).length){
                        this.filesToUpload.push(file)
                    }
                })
            }
        },
        cleanFileList(){
            this.filesToUpload = []
            this.$refs.uploaderInput.value = ''
        },
        whatAccept(type){
            switch (type) {
                case 1: // pdf
                    return '.pdf'
                    break;
                case 2: // img
                    return 'image/*'
                    break;
                case 3: // video
                    return 'video/*'
                    break;
                case 4: // todos
                    
                    break;
            
                default:
                    break;
            }
        }

    },
}
</script>

<style>

</style>