import { CotizacionVerPendienteFilter } from "../CotizacionVerPendienteFilter";

export class CotizacionVerPendienteFilterFactory {
    static instantiate(obj){
        return new CotizacionVerPendienteFilter(obj)
    }

    static instantiateEmpty(){
        return new CotizacionVerPendienteFilter({
            id_unidad_medida: null,
            cantidad_material_minimo: 0,
            cantidad_material_maximo: 0,
            rango_valor_minimo: 0,
            rango_valor_maximo: 0,
        })
    }

    static instantiateEmptyWithRanges(range){
        const cantidad_material_minimo = Number(range?.cantidad_minima ?? 0);
        const cantidad_material_maximo = Number(range?.cantidad_maxima ?? 0);
        const rango_valor_minimo = Number(range?.valor_unidad_minimo ?? 0);
        const rango_valor_maximo = Number(range?.valor_unidad_maximo ?? 0);

        const service = new CotizacionVerPendienteFilter({
            id_unidad_medida: null,
            cantidad_material_minimo,
            cantidad_material_maximo,
            rango_valor_minimo,
            rango_valor_maximo,
        })

        service.setExceptions({
            cantidad_material_minimo,
            cantidad_material_maximo,
            rango_valor_minimo,
            rango_valor_maximo,
        });

        return service;
    }
}