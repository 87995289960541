import { UseCase } from "~/domain/core/UseCase";
import { CorreosNotificacion } from '~/services/configurar/proveedores/correos_notificacion'
import { CorreosProveedoresFactory } from '~/domain/entities/configurar/proveedores/factories/correosProveedoresFactory'
import { $try } from '~/services/global'

class PutEmailNotificationProvidersUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CorreosNotificacion.putEmailNotificacionProveedores(payload)
        return $try(() => {
            if(data?.data) return new CorreosProveedoresFactory.instantiate(data.data)
        })
    }
}

export default PutEmailNotificationProvidersUseCase.execute