<template>
    <el-input
    ref="searchInput"
    v-model="searchText"
    prefix-icon="el-icon-search"
    :placeholder="placeholder"
    :size="size"
    @input="search(false)"
    v-on:keyup.native.enter="search(true)"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    props:{
        autoSearch: { type: Boolean, default: true },
        dataToSearch: { type: [Array,Object] , default: () => [] },
        prop: { type: Array, default: () => [] },
        size: { type: String, default: 'small' },
        placeholder: { type: String, default: 'Buscar módulo o submodulo' },
        
        // criteries: { type: Array, default: () => ['name','nombre']}
    },
    data(){
        return {
            searchText: '',
        }
    },
    computed: {
        ...mapGetters({
            show: 'menu/show_menu'
        })
    },
    watch:{
        show(val){
            if(val){
                this.searchText = '';
                this.$refs.searchInput.focus();
            }
        },
        searchText(val){
            // no search return all the data
            if(val == '') this.$emit('filtered', []);
        },
    },
    methods: {
        search(canSearch=false){
            // autosearch check
            if(!this.autoSearch && canSearch == false) return;

            const searchText = this.searchText;
            const dataToSearch = this.dataToSearch;
            const prop = this.prop;
            const splittedSearch = searchText.toLowerCase().split(' ');

            const filtered = dataToSearch.filter(el => splittedSearch.every(word => this.deepSearch(el, word) > 0));
            this.$emit('filtered', filtered);
        },
        deepSearch(obj, stopParameter){
            let count = 0
            const entries = Object.entries(obj)
            entries.forEach((entry) => {
                if(this.prop.length){
                    if(this.prop.includes(entry[0])){
                        switch(typeof(entry[1])){
                            case 'object':
                                if(moment(entry[1]).isValid()) return moment(entry[1]).format('YYYY-MM-DD').toLowerCase().includes(stopParameter.toLowerCase()) ? count++ : count
                                count += (entry[1] ? this.deepSearch(entry[1], stopParameter) : 0)
                                break;
                            case 'string':
                                return entry[1].toLowerCase().includes(stopParameter.toLowerCase()) ? count++ : count
                            default: 
                                return entry[1] === parseInt(stopParameter) ? count++ : count
                        }
                    }
                } else {
                    switch(typeof(entry[1])){
                        case 'object':
                            if(moment(entry[1]).isValid()) return moment(entry[1]).format('YYYY-MM-DD').toLowerCase().includes(stopParameter.toLowerCase()) ? count++ : count
                            count += (entry[1] ? this.deepSearch(entry[1], stopParameter) : 0)
                            break;
                        case 'string':
                            return entry[1].toLowerCase().includes(stopParameter.toLowerCase()) ? count++ : count
                        default: 
                            return entry[1] ==  parseInt(stopParameter) ? count++ : count
                    }
                }
            })  
            return count
        }
    },
}
</script>