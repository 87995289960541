import { ValueObject } from '~/domain/core/ValueObject'

export class ResponsibleVO extends ValueObject {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id',
                'nombre',
            ]
        })
    }
}