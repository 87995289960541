import { Warehouse } from "../Warehouse";

export class WarehouseFactory {
    static instantiate(obj){
        return new Warehouse(obj)
    }

    static instantiateEmpty(){
        return new Warehouse({
            bodega: '',
            id: '',
        })
    }
}