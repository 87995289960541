import { UseCase } from "~/domain/core/UseCase"
import Materials from "~/services/almacen/materiales"
import { $try } from '~/services/global'

class ShowMaterialUseCase extends UseCase {
    static async execute(params){
        const { id } = params
        const data = await Materials.showMaterial({id})
        if(data.data) return data.data
    }
}

export default ShowMaterialUseCase.execute