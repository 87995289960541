import { OpportunityConfigurationFilter } from "../OpportunityConfigurationFilter";

export class OpportunityConfigurationFilterFactory {
    static instantiate(obj){
        return new OpportunityConfigurationFilter(obj)
    }

    static instantiateEmpty(){
        return new OpportunityConfigurationFilter({
            id_tipo_material: null,
            id_unidad_medida: null,
            valor_minimo: 0,
            valor_maximo: 0,
            query:null
        })
    }

    static instantiateEmptyWithRange(rangeVal){
        const valor_minimo = rangeVal?.[0] ?? 0
        const valor_maximo = rangeVal?.[1] ?? 0

        const filter = new OpportunityConfigurationFilter({
            id_tipo_material: null,
            id_unidad_medida: null,
            query:null,
            valor_minimo,
            valor_maximo,
        })

        filter.setExceptions({
            valor_minimo,
            valor_maximo,
        })

        return filter
    }
}