import * as CalendarioGeneralDomain from '~/domain/use_cases/configurar/obras/calendario_general'

import { $try } from '~/services/global'
// state
export const state = {
    calendario_general: [],
}

// getters
export const getters = {
    calendario_general: state => state.calendario_general,
}

// mutations
export const mutations = {
    set_calendario_general(state, calendario_general){
        state.calendario_general = calendario_general
    }
}

// actions
export const actions = {

    async Action_get_calendario_general({ commit }){
        const calendario_general  = await CalendarioGeneralDomain.GetGeneralCalendarsUseCase()
        $try (() => {
            if(calendario_general) commit('set_calendario_general', calendario_general)
        })
    },

    async Action_create_calendario_general({ state, commit }, payload){
        const calendario_general = await CalendarioGeneralDomain.CreateGeneralCalendarUseCase(payload)
        $try (() => {
            if(calendario_general) commit('set_calendario_general', [...state.calendario_general, calendario_general])
        })
    },

    async Action_edit_calendario_general({ state, commit }, payload){
        const calendario_general = await CalendarioGeneralDomain.EditGeneralCalendarUseCase(payload)
        $try (() => {
            if(calendario_general) commit('set_calendario_general', state.calendario_general.map(item => item.id == calendario_general.id ? calendario_general : item))
        })
    },

    async Action_delete_calendario_general({ state, commit }, id){
        const data = await CalendarioGeneralDomain.DeleteGeneralCalendarUseCase(id)
        $try (() => {
            if(data) commit('set_calendario_general', state.calendario_general.filter(item => item.id != id.id))
        })
    },
    async Action_create_calendario_general_actividad({ state, commit}, payload){
        const actividad = await CalendarioGeneralDomain.CreateCalendarActivityUseCase(payload)
        $try (() => {
            if(actividad){
                const calendario_general = state.calendario_general.map(item => {
                    if(item.id != payload.id_configuracion_calendarios) return item 
                
                    return ({
                        ...item,
                        actividades: [...item.actividades, actividad]
                    })
                })
                commit('set_calendario_general', calendario_general)
            }
        })
    },
    async Action_edit_calendario_general_actividad({commit}, payload){
        const actividad = await CalendarioGeneralDomain.EditCalendarActivityUseCase(payload)
        $try (() => {
            if(actividad){
                const calendario_general = state.calendario_general.map(item => {
                    if(item.id != payload.id_configuracion_calendarios) return item 
                
                    return ({
                        ...item,
                        actividades: item.actividades.map(act => act.id == actividad.id ? actividad : act)
                    })
                })
                commit('set_calendario_general', calendario_general)
            }
        })
    },
    async Action_delete_calendario_general_actividad({commit}, payload){
        const data = await CalendarioGeneralDomain.DeleteCalendarActivityUseCase(payload)
        $try (() => {
            if(data) {
                const calendario_general = state.calendario_general.map(item => {
                    if(item.id != payload.id_configuracion_calendarios) return item 
                
                    return ({
                        ...item,
                        actividades: item.actividades.filter(act => act.id != payload.id_configuracion_calendarios_actividades)
                    })
                })
                commit('set_calendario_general', calendario_general)
            } 
        })
    },



    async Action_create_calendario_general_subactividad({commit}, payload){
        const subactividad = await CalendarioGeneralDomain.CreateCalendarSubactivityUseCase(payload)
        $try (() => {
            if(subactividad){
                const calendario_general = state.calendario_general.map(item => {
                    if(item.id != payload.id_configuracion_calendarios) return item 
                
                    return ({
                        ...item,
                        actividades: item.actividades.map(act => {
                            if(act.id != payload.id_configuracion_calendarios_actividades) return act
                            return ({
                                ...act,
                                actividades: [...act.actividades, subactividad]
                            })
                        })
                    })
                })
                commit('set_calendario_general', calendario_general)
            }
        })
    },

    async Action_edit_calendario_general_subactividad({commit}, payload){
        const subactividad = await CalendarioGeneralDomain.EditCalendarSubactivityUseCase(payload)
        $try (() => {
            if(subactividad){
                const calendario_general = state.calendario_general.map(item => {
                    if(item.id != payload.id_configuracion_calendarios) return item 
                
                    return ({
                        ...item,
                        actividades: item.actividades.map(act => {
                            if(act.id != payload.id_configuracion_calendarios_actividades) return act
                            return ({
                                ...act,
                                actividades: act.actividades.map(sub => sub.id == subactividad.id ? subactividad : sub)
                            })
                        })
                    })
                })
                commit('set_calendario_general', calendario_general)
            }
        })
    },

    async Action_delete_calendario_general_subactividad({commit}, payload){
        const data = await CalendarioGeneralDomain.DeleteCalendarSubactivityUseCase(payload)
        $try (() => {
            if(data){
                const calendario_general = state.calendario_general.map(item => {
                    if(item.id != payload.id_configuracion_calendarios) return item 
                
                    return ({
                        ...item,
                        actividades: item.actividades.map(act => {
                            if(act.id != payload.id_configuracion_calendarios_actividades) return act
                            return ({
                                ...act,
                                actividades: act.actividades.filter(sub => sub.id != payload.id)
                            })
                        })
                    })
                })
                commit('set_calendario_general', calendario_general)
            }
        })
    },
    
    async Action_update_position_calendar_activity_children({commit}, payload){
        const activityChildren = await CalendarioGeneralDomain.UpdatePositionCalendarActivityChildrensUseCase(payload)
    },
    async Action_update_position_calendar_activity({commit}, payload){
        const activity = await CalendarioGeneralDomain.UpdatePositionCalendarActivityUseCase(payload)
    },
}