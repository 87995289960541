import { UseCase } from '~/domain/core/UseCase'
import { Equipos } from '~/services/almacen/equiposModelos'

class GetDetalleCabeceraUseCase extends UseCase {
    static async execute(payload){
        const data = await Equipos.getDetalleEquipo(payload)
        return data?.data ?? {}
    }
}

export default GetDetalleCabeceraUseCase.execute