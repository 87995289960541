import { Entity } from '~/domain/entities'

export class GraficaCobrar extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'total_cobrar',
                'grafica',
                'tabla',
            ]
        })
    }
}