import { Entity } from '~/domain/entities'

export class Responsible extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'imagen',
                'nombre',
                'cargo',
                'responsable_principal',
            ]
        });
    }
}