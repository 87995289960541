export default [
    {
        path: '/bodega',
        name: 'bodega.main',
        meta: { title: 'Bodega' },
        component: () => import('../../pages/bodega/').then(m => m.default || m),
    },
    {
        path: '/bodega/:id_bodega/materiales',
        name: 'bodega.materiales',
        meta: { title: 'Bodega - Materiales' },
        component: () => import('../../pages/bodega/materiales.vue').then(m => m.default || m),
    },
    {
        path: '/bodega/:id_bodega/ordenes-compra',
        name: 'bodega.ordenes-compra',
        meta: { title: 'Bodega - Ordenes - Compra' },
        component: () => import('../../pages/bodega/ordenes.vue').then(m => m.default || m),
    },
    {
        path: '/bodega/dashboard',
        name: 'bodega.dashboard',
        meta: { title: 'Bodega - Dashboard' },
        component: () => import('../../pages/bodega/dashboard.vue').then(m => m.default || m),
    },
  
]