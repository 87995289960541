import { Gallery } from "../Gallery";

export class GalleryFactory {
    static instantiate(obj){
        return new Gallery(obj)
    }

    static instantiateEmpty(){
        return new Gallery({
            id: '',
            nombre: '',
            id_proyecto: '',
            id_tipo: '',
            cantidad_archivos: '',
            created_at: '',
            created_by: '',
            obra: '',
            tipo: ''
        })
    }
}