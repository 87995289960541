import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { CustomersVOFactory } from '~/domain/value_objects/opportunity/factories/customersVOFactory'
import { $try } from '~/services/global'

class GetSelectOppotunityCustomersUseCase extends UseCase {
    static async execute(payload){
        const { data } = await Selects.GetSelectOpportunityCustomers(payload)
        return $try(() => {
            if(data && Array.isArray(data)) return data.map(type => CustomersVOFactory.instantiate(type))
        })
    }
}

export default GetSelectOppotunityCustomersUseCase.execute