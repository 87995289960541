import { UseCase } from "~/domain/core/UseCase"
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'
import { UsersVOFactory } from '~/domain/value_objects/users/factories/UsersVOFactory'

class GetSelectEncargadosUserUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectEncargadosUsers()
        return $try(() => {
            if(data?.data) return data.data.map(el => UsersVOFactory.instantiate(el))
            return []
        })
    }

}

export default GetSelectEncargadosUserUseCase.execute;