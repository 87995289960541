import { UseCase } from "~/domain/core/UseCase"
import Materials from "~/services/almacen/materiales"
import { $try } from '~/services/global'

class ChangeMaterialStateUseCase extends UseCase {
    static async execute(params){
        const { id } = params
        const data = await Materials.changeMaterialState({id})
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default ChangeMaterialStateUseCase.execute
