import * as UseCases from "~/domain/use_cases/oportunidades"
import { OpportunityFilterFactory } from '~/domain/filters/opportunity/factories/OpportunityFilterFactory'
import { OpportunityConfigurationFilterFactory } from '~/domain/filters/opportunity/factories/OpportunityConfigurationFilterFactory'
import { $try } from '~/services/global'

export const state = {
    configuraciones_oportunidad: [],
    oportunidades: {},
    oportunidadesGanadas: {},
    oportunidadesPerdidas: {},
    dataFiltros: {},
    filtros: OpportunityFilterFactory.instantiateEmpty(),
    filterConfiguracion: OpportunityConfigurationFilterFactory.instantiateEmpty(),
    criterio: {},
    oportunidadesInformacion: [],
    oportunidadesEquipos: [],
    oportunidadesCiclos: [],
    infoCicloOpportunity: [],
    oportunidadesResponsables: [],
    oportunidadesClientes: [],
    oportunidadesMetodoPago: [],
    numCriteriosFiltro: 0,
    sicloAvanzado: false,
    paymentMethod: {},
    configuracion:{},
    materialesConfiguracion:[],
    selectMateriales:[],
    filtroConfiguracion:[]
}

export const getters = {
    configuraciones_oportunidad: state => state.configuraciones_oportunidad,
    oportunidades: state => state.oportunidades,
    oportunidadesGanadas: state => state.oportunidadesGanadas,
    oportunidadesPerdidas: state => state.oportunidadesPerdidas,
    dataFiltros: state => state.dataFiltros,
    filtros: state => state.filtros,
    filterConfiguracion: state => state.filterConfiguracion,
    criterio: state => state.criterio,
    oportunidadesInformacion: state => state.oportunidadesInformacion,
    oportunidadesEquipos: state => state.oportunidadesEquipos,
    oportunidadesCiclos: state => state.oportunidadesCiclos,
    infoCicloOpportunity: state => state.infoCicloOpportunity,
    oportunidadesResponsables: state => state.oportunidadesResponsables,
    oportunidadesClientes: state => state.oportunidadesClientes,
    oportunidadesMetodoPago: state => state.oportunidadesMetodoPago,
    numCriteriosFiltro: state => state.numCriteriosFiltro,
    sicloAvanzado: state => state.sicloAvanzado,
    paymentMethod: state => state.paymentMethod,
    configuracion: state => state.configuracion,
    materialesConfiguracion: state => state.materialesConfiguracion,
    selectMateriales: state => state.selectMateriales,
    filtroConfiguracion: state => state.filtroConfiguracion,

}

export const mutations = {
    set_oportunidades(state, payload) {
        state.oportunidades = payload
    },
    set_oportunidades_ganadas(state, payload) {
        state.oportunidadesGanadas = payload
    },
    set_oportunidades_perdidas(state, payload) {
        state.oportunidadesPerdidas = payload
    },
    set_filtros(state, payload) {
        state.filtros = payload
    },
    set_filter_configuracion(state, payload) {
        state.filterConfiguracion = payload
    },
    set_select_filtros_oportunidades(state, payload) {
        state.dataFiltros = payload
    },
    set_criterio(state, payload) {
        state.criterio = payload
    },
    set_opportunity_informacion(state, payload) {
        state.oportunidadesInformacion = payload
    },
    set_opportunity_equipos(state, payload) {
        state.oportunidadesEquipos = payload
    },
    add_opportunity_equipo(state, payload) {
        state.oportunidadesEquipos.push(payload)
    },
    set_opportunity_ciclos(state, payload) {
        state.oportunidadesCiclos = payload
    },
    set_info_ciclo_opportunity(state, payload) {
        state.infoCicloOpportunity = payload
    },
    set_opportunity_responsibles(state, payload) {
        state.oportunidadesResponsables = payload
    },
    add_opportunity_responsibles(state, payload) {
        state.oportunidadesResponsables.push(payload)
    },
    set_opportunity_customers(state, payload) {
        state.oportunidadesClientes = payload
    },
    add_opportunity_customer(state, payload) {
        state.oportunidadesClientes.push(payload)
    },
    set_opportunity_payment_method(state, payload) {
        state.oportunidadesMetodoPago = payload
    },
    set_configuraciones_oportunidad(state, configuraciones_oportunidad) {
        state.configuraciones_oportunidad = configuraciones_oportunidad
    },
    set_num_criterios_filtro(state, payload){
        state.numCriteriosFiltro = payload
    },
    set_siclo_avanzado(state, payload) {
        state.sicloAvanzado = payload
    },
    set_payment_method(state, payload) {
        state.paymentMethod = payload
    },
    set_configuracion(state, payload) {
        state.configuracion = payload
    },
    set_materiales_configuracion(state, payload) {
        state.materialesConfiguracion = payload
    },
    set_select_materiales(state, payload) {
        state.selectMateriales = payload
    },
    set_filtro_configuracion(state, payload) {
        state.filtroConfiguracion = payload
    },
}

export const actions = {
    // Listar Opportunity ----------------------------------------------------------------------------------

    async Action_get_oportunidades({ commit }, payload) {
        const data = await UseCases.GetOpportunityesUseCase(payload)
        $try(
            () => commit('set_oportunidades', data)
        )
    },
    async Action_get_oportunidades_ganadas({ commit }, payload) {
        const data = await UseCases.GetOpportunityesWonUseCase(payload)
        $try(
            () => commit('set_oportunidades_ganadas', data)
        )
    },
    async Action_get_oportunidades_perdidas({ commit }, payload) {
        const data = await UseCases.GetOpportunityesLossesUseCase(payload)
        $try(
            () => commit('set_oportunidades_perdidas', data)
        )
    },

    // Filtros --------------------------------------------------------------------------------------------

    async Action_clear_filtros({ commit }, payload) {
        $try( () => commit('set_filtros', OpportunityFilterFactory.instantiateEmptyWithRange(payload.rangeProb, payload.rangeVal)) )
    },
    async Action_clear_filtro_oportunidades({ commit }, payload) {
        $try( () => commit('set_filter_configuracion', OpportunityConfigurationFilterFactory.instantiateEmptyWithRange(payload.valor_minimo, payload.valor_maximo)) )
    },

    async Action_get_filtros_oportunidades({ commit }, payload) {
        const data = await UseCases.GetFiltersOpportunityUseCase(payload)
        $try( () => commit('set_select_filtros_oportunidades', data) )
    },

    async Action_get_criterio({ commit }, payload) {
        const data = await UseCases.GetFilterCriterioOpportunityUseCase(payload)
        $try( () => commit('set_criterio', data) )
    },

    async Action_save_criterio_ubicacion( _, payload ) {
        await UseCases.saveOpporunityFilterUbicacionUseCase(payload)
    },

    async Action_save_criterio_segmentacion( _, payload ) {
        await UseCases.saveOpporunityFilterSegmentacionUseCase(payload)
    },

    async Action_save_criterio_general( _, payload ) {
        await UseCases.saveOpporunityFilterGeneralUseCase(payload)
    },

    async Action_delete_filtros() {
        await UseCases.deleteOpportunityFilterUseCase()
    },

    // Ver Opportunity -------------------------------------------------------------------------------------

    async Action_get_opportunity_information({ commit }, payload) {
        const data = await UseCases.GetOpportunityInformationUseCase(payload)
        $try(() => commit('set_opportunity_informacion', data))
    },

    async Action_edit_opportunity_information({ commit }, params) {
        const data = await UseCases.EditOpportunityInformationUseCase(params)
    },

    // Equipos Oportunidad ----------------------------------------------------------------------------------

    async Action_get_opportunity_equipos({ commit }, payload) {
        const data = await UseCases.GetOpportunityEquiposUseCase(payload)
        $try(() => commit('set_opportunity_equipos', data))
    },

    async Action_add_opportunity_equipo({ commit }, params) {
        const data = await UseCases.AddOpportunityEquipoUseCase(params)
    },

    // Ciclos Oportunidad ----------------------------------------------------------------------------------

    async Action_get_opportunity_ciclos({ state, commit }, payload) {
        const data = await UseCases.GetOpportunityCiclosUseCase(payload)
        $try(() => commit('set_opportunity_ciclos', data))
    },
    
    async Action_edit_opportunity_ciclos( _, payload ) {
        await UseCases.EditOpportunityCiclosUseCase(payload)
    },
    
    async Action_get_info_ciclo_opportunity({ commit }, payload) {
        const data = await UseCases.GetInfoCicloOpportunityUseCase(payload)
        $try(() => commit('set_info_ciclo_opportunity', data))
    },

    async Action_advance_ciclo_opportunity( { commit }, params ) {
        const data = await UseCases.AdvanceCicloOpportunityUseCase(params)
        commit('set_siclo_avanzado', data ? true : false)
    },

    // Responsables Oportunidad ----------------------------------------------------------------------------

    async Action_get_opportunity_responsibles({ commit }, payload) {
        const data = await UseCases.GetOpportunityResponsibleUseCase(payload)
        $try(() => commit('set_opportunity_responsibles', data))
    },

    async Action_add_opportunity_responsibles({ commit }, params) {
        const data = await UseCases.AddOpportunityResponsibleUseCase(params)
        $try(() => {
                if(Object.values(data).length > 0) commit('add_opportunity_responsibles', data)
            })
    },

    async Action_add_principal_opportunity_responsibles( _, params) {
        await UseCases.AddPrincipalResponsibleUseCase(params)
    },

    async Action_delete_opportunity_responsibles( _, params) {
        await UseCases.DeleteOpportunityResponsibleUseCase(params)
    },

    // Clientes Oportunidad -------------------------------------------------------------------------------

    async Action_get_opportunity_customers({ commit }, payload) {
        const data = await UseCases.GetOpportunityCustomersUseCase(payload)
        $try(() => commit('set_opportunity_customers', data))
    },

    async Action_add_opportunity_customer({ commit }, params) {
        const data = await UseCases.AddOpportunityCustomerUseCase(params)
        $try(() => {
                if(Object.values(data).length > 0) commit('add_opportunity_customer', data)
        })
    },

    async Action_delete_opportunity_customer( _, params) {
        await UseCases.DeleteOpportunityCustomerUseCase(params)
    },

    // Formas de pago ---------------------------------------------------------------------------------------

    async Action_set_opportunity_payment_method({ commit }, params) {
        const data = await UseCases.SetOpportunityPaymentMethodUseCase(params)
        $try(() => {
            if(Object.values(data).length > 0) commit('set_opportunity_payment_method', data)
        })
    },

    async Action_get_opportunity_payment_method({ commit }, payload) {
        const data = await UseCases.GetPaymentMethodUseCase(payload)
        $try(() => { commit('set_payment_method', data) })
    },

    //------------------------------------------------------------------------------------------------------

    async Action_get_configuraciones_oportunidad({ commit }, payload) {
        const configuraciones_oportunidad = await UseCases.GetOpportunityConfigurationsUseCase(payload)

        $try(
            () => commit('set_configuraciones_oportunidad', configuraciones_oportunidad)
        )
    },

    async Action_create_etapa_oportunidad({ commit }, payload) {
        await UseCases.CreateOpportunityStageUseCase(payload)
    },

    async Action_edit_etapa_oportunidad({ commit }, payload) {
        await UseCases.EditOpportunityStageUseCase(payload)
    },

    async Action_delete_etapa_oportunidad({ commit }, payload) {
        await UseCases.DeleteOpportunityStageUseCase(payload)
    },
    async Action_delete_equipo_modelo_oportunidad({ commit }, payload) {
        await UseCases.DeleteOpportunityModelEquipmentUseCase(payload)
    },

    // Configuraciones ---------------------------------------------------------------------------------------

    async Action_get_configuracion({ commit }, payload) {
        const data = await UseCases.GetConfiguracionUseCase(payload)
        $try(() => { commit('set_configuracion', data) })
    },
    async Action_get_materiales_configuracion({ commit }, payload) {
        const filter = state.filterConfiguracion
        const data = await UseCases.GetMaterialesConfiguracionUseCase({payload, filter})
        $try(() => { commit('set_materiales_configuracion', data) })
    },
    async Action_get_select_materiales({ commit }, payload) {
        const data = await UseCases.GetSelectMaterialesUseCase(payload)
        $try(() => { commit('set_select_materiales', data) })
    },
    async Action_put_actualizar_valor_horas_hombre({ commit }, payload) {
        await UseCases.PutActualizarValorHorasHombreUseCase(payload)

    },
    async Action_put_restablecer_valor_horas_hombre({ commit }, payload) {
        await UseCases.PutRestablecerValorHorasHombreUseCase( payload)
    },
    async Action_put_actualizar_material({ commit }, payload) {
        await UseCases.PutActualizarMaterialUseCase( payload)
    },
    async Action_delete_material({ commit }, payload) {
        await UseCases.DeleteMaterialUseCase( payload)
    },
    async Action_post_agregar_materiales({ commit }, payload) {
        await UseCases.PostAgregarMaterialUseCase( payload)
    },
    async Action_post_reestablecer_mantenimientos({ commit }, payload) {
        await UseCases.PostReestablecerMantenimientosUseCase( payload)
    },
    async Action_delete_configuracion({ commit }, payload) {
        await UseCases.DeleteConfiguracionUseCase( payload)
    },
    async Action_get_data_filtro_configuracion({ commit }, payload){
        const data = await UseCases.GetDataFiltroConfiguracionUseCase(payload)
        $try(() => { commit('set_filtro_configuracion', data) })
    },
    async Action_clear_filter_configuracion({ commit }, payload){
        $try( () => commit('set_filter_configuracion', OpportunityConfigurationFilterFactory.instantiateEmptyWithRange([payload.min, payload.max])) )
    },


}

