import { UseCase } from "~/domain/core/UseCase";
import { CotizacionCliente } from '~/services/cotizaciones/cotizaciones_clientes/cotizacion_cliente'
import { CotizacionHistorialFactory } from '~/domain/entities/cotizaciones/cotizaciones_clientes/factories/cotizacionHistorialFactory'
import { $try } from '~/services/global'




class GetCotizadorHistorialUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await CotizacionCliente.getCotizacionHistorial(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new CotizacionHistorialFactory.instantiate(e)) 
        })
    }
}

export default GetCotizadorHistorialUseCase.execute