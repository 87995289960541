<template>
    <div class="card-servicio border br-8 pt-2 cr-pointer">

        <div>
            <p class="px-3 f-600 tres-puntos"> {{ name }} </p>
            <p v-if="project" class="f-14 px-3 f-500 mb-1 tres-puntos"> Proyecto {{ project }} </p>
            <p v-if="station" class="f-14 px-3 f-500 mb-1 tres-puntos"> Estación: {{ station }} </p>
            <p v-if="section" class="f-14 px-3 f-500 mb-1 tres-puntos"> Sección: {{ section }} </p>
            <p v-if="configuration" class="f-12 px-3">Configuración: {{ configuration }} </p>
            <p v-if="type" class="f-12 px-3">Tipo: {{ type }} </p>
            <div class="row mx-0">
                <div class="col-auto">
                    <p class="f-12 d-middle">
                        <i class="icon-history f-20" />
                        {{ status }}
                    </p>
                </div>
                <div v-if="date" class="col-auto">
                    <p class="f-12 d-middle">
                        <i class="icon-calendar-check f-20" />
                        {{ date }}
                    </p>
                </div>
            </div>
        </div>

        <div>
            <div class="bg-db w-100 mt-1" style="height: 4px;">
                <div class="bg-general" :style="`width: ${percentage}%; height: 4px;`" />
            </div>
            <div class="bg-porcentaje">
                <div class="row mx-0 justify-content-between">
                    <div class="col-auto my-auto">
                        <p class="text-general f-18 f-700 py-2"> {{ percentage }}%</p>
                    </div>
                    <div class="col-auto my-auto">
                        <el-rate v-if="valueServicio" v-model="valueServicio" 
                        disabled
                        show-score
                        text-color="#FF9800" disabled-void-color="#9d9d9d" class="rate-servicios" />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props:{
        name:{
            type: String,
        },
        section:{
            type: String,
        },
        project:{
            type: String,
        },
        configuration: {
              type: String,
        },
        station: {
            type: String,
        },
        type:{
            type: String
        },
        status:{
            type: String
        },
        date:{
            type: String
        },
        valueServicio:{
            type: Number
        },
        percentage:{
            type: Number
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
.card-servicio{
    width: 356px;
    height: 160px;
        .icon-history{
            &::before{
                margin-left: 0px;
            }
        }
    .bg-porcentaje{
        background: #F1F2F4;
    }
}
</style>