import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class GetStagesUseCase extends UseCase {
    static async execute(params){
        const data = await Stages.getStages()
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetStagesUseCase.execute