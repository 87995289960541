import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class DeleteStageUseCase extends UseCase {
    static async execute(params){
        const { id } = params
        const data = await Stages.deleteStage({id})
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default DeleteStageUseCase.execute