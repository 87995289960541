import { StockMaterialesFilter } from "../StockMaterialesFilter"

export class StockMaterialesFilterFactory {
    static instantiate(obj){
        return new StockMaterialesFilter(obj)
    }

    static instantiateEmpty(){
        const filter = new StockMaterialesFilter({
            id_unidad_medida: null,
            id_tipo_material: null,
            estado: [],
            valor_inicial: 0,
            valor_final: 0,
            fecha_inicio: null,
            fecha_fin: null,
            solicitante: null,
			taller: null
        })
        filter.setExceptions({
            valor_inicial: 0,
            valor_final: 0
        })
        return filter
    }

    static instantiateEmptyWithRange(rangeUnidad){
        const valor_inicial = rangeUnidad?.min ?? 0;
        const valor_final = rangeUnidad?.max ?? 0;

        const filter = new StockMaterialesFilter({
            id_unidad_medida: null,
            id_tipo_material: null,
            estado: [],
            valor_inicial,
            valor_final,
            fecha_inicio: null,
            fecha_fin: null,
			solicitante: null,
			taller: null
        })
        filter.setExceptions({
            valor_inicial,
            valor_final
        })
        return filter
    }
}