import { UseCase } from "~/domain/core/UseCase";
import unitMeasure from "~/services/configurar/almacen/measure_unit"

class ListUnitMeasureTypeUseCase extends UseCase {
    static async execute(){
        const data = await unitMeasure.listUnitMeasure()
        return data?.data?.map(d => ({...d, edit: false})) ?? []
    }
}

export default ListUnitMeasureTypeUseCase.execute