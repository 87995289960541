import { UseCase } from "~/domain/core/UseCase";
import { CotizacionVigente } from '~/services/cotizaciones/cotizacion_vigente'
import { CotizacionEnviadaFactory } from '~/domain/entities/cotizaciones/principal/factories/cotizacionEnviadaFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetCotizacionEnviada extends UseCase{
    static async execute(payload){
        const  data  =  await CotizacionVigente.getCotizacionesEnviadas()
        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    CotizacionEnviadaFactory.instantiate
                ).toTable();
            }
        })
    }
}

export default GetCotizacionEnviada.execute