import Api from '~/services/api'

export const CotizacionVigente = {
    getCotizacionesVigentes(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`/cotizaciones/vigentes`,payload)
    },
    getCotizacionesEnviadas(){
        const ApiHandler = new Api()
        return ApiHandler.get(`/cotizaciones/enviadas`)
    },
    getEstadoCotizacion(){
        const ApiHandler = new Api()
        return ApiHandler.get(`/cotizaciones/vigentes/estados`)
    },
}


