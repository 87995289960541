<template>
    <div class="d-flex card-mini-user">
        <img :src="img | helper-storage" :class="`${tamanoImg} rounded-circle border`" :width="tamanoImg" :height="tamanoImg">
        <div class="my-auto ml-2">
            <p class="f-14" :class="fw">{{ nombre }}</p>
            <p v-if="cargo" class="f-12">{{ cargo }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        img:{
            type: String,
            default: '/img/pruebas/person1.png',
            required: false
        },
        tamanoImg:{
            type: String,
            default: '48',
            required: false
        },
        nombre:{
            type: String,
            default: 'Sin nombre',
            required: true
        },
        cargo:{
            type: String,
            default: '',
            required: false
        },
        fw:{
            type: String,
            required: false
        }
    }

}
</script>