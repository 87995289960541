import { UseCase } from '~/domain/core/UseCase';
import { Obras } from '~/services/obras/obras';
import { ActiveObraFactory } from '~/domain/entities/obras/factories/ActiveObraFactory';
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator';
import { $try } from '~/services/global';

class GetActiveObrasUseCase extends UseCase {
  static async execute(query) {
    const data = await Obras.getActiveObras(query);
    return $try(() => {
      if(data?.data){
        return new PaginationDecorator(
          data.data,
          ActiveObraFactory.instantiate
        )
      }
    });
  }
}

export default GetActiveObrasUseCase.execute