import { calificacion } from "../calificacion";

export class CalificacionFactory {
    static instantiate(obj){
        return new calificacion(obj)
    }

    static instantiateEmpty(){
        return new calificacion({
            id: undefined,
            nombre: '',
            id_tipo: '',
            posicion: ''
        })
    }
}