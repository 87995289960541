import Vue from 'vue'
import lodash from 'lodash'
import './vee-validate'
import './draggable'
import './slim'
import './fullCalendar'
import "./leaflet"
import "~/plugins/dflip/css/dflip.min.css";
import "~/plugins/dflip/css/themify-icons.min.css"
// import './moment'

import FlipBook from "../mixins/FlipBook";
Vue.mixin(FlipBook);
Vue.prototype._ = lodash



import * as XLSX from 'xlsx/xlsx.mjs';

/* load 'fs' for readFile and writeFile support */
import * as fs from 'fs';
XLSX.set_fs(fs);

/* load 'stream' for stream support */
import { Readable } from 'stream';
XLSX.stream.set_readable(Readable);

/* load the codepage support library for extended support with older formats  */
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
XLSX.set_cptable(cpexcel);