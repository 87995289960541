import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivities } from "~/services/almacen/mantenimiento/actividades"
import { ActivityFactory } from '~/domain/entities/almacen/mantenimiento/actividades/factories/ActivityFactory'
import { $try } from '~/services/global'

class EditMaintenanceActivityUseCase extends UseCase {
    static async execute(params){
        const data = await MaintenanceActivities.editMaintenanceActivity(params)

        return $try(() =>  {
            if(data?.data) return ActivityFactory.instantiate(data.data)
        })
    }
}

export default EditMaintenanceActivityUseCase.execute
