import { UseCase } from "~/domain/core/UseCase";
import { Bodegas } from '~/services/bodegas/bodegas';
import { $try } from '~/services/global';

class WarehouseDetailPurchaseOrdersUseCase extends UseCase {
    static async execute({ id, buscar }) {
        const data = await Bodegas.warehouseDetailPurchaseOrders(id, buscar)

        return $try(() => {
            return data?.data ?? {}
        })
    }
}
export default WarehouseDetailPurchaseOrdersUseCase.execute