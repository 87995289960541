import { Entity } from '~/domain/entities'

export class PedidoInternoFiltros extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
				'proyectos',
				'equipos',
				'solicitantes'
            ]
        })
    }
}