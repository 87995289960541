import { UseCase } from "~/domain/core/UseCase";
import { Gallery } from '~/services/obras/gallery/gallery'
import { $try } from '~/services/global'

class DeleteFolderUseCase extends UseCase {
    static async execute(params){
        const data = await Gallery.deleteFolder(params)
        
        return $try(() => data?.message)
    }
}

export default DeleteFolderUseCase.execute