import * as ListCheck from '~/domain/use_cases/obras/servicios/lista_chequeo';

export const state = {
    detalleServicio: {},
    listasChequeo: [],
}

export const getters = {
    detalleServicio: state => state.detalleServicio,
    listasChequeo: state => state.listasChequeo
}

export const mutations = {
    set_detalle_servicio(state, payload) {
        state.detalleServicio = payload
    },
    set_listas_chequeo(state, payload) {
        state.listasChequeo = payload
    }
}

export const actions = {
    async Action_get_detalle_servicio({ commit }, payload) {
        const data = await ListCheck.GetDetalleServicioUseCase(payload);
        commit('set_detalle_servicio', data);
    },
    async Action_get_listas_chequeo({ commit }, payload) {
        const data = await ListCheck.GetListaChequeoUseCase(payload);
        commit('set_listas_chequeo', data);
    },
    async Action_create_lista_chequeo({ commit }, params) {
        const data = await ListCheck.CreateListaChequeoUseCase(params);
    },
    async Action_edit_lista_chequeo({ commit }, params) {
        const data = await ListCheck.EditListaChequeoUseCase(params);
    },
    async Action_delete_lista_chequeo({ commit }, payload) {
        const data = await ListCheck.DeleteListaChequeoUseCase(payload);
        if (data) commit('set_listas_chequeo', state.listasChequeo.filter(el => el.id !== payload.id_control_pisos_lista_chequeo));
    },
    async Action_add_actividad({ commit }, params) {
        const data = await ListCheck.AddActividadUseCase(params);
    },
    async Action_edit_actividad({ commit }, params) {
        const data = await ListCheck.EditActividadUseCase(params);
    },
    async Action_delete_actividad({ commit }, payload) {
        const data = await ListCheck.DeleteActividadUseCase(payload);
        if (data) commit('set_listas_chequeo', state.listasChequeo.map(({actividades, ...rest}) => {
            if (rest.id_configuracion_control_piso_lista_chequeo === payload.id_control_pisos_lista_chequeo){
                return {actividades: actividades.filter(f => f.id !== payload.id_configuracion_control_piso_lista_chequeo_actividades), ...rest}
            }
            return {actividades, ...rest}
        }));
    },
    async Action_add_adjuntos_actividad({ commit }, params) {
        const data = await ListCheck.AddAdjuntoActividadUseCase(params);
    },
}