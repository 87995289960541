import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { CustomersFactory } from '~/domain/entities/oportunidades/information/factories/CustomersFactory'
import { $try } from '~/services/global'

class GetOpportunityCustomersUseCase extends UseCase {
    static async execute(payload){
        const data = await Opportunities.getCustomersOpportunity(payload)
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(type => CustomersFactory.instantiate(type))
        })
    }
}

export default GetOpportunityCustomersUseCase.execute