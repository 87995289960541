import Api from '~/services/api'

export const Cartera = {
    getCobroCarteraPagarFiltro(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cobro-cartera/cuentas-por-pagar/filtro`, payload)
    },
    getCobroCarteraCobrarFiltro(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cobro-cartera/cuentas-por-cobrar/filtro`, payload)
    },
    getCobroCarteraPagarGrafica(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cobro-cartera/cuentas-por-pagar/grafica`, payload)
    },
    getCobroCarteraCobrarGrafica(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cobro-cartera/cuentas-por-cobrar/grafica`, payload)
    },
    getCobroCarteraPagar(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cobro-cartera/cuentas-por-pagar`, payload)
    },
    getCobroCarteraCobrar(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cobro-cartera/cuentas-por-cobrar`, payload)
    }
}