import Api from '~/services/api'

export const Workshops = {
    getWorkshops(payload){
        const Service = new Api()
        return Service.get(`talleres`, payload)
    },
    getServiceRange(){
        const Service = new Api()
        return Service.get(`talleres/rango-servicio`)
    },
    getServicesTypesGraph(){
        const Service = new Api()
        return Service.get(`talleres/tipo-servicios`)
    },
    getServicesGraph(){
        const Service = new Api()
        return Service.get(`talleres/servicios-mes`)
    },
    getSelectResponsibleUsers(){
        const Service = new Api()
        return Service.get(`select/responsables-talleres`)
    },
    saveWorkshop(payload){
        const Service = new Api({ notification: true })
        return Service.post('talleres', payload)
    },
    updateWorkshop(id_taller, payload){
        const Service = new Api()
        return Service.put(`talleres/${id_taller}`, payload)
    },
    deleteWorkshop(id_taller){
        const Service = new Api({ notification: true })
        return Service.delete(`taller/${id_taller}`)
    },
    saveFunctionary(payload){
        const Service = new Api({ notification: true })
        return Service.post('talleres/usuario-responsable', payload)
    }
}