import Api from '~/services/api'

export const Obras = {
    getActiveObras(query){
        const Service = new Api()
        return Service.get('obras/vigentes', query)
    },
    getFinishedObras(params){
        const Service = new Api()
        return Service.get('obras/finalizadas', params)
    },
    getFinishedObrasRange(params){
        const Service = new Api()
        return Service.get('obras/finalizadas/filtro-rangos-valores', params)
    }
}