import Api from '~/services/api'

export const Calendario = {
    getCalendarioAnnio(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`calendario/anio`, payload)
    },
    getEquipos(){
        const ApiHandler = new Api()
        return ApiHandler.get(`calendario/equipos`)
    },
    getOpsFechasProximas(){
        const ApiHandler = new Api()
        return ApiHandler.get(`calendario/anio/alerta`)
    },
    getCabeceraCalendario(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`calendario/cabecera-calendario`, payload)
    },
    getCalendarioMes(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`calendario/mes`, payload)
    },
    getCalendarioSemana(payload){
        const ApiHandler = new Api()
        return ApiHandler.post(`calendario/semana`, payload)
    },
    getDetalleTarea(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`calendario/detalle-tarea/${payload}`)
    },
}