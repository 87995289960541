

export default [
    {
        path: '/configurar',
        name: 'configurar',
        redirect: {name: 'configurar.banners'},
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar').then(m => m.default || m),
        children: [
            {
                path: 'banners',
                meta: { nombre: 'banners' },
                name: 'configurar.banners',
                component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/banners').then(m => m.default || m),
            },
            {
                path: 'almacen',
                meta: { nombre: 'almacén' },
                redirect: {name: 'configurar.almacen.tipo_material'},
                name: 'configurar.almacen',
                component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/almacen/').then(m => m.default || m),
                children: [
                    {
                        path: 'unidad-medida',
                        meta: { nombre: 'unidades de medida' },
                        name: 'configurar.almacen.unidad',
                        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/almacen/unidadMedida.vue').then(m => m.default || m),
                    },
                    {
                        path: 'tipos-de-material',
                        meta: { nombre: 'tipos de material' },
                        name: 'configurar.almacen.tipo_material',
                        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/almacen/tiposDeMaterial.vue').then(m => m.default || m),
                    },
                    {
                        path: 'datos-adicionales',
                        meta: { nombre: 'datos adicionales' },
                        name: 'configurar.almacen.datos_adicionales',
                        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/almacen/datosAdicionales.vue').then(m => m.default || m),
                    },
                    {
                        path: 'valor-hora-hombre',
                        meta: { nombre: 'valor de hora/hombre' },
                        name: 'configurar.almacen.valor_hora_hombre',
                        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/almacen/valorHoraHombre.vue').then(m => m.default || m),
                    },
                    {
                        path: 'incrementos-porcentuales',
                        meta: { nombre: 'incrementos porcentuales' },
                        name: 'configurar.almacen.incrementos_porcentuales',
                        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/almacen/incrementosPorcentuales.vue').then(m => m.default || m),
                    },
                    {
                        path: 'estados',
                        meta: { nombre: 'estados pedidos' },
                        name: 'configurar.almacen.estados.pedidos.internos',
                        component: () => import('../../pages/configurar/almacen/estadosPedidosInternos.vue').then(m => m.default || m),
                    },

                ]
            },
            {
                path: 'obras',
                meta: { nombre: 'Obras' },
                name: 'configurar.obras',
                redirect: {name: 'configurar.obras.calendarios.generales'},
                component: () => import('../../pages/configurar/obras').then(m => m.default || m),
                children: [
                    {
                        path: 'calendarios_generales',
                        meta: { nombre: 'Calendarios generales' },
                        name: 'configurar.obras.calendarios.generales',
                        component: () => import('../../pages/configurar/obras/calendarioGeneral.vue').then(m => m.default || m),
                    },
                    {
                        path: 'calendarios_talleres',
                        meta: { nombre: 'Calendarios talleres' },
                        name: 'configurar.obras.calendarios.talleres',
                        component: () => import('../../pages/configurar/obras/calendarioTalleres.vue').then(m => m.default || m),
                    },
                    {
                        path: 'carpetas',
                        meta: { nombre: 'carpetas' },
                        name: 'configurar.obras.carpetas',
                        component: () => import('../../pages/configurar/obras/carpetas.vue').then(m => m.default || m),
                    },
                    {
                        path: 'lista_de_chequeo',
                        meta: { nombre: 'Lista de chequeo' },
                        name: 'configurar.obras.lista.chequeo',
                        component: () => import('../../pages/configurar/obras/listaChequeo.vue').then(m => m.default || m),
                    },
                    {
                        path: 'calificacion',
                        meta: { nombre: 'Calificación servicio' },
                        name: 'configurar.obras.calificacion.servicio',
                        component: () => import('../../pages/configurar/obras/calificacionServicio.vue').then(m => m.default || m),
                    },

                ]
            },
            {
                path: 'talleres',
                meta: { nombre: 'Talleres' },
                name: 'configurar.talleres',
                redirect: {name: 'configurar.talleres.tipos.servicios'},
                component: () => import('~/pages/configurar/talleres').then(m => m.default || m),
                children: [
                    {
                        path: 'tipos_servicios',
                        meta: { nombre: 'Tipos de servicios' },
                        name: 'configurar.talleres.tipos.servicios',
                        component: () => import('~/pages/configurar/talleres/service_types').then(m => m.default || m),
                    },
                ]
            },
            {
                path: 'paises',
                meta: { nombre: 'Paises' },
                name: 'configurar.paises',
                redirect: { name: 'configurar.paises.listado' },
                component: () => import('~/pages/configurar/paises').then(m => m.default || m),
                children: [
                    {
                        path: 'listado',
                        meta: { nombre: 'Listado de paises' },
                        name: 'configurar.paises.listado',
                        component: () => import('~/pages/configurar/paises/listado').then(m => m.default || m),
                    } 
                ]
            },
            {
                path: 'proveedores',
                meta: { nombre: 'Proveedores' },
                name: 'configurar.proveedores',
                component: () => import('~/pages/configurar/proveedores').then(m => m.default || m),
            },            
            {
                path: 'permisos',
                meta: { nombre: 'Permisos' },
                name: 'configurar.permisos',
                redirect: { name: 'configurar.permisos.acciones'},
                component: () => import('~/pages/configurar/permisos').then(m => m.default || m),
                children: [
                    {
                        path: 'acciones',
                        meta: { nombre: 'Acciones' },
                        name: 'configurar.permisos.acciones',
                        component: () => import('~/pages/configurar/permisos/acciones/index').then(m => m.default || m),
                    } ,
                    {
                        path: 'rutas',
                        meta: { nombre: 'Rutas' },
                        name: 'configurar.permisos.rutas',
                        component: () => import('~/pages/configurar/permisos/rutas/index').then(m => m.default || m),
                    }  
                ]
            },
            {
                path: 'permisos/acciones/menu/:id/grupo/:tab',
                meta: { nombre: 'Acciones' },
                name: 'configurar.permisos.acciones.menu',
                redirect: { name: 'configurar.permisos.acciones.menu.grupo' },
                component: () => import('~/pages/configurar/permisos/acciones/menu').then(m => m.default || m),
                children: [
                    {
                        path: 'permisos',
                        meta: { nombre: 'Acciones - menu - grupo' },
                        name: 'configurar.permisos.acciones.menu.grupo',
                        component: () => import('~/pages/configurar/permisos/acciones/grupo').then(m => m.default || m)
                    }
                ]
            },
            {
                path: 'permisos/rutas/detalle/:id/grupo/:tab',
                meta: { nombre: 'Rutas - detalle' },
                name: 'configurar.permisos.rutas.detalle',
                redirect: { name: 'configurar.permisos.rutas.detalle.grupo' },
                component: () => import('~/pages/configurar/permisos/rutas/detalle').then(m => m.default || m),
                children: [
                    {
                        path: 'permisos',
                        meta: { nombre: 'Rutas - menu - grupo' },
                        name: 'configurar.permisos.rutas.detalle.grupo',
                        component: () => import('~/pages/configurar/permisos/rutas/detalle').then(m => m.default || m)
                    }
                ]
            },
        ]
    }
]
