import { ValueObject } from '~/domain/core/ValueObject'

export class CalendarVO extends ValueObject{
    constructor(obj){
        super({
            params: obj, 
            keys: ['nombre', 'id']
        });
    }
}
