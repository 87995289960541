import { Entity } from '~/domain/entities'

export class Compra extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
				'proyecto',
				'proveedor',
				'cliente',
				'unidades',
				'fecha',
				'valor',
				'pago'
            ]
        })
    }
}