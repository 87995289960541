import { UseCase } from "~/domain/core/UseCase";
import { Cotizaciones } from '~/services/users_prividers/cotizacionesPendientes.js'
import { CotizacionesFactory } from '~/domain/entities/users_providers/factories/CotizacionesFactory.js'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetCotizacionesPendientesUseCase extends UseCase {
    static async execute(payload){
        const data =  await Cotizaciones.getCotizacionesPendientes(payload)

        return $try(() => {
            if( data?.data ){
                return new PaginationDecorator( data.data, CotizacionesFactory.instantiatePendiente );
            }
        })
    }
}

export default GetCotizacionesPendientesUseCase.execute