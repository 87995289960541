import { Filter } from '~/domain/core/Filter'

export class PresenciaEquiposFilter extends Filter {
    constructor(obj){
        super({
            params: obj,
            keys: [ 
                'id_equipo',
                'id_cliente',
                'fecha_venta_desde',
                'fecha_venta_hasta',
                'precio_minimo',
                'precio_maximo',
            ]
        })
    }
}