<template>
    <div class="card card__size card__size cr-pointer position-relative select-none" @click="redirect(element.route)">
        <div class="icon__center icon__color h-100">
            <i :class="`${element.icon} f-50`"></i>
        </div>
        <div class="text-bottom">{{element.name}}</div>
    </div>
</template>

<script>
export default {
    props:{
        element: { type: Object, default: () => {} }
    },
    methods:{
        redirect(route){
            if(!route) return;
            this.$store.commit('menu/SHOW_MENU', false)
            this.$emit('hideMenu');
            this.navigateTo({ name: route });
        }
    }
}
</script>

<style scoped>
.card__size{
    height: 100px; 
    width: 100px;
}

.card__shadow{
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
}

.icon__center{
    display: grid;
    place-items: center;
}

.icon__color{
    color: #5D5D5D;
}

.text-bottom{
    position: absolute;
    width: calc(100% + 10px);
    height: 25px;
    text-align: center;
    left: -5px;
    bottom: -30px;
    color: white;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, .8);
}
.select-none{
	user-select: none;
}
</style>