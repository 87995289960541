import { UseCase } from "~/domain/core/UseCase";
import { Funcionariosroveedores } from '~/services/proveedores/funcionariosProveedores'
import { $try } from '~/services/global'

class GetFuncionariosProveedoresUseCase extends UseCase {
    static async execute({idProveedor, page}){
        const data = await Funcionariosroveedores.getUsuarioProveedor({idProveedor, page})
        return $try(() => {
            return data.data
        })
    }
}

export default GetFuncionariosProveedoresUseCase.execute