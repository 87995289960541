import { Entity } from '~/domain/entities'

export class CategoryMaterials extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'idMaterialConfig',
				'unidades',
				'id',
				'referencia',
				'nombre',
				'descripcion',
				'estado',
				'id_unidad_medida',
				'id_tipo_material',
				'created_at',
				'created_by',
				'tipo',
				'unidad_medida',
				'valor_cotizado',
				'mantenimiento',
				'mantenimientos'
            ]
        })
    }
}