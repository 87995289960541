<template>
    <div class="row my-3 titulo-divisor" :class="clase">
        <div v-if="titulo != ''" class="col-auto my-auto d-middle-center">
            <p class="f-600 f-14 ucfirst " :style="estilo">{{titulo}}</p>
            <p class="f-12 font-italic ml-2 ucfirst " :style="estilo">{{subTitulo}}</p>
        </div>
        <slot v-else name="titulo"/>
        <div class="col my-auto pl-0">
            <hr class="my-0 bg-white" />
        </div>
        <div class="col-auto my-auto">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props:{
        titulo:{
            type: String,
            default: () => ''
        },
        subTitulo:{
            type: String,
            default: () => ''
        },
        clase:{
            type: String,
            default: () => ''       
        },
        estilo: {
            type: String,
            default: () => 'text-general'
        }
    }
}
</script>
