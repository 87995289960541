import { UseCase } from "~/domain/core/UseCase";
import { Cotizaciones } from '~/services/users_prividers/cotizacionesPendientes.js'
import { MaterialCotizacionFactory } from '~/domain/entities/users_providers/factories/MaterialCotizacionFactory.js'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'
class GetMaterialCotizacionUseCase extends UseCase {
    static async execute(params){
        const data =  await Cotizaciones.getMaterialesCotizaciones(params)

        return $try(() => {
            if( data?.data ){
                return new PaginationDecorator( data.data, MaterialCotizacionFactory.instantiate ).toTable();
            }
        })
    }
}

export default GetMaterialCotizacionUseCase.execute