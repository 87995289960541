import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class GetRangeValueConfigUseCase extends UseCase {
    static async execute(params){
        const data = await Stages.getRangeValueConfig(params)
        return $try(() => {
            return data?.data ?? {}
        })
    }
}

export default GetRangeValueConfigUseCase.execute