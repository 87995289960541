<template>
    <div class="row mx-0 tabs border-bottom" :class="`${inline ? 'd-flex flex-nowrap overflow-auto scroll-none' : ''}`">
        <template v-for="(item,index) in tabs">
            <a
            v-if="item.callback && typeof item.callback == 'function'" 
            :key="index"
            :class="{'router-link-active': subIsActive(item.active), 'white-space-nowrap' : inline}"
            class="router-link-exact-active d-middle position-relative tab-inner f-600 cr-pointer"
            >
                <p class="nombre-tab" @click="item.callback()">{{ item.titulo }}</p>
            </a>
            <router-link
            v-else-if="user.super_admin || user.permisos.rutas.some(el => el.name_ruta == item.ruta) || user.tipo == 4"
            :data-xd="item.ruta"
            :to="{ name: item.ruta, params: item.params || {} }"
            :class="{'router-link-active': subIsActive(item.active), 'white-space-nowrap' : inline}"
            class="d-middle position-relative tab-inner f-600"
            >
                <el-tooltip :disabled="item.component_name==$route.ruta" class="item" effect="light" :content="item.titulo" placement="top">
                    <i v-if="item.icono" :class="item.icono" class="mdi f-20" />
                </el-tooltip>
                <p class="nombre-tab">{{ item.titulo }}</p>
                <div v-if="item.num !== undefined" class="ml-2 bg-general text-white rounded-pill f-13 text-center" style="width:26px;">
                    {{ item.num }}
                </div>
            </router-link>
        </template>
        <slot name="btnAgregar" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'TabsIcons',
    props: {
        tabs: {
            type: Array,
            default: () => []
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    computed: {
		...mapGetters({
			user: 'auth/user',
		}),
        isActive() {
            return this.$route.name
        }
    },
    methods: {
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.tabs{
    // padding-bottom: 6px;
    // border-bottom: 2px solid #32A3FD;
    // box-shadow: 0px 2px 2px #d0d0d0;
    .item-tab{
        min-width: 40px;
        background: var(--color-general);
        color: #ffffff;
    }
    a{
        padding: 8px 15px !important;
        color: var(--color-gray) ;
        font-size: 14px;
        border-bottom: 2px solid transparent !important;
        font-weight: 400 ;
        
        &.router-link-active{
            padding: 8px 15px;
            border-bottom: 2px solid var(--color-general) !important;
            color: var(--color-general) !important;
            .nombre-tab{
                display: inline-block;
            }
            .item-tab{
                background: var(--color-general);
                color: white;
            }
        }
        &:hover{
            text-decoration: none !important;
        }
    }
    .white-space-nowrap{
        white-space: nowrap;
    }
}
</style>
