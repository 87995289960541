import { UseCase } from "~/domain/core/UseCase";
import { ListaChequeo } from '~/services/obras/servicios/lista_chequeo'
import { CheckListFactory } from '~/domain/entities/obras/servicios/lista_chequeo/factories/checkListFactory'
import { ActividadFactory } from '~/domain/entities/obras/servicios/lista_chequeo/factories/actividadFactory'
import { AdjuntoFactory } from '~/domain/entities/obras/servicios/lista_chequeo/factories/adjuntoFactory'
import { $try } from '~/services/global'

class GetListaChequeoUseCase extends UseCase {
    static async execute(payload){
        const data =  await ListaChequeo.getCheckListies(payload)
        return $try(() => {
            if(!data?.data || !Array.isArray(data.data)) return []

            return data.data.map(({actividades, ...rest}) => CheckListFactory.instantiate({
                ...rest,
                actividades: actividades.map( ({adjuntos, ...rest}) => ActividadFactory.instantiate({
                    ...rest,
                    adjuntos: adjuntos.map( el => AdjuntoFactory.instantiate(el) )
                }))
            }))
        })
    }
}

export default GetListaChequeoUseCase.execute
