import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'
import { OpcionCalificacionFactory } from '~/domain/entities/configurar/obras/calificacion_servicio/factories/opcionCalificacionFactory'
import { $try } from '~/services/global'

class AddQuestionToWorkShopUseCase extends UseCase {
    static async execute(params){
        const data = await CalificacionService.addQuestionToWorkShop(params)
        return data?.data
    }
}

export default AddQuestionToWorkShopUseCase.execute