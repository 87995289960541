import { UseCase } from "~/domain/core/UseCase";
import { PurchaseOrder } from '~/services/compras/ordenes_compra'
import { $try } from '~/services/global'

class GetFiltrosOrdenesCompraGruposUseCase extends UseCase {
    static async execute(){
        const data = await PurchaseOrder.getFiltrosGruposOcs()
        return $try(() => {
            return data?.data ?? {}
        });
    }
}

export default GetFiltrosOrdenesCompraGruposUseCase.execute