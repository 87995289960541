// import { StagesDomain, MaterialDomain, } from '~/domain/use_cases/almacen'
import * as StagesDomain from '~/domain/use_cases/almacen/stages'
import { StageFilterFactory } from '~/domain/filters/almacen/etapas/factories/StageFilterFactory'
import { $try } from '~/services/global';
// state
export const state = {
    stages: [],
    stage: {},
    stagesSelect: [],
    stagesConfigurations: [],
    stagesConfiguration: {},
    configurationsSelect: [],
    materialsSelect: [],
    materials: [],
    material: {},
    tableMaterials: {
        data: [],
        pagination: {
            current_page: 1
        },
    },
    filter: StageFilterFactory.instantiateEmpty(),
    rangesFilter: {},
    datosExcel: [],
    errors_excel: [],
    error_subiendo: null,
    contarMateriales: [],
    query: ''
}

// getters
export const getters = {
    stages: state => state.stages,
    stage: state => state.stage,
    stagesSelect: state => state.stagesSelect,
    stagesConfigurations: state => state.stagesConfigurations,
    stagesConfiguration: state => state.stagesConfiguration,
    configurationsSelect: state => state.configurationsSelect,
    materialsSelect: state => state.materialsSelect,
    materials: state => state.materials,
    material: state => state.material,
    contarMateriales: state => state.contarMateriales,
    tableMaterials: state => state.tableMaterials.data,
    //filters
    pagination: state => state.tableMaterials.pagination,
    query: state => state.query,
    filter: state => state.filter,
    rangeValueFilter: state => state.rangesFilter,
    datosExcel: state => state.datosExcel,
    errors_excel: state => state.errors_excel,
    error_subiendo: state => state.error_subiendo,
}

// mutations
export const mutations = {
    set_stage(state, payload) {
        state.stage = payload
    },
    set_stages(state, payload) {
        state.stages = payload
    },
    push_stages(state, payload) {
        state.stages  = [...state.stages, payload]
    },
    // stage configurations
    set_configuration(state, payload) {
        state.stagesConfiguration = payload
    },
    set_configurations(state, payload) {
        state.stagesConfigurations = payload
    },
    push_configurations(state, payload) {
        state.stagesConfigurations = payload
    },
    // stage materials configurations
    set_materials_configurations_select(state, payload) {
        state.materialsSelect = payload
    },
    set_materials_configurations(state, payload) {
        state.materials = payload
    },
    push_materials_configurations(state, payload) {
        state.materials = payload
    },
    set_stages_select(state, payload) {
        state.stagesSelect = payload
    },
    set_configurations_select(state, payload) {
        state.configurationsSelect = payload
    },
    // table data
    set_info_table(state, payload) {
        state.tableMaterials = payload
    },
    update_maintenance_element(state, payload){
        let element = state.tableMaterials.find(el => el.id == payload.id);
        if(element) element = payload
    },
    //filters
    set_filter(state, payload){
        state.filter = payload
    },
    set_range_value_conf(state, payload){
        state.rangesFilter = payload
    },
    prueba_excel(state, payload){
        state.datosExcel = payload
    },
    set_errors_file(state, payload){
        state.errors_excel = payload
    },
    set_error_subiendo(state, payload){
        state.error_subiendo = payload
    },
    set_query(state, payload){
        state.query = payload
    },
    set_pagination(state, payload){
        state.tableMaterials.pagination = payload
    },
    set_contar_materiales(state, payload){
        state.contarMateriales = payload
    }
}

// actions
export const actions = {
    async Action_get_stages({ commit }, payload) {
        const stages = await StagesDomain.GetStagesUseCase()
        $try(() => {
            commit('set_stages', stages)
        });
    },
    async Action_create_stage({ commit, state }, payload) {
        const stage = await StagesDomain.CreateStagesUseCase(payload)
        return $try(() => {
            if(stage) {
                commit('push_stages', stage);
                return { id: stage.id };
            }
        });
    },
    async Action_get_stage({ commit }, id) {
        const stageDetails = await StagesDomain.GetStageDetailUseCase({ id })
        $try(() => {
            commit('set_stage', stageDetails);
        });
    },
    async Action_update_stage({ commit }, payload) {
        const stage = await StagesDomain.UpdateStageUseCase(payload)
        $try(() => {
            if(stage) commit('set_stage',stage)
        })
    },
    async Action_delete_stage({ commit }, id) {
        await StagesDomain.DeleteStageUseCase({ id })
    },
    // stage configurations
    async Action_get_configurations({ commit }, payload) {
        const configurations = await StagesDomain.GetStageConfUseCase({ id: payload.id_etapa })
        $try(
            () => commit('set_configurations', configurations)
        );
    },
    async Action_create_configuration({ commit }, payload) {
        const configuration = await StagesDomain.CreateStageConfUseCase(payload)
        return $try(() => {
            //commit('push_configurations', configuration)
            return { id: configuration.id };
        })
    },
    async Action_get_configuration({ commit }, payload) {
        const configurationDetails = await StagesDomain.GetStateConfDetailsUseCase(payload)
        $try(
            () => commit('set_configuration', configurationDetails)
        )
    },
    async Action_update_configuration({ commit }, payload) {
        await StagesDomain.UpdateStageConfUseCase(payload)
    },
    async Action_delete_configuration({ commit }, payload) {
        await StagesDomain.DeleteStageConfUseCase(payload)
    },
    // stage configurations materials
    async Action_get_materials_configuration_select({ commit }, id_etapa, idConfiguration) {
        const configurationMaterials = await StagesDomain.GetConfMaterialsUseCase({ id_etapa, id_configuracion: idConfiguration })
        $try(
            () => commit('set_materials_configurations_select', configurationMaterials)
        )
    },
    async Action_add_material_configuration({ commit }, payload) {
        const configurationMaterial = await StagesDomain.CreateConfMaterialUseCase(payload)
        $try(
            () => commit('push_materials_configurations', configurationMaterial)
        )
    },
    async Action_add_material_configuration_clone({ commit, state }, payload) {
        const configurationMaterialClone = await StagesDomain.CreateConfMaterialCloneUseCase(payload)
        $try( 
            () => commit('push_materials_configurations', configurationMaterialClone,state.materialsSelect)
        );
    },
    async Action_get_stages_select({ commit }, id_etapa, idConfiguration) {
        const stagesSelect = await StagesDomain.GetStageSelectUseCase({ id_etapa, id_configuration: idConfiguration })
        $try( 
            () => commit('set_stages_select', stagesSelect)
        )
    },
    async Action_get_configurations_select({ commit }, payload) {
        const stageConfSelect = await StagesDomain.GetStageConfSelectUseCase(payload)
        $try( 
            () => commit('set_configurations_select', stageConfSelect)
        );
    },
    // table data
    async Action_get_info_table({ state, commit, getters }, payload) {
        const { id_etapa, id_configuration } = payload;
        const buscar = state.query;
        const filter = state.filter.clean();
        const page = getters.pagination.current_page ?? 1;
        const tableInformation = await StagesDomain.GetTablesInformationUseCase(id_etapa, id_configuration, {...filter, page, buscar});
        $try(() => {
            commit("set_info_table", tableInformation);
        });
    },
    async Action_update_material_config({ commit }, payload) {
        await StagesDomain.UpdateMaterialConfigUseCase(payload)
    },
    async Action_delete_material_config({ commit }, payload) {
        await StagesDomain.DeleteMaterialConfUseCase(payload)

    },

    clear_filter_config({commit, state}){
        commit('set_filter', StageFilterFactory.instantiateEmptyWithRange(state.rangesFilter))
    },

    async Action_get_value_range({ commit }, payload){
        const ranges = await StagesDomain.GetRangeValueConfigUseCase(payload)
        $try(() => {
            commit('set_range_value_conf', ranges)
        })
    },
    async Action_get_cantidad_materiales({ commit }, payload){
        const mats = await StagesDomain.GetContarMaterialesUseCase(payload)
        $try(() => {
            commit('set_contar_materiales', mats)
        })
    },
    async Action_validate_excel({ commit }, payload){
        const data = await StagesDomain.ValidateExcelUseCase(payload)
        $try(() => {
            if (data?.data) commit('set_errors_file', data.data)
            if (data.message == 'Error insertando información') {
                commit('set_error_subiendo', {error:true,mensaje: data.message})
            } else {
                commit('set_error_subiendo', {error:false,mensaje: data.message})

            }
        })
    },

}