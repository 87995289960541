import { UseCase } from "~/domain/core/UseCase";
import { PresenciaEquipos } from '~/services/ventas/presenciaEquipos'
import { FiltroMaterialesFactory } from '~/domain/entities/ventas/factories/FiltroMaterialesFactory'
import { $try } from '~/services/global'

class GetFiltroMaterialesUseCase extends UseCase {
    static async execute(payload){
        const data = await PresenciaEquipos.getFiltroMateriales(payload)
        return $try(() => {
            if(data?.data) return new FiltroMaterialesFactory.instantiate(data.data)
        })
    }
}

export default GetFiltroMaterialesUseCase.execute