import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivities } from "~/services/almacen/mantenimiento/actividades"
import { AttachmentVOFactory } from '~/domain/value_objects/almacen/mantenimiento/actividades/factories/AttachmentVOFactory'
import { $try } from '~/services/global'
import  AWS  from '~/services/aws'

class CreateAttachmentUseCase extends UseCase {
    static async execute(params){
        const payload = await $try(
                async () => {
                    const { adjunto, ...rest } = params

                    const fileKey = await AWS.uploadFile({file: adjunto.archivo[0], path:'maintenance/'})

                    adjunto.archivo = fileKey
            
                    return {...rest, payload: adjunto}
                }
            )
        
        const data = await MaintenanceActivities.createActiviyFileAttachment(payload)

        return $try(() => {
            if(data?.data) return AttachmentVOFactory.instantiate(data)
        })
    }
}

export default CreateAttachmentUseCase.execute
