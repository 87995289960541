import { Entity } from '~/domain/entities'

export class OppotunityInformation extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'img_banner',
                'img',
                'cliente',
                'creacion',
                'identificacion',
                'cierre',
                'nombre_oportunidad',
                'tipo',
                'descripcion',
                'probabilidad',
                'valor',
                'estado_final',
                'comentario_final'
            ]
        });
    }
}