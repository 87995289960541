import { GraficaCobrar } from "../GraficaCobrar";

export class GraficaCobrarFactory {
    static instantiate(obj){
        return new GraficaCobrar(obj)
    }

    static instantiateEmpty(){
        return new GraficaCobrar({
            total_cobrar: '',
            grafica: '',
            tabla: '',
        })
    }
}