<template>
    <div class="nav-header-top">
        <nav class="navbar-expand-lg navbar-light bg-navbar estilo-nav box-shadow-nav d-middle position-fixed">
            <img :src="personalizacion.logo_activo | helper-storage-sandbox" height="35" class="img-company obj-cover ml-4"  />
            <div id="navbarToggler" class="collapse navbar-collapse mr-4">
                <div class="ml-auto mr-3">
                    <ul class="navbar-nav text-navbar">
                        <li class="nav-item notificaciones pt-1 mx-2">
                                <el-badge :value="count_not" class="item">
                                <el-popover placement="bottom" width="400" trigger="click" popper-class="p-3" >
                                    <div class="row mt-n2 text-white justify-content-between p-2 bg-general">
                                        <b>Notificaciones</b>
                                        <small class="cr-pointer" @click="updateAllNotifi()">
                                            Marcar como leidas
                                        </small>
                                    </div>
                                    <div class="row overflow-auto custom-scroll" style="max-height: calc(100vh - 200px)">
                                        <div class="col-12">
                                            <div v-if="list_pendiente.length > 0" class="row px-3 py-1 bg-whitesmoke">
                                                Recientes
                                            </div>
                                            <div v-for="data in list_pendiente" :key="data.id">
                                                <div class="row mx-0 mt-2 cr-pointer" @click="ver_notificacion(data)">
                                                    <img :src="data.usuario_involucrado.foto_miniatura | helper-storage" class="rounded-circle obj-cover my-auto mx-2" width="50" height="50" />
                                                    <div class="col-9">
                                                        <p class="f-12 font-weight-bold">{{data.texto}}</p>
                                                        <div class="row mx-0 justify-content-between f-9 text-muted">
                                                            <i>{{data.nombre}}</i>
                                                            <i>{{data.created_at | helper-fecha('DD MMM Y HH:mm a')}}</i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div v-if="list_pendiente.length == 0">
                                                <p class="f-12 text-muted text-center">No tiene nuevas notificaciones</p>
                                        </div>
                                        <div v-if="vermas_pendiente>0" class="row px-3 py-1 bg-general cr-pointer" @click="view_notifiaciones">
                                            Ver {{vermas_pendiente}} notificaciones más
                                        </div>
                                        <div v-if="list_leido.length >0" class="row px-3 py-1 bg-whitesmoke">
                                            Anteriores
                                        </div>
                                        <div v-for="data in list_leido" :key="data.id">
                                            <div class="row mx-0 mt-2 cr-pointer" @click="ver_notificacion(data)">
                                                <img :src="data.usuario_involucrado.foto_miniatura | helper-storage" class="rounded-circle obj-cover my-auto mx-2" width="50" height="50" />
                                                    <div class="col-9">
                                                        <p class="f-12 font-weight-bold">{{data.texto}}</p>
                                                            <div class="row mx-0 justify-content-between f-9 text-muted">
                                                                <i>{{data.nombre}}</i>
                                                                <i>{{data.created_at | helper-fecha('DD MMM Y HH:mm a')}}</i>
                                                            </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div v-if="vermas_leidas>0" class="row px-3 py-1 bg-general cr-pointer" @click="view_notifiaciones">
                                                Ver {{vermas_leidas}} notificaciones más
                                            </div>
                                        </div>
                                    </div>
                                    <i id="notifica" slot="reference" class="icon-notification f-22 cr-pointer" />
                                </el-popover>
                            </el-badge>
                        </li>
                    </ul>
                </div>
                <ul class="navbar-nav">
                    <!-- usuario Authenticated -->
                    <li class="nav-item dropdown text-navbar">
                        <a class="nav-link dropdown-toggle text-navbar d-flex d-middle-center" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                <img :src="user.foto_mini | helper-storage" class="rounded-circle profile-photo mr-1 border bg-whitesmoke" />
                                <div>
                                    <p class="f-15">{{ user.nombre }}</p>
                                    <p class="f-13">{{ user.rol && user.rol.nombre }}</p>
                                </div>
                        </a>
                        <div class="dropdown-menu text-muted2">
                            <a href="#" class="dropdown-item pl-3 text-muted2" @click="logout">
                                <i class="mdi mdi-login-variant"/>
                                Salir
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';
import axios from 'axios';
export default {
    data(){
        return{
            rutaFS: process.env.VUE_APP_URL_FULL_SMART,
            tema: 'light',
            ruta:'/notificaciones',
            count_not:0,
            list_pendiente:[],
            list_leido:[],
            noti:false,
            vermas_pendiente:0,
            vermas_leidas:0,
            texto:'',
            estado_crear:false,
            favoritos:[],
            dragging: false,
            estado_editar:false,
            ordenar:'',
            options:[
                {
                    value:1,
                    label:'Ordenar por modulo'
                },
                {
                    value:2,
                    label:'Ordenar alfabeticamente'
                }
            ],
            edit:false,
            old_text:''
        }
    },
    computed:({
        ...mapGetters({
            user_data: 'auth/user',
            personalizacion: 'personalizacion/personalizacion'
        }),
        user(){
            return this?.user_data ?? { rol: {} }
        }
    }),
    mounted(){
        this.list_notificaciones();
    },
    methods: {
        async updateAllNotifi(){
            const token = Cookies.get('token');
            if(token) {
				let error = false;
                let {data} = await axios.post(`${this.ruta}/update_all_nav_notifi`,{datos:JSON.stringify(this.list_pendiente)}).catch(function (error) {
					error = true
				});
                    if(data && !error)
                    this.count_not = data.contador_notifaciones
                    this.list_pendiente = data.pendientes
                    this.list_leido = data.leidas
            }
        },
        view_notifiaciones(){
            this.$router.push("/notificaciones/mis-notificaciones");
        },
        isProveedores(){
            const subdomain = window.location.hostname.split('.').shift()
            return (['proveedores', 'proveedores-prueba', 'localhost']).includes(subdomain)
        },
        async list_notificaciones(){
            const token = Cookies.get('token');
            if(token) {
                try {
                    const { data } = await axios.get(`${this.ruta}/notifi_nav`);
                        this.count_not = data.contador_notifaciones
                        this.list_pendiente = data.pendientes
                        this.list_leido = data.leidas
                        this.vermas_pendiente = data.count_pendiente,
                        this.vermas_leidas = data.count_leido
                } catch (error) {
					console.log(error);
                }
            }
        },
        async logout(){
            if (this.isProveedores()) {
                await this.$store.dispatch('auth/LOGOUTPROVEEDORES');
                this.$router.push({name: "login"})
            }else{
                await this.$store.dispatch('auth/LOGOUT');
                this.redirectFs()
            }
            // this.navigateTo({name: 'login'})
        },
        async ver_notificacion(dato){
            window.open(`${this.rutaFS}/${dato.ruta}`, "_self")
        },
        redirectFs(){
            window.open(`${this.rutaFS}/login`, "_self")
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-header-top{
    height: 65px;
    .estilo-nav{
        position: absolute;
        height: 65px;
        z-index: 13;
        width: calc(100vw - 54px);
        margin-left: 53px;
        .img-company{
            max-width:240px
        }
        .notificaciones{
            position: relative;
            .cant-not{
                position:absolute;
                font-size: 10px;
                background: #ff0000;
                color: #fff;
                border-radius: 50rem;
                padding: 1px 5px;
                top: 5px;
                right: -7px;
            }
        }
    }
    .profile-photo {
        width: 3rem;
        height: 3rem;
        margin: -.375rem 0;
    }
    .box-shadow-nav{
        box-shadow: 0px 3px 6px #00000029;
    }
}
</style>
