import { UseCase } from "~/domain/core/UseCase";
import unitMeasure from "~/services/configurar/almacen/measure_unit"

class DeleteUnitMeasureTypeUseCase extends UseCase {
    static async execute(id){
        const data = await unitMeasure.deleteUnitMeasure(id)
        return data?.message 
    }
}

export default DeleteUnitMeasureTypeUseCase.execute