import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { PaymentMethodFactory } from '~/domain/entities/oportunidades/information/factories/PaymentMethodFactory'
import { $try } from '~/services/global'

class SetOpportunityPaymentMethodUseCase extends UseCase {
    static async execute(params){
        const data =  await Opportunities.setPaymentMethodOpportunity(params)
        return $try(() => data?.data ? PaymentMethodFactory.instantiate(data.data) : {} )
    }
}

export default SetOpportunityPaymentMethodUseCase.execute