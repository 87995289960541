import { ProviderMaterialFilter } from "../ProviderMaterialFilter";

export class ProviderMaterialFilterFactory {
    static instantiate(obj){
        return new ProviderMaterialFilter(obj);
    }
    
    static instantiateEmpty(){
        return new ProviderMaterialFilter({
            fecha_compra_inicio: '',
            fecha_compra_fin: '',
            fecha_cotizacion_inicio: '',
            fecha_cotizacion_fin: '',
            valor_cotizado_inicio: 0,
            valor_cotizado_fin: 0,
            valor_compra_inicio: 0,
            valor_compra_fin: 0,
        })
    }
}