<template>
    <div class="like-tag br-20 border bg-white d-middle justify-content-between mb-2">
        <el-tooltip :content="name" placement="top" effect="light" visible-arrow>
            <p class="f-10 pl-2 tres-puntos">{{ name }}</p>
        </el-tooltip>
        <div class="wh-27 bg-d4 rounded-circle d-middle-center cr-pointer" @click="deleteItem">
            <i class="icon-window-close f-14" />
        </div>
    </div>
</template>

<script>
export default {
    props:{
        id:{
            type: Number,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    methods:{
        deleteItem(){
            this.$emit('deleteItem')
        }
    }

}
</script>

<style lang="scss" scoped>
.bg-d4{
    background: #d4d4d4;
}
</style>