import { WorkshopCalendar } from "../WorkshopCalendar";

export class WorkshopCalendarFactory {
    static instantiate(obj){
        return new WorkshopCalendar(obj)
    }

    static instantiateEmpty(){
        return new WorkshopCalendar({
            id: '',
            nombre: '',
            actividades: [],
        })
    }
}