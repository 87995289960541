import { Entity } from '~/domain/entities'

export class Workshop extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id',
                'nombre',
                'direccion',
                'telefono',
                'responsable',
                'servicios_vigentes',
                'calificacion',
                'imagen'
            ]
        })
    }
}