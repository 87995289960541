import { UseCase } from "~/domain/core/UseCase"
import Auth from "~/services/auth/auth"
import { $try } from '~/services/global'

class RecoverPasswordUseCase extends UseCase {
    static async execute(params){
        const data = await Auth.recoverPassword(params)
        return $try(() => {
            return data
        })
    }
}

export default RecoverPasswordUseCase.execute
