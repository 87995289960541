import { UseCase } from "~/domain/core/UseCase";
import { Material } from "~/services/almacen/categorias/materiales"
import { MaterialFactory } from '~/domain/entities/almacen/materiales/factories/MaterialFactory'
import { $try } from '~/services/global'

class AddCategoryMaterialsUsecase extends UseCase {
    static async execute(payload){
        const data = await Material.addCategoryMaterial(payload)
        return $try(() => {
            if(data?.data){
                return data.data.map(el => MaterialFactory.instantiate(el))
            }
        })
    }   
}

export default AddCategoryMaterialsUsecase.execute