<template>
  <el-input 
  ref="input" 
  type="text" 
  :size="size" 
  :value="formattedValue" 
  :disabled="disabled" 
  v-currency="options" 
  @input="handleInput" 
  @change="$emit('change', $event)" 
  @blur="$emit('blur', $event)"
  />
</template>

<script>
import { setValue, getValue } from "vue-currency-input";

export default {
  name: "ElCurrencyInput",
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    options: {
      type: Object,
      default: () => {}
    },
    size: {
      type: String,
      default: 'small'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formattedValue: null
    };
  },
  watch: {
    value: "setValue"
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    handleInput(value) {
      this.$emit("input", getValue(this.$refs.input));
      this.formattedValue = value;
    },
    setValue(value) {
      setValue(this.$refs.input, value);
    }
  }
};
</script>
