import * as MaterialDomain from '~/domain/use_cases/almacen/categorias/materiales'
import { MaterialFilterFactory } from '~/domain/filters/almacen/materiales/factories/MaterialFilterFactory.js'
import { $try } from '~/services/global'

export const state = {
    materials: new Array(),
    filter: MaterialFilterFactory.instantiateEmpty(),
    range: [],
    selected_materials: []
}

export const getters = {
    materials: state => state.materials,
    filter: state => state.filter,
    range: state => state.range,
    selected_materials: state => state.selected_materials
}

export const mutations = {
    set_materials(state, payload){
        state.materials = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_range(state, payload){
        state.range = payload
    },
    set_selected_materials(state, payload){
        state.selected_materials = payload
    }
}

export const actions = {
    async Action_get_category_materials_list({ state, commit, dispatch }, params){
        const filter = state.filter.clean()
        const category_id = params?.category_id
        const page = params?.page
        
        const materials = await MaterialDomain.GetCategoryMaterialListUseCase(category_id, { ...filter, page })
        $try(() => {
            if(materials) commit('set_materials', materials)
        })
    },

    async Action_get_category_material_range({ commit, dispatch }, category_id){
        const range = await MaterialDomain.GetCategoryMaterialsRangeUseCase(category_id)
        $try(() => {
            if(range) commit('set_range', range)
            dispatch('clear_filter')
        })
    },

    async Action_delete_category_material({ dispatch }, payload){
        const message = await MaterialDomain.DeleteCategoryMaterialUsecase(payload)
        const category_id = payload?.category_id
        $try( async () => {
            await dispatch('Action_get_category_material_range', category_id)
            if(message) dispatch('Action_get_category_materials_list', { category_id })
        })
    },

    async Action_add_category_material({ dispatch }, payload){
        const materials = await MaterialDomain.AddCategoryMaterialsUsecase(payload)
        const category_id = payload?.category_id
        $try( async () => {
            await dispatch('Action_get_category_material_range', category_id)
            if(materials) dispatch('Action_get_category_materials_list', { category_id })
        })
    },

    async Selected_mataerials({ commit }, payload){
        const data = await MaterialDomain.GetCategoryMaterialsSelectedUseCase(payload)
        $try(() => {
            if(data) commit('set_selected_materials', data)
        })
    },

    clear_filter({ state, commit }){
        commit('set_filter', MaterialFilterFactory.instantiateEmptyWithRange(state.range))
    }
}