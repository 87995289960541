<template>
    <div class="d-flex mx-0 tabsIconos">
        <div class="flex-wrap tabs-icons px-0 d-middle">
            <template v-for="(item,index) in tabs">
				<a
				v-if="item.callback && typeof item.callback == 'function'" 
				:key="index"
				class="router-link-exact-active d-middle position-relative tab-inner f-600 cr-pointer mr-2"
				@click="item.callback()"
				>
					<el-tooltip class="item" effect="light" :content="item.titulo" placement="top">
						<i v-if="item.icono" :class="item.icono" class="f-22 cr-pointer mr-2" />
					</el-tooltip>
				</a>

                <router-link
				v-else
                v-slot="{ navigate, isActive }"
                :data-xd="item.ruta"
                :to="{ name: item.ruta, params: item.params || {} }"
                >
                    <a class="d-middle mr-2" :class="{'router-link-exact-active router-link-active': isActive}" @click="navigate">
                        <el-tooltip class="item" effect="light" :content="item.titulo" :disabled="isActive" placement="top">
                            <i v-if="item.icono" :class="item.icono" class="f-22 cr-pointer" />
                        </el-tooltip>
                        <span v-if="isActive" class="cr-default">
                            {{item.titulo}}
                        </span>
                        <div v-if="item.cantidad && isActive" class="tipo-badge">
                            {{item.cantidad}}
                        </div>
                    </a>
                </router-link>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TabsIcons',
    props:{
        tabs:{
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="scss" scoped>
.tabsIconos{
    .tabs-icons{
        border-bottom: 2px solid #b5b5b5;
        a{
            color: var(--text-tema) !important;
            font-size: 14px;
            position: relative;
            &.router-link-exact-active.router-link-active{
                padding: 10px 10px 5px 10px;
                border-bottom: 2px solid var(--color-general);
                color: var(--color-general) !important;
            }
            &:hover{
                text-decoration: none !important;
            }
            .tipo-badge{
                width: 20px;
                height: 20px;
                background: var(--color-general);
                color: white;
                border-radius: 30%;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateX(25%);
            }
        }
    }
}
</style>
