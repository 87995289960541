import { Filter } from '~/domain/core/Filter'

export class FilterMateriales extends Filter {
    constructor(obj){
        super({
            params: obj,
            keys: [ 
                'tipo_materiales',
                'unidad_medida',
                'valor_max',
                'valor_min'
            ]
        })
    }
}