import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class CreateStagesUseCase extends UseCase {
    static async execute(params){
        const data = await Stages.createStages(params)
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default CreateStagesUseCase.execute