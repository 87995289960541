import { UseCase } from "~/domain/core/UseCase";
import { Services } from '~/services/obras/servicios/servicios'

class DeleteServiceUseCase extends UseCase {
    static async execute(payload) {
        const data = await Services.deleteServicioTaller(payload)
        return data?.message
    }
}

export default DeleteServiceUseCase.execute