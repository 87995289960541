import { UseCase } from '~/domain/core/UseCase'
import AWS from '~/services/aws'

const bucket_path = process.env.VUE_APP_BUCKET_PATH ?? 'iaf-software' 

class SaveFileToAWSUseCase extends UseCase {
    static async execute(file, path = '', callback = () => {}){
        if(!(file instanceof File)) throw Error('El primer argumento no es de tipo File');
        if(!(typeof path  === 'string')) throw Error('El segundo argumento no es de tipo string');
        if(!(typeof callback === 'function')) throw Error('El tercer argumento no es una funcion')

        const startSlash = path.charAt(0) === '/' ? '' : '/'
        const endSlash = path.charAt(path.length - 1) === '/' ? '' : '/'
        const extension = file.name.split('.').pop()
        const finalPath = startSlash + path + endSlash
        const filename = AWS.getHash()

        const route = `${bucket_path}${finalPath}${filename}.${extension}`;
        
        return AWS.getInstance().upload({
            Key: route,
            ContentType: file.type,
            Body: file
        })
        .on('httpUploadProgress',uploadEvent=>{
            callback(Math.round((uploadEvent.loaded * 100) / uploadEvent.total ))
        }).promise();
    }
}

export default SaveFileToAWSUseCase.execute