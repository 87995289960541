import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { TallerVOFactory } from '~/domain/value_objects/servicios/factories/tallerVOFactory'
import { $try } from '~/services/global'

class GetSelectTalleresUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectTalleres()
        return $try(() => {
            if(data?.data) return data.data.map(el => TallerVOFactory.instantiate(el))
            return []
        })
    }
}

export default GetSelectTalleresUseCase.execute;