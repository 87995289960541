import { UseCase } from '~/domain/core/UseCase'
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { OpportunityLossesFactory } from '~/domain/entities/oportunidades/factories/OpportunityLossesFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetOpportunityesLossesUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getOpportunityLosses(payload)
        return $try(() => {
            if(data?.data?.data){
                return new PaginationDecorator(
                    data.data,
                    OpportunityLossesFactory.instantiate
                ).toTable();
            }
        })
    }
}

export default GetOpportunityesLossesUseCase.execute