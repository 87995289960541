import Api from '~/services/api'


const menusList = {
    listMenus(payload){
        const Service = new Api()
        return Service.get('/configuracion/permisos/menus', payload)
    },
    getHeaders(payload){
        const Service = new Api()
        return Service.get('/configuracion/permisos/permiso', payload)
    },
    createRutaRaiz(payload){ 
        const Service = new Api({ notification: true })
        return Service.post('configuracion/permisos/menus', payload)
    }, 
}
 
export default menusList
 