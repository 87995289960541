import  Api  from '~/services/api'



export const MaintenanceActivitiesSteps = {
    getSteps({id_material, id_mantenimiento, id_actividad}){
        const ApiHandler = new Api()
        return ApiHandler.get(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/pasos`)
    },
    createStep({id_material, id_mantenimiento, id_actividad, payload}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.post(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/pasos`, payload)
    },
    editStep({id_material, id_mantenimiento, id_actividad,id, payload}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.put(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/pasos/${id}`, payload)
    },
    deleteStep({id_material, id_mantenimiento, id_actividad,id}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/pasos/${id}`)
    },
    uploadFile({ id_material, id_mantenimiento, id_actividad, id_paso, payload }){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/pasos/${id_paso}/adjunto`, payload)
    },
    deleteFile({ id_material, id_mantenimiento, id_actividad, id }){
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.delete(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/adjunto/${id}`)
    },
    switchPosition({ id_material, id_mantenimiento, id_actividad, payload }) {
        const ApiHandler = new Api({ notification: true })
        return ApiHandler.post(`/almacen/materiales/${id_material}/mantenimiento/${id_mantenimiento}/actividades/${id_actividad}/pasos/modificar-posicion`, payload)
    },
    
}