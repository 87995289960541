import { UseCase } from "~/domain/core/UseCase"
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'
import { StatesPaginatedVOFactory } from '~/domain/value_objects/opportunity/factories/statesPaginatedVOFactory'

class GetSelectStatesPaginatedUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectStatesPaginated(payload)
        return $try(() => {
            if(data?.data) return data.data.map(el => StatesPaginatedVOFactory.instantiate(el))
            return []
        })
    }

}

export default GetSelectStatesPaginatedUseCase.execute;