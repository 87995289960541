import { CategoryFilter } from "../CategoryFilter";

export class CategoryFilterFactory {
    static instantiate(obj){
        return new CategoryFilter(obj)
    }

    static instantiateEmpty(){
        return new CategoryFilter({
            nombre: '',
            materiales_min: 0,
            materiales_max: 0,
            etapas_min: 0,
            etapas_max: 0,
            planes_mantenimiento_min: 0,
            planes_mantenimiento_max: 0,
            fecha_min: null,
            fecha_fin: null,
            id_proveedores: [],
        })
    }

    static instantiateEmptyWithRanges(obj){
        const materiales_min = obj?.materiales?.[0] ?? 0
        const materiales_max = obj?.materiales?.[1] ?? 0
        const etapas_min = obj?.etapas?.[0] ?? 0
        const etapas_max = obj?.etapas?.[1] ?? 0
        const planes_mantenimiento_min = obj?.planes_mantenimiento?.[0] ?? 0
        const planes_mantenimiento_max = obj?.planes_mantenimiento?.[1] ?? 0

        const category = new CategoryFilter({
            nombre: '',
            materiales_min,
            materiales_max,
            etapas_min,
            etapas_max,
            planes_mantenimiento_min,
            planes_mantenimiento_max,
            fecha_min: null,
            fecha_fin: null,
            id_proveedores: [],
        })

        category.setExceptions({
            materiales_min,
            materiales_max,
            etapas_min,
            etapas_max,
            planes_mantenimiento_min,
            planes_mantenimiento_max,
        })
        
        return category
    }
}