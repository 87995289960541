import axios from 'axios'
import store from '~/store'
import router from '~/router'
import Cookies from 'js-cookie';

axios.defaults.baseURL =  process.env.VUE_APP_URL + '/api';

// Request interceptor
axios.interceptors.request.use(request => {
    const token = Cookies.get('token');
    if (token) request.headers.common['Authorization'] = `Bearer ${token}`

    const locale = store.getters['lang/locale']
    if (locale) request.headers.common['Accept-Language'] = locale

    // request.headers['X-Socket-Id'] = Echo.socketId()
    return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
    const { status, data } = error.response
    if (status >= 500) console.error(error)
    if (status === 400) throw data
    if (status === 401){
        store.dispatch('auth/NO_AUTHENTICATED_USER')
        router.push({ name: 'logout.smart.iaf' })
    }

    return Promise.reject(error)
})
