import { UseCase } from "~/domain/core/UseCase";
import { Cotizaciones } from '~/services/cotizaciones/detalle_cotizaciones/detalle_cotizaciones'
import { $try } from '~/services/global'

class EditMaterialCotizacionUseCase extends UseCase{
    static async execute(params){
        const  data  =  await Cotizaciones.editMaterialCotizacion(params)
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default EditMaterialCotizacionUseCase.execute