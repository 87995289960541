import { UseCase } from "~/domain/core/UseCase";
import { Documents } from '~/services/talleres/ver_documentos';
import { $try } from '~/services/global';

class DeleteFolderUseCase extends UseCase {
    static async execute({id_taller, id_folder}) {
        const data = await Documents.deleteFolder(id_taller, id_folder);
        return $try(() => data?.message)
    }
}

export default DeleteFolderUseCase.execute;
