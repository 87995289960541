import { UseCase } from "~/domain/core/UseCase";
import { HoursMan } from "~/services/configurar/almacen/horas_hombre"
import { HoursManFactory } from "~/domain/entities/configurar/almacen/horas_hombre/factories/HoursManFactory"
import { $try } from '~/services/global'

class EditHoursManUseCase extends UseCase {
    static async execute(params){
        const data = await HoursMan.editHoursManValue(params)

        return $try(() => {
            if(data?.data) HoursManFactory.instantiate(data.data)
        })
    }
}

export default EditHoursManUseCase.execute