import { UseCase } from "~/domain/core/UseCase";
import BuyOrders from '~/services/proveedores/ver/ordenes_compra'
import {$try} from '~/services/global'

class GetBuyOrdersClientsUseCase extends UseCase {
    static async execute(params){
        const data = await BuyOrders.obtenerClientesOrdenesCompra(params)
        
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetBuyOrdersClientsUseCase.execute