import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class UpdateMaterialConfigUseCase extends UseCase {
    static async execute(params) {
        const { id, id_etapa, id_configuracion,...payload } = params
        await Stages.updateMaterialConfig({ id, id_etapa, id_configuracion, payload })
    }
}

export default UpdateMaterialConfigUseCase.execute
