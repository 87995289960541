import { DomainObject } from "~/domain/core/DomainObject";

export class ValueObject extends DomainObject {
    constructor({params: obj, keys: KEYS}){
        super({
            params: obj,
            keys: KEYS
        })
    }
}
