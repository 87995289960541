import { UseCase } from "~/domain/core/UseCase";
import { Bills } from '~/services/compras/facturas'
import { ProviderVOFactory } from '~/domain/value_objects/compras/facturas/factories/ProviderVOFactory'
import { $try } from '~/services/global'

class GetFilterProviderUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data } =  await Bills.getFilterProviders()


        $try(() => {

            if(data.length){
                data.forEach((item) =>{
                    customData.push(ProviderVOFactory.instantiate(item))
                })
            }

        })

        return customData
    }
}

export default GetFilterProviderUseCase.execute