import { Filter } from '~/domain/core/Filter';

export class OpportunityConfigurationFilter extends Filter{
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id_tipo_material',
				'id_unidad_medida',
				'valor_minimo',
				'valor_maximo',
                'query'
            ]
        });
    }
}