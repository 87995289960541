import { CotizacionVencidaFilter } from "../CotizacionVencidaFilter";

export class CotizacionVencidaFilterFactory {
    static instantiate(obj){
        return new CotizacionVencidaFilter(obj)
    }

    static instantiateEmpty(){
        return new CotizacionVencidaFilter({
            referencia: null,
            fecha_inicio_cotizacion: null,
            fecha_fin_cotizacion: null,
        })
    }
}