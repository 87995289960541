import { UseCase } from "~/domain/core/UseCase";
import { CheckList } from '~/services/configurar/obras/lista_chequeo.js'
import { CheckListFactory } from '~/domain/entities/configurar/obras/check_list/factories/CheckListFactory.js'
import { $try } from '~/services/global'

class UpdatePositionActivitiesCheckListUseCase extends UseCase {
    static async execute(payload){
        const { data } = await CheckList.changePositionActivityCheckList(payload)
        //return $try( () => data?.map(e => CheckListFactory.instantiate(e) ) ?? [])
    }
}

export default UpdatePositionActivitiesCheckListUseCase.execute