import { ActivitiesCheckList } from "../ActivitiesCheckList";

export class ActivitiesCheckListFactory {
    static instantiate(obj) {
        return new ActivitiesCheckList(obj)
    }

    static instantiateEmpty() {
        return new ActivitiesCheckList({
            id: undefined,
            nombre: '',
            posicion: undefined,
            id_lista_chequeo:'',
            peso:''
        })
    }
}