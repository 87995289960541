import { Entity } from '~/domain/entities'

export class ConfiguracionEtapa extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'almacen_etapas_configuracion', 
                'id_configuracion',
                'id_cotizador_equipos_etapas', 
                'porcentaje_configuracion', 
                'valor'
            ]
        })
    }
}