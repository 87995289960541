import * as Cotizaciones from '~/domain/use_cases/obras/cotizaciones'
import { cotizacionesFilterFactory } from '~/domain/filters/obras/factories/cotizacionesFilterFactory'
import { $try } from '~/services/global'


//state
export const state = {
    materials: null,
    filters: cotizacionesFilterFactory.instantiateEmpty(),
}

//getters
export const getters = {
    materialsList: state => state.materials,
    filters: state => state.filters,
}

//mutations
export const mutations = {
    set_materials_cotizacion(state, payload) {
        state.materials = payload
    },
    set_filters(state, payload) {
        state.filters = payload
    },
}

//actions
export const actions = {
    async Action_get_materials({ commit }, pagina) {
        const data = await Cotizaciones.GetCotizacionesUseCase({...state.filters, ...pagina})
        $try(() => {
            if (data) commit('set_materials_cotizacion', data)
        })
    },
    Action_limpiar_filtros({ commit }) {
        commit('set_filters', cotizacionesFilterFactory.instantiateEmpty())
    },
}