import { Entity } from '~/domain/entities'

export class CabeceraDetalleMateriales extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'nombre',
                'porcentaje_utilidad',
                'numero_materiales',
                'valor_materiales',
                'horas_hombre',
                'valor_total_horas_hombre',
                'valor_total',
            ]
        });
    }
}