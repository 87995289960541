<template>
    <div class="row archivo mx-0 f-12 border-bottom">
        <div class="col-md-3 col-lg-3 my-auto pl-0">
            <div 
            :id="`${ (fileType == 'pdf' && visualizar == 1) ? 'df_gbp' : ''}`" 
            class="d-middle tres-puntos cr-pointer " 
            :class="`${ (fileType == 'pdf' && visualizar == 1) ? '_df_custom' : ''}`"
            @click="viewFile(fileType, file)">
                <i class="f-23" :class="setFileType(fileType)" />
                <el-tooltip :content="fileName" placement="right">
                    <p class="f-600 tres-puntos"> {{ fileName }} </p>
                </el-tooltip>
            </div>
        </div>
        <div class="col-md-3 col-lg-1 my-auto text-center">
            <p class="py-3">{{ visualizar != 0 ? 'Si' : 'No' }}</p>
        </div>
        <div class="col-md-3 col-lg-3 my-auto">
            <card-mini-user :nombre="userName" :img="userImg" tamanoImg="26" fw="f-300" />
        </div>
        <div class="col-md-4 col-lg-3 my-auto">
            <p class="py-3 f-500"> {{ dateCreated }} </p>
        </div>
        <div class="col-md-3 col-lg-2 my-auto text-center icon-option d-middle-center">
            <a :href="file | helper-storage" :download="fileName" class="icon-arrow-down-bold cr-pointer f-20" target="_blank"> </a>
            <i class="icon-pencil f-20 cr-pointer" @click="$emit('editFile')" />
            <i class="icon-trash-can-outline f-20 cr-pointer" @click="deleteFile" />
        </div>
        <modal-media-visualizer ref="modalViewFiles" :media="file" :extension="fileType" />
    </div>
</template>

<script>
import FlipBook from '~/mixins/FlipBook'
export default {
    props:{
        fileName:{
            type: String,
            required: true
        },
        file:{
            type: String,
            required: true
        },
        fileType:{
            type: String,
            required: true
        },
        visualizar:{
            type: Number,
        },
        userImg:{
            type: String
        },
        userName:{
            type: String
        },
        dateCreated:{
            type: String
        },

    },
    data(){
        return{
            visibleEditarArchivo: false,
            inputNombreArchivo:''
        }
    },
    methods:{
        cambiarNombreArchivo(){
            this.visibleEditarArchivo = !this.visibleEditarArchivo
        },
        deleteFile(){
            this.$emit('deleteFile')
        },
        setFileType(type){
            switch (type) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'svg':
                    return 'icon-image text-imagen'
                    break;
                case 'pdf':
                    return 'icon-pdf text-pdf'
                    break;
                case 'mov':
                case 'mp4':
                    return 'icon-youtube-play-1 text-vid'
                    break;
            
                default:
                    break;
            }
        },
        viewFile(fileType, file){
            if (this.visualizar == 1) {
                if (fileType == 'jpg' || fileType == 'jpeg' || fileType == 'png' || fileType == 'svg' || fileType == 'mov' || fileType == 'mp4') {
                    this.$refs.modalViewFiles.toggle()
                }
                if(fileType === 'pdf'){
                    this.set_pdf(file)
                }
            } else {
                this.$notify({
                    title: 'No se puede visualizar el archivo',
                    type:'warning',
                    duration: 2000,
                })
            }
        }
    }
    

}
</script>

<style>

</style>