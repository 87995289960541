import { UseCase } from "~/domain/core/UseCase";
import { Equipos } from '~/services/obras/equipos/equipo'
import { EtapasFactory } from '~/domain/entities/obras/equipos/factory/etapasFactory'
import { $try } from '~/services/global'

class EditEtapasUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await Equipos.putEditEtapas(payload)
        return $try(() => {
            if(data?.data) return new EtapasFactory.instantiate(data.data)
        })
    }
}

export default EditEtapasUseCase.execute