import * as CotizacionesDetalleDomain from '~/domain/use_cases/cotizaciones/detalle'
import {$try} from '~/services/global'

// state
export const state = {
    cotizacionDetalle: [],
    materiales: [],
    selectMateriales: [],
    configuraciones: [],
    selectConfiguraciones:[],
    cotizadorHistorial:[],
    valorFinalEtapa:0

}

// getters
export const getters = {
    cotizacionDetalle: state => state.cotizacionDetalle,
    materiales: state => state.materiales,
    selectMateriales: state => state.selectMateriales,
    configuraciones: state => state.configuraciones,
    selectConfiguraciones: state => state.selectConfiguraciones,
    cotizadorHistorial: state => state.cotizadorHistorial,
    valorFinalEtapa: state => state.valorFinalEtapa,

}

// mutations
export const mutations = {
    set_cotizacion_detalle(state, providers){
        state.cotizacionDetalle = providers
    },
    set_materiales(state, providers){
        state.materiales = providers
    },
    set_select_materiales(state, providers){
        state.selectMateriales = providers
    },
    set_configuraciones(state, providers){
        state.configuraciones = providers
    },
    set_select_configuraciones(state, providers){
        state.selectConfiguraciones = providers
    },
    set_select_cotizador_historial(state, providers){
        state.cotizadorHistorial = providers
    },
    set_valor_final_etapa(state, providers){
        state.valorFinalEtapa = providers
    },

}

// actions
export const actions = {
    async Action_get_cotizacion_detalle({commit}, payload){
        const providers = await CotizacionesDetalleDomain.GetQuotationClientDetailUseCase(payload)
        $try(() => { 
            commit('set_cotizacion_detalle', providers)
        });
    },
    async Action_post_crear_cotizacion({commit}, payload){
        const providers = await CotizacionesDetalleDomain.PostQuotationUseCase(payload)
        $try(() => { 
            commit('set_cotizacion_detalle', providers)
        });
    },
    async Action_get_configuraciones_materiales({commit}, payload){
        const providers = await CotizacionesDetalleDomain.GetConfigurationMaterialsUseCase(payload)
        $try(() => { 
            commit('set_materiales', providers)
        });
    },

    async Action_delete_material({commit}, payload){
        return await CotizacionesDetalleDomain.DeleteMaterialUseCase(payload)
    },

    async Action_get_select_materiales({commit}, payload){
        const providers =  await CotizacionesDetalleDomain.GetMaterialsConfigurationSelectUseCase(payload)
        $try(() => { 
            commit('set_select_materiales', providers)
        });
    },

    async Action_post_add_materiales_configuracion({commit}, payload){
        return await CotizacionesDetalleDomain.PostAddMaterialsConfigurationUseCase(payload)
    },

    async Action_put_editar_cotizacion({commit}, payload){
        const providers =  await CotizacionesDetalleDomain.PutUpdateQuotationUseCase(payload)
        $try(() => { 
            commit('set_cotizacion_detalle', providers)
        });
    },

    async Action_post_agregar_etapa({commit}, payload){
        const providers =  await CotizacionesDetalleDomain.PostAddStageUseCase(payload)
        $try(() => { 
            commit('set_cotizacion_detalle', providers)
        });
    },

    async Action_delete_configuracion({state, commit}, payload){
        const providers = await CotizacionesDetalleDomain.DeleteConfigurationUseCase(payload)
        $try(() => { 
            if (providers) {
                let detalleCotizacion = state.cotizacionDetalle
                let etapas = detalleCotizacion.cotizador_equipos_etapas
                etapas.map(e => {
                    if (payload.id_etapa == e.id) {
                        let configuraciones = e.cotizador_equipos_etapas_configuraciones
                        e.cotizador_equipos_etapas_configuraciones = configuraciones.filter(o => o.id != payload.id_configuracion)
                        commit('set_configuraciones', e.cotizador_equipos_etapas_configuraciones)
                    }
                    return e
                })
                detalleCotizacion.cotizador_equipos_etapas = etapas
                commit('set_cotizacion_detalle', detalleCotizacion)
            }
        });
    },

    async Action_post_add_configuracion({state, commit}, payload){
        const providers =  await CotizacionesDetalleDomain.PostAddConfigurationUseCase(payload)
        
        $try(() => { 
            let config = state.configuraciones
            config.push(providers)
            commit('set_select_configuraciones', config)
        });

    },

    async Action_get_list_configuracion({commit}, payload){
        const providers = await CotizacionesDetalleDomain.GetListConfigurationUseCase(payload)
        $try(() => { 
            commit('set_select_configuraciones', providers)
        });
    },
    
    async Action_post_actualizar_porcentaje_configuracion({commit}, payload){
        return  CotizacionesDetalleDomain.PutUpdatePercentejeConfigurationUseCase(payload)  
    },

    async Action_put_actualizar_porcentaje_utilidad({commit}, payload){
        const providers = await CotizacionesDetalleDomain.PutUpdatePercentajeUtilityUseCase(payload) 
        $try(() => { 
            commit('set_cotizacion_detalle', providers)
        });
    },

    async Action_get_cotizador_historial({commit}, payload){
        const providers = await CotizacionesDetalleDomain.GetCotizadorHistorialUseCase(payload) 
        $try(() => { 
            commit('set_select_cotizador_historial', providers)
        });
    },
    async Action_get_list_valor_final_etapa({commit}, payload){
        const providers = await CotizacionesDetalleDomain.GetValorFinalEtapaUseCase(payload)
        $try(() => { 
            commit('set_valor_final_etapa', providers)
        });
    },
    async Action_delete_cotizacion({commit}, payload){
        return  CotizacionesDetalleDomain.DeleteCotizacion(payload)  
    },
    async Action_delete_etapa({commit}, payload){
        return  CotizacionesDetalleDomain.DeleteEtapaUseCase(payload)  
    },

}