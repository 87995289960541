import { UseCase } from "~/domain/core/UseCase";
import { PurchaseOrder } from "~/services/obras/servicios/orden_compra";
import { $try } from "~/services/global";

class GetPurchaseOrderMaterialsRangeUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso, id_orden_compra }) {
        const data = await PurchaseOrder.getPurchaseOrderMaterialsRange(id_proyecto, id_control_piso, id_orden_compra);
        return $try(() => {
            if(data?.data){
                return {
                    min: data.data.unidades_min,
                    max: data.data.unidades_max
                }
            }
        });
    }
}

export default GetPurchaseOrderMaterialsRangeUseCase.execute;