import { Filter } from '~/domain/core/Filter';

export class PedidoInternoFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_solicitante',
                'id_proyecto',
                'id_equipo',
                'id_equipo_modelo',
                'fecha_solicitud_hasta',
                'fecha_solicitud_desde',
                'fecha_llegada_hasta',
                'fecha_llegada_desde'
            ]
        });
    }
}