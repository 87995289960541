import { UseCase } from "~/domain/core/UseCase";
import { PurchaseOrder } from '~/services/compras/ordenes_compra'
import { PurchaseOrderDayFactory } from '~/domain/entities/compras/ordenes_compra/factories/PurchaseOrderDayFactory'
import { GroupDecorator } from '../../../decorators/GroupDecorator'
import { $try } from '~/services/global'

class GetPurchaseOrderDaysUseCase extends UseCase {
    static async execute({id, payload = {}}){
        const data = await PurchaseOrder.getPurchaseOrderDays({id, payload})
        return $try(() => {
            if(data.data){
                return new GroupDecorator(
                    data.data,
                    PurchaseOrderDayFactory.instantiate,
                );
            } 
        });
    }
}

export default GetPurchaseOrderDaysUseCase.execute