
import { Entity } from '~/domain/entities'

export class Warehouse extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['id', 'nombre']
        });
    }
}