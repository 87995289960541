import Api from './api'

export const menusFavoritos = {
    saveMenu(menu){
        const ApiHandler = new Api()
        return ApiHandler.post(`configurar-menus/agregar-favorito`, menu)
    },
    changePositionMenu(payload){
        const ApiHandler = new Api()
        return ApiHandler.put(`configurar-menus/cambiarPosicionFavorito`, payload)
    },
    removeMenu(id){
        const ApiHandler = new Api()
        return ApiHandler.delete(`configurar-menus/${id}/eliminar-favorito`)
    }
}