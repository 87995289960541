import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { $try } from '~/services/global'

class GetConfiguracionUseCase extends UseCase {
    static async execute(payload){
        const data =  await Opportunities.getConfiguracion(payload)
        return $try( () => data?.data ?? [] )
    }
}

export default GetConfiguracionUseCase.execute