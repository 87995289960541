import { FilterMateriales } from "../FilterMateriales";

export class FilterMaterialesFactory {
        static instantiateEmpty(){
        return new FilterMateriales({
            id_tipo_material: '',
            id_unidad_medida: '',
            valor_max: 0,
            valor_min: 0,
        })
    }

    static instantiateEmptyWithRange(range){
        const valor_min = range?.valor_min ?? 0
        const valor_max = range?.valor_max ?? 0
        
        const service = new FilterMateriales({
            id_tipo_material: '',
            id_unidad_medida: '',
            valor_min,
            valor_max,
        })

        service.setExceptions({
            valor_min,
            valor_max,
        })

        return service
    }
}