import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'

class UpdatePositionCalendarActivityUseCase extends UseCase {
    static async execute(payload){
        await GeneralCalendarService.changePositionCalendarActivity(payload)
    }
}

export default UpdatePositionCalendarActivityUseCase.execute
