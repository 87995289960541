import { UseCase } from "~/domain/core/UseCase"
import { Proveedores } from "../../../../services/proveedores/proveedor-cotizacion"
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { CotizacionesMaterialesFactory } from "../../../entities/proveedores/factories/CotizacionesMaterialesFactory"
import { $try } from '~/services/global'

class GetDetalleCotizacionMatsUseCase extends UseCase{

    static async execute(idProveedor, idCotizacion, payload){
        const data = await Proveedores.getDetalleCotizacionMateriales(idProveedor, idCotizacion, payload);
        return $try(() => {
            if(data?.data) return new PaginationDecorator(data.data, CotizacionesMaterialesFactory.instantiate).toTable();
        })
    }
}

export default GetDetalleCotizacionMatsUseCase.execute