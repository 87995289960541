import { AdditionalDataTypeVO } from "../AdditionalDataTypeVO";

export class AdditionalDataTypeVOFactory {
    static instantiate(obj){
        return new AdditionalDataTypeVO(obj)
    }

    static instantiateEmpty(){
        return new AdditionalDataTypeVO({
            valor: '',
            id_configuracion_datos_adicionales: '',
            id:'',
            posicion:''
        })
    }
}