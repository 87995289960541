import Api from '~/services/api'

export const Resumen = {
    getPercentageIncreases(){
        const Service = new Api()
        return Service.get(`configuraciones/almacen/incrementos-porcentuales`)
    },
    getOpportunityProductResume(payload){
        const Service = new Api()
        return Service.get(`oportunidad-producto/resumen`, payload)
    },
    saveAdditionalHours(payload){
        const Service = new Api({ notification: true})
        return Service.post(`oportunidad-producto/resumen/horas-adicionales`, payload)
    },
    savePercetangeIncrease(payload){
        const Service = new Api({ notification: true })
        return Service.post(`oportunidad-producto/resumen/incremento-porcentual`, payload)
    },
    updatePercetangeIncrease(id_oportunidades_resumen_incrementos_porcentuales, payload){
        const Service = new Api({ notification: true })
        return Service.put(`oportunidad-producto/resumen/incremento-porcentual/${id_oportunidades_resumen_incrementos_porcentuales}`, payload)
    },
    removePercetangeIncrease(id_oportunidades_resumen_incrementos_porcentuales){
        const Service = new Api({ notification: true })
        return Service.delete(`oportunidad-producto/resumen/incremento-porcentual/${id_oportunidades_resumen_incrementos_porcentuales}`)
    },
    getDetalleEtapa(payload){
        const Service = new Api({ notification: true })
        return Service.get(`oportunidades/vigentes/${payload.id_oportunidad}/productos/${payload.id_producto}/detalle-etapa`, {tabla:payload.tabla})
    }
}