import { WorkshopCalendarActivity } from "../WorkshopCalendarActivity";

export class WorkshopCalendarActivityFactory {
    static instantiate(obj){
        return new WorkshopCalendarActivity(obj)
    }

    static instantiateEmpty(){
        return new WorkshopCalendarActivity({
            id:'',
            nombre: '',
            duracion_dias: '',
            dia_inicio: '',
            id_actividad_predecesora: '',
            descripcion: '',
            color:'',
            id_configuracion_calendarios_talleres:''
        })
    }
}