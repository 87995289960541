import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivitiesSteps } from '~/services/almacen/mantenimiento/steps'
import { StepsFactory } from '~/domain/entities/almacen/mantenimiento/stepsActivities/factories/StepsFactory'
import { $try } from '~/services/global'

class GetMaintenanceActivityStepsUseCase extends UseCase {
    static async execute(params){
        const { data } = await MaintenanceActivitiesSteps.getSteps(params)
        return $try(
            () => {
                return data?.data.length 
                    ? data.data.map(item => StepsFactory.instantiateWithAttachments(item)) 
                    : [];
            }
        )
    }
}

export default GetMaintenanceActivityStepsUseCase.execute