import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'
import { CalendarActivityFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/CalendarActivityFactory'
import { GeneralCalendarFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/GeneralCalendarFactory'
import { CalendarSubactivityVOFactory } from '~/domain/value_objects/configurar/obras/calendario_general/factories/CalendarSubactivityVOFactory'
import { $try } from '~/services/global'

class GetGeneralCalendarsUseCase extends UseCase {
    static async execute(params){
        const { data } =  await GeneralCalendarService.getGeneralCalendars()

        return $try(
                () =>  data.map((item) => {
                    return GeneralCalendarFactory.instantiate(
                        {
                            ...item,
                            actividades: item.actividades.map(actividad => CalendarActivityFactory.instantiate(
                                {
                                    ...actividad,
                                    actividades: actividad.actividades.map(subactividad => CalendarSubactivityVOFactory.instantiate(subactividad))
                                }))
                        }
                    )
                }
                ) 
        )
    }
}

export default GetGeneralCalendarsUseCase.execute