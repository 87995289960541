import { UseCase } from "~/domain/core/UseCase"
import  { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { PedidoInternosMaterialesFactory } from '~/domain/value_objects/almacen/pedidos/factories/PedidoInternosMaterialesFactory'
import { $try } from '~/services/global'

class GetSelectPedidosInternosMateriales extends UseCase {
    static async execute(payload){
        const data = await PedidosInternosOcs.getSelectPedidosInternosMateriales(payload)
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => PedidoInternosMaterialesFactory.instantiate(e))
        })
    }
}

export default GetSelectPedidosInternosMateriales.execute