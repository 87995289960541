import * as TiposMaterialDomain from '~/domain/use_cases/configurar/almacen/tipos_material'

import { $try } from '~/services/global'
// state
export const state = {
    materialTypes: [],
}

// getters
export const getters = {
    materialTypes: state => state.materialTypes,
}

// mutations
export const mutations = {
    set_material_types(state, materialTypes){
        state.materialTypes = materialTypes
    }
}

// actions
export const actions = {

    async Action_list_material_types({ commit }){
        const materialTypes = await TiposMaterialDomain.GetMaterialTypesUseCase()
        $try( 
            () => commit('set_material_types', materialTypes)
        )
    },

    async Action_create_material_type({ state, commit }, payload){
        const materialType = await TiposMaterialDomain.CreateMaterialTypeUseCase(payload)
        $try(() => {
            if(materialType) commit('set_material_types', [...state.materialTypes, materialType])
        })
    },

    async Action_edit_material_type({ state, commit }, payload){
        const materialType = await TiposMaterialDomain.EditMaterialTypeUseCase(payload)
        $try(() => {
            if(materialType) commit('set_material_types', state.materialTypes.map(el => el.id === materialType.id ? materialType : el))
        })
    },

    async Action_delete_material_type({ state, commit }, payload){
        const { id } = payload
        const data = await TiposMaterialDomain.DeleteMaterialTypeUseCase(payload)
        $try(() => {
            if(data) commit('set_material_types', state.materialTypes.filter(el => el.id != id))
        })
    },
    async Action_update_position_materal_type({ commit }, payload){
        await TiposMaterialDomain.AddPositionMaterialTypesUseCase(payload)
        $try( 
            () => commit('set_material_types', materialTypes)
        )
    },
}