<template>
    <modal ref="modalDelete" :titulo="title" btn-eliminar @eliminar="deleteItem">
        <div class="row mx-0 my-3 justify-content-center f-14">
            <div class="col" :class="alignText ? alignText : ''">
                <p> {{mensaje}} </p>
                <p v-if="mensaje2" class="mt-3 lh-18"> {{mensaje2}} </p>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: 'Agregar titulo'
        },
        mensaje: {
            type: String,
            required: true,
            default: ''
        },
        mensaje2: {
            type: String,
            required: false,
            default: ''
        },
        alignText: {
            type: String,
            default: 'text-center'
        },
    },
    data(){
        return {
            dialog: false,
            id:'',
            titulo: 'Eliminar',
            // mensaje: '',
            accion:''
        }
    },
    methods: {
        toggle(){
            this.$refs.modalDelete.toggle();
        },
        deleteItem(){
            this.$emit('delete');
            this.toggle();
        }
    }
}
</script>

<style lang="css" scoped>
</style>
