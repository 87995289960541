

export class Maintenance {
    constructor(obj){
        const KEYS = ['nombre', 'id', 'descripcion', 'numero_actividades']
        const objKeys = Object.keys(obj)

        if(objKeys.length){
            const hasValidKeys = objKeys.reduce((prev, curr)=>{
                prev = KEYS.includes(prev) || KEYS.includes(curr)
                return prev
            }, '')
            if(!hasValidKeys){
                throw  new Error(`El objeto no es de tipo Maintenance: ${objKeys}`)
            }
        } 

        objKeys.forEach(key => this[key] = obj[key] ?? '')
    }
    map(){
        return {
            nombre: this.nombre,
            id: this.id,
            descripcion: this.descripcion,
            numero_actividades: this.numero_actividades,
        }
    }
}