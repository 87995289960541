import { UseCase } from '~/domain/core/UseCase'
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'
import { ResponsibleVOFactory } from '~/domain/value_objects/proveedores/responsables/factories/ResponsibleVOFactory'

class GetSelectResponsibleWorkshopsUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectResponsibleWorkshops()

        return $try(() => {
            if(!data?.data) return []
            return data.data.filter(Boolean).map(ResponsibleVOFactory.instantiate)
        })
    }
}

export default GetSelectResponsibleWorkshopsUseCase.execute