import { UseCase } from '~/domain/core/UseCase'
import { Resumen } from '~/services/oportunidades/productos/resumen'
import { PercentageIncreaseFactory } from '~/domain/entities/oportunidades/productos/factories/PercentageIncreaseFactory'
import { $try } from '~/services/global'

class GetPercentageIncreasesUseCase extends UseCase {
    static async execute(){
        const data = await Resumen.getPercentageIncreases()
        return $try(() => {
            if(!data?.data) return []

            return data.data.map(PercentageIncreaseFactory.instantiate)
        })
    }
}

export default GetPercentageIncreasesUseCase.execute