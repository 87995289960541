import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'
import { CalendarActivityFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/CalendarActivityFactory'
import { $try } from '~/services/global'

class CreateCalendarActivityUseCase extends UseCase {
    static async execute(params){
        const data = await GeneralCalendarService.createGeneralCalendarActivity(params)

        return $try(() => {
            if(data?.data) return CalendarActivityFactory.instantiate(data.data)
        })
    }
}

export default CreateCalendarActivityUseCase.execute