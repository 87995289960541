import Api from '~/services/api'

export const MaterialCotizacion = {
    getOptionsMaterialCotizacion(idMaterial){
        const cotizacion = new Api();
        return cotizacion.get(`almacen/materiales/${idMaterial}/cotizaciones/filtros`);
    },
    getMaterialCotizacionFilter(idMaterial, payload){
        const cotizacion = new Api();
        return cotizacion.get(`almacen/materiales/${idMaterial}/cotizaciones`, payload);
    },
}