import * as CotizacionesDomain from '~/domain/use_cases/cotizaciones'
import {$try} from '~/services/global'

// state
export const state = {
    cotizaciones: [],
    equipos: [],
    paises: [],
    estapasEquipos: [],
    configuracionEtapasEquipos: [],
}

// getters
export const getters = {
    cotizaciones: state => state.cotizaciones,
    equipos: state => state.equipos,
    paises: state => state.paises,
    estapasEquipos: state => state.estapasEquipos,
    configuracionEtapasEquipos: state => state.configuracionEtapasEquipos,

}

// mutations
export const mutations = {
    set_cotizaciones(state, providers){
        state.cotizaciones = providers
    },
    set_equipos(state, providers){
        state.equipos = providers
    },
    set_paises(state, providers){
        state.paises = providers
    },
    set_etapas_equipo(state, providers){
        state.estapasEquipos = providers
    },
    set_configuracion_etapas_equipo(state, providers){
        state.configuracionEtapasEquipos = providers
    },

}

// actions
export const actions = {

    async Action_get_cotizaciones({commit}, payload){
        const providers = await CotizacionesDomain.GetQuotationClientUseCase(payload)
        $try(() => { 
            commit('set_cotizaciones', providers)
        });
    },
    async Action_get_equipos_ops({commit}, payload){
        const providers = await CotizacionesDomain.GetProductsOps(payload)
        $try(() => { 
            commit('set_equipos', providers)
        });
    },
    async Action_get_paises({commit}, payload){
        const providers = await CotizacionesDomain.GetQuotationCountryUseCase(payload)
        $try(() => { 
            commit('set_paises', providers)
        });
    },
    async Action_get_etapas_equipos({commit}, payload){
        const providers = await CotizacionesDomain.GetEquipmentStageUseCase(payload)
        $try(() => { 
            commit('set_etapas_equipo', providers)
        });
    },
    async Action_get_configuracion_etapas_equipos({commit}, payload){
        const providers = await CotizacionesDomain.GetConfigurationStageEquipmentUseCase(payload)
        $try(() => { 
            commit('set_configuracion_etapas_equipo', providers)
        });
    },



}