import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es'

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

for (let rule in rules){
    extend(rule, {
        ...rules[rule], // add the rule
        message: es.messages[rule] // add its message
    })
}