import { Entity } from '~/domain/entities'

export class OpportunityWon extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'oportunidad',
                'cliente',
                'valor',
                'fecha_ganada',
                'estado_final',
                'equipos',
                'probabilidad',
                'obra',
            ]
        });
    }
}
