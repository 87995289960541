import { $try } from "~/services/global"

export const state = {
    default_breadcumbs: [
        // Almacen
        { name: 'Almacén', route: 'almacen.main' },
        { name: 'Categorias', route: 'almacen.categorias' },
        { name: 'Equipos modelos', route: 'almacen.equipos' },
        { name: 'Crear material', route: 'almacen.materiales.crear' },
        { name: 'Editar material', route: 'almacen.materiales.editar' },

        // Almacen - Materiales
        { 
            name: 'Materiales', 
            routes: [
                'almacen.materiales', 
                'bodega.materiales', 
                'proveedores.ver.materiales', 
                'almacen.categorias.materiales'
            ]
        },

        // Almacen - Stock
        { 
            name: 'Stock', 
            routes: [
                'almacen.stock', 
                'almacen.materiales.ver.stock'
            ]
        },
        
        // Almacen - Ver
        { 
            name: 'Información', 
            routes: [
                'almacen.materiales.ver.datos', 
                'oportunidades.ver.informacion', 
                'obras.vigentes.ver.informacion', 
                'proveedores.ver.informacion',
                'talleres.ver.informacion',
            ]
        },

        {
            name: 'Etapas',
            routes: [
                'almacen.etapas',
                'almacen.etapas.ver',
                'almacen.etapas.ver.configuracion'
            ]
        },
        
        // Almacen - mantenimiento
        { 
            name: 'Mantenimiento', 
            routes: [
                'almacen.mantenimiento', 
                'almacen.materiales.ver.mantenimiento'
            ]
        },
        // Almacen - pedidos internos
        { name: 'Pedidos internos', route: 'almacen.pedidos.internos' },
        { name: 'Estado del pedido', route: 'almacen.pedidos.internos.estado.pedido' },
        { name: 'Ver OC', route: 'almacen.pedidos.ver.orden.compra' },

        { name: 'Planes de mantenimiento', route: 'almacen.mantenimiento.planes.ver' },

        // Oportunidades
        { name: 'Oportunidades', route: 'oportunidades.main' },
        { name: 'Ganadas', route: 'oportunidades.listado.ganadas' },
        { name: 'Perdidas', route: 'oportunidades.listado.perdidas' },
        
        //proyectos
        { name: 'Proyectos', route: 'proyectos.main' },

        // Oportunidades - Ver
        { name: 'Productos', route: 'oportunidades.ver.productos' },
        { name: 'Bitácora', routes: ['oportunidades.ver.bitacora.calendario', 'oportunidades.ver.bitacora.mes', 'oportunidades.ver.bitacora.lista', 'oportunidades.ver.bitacora.semana'] },
        
        // Obras 
        { name: 'Obras', route: 'obras.main' },
        { name: 'Producción', route: 'obras.vigentes.cards' },
        { name: 'Finalizados', route: 'obras.vigentes.finalizadas' },

        // Obras - Ver
        { name: 'Equipos', route: 'obras.vigentes.ver.equipos' },
        { name: 'Servicios', route: 'obras.vigentes.ver.servicios' },
        { name: 'Control de obra', route: 'obras.vigentes.ver.control.obra' },
        { name: 'Galería', route: 'obras.vigentes.ver.galeria' },
        { name: 'Documentación', routes: ['obras.vigentes.ver.documentacion', 'obras.vigentes.servicios.documentacion'] },
        { name: 'Forma de pago', routes: ['obras.vigentes.ver.forma.pago', 'obras.vigentes.forma.pago'] },

        // Obras - servicios
        { name: 'Lista de checkeo', route: 'obras.vigentes.lista.chequeo' },
        { name: 'Visitas', route: 'obras.vigentes.servicios.visitas.listado' },
        { name: 'Orden de compra', route: 'obras.vigentes.orden.compra' },
        
        // Compras
        { 
            name: 'Compras', 
            routes: [
                'compras.main', 
                'compras.matrix', 
                'almacen.materiales.ver.compras'
            ]
        },
        { 
            name: 'Listado', 
            routes: [
                'compras.ordenes.listado', 
                'calendario.lista'
            ]
        },
        { 
            name: 'Ordenes de compra', 
            routes: [
                'compras.ordenes', 
                'bodega.ordenes-compra', 
                'proveedores.ver.ordenes', 
                'obras.vigentes.ver.orden.compra',
                'almacen.pedidos.internos.ordenes.compra'
            ]
        },
        { name: 'Semana', route: 'compras.ordenes.semanas' },
        { name: 'Facturas', route: 'orden.compra.facturas' },
        
        // Talleres
        { name: 'Talleres', route: 'talleres.main' },
        { name: 'Documentos', route: 'talleres.ver.documentos' },
        
        // Calendario
        { name: 'Calendario', routes: ['calendario.main', 'obras.vigentes.calendario'] },
        { name: 'Año', route: 'calendario.year' },
        { name: 'Mes', route: 'calendario.mes' },
        { name: 'Semanas', route: 'calendario.semanas' },

        // Calendario - Ver
        { name: 'Detalle', route: 'calendario.ver.detalle' },
        { name: 'Configuración', route: 'calendario.ver.detalle.configuracion' },
        { name: 'Plan mantenimiento', route: 'calendario.ver.detalle.configuracion.mantenimiento' },
        
        // Bodegas
        { name: 'Bodega', route: 'bodega.main'},
        { name: 'Dashboard', route: 'bodega.dashboard'},
        
        // Proveedores
        { name: 'Proveedores', route: 'proveedores.listado' },
        { name: 'Proveedores', route: 'proveedores.usuarios' },
        
        // Cotizaciones
        { 
            name: 'Cotizaciones', 
            routes: [
                'cotizaciones.main', 
                'almacen.materiales.ver.cotizaciones', 
                'oportunidades.ver.cotizaciones', 
                'obras.vigentes.ver.cotizaciones', 
                'oportunidades.ver.cotizaciones.materiales', 
                'oportunidades.ver.cotizaciones.ver', 
                'proveedores.ver.cotizaciones'
            ]
        },
        { 
            name: 'Vigentes', 
            routes: [
                'cotizaciones.vigentes', 
                'oportunidades.listado.vigentes'
            ]
        },
        { name: 'Enviadas', route: 'cotizaciones.enviadas' },
        { name: 'Cotizaciones clientes', route: 'cotizaciones.clientes' },

        // Ventas
        { name: 'Ventas', route: 'ventas.main'},
        
        // Configuracion Almacen
        { name: 'Configurar almacen', route: 'configurar.almacen' },
        { name: 'Tipo material', route: 'configurar.almacen.tipo_material' },
        { name: 'Unidades Medida', route: 'configurar.almacen.unidad' },
        { name: 'Datos Adicionales', route: 'configurar.almacen.datos_adicionales' },
        { name: 'Valor hora hombre', route: 'configurar.almacen.valor_hora_hombre' },
        { name: 'Incrementos porcentuales', route: 'configurar.almacen.incrementos_porcentuales' },
        { name: 'Estados de pedidos internos', route: 'configurar.almacen.estados.pedidos.internos' },

        // Configuracion Obras
        { name: 'Configurar obras', route: 'configurar.obras' },
        { name: 'Calendarios generales', route: 'configurar.obras.calendarios.generales' },
        { name: 'Calendarios de talleres', route: 'configurar.obras.calendarios.talleres' },
        { name: 'Carpetas', route: 'configurar.obras.carpetas' },
        { name: 'Lista de chequeo', route: 'configurar.obras.lista.chequeo' },
        { name: 'Calificación servicio', route: 'configurar.obras.calificacion.servicio' },
        
        // Configuracion paises
        { name: 'Configuración utilidad paises', route: 'configurar.paises' },

        // Configuracion Talleres
        { name: 'Configuracion de talleres', route: 'configurar.talleres' },
        { name: 'Tipos de servicios', route: 'configurar.talleres.tipos.servicios' },

        // Configuracion Permisos
        { name: 'Configuracion de permisos', route: 'configurar.permisos' },
        { name: 'Acciones', route: 'configurar.permisos.acciones' },
        { name: 'Rutas', route: 'configurar.permisos.rutas' },
    ],
    breadcumbs: []
}

export const getters = {
    breadcumbs: state => state.breadcumbs
}

export const mutations = {
    set_breadcumbs(state, payload){
        state.breadcumbs = payload
    }
}

export const actions = {
    getBreadcumbs({ state, commit }, routes){ 
        $try(() => {
            let breadcumbs = []
            routes.forEach(route => {
                state.default_breadcumbs.forEach(breadcumb => {
                    const hasRoute = typeof breadcumb?.route == 'string' && breadcumb.route  === route
                    const hasRoutes = Array.isArray(breadcumb?.routes) && breadcumb.routes.includes(route)
                    if(hasRoute) {
                        breadcumbs.push(breadcumb)
                    }else if(hasRoutes){
                        breadcumbs.push({name: breadcumb.name, route: breadcumb.routes.find(el => el === route) })
                    }
                })
            })
            commit('set_breadcumbs', breadcumbs);
        })
    },
    removeBreadcumb({ state }, position){
        $try(() => {
            state.breadcumbs.splice(position, 1);
        })
    },
    spliceBreadcumbs({ state }, other_breadcumbs){
        $try(() => {
            if(!Array.isArray(other_breadcumbs)) return 

            other_breadcumbs.forEach(({ position, breadcumb }) => {
                state.breadcumbs.splice(position,0, breadcumb);
            });
        });
    },
    replaceBreadcumb({ state }, { position, breadcumb }){
        $try(() => {
            const element = state.default_breadcumbs.reduce((acc, { name, route, routes }) => {
                const hasRoute = typeof route === 'string' && breadcumb === route
                const hasRoutes = Array.isArray(routes) && routes.includes(breadcumb)
                if (hasRoute || hasRoutes)  acc = ({ name, route: breadcumb })
                return acc
            }, {});

            if (Object.keys(element) == 0) return console.error('La ruta del breadcumb no esta registrada');
            state.breadcumbs.splice(position,1, element);
        })
    }
}