import { UseCase } from "~/domain/core/UseCase"
import  { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { PedidosInternosDetalleOcsFilterFactory } from '~/domain/entities/almacen/pedidos/factories/PedidosInternosDetalleOcsFilterFactory'
import { $try } from '~/services/global'

class GetFiltroOrdenesComprasDetalleUseCase extends UseCase {
    static async execute(payload){
        const data = await PedidosInternosOcs.getFiltrDetalleoOrdenesCompra(payload)
        return $try(() => {
            return data.data ? PedidosInternosDetalleOcsFilterFactory.instantiate(data.data): null 
        })

    }
}

export default GetFiltroOrdenesComprasDetalleUseCase.execute