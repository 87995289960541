import * as ProviderViewDomain from '~/domain/use_cases/proveedores/ver'
import { $try } from '~/services/global'

// state
export const state = {
    provider_details: {},
    provider_files: [],
    provider_materials: [],
    provider_quotes: [],
    provider_buy_orders: [],
    regenerate_quotes: [],
    regenerate_materials: [],
    regenerate_buy_orders: [],
}

// getters
export const getters = {
    provider_details: state => state.provider_details,
    provider_files: state => state.provider_files,
    provider_materials: state => state.provider_materials,
    provider_quotes: state => state.provider_quotes,
    provider_buy_orders: state => state.provider_buy_orders,
}

// mutations
export const mutations = {
    set_provider_details(state, provider_details){
        state.provider_details = provider_details
    },

    set_provider_files(state, provider_files){
        state.provider_files = [...state.provider_files, ...provider_files]
    },

    set_provider_materials(state, provider_materials){
        state.provider_materials = provider_materials
    },
    
    set_regenerate_materials(state, regenerate_materials){
        state.regenerate_materials = regenerate_materials
    },

    set_provider_quotes(state, provider_quotes){
        state.provider_quotes = provider_quotes
    },
    
    set_regenerate_quotes(state, regenerate_quotes){
        state.regenerate_quotes = regenerate_quotes
    },

    set_provider_buy_orders(state, provider_buy_orders){
        state.provider_buy_orders = provider_buy_orders
    },
    
    set_regenerate_buy_orders(state, regenerate_buy_orders){
        state.regenerate_buy_orders = regenerate_buy_orders
    },
}

// actions
export const actions = {
    //Regenerate from memory
    async Action_regenerate_provider_quotes({state, commit}){
        $try(() => {
            commit('set_provider_quotes', state.regenerate_quotes)
        })
    },

    async Action_regenerate_provider_materials({state, commit}){
        $try(() => {
            commit('set_provider_materials', [...state.regenerate_materials])
        })
    },

    async Action_regenerate_provider_buy_orders({state, commit}){
        $try(() => {
            commit('set_provider_buy_orders', state.regenerate_buy_orders)
        })
    },

    //

    async Action_refresh_provider_files({commit, state}, file){
        console.log('refereing', file);
        $try(() => {
            commit('set_provider_files', [file])
        })
    },

    async Action_get_provider_details({commit}, params){
        const provider_details = await ProviderViewDomain.GetProviderDetailsUseCase(params)
        $try(() => {
            if(provider_details) commit('set_provider_details', provider_details)
        })
    },

    async Action_upload_provider_file({dispatch}, params){
        const provider_files = await ProviderViewDomain.UploadProviderFileUseCase(params)
        console.log({provider_files});
        $try(() => {
            if(provider_files) dispatch('Action_refresh_provider_files', provider_files)
        })
    },

    async Action_get_provider_files({commit}, params){
        const provider_files = await ProviderViewDomain.GetProviderFilesUseCase(params)
        $try(() => {
            if(provider_files) commit('set_provider_files', provider_files)
        })
    },

    async Action_get_provider_materials({commit}, params){
        const provider_materials = await ProviderViewDomain.GetProviderMaterialsUseCase(params)
        $try(() => {
            if(provider_materials){
                commit('set_provider_materials', provider_materials)
                commit('set_regenerate_materials', provider_materials)
            }
        })
    },
    
    async Action_get_provider_quotes({commit}, params){
        const provider_quotes = await ProviderViewDomain.GetProviderQuotesUseCase(params)
        $try(() => {
            if(provider_quotes){
                commit('set_provider_quotes', provider_quotes)
                commit('set_regenerate_quotes', provider_quotes)
            }
        })
    },

    async Action_get_provider_buy_orders({commit}, params){
        const provider_buy_orders = await ProviderViewDomain.GetProviderBuyOrdersUseCase(params)
        $try(() => {
            if(provider_buy_orders){
                commit('set_provider_buy_orders', provider_buy_orders)
                commit('set_regenerate_buy_orders', provider_buy_orders)
            }
        })
    },
}