import Api from '~/services/api'

export const Pedidos = {
    getPedidosInternos(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/pedidos-internos`, payload)
    },
    getFiltrosPedidosInternos(){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/pedidos-internos/filtros`)
    },
    addPedidoInterno(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`almacen/pedidos-internos`, payload)
    },
    updatePedidoInterno(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`almacen/pedidos-internos/${payload.id}`, payload)
    },
    deletePedidoInterno(id){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`almacen/pedidos-internos/${id}`)
    },
    getPedidoInterno(id){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/pedidos-internos/${id}`)
    },
    getPedidosInternosMateriales(id, payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/pedidos-internos/${id}/materiales`, payload)
    },
    getFiltroMaterialPedido(id){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/pedidos-internos/${id}/materiales/filtros`)
    },
    addMaterialPedidoInterno(id, payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`almacen/pedidos-internos/${id}/materiales`, payload)
    },
    updateMaterialPedidoInterno({id_pedido, id}, payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`almacen/pedidos-internos/${id_pedido}/materiales/${id}`, payload)
    },
    deleteMaterialPedidoInterno(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`almacen/pedidos-internos/${payload.id_pedido}/materiales/${payload.id}`)
    },
    updateEstadoPedidoMaterial({id_pedido, id}, payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`almacen/pedidos-internos/${id_pedido}/materiales/${id}/estado`, payload)
    },
    updateProveedorPedidoMaterial({id_pedido, id}, payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`almacen/pedidos-internos/${id_pedido}/materiales/${id}/proveedor`, payload)
    },
    getSelectEstadoPedidoMaterial(){
        const ApiHandler = new Api()
        return ApiHandler.get(`almacen/pedidos-internos/estados`)
    }
}