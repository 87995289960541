import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { ConfiguracionVOFactory } from '~/domain/value_objects/servicios/factories/ConfiguracionVOFactory'
import { $try } from '~/services/global'

class GetSelectConfiguracionUseCase extends UseCase {
    static async execute(payload){
        const data = await Selects.GetSelectConfiguraciones(payload)
        return $try(() => {
            if(data?.data) return data.data.map(el => ConfiguracionVOFactory.instantiate(el))
            return []
        })
    }
}

export default GetSelectConfiguracionUseCase.execute;