import { UseCase } from '~/domain/core/UseCase'
import StockMaterials from '~/services/almacen/stock'
import { $try } from '~/services/global'

class InOutOperationUseCase extends UseCase {
    static async execute(params){
        const data = await StockMaterials.inOutOperation(params)
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default InOutOperationUseCase.execute