
import * as ProviderOCDomain from '~/domain/use_cases/proveedores/ver/ordenes_compra'

import { ProviderPurchaseOrderFilterFactory } from '~/domain/filters/proveedores/ordenes_compra/factories/ProviderPurchaseOrderFilterFactory'

import { $try } from '~/services/global'
// state
export const state = {
    oportunities: [],
    clients: [],
    filter: ProviderPurchaseOrderFilterFactory.instantiateEmpty(),
}

// getters
export const getters = {
    oportunities: state => state.oportunities,
    clients: state => state.clients,
    filter: state => state.filter,
}


// mutations
export const mutations = {
    set_buy_order_oportunities(state, oportunities){
        state.oportunities = oportunities
    },

    set_buy_order_clients(state, clients){
        state.clients = clients
    },

    set_filter(state, payload){
        state.filter = payload
    }
}

//actions
export const actions = {
    async Action_get_buy_orders_opportunities({commit}, params){
        const opportunities = await ProviderOCDomain.GetBuyOrdersOpportunitesUseCase(params)
        $try(() => {
            commit('set_buy_order_oportunities', opportunities)
        })
    },

    async Action_get_buy_orders_clients({commit}, params){
        const clients = await ProviderOCDomain.GetBuyOrdersClientsUseCase(params)
        $try(() => {
            commit('set_buy_order_clients', clients)
        })
    },

    clear_filter({commit}){
        commit('set_filter', ProviderPurchaseOrderFilterFactory.instantiateEmpty())
    }
}