import { UseCase } from "~/domain/core/UseCase";
import { CalificacionService } from '~/services/configurar/obras/calificacion_servicio'
import { CalificacionFactory } from '~/domain/entities/configurar/obras/calificacion_servicio/factories/calificacionFactory'
import { $try } from '~/services/global'

class GetQuestionsUseCase extends UseCase {
    static async execute(params){
        const data = await CalificacionService.getQuestions(params)

        return $try(() => {
            if(data?.data && Array.isArray(data.data)){
                return data.data.map(e => CalificacionFactory.instantiate(e) ) 
            } 
        })
    }
}

export default GetQuestionsUseCase.execute