import { Entity } from '~/domain/entities'

export class CalendarioAnnio extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'etapas',
                'configuraciones',
                'materiales',
                'planes_mantenimiento',
                'actividades',
                'dias',
                'mes',
                'ano',
                'nombre',
                'pasos',
            ]
        })
    }
}