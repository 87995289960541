import { Entity } from '~/domain/entities'

export class Etapas extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                "id",
                "etapa",
                "configuraciones",
            ]
        })
    }
}


