import moment from 'moment';
require('moment/locale/es');

const bucket = process.env.VUE_APP_BUCKET
const bucket_manager = process.env.VUE_APP_BUCKET_MANAGER
const bucket_sandbox = process.env.VUE_APP_BUCKET_SANDBOX

export const HelperUcfirst = texto =>{
	if (texto && typeof texto === "string") {
		return texto.charAt(0).toUpperCase() + texto.slice(1);			
	}
}

export const HelperStorage = (url, option) => {
    const default_images = {
        category: '/img/default/no-category.png'
    }
    if (url) return `https://${bucket}.s3.amazonaws.com/${url}`
    return default_images[option] || '/img/default/no-image.png'
}

export const HelperStorageSandbox = (url, option) => {
    const default_images = {
        category: '/img/default/no-category.png'
    }
    if (url) return `https://${bucket_sandbox}.s3.amazonaws.com/${url}`
    return default_images[option] || '/img/default/no-image.png'
}

export const HelperStorageManager = url => {
    if (url) {
        return `https://${bucket_manager}.s3.amazonaws.com/${url}`
    }
}

export const HelperFecha = (valor, formato) => {
    if (moment(valor).isValid() && formato){
        return moment(valor).format(formato);
    }
    if (moment(valor).isValid()){
        return moment(valor).format('MMM Y');
    }
    return '';
} 

export const HelperWeek = (valor, formato = 'DD MMMM YY') => {
    if (moment(valor).isValid() && formato){
        return `${moment(valor).startOf('week').format(formato)} - ${moment(valor).endOf('week').format(formato)}`;
    }
    return '';
}