import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class DeleteStageConfUseCase extends UseCase {
    static async execute(params){
        const { id, id_etapa } = params
        const data = await Stages.deleteConfiguration({id, id_etapa})
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default DeleteStageConfUseCase.execute