import { UseCase } from "~/domain/core/UseCase"
import Materials from "~/services/configurar/almacen/tipos_material"
import { $try } from '~/services/global'

class GetAdditionalDataTypeUseCase extends UseCase {
    static async execute(params){
        const data = await Materials.getAdditionalDataTypes()
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default GetAdditionalDataTypeUseCase.execute