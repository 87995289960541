import { UseCase } from '~/domain/core/UseCase'
import Materials from "~/services/almacen/materiales"
import { $try } from '~/services/global' 

class GetMaterialsRangeUseCase extends UseCase {
    static async execute(){
        const data = await Materials.getMaterialsRange()
        return $try(() => {
            if(data?.data?.valor){
                return [
                    parseInt(data.data.valor[0]),
                    parseInt(data.data.valor[1]),
                ]
            }
        })
    }
}

export default GetMaterialsRangeUseCase.execute