import { UseCase } from "~/domain/core/UseCase"
import { Calendario } from "~/services/calendario/calendario"
import { FechasVencimientoOpsFactory } from "~/domain/entities/calendario/factories/FechasVencimientoOpsFactory"
import { $try } from "~/services/global"

class GetOpsFechasProximas extends UseCase{
    static async execute(){
        const data = await Calendario.getOpsFechasProximas()
        return $try(() => {
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => FechasVencimientoOpsFactory.instantiate(e))
        })
    }
}

export default GetOpsFechasProximas.execute