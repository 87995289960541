import Api from '~/services/api'

const Service = new Api()

const MaterialTypes = {
    listMaterialTypes(){
        const Service = new Api()
        return Service.get('/configuracion/tipos-materiales')
    },
    createMaterialType({payload}){
        const Service = new Api({notification: true})
        return Service.post('/configuracion/tipos-materiales', payload)
    },
    addPositionMaterialTypes(payload){
        const Service = new Api({notification: true})
        return Service.post('/configuracion/tipos-materiales/posicion', payload)
    },
    editMaterialType({id, payload}){
        const Service = new Api({notification: true})
        return Service.put(`/configuracion/tipos-materiales/${id}`, payload)
    },
    deleteMaterialType({id, payload}){
        const Service = new Api({notification: true})
        return Service.delete(`/configuracion/tipos-materiales/${id}`)
    },
    showMaterial({ id }) {
        return Service.get(`almacen/ver-materiales/${id}`)
    },

}

export default MaterialTypes