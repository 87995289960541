import { WorkshopFilter } from "../WorkshopFilter";

export class WorkshopFilterFactory {
    static instantiate(obj){
        return new WorkshopFilter(obj)
    }

    static instantiateEmpty(range){
        const servicios_min = range?.min ?? 0
        const servicios_max = range?.max ?? 0
        
        const workshop = new WorkshopFilter({
            nombre_taller: '',
            direccion: '',
            telefono: '',
            responsables: [],
            servicios_min,
            servicios_max,
        })

        workshop.setExceptions({
            servicios_min,
            servicios_max,
        })

        return workshop
    }
}