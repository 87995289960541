import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'
import { CalendarSubactivityVOFactory } from '~/domain/value_objects/configurar/obras/calendario_general/factories/CalendarSubactivityVOFactory'
import { $try } from '~/services/global'

class CreateCalendarSubactivityUseCase extends UseCase {
    static async execute(params){
        const data = await GeneralCalendarService.createGeneralCalendarSubactivity(params)

        return $try(() => {
            if(data?.data) return CalendarSubactivityVOFactory.instantiate(data.data)
        })
    }    
}

export default CreateCalendarSubactivityUseCase.execute