import { StockMateriales } from "../StockMateriales";

export class StockMaterialesFactory {
    static instantiate(obj){
        return new StockMateriales(obj)
    }

    static instantiateEmpty(){
        return new StockMateriales({
            id: '',
            unidades: '',
            comentario: '',
            id_bodega: '',
            tipo: '',
            fecha_ultimo_movimiento: '',
            id_material: '',
            nombre: '',
            referencia: '',
            valor: '',
            valor_total: '',
            tipo_material: '',
            unidad_medida: '',
            estado: '',
            unidades_disponibles: ''
        })
    }
}