import { UseCase } from "~/domain/core/UseCase";
import { PresenciaEquipos } from '~/services/ventas/presenciaEquipos'
import { VentasPresenciaEquiposFactory } from '~/domain/entities/ventas/factories/VentasPresenciaEquiposFactory'
import { PaginationDecorator } from '~/domain/decorators/PaginationDecorator'
import { $try } from '~/services/global'

class GetPresenciaEquiposUseCase extends UseCase {
    static async execute(payload){
        const data =  await PresenciaEquipos.getVentasPresenciaEquipos(payload)
        return $try(() => {
            if( data?.data ){
                return new PaginationDecorator( data.data, VentasPresenciaEquiposFactory.instantiate );
            }
        })
    }
}

export default GetPresenciaEquiposUseCase.execute