import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { ProjectVOFactory } from '~/domain/value_objects/proyectos/factories/ProjectVOFactory'
import { limitText } from '~/domain/helpers/string'
import { $try } from '~/services/global'

class GetSelectProjectsUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectProjects()
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(({ nombre,...rest }) => ProjectVOFactory.instantiate({ nombre: limitText(nombre, 28), ...rest}))
        })
    }
}

export default GetSelectProjectsUseCase.execute