import { UseCase } from "~/domain/core/UseCase";
import { PurchaseOrder } from '~/services/compras/ordenes_compra'
import { WeekFactory } from '~/domain/entities/compras/ordenes_compra/factories/WeekFactory'
import { $try } from '~/services/global'
 
class GetWeekStatesUseCase extends UseCase {
    static async execute({id, anio}){
        const data = await PurchaseOrder.getWeeks({id, anio})
        return $try(() => {
            if(data.data && Array.isArray(data.data)) return data.data.map(week => WeekFactory.instantiate(week))
        })
    }
}

export default GetWeekStatesUseCase.execute