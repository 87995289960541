import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { PurchaseOrderFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/PurchaseOrderFactory'
import { $try } from '~/services/global';

class DeliverPurchaseOrderUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso, id_orden_compra, id_ordenes_compras_facturas }) {
        const data = await PurchaseOrder.deliverPurchaseOrder(id_proyecto, id_control_piso, id_orden_compra, id_ordenes_compras_facturas);
        return $try(() => PurchaseOrderFactory.instantiate(data?.data));
    }
}

export default DeliverPurchaseOrderUseCase.execute;