import { UseCase } from "~/domain/core/UseCase";
import PermisosAccionesList from "~/services/configurar/permisos/acciones"
import { PermisoRutaFactory } from '~/domain/entities/configurar/permisos/factories/PermisoRutaFactory'
import { $try } from '~/services/global'

class CreatePermisoRutaUseCase extends UseCase {
    static async execute(params){ 
        const { data } =  await PermisosAccionesList.createPermisoRuta(params)

        return $try(  
            () => PermisoRutaFactory.instantiate(data)
        )
    } 
}

export default CreatePermisoRutaUseCase.execute


