import { UseCase } from '~/domain/core/UseCase'
import { Equipos } from '~/services/almacen/equiposModelos'

class CreateEquiposModelosUseCase extends UseCase {
    static async execute(payload){
        const data = await Equipos.createEquipos(payload)
        return data?.message
    }
}

export default CreateEquiposModelosUseCase.execute