import { UseCase } from "~/domain/core/UseCase";
import { Equipos } from '~/services/obras/equipos/agregarEquipo'
import { EquipoFactory } from '~/domain/entities/obras/equipos/factory/equiposFactory'
import { $try } from '~/services/global'

class AgregarEquipoProyectoUseCase extends UseCase{
    static async execute(payload){
        const  data  =  await Equipos.addProyectoEquipo(payload)
        return $try(() => {
            // if(data?.data) return new EquipoFactory.instantiate(data.data)
            if(data?.data && Array.isArray(data.data)) return data.data.map(e => new EquipoFactory.instantiate(e)) 

        })
    }
}

export default AgregarEquipoProyectoUseCase.execute