import { UseCase } from '~/domain/core/UseCase'
import { Category } from '~/services/almacen/categorias/categorias'
import { CategoryFactory } from '~/domain/entities/almacen/categorias/factories/CategoryFactory'

class CreateCategoryUseCase extends UseCase {
    static async execute(payload){
        const data = await Category.createCategory(payload)
        return data?.data?.id
    }
}

export default CreateCategoryUseCase.execute