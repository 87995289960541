import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades.js'
import { $try } from '~/services/global'

class AddPrincipalResponsibleUseCase extends UseCase {
    static async execute(params){
        await Opportunities.addPrincipalResponsiblesOpportunity(params)
    }
}

export default AddPrincipalResponsibleUseCase.execute