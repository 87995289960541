import Api from '~/services/api'

export const Configuracion = {
    getConfiguracion(payload) {
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload.id}/configuracion/${payload.id_configuracion}`, {tabla:payload.tabla})
    },
    getSelectMateriales(payload) {
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload.id}/configuracion/${payload.id_configuracion}/select-materiales`, {tabla:payload.tabla})
    },
    getMateriales(payload) {
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${payload.id}/configuracion/${payload.id_configuracion}/materiales`, payload)
    },
    postAddMateriales({params, payload}) {
        const ApiHandler = new Api()
        return ApiHandler.post(`obras/vigentes/${params.id}/configuracion/${params.id_configuracion}/materiales`, payload)
    },
    putUpdateMaterial({params, payload}) {
        const ApiHandler = new Api()
        return ApiHandler.put(`obras/vigentes/${params.id}/configuracion/${params.id_configuracion}/materiales/${payload.id}`, payload)
    },
    deleteMaterial({params, payload}) {
        const ApiHandler = new Api()
        return ApiHandler.delete(`obras/vigentes/${params.id}/configuracion/${params.id_configuracion}/materiales/${payload.id}`, {tabla:payload.tabla})
    },
    deleteConfiguracion(params) {
        const ApiHandler = new Api()
        return ApiHandler.delete(`obras/vigentes/${params.id}/configuracion/${params.id_configuracion}`, {tabla:params.tabla})
    },
    getDataFiltro(params) {
        const ApiHandler = new Api()
        return ApiHandler.get(`obras/vigentes/${params.id}/configuracion/${params.id_configuracion}/filtro-materiales`, {tabla:params.tabla})
    },
}
