import { UseCase } from '~/domain/core/UseCase'
import StockMaterials from '~/services/almacen/stock'
import { $try } from '~/services/global'

class GetBodegasUseCase extends UseCase {
    static async execute(){
        const data = await StockMaterials.listBodegas()
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetBodegasUseCase.execute
