<template>
    <div class="my-3 tabla-general">
        <!-- Cabecera top -->
        <slot name="cabecera-superior" class="row d-middle" />

        <div class="col-12 p-0">
            <div class="row d-middle">
                <!-- Cabecera izquierda -->
                <slot name="cabecera-izquierda" />

                <!-- Barra de búsqueda -->
                <div class="ml-auto col-auto">
                    <el-input
                    v-if="mostrarBuscador"
                    v-model="textoBusqueda"
                    placeholder="Buscar en el listado"
                    size="small"
                    prefix-icon="el-icon-search"
                    class="cabecera-busqueda mx-1"
                    />
                </div>

                <!-- Cabecera derecha -->
                <slot name="cabecera-derecha" /> 
            </div>
            <!-- botones adicionales -->
            <div class="row mx-0">
                <slot name="botones-adicionales" />
            </div>  
        </div>

        <!-- Tabla -->
        <div>
            <el-table
            ref="eltabla"
            v-loading="cargando"
            fit
            :height="alturaTabla"
            :stripe="conFranjas"
            :data="datosDinamicos"
            :class="claseSeleccionable"
            :empty-text="mensaje"
            :row-class-name="rowClassName"
            class="w-100 my-3 tabla-element"
            @row-click="rowClick"
            @cell-click="eventoSeleccion"
            @selection-change="eventoSeleccionMultiple"
            @sort-change="ordenarSegunCampo"
            >
                <!-- Columna de selección múltiple -->
                <el-table-column v-if="activarSeleccionMultiple" type="selection" min-width="30" />

                <!-- para que el indice quede de primero -->
                <slot name="indice">
                    <el-table-column
                    v-for="(col, index) in columnaIndice"
                    :key="col.id"
                    :prop="col.valor"
                    :label="col.titulo"
                    :sortable="col.ordenar ? col.ordenar : false"
                    :min-width="col.ancho || 180"
                    :align="col.alinear"
                    :fixed="col.fijar || false"
                    :class-name="col.class"
                    :formatter="col.formato"
                    :type="col.tipo || ''"
                    :index="calcularIndiceActual(index)"
                    />
                </slot>

                <!-- Slot para agregar columnas adicionales a la izquierda -->
                <slot name="adicionales-izquierda" />

                <!-- Slot para agregar columnas en el template. Por defecto mostrará las que se le pasen en el array "Columnas" -->
                <slot>
                    <el-table-column
                    v-for="(col, index) in columnasDibujables"
                    :key="col.id"
                    :prop="col.valor"
                    :label="col.titulo"
                    :sortable="col.ordenar ? col.ordenar : false"
                    :min-width="col.ancho || 180"
                    :align="col.alinear"
                    :fixed="col.fijar || false"
                    :class-name="col.class"
                    :formatter="col.formato"
                    :type="col.tipo || ''"
                    :index="calcularIndiceActual(index)"
                    />
                </slot>

                <!-- Slot para agregar columnas adicionales a la derecha -->
                <slot name="adicionales-derecha" />
            </el-table>
        </div>
        <!-- Paginación -->
        <div v-if="usarPaginacion" class="d-flex justify-content-center mt-4">
            <el-pagination
            :current-page.sync="paginacion.paginaActual"
            :page-size.sync="paginacion.porPagina"
            :page-sizes="paginacion.selectorPaginado"  
            :total="paginacion.total"
            :layout="paginacion.botones"
            background
            @current-change="validarPaginacion"
            @size-change="validarPaginacion"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        alturaTabla:{
            type:String,
            default:'calc(100vh - 20.5rem)'
        },
        data: {
            type: Array,
            default: () => []
        },
        columnas: {
            type: Array,
            default: () => []
        },
        columnasFormateadas: {
            type: Object,
            default: () => ({})
        },
        mostrarBuscador: {
            type: Boolean,
            default: true
        },
        mostrarIndice: {
            type: Boolean,
            default: false
        },
        activarSeleccion: {
            type: Boolean,
            default: false
        },
        clasePersonalizada:{
            type: String,
            default: ''
        },
        conFranjas:{
            type: Boolean,
            default: false,
        },
        activarSeleccionMultiple: {
            type: Boolean,
            default: false
        },
        botones: {
            type: Array,
            default: () => []
        },
        configuracionUsuario: {
            type: Object,
            default: () => ({})
        },
        usarPaginacion: {
            type: Boolean,
            default: true
        },
        usarServidor: {
            type: Boolean,
            default: false
        },
        servidorData: {
            type: [Array, Object],
            default: () => {
                return {
                    current_page: 1,
                    per_page: 1,
                    total: 1,
                    data: []
                }
            }
        },
        cargando: {
            type: Boolean,
            default: false
        },
        mensaje:{
            type: String,
            default:'No hay registros...'
        },
        itemsSeleccionMultiple:{
            type: Array,
            default: () =>[] 
        },
        layoutServidor:{
            type: String,
            default: 'prev, pager, next, jumper, total'
        },
        rowClassName:{
            type: [Function, String],
            default: () => ''
        }
    },
    data(){
        return {
            datosDinamicos: [],
            datosFiltrados: [],
            textoBusqueda: '',
            totalDatos: 0,
            configuracion: {
                paginaActual: 1,
                porPagina: 25,
                selectorPaginado: [5, 10, 15, 25, 50, 100],
                celdasSeleccionables: [0, 1, 2],
                ...this.opciones
            },
            ordenar: [],
            estaOrdenado:false,
        }
    },
    computed: {
        
        /*
            Array de datos el cual pasará por un proceso de "limpieza", quitando todas las keys innecesarias (watch)
            Este será siempre el array de datos base para ejecutar búsquedas en la tabla.
            Este array es inmutable, a menos que cambien los datos del componente padre.
        */
        datosEstaticos(){
            return this.usarServidor
                ? _.cloneDeep(this.servidorData.data)
                : _.cloneDeep(this.data)

        },
        paginacion(){
            return {
                paginaActual: this.usarServidor
                    ? this.servidorData.current_page
                    : this.configuracion.paginaActual,

                porPagina: this.usarServidor
                    ? this.servidorData.per_page
                    : this.configuracion.porPagina,

                total: this.usarServidor
                    ? this.servidorData.total
                    : this.totalDatos,

                botones: this.usarServidor
                    ? this.layoutServidor || 'prev, pager, next, jumper, total'
                    : 'sizes, prev, pager, next, jumper',

                selectorPaginado: this.configuracion.selectorPaginado,
            }
        },
        claseSeleccionable(){
            return this.activarSeleccion ? 'cr-pointer' : this.clasePersonalizada
        },
        columnaIndice(){
            return [
                ...(this.mostrarIndice ? [{ titulo: '#', tipo: 'index', ancho: 30, alinear: 'center' }] : []),
            ]
        },
        columnasDibujables(){
            return [
                // ...(this.mostrarIndice ? [{ titulo: '#', tipo: 'index', ancho: 30, alinear: 'center' }] : []),
                ...this.columnas.filter(item => item.valor)
            ]
        },
        columnasBusqueda(){
            return this.columnas.flatMap(item => item.valor ?? item)
        },
        criteriosBusqueda(){
            return this.textoBusqueda.trim().split(' ')
        },
        textoBusquedaSinEspacios(){
            return this.textoBusqueda.trim()
        },
        campoBusquedaEstaVacio(){
            return _.isEmpty(this.textoBusqueda.trim())
        },
        altoDinamico(){
            return this.usarPaginacion
                ? { contenedor: 'height:calc(100vh - 270px)', tabla: '100%' }
                : { contenedor: null, tabla: null }
        }
    },
    watch: {
        /*
            Array copia de datosEstaticos el cual mostrará los datos filtrados.
            El array estará cambiando a medida que se apliquen filtros.
        */
        datosEstaticos: {
            immediate: true,
            handler(datos){
                if (this.usarServidor){
                    this.datosDinamicos = _.cloneDeep(datos)
                } else {
                    this.paginar(datos)
                }
            }
        },
        //Se envia un array con los indices de las filas que se desen chekear cuando seleccion multiple esta activado
        itemsSeleccion(items){
            if(items.length > 0) this.mostrarItemsSeleccionados(items)
        },
        /*
            Se aplica un handler al v-model del campo de búsqueda, para así ejecutar a través del debouncer
        */
        textoBusqueda: {
            handler(){
                this.debouncer()
            },
            deep: true
        },
    },
    methods: {
        /*
            Esta función vacía, recibirá el la función _.debounce del mounted, para invocarse cuando se necesite
        */
        debouncer: _.debounce(function(){
            this.validarBusqueda()
        }, 200),
        ordenarSegunCriterio(criterio, orden, columna){
            return _.orderBy(criterio, [datos => datos[columna].toString().toLowerCase().trim()], [orden === 'descending' ? 'desc' : 'asc'])
        },
        /*
            Ordena los campos de fecha, según el objeto enviado por props
        */
        ordenarSegunCampo({ column: { sortable: ordenamiento }, prop, order: orden }){
            const columna = this.columnasFormateadas[prop] || prop
            // const array = _.isEmpty(this.datosFiltrados) ? this.datosEstaticos : this.datosFiltrados
            // const ordenado = _.orderBy(array, [columna], [orden === 'descending' ? 'desc' : 'asc'])
            if(this.usarServidor){
                const tipoOrdenamiento = {
                    'ascending' :'asc',
                    'descending' : 'desc',
                    null: null
                }
                this.$emit('ordenar',{ valor: prop, tipo: tipoOrdenamiento[orden]  })
            }
            if(orden && !this.campoBusquedaEstaVacio){
             
                const ordenado = this.ordenarSegunCriterio(this.datosFiltrados,orden,columna)
                this.estaOrdenado = true
                this.paginar(ordenado)
            }
            else if (orden && this.campoBusquedaEstaVacio){
                
                const ordenado = this.ordenarSegunCriterio(this.datosEstaticos,orden,columna)
              
                this.estaOrdenado = true
                this.ordenar = ordenado
                this.paginar(ordenado)
            }
            else if (!orden && !this.campoBusquedaEstaVacio){
                this.estaOrdenado = false
                this.paginar(this.datosFiltrados)
            }
            else if(!orden && this.campoBusquedaEstaVacio){
                this.paginar(this.datosEstaticos)
                this.estaOrdenado = false
            }
        },
        /**
            Evento que se ejecuta si se presiona una fila. Debe estar activada la selección única
            @params = args [row, column, cell, event] (element table)
        */
        eventoSeleccion(...args){
          
            for (var i = 0; i < this.columnasDibujables.length; i++){
                this.configuracion.celdasSeleccionables[i] = i
            }
            if (! this.configuracion.celdasSeleccionables
                .includes(args[2].cellIndex))
                return

            this.$emit('seleccion',  args[0])
        },
        eventoSeleccionMultiple(seleccionados){
            this.$emit('seleccion', seleccionados)
        },
        rowClick(e){
            this.$emit('redirect', e)
        },
        /*
            Valida si el campo de búsqueda está vacío o tiene texto. Si está vacío, ejecuta el paginar.
            Si tiene texto, ejecuta la búsqueda.
        */
        validarBusqueda(){
            if (this.usarServidor){
                this.$emit('buscar', this.textoBusquedaSinEspacios)
            } else {
                this.validarPaginacion()
            }
        },
        /*
            Si es ServerSide, emitirá el evento paginar. Si no, invocará la función paginar
        */
        validarPaginacion(pagina){
            //Verifica si hay que paginar
            if (this.usarServidor){
              
                this.$emit('paginar', pagina)

            }
            else if(!this.campoBusquedaEstaVacio && this.estaOrdenado){
              
                this.ejecutarBusqueda(this.ordenar)
                this.paginar(this.datosFiltrados)
            }   
            else if (!this.campoBusquedaEstaVacio && !this.estaOrdenado){
               
                this.ejecutarBusqueda(this.datosEstaticos)
                this.paginar(this.datosFiltrados)
            }
            //verifica si esta ordenando
            else if(this.estaOrdenado){
              
                this.paginar(this.ordenar)
                
            }else{
               
                this.paginar(this.datosEstaticos)
                
            }
        },
        /*
            El paginar tomará la página actual y comparará cuántos registros necesita por página.
            En base a ese cálculo, se toman los datos en el intérvalo necesario (página)
        */
        paginar(datos = []){
            this.datosDinamicos = this.usarPaginacion
                ? _.cloneDeep(datos.slice(
                    (this.paginacion.paginaActual - 1) * this.paginacion.porPagina,
                    this.paginacion.paginaActual * this.paginacion.porPagina
                ))
                : _.cloneDeep(datos)

            this.totalDatos = datos.length
        },
        quitar_acento(str){
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        },
        /*
            Búsqueda en los registros a partir de los criterios de búsqueda dados en el campo buscar.
        */
        ejecutarBusqueda(datos){
            this.datosFiltrados = []
            datos.forEach(element => {
                //Recorre cada columna de la fila 
                let datoElemento = Object.values(element).join(' ')
                //Si encuentra la propiedad cliente junta sus propiedades para la busqueda
                Object.entries(element).some(([key, value]) => {
                    if(key === 'cliente') datoElemento += Object.values(value).join(' ')
                }) 
                // quita el acento de todos las cadenas antes de comparar
                datoElemento = this.quitar_acento(datoElemento)
                // Compara que todas los criterios coincidan para luego devulver las coincidencias
                let el = this.criteriosBusqueda.every(criterio => datoElemento.toString().toLowerCase().includes(criterio.toString().toLowerCase()))
                if(el){
                    this.datosFiltrados.push(element)
                    return;
                }
            })
        },
        calcularIndiceActual(indice){
            return ((this.paginacion.porPagina * (this.paginacion.paginaActual - 1))) + indice + 1
        },
        mostrarItemsSeleccionados(items){
            items.forEach(indice => {
                this.$refs.eltabla.toggleRowSelection(this.dinamicos[0])
            })  
        },
    }
}
</script>

<style lang="scss" scoped>
    .tabla-element {
        font-size: 12px;
    }
</style>
