import * as PermisosAccionesDomain from '~/domain/use_cases/configurar/permisos'
import { $try } from '~/services/global';

export const state = {
    permisosAcciones: [],
} 

export const getters = {
    permisosAcciones: state => state.permisosAcciones
}

export const mutations = {
    set_permisosAcciones(state, payload){
        state.permisosAcciones = payload
    },
    create_permiso(state, payload){
        state.permiso = payload
    },
}

export const actions = {
    async Action_get_permisosAcciones({state, commit}, page){ 
        const data = await PermisosAccionesDomain.GetPermisosAccionesUseCase({ page }) 
        $try(() => {
            if(data) commit('set_permisosAcciones', data);
        });
    },
    async Action_create_permiso({commit}, payload){  
        const permiso = await PermisosAccionesDomain.CreatePermisoUseCase(payload)
        $try(
            () => commit('create_permiso', permiso)
        )
    },
    async Action_create_permiso_ruta({commit}, payload){   
        const permiso = await PermisosAccionesDomain.CreatePermisoRutaUseCase(payload)
        $try(
            () => commit('create_permiso', permiso)
        )
    },
    async Action_add_permiso_roles({commit}, payload){  
        const permiso = await PermisosAccionesDomain.AddPermisoRolesUseCase(payload)
        $try(
            () => commit('create_permiso', permiso)
        )
    }, 
    async Action_add_rol_permisos({commit}, payload){   
        const permiso = await PermisosAccionesDomain.AddRolPermisosUseCase(payload)
        $try( 
            () => commit('create_permiso', permiso)
        ) 
    }, 
    async Action_add_rol_permisos_ruta({commit}, payload){   
        const permiso = await PermisosAccionesDomain.AddRolPermisosRutaUseCase(payload)
        $try( 
            () => commit('create_permiso', permiso)
        ) 
    }, 
    async Action_add_rol_permisos_ruta_roles({commit}, payload){   
        const permiso = await PermisosAccionesDomain.AddRolPermisosRutaRolesUseCase(payload)
        $try( 
            () => commit('create_permiso', permiso)
        ) 
    },  
    async Action_add_permisos_ruta_generales({commit}, payload){   
        const permiso = await PermisosAccionesDomain.AddPermisosRutaGeneralesUseCase(payload)
        $try( 
            () => commit('create_permiso', permiso)
        ) 
    }, 
}