import { PurchaseOrderDay } from "../PurchaseOrderDay";

export class PurchaseOrderDayFactory {
    static instantiate(obj){
        return new PurchaseOrderDay(obj)
    }

    static instantiateEmpty(){
        return new PurchaseOrderDay({
            id: '',
            cliente: '',
            proveedor: '',
            numero_materiales: '',
            estado: '',
            valor: '',
        })
    }
}