import { OpportunityTypeVO } from "../opportunityTypeVO";

export class OpportunityTypeVOFactory {
    static instantiate(obj){
        return new OpportunityTypeVO(obj)
    }

    static instantiateEmpty(){
        return new OpportunityTypeVO({
            id: '',
            nombre: '',
        })
    }
}