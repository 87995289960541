import Api from '~/services/api'

export const CorreosNotificacion = {
    getEmailNotificacionProveedores(payload){
        const ApiHandler = new Api()
        return ApiHandler.get('configurar/proveedores/correos');
    },
    postEmailNotificacionProveedores(payload){
        const ApiHandler = new Api()
        return ApiHandler.post('configurar/proveedores/correos', payload);
    },
    deleteEmail(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`configurar/proveedores/correos/${payload}`)
    },
    putEmailNotificacionProveedores(payload){
        const ApiHandler = new Api()
        return ApiHandler.put(`configurar/proveedores/correos/${payload.id}`, payload.data);
    },
    deleteProviders(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`configurar/proveedores/correos/${payload.id}/eliminar/${payload.id_email}`);
    },
}