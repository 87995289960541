import { UseCase } from "~/domain/core/UseCase"
import { Cartera } from "~/services/cobro_cartera/cartera"
import { FiltroPagarFactory } from "~/domain/entities/cobro_cartera/factories/FiltroPagarFactory"
import { $try } from "~/services/global"

class GetFiltroPagarUseCase extends UseCase{
    static async execute(payload){
        const data =  await Cartera.getCobroCarteraPagarFiltro(payload)
        return $try(() => {
            return data.data ? FiltroPagarFactory.instantiate(data.data): null 
        })
    } 
}

export default GetFiltroPagarUseCase.execute