import { Entity } from '~/domain/entities'

export class CotizacionOption extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [  
                'estados',
                'materiales_min',
                'materiales_max',
                'valor_min',
                'valor_max',
                'fecha_cotizacion_min',
                'fecha_cotizacion_max',
                'dias_credito_min',
                'dias_credito_max'
            ]
        });
    }
}