import { Entity } from '~/domain/entities'

export class OpportunityLosses extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'oportunidad',
                'cliente',
                'valor',
                'fecha_perdida',
                'equipos',
                'probabilidad',
                'obra',
                'ultimo_ciclo',
            ]
        });
    }
}