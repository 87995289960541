<template>
    <div id="menu" :class="`menu-content position-absolute menu`" :style="`opacity: ${show_menu?1:0};`">
        <div class="row" sty>
            <div class="col-4 mx-auto py-5">
				<el-input v-model="buscar" prefix-icon="el-icon-search" placeholder="Buscar módulo..." size="small" />
            </div>
        </div>
        <div class="content scroll-none" @click.stop="toggleMenu">
			<transition name="fade">
				<div v-if="actual_menu == 'menu'">
					<draggable
						class="container d-middle flex-wrap pl-5"
						style="row-gap: 4rem; column-gap: 2rem"
						:list="user.menu_fs.filter(el => (el.nombre.toLowerCase().includes(buscar.toLowerCase())))"
						:group="{ name: 'favoritos', pull: 'clone', put: false }"
						:clone="addFavorites"
					>
						<div v-for="(menu,i) in user.menu_fs.filter(el => (el.nombre.toLowerCase().includes(buscar.toLowerCase())))" :key="i">
							<menu-full-smart :element="menu"  @hideMenu="toggleMenu"/>
						</div>
					</draggable>
				</div>
				<div v-if="actual_menu == 'config'" class="container d-middle flex-wrap pl-5" style="row-gap: 4rem; column-gap: 2rem">
					<div v-for="(menu,i) in user.menu_fs.filter(el => (el.nombre.toLowerCase().includes(buscar.toLowerCase()) && el.ruta_configurar))" :key="i">
						<menu-full-smart :element="menu" config  @hideMenu="toggleMenu"/>
					</div>
				</div>
			</transition>
            <div class="container d-middle flex-wrap pl-5 mt-5 pt-4" style="row-gap: 3rem; column-gap: 2rem">
                <div v-for="(menu,i) in filtered_menus.filter(el => el.name.toLowerCase().includes(buscar.toLowerCase()))" :key="i">
                    <menu-element :element="menu"  @hideMenu="toggleMenu"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuElement from './menu/menuElement'
import MenuFullSmart from './menu/menuFullSmart'
export default {
    components: { MenuElement, MenuFullSmart },
    data(){
        return {
            filtered_menus: [],
            menu: null,
			buscar: ''
        }
    },
    created(){
        this.$store.commit('menu/SET_DEFAULT')
        setTimeout(() => {
            if(this.menu?.style?.display) this.menu.style.display = 'none'
        }, 300);
    },
    mounted(){
        this.menu = document.getElementById('menu');
    },
    computed: {
        ...mapGetters({
            menus: 'menu/current_menus',
            user: 'auth/user',
            show_menu: 'menu/show_menu',
			actual_menu: 'menu/actual_menu'
        }),
        show_menu: {
            get(){
                return this.$store.getters['menu/show_menu'];
            },
            set(val){
                return this.$store.commit('menu/SHOW_MENU', val);
            }
        }
    },
    watch: {
        show_menu(val){
            if (val) this.filtered_menus = this.menus.filter(el => {
                if (this.user?.super_admin) return el
                if (this.user?.permisos?.rutas?.some( item => item.name_ruta == el.route )) return el
            })
            this.validateMenus()
        },
        menus(val){
            this.filtered_menus = val.filter(el => {
                if (this.user?.super_admin) return el
                if (this.user?.permisos?.rutas?.some( item => item.name_ruta == el.route )) return el
            })
            this.validateMenus()
        }
    },
    methods:{
        validateMenus(){
            try {
                if (this.user?.mostrar_configuracion && this.user?.super_admin == 0 && this.actual_menu == 'config'){
                this.filtered_menus =  this.filtered_menus.filter(el => {
                    const exists = this.user?.permiso_configuracion.find(e => e.name_ruta == el.configuracion)
                    if (exists) return el
                })
                if (this.filtered_menus.length) this.filtered_menus.push(this.menus[5])
            }
            } catch (error) {
                console.log("error", error);
            }
            
        },
        toggleMenu(){
            this.show_menu = false
            // console.log(this.menu);
            setTimeout(() => this.menu.style.display = 'none', 300);
            this.$store.commit('menu/SET_ACTUAL_MENU', '');
        },
		addFavorites(menu){
			if (!this.user.menus_fs_favoritos.some(el => el.id === menu.id)) return menu
		}
    }
}
</script>

<style scoped>
.menu-content{
    position: absolute;
    top: 0;
    width: 100vw; 
    height: 100vh; 
    z-index: 14; 
    transition: opacity .3s ease-in-out;
    overflow: hidden;
    display: none;
    opacity: 0;
}

.content{
    height: calc(100% - 220px);
    overflow-y: auto;
}

.menu{
	backdrop-filter: blur(3px);
	background: rgba(0, 0, 0, 0.47);
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .25s
}
</style>