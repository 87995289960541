export default [
    {
        path: '/proveedores/proveedores',
        name: 'proveedores.listado',
        component: () => import('../../pages/proveedores/listado').then(m => m.default || m),
    },
    {
        path: '/proveedores/:id_proveedor',
        name: 'proveedores.ver',
        redirect: {name: 'proveedores.ver.informacion'},
        component: () => import('../../pages/proveedores/ver').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                name: 'proveedores.ver.informacion',
                 component: () => import('../../pages/proveedores/ver/informacion').then(m => m.default || m),
            },
            {
                path: 'materiales',
                name: 'proveedores.ver.materiales',
                 component: () => import('../../pages/proveedores/ver/materiales').then(m => m.default || m),
            },
            {
                path: 'cotizaciones',
                name: 'proveedores.ver.cotizaciones',
                 component: () => import('../../pages/proveedores/ver/cotizaciones').then(m => m.default || m),
            },
            {
                path: 'ordenes_de_compra',
                name: 'proveedores.ver.ordenes',
                 component: () => import('../../pages/proveedores/ver/ordenesCompra').then(m => m.default || m),
            },
            {
                path: 'funcionarios',
                name: 'proveedores.ver.funcionarios',
                 component: () => import('../../pages/proveedores/ver/funcionarios').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/proveedores/:id_proveedor/cotizaciones/ver/:id_cotizacion',
        name: 'proveedores.ver.cotizaciones.ver',
         component: () => import('../../pages/proveedores/ver/verCotizacion').then(m => m.default || m),
    },
]