import * as Pedidos from '~/domain/use_cases/almacen/pedidos'
import { PedidoInternoFilterFactory } from '../../../domain/filters/almacen/pedidos/factories/PedidoInternoFilterFactory'
import { PedidoMaterialFilterFactory } from '../../../domain/filters/almacen/pedidos/factories/PedidoMaterialFilterFactory'
import { PedidoInternoFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoFactory';
import { $try } from '~/services/global'

export const state = {
    pedido_interno: {
        data: [],
        pagination: {
            current_page: 1
        },
    },
    query: '',
    filtro_pedido_interno: [],
    filter: PedidoInternoFilterFactory.instantiateEmpty(),
    pedido: PedidoInternoFactory.instantiateEmpty(),
    pedido_interno_material: {
        data: [],
        pagination: {
            current_page: 1
        }
    },
    filtro_pedido_interno_material: [],
    filter_material: PedidoMaterialFilterFactory.instantiateEmpty(),
    estados: []
}

export const getters = {
    pedido_interno: state => state.pedido_interno.data,
    pagination: state => state.pedido_interno.pagination,
    query: state => state.query,
    filtro_pedido_interno: state => state.filtro_pedido_interno,
    filter: state => state.filter,
    pedido: state => state.pedido,
    pedido_interno_material: state => state.pedido_interno_material.data,
    pagination_material: state => state.pedido_interno_material.pagination,
    filtro_pedido_interno_material: state => state.filtro_pedido_interno_material,
    filter_material: state => state.filter_material,
    estados: state => state.estados
}

export const mutations = {
    set_pedido_interno(state, payload){
        state.pedido_interno = payload
    },
    set_pedido(state, payload){
        state.pedido = payload
    },
    set_query(state, payload){
        state.query = payload
    },
    set_pagination(state, payload){
        state.pedido_interno.pagination = payload
    },
    add_pedido_interno(state, payload){
        state.pedido_interno.data.push(payload)
    },
    edit_pedido_interno(state, payload){
        state.pedido_interno.data = state.pedido_interno.data.map(e => {
            if (e.id === payload.id) e = payload
            return e
        })
    },
    delete_pedido_interno(state, payload){
        state.pedido_interno = state.pedido_interno.filter(e => (e.id !== payload))
    },
    set_filtro_pedido_interno(state, payload){
        state.filtro_pedido_interno = payload
    },
    set_filter(state, payload){
        state.filter = payload
    },
    set_pedido_interno_material(state, payload){
        state.pedido_interno_material = payload
    },
    set_pagination_material(state, payload){
        state.pedido_interno_material.pagination = payload
    },
    add_pedido_interno_material(state, payload){
        state.pedido_interno_material.data.push(payload)
    },
    edit_pedido_interno_material(state, payload){
        state.pedido_interno_material.data = state.pedido_interno_material.data.map(e => {
            if (e.id === payload.id) e = payload
            return e
        })
    },
    edit_estado_pedido_interno_material(state, payload){
        let element = state.pedido_interno_material.data.find(e => e.id === payload.id)
        if (element) element.id_estado = payload.id_estado
    },
    edit_proveedor_pedido_interno_material(state, payload){
        let element = state.pedido_interno_material.data.find(e => e.id === payload.id)
        if (element) element.id_proveedor = payload.id_proveedor
    },
    delete_pedido_interno_material(state, payload){
        state.pedido_interno_material = state.pedido_interno_material.filter(e => (e.id !== payload))
    },
    set_filtro_pedido_interno_material(state, payload){
        state.filtro_pedido_interno_material = payload
    },
    set_filter_material(state, payload){
        state.filter_material = payload
    },
    set_estados(state, payload){
        state.estados = payload
    }
}

export const actions = {
    async Action_get_pedido_interno({state, commit, getters}){
        const buscar = state.query
        const filter = state.filter.clean()
        const page = getters.pagination.current_page ?? 1;
        const data = await Pedidos.GetPedidoInternoUseCase({...filter, buscar, page })
        if (data) commit('set_pedido_interno', data)
    },
    async Action_get_pedido({commit}, id){
        const data = await Pedidos.GetUnitPedidoInternoUseCase(id)
        $try(() =>{
            if (data) commit('set_pedido', data)
        })
    },
    async Action_get_pedido_interno_filtro({commit}){
        const data = await Pedidos.GetFiltrosPedidoInternoUseCase()
        $try(() =>{
            if (data) commit('set_filtro_pedido_interno', data)
        })
    },
    async Action_crear_pedido_interno({commit}, payload){
        const data = await Pedidos.AddPedidoInternoUseCase(payload)
        $try(() => {
            if (data) commit('add_pedido_interno', data)
        })
    },
    async Action_update_pedido_interno({commit}, payload){
        const data = await Pedidos.EditPedidoInternoUseCase(payload)
        $try(() => {
            if (data) commit('edit_pedido_interno', data)
        })
    },
    async Action_delete_pedido_interno( { commit }, params) {
        const data = await Pedidos.DeletePedidoInternoUseCase(params)
        $try(() => {
            if(data) commit('delete_pedido_interno', data)
        })
    },
    Action_clear_filter({commit}){
        commit('set_filter', PedidoInternoFilterFactory.instantiateEmpty())
    },
    async Action_get_pedidos_internos_materiales({state, commit, getters}, id){
        const buscar = state.query
        const filter = state.filter_material.clean()
        const page = getters.pagination_material.current_page ?? 1;
        const data = await Pedidos.GetPedidosInternosMaterialesUseCase(id, { ...filter, buscar, page })
        $try(() => {
            if (data) commit('set_pedido_interno_material', data)
        })
    },
    async Action_get_pedidos_internos_materiales_filtro({commit}, payload){
        const data = await Pedidos.GetFiltrosMaterialPedidoUseCase(payload)
        $try(() =>{
            if (data) commit('set_filtro_pedido_interno_material', data)
        })
    },
    async Action_crear_pedido_interno_material({commit}, payload){
        const data = await Pedidos.AddMaterialPedidoInternoUseCase(payload)
        $try(() => {
            if (data) commit('add_pedido_interno_material', data)
        })
    },
    async Action_update_pedido_interno_material({commit}, payload){
        const {id_pedido, id, ...rest} = payload
        const data = await Pedidos.EditMaterialPedidoUseCase({id_pedido, id}, rest)
        $try(() => {
            if (data) commit('edit_pedido_interno_material', data)
        })
    },
    async Action_update_estado_pedido_interno_material({commit}, payload){
        const {id_pedido, id, ...rest} = payload
        const data = await Pedidos.EditEstadoMaterialPedidoUseCase({id_pedido, id}, rest)
        $try(() => {
            if (data) commit('edit_estado_pedido_interno_material', data)
        })
    },
    async Action_update_proveedor_pedido_interno_material({commit}, payload){
        const {id_pedido, id, ...rest} = payload
        const data = await Pedidos.EditProveedorMaterialPedidoUseCase({id_pedido, id}, rest)
        $try(() => {
            if (data) commit('edit_proveedor_pedido_interno_material', data)
        })
    },
    async Action_delete_pedido_interno_material({commit}, payload){
        const data = await Pedidos.DeleteMaterialPedidoUseCase(payload)
        $try(() => {
            if(data) commit('delete_pedido_interno_material', data)
        })
    },
    async Action_get_estados({commit}){
        const data = await Pedidos.GetSelectEstadoPedidoUseCase()
        $try(() => {
            if (data) commit('set_estados', data)
        })
    },
    Action_clear_filter_material({commit}){
        const rangeCantidad = {
            min: state.filtro_pedido_interno_material.cantidad_min,
            max: state.filtro_pedido_interno_material.cantidad_max
        }
        commit('set_filter_material', PedidoMaterialFilterFactory.instantiateEmptyWithRange(rangeCantidad))
    }
}