import * as UnidadesMedidasDomain from '~/domain/use_cases/configurar/almacen/unidades_medidas'

// state
export const state = {
    listMeasureUnit: [],
}

// getters
export const getters = {
    listMeasureUnit: state => state.listMeasureUnit,
}

// mutations
export const mutations = {
    set_measure_unit(state, payload){
        state.listMeasureUnit = payload
    }
}

// actions
export const actions = {
    async LIST_MEASURE_UNIT({commit}){
        const measureUnits = await UnidadesMedidasDomain.ListUnitMeasureTypeUseCase()
        if(measureUnits) commit('set_measure_unit', measureUnits)
    },
    async CREATE_MEASURE_UNIT({state, commit}, payload){
        const measureUnit = await UnidadesMedidasDomain.CreateUnitMeasureTypeUseCase(payload)
        if(measureUnit) commit('set_measure_unit', [...state.listMeasureUnit, measureUnit])
    },
    async EDIT_MEASURE_UNIT({commit}, payload){
        const measureUnit = await UnidadesMedidasDomain.EditUnitMeasureTypeUseCase(payload)
        if(measureUnit) commit('set_measure_unit', state.listMeasureUnit.map(el => el.id === measureUnit.id ? measureUnit : el))
    },
    async DELETE_MEASURE_UNIT({commit}, id){
        const data = await UnidadesMedidasDomain.DeleteUnitMeasureTypeUseCase(id)
        if(data) commit('set_measure_unit', state.listMeasureUnit.filter(el => el.id != id))
    },
    async UPDATE_POSITION_MEASURE_UNIT({commit}, payload){
        const data = await UnidadesMedidasDomain.AddPosMeasureUnitUseCase(payload)
        if(data) commit('set_measure_unit', data)
    }
}