import { UseCase } from "~/domain/core/UseCase";
import { ListaChequeo } from '~/services/obras/servicios/lista_chequeo'

class AddAdjuntoActividadUseCase extends UseCase {
    static async execute(params){
        const data =  await ListaChequeo.addAdjuntoActividad(params)
        return data?.message
    }
}

export default AddAdjuntoActividadUseCase.execute