import { Entity } from '~/domain/entities'

export class calificacion extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: ['id','nombre','id_tipo']
        });
    }
}
