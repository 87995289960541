import { UseCase } from '~/domain/core/UseCase'
import { Workshops } from '~/services/talleres/talleres'
import { WorkshopGraphVOFactory } from '~/domain/value_objects/talleres/factories/WorkshopGraphVOFactory'
import { $try } from '~/services/global'

class GetServicesGraphUseCase extends UseCase {
    static async execute(){
        const data = await Workshops.getServicesGraph()
        return $try(() => {            
            return data?.data?.map(WorkshopGraphVOFactory.instantiate)
        })
    }
}

export default GetServicesGraphUseCase.execute