import Api from '~/services/api'

export const CountryService = {
    getCountries(payload){
        const Country = new Api()
        return Country.get('configuracion-pais-utilidad', payload);
    },
    createCountry(payload){
        const Country = new Api({notification: true})
        return Country.post('configuracion-pais-utilidad', payload);
    },
    updateCountry(payload){
        const Country = new Api({notification: true})
        const { id_pais, utilidad } = payload
        return Country.put(`configuracion-pais-utilidad/${payload.id}`, { id_pais, utilidad });
    }
}