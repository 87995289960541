import { OrdenesComprasListadoVO } from "../OrdenesComprasListadoVO";

export class OrdenesComprasListadoVOFactory {
    static instantiate(obj){
        return new OrdenesComprasListadoVO(obj)
    }

    static instantiateEmpty(){
        return new OrdenesComprasListadoVO({
            valor_inicial: '',
            valor_final: ''
        })
    }
}