import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { ProviderFactory } from '~/domain/entities/compras/matriz/factories/ProviderFactory'
import { $try } from '~/services/global'

class GetProvidersUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data } =  await Matriz.getProviders(params)

        $try(() => {
            if(data.length){
                data.forEach((item) =>{
                    customData.push(
                        ProviderFactory.instantiate(item))
                })
            }
        })

        return customData
    }
}

export default GetProvidersUseCase.execute