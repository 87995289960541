import { Filter } from '~/domain/core/Filter';

export class CobroFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_proyecto',
                'fecha_pago',
                'plazo_pago',
                'valor_min',
                'valor_max',
                'dias_vencimiento_min',
                'dias_vencimiento_max'
            ]
        });
    }
}