<template>
    <modal ref="modalGallery" tamano="modal-lg" :titulo="titulo">
        <carousel :imagenes="imagenes" :imgInicial="imgInicial" :titulo="titulo" />
    </modal>
</template>

<script>
export default {
    data() {
        return {
            imagenes: [],
            titulo: '',
            imgInicial: 0,
        }
    },
    methods: {
        toggle(imagenes, titulo, inicial){
            this.imagenes = imagenes ?? []
            this.titulo = titulo ?? ''
            this.imgInicial = inicial ?? 0
            this.$refs.modalGallery.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
