import { UseCase } from '~/domain/core/UseCase'
import  Services  from "~/services/configurar/talleres/tipos_servicios"
import { $try } from '~/services/global'

class UpdatePositionServicesWorkshopUseCase extends UseCase {
    static async execute(payload){
        const data = await Services.updatePositionServicesWorkshop(payload)
        return $try(() => {
			if(data?.data) return data.data
        })
    }
}

export default UpdatePositionServicesWorkshopUseCase.execute