import { Entity } from '~/domain/entities'

export class PurchaseOrder extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id', 
                'id_proveedor', 
                'id_oportunidad', 
                'id_moneda', 
                'flete', 
                'id_ordenes_compras_grupos', 
                'tipo', 
                'estado', 
                'id_cliente', 
                'oportunidad', 
                'valor', 
                'proveedor'
            ]
        });
    }
}