<template>
    <div class="d-flex justify-content-lg-center justify-content-xl-end h-100 login login-background">
        <div class="login-loading position-absolute" style="z-index: 1; overflow: hidden;">
            <img 
                src="/img/login/iaf.png" 
                id="iaf" 
                class="iaf iaf-text__size"
            >
            <lottie-vue-player 
                ref="waves"
                id="waves"
                class="lottie-waves"
                :src="`/img/login/animation.json`"
                :theme="options.theme"
                :autoplay="true"
                :player-size="options.playerSize"
                style="width: 100%; height:50vh"
            />
            <div 
                id="circles" 
                class="circles position-absolute"
            />
        </div>
        <div 
            id="loginForm" 
            class="login-form bg-transparent" 
            style="height 100vh; z-index: 2;"
        >
            <child />
        </div>
    </div>
</template>
<script>
export default {
    name: 'login-template',
    mounted(){
        setTimeout(() => {
            this.$refs.waves.stop() 
            document.getElementById('loginForm').classList.add('animation__fadein');
            document.getElementById('iaf').classList.add('animation__scaledownleft');
            document.getElementById('circles').classList.add('animation__moveleft')
        }, 2000);
    },
    data(){
        return {
            options: {
                minimizable: false,
                playerSize: "standard",
                backgroundColor: '#fff',
                backgroundStyle: 'color',
                theme: {
                    controlsView: false,
                    active: "light",
                    light: {
                        color: '#3D4852',
                        backgroundColor: '#fff',
                        opacity: '0.7',
                    },
                    dark: {
                        color: '#fff',
                        backgroundColor: '#202020',
                        opacity: '0.7',
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">
    .underline:hover{
        text-decoration: underline !important;
    }
    .input__height{
        height: 39px;
    }
    .image-size{
        width: 131px; height: 131px;
    }

    .validate-form{
        &__size{
            width: 350px;
        }
        &-text__size{
            width: 300px
        }
    }
</style>

<style scoped lang="scss">
.login{
    &-form{
        display: none;
        margin: auto 25rem;
    }
    &-background{
        background-image: url("/img/login/background.png");
    }
    &-loading{
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
}

.bg-transparent{
    background: transparent;
}

.lottie-waves{
    transform: translate(-500px,-100px);
    position: absolute;
    z-index: 3;
    top: 40%;
    left: 20%;
    display: block;
}

.iaf{
    transform: scale(1.8);
    position: absolute;
    z-index: 2;
    top: 40%;
    left: 37%;
    width: 22vh;
}

.circles, .circles::before{
    background: rgba(11, 86, 167, .7);
    border-radius: 50%;
}

.circles{
    width: 150vh;
    height: 150vh;
    left: -200px;
    top: -100px;
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100px;
        top: -200px;
    }
}

.animation{
    &__scaledownleft{
        animation: scaleDownLeft 1s;
        transform: translate(-400px,-100px) scale(1);
    }
    &__moveleft{
        transform: translate(-400px,-100px);
        animation: moveLeft 1s;
    }
    &__fadein{
        animation: fadeIn 1s;
        display: block;
    }
}

@keyframes moveLeft {
    0%{
        transform: translate(0,0);
    }
    100%{
        transform: translate(-400px,-100px);
    }
}

@keyframes scaleDownLeft {
    0%{
        transform: translate(0,0) scale(1.8);
    }
    100%{
        transform: translate(-400px,-100px) scale(1);
    }
}

@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@media (max-width: 1800px) {
    .login{
        &__px{
            margin: auto 10rem !important;
        }
    }
}

@media (max-width: 1300px) {
    .login{
        &-form{
            margin: auto;
        }
        padding: 0;
        margin: 0 auto;
        &__px{
            margin: auto 5rem !important;
            justify-content: center !important;
        }
    }
    .circles, .iaf, .lottie-waves{
        display: none;
    }
}
</style>