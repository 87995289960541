import { menusFavoritos } from '~/services/menus_favoritos';

export const state = {
    show_menu: false,
    actual_menu: '',
    menus: [
        {
            icon: 'icon-factory',
            name: 'Almacen',
            route: 'almacen.main'
        },
        // {
        //     icon: 'icon-star',
        //     name: 'Oportunidades',
        //     route: 'oportunidades.main'
        // },
        {
            icon: 'icon-shopping',
            name: 'Compras',
            route: 'compras.matrix'
        },
        {
            icon: 'icon-hammer-screwdriver',
            name: 'Talleres',
            route: 'talleres.main'
        },
        {
            icon: 'icon-account-cash',
            name: 'Pagos Proveedores',
            route: 'cobro.cartera.pagar'
        },
        {
            icon: 'icon-dolly',
            name: 'Proveedores',
            route: 'proveedores.listado'
        },
        {
            icon: 'icon-clipboard-text',
            name: 'Cotizaciones',
            route: 'cotizaciones.main'
        },
        {
            icon: 'icon-calendar-multiselect',
            name: 'Calendario',
            route: 'calendario.year'
        },
        {
            icon: 'icon-archive',
            name: 'Bodega',
            route: 'bodega.main'
        },
        {
            icon: 'icon-account-cash',
            name: 'Full Smart',
            route: 'full.smart.iaf'
        },
    ],
    config_menus: [
        {
            icon: 'icon-hammer-screwdriver',
            name: 'Talleres',
            route: 'configurar.talleres',
            configuracion: 'configurar.permisos.acciones.talleres'
        },
        {
            icon: 'icon-factory',
            name: 'Almacen',
            route: 'configurar.almacen',
            configuracion: 'configurar.permisos.acciones.almacen'
        },
        {
            icon: 'icon-robot-industrial',
            name: 'Obras',
            route: 'configurar.obras',
            configuracion: 'configurar.permisos.acciones.obras'
        },
        {
            icon: 'icon-globe',
            name: 'Utilidad paises',
            route: 'configurar.paises',
            configuracion: 'configurar.permisos.acciones.utilidad_paises'
        },
        {
            icon: 'icon-dolly',
            name: 'Proveedores',
            route: 'configurar.proveedores',
            configuracion: 'configurar.permisos.acciones.proveedores'
        },
        {
            icon: 'icon-key-chain',
            name: 'Permisos',
            route: 'configurar.permisos',
            configuracion: 'configurar.permisos.acciones.permisos'
        },
    ],
    current_menus: [],
}

export const getters = {
    show_menu: state => state.show_menu,
    actual_menu: state => state.actual_menu,
    menus: state => state.current_menus,
    config_menus: state => state.config_menus,
    current_menus: state => state.current_menus,
    filtered_menus: state => state.menus,
}

export const mutations = {
    SET_DEFAULT(state){
        state.current_menus = state.menus
    },
    CHANGE_MENU(state, payload){
        if(payload == 'menu') state.current_menus = state.menus
        if(payload == 'config') state.current_menus = state.config_menus
    },
    SHOW_MENU(state, payload){
        state.show_menu = payload
    },
    SET_ACTUAL_MENU(state, payload){
        state.actual_menu = payload
    },
    SET_MENUS(state, payload){
        state.menus = payload
    },
    SET_FILTERED_MENUS(state, payload){
        state.filtered_menus = payload
    }
}

export const actions = {
    async Action_save_menu_favorite(_, menu){
        await menusFavoritos.saveMenu(menu)
    },
    async Action_change_position_menu_favorite(_, payload){
        await menusFavoritos.changePositionMenu(payload)
    },
    async Action_delete_menu_favorite(_, id){
        await menusFavoritos.removeMenu(id)
    }
}