<template>
    <div :class="estilo">
        <div class="my-auto">
            <p class="pr-2 f-13">{{ titulo }}</p>
        </div>
        <div class="item">
            <span v-if="moneda" class="pr-1">{{ formatoMoneda(valor) }}</span>
            <span v-if="porcentaje" class="pl-1">{{ valor }} %</span>
        </div>
    </div>

</template>

<script>
export default {
    props:{
        titulo:{
            type: String,
            default: '',
            required: true
        },
        estilo:{
            type: String,
            default: 'indicador-5d',
            required: false
        },
        valor:{
            default: '',
            required: true
        },
        moneda:{
            type: Boolean,
            required: false
        },
        porcentaje:{
            type: Boolean,
            required: false
        },

    }

}
</script>

<style lang="scss" scoped>
.base-indicador{
    border-radius: 4px;
    width: 192px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
}
.big-base-indicador{
    border-radius: 4px;
    width: 192px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
}
.base-indicador-item{
    border-radius: 0px 1px 1px 0px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    color: #fff;
}

.indicador-5d{
    border: 1px solid var(--color-5d);
    color: var(--color-5d);
    @extend .base-indicador;
    .item{
        background: var(--color-5d);
        @extend .base-indicador-item;
    }
}
.indicador-mint{
    border: 1px solid #3CD273;
    color: var(--color-5d);
    @extend .base-indicador;
    .item{
        background: #3CD273;
        @extend .base-indicador-item;
    }
}
.indicador-mint-ops{
    border: 1px solid #3CD273;
    color: var(--color-5d);
    @extend .big-base-indicador;
    .item{
        background: #3CD273;
        @extend .base-indicador-item;
    }
}

</style>