import { UseCase } from "~/domain/core/UseCase"
import { PedidosInternosOcs } from '~/services/almacen/pedidos_internos_ocs'
import { InformacionDetalleOcsFactory } from '~/domain/value_objects/almacen/pedidos/factories/InformacionDetalleOcsFactory'
import { $try } from '~/services/global'

class GetInformacionDetalleOcsUseCase extends UseCase {
    static async execute(payload){
        const data = await PedidosInternosOcs.getInformacionDetalleOcs(payload)
        return $try(() => {
            return data.data ? InformacionDetalleOcsFactory.instantiate(data.data): null 
        })

    }
}

export default GetInformacionDetalleOcsUseCase.execute