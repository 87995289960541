import { UseCase } from "~/domain/core/UseCase";
import { PresenciaEquipos } from '~/services/ventas/presenciaEquipos'
import { DetalleEquipoVendidoFactory } from '~/domain/entities/ventas/factories/DetalleEquipoVendidoFactory'
import { $try } from '~/services/global'

class GetDetalleEquipoVendidoUseCase extends UseCase {
    static async execute(payload){
        const data = await PresenciaEquipos.getdetalleEquipoVendido(payload)
        return $try(() => {
            if(data?.data) return new DetalleEquipoVendidoFactory.instantiate(data.data)
        })
    }
}

export default GetDetalleEquipoVendidoUseCase.execute