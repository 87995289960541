<template>
    <div class="bg-f9 border p-3 br-8">
        <div class="row mx-0">
            <div class="col-9 my-auto">
                <div class="contenedor-progress w-100">
                    <div class="progress" :style="`width:${percentageCalc()}%`">
                    </div>
                    <el-tooltip effect="light" placement="bottom" visible-arrow>
                        <template #content>
                            <p class="f-12 f-600">{{ formatoMoneda(actualValue) }}</p>
                            <p class="f-10 font-italic my-1">Pagado</p>
                            <p class="f-12">{{ lastDate | helper-fecha('DD MMM. YYYY') }}</p>
                        </template>
                        <i class="icon-flag-triangle text-general" :style="`left:${(this.percentage)-2}%`" />
                    </el-tooltip>
                </div>
            </div>
            <div class="col-3 text-center">
                <p class="f-600">{{ formatoMoneda(totalValue) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        totalValue:{
            type: Number,
            required: true, 
            default: 0
        },
        actualValue:{
            type: [Number, String],
            required: true,
            default: 0
        },
        lastDate: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            percentage: '',
        }
    },
    methods:{
        percentageCalc(){
           return this.percentage = this.actualValue <= this.totalValue ? ((this.actualValue*100)/this.totalValue) : 100 
        }
    }

}
</script>

<style lang="scss" scoped>
.contenedor-progress{
    background: #DDDDDD;
    border-radius: 13px;
    height: 10px;
    position: relative;
    .progress{
        background: #26DE81;
        border-radius: 13px;
        height: 10px;
    }
    .icon-flag-triangle{
        position: absolute;
        top: -14px ;
        font-size: 22px;
    }
}
</style>