import Api from '~/services/api'

export const HoursMan = {
    getHoursManValue(){
        const Service = new Api()
        return Service.get('/configuraciones/almacen/horas-hombre')
    },
    createHoursManValue(payload){
        const Service = new Api({ notification: true })
        return Service.post('/configuraciones/almacen/horas-hombre', payload)
    },
    editHoursManValue({id, payload}){
        const Service = new Api({ notification: true })
        return Service.put(`/configuraciones/almacen/horas-hombre/${id}`, payload)
    },
    deleteHoursManValue({id}){
        const Service = new Api({ notification: true })
        return Service.delete(`/configuraciones/almacen/horas-hombre/${id}`, payload)
    },
}
