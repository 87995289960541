import { UseCase } from "~/domain/core/UseCase";
import { Configuracion } from '~/services/obras/configuracion/configuracion'
import { $try } from '~/services/global'
class PostAnnadirMaterialUseCase extends UseCase {
    static async execute(payload) {
        const data = await Configuracion.postAddMateriales(payload)
        return $try(() => {
            if(data?.message){
                return data.message
            }
        })
    }
}

export default PostAnnadirMaterialUseCase.execute