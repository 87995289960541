import Api from '~/services/api'

export const WorkshopView = {
    getWorkshopInfo(id_taller){
        const Service = new Api()
        return Service.get(`talleres/${id_taller}`)
    },
    getWorkshopServices(id_taller){
        const Service = new Api()
        return Service.get(`talleres/${id_taller}/tipo-servicios`)
    },
    getWorkshopActiveServices(id_taller){
        const Service = new Api()
        return Service.get(`talleres/${id_taller}/servicios-vigentes`)
    },
    getWorkshopServiceTypesAndRange(id_taller){
        const Service = new Api()
        return Service.get(`talleres/${id_taller}/filtro/servicios-finalizados`)
    },
    getWorkshopHistoryServices(id_taller, filter){
        const Service = new Api()
        return Service.get(`talleres/${id_taller}/historial-servicios`, filter)
    }
}