import { UseCase } from "~/domain/core/UseCase";
import { GeneralCalendarService } from '~/services/configurar/obras/calendario_general'
import { CalendarSubactivityVOFactory } from '~/domain/value_objects/configurar/obras/calendario_general/factories/CalendarSubactivityVOFactory'
import { $try } from '~/services/global'

class EditCalendarSubactivityUseCase extends UseCase {
    static async execute(params){
        const data =  await GeneralCalendarService.editGeneralCalendarSubactivity(params)
        return $try(() => {
            if(data?.data) {
                const { created_by, updated_at,updated_by, deleted_at, ...rest } = data.data
                return CalendarSubactivityVOFactory.instantiate(rest)
            }
        })
    }
}

export default EditCalendarSubactivityUseCase.execute