import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { $try } from '~/services/global';

class DeletePurchaseOrderBillUseCase extends UseCase {
    static async execute({ id_proyecto, id_control_piso, id_orden_compra, id_ordenes_compras_facturas }) {
        const data = await PurchaseOrder.deletePurchaseOrderBill(id_proyecto, id_control_piso, id_orden_compra, id_ordenes_compras_facturas);
        return $try(() => data?.message);
    }
}

export default DeletePurchaseOrderBillUseCase.execute;