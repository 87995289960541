import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { BillFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/BillFactory';
import { $try } from '~/services/global';

class UpdatePurchaseOrderBillUseCase extends UseCase {
    static async execute({id_proyecto, id_control_piso, id_orden_compra, id_ordenes_compras_facturas, payload}) {
        const data = await PurchaseOrder.updatePurchaseOrderBill(id_proyecto, id_control_piso, id_orden_compra, id_ordenes_compras_facturas, payload);
        return $try(() => {
            if (data?.data) return BillFactory.instantiate(data.data);
        })
    }
}

export default UpdatePurchaseOrderBillUseCase.execute;