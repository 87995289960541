import { Entity } from '~/domain/entities'

export class DetalleEquipoVendido extends Entity{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id',
                'tabla',
                'nombre',
                'foto_producto',
                'precio',
                'precio_venta',
                'cantidad_etapas',
                'materiales',
                'horas_hombre',
                'comentario',
                'categoria',
                'Subcategoria',
                'fecha_venta',
                'foto_cliente',
                'cliente',
                'etapas',
            ]
        });
    }
}