import Api from '~/services/api'

export const CotizacionCliente = {
    getCotizacion(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador`, payload)
    },

    getCotizacionClienteDetalle(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador/${payload}`)
    },

    getProductsOps(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador/oportunidades/${payload}/productos`)
    },

    getUtilidadPaises(){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador/utilidad-paises`)
    },

    getEtapasEquipo(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador/oportunidades/${payload}/etapas`)
    },

    getConfiguracionEtapasEquipo(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador/oportunidades/${payload.id_op}/etapas/${payload.id_ops_producto_etapa}/configuraciones`)
    },

    postCrearCotizacion(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`cotizaciones/cotizador`, payload)
    },

    getMaterialesConfiguracion(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador/${payload.id_cotizador}/configuraciones/${payload.id_configuracion}/materiales`, payload.page)
    },

    deleteMaterial(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`cotizaciones/cotizador/${payload.id_cotizador}/configuraciones/${payload.id_configuracion}/materiales/${payload.id}`)
    },

    getMaterialesConfiguracionSelect(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador/${payload.id_cotizador}/configuraciones/${payload.id_configuracion}/select-materiales`)
    },

    postAddMaterialesConfiguracion(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`cotizaciones/cotizador/${payload.id_cotizador}/configuraciones/${payload.id_configuracion}/agregar-materiales`, {materiales:payload.materiales})
    },

    putActualizarCotizacion(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`cotizaciones/cotizador/${payload.id_cotizador}`, payload.data)
    },

    postAgregarEtapaEquipo(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`cotizaciones/cotizador/${payload.id_cotizador}/equipo/${payload.id_cotizador_equipos_etapas}/agregar-etapas`, {id_etapa:payload.id_etapa})
    },

    deleteEtapaConfiguracion(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`cotizaciones/cotizador/${payload.id_cotizador}/configuraciones/${payload.id_configuracion}/eliminar-configuracion`)
    },

    getConfiguracion(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`cotizaciones/cotizador/${payload.id_cotizador}/configuraciones/${payload.id_etapa}/listar-configuraciones`)
    },

    postAddConfiguracion(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`cotizaciones/cotizador/${payload.id_cotizador}/configuraciones/${payload.id_etapa}/agregar-configuraciones`, {id_configuracion:payload.id_configuracion})
    },
    
    putActualizarPorcentajeConfiguracion(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`cotizaciones/cotizador/${payload.id_cotizador}/configuraciones/${payload.id_configuracion}/actualizar-utilidades-configuraciones` )
    },

    putActualizarPorcentajeUtilidad(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`cotizaciones/cotizador/${payload.id_cotizador}/actualizar-utilidades-configuraciones`, {porcentaje_utilidad:payload.porcentaje_utilidad} )
    },

    getCotizacionHistorial(payload){
        const ApiHandler = new Api({})
        return ApiHandler.get(`cotizaciones/cotizador/${payload}/historial-cotizador`)
    },

    getValorFinalEtapa(payload){
        const ApiHandler = new Api({})
        return ApiHandler.get(`cotizaciones/cotizador/${payload.id_cotizador}/valor-etapa/${payload.id_etapa}`)
    },

    deleteCotizacion(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`cotizaciones/cotizador/${payload}`)
    },

    deleteEtapa(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.delete(`cotizaciones/cotizador/${payload.id_cotizador}/etapas/${payload.id_etapa}`)
    },
}


