import { UseCase } from "~/domain/core/UseCase"
import Auth from "~/services/auth/auth"
import { $try } from '~/services/global'

class ValidateCodeUseCase extends UseCase {
    static async execute(params){
        return $try(async () => {
            const data = await Auth.ValidateCodeUseCase(params)
            return data
        }, true)
    }
}

export default ValidateCodeUseCase.execute