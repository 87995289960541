export default [
    {
        path: '/almacen',
        name: 'almacen.main',
        meta: { title: 'Almacen' },
        redirect: { name: 'almacen.materiales' },
        component: () => import('../../pages/almacen').then(m => m.default || m),
        children: [
            {
                path: 'categorias',
                name: 'almacen.categorias',
                meta: { title: 'Almacen - categorias' },
                component: () => import('../../pages/almacen/categorias/listado').then(m => m.default || m),
            },
            {
                path: 'categorias/:id_categoria/materiales/:name',
                name: 'almacen.categorias.materiales',
                meta: { title: 'Almacen - categorias - materiales' },
                component: () => import('../../pages/almacen/categorias/materiales').then(m => m.default || m),
            },
            {
                path: 'materiales',
                name: 'almacen.materiales',
                meta: { title: 'Almacen - materiales' },
                component: () => import('../../pages/almacen/materiales').then(m => m.default || m),
            },
            {
                path: 'stock',
                name: 'almacen.stock',
                meta: { title: 'Almacen - stock' },
                component: () => import('../../pages/almacen/stock').then(m => m.default || m),
            },
            {
                path: 'etapas',
                name: 'almacen.etapas',
                meta: { title: 'Almacen - etapas' },
                component: () => import('../../pages/almacen/etapas').then(m => m.default || m),
            },
            {
                path: 'equipos',
                name: 'almacen.equipos',
                meta: { title: 'Almacen - equipos' },
                component: () => import('../../pages/almacen/equiposModelos').then(m => m.default || m),
            },
            {
                path: 'mantenimiento',
                name: 'almacen.mantenimiento',
                meta: { title: 'Almacen - mantenimiento' },
                component: () => import('../../pages/almacen/mantenimiento').then(m => m.default || m)
            },
            {
                path: '/almacen/mantenimiento/:id_material/planes',
                name: 'almacen.mantenimiento.planes',
                meta: { title: 'Almacen - mantenimiento - planes' },
                component: () => import('../../pages/almacen/mantenimiento/listadoPlanes').then(m => m.default || m),
            },
            {
                path: '/almacen/mantenimiento/:id_material/planes/:id',
                name: 'almacen.mantenimiento.planes.ver',
                meta: { title: 'Almacen - mantenimiento - planes' },
                component: () => import('../../pages/almacen/mantenimiento/verPlan').then(m => m.default || m),
            },
            {
                path: 'pedidos',
                name: 'almacen.pedidos.internos',
                meta: { title: 'Almacen - pedidos' },
                redirect: { name: 'almacen.pedidos.internos.estado.pedido' },
                component: () => import('../../pages/almacen/pedidosInternos').then(m => m.default || m),
                children:[
                    {
                        path: 'estados' ,
                        name: 'almacen.pedidos.internos.estado.pedido',
                        meta: { title: 'Estado pedido' },
                        component: () => import('../../pages/almacen/pedidosInternos/estadoPedidoLista.vue')
                    },
                    {
                        path: 'ordenes' ,
                        name: 'almacen.pedidos.internos.ordenes.compra',
                        meta: { title: 'Estado pedido' },
                        component: () => import('../../pages/almacen/pedidosInternos/ordenesCompra.vue')
                    },
                ]
            },
        ]
    },
    {
        path: '/almacen/pedidos/ordenes/responsable/proveedor',
        name: 'almacen.pedidos.internos.oc.materiales.proveedor',
        component: () => import('../../pages/almacen/pedidosInternos/verAgregarMaterialProveedor').then(m => m.default || m),
    },
    {
        path: '/almacen/pedidos/ordenes/responsable/bodega',
        name: 'almacen.pedidos.internos.oc.materiales.bodega',
        component: () => import('../../pages/almacen/pedidosInternos/verAgregarMaterialBodega').then(m => m.default || m),
    },
    {
        path: '/almacen/pedidos/:id_pedido/ver',
        name: 'almacen.pedidos.ver',
        meta: { title: 'Almacen - pedidos - ver' },
        component: () => import('../../pages/almacen/pedidosInternos/verPedido').then(m => m.default || m),
    },
    {
        path: '/almacen/pedidos/:id/ver-orden',
        name: 'almacen.pedidos.ver.orden.compra',
        meta: { title: 'Almacen - pedidos - ver' },
        component: () => import('../../pages/almacen/pedidosInternos/verOc').then(m => m.default || m),
    },
    {
        path: '/almacen/materiales/crear',
        name: 'almacen.materiales.crear',
        meta: { title: 'Almacen - materiales - crear' },
        component: () => import('../../pages/almacen/materiales/crearMaterial').then(m => m.default || m),
    },
    {
        path: '/almacen/materiales/editar/:id',
        name: 'almacen.materiales.editar',
        meta: { title: 'Almacen - materiales - editar' },
        component: () => import('../../pages/almacen/materiales/crearMaterial').then(m => m.default || m),
    },
    {
        path: '/almacen/materiales/:id',
        name: 'almacen.materiales.ver',
        redirect: {name: 'almacen.materiales.ver.datos'},
        meta: { title: 'Almacen - materiales - ver' },
        component: () => import('../../pages/almacen/materiales/informacion').then(m => m.default || m),
        children: [
            {
                path: 'informacion',
                name: 'almacen.materiales.ver.datos',
                meta: {title: 'Almacen - materiales - información'},
                component: () => import('../../pages/almacen/materiales/informacion/datos').then(m => m.default || m),
            },
            {
                path: 'stock',
                name: 'almacen.materiales.ver.stock',
                meta: {title: 'Almacen - materiales - stock'},
                component: () => import('../../pages/almacen/materiales/informacion/stock').then(m => m.default || m),
            },
            {
                path: 'cotizaciones',
                name: 'almacen.materiales.ver.cotizaciones',
                meta: {title: 'Almacen - materiales - cotizaciones'},
                component: () => import('../../pages/almacen/materiales/informacion/cotizaciones').then(m => m.default || m),
            },
            {
                path: 'compras',
                name: 'almacen.materiales.ver.compras',
                meta: {title: 'Almacen - materiales - compras'},
                component: () => import('../../pages/almacen/materiales/informacion/compras').then(m => m.default || m),
            },
            {
                path: 'mantenimiento',
                name: 'almacen.materiales.ver.mantenimiento',
                meta: {title: 'Almacen - materiales - mantenimiento'},
                component: () => import('../../pages/almacen/mantenimiento/listadoPlanes').then(m => m.default || m),
            },
        ],
    },

    // etapas
    {
        path: '/almacen/etapas/:id',
        name: 'almacen.etapas.ver',
        meta: { title: 'Almacen - etapas' },
        component: () => import('../../pages/almacen/etapas/verEtapa').then(m => m.default || m),
    },
    {
        path: '/almacen/etapas/:id/configuracion/:id_configuracion',
        name: 'almacen.etapas.ver.configuracion',
        meta: { title: 'Almacen - etapas - config' },
        component: () => import('../../pages/almacen/etapas/verEtapaConfig').then(m => m.default || m),
    },

    // Equipos Modelos
    {
        path: '/almacen/equipos/:id',
        name: 'almacen.equipos.ver',
        meta: { title: 'Almacen - equipos - ver' },
        component: () => import('~/pages/almacen/equiposModelos/detalleEquipos').then(m => m.default || m),
    }

]
