import { StateVO } from "../StateVO";

export class StateVOFactory {
    static instantiate(obj){
        return new StateVO(obj)
    }

    static instantiateEmpty(){
        return new StateVO({
            id: '',
            nombre: '',
        })
    }
}