import { UseCase } from '~/domain/core/UseCase'
import Stages from "~/services/almacen/stages"
import { $try } from '~/services/global'

class ValidateExcelUseCase extends UseCase {
    static async execute(params){
        const { id_etapa, id_configuracion, ...payload } = params
        const data = await Stages.validateMaterialsExcel({id_etapa, id_configuracion, payload})
        return $try(() => {
            return data ?? []
        })
    }
}

export default ValidateExcelUseCase.execute