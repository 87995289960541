<template>
    <div class="col-12 col-lg-12 col-xl-10 my-1">
        <div class="row border-dato br-3 mx-0">
            <div class="col-lg-5 py-2">
                <p class="f-12 text-muted2 f-600"> {{ name }}: </p>
            </div> 
            <div class="col-lg-7 py-2">
                <p class="f-12 text-muted2"> {{ descripcion }} </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        descripcion: {
            type: String,
            required: true
        },
    },

}
</script>

<style>

</style>