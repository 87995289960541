import { Pagar } from "../Pagar";

export class PagarFactory {
    static instantiate(obj){
        return new Pagar(obj)
    }

    static instantiateEmpty(){
        return new Pagar({
            id: '',
			proveedor: '',
			tipo: '',
			cliente: '',
			fecha_orden: '',
			fecha_vencimiento: '',
			dias: '',
			valor: '',
			pago: ''
        })
    }
}