import { OpportunityConfiguration } from "../OpportunityConfiguration";

export class OpportunityConfigurationFactory {
    static instantiate(obj){
        return new OpportunityConfiguration(obj)
    }

    static instantiateEmpty(){
        return new OpportunityConfiguration({
            id_almacen_etapas: '',
            id: '',
            almacen_etapas: {
                id: '',
                nombre: ''
            },
            almacen_etapas_configuraciones: [],
        })
    }
}