import { ProjectVO } from "../ProjectVO";

export class ProjectVOFactory {
    static instantiate(obj){
        return new ProjectVO(obj)
    }

    static instantiateEmpty(){
        return new ProjectVO({
            id: '',
            nombre: '',
        })
    }
}