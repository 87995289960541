import * as HorasHombresDomain from '~/domain/use_cases/configurar/almacen/horas_hombre'

import { $try } from '~/services/global'
// state
export const state = {
    horas_hombre: null
}

// getters
export const getters = {
    horas_hombre: state => state.horas_hombre,
}

// mutations
export const mutations = {
    set_horas_hombre(state, horas_hombre) {
        state.horas_hombre = horas_hombre
    }
}

// actions
export const actions = {
    async Action_get_horas_hombre({ commit }) {
        const horas_hombre = await HorasHombresDomain.GetHoursManUseCase()
        $try(() => {
            if (horas_hombre) commit('set_horas_hombre', horas_hombre)
        })
    },

    async Action_edit_horas_hombre({ commit }, payload) {
        const data = await HorasHombresDomain.EditHoursManUseCase(payload)
        $try(() => { if (data) commit('set_horas_hombre', data) })
    },

    async Action_create_horas_hombre({ commit }, payload) {
        const data = await HorasHombresDomain.CreateHoursManUseCase(payload)
        $try(() => { if (data) commit('set_horas_hombre', data) })
    }
}