import * as ObrasDomain from '~/domain/use_cases/obras';

export const state = {
    query: '',
    obras: []
}

export const mutations = {
    set_obras_decorator(state, payload){
        state.obras = payload
    },
    set_query(state, payload){
        state.query = payload
    }
}

export const getters = {
    obras: state => state.obras.data,
    pagination: state => state.obras.pagination,
    query: state => state.query
}

export const actions = {
    async Action_get_active_obras({ state, commit }){
        const filtro = state.query || null
        if(state.obras?.pagination?.current_page > state.obras?.pagination?.last_page) state.obras.pagination.current_page = 1
        const page = state.obras?.pagination?.current_page ?? 1
        const decorator = await ObrasDomain.GetActiveObrasUseCase({filtro: filtro, page});
        if(decorator) {
            commit('set_obras_decorator', decorator);
        }
    }
}