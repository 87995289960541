import * as PurchaseOrderDomain from '~/domain/use_cases/obras/servicios/forma_pago';
import store from '~/store'

export const state = {
    payment_methods_resume: {
        total_pagado: 0,
        total_valor_pagar: 0
    }
}

export const getters = {
    payment_methods_resume: state => state.payment_methods_resume
}

export const mutations = {
    set_payment_methods_resume(state, payload){
        state.payment_methods_resume = payload
    }
}

export const actions = {
    async Action_get_payment_methods_resume({ commit }, payload){
        const data = await PurchaseOrderDomain.GetPaymentMethodResumeUseCase(payload);
        commit('set_payment_methods_resume', data)
    },
    async Action_get_payment_methods({ commit }, payload) {
        const data = await PurchaseOrderDomain.GetPaymentMethodFeesUseCase(payload);
        store.commit('obras/servicios/forma_pago/set_payment_methods', data);
        store.commit('obras/servicios/forma_pago/set_total_percentage', data.reduce((acc, curr) => (acc + curr.porcentaje), 0))
        store.commit('obras/servicios/forma_pago/set_total_value', data.reduce((acc, curr) => (acc + curr.valor), 0))
    },
    async Action_save_payment_methods({ commit }, payload){
        const data = await PurchaseOrderDomain.SavePaymentMethodFeesUseCase(payload)
        if(data) store.commit('obras/servicios/forma_pago/set_payment_methods', data);
    },
    async Action_report_payment({ commit }, payload){
        const data = await PurchaseOrderDomain.ReportPaymentUseCase(payload)
        if(data) commit('set_payment_methods_resume', data)
    },
    async Action_finish_payment({ commit }, payload){
        const data = await PurchaseOrderDomain.FinishPaymentUseCase(payload)
        if(data) commit('set_payment_methods_resume', data)
    }
}