import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { CategoriaVOFactory } from '~/domain/value_objects/almacen/pedidos/factories/CategoriaVOFactory'
import { $try } from '~/services/global'

class GetSelectCategoriaUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectCategorias()
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => CategoriaVOFactory.instantiate(e))
        })
    }
}

export default GetSelectCategoriaUseCase.execute