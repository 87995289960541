export default [
    {
        path: '/cotizaciones',
        name: 'cotizaciones.main',
        redirect: {name: 'cotizaciones.vigentes'},
        component: () => import('../../pages/cotizaciones/index').then(m => m.default || m),
        children: [
            {
                path: 'vigentes',
                name: 'cotizaciones.vigentes',
                component: () => import('../../pages/cotizaciones/vigentes').then(m => m.default || m),
            },
            {
                path: 'enviadas',
                name: 'cotizaciones.enviadas',
                component: () => import('../../pages/cotizaciones/enviadas').then(m => m.default || m),
            },
            {
                path: 'clientes',
                name: 'cotizaciones.clientes',
                component: () => import('../../pages/cotizaciones/clientes').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/cotizaciones/vigentes/:id_cotizacion/cotizacion',
        name: 'cotizaciones.ver',
        component: () => import('../../pages/cotizaciones/verCotizacion').then(m => m.default || m),
    },
    {
        path: '/cotizaciones/enviadas/:id_cotizacion/cotizacion',
        name: 'cotizaciones.ver.enviadas',
        component: () => import('../../pages/cotizaciones/verCotizacionEnviadas').then(m => m.default || m),
    },
    {
        path: '/cotizaciones/clientes/:id_cotizacion/cotizacion',
        name: 'cotizaciones.ver.detalle',
        component: () => import('../../pages/cotizaciones/verCotizacionClienteDetalle').then(m => m.default || m),
    },
    {
        path: '/cotizaciones/crear',
        name: 'cotizaciones.crear',
        component: () => import('../../pages/cotizaciones/crearCotizacion').then(m => m.default || m),
    },
    {
        path: '/cotizaciones/cliente/id_cotizacion/ver',
        name: 'cotizaciones.ver.cliente',
        component: () => import('../../pages/cotizaciones/verCotizacionCliente').then(m => m.default || m),
    },
    {
        path: '/cotizaciones/cliente/:id_cotizacion/ver/:id_configuracion',
        name: 'cotizaciones.ver.cliente.configuracion.materiales',
        component: () => import('../../pages/cotizaciones/verConfiguracionCotizacion').then(m => m.default || m),
    },
]