import { FiltroCompra } from "../FiltroCompra";

export class FiltroCompraFactory {
    static instantiate(obj){
        return new FiltroCompra(obj)
    }

    static instantiateEmpty(){
        return new FiltroCompra({
            proyectos: '',
            proveedores: '',
            clientes: '',
            unidades_min: '',
            unidades_max: '',
            valor_min: '',
            valor_max: '',
            estados: ''
        })
    }
}