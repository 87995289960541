import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { $try } from '~/services/global'

class GetSelectResponsableProveedoresUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetSelectResponsableProveedor()
        return $try(() => {
            if(data.data && Array.isArray(data.data))
                return data.data
        })
    }
}

export default GetSelectResponsableProveedoresUseCase.execute