import { Entity } from '~/domain/entities'

export class Valor extends Entity{
    constructor(obj){
        super({
            params:obj,
            keys: [
                'id',
                'bodega',
                'valor'
            ]
        })
    }
}