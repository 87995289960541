import * as ProviderDomain from '~/domain/use_cases/proveedores/funcionarios'
// import { Pagination } from '../../../domain/entities/decorators/pagination/Pagination'

import { $try } from '~/services/global'

// state
export const state = {
    funcionarios: [],

}

// getters
export const getters = {
    funcionarios: state => state.funcionarios,
}

// mutations
export const mutations = {
    set_funcionarios(state, data){
        state.funcionarios = data
    },
}

// actions
export const actions = {
    async Action_lista_funcionarios({ commit }, {idProveedor, page}){
        const data = await ProviderDomain.GetFuncionariosProveedoresUseCase({idProveedor, page})
        $try ( () => { commit('set_funcionarios', data) } )
    },
    async Action_crear_funcionarios({ commit }, {payload, idProveedor}){
        await ProviderDomain.PostFuncionariosProveedoresUseCase({payload, idProveedor})
    },
    async Action_eliminar_funcionario({ commit }, {idProveedor, idFuncionario}){
        await ProviderDomain.DeleteFuncionariosProveedoresUseCase({idProveedor, idFuncionario})
    },
    async Action_editar_funcionario({ commit }, {payload, idProveedor, idFuncionario}){
        await ProviderDomain.PutFuncionariosProveedoresUseCase({payload, idProveedor, idFuncionario})
    },


}