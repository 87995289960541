import Api from '~/services/api'

export const PurchaseOrder = {
    getPurchaseOrderList({ id, pagination, payload }) {
        const PurchaseOrder = new Api();
        return PurchaseOrder.get(`compras/${id}/ordenes-de-compra/listado`, { ...pagination, ...payload })
    },
    getPurchaseOrderListValues({ id }) {
        const PurchaseOrder = new Api();
        return PurchaseOrder.get(`compras/${id}/ordenes-de-compra/listado/valores-filtro`)
    },
    getPurchaseOrderInfo({ id }) {
        const PurchaseOrder = new Api();
        return PurchaseOrder.get(`compras/${id}/orden-compra`)
    },
    sendPurchaseOrder({ id }) {
        const PurchaseOrder = new Api({ notification: true });
        return PurchaseOrder.post(`compras/${id}/orden-compra/enviar`)
    },
    getPurchaseOrderMaterials({ id }) {
        const PurchaseOrder = new Api();
        return PurchaseOrder.get(`compras/${id}/orden-compra/materiales`)
    },
    getPurchaseOrderDays({ id, payload }) {
        const PurchaseOrderList = new Api();
        return PurchaseOrderList.get(`compras/${id}/ordenes-de-compra/dias`, { ...payload })
    },
    getWeeks({ id, anio }) {
        const Week = new Api();
        return Week.get(`/compras/${id}/ordenes-de-compra/semanas`, { anio })
    },
    getFiltrosGruposOcs() {
        const Week = new Api();
        return Week.get(`/ordenes-compras/filtro-grupo-materiales`)
    }
}