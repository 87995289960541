import { HelperFecha, HelperStorage, HelperStorageSandbox, HelperStorageManager, HelperUcfirst, HelperWeek } from './global'

const fecha = {};
fecha.install = Vue => {
    Vue.filter('helper-fecha', (valor, formato = false) => HelperFecha(valor, formato))
}

const file = {}
file.install = Vue => {
    Vue.filter('helper-storage', (url, option) => HelperStorage(url, option))
}

const managerFile = {}
managerFile.install = Vue => {
    Vue.filter('helper-storage-manager', (url) => HelperStorageManager(url))
}

const sandboxFile = {}
sandboxFile.install = Vue => {
    Vue.filter('helper-storage-sandbox', (url) => HelperStorageSandbox(url))
}

const ucfirst = {}
ucfirst.install = Vue => {
	Vue.filter('ucfirst', val => HelperUcfirst(val))
}

const week = {}
week.install = Vue => {
	Vue.filter('week', val => HelperWeek(val))
}

export default [
    fecha, file, managerFile, ucfirst, week, sandboxFile
];