import Api from '~/services/api'

export const Bodegas = {
    getBodegas(payload){
        const ApiHandler = new Api()
        return ApiHandler.get(`bodegas`, payload)
    },
    getBodegaDetail(id){
        const ApiHandler = new Api()
        return ApiHandler.get(`bodegas/${id}`)
    },
    warehouseDetailPurchaseOrders(id, buscar){
        const ApiHandler = new Api()
        return ApiHandler.get(`bodegas/${id}/orden-compra`, {buscar})
    },
    addBodega(payload){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.post(`bodegas`, payload)
    },
    editBodega(params){
        const ApiHandler = new Api({notification: true})
        return ApiHandler.put(`bodegas/${params.id}`, params.payload)
    },
}