// import * as pedidosInternosOcs from '~/domain/use_cases/configurar/obras/calificacion_servicio'
import * as pedidosInternosOcs from '~/domain/use_cases/almacen/pedidos_internos_ocs'
import { OrdenCompraFilterFactory } from '~/domain/filters/almacen/pedidos/factories/OrdenCompraFilterFactory'
import { DetalleOrdenCompraFilterFactory } from '~/domain/filters/almacen/pedidos/factories/DetalleOrdenCompraFilterFactory'
import { $try } from '~/services/global'


// state
export const state = {
    ordenesCompra: [],
    pedidos:[],
    pedidos_internos_materiales:[],
    informacion_ocs:[],
    materiales:{},
    filtroOrdenesCompra:[],
    filtroOcs: OrdenCompraFilterFactory.instantiateEmpty(),
    filtroDetalleOrdenCompra: {},
    filtroDetalleOcs: DetalleOrdenCompraFilterFactory.instantiateEmpty(),
    informacionDetalleOcs:{},
    nuevaOcs:{}

}

// getters
export const getters = {
    ordenesCompra: state => state.ordenesCompra,
    pedidos: state => state.pedidos,
    pedidos_internos_materiales: state => state.pedidos_internos_materiales,
    informacion_ocs: state => state.informacion_ocs,
    materiales: state => state.materiales,
    filtroOrdenesCompra: state => state.filtroOrdenesCompra,
    filtroOcs: state => state.filtroOcs,
    filtroDetalleOrdenCompra: state => state.filtroDetalleOrdenCompra,
    filtroDetalleOcs: state => state.filtroDetalleOcs,
    informacionDetalleOcs: state => state.informacionDetalleOcs,
    nuevaOcs: state => state.nuevaOcs,
}

// mutations
export const mutations = {
    set_ordenes_compra(state, payload) {
        state.ordenesCompra = payload
    },
    set_pedidos(state, payload) {
        state.pedidos = payload
    },
    set_pedidos_internos_materiales(state, payload) {
        state.pedidos_internos_materiales = payload
    },
    set_informacion_ocs(state, payload) {
        state.informacion_ocs = payload
    },
    set_materiales(state, payload) {
        state.materiales = payload
    },
    set_filtro_ordenes_compra(state, payload) {
        state.filtroOrdenesCompra = payload
    },
    set_filtro_ocs(state, payload) {
        state.filtroOcs = payload
    },
    set_filtro_detalle_orden_compra(state, payload) {
        state.filtroDetalleOrdenCompra = payload
    },
    set_filtro_detalle_ocs(state, payload) {
        state.filtroDetalleOcs = payload
    },
    set_informacion_detalle_ocs(state, payload) {
        state.informacionDetalleOcs = payload
    },
    set_nueva_ocs(state, payload) {
        state.nuevaOcs = payload
    },
    set_reportar_pago(state, payload) {
        state.informacion_ocs.valor_pagado = payload.valor_pagado
        state.informacion_ocs.valor_pagado_fecha = payload.valor_pagado_fecha
    },
    set_finalizar_pago(state, payload) {
        state.informacion_ocs.valor_pagado += payload.valor_pagado
        state.informacion_ocs.valor_pagado_fecha = payload.valor_pagado_fecha
    },
    set_entrega_completa(state, payload) {
        state.informacion_ocs = payload
    },
    set_asociar_factura(state, payload) {
        state.informacion_ocs.facturas.push(payload)
    }
}

// actions
export const actions = {
   
    async Action_get_ocs_pedidos_internos({ commit }, payload) {
        const data = await pedidosInternosOcs.GetListaOcsPedidoInternoUseCase({...state.filtroOcs.clean(), ...payload})
        if (data) commit('set_ordenes_compra', data)
    },
    async Action_get_ocs_pedidos({ commit }, payload) {
        const data = await pedidosInternosOcs.GetSelectPedidosUseCase(payload)
        if (data) commit('set_pedidos', data)
    },
    async Action_get_materiales_pedidos_internos({ commit }, payload) {
        const data = await pedidosInternosOcs.GetSelectPedidosInternosMateriales(payload)
        if (data) commit('set_pedidos_internos_materiales', data)
    },
    async Action_get_informacion_ocs({ commit }, payload) {
        const data = await pedidosInternosOcs.GetInformacionOcsUseCase(payload)
        if (data) commit('set_informacion_ocs', data)
    },
    async Action_get_materiales({ commit }, { id, payload}) {
        const data = await pedidosInternosOcs.GetMaterialesUseCase({id, payload:{...payload, ...state.filtroDetalleOcs.clean()}})
        if (data) commit('set_materiales', data)
    },
    async Action_get_filtros_ordenes_compras({ commit }, payload) {
        const data = await pedidosInternosOcs.GetFiltroOrdenesComprasUseCase(payload)
        if (data) commit('set_filtro_ordenes_compra', data)
    },
    Action_clear_filter_ocs({ commit }) {
        const rangosValores = {
            min:state.filtroOrdenesCompra.valor_min,
            max:state.filtroOrdenesCompra.valor_max
        }
        commit('set_filtro_ocs', OrdenCompraFilterFactory.instantiateEmptyWithRange(rangosValores))
    },
    async Action_get_filtros_detalle_ordenes_compras({ commit }, payload) {
        const data = await pedidosInternosOcs.GetFiltroOrdenesComprasDetalleUseCase(payload)
        if (data) commit('set_filtro_detalle_orden_compra', data)
    },
    Action_clear_filter_detalle_ocs({ commit }) {
        const rangeUnidades = {
            min:state.filtroDetalleOrdenCompra.unidades_min,
            max:state.filtroDetalleOrdenCompra.unidades_max
        }
        commit('set_filtro_detalle_ocs', DetalleOrdenCompraFilterFactory.instantiateEmptyWithRange(rangeUnidades))
    },
    async Action_post_materiales_pedidos_internos({ commit }, payload) {
        const data = await pedidosInternosOcs.PostMaterialesPedidosInternosUseCase(payload)
        if (data) commit('set_nueva_ocs', data)
    },
    async Action_informacion_detalle_ocs({ commit }, payload) {
        let data = await pedidosInternosOcs.GetInformacionDetalleOcsUseCase(payload)
        const detalleOcs = JSON.parse(localStorage.getItem('detalleOcs'))
        console.log("detalleOcs", detalleOcs);
        if (detalleOcs) {
            data.materiales.map(e => {
                detalleOcs.materiales.map(o => {
                    if (e.id == o.id) {
                        e.valor_unitario = o.valor_unitario
                    }
                    return o
                })
                return e
            })
        }
        localStorage.setItem('detalleOcs', JSON.stringify(data));
        if (data) commit('set_informacion_detalle_ocs', data)
    },
    async Action_delete_factura_orden_compra({ commit }, payload) {
        const {id, ...rest} = payload
        await pedidosInternosOcs.DeleteFacturaOrdenCompraUseCase({ id }, rest)
    },
    async Action_reportar_pago({ commit }, payload) {
        const {id, ...rest} = payload
        const data = await pedidosInternosOcs.ReportarPagoUseCase({ id }, rest)
        if (data) commit('set_reportar_pago', data)
    },
    async Action_finalizar_pago({ commit }, payload) {
        const data = await pedidosInternosOcs.FinalizarPagoUseCase(payload)
        if (data) commit('set_finalizar_pago', data)
    },
    async Action_entrega_completa({ commit }, payload) {
        const data = await pedidosInternosOcs.EntregaCompletaUseCase(payload)
        if (data) commit('set_entrega_completa', data)
    },
    async Action_asociar_factura({ commit }, payload) {
        const data = await pedidosInternosOcs.AsociarFacturaUseCase(payload)
        if (data) commit('set_asociar_factura', data)
    }
}