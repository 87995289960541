import { UseCase } from "~/domain/core/UseCase"
import Auth from "~/services/auth/auth"
import { $try } from '~/services/global'

class RecoverUsernameUseCase extends UseCase {
    static async execute(params){
        const data = await Auth.recoverUsername(params)
        return $try(() => {
            return data
        })
    }
}

export default RecoverUsernameUseCase.execute
