export default [
    {
        path: '/cobro_cartera',
        name: 'cobro.cartera',
        redirect: {name: 'cobro.cartera.pagar'},
        component: () => import('../../pages/cobroCartera/index').then(m => m.default || m),
        children: [
            {
                path: 'cuentas_por_pagar',
                name: 'cobro.cartera.pagar',
                component: () => import('../../pages/cobroCartera/porPagar').then(m => m.default || m),
            },
            // {
            //     path: 'cuentas_por_cobrar',
            //     name: 'cobro.cartera.cobrar',
            //     component: () => import('../../pages/cobroCartera/porCobrar').then(m => m.default || m),
            // }
        ]
    },
]