import { UseCase } from "~/domain/core/UseCase";
import { Opportunities } from '~/services/oportunidades/oportunidades'
import { $try } from '~/services/global'

class DeleteOpportunityStageUseCase extends UseCase {
    static async execute(params){
        const { id_configuracion, ...rest } = params
        await Opportunities.deleteOpportunityStageConfigurations({payload: {id_configuracion}, ...rest})
    }
}

export default DeleteOpportunityStageUseCase.execute