import { ServicioFilter } from "../servicioFilter";

export class ServicioFilterFactory {
    static instantiate(obj){
        return new ServicioFilter(obj)
    }

    static instantiateEmpty(){
        return new ServicioFilter({
            id_taller: '',
            id_estado: '',
        })
    }
}