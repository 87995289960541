import { UseCase } from "~/domain/core/UseCase"
import Materials from "~/services/almacen/materiales"
import { $try } from '~/services/global'

class GetAdditionalDataUseCase extends UseCase {
    static async execute(params){
        const data = await Materials.getAdditionalData()
        return $try(() => {
            return data?.data ?? []
        })
    }
}

export default GetAdditionalDataUseCase.execute
