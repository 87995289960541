import { UseCase } from "~/domain/core/UseCase";
import { Bills } from '~/services/compras/facturas'
import { BillFactory } from '~/domain/entities/compras/facturas/factories/BillFactory'
import { $try } from '~/services/global'

class GetBillsUseCase extends UseCase {
    static async execute(id, billFilter){
        let customData = []
        const { data } =  await Bills.getBills(id, billFilter)

        $try(() => {

            if(data.data.length){
                data.data.forEach((item) =>{
                    customData.push(BillFactory.instantiate(item))
                })
            }

        })

        return {data: customData, server: data}
    }
}

export default GetBillsUseCase.execute