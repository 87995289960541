import { Filter } from '~/domain/core/Filter';

export class WorkshopFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_cliente',
                'fecha_inicio',
                'fecha_fin',
                'valor_minimo',
                'valor_maximo',
            ]
        });
    }
}