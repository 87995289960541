import { UseCase } from "~/domain/core/UseCase";
import { incrementos } from '~/services/configurar/almacen/incrementos_porcentuales'
import { incrementosFactory } from '~/domain/entities/configurar/almacen/incrementos_porcentuales/factories/incrementosFactory.js'
import { $try } from '~/services/global'

class GetIncrementosDataTypeUseCase extends UseCase {
    static async execute(){
        const { data } =  await incrementos.getIncrementos()
        
        return $try( () => 
            data?.map(e => incrementosFactory.instantiate(e) ) ?? []
        )
    }
}

export default GetIncrementosDataTypeUseCase.execute