import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivitiesSteps } from "~/services/almacen/mantenimiento/steps"

class DeleteFileMaintenanceActivityStepUseCase extends UseCase {
    static async execute(params){
        const data = await MaintenanceActivitiesSteps.deleteFile(params)
        return data?.message
    }
}

export default DeleteFileMaintenanceActivityStepUseCase.execute