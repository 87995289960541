import { Filter } from '~/domain/core/Filter';

export class OrdenCompraFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                'id_oportunidad',
                'id_proveedor',
                'id_cliente',
                'fecha_inicio',
                'fecha_fin',
                'valor_inicial',
                'valor_final',
                'id_estado',
            ]
        });
    }
}