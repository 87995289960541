import { Filter } from '~/domain/core/Filter'

export class CategoryFilter extends Filter {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'nombre',
                'materiales_min',
                'materiales_max',
                'etapas_min',
                'etapas_max',
                'planes_mantenimiento_min',
                'planes_mantenimiento_max',
                'fecha_min',
                'fecha_fin',
                'id_proveedores',
            ]
        })
    }
}