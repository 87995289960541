import { PaymentStatusVO } from "../PaymentStatusVO";

export class PaymentStatusVOFactory {
    static instantiate(obj){
        return new PaymentStatusVO(obj)
    }

    static instantiateEmpty(){
        return new PaymentStatusVO({
            id: '',
            value: '',
            nombre: '',
        })
    }
}