import { UseCase } from "~/domain/core/UseCase"
import { MaintenanceActivities } from "~/services/almacen/mantenimiento/actividades"
import { ActivityFactory } from '~/domain/entities/almacen/mantenimiento/actividades/factories/ActivityFactory'
import { $try } from '~/services/global'

class GetMaintenanceActivitiesUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const data = await MaintenanceActivities.getMaintenanceActivities(params)   

        return $try(() => {
            if(data?.data?.data) customData = data.data.data.map(item => ActivityFactory.instantiate(item))
            return { server: data?.data, data: customData }
        })
    }
}

export default GetMaintenanceActivitiesUseCase.execute
