import { Entity } from '~/domain/entities'

export class UtilidadPais extends Entity {
    constructor(obj){
        super({
            params: obj,
            keys: [
                'id', 
                'pais', 
                'bandera', 
                'utilidad', 
            ]
        })
    }
}