import { PedidosInternos } from "../estadoPedidosInternos";

export class EstadoPedidosInternosFactory {
    static instantiate(obj){
        return new PedidosInternos(obj)
    }

    static instantiateEmpty(){
        return new PedidosInternos({
            id: undefined,
            estado: '',
            color: '',
        })
    }
}