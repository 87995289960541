<template>
    <slim-cropper ref="image" v-model="base64" :options="options" class="bg-slim border cr-pointer mx-auto position-relative overflow-hidden br-5">
        <input type="file" accept="image/*" @change="showImage = false">
        <div v-if="showImage">
            <img :src="image | helper-storage" class="position-absolute img-position w-100 h-100 z-1 obj-cover">
        </div>
    </slim-cropper>
</template>

<script>
export default {
    props: {
        options: { 
            type: Object, 
            default: () => ({
                ratio: '1:1',
                label:'Añadir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            })
        },
        filter: {
            type: Function,
            default: null,
        },
        image: String
    },
    data(){
        return {
            showImage: false,
            base64: null,
            index: 0,
            transform: null
        }
    },
    watch:{
        base64:{
            handler(val){
                this.$emit('input', val);
            },
            deep:true
        },
        image(val){
            this.loadImage(val)
        }
    },
    mounted(){
        const elements = document.querySelectorAll('.slim-btn.slim-btn-remove')
        this.index = elements.length == 0 ? 1 : elements.length - 1;
        this.transform = this.filter ?? this.$options.filters['helper-storage']
    },
    methods:{
        loadImage(image){
            this.showImage = !!image;
            
            if(!image) return this.reset()

            const img = this.transform(image)
            
            this.$refs.image.instanciaCrop.load(img);

            document.querySelector('.slim-btn.slim-btn-remove')?.addEventListener('click',() => setTimeout(() => this.showImage = false, 1000))
        },
        reset(){
            if(!!this.base64){
                document.querySelectorAll('.slim-btn.slim-btn-remove')?.[this.index]?.click()
            } 
        }
    }
}
</script>

<style scoped>
.cropper-width{
    width: 223px;
}

.img-position{
    bottom: 0; 
    left: 0;
}
.img-scale{
    transform: scale(1.3);
}

.bg-slim{
    background-color:#F5F5F5;
}
</style>