import { Stage } from "../Stage";

export class StageFactory {
    static instantiate(obj){
        return new Stage(obj)
    }

    static instantiateEmpty(){
        return new Stage({
            nombre: '',
            id: '',
            info: ''
        })
    }
}