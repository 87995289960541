import { UseCase } from '~/domain/core/UseCase'
import { Gallery } from '~/services/obras/gallery/gallery'
import { GalleryFactory } from '~/domain/entities/obras/galeria/factories/GalleryFactory'
import { $try } from '~/services/global' 

class EditeFolderUseCase extends UseCase {
    static async execute(params){
        const data = await Gallery.editeFolder(params)

        return $try(() => {
            if(data?.data) return GalleryFactory.instantiate(data.data)
        })
    }
}

export default EditeFolderUseCase.execute