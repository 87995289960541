<template>
    <modal
    ref="modalConfirmar"
    tamano="modal-lg"
    :titulo="titulo"
    :no-guardar="noGuardar || eliminar"
    :eliminar="eliminar"
    :adicional="adicional"
    @guardar="guardar"
    @eliminar="accionEliminar"
    @adicional="onAdicional"
    >
        <div class="container">
            <div class="row my-3 text-muted f-14 justify-center text-center px-3" style="max-height: 450px;">
                <div class="col-12" style="max-height: 450px;">
                    <video v-if="isVideo" height="100%" width="100%" controls>
                        <source :src="media | helper-storage" type="video/mp4" />
                    </video>
                    <img v-else :src="media | helper-storage" class="w-100 h-100" style="object-fit: initial" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        media: {
            type: String,
            default: ''
        },
        extension: {
            type: String,
            default: ''
        },
        eliminar: {
            type: Boolean,
            default: false
        },
        adicional: {
            type: String,
            default: ''
        },
        noGuardar: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
        }
    },
    computed: {
        isVideo(){
            return this.extension.includes('mp4')
        }
    },
    methods: {
        toggle(){
            this.$refs.modalConfirmar.toggle()
        },
        guardar(){
            this.$emit('guardar')
            this.toggle()
        },
        accionEliminar(){
            this.$emit('eliminar')
            this.toggle()
        },
        onAdicional(){
            this.$emit('adicional')
            this.toggle()
        }
    }
}
</script>
