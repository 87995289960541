import { UseCase } from "~/domain/core/UseCase";
import { Matriz } from '~/services/compras/matriz'
import { OportunityFactory } from '~/domain/entities/compras/matriz/factories/OportunityFactory'
import { $try } from '~/services/global'

class GetOpportunitiesUseCase extends UseCase {
    static async execute(params){
        let customData = []
        const { data } =  await Matriz.getOpportunities(params)


        $try(() => {

            if(data.length){
                data.forEach((item) =>{
                    customData.push(OportunityFactory.instantiate({id: item.id, oportunidad: item.nombre}))
                })
            }

        })

        return customData
    }
}

export default GetOpportunitiesUseCase.execute