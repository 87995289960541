import { UseCase } from "~/domain/core/UseCase";
import { Bodegas } from '~/services/bodegas/bodegas'
import { $try } from '~/services/global'

class CreateBodegaUseCase extends UseCase {
    static async execute(payload){
        await Bodegas.addBodega(payload)
    }
}

export default CreateBodegaUseCase.execute