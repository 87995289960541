import { UseCase } from "~/domain/core/UseCase"
import  { PedidosInternosOcs }  from '~/services/almacen/pedidos_internos_ocs'
import { PedidoInternoOcsFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoOcsFactory'
import { $try } from '~/services/global'

class GetListaOcsPedidoInternoUseCase extends UseCase {
    static async execute(payload){
        const data = await PedidosInternosOcs.getPedidosInternosOcs(payload)
        return $try(() => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(e => PedidoInternoOcsFactory.instantiate(e))
        })
    }
}

export default GetListaOcsPedidoInternoUseCase.execute