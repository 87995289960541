<template>
    <router-link :to="{ name: route }" class="my-auto">
        <el-tooltip :content="tooltip" effect="light" placement="left">
            <i :class="`${icon} f-35 br-2 bg-general text-white mr-2`" />
        </el-tooltip>
    </router-link>
</template>

<script>
export default {
    name:'RedirectElement',
    props: {
        tooltip: { type: String, default: 'Inicio'},
        route: { type: String, default: 'home'},
        icon: { type: String, default: 'icon-view-grid'}
    }
}
</script>