import { Material } from "../Material";

export class MaterialFactory {
    static instantiate(obj){
        return new Material(obj)
    }

    static instantiateEmpty(){
        return new Material({
            id: '',
            bodega: '',
            cantidad_material: ''
        })
    }
}