import { Bill } from '../Bill'

export class BillFactory {
    static instantiate(obj) {
        return new Bill(obj);
    }

    static instantiateEmpty() {
        return new Bill({
            id: null,
            id_orden_compra: null,
            id_factura: null,
            referencia: '',
        });
    }
}