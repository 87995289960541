import Api from '~/services/api'

const ApiHandler = new Api()

const Stages = {
    getStages(){
        return ApiHandler.get('almacen/etapas')
    },
    createStages(payload){
        return ApiHandler.post('almacen/etapas', payload)
    },
    getStageDetail({id}){
        return ApiHandler.get(`almacen/etapas/${id}`)
    },
    updateStage({id, payload}){
        return ApiHandler.put(`almacen/etapas/${id}`, {nombre: payload.nombre})
    },
    deleteStage({id}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`almacen/etapas/${id}`)
    },

    //Configurations
    getStageConfiguration({id}){
        return ApiHandler.get(`almacen/etapas/${id}/configuracion`)
    },
    createStageConfiguration({id, payload}){
        return ApiHandler.post(`almacen/etapas/${id}/configuracion`, payload)
    },
    getConfigurationDetail({id, id_etapa}){
        return ApiHandler.get(`almacen/etapas/${id_etapa}/configuracion/${id}`)
    },
    getContarMateriales({id_etapa, id_configuration}){
        return ApiHandler.get(`almacen/etapas/${id_etapa}/configuracion/${id_configuration}/contar-materiales`)
    },
    updateConfiguration({id_etapa, id, payload}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.put(`almacen/etapas/${id_etapa}/configuracion/${id}`, {nombre: payload.nombre, horas: payload.horas,porcentaje: payload.porcentaje})
    },
    deleteConfiguration({id, id_etapa}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`almacen/etapas/${id_etapa}/configuracion/${id}`)
    },

    //Configuration materials
    getConfigurationMaterials({id_etapa, id_configuracion}){
        return ApiHandler.get(`almacen/etapas/${id_etapa}/configuracion/${id_configuracion}/material-select`)
    },
    createConfigurationMaterial({id_etapa, id_configuration, payload}){
        return ApiHandler.post(`almacen/etapas/${id_etapa}/configuracion/${id_configuration}/material`, payload);
    },
    createMaterialConfigurationClone({id_etapa, id_configuracion_etapa, payload}){
        return ApiHandler.post(`almacen/etapas/${id_etapa}/configuracion/${id_configuracion_etapa}/clonar-materiales-configuracion`, payload)
    },
    
    //Select
    getStageSelect({id_etapa, id_configuration}){
        return ApiHandler.get(`almacen/etapas/${id_etapa}/configuracion/${id_configuration}/etapa-select`);
    },
    getConfigurationsSelect({id_etapa, id_configuration}){
        return ApiHandler.get(`almacen/etapas/${id_etapa}/configuracion/${id_configuration}/configuracion-select`)
    },
    getRangeValueConfig(payload){
        return ApiHandler.get(`rangos-valor-materiales`, payload)
    },

    //Table info
    getTableInfo(id_etapa, id_configuration, payload){
        return ApiHandler.get(`almacen/etapas/${id_etapa}/configuracion/${id_configuration}/material`, payload);
    },
    updateMaterialConfig({id, id_etapa, id_configuracion, payload}){
        return ApiHandler.put(`almacen/etapas/${id_etapa}/configuracion/${id_configuracion}/material/${id}`, {id_material: payload.id_material, unidades: payload.unidades,id_mantenimiento: payload.id_mantenimiento});
    },
    deleteMaterialConfig({id, id_etapa, id_configuration}){
        const ApiHandler = new Api({notification:true})
        return ApiHandler.delete(`almacen/etapas/${id_etapa}/configuracion/${id_configuration}/material/${id}`);
    },
    //
    createStageConfiguration({id, payload}){
        return ApiHandler.post(`almacen/etapas/${id}/configuracion`, payload)
    },
    validateMaterialsExcel({payload}){
        // const ApiHandler = new Api({notification:true})
        return ApiHandler.post(`almacen/importar-materiales`, payload);
    }

}

export default Stages