import { UseCase } from '~/domain/core/UseCase'
import { Workshops } from '~/services/talleres/talleres'
import { $try } from '~/services/global'

class GetServiceRangeUseCase extends UseCase {
    static async execute(){
        const data = await Workshops.getServiceRange()
        return $try(() => {
            if(data?.data) return data.data
        })
    }
}

export default GetServiceRangeUseCase.execute