import { UseCase } from '~/domain/core/UseCase'
import StockMaterials from '~/services/almacen/stock'
import { CabeceraStockFactory } from '~/domain/entities/almacen/stock/factories/CabeceraStockFactory'
import { $try } from '~/services/global'

class GetCabeceraStockUseCase extends UseCase {
    static async execute(payload){
        const data = await StockMaterials.getCabeceraStockUseCase(payload)
        return $try(() => {
            return data.data ? CabeceraStockFactory.instantiate(data.data): null
        })
    }
}

export default GetCabeceraStockUseCase.execute
