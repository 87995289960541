import { CalendarActivity } from "../CalendarActivity";

export class CalendarActivityFactory {
    static instantiate(obj){
        return new CalendarActivity(obj)
    }

    static instantiateEmpty(){
        return new CalendarActivity({
            id: '',
            nombre: '',
            id_configuracion_calendarios: '',
            actividades: [],
            posicion: '',
        })
    }
}