import { UseCase } from '~/domain/core/UseCase';
import { PurchaseOrder } from '~/services/obras/servicios/orden_compra';
import { BillFactory } from '~/domain/entities/obras/servicios/orden_compra/factories/BillFactory';
import { $try } from '~/services/global';

class SavePurchaseOrderBillUseCase extends UseCase {
    static async execute({id_proyecto, id_control_piso, id_orden_compra, payload}) {
        const data = await PurchaseOrder.savePurchaseOrderBill(id_proyecto, id_control_piso, id_orden_compra, payload);
        return $try(() => {
            if (data?.data) return BillFactory.instantiate(data.data);
        })
    }
}

export default SavePurchaseOrderBillUseCase.execute;