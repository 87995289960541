import { Configuration } from "../Configuration";

export class ConfigurationFactory {
    static instantiate(obj){
        return new Configuration(obj)
    }

    static instantiateEmpty(){
        return new Configuration({
            nombre: '',
            id: '',
            tabla: '',
            etapa: '',
            horas_hombre: '',
            valor: ''
        })
    }
}