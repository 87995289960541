import { Documentacion } from "../Documentacion";

export class DocumentacionFactory {
  static instantiate(obj) {
    return new Documentacion(obj);
  }

  static instantiateEmpty() {
    return new Documentacion({
      id: "",
      nombre: "",
      id_control_piso: "",
      cantidad_archivos: "",
    });
  }
}
