import { UseCase } from "~/domain/core/UseCase";
import { Selects } from '~/services/selects/selects'
import { MaterialTypeVOFactory } from "~/domain/value_objects/almacen/materiales/factories/MaterialTypeVOFactory"
import { $try } from '~/services/global'

class GetSelectMaterialTypesUseCase extends UseCase {
    static async execute(){
        const data = await Selects.GetMaterialTypes()
        return $try(async () => {
            if(data.data && Array.isArray(data.data)) 
                return data.data.map(el => MaterialTypeVOFactory.instantiate(el))
        })
    }
}

export default GetSelectMaterialTypesUseCase.execute