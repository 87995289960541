import { CotizacionFilter } from "../CotizacionFilter"
export class CotizacionFilterFactory {
    static instantiate(obj){
        return new CotizacionFilter(obj)
    }

    static instantiateEmpty(){
        const filter = new CotizacionFilter({
            id_estado: null,
            materiales_min: 0,
            materiales_max: 0,
            valor_min: 0,
            valor_max: 0,
            fecha_cotizacion_min: null,
            fecha_cotizacion_max: null,
            dias_credito_min: 0,
            dias_credito_max: 0
        })
        filter.setExceptions({
            materiales_min: 0,
            materiales_max: 0,
            valor_min: 0,
            valor_max: 0,
            dias_credito_min: 0,
            dias_credito_max: 0
        })
        return filter
    }

    static instantiateEmptyWithRange(range){
        const materiales_min = range?.materiales_min ?? 0;
        const materiales_max = range?.materiales_max ?? 0;
        const valor_min = range?.valor_min ?? 0;
        const valor_max = range?.valor_max ?? 0;
        const dias_credito_min = range?.dias_credito_min ?? 0;
        const dias_credito_max = range?.dias_credito_max ?? 0;

        const filter = new CotizacionFilter({
            id_estado: null,
            materiales_min,
            materiales_max,
            valor_min,
            valor_max,
            fecha_cotizacion_min: null,
            fecha_cotizacion_max: null,
            dias_credito_min,
            dias_credito_max
        })
        filter.setExceptions({
            materiales_min,
            materiales_max,
            valor_min,
            valor_max,
            dias_credito_min,
            dias_credito_max
        })
        return filter
    }
}