import { UseCase } from "~/domain/core/UseCase";
import { Pedidos } from '~/services/almacen/pedidos';
import { PedidoInternoFactory } from '~/domain/entities/almacen/pedidos/factories/PedidoInternoFactory';
import { $try } from "~/services/global";

class EditPedidoInternoUseCase extends UseCase {
  static async execute(params) {
    const data = await Pedidos.updatePedidoInterno(params);
    return $try(() => {
      if (data?.data) return new PedidoInternoFactory.instantiate(data.data);
    })
  }
}

export default EditPedidoInternoUseCase.execute;
